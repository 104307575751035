import React from 'react'

import * as loco from '@loco'
import { Wrapper, Text, Icon } from './styled'

type Props = {
    variant: 'lecture' | 'chapter'
}

const NotAllowed = ({ variant }: Props) => {
    return (
        <Wrapper>
            <Icon />
            {variant === 'chapter' ? (
                <Text>{loco.notAllowed.chapter}</Text>
            ) : (
                <Text>{loco.notAllowed.lecture}</Text>
            )}
        </Wrapper>
    )
}

export default NotAllowed
