import React from 'react'
import Typography from '@material-ui/core/Typography'

import * as loco from '@loco'
import { Routes } from '@variables'
import Illustration from '../../SVG/NoStatistics'
import { Wrapper, Left, Right, Text, Button, Link } from './styled'

const NoStatistics = () => (
    <Wrapper>
        <Left>
            <Illustration />
        </Left>

        <Right>
            <Typography variant="h5">{loco.statistics.empty.title}</Typography>
            <Text paragraph>{loco.statistics.empty.subtitle}</Text>
            <Button variant="contained" color="primary" size="large">
                {loco.statistics.empty.add}
                <Link to={Routes.PROFILE_EDIT}></Link>
            </Button>
        </Right>
    </Wrapper>
)

export default NoStatistics
