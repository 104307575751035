import React, { useContext } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { QueryBuilder, PlayArrowRounded } from '@material-ui/icons'

import * as loco from '@loco'
import { ChaptersQuery } from '@graphql'
import BookIcon from '../../../SVG/Book'
import CoinsIcon from '../../../SVG/Coins'
import { colors, Routes } from '@variables'
import Tooltip from '../../../shared/Tooltip'
import CompleteIcon from '../../../SVG/Complete'
import { AuthContext } from '../../../../context/Auth'
import TruncatedText from '../../../shared/TruncatedText'
import IconWithProgressBar from '../../../shared/IconWithProgressBar'
import { getIcon, getRouteWithId, getMinutesWord, formatSecondsToMinutes } from '@utils'
import {
    Info,
    Footer,
    Wrapper,
    NextButton,
    Description,
    IconWrapper,
    TextWrapper,
    TitleWrapper,
    ContentWrapper,
    CompleteIconWrapper
} from './styled'

type Props = {
    expanded: boolean
    lecturesLength: number
    isLastActive?: boolean
    chapter: ChaptersQuery['chapters'][0]
}

const ChapterPreview = ({
    expanded,
    isLastActive,
    lecturesLength,
    chapter: { icon, description, maxPoints, totalLength, acquiredPoints, progress: prgs, name, id }
}: Props) => {
    const history = useHistory()
    const { isAuthorized, user } = useContext(AuthContext)

    const isNewOne = !Boolean(user?.student?.progress.points.reached)
    const isTempOrNotAuth = !isAuthorized || Boolean(user?.isTemporary)

    const goToChapter = () => history.push(getRouteWithId(Routes.CHAPTER, id))

    const progress = prgs ? (prgs > 100 ? 100 : prgs) : 0
    const hasProgress = progress > 0

    return (
        <Wrapper>
            <ContentWrapper>
                <IconWrapper onClick={goToChapter} role="button">
                    <IconWithProgressBar
                        size="medium"
                        value={progress}
                        icon={getIcon(icon)}
                        color={colors.primary.orange}
                    />
                </IconWrapper>

                <TextWrapper>
                    <Link to={getRouteWithId(Routes.CHAPTER, id)}>
                        <TitleWrapper isIconThere={Boolean(icon.svg)}>
                            <span>{name}</span>
                            {progress === 100 && (
                                <Tooltip title={loco.tooltips.chapter.completed}>
                                    <CompleteIconWrapper>
                                        <CompleteIcon />
                                    </CompleteIconWrapper>
                                </Tooltip>
                            )}
                        </TitleWrapper>
                    </Link>

                    <Description>
                        <TruncatedText text={description} max={150} onClick={goToChapter} />
                    </Description>
                </TextWrapper>

                {(expanded || Boolean(isLastActive)) && (
                    <NextButton
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={goToChapter}
                        startIcon={
                            isTempOrNotAuth || isNewOne ? (
                                <PlayArrowRounded style={{ color: 'white' }} />
                            ) : null
                        }
                    >
                        {progress < 100 && hasProgress && loco.common.continue}
                        {progress < 100 && !hasProgress && loco.common.start}
                        {progress >= 100 && loco.common.repeat}
                    </NextButton>
                )}
            </ContentWrapper>

            <Footer>
                <Info>
                    <CoinsIcon
                        color={
                            Boolean(acquiredPoints)
                                ? colors.primary.orange
                                : colors.primary.darkBlue
                        }
                    />
                    <span>
                        {acquiredPoints || 0}/{maxPoints} {loco.common.points}
                    </span>
                </Info>

                <Info>
                    <BookIcon />
                    <span>
                        {isTempOrNotAuth ? 1 : lecturesLength}{' '}
                        {lecturesLength < 5
                            ? loco.dashboard.chapter.lecture
                            : loco.dashboard.chapter.lectures}
                    </span>
                </Info>

                <Info>
                    <QueryBuilder style={{ color: colors.primary.orange }} />
                    <span>
                        {formatSecondsToMinutes(totalLength, true)}{' '}
                        {getMinutesWord(Math.round(totalLength / 60))}
                    </span>
                </Info>
            </Footer>
        </Wrapper>
    )
}

export default ChapterPreview
