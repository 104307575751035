import { useMedia } from 'react-use'
import { object, string } from 'yup'
import { useSnackbar } from 'notistack'
import debounce from 'lodash/debounce'
import React, { useCallback } from 'react'
import isUndefined from 'lodash/isUndefined'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Form, Field, Formik, FormikProps, FormikHelpers } from 'formik'

import * as loco from '@loco'
import Close from '../../shared/Close'
import { ButtonWrapper } from './styled'
import InputField from '../../Fields/Input'
import { Title } from '../../Tables/Superadmin'
import { notificationMsgs } from '@variables'
import { DialogContent, ErrorMsg } from '../../shared/styled'
import { translateGQLError, getRole as getCzRole } from '@utils'
import {
    Role,
    AdminUserFragment,
    useInviteUserMutation,
    useUpdateUserMutation,
    useIsEmailUsedLazyQuery
} from '@graphql'

type FormValues = {
    username: string
    email: string
}

type Props = {
    title: Title
    open: boolean
    close: () => void
    refetch: () => void
    currentUser?: AdminUserFragment
}

const SuperDialog = ({ open, close, title, currentUser, refetch }: Props) => {
    const { enqueueSnackbar } = useSnackbar()
    const isWide = useMedia('(min-width: 854px)')

    const [checkIsEmailUsed, { data: emailData }] = useIsEmailUsedLazyQuery()

    const [inviteUser, { loading: inviteLoading }] = useInviteUserMutation()
    const [updateUser, { loading: updateLoading }] = useUpdateUserMutation()

    const isLoading = inviteLoading || updateLoading

    const submit = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
        if (!currentUser) {
            const role = getRole(title)
            const email = values.email

            const newData = {
                role,
                email: values.email,
                name: values.username
            }

            if (role === Role.ADMIN || role === Role.SUPER_ADMIN) {
                if (!email.includes('@patria.cz') && !email.includes('@futured.app')) {
                    enqueueSnackbar(notificationMsgs.adminEmail, { variant: 'error' })
                    return
                }
            }

            const { data, errors } = await inviteUser({
                variables: { data: { ...newData } }
            })

            if (data && !errors) {
                enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
                resetForm()
                close()
            } else {
                errors?.forEach((err) =>
                    enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
                )
            }
        } else {
            const { data, errors } = await updateUser({
                variables: {
                    data: {
                        email: values.email,
                        username: values.username
                    },
                    where: {
                        id: currentUser.id
                    }
                }
            })

            if (data && !errors) {
                enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
                resetForm()
                refetch()
                close()
            } else {
                errors?.forEach((err) =>
                    enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
                )
            }
        }
    }

    const handleIsEmailUsed = async (event: any) => {
        checkIsEmailUsed({
            variables: {
                email: event.target.value
            }
        })
    }

    const delayedCallback = useCallback(debounce(handleIsEmailUsed, 400), [])

    const onInput = (event: any) => {
        if (currentUser?.id) return

        event.persist()
        delayedCallback(event)
    }

    return (
        <Dialog
            open={open}
            onClose={close}
            fullScreen={!isWide}
            aria-labelledby="superadmin-dialog"
        >
            <DialogContent>
                <Close onClick={close} />

                <Typography variant="h3" style={{ marginBottom: 20, textAlign: 'center' }}>
                    {title}
                </Typography>

                <Formik
                    onSubmit={submit}
                    isInitialValid={false}
                    validationSchema={validationSchema}
                    initialValues={{
                        email: currentUser?.email || '',
                        username: currentUser?.username || ''
                    }}
                >
                    {({ isValid, isSubmitting, values }: FormikProps<FormValues>) => (
                        <Form style={{ width: '100%' }} spellCheck={false}>
                            <Field
                                fullWidth
                                key="username"
                                name="username"
                                value={values.username}
                                component={InputField}
                                className="customInput"
                                disabled={isSubmitting || isLoading}
                                label={loco.dialogs.user.name.label}
                                placeholder={loco.dialogs.user.name.placeholder}
                            />

                            <Field
                                fullWidth
                                key="email"
                                name="email"
                                onInput={onInput}
                                value={values.email}
                                component={InputField}
                                className="customInput"
                                disabled={isSubmitting || isLoading}
                                label={loco.dialogs.user.email.label}
                                placeholder={loco.dialogs.user.email.placeholder}
                            />

                            {!isUndefined(emailData?.isEmailUsed.isUsed) &&
                                Boolean(emailData?.isEmailUsed.isUsed) && (
                                    <ErrorMsg style={{ marginTop: 0 }}>
                                        {loco.common.emailIsUsed}{' '}
                                        {getCzRole(emailData?.isEmailUsed.role)}
                                    </ErrorMsg>
                                )}

                            <ButtonWrapper>
                                <Button
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    disabled={!isValid || isLoading || isSubmitting}
                                >
                                    {currentUser ? loco.common.edit : loco.common.create}
                                    {`${isLoading || isSubmitting ? ' ..' : ''}`}
                                </Button>
                            </ButtonWrapper>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}

const getRole = (title: Title): Role => {
    if (title === Title.ADMIN) return Role.ADMIN
    return Role.TEACHER
}

const validationSchema = object({
    username: string().required(loco.validation.common.required),
    email: string().email(loco.validation.email.common).required(loco.validation.common.required)
})

export default SuperDialog
