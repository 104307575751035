import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import { media } from '@variables'
import { colors } from '@variables'

type MobileMenuWrapperProps = {
    readonly open: boolean
}

type LinkProps = {
    readonly active?: boolean
}

export const Inner = styled.div`
    width: calc(100% - 40px);
    position: absolute;
    top: 20px;
`

export const MobileMenuWrapper = styled.nav<MobileMenuWrapperProps>`
    display: ${(props) => (props.open ? 'flex' : 'none')};
    top: 90px;
    width: 100%;
    z-index: 1302;
    position: fixed;
    background: white;
    flex-direction: column;
    height: calc(100vh - 90px);
    padding: 15px 20px 0 20px;
    justify-content: flex-start;
    overflow-y: scroll;

    ${media.xl} {
        top: 100px;
        height: calc(100vh - 100px);
    }

    ${media.phone} {
        padding: 0 20px;
        overflow-y: inherit;
        justify-content: center;
    }
`

export const Ul = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`

export const Li = styled.li<LinkProps>`
    margin: 0;
    height: 40px;
    display: flex;
    padding-left: 20px;
    border-radius: 5px;
    align-items: center;
    background-color: ${(props) => (props.active ? 'hsla(220, 14%, 91%, 0.4)' : 'white')};

    ${media.phone} {
        height: 55px;
        margin: 3px 0;
    }
`

export const Link = styled(NavLink)`
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    text-decoration: none;
    color: ${colors.primary.darkBlue};

    ${media.phone} {
        font-size: 20px;
        line-height: 26px;
    }
`

export const Divider = styled.hr`
    width: 100%;
    margin: 10px 0 20px 0;
    background-color: #eaeaeb;

    ${media.tablet} {
        margin: 40px 0;
    }
`

export const BottomText = styled.p`
    width: 100%;
    color: #94979c;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    align-self: center;
`
