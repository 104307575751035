import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="54"
            height="54"
            fill="none"
            viewBox="0 0 54 54"
            {...props}
        >
            <circle cx="27" cy="27" r="27" fill="#F59100" opacity="0.1"></circle>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M22.5 38.25h-6.75V30a1.5 1.5 0 011.5-1.5h5.25M31.5 28.5h5.25a1.5 1.5 0 011.5 1.5v8.25H31.5"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M28.5 34.313A1.416 1.416 0 0027 33a1.416 1.416 0 00-1.5 1.313v3.937h3v-3.937z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M27 21.75v-6"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M27 15.75h4a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-4v-3zM27 27.75a1.467 1.467 0 100-2.934 1.467 1.467 0 000 2.934z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M31.5 26.118A4.435 4.435 0 0027 21.75a4.435 4.435 0 00-4.5 4.368V38.25h9V26.118z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M18.75 31.5h.75M18.75 35.25h.75M34.5 31.5h.75M34.5 35.25h.75"
            ></path>
        </svg>
    )
}

export default Icon
