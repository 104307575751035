import React from 'react'
import { GraphQLError } from 'graphql'
import { useSnackbar } from 'notistack'
import { useSetState } from 'react-use'
import { Typography, CircularProgress } from '@material-ui/core'

import * as loco from '@loco'
import WrongIcon from '../../../SVG/WrongIcon'
import InfoDialog from '../../../Dialogs/Info'
import RejectForm from '../../../Dialogs/Reject'
import { TipLink } from '../../../shared/styled'
import CoinsIcon from '../../../SVG/Coins'
import { translateGQLError } from '@utils'
import CorrectIcon from '../../../SVG/CorrectIcon'
import { notificationMsgs } from '@variables'
import AnswerIllustration from '../../../SVG/Answer'
import TestCorrectIllustration from '../../../SVG/TestCorrect'
import TestWrongIllustration from '../../../SVG/TestWrong'
import { StepFragment, useReportIssueMutation } from '@graphql'
import {
    IsRight,
    TipPrice,
    TipCenter,
    TipWrapper,
    NextButton,
    IsRightText,
    TipPriceText,
    TipCharacterWrapper
} from './styled'

type Props = {
    step: StepFragment
    isLoading: boolean
    isLastStep: boolean
}

const Tip = ({
    step: { isCorrect, description, correctAnswer, points, question },
    isLastStep,
    isLoading
}: Props) => {
    const { enqueueSnackbar } = useSnackbar()

    const [state, setState] = useSetState({
        isInfoOpen: false,
        isRejectOpen: false
    })

    const toggleInfo = () => setState({ isInfoOpen: !state.isInfoOpen })
    const toggleReject = () => setState({ isRejectOpen: !state.isRejectOpen })

    const [reportIssue, { loading: reportLoading }] = useReportIssueMutation()
    const loading = isLoading || reportLoading

    const onReport = async (reason: string): Promise<{ errors: GraphQLError[] | undefined }> => {
        const { data, errors } = await reportIssue({
            variables: {
                issue: reason,
                question: { id: question.id }
            }
        })

        if (data && !errors) {
            enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }

        return { errors }
    }

    return (
        <>
            <InfoDialog
                toggleOpen={toggleInfo}
                isOpen={state.isInfoOpen}
                buttonProps={{
                    onClick: toggleInfo,
                    text: loco.common.close
                }}
                data={[
                    {
                        title: loco.test.more,
                        description: description || '',
                        icon: <AnswerIllustration />
                    }
                ]}
            />

            <RejectForm
                reject={onReport}
                close={toggleReject}
                open={state.isRejectOpen}
                title={loco.dialogs.common.report.title}
                description={loco.dialogs.common.report.description}
            />

            <TipWrapper isLoading={loading} color={isCorrect ? '#F5FDF6' : '#FFEFF2'}>
                <TipCharacterWrapper>
                    {isCorrect ? <TestCorrectIllustration /> : <TestWrongIllustration />}
                </TipCharacterWrapper>

                <TipCenter>
                    <IsRight>
                        {isCorrect ? <CorrectIcon /> : <WrongIcon />}
                        {isCorrect ? (
                            <IsRightText isRight={true}>{loco.test.right}</IsRightText>
                        ) : (
                            <IsRightText isRight={false}>{loco.test.wrong}</IsRightText>
                        )}
                    </IsRight>

                    {/* description */}
                    <Typography variant="body1" style={{ margin: 0, opacity: 0.8 }}>
                        {correctAnswer?.text}{' '}
                        <span
                            role="button"
                            onClick={toggleInfo}
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        >
                            {loco.common.more}
                        </span>
                    </Typography>

                    {isCorrect && (
                        <>
                            <TipPrice>
                                <CoinsIcon />
                                <TipPriceText>{points}</TipPriceText>
                            </TipPrice>
                            <TipLink onClick={toggleReject}>{loco.test.report}</TipLink>
                        </>
                    )}

                    {!isCorrect && <TipLink onClick={toggleReject}>{loco.test.report}</TipLink>}
                </TipCenter>

                <NextButton
                    size="large"
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={loading}
                    cy-data="test-tip-next-button"
                >
                    {isLastStep ? loco.test.result : loco.test.next}
                    {loading && (
                        <CircularProgress style={{ height: 14, width: 14, marginLeft: 10 }} />
                    )}
                </NextButton>
            </TipWrapper>
        </>
    )
}

export default Tip
