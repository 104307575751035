import React from 'react'

const FacebookIcon = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="14"
            fill="none"
            viewBox="0 0 8 14"
            {...props}
        >
            <path
                fillRule="evenodd"
                fill={props.color ? props.color : '#fff'}
                d="M5.113 13.745H2.614V7.709H.57v-2.4h2.057v-.18c0-.484-.01-.965.002-1.449.008-.306.02-.62.075-.921.128-.734.466-1.354 1.08-1.795C4.318.58 4.93.422 5.578.41c.076-.002.152-.003.229-.003.348 0 .696.018 1.044.032.231.012.463.044.683.066v2.15H7.27l-.333-.002-.328-.001c-.162 0-.325 0-.487.007-.567.02-.986.235-1.006.943-.015.516-.02 1.707-.02 1.707h2.367c-.106.823-.208 1.613-.313 2.413H5.113v6.024z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default FacebookIcon
