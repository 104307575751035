import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import { Tab as TabBase, Container as MuiContainer } from '../../../../components/shared/styled'

export const Title = styled(Typography)``
export const Container = styled(MuiContainer)`
    ${Title} {
        font-size: 18px;
        line-height: 28px;
        padding: 50px 0 24px 0;
    }
`

export const Top = styled.div`
    width: 100%;
    display: flex;
    background: white;
    border-radius: 5px;
    justify-content: center;
    padding: 36px 0 55px 0;
    box-shadow: 0px 12px 60px rgba(42, 48, 57, 0.1);
`

export const TopItem = styled.div`
    display: flex;
    margin: 0 77px;
    align-items: center;
    flex-direction: column;

    p {
        padding: 20px 0 4px 0;
    }
`

export const Header = styled.div`
    width: 100%;
    display: flex;
    margin: 50px 0 30px 0;
    justify-content: center;
`

export const Tab = styled(TabBase)``
