import React from 'react'

import { Props } from '.'

function Icon({ color }: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="24"
            fill="none"
            viewBox="0 0 20 24"
        >
            <path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M8 21.75a2.087 2.087 0 004.005 0M10 3V.75"
            ></path>
            <path
                fillRule="evenodd"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M10 3a7.5 7.5 0 017.5 7.5c0 7.046 1.5 8.25 1.5 8.25H1s1.5-1.916 1.5-8.25A7.5 7.5 0 0110 3z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default Icon
