import React, { useState, useEffect, SetStateAction } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState } from 'draft-js'

type Props = {
    editorState: EditorState
    setState: SetStateAction<any>
}

const WYSIWYG = ({ editorState, setState }: Props) => {
    const [lock, setLock] = useState(false)

    useEffect(() => {
        setLock(true)
    }, [])

    return lock ? (
        <Editor
            toolbar={toolbar}
            editorState={editorState}
            editorClassName="editorClassName"
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            onEditorStateChange={(editorState) => setState(editorState)}
        />
    ) : null
}

// https://jpuri.github.io/react-draft-wysiwyg/#/docs
const toolbar = {
    options: ['inline', 'blockType', 'list', 'history'],
    inline: {
        options: ['bold', 'italic']
    },
    blockType: {
        options: ['Normal', 'H1', 'H2']
    },
    list: {
        options: ['unordered', 'ordered']
    }
}

export default WYSIWYG
