import styled from 'styled-components'
import MuiPaper from '@material-ui/core/Paper'
import MuiPopper from '@material-ui/core/Popper'

export const Paper = styled(MuiPaper)``

export const Popper = styled(MuiPopper)`
    z-index: 99999 !important;

    @media (max-width: 1169px) {
        position: fixed !important;
        transform: none !important;
        width: calc(100% - 48px);
        left: 24px !important;
    }

    ${Paper} {
        top: 55px;
        width: 480px;
        position: relative;
        box-shadow: 0px 30px 100px rgba(42, 48, 57, 0.05);

        @media (max-width: 1169px) {
            width: 100%;
            box-shadow: 0px 30px 100px rgba(42, 48, 57, 0.3);
        }
    }
`

export const Black = styled.div`
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(4, 4, 113, 0.5);
`

export const Column = styled.div`
    height: 320px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`
