import { Button } from '@material-ui/core'
import MailIcon from '@material-ui/icons/Mail'
import { useHistory } from 'react-router-dom'
import React, { useContext, useEffect } from 'react'
import TwitterIcon from '@material-ui/icons/Twitter'
import SaveAltRoundedIcon from '@material-ui/icons/SaveAltRounded'

import * as loco from '@loco'
import { Routes } from '@variables'
import Head from '../../../../components/Head'
import DiamondIcon from '../../../../components/SVG/Coins'
import { getProfileName, getServiceUrl } from '@utils'
import FacebookIcon from '../../../../components/SVG/Facebook'
import { AuthContext } from '../../../../context/Auth'
import TipCharacter from '../../../../components/SVG/TipCharacter'
import FakeAvatar from '@static/images/avatar.png'
import DiplomaHeaderIcon from '../../../../components/SVG/DiplomaHeader'
import {
    FacebookButton,
    SocialWrapper,
    DiplomaHeader,
    TwitterButton,
    DiplomaCoins,
    Description,
    EmailButton,
    DiplomaLink,
    Container,
    Wrapper,
    Avatar,
    Title,
    Name
} from './styled'

const DiplomaPage = () => {
    const histroy = useHistory()
    const { user } = useContext(AuthContext)

    const shareUrl = `https://${process.env.RAZZLE_HOST}`

    useEffect(() => {
        if (!Boolean(user?.student?.finalTestStatus?.isCompleted)) return histroy.push(Routes.HOME)
    }, [])

    return (
        <>
            <Head title={loco.seo.diplom.title} />

            <Container style={{ height: '100vh' }}>
                <Wrapper>
                    <TipCharacter
                        style={{ width: 80, position: 'relative', left: 15, bottom: -20 }}
                    />
                    <DiplomaHeader>
                        <DiplomaHeaderIcon />
                        <Title variant="h6" align="center">
                            {loco.diplom.title}
                        </Title>
                    </DiplomaHeader>

                    <Avatar
                        alt="avatar"
                        // user?.avatar?.svg || user?.avatar?.png ||
                        src={FakeAvatar}
                    />
                    <Name align="center">{getProfileName(user)}</Name>
                    <Description align="center">{loco.diplom.description}</Description>

                    <DiplomaCoins>
                        <DiamondIcon />
                        <span>
                            {user?.student?.progress.points.reached}/
                            {user?.student?.progress.points.total} {loco.common.points}
                        </span>
                    </DiplomaCoins>

                    <SocialWrapper>
                        <span>{loco.diplom.share}</span>
                        <FacebookButton
                            url={shareUrl}
                            quote={loco.diplom.youtube.quote}
                            hashtag={loco.diplom.youtube.hashtag}
                        >
                            <FacebookIcon color="white" />
                        </FacebookButton>
                        <TwitterButton
                            url={shareUrl}
                            title={loco.diplom.twitter.title}
                            hashtags={loco.diplom.twitter.hashtags.split(',')}
                        >
                            <TwitterIcon />
                        </TwitterButton>
                        <EmailButton
                            url={shareUrl}
                            body={loco.diplom.email.body}
                            subject={loco.diplom.email.subject}
                        >
                            <MailIcon />
                        </EmailButton>
                    </SocialWrapper>

                    <DiplomaLink target="_blank" href={getServiceUrl('diploma')}>
                        <Button
                            fullWidth
                            size="large"
                            color="primary"
                            variant="contained"
                            startIcon={<SaveAltRoundedIcon />}
                        >
                            {loco.diplom.download}
                        </Button>
                    </DiplomaLink>
                </Wrapper>
            </Container>
        </>
    )
}

export default DiplomaPage
