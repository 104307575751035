import { Image } from './../../pages/Public/LP/Education/styled'
import styled from 'styled-components'

import { colors, transition, media } from '@variables'
import { AccessibleButton } from '../../components/shared/styled'

export const IconWrapper = styled(AccessibleButton)`
    background-color: hsla(0, 0%, 0%, 0.1);
    transition: ${transition.main};
    border-radius: 100px;
    position: absolute;
    height: 32px;
    width: 32px;
    padding: 5px;
    z-index: 1;
    right: 5px;
    top: 5px;

    ${media.tablet} {
        :hover {
            background-color: hsla(0, 0%, 0%, 0.4);
        }
    }

    svg {
        width: 18px;
        height: auto;
        color: white;
    }
`

export const Wrapper = styled.div`
    height: 100%;
    width: 100%;

    video {
        object-fit: contain;
    }

    .react-player__preview {
        background-repeat: no-repeat;
        background-size: contain !important;
        background-position: center !important;
    }
`

export const More = styled.div`
    display: flex;
    padding: 20px;
    align-items: flex-start;
    flex-direction: column;
`

export const MoreButton = styled.button`
    padding: 0;
    border: none;
    outline: none;
    margin: 10px 0;
    cursor: pointer;
    font-size: 12px;
    background: none;
    transition: ${transition};
    text-transform: uppercase;

    :first-of-type {
        margin-top: 0;
    }
    :last-of-type {
        margin-bottom: 0;
    }

    ${media.tablet} {
        :hover {
            color: ${colors.primary.orange};
        }
    }
`
