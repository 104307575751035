import React from 'react'

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            fill="none"
            viewBox="0 0 48 48"
        >
            <circle cx="24" cy="24" r="24" fill="#F59100" opacity="0.1"></circle>
            <path
                fillRule="evenodd"
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M19 14.625c-.69 0-1.25.56-1.25 1.25v1.973a6.25 6.25 0 1012.5 0v-1.973c0-.69-.56-1.25-1.25-1.25H19z"
                clipRule="evenodd"
            ></path>
            <path
                fillRule="evenodd"
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M19 33.375c-.69 0-1.25-.56-1.25-1.25v-1.973a6.25 6.25 0 1112.5 0v1.973c0 .69-.56 1.25-1.25 1.25H19z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M18.564 20.875h10.872M29.456 33.29C28.386 31.042 26.344 29 24 29c-2.345 0-4.387 2.043-5.457 4.29"
            ></path>
        </svg>
    )
}

export default Icon
