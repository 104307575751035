import styled from 'styled-components'

import { Props } from '.'
import { colors } from '@variables'
import { getPercentages } from '@utils'

export const Svg = styled.svg`
    transform: rotate(270deg);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 4;
    left: 0;
    top: 0;
`

export const Rect = styled.rect<{ readonly progress: number }>`
    stroke-dashoffset: 1000;
    stroke-dasharray: 1000;
    stroke-linecap: square;

    ${(props) => props.progress && `stroke-dashoffset: ${1000 - props.progress};`}
`

export const Wrapper = styled.div<Pick<Props, 'size' | 'value'>>`
    background-color: ${colors.primary.gray2};
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
    display: flex;

    ${(props) =>
        props.size === 'medium' &&
        `
        min-height: 75px;
        min-width: 75px;
        height: 75px;
        width: 75px;
    `}
    ${(props) =>
        props.size === 'large' &&
        `
        min-height: 95px;
        min-width: 95px;
        height: 95px;
        width: 95px;
    `}


    :after {
        display: ${(props) => (props.value === 0 ? 'none' : 'block')};
        background-color: ${colors.primary.orange};
        border: 1px solid white;
        position: absolute;
        border-radius: 50%;
        height: 12px;
        width: 12px;
        content: '';
        z-index: 5;
    }

    ${(props) =>
        // Left
        props.value >= 0 &&
        props.value <= 25 &&
        `
        :after {
            left: -5px;
            bottom: ${getPercentages(props.value, 25)}%;
            transform: translateY(${getPercentages(props.value, 25)}%);
        }
    `}
    ${(props) =>
        // Top
        props.value > 25 &&
        props.value <= 50 &&
        `
        :after {
            top: -5px;
            left: ${getPercentages(props.value - 25, 25)}%;
            transform: translateX(-${getPercentages(props.value - 25, 25)}%);
        }
    `}
    ${(props) =>
        // Right
        props.value > 50 &&
        props.value <= 75 &&
        `
        :after {
            right: -5px;
            top: ${getPercentages(props.value - 50, 25)}%;
            transform: translateY(-${getPercentages(props.value - 50, 25)}%);
        }
    `}
    ${(props) =>
        // Bottom
        props.value > 75 &&
        `
        :after {
            bottom: -5px;
            right: ${getPercentages(props.value - 75, 25)}%;
            transform: translateX(${getPercentages(props.value - 75, 25)}%);
        }
    `}
`

export const Inner = styled.div<Pick<Props, 'size'>>`
    z-index: 3;
    display: flex;
    background: white;
    position: relative;
    border-radius: 4px;
    align-items: center;
    justify-content: center;

    ${(props) =>
        props.size === 'medium' &&
        `
        width: 73px;
        height: 73px;
        min-width: 73px;
        min-height: 73px;
    `}
    ${(props) =>
        props.size === 'large' &&
        `
        width: 93px;
        height: 93px;
        min-width: 93px;
        min-height: 93px;
    `}
`

export const IconWrapper = styled.div`
    z-index: 3;
    width: 90%;
    height: 90%;
    display: flex;
    position: relative;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    background: ${colors.primary.gray2};
`

export const Icon = styled.img<Pick<Props, 'size'>>`
    top: 50%;
    left: 50%;
    height: auto;
    position: absolute;
    transform: translate(-50%, -50%);

    ${(props) => props.size === 'medium' && ` width: 40px; `}
    ${(props) => props.size === 'large' && ` width: 50px; `}
`
