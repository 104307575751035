import * as React from 'react'
import styled from 'styled-components'

import { transition, media } from '@variables'

const Wrapper = styled.div<{ readonly color: string }>`
    padding: 4px 12px;
    letter-spacing: 0.5px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 5px;
    background-color: ${(props) => props.color};
    position: absolute;
    right: 0;
    transition: ${transition.main};

    a {
        color: white;
    }

    ${media.tablet} {
        :hover {
            background-color: #fcb630;
            cursor: pointer;
        }
    }
`

type Props = {
    color: string
    children: React.ReactNode | string
}

const Tag = ({ color, children }: Props) => <Wrapper color={color}>{children}</Wrapper>

export default Tag
