import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import TextField from '@material-ui/core/TextField'
import { ToolbarProps } from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { DebounceInput } from 'react-debounce-input'
import DateRangeIcon from '@material-ui/icons/DateRange'
import { DateRangePicker } from '@material-ui/pickers'
import { DateRange } from '@material-ui/pickers/DateRangePicker/RangeTypes'

import * as loco from '@loco'
import { Wrap, Fields, FieldWrap, IconWrap } from './styled'

type Props = {
    title: string
    searchText: string
    onSearchChanged: (query: string) => void
    selectedDate: DateRange<Date | null>
    handleDateChange: (date: DateRange<Date | null>) => void
} & ToolbarProps

const Toolbar = ({
    title,
    searchText,
    onSearchChanged,
    selectedDate,
    handleDateChange,
    ...rest
}: Props) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onSearchChanged(e.target.value)
    }

    return (
        <Wrap {...rest}>
            <Typography variant="h6">{title}</Typography>
            <Fields>
                <FieldWrap>
                    <IconWrap edge="start">
                        <DateRangeIcon />
                    </IconWrap>
                    <DateRangePicker
                        endText=" "
                        startText=" "
                        mask="__.__.____"
                        value={selectedDate}
                        onChange={(date) => handleDateChange(date)}
                        renderInput={(startProps, endProps) => (
                            <>
                                <TextField {...startProps} helperText="" variant="standard" />
                                <TextField {...endProps} helperText="" variant="standard" />
                            </>
                        )}
                    />
                    <IconWrap
                        edge="end"
                        role="button"
                        onClick={() => handleDateChange([null, null])}
                    >
                        <CloseIcon />
                    </IconWrap>
                </FieldWrap>
                <FieldWrap>
                    <IconWrap edge="start">
                        <SearchIcon />
                    </IconWrap>
                    <DebounceInput
                        // @ts-ignore
                        element={TextField}
                        value={searchText}
                        onChange={onChange}
                        debounceTimeout={500}
                        placeholder={loco.table.toolbar.searchTooltip}
                        autoFocus
                    />
                    <IconWrap edge="end" role="button" onClick={() => onSearchChanged('')}>
                        <CloseIcon />
                    </IconWrap>
                </FieldWrap>
            </Fields>
        </Wrap>
    )
}

export default Toolbar
