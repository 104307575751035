import { Fade } from 'react-reveal'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import { useInView } from 'react-intersection-observer'

import * as loco from '@loco'
import { Routes } from '@variables'
import Icon1 from '@static/images/lp-edu-1.svg'
import Icon2 from '@static/images/lp-edu-2.svg'
import Icon3 from '@static/images/lp-edu-3.svg'
import Icon4 from '@static/images/lp-edu-4.svg'
import Icon5 from '@static/images/lp-edu-5.svg'
import Img from '@static/images/LP-education.png'
import { Wrapper, Wrap, Left, Image, TopTitle, Title, Row, Item, ItemText, ItemImg } from './styled'

const Education = () => {
    const history = useHistory()
    const { ref, inView } = useInView({
        threshold: 0.2
    })

    useEffect(() => {
        if (inView) {
            history.replace({ hash: Routes.EDU_HASH_PARAM })
        }
    }, [inView])

    return (
        <Wrapper id={Routes.EDU_HASH_PARAM}>
            <Container ref={ref}>
                <Wrap>
                    <Left>
                        <Fade>
                            <TopTitle align="left">{loco.lp.edu.topTitle}</TopTitle>
                            <Title
                                variant="h2"
                                align="left"
                                dangerouslySetInnerHTML={{ __html: loco.lp.edu.title }}
                            ></Title>
                        </Fade>
                        <Fade delay={50}>
                            <Row>
                                <Item>
                                    <ItemImg
                                        width="70"
                                        height="70"
                                        src={Icon1}
                                        loading="lazy"
                                        alt={loco.lp.edu.item1}
                                    />
                                    <ItemText
                                        variant="h4"
                                        align="left"
                                        dangerouslySetInnerHTML={{ __html: loco.lp.edu.item1 }}
                                    ></ItemText>
                                </Item>
                                <Item>
                                    <ItemImg
                                        width="70"
                                        height="70"
                                        src={Icon2}
                                        loading="lazy"
                                        alt={loco.lp.edu.item2}
                                    />
                                    <ItemText
                                        variant="h4"
                                        align="left"
                                        dangerouslySetInnerHTML={{ __html: loco.lp.edu.item2 }}
                                    ></ItemText>
                                </Item>
                                <Item>
                                    <ItemImg
                                        width="70"
                                        height="70"
                                        src={Icon3}
                                        loading="lazy"
                                        alt={loco.lp.edu.item3}
                                    />
                                    <ItemText
                                        variant="h4"
                                        align="left"
                                        dangerouslySetInnerHTML={{ __html: loco.lp.edu.item3 }}
                                    ></ItemText>
                                </Item>
                                <Item>
                                    <ItemImg
                                        width="70"
                                        height="70"
                                        src={Icon4}
                                        loading="lazy"
                                        alt={loco.lp.edu.item4}
                                    />
                                    <ItemText
                                        variant="h4"
                                        align="left"
                                        dangerouslySetInnerHTML={{ __html: loco.lp.edu.item4 }}
                                    ></ItemText>
                                </Item>
                                <Item>
                                    <ItemImg
                                        width="70"
                                        height="70"
                                        src={Icon5}
                                        loading="lazy"
                                        alt={loco.lp.edu.item5}
                                    />
                                    <ItemText
                                        variant="h4"
                                        align="left"
                                        dangerouslySetInnerHTML={{ __html: loco.lp.edu.item5 }}
                                    ></ItemText>
                                </Item>
                            </Row>
                        </Fade>
                    </Left>
                </Wrap>
            </Container>

            <Image src={Img} alt="apps images" loading="lazy" />
        </Wrapper>
    )
}

export default Education
