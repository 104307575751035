import styled from 'styled-components'

import { media } from '@variables'
import { colors } from '@variables'

export const Video = styled.div`
    width: 100%;
`

export const VideoTitle = styled.h6`
    max-height: 50px;
    overflow: hidden;
    font-size: 16px;
    font-weight: 400;
    margin: 15px 0 2.5px 0;
    color: ${colors.primary.darkBlue};
`

export const VideoPreview = styled.div<{ readonly img: string }>`
    width: 100%;
    height: 150px;
    position: relative;
    border-radius: 5px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: ${(props) => `url(${props.img})`};
`

export const VideoTag = styled.div`
    margin: 0;
    color: #95989c;
    font-size: 14px;
`

export const Duration = styled.div`
    position: absolute;
    display: block;
    bottom: 10px;
    right: 10px;
    color: white;
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    border-radius: 5px;
    width: 50px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.primary.darkBlue};
`
