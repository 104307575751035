import styled from 'styled-components'

import { Props } from '.'

export const Wrapper = styled.div<Pick<Props, 'variant'>>`
    height: 100%;
    display: flex;
    margin: 0 auto;
    max-width: 100%;
    flex-flow: row wrap;
    align-items: flex-start;
    padding: 15px 5px 15px 0;
    justify-content: space-between;

    ${(props) =>
        props.variant === 'horizontal' &&
        `
        padding: 30px 15px 30px 25px;
    `}

    @media (max-width: 959px) {
        padding: 30px 15px 30px 25px;
    }

    ${(props) => props.variant === 'horizontal' && ` padding: 30px 0 0 20px; `}
`
