import React, { useContext } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import ScrollMenu from 'react-horizontal-scrolling-menu'

import * as loco from '@loco'
import Head from '../../../../components/Head'
import { MenuList } from '../../../../components/Library'
import AllLibrary from '../../../../components/Library/All'
import VideoLibrary from '../../../../components/Library/Video'
import AudioLibrary from '../../../../components/Library/Audio'
import { useLibraryQuery, LibraryCategory } from '@graphql'
import { LibraryContext } from '../../../../context/Library'
import VocabularyLibrary from '../../../../components/Library/Vocabulary'
import TranscriptionLibrary from '../../../../components/Library/Transcript'
import { Container, Menu, SearchField, LibraryWrapper, SearchFieldWrapper, Title } from './styled'

const Library = () => {
    const { selected, maxItems, searchQuery, loadMore, onSelect, handleSearch } = useContext(
        LibraryContext
    )

    const { data: previewData, loading: previewLoading } = useLibraryQuery({
        skip: selected !== 'Vše',
        onError: (err) => console.error(err),
        variables: { filter: { search: searchQuery } }
    })
    const { data: videoData, loading: videoLoading } = useLibraryQuery({
        skip: selected !== LibraryCategory.VIDEO,
        onError: (err) => console.error(err),
        variables: {
            itemsPerPage: maxItems,
            filter: {
                search: searchQuery,
                type: LibraryCategory.VIDEO
            }
        }
    })
    const { data: audioData, loading: audioLoading } = useLibraryQuery({
        skip: selected !== LibraryCategory.AUDIO,
        onError: (err) => console.error(err),
        variables: {
            itemsPerPage: maxItems,
            filter: {
                search: searchQuery,
                type: LibraryCategory.AUDIO
            }
        }
    })
    const { data: transcriptData, loading: transcriptLoading } = useLibraryQuery({
        skip: selected !== LibraryCategory.TRANSCRIPT,
        onError: (err) => console.error(err),
        variables: {
            itemsPerPage: maxItems,
            filter: {
                search: searchQuery,
                type: LibraryCategory.TRANSCRIPT
            }
        }
    })
    const { data: vocabularyData, loading: vocabularyLoading } = useLibraryQuery({
        skip: selected !== LibraryCategory.VOCABULARY,
        onError: (err) => console.error(err),
        variables: {
            itemsPerPage: maxItems,
            filter: {
                search: searchQuery,
                type: LibraryCategory.VOCABULARY
            }
        }
    })

    const navigationList = [
        { name: 'Vše', csName: 'Vše' },
        { name: LibraryCategory.VIDEO, csName: loco.library.video.title },
        { name: LibraryCategory.AUDIO, csName: loco.library.audio },
        { name: LibraryCategory.TRANSCRIPT, csName: loco.library.transcript.title },
        { name: LibraryCategory.VOCABULARY, csName: loco.library.dictionary }
    ]

    const menu = MenuList(navigationList, LibraryCategory.VIDEO)

    return (
        <>
            <Head title={loco.seo.library.title} />

            <Container>
                <section>
                    <Title variant="h1">{loco.library.title}</Title>

                    <LibraryWrapper>
                        <Menu>
                            <ScrollMenu
                                data={menu}
                                scrollToSelected
                                selected={selected}
                                alignCenter={false}
                                // @ts-ignore
                                onSelect={onSelect}
                            />
                        </Menu>

                        {/* Search input */}
                        <SearchFieldWrapper>
                            <SearchIcon
                                style={{
                                    position: 'absolute',
                                    color: '#C0C0C0',
                                    left: 30,
                                    top: 13
                                }}
                            />
                            <SearchField
                                // @ts-ignore
                                minLength={1}
                                value={searchQuery}
                                debounceTimeout={500}
                                onChange={handleSearch}
                                placeholder={loco.library.search.placeholder}
                            />
                        </SearchFieldWrapper>

                        {selected === 'Vše' && (
                            <AllLibrary
                                data={previewData}
                                selected={selected}
                                isLoading={previewLoading}
                            />
                        )}

                        {selected === LibraryCategory.VIDEO && (
                            <VideoLibrary
                                selected={selected}
                                loadMore={loadMore}
                                isLoading={videoLoading}
                                data={videoData?.library.data || []}
                                maxItems={videoData?.library.meta.items || 0}
                            />
                        )}

                        {selected === LibraryCategory.AUDIO && (
                            <AudioLibrary
                                selected={selected}
                                loadMore={loadMore}
                                isLoading={audioLoading}
                                data={audioData?.library.data || []}
                                maxItems={audioData?.library.meta.items || 0}
                            />
                        )}

                        {selected === LibraryCategory.TRANSCRIPT && (
                            <TranscriptionLibrary
                                selected={selected}
                                loadMore={loadMore}
                                isLoading={transcriptLoading}
                                data={transcriptData?.library.data || []}
                                maxItems={transcriptData?.library.meta.items || 0}
                            />
                        )}

                        {selected === LibraryCategory.VOCABULARY && (
                            <VocabularyLibrary
                                selected={selected}
                                loadMore={loadMore}
                                isLoading={vocabularyLoading}
                                data={vocabularyData?.library.data || []}
                                maxItems={vocabularyData?.library.meta.items || 0}
                            />
                        )}
                    </LibraryWrapper>
                </section>
            </Container>
        </>
    )
}

export default Library
