import styled from 'styled-components'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'

export const AccordionSummary = styled(MuiAccordionSummary)``
export const AccordionDetails = styled(MuiAccordionDetails)``

export const Accordion = styled(MuiAccordion)`
    ${AccordionSummary} {
        padding: 0;
        cursor: inherit;
        user-select: inherit;
    }
`

export const Wrapper = styled.div`
    width: 100%;
    padding: 0 0 75px 0;
    background-color: white;
`

export const ArrowWrapper = styled.button`
    position: absolute;
    cursor: pointer;
    bottom: 30px;
    right: 30px;
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
`
