import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

const Loader = () => (
    <Wrapper>
        <CircularProgress />
    </Wrapper>
)

const Wrapper = styled.div`
    background-color: hsla(0, 100%, 100%, 0.8);
    justify-content: center;
    align-items: center;
    position: absolute;
    cursor: progress;
    display: flex;
    z-index: 1000;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
`

export default Loader
