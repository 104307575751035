import React from 'react'
import { format } from 'date-fns'
import { cs } from 'date-fns/locale'
import MaterialTable, { MTableHeader, Column } from 'material-table'
import { DateRange } from '@material-ui/pickers/DateRangePicker/RangeTypes'

import * as loco from '@loco'
import Row from '../../../shared/Row'
import Pagination from '../../Pagination'
import { tableLocalization } from '@variables'
import { getProfileName, secondsToHms } from '@utils'
import { tableIcons, headerStyle } from '../../../shared'
import Toolbar from '../../../../components/Tables/Toolbar'
import { DiplomasQuery, OrderByDirectionInput } from '@graphql'

type Data = DiplomasQuery['diplomas']['data']
type Diploma = Data[0]

type Props = {
    title: string
    data: Data
    page: number
    loading: boolean
    pageSize: number
    totalCount: number
    sortedColumnId: number
    onChangePage: (page: number) => void
    onSearch: (searchQuery: string) => void
    onChangeItemsPerPage: (page: number) => void
    sortedColumnDirection: OrderByDirectionInput
    handleSortOrderChange: (orderedColumnId: number, orderDirection: OrderByDirectionInput) => void
    selectedDate: DateRange<Date | null>
    handleDateChange: (date: DateRange<Date | null>) => void
}

const DiblomasTable = ({
    page,
    title,
    data,
    loading,
    pageSize,
    onSearch,
    totalCount,
    onChangePage,
    sortedColumnId,
    onChangeItemsPerPage,
    handleSortOrderChange,
    sortedColumnDirection,
    handleDateChange,
    selectedDate
}: Props) => {
    return (
        <MaterialTable
            data={data}
            page={page}
            title={title}
            columns={columns}
            icons={tableIcons}
            isLoading={loading}
            totalCount={totalCount}
            onSearchChange={onSearch}
            onChangePage={onChangePage}
            localization={tableLocalization}
            onChangeRowsPerPage={onChangeItemsPerPage}
            options={{
                actionsColumnIndex: -1,
                debounceInterval: 500,
                pageSize: pageSize,
                thirdSortClick: false,
                draggable: false
            }}
            components={{
                Header: (props) =>
                    (
                        <MTableHeader
                            {...props}
                            orderBy={sortedColumnId}
                            onOrderChange={handleSortOrderChange}
                            orderDirection={sortedColumnDirection}
                        />
                    ) as any,
                Toolbar: (props) =>
                    (
                        <Toolbar
                            title={props.title}
                            selectedDate={selectedDate}
                            searchText={props.searchText}
                            onSearchChanged={props.onSearchChanged}
                            handleDateChange={(date) => handleDateChange(date)}
                        />
                    ) as any,
                Pagination: Pagination
            }}
        />
    )
}

const columns: Column<Diploma>[] = [
    {
        field: 'code',
        sorting: false,
        title: loco.diplomas_table_id,
        headerStyle: { ...headerStyle },
        render: function render(row: Diploma) {
            return <Row text={row?.code || ''} />
        }
    },
    {
        field: 'createdAt',
        sorting: true,
        title: loco.diplomas_table_createdAt,
        headerStyle: { ...headerStyle },
        render: function render(row: Diploma) {
            return <Row text={format(new Date(row.createdAt), 'dd.MM.Y', { locale: cs })} />
        }
    },
    {
        field: 'student.user.username',
        sorting: false,
        title: loco.diplomas_table_username,
        headerStyle: { ...headerStyle },
        render: function render(row: Diploma) {
            return <Row text={getProfileName(row?.student.user)} />
        }
    },
    {
        sorting: false,
        field: 'student.user.email',
        title: loco.diplomas_table_email,
        headerStyle: { ...headerStyle },
        render: function render(row: Diploma) {
            return <Row text={row?.student.user?.email || ''} />
        }
    },
    {
        sorting: false,
        field: 'points',
        title: loco.diplomas_table_points,
        headerStyle: { ...headerStyle },
        render: function render(row: Diploma) {
            return <Row text={`${row?.points || 0}`} />
        }
    },
    {
        sorting: false,
        field: 'student.user.timeSpent',
        title: loco.diplomas_table_timeSpent,
        headerStyle: { ...headerStyle },
        render: function render(row: Diploma) {
            return <Row text={secondsToHms(row?.student.user.timeSpent || 0)} />
        }
    }
]

export default DiblomasTable
