import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;

    h6 {
        padding-bottom: 10px;
    }

    @media (max-width: 960px) {
        padding: 30px;
        border-radius: 4px;
        background-color: white;
    }
`

export const Points = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 35px;

    span {
        font-weight: 500;
    }
`

export const PointsText = styled.span`
    font-size: 36px;
    align-self: center;
    padding: 15px 10px 0 15px;
`

export const Level = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const LevelRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
