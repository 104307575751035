import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
            {...props}
        >
            <circle cx="16" cy="16" r="16" fill="#00AFEC" opacity="0.1"></circle>
            <path
                fillRule="evenodd"
                stroke="#00AFEC"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.077 11.5a1.5 1.5 0 011.5-1.5h10.846a1.5 1.5 0 011.5 1.5v9.923a1.5 1.5 0 01-1.5 1.5H10.577a1.5 1.5 0 01-1.5-1.5V11.5z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#00AFEC"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.847 9.077v1.846M20.154 9.077v1.846"
            ></path>
            <path
                fill="#00AFEC"
                d="M15.095 18.429c.008-.483.063-.864.164-1.144.101-.28.308-.59.62-.931l.798-.822c.34-.385.511-.799.511-1.24 0-.427-.112-.76-.335-.999-.223-.243-.547-.365-.973-.365-.414 0-.747.11-.998.329-.252.219-.377.513-.377.882h-1.126c.008-.657.241-1.187.7-1.588.462-.406 1.063-.609 1.8-.609.767 0 1.364.207 1.79.621.43.41.645.974.645 1.692 0 .71-.329 1.41-.986 2.099l-.663.657c-.296.329-.444.801-.444 1.418h-1.126zm-.049 1.929c0-.183.055-.335.164-.457.114-.125.28-.188.5-.188.218 0 .385.063.498.188.114.122.17.274.17.457a.643.643 0 01-.17.456c-.113.118-.28.176-.499.176-.219 0-.385-.058-.499-.176a.657.657 0 01-.164-.456z"
            ></path>
        </svg>
    )
}

export default Icon
