import { useSnackbar } from 'notistack'
import { Link, useHistory } from 'react-router-dom'
import React, { useState, useContext } from 'react'
import Typography from '@material-ui/core/Typography'

import * as loco from '@loco'
import { Routes } from '@variables'
import Logo from '../../../../../components/SVG/Logo'
import OldIcon from '../../../../../components/SVG/Old'
import StudentIcon from '../../../../../components/SVG/Student'
import WorkingIcon from '../../../../../components/SVG/Working'
import UnemployedIcon from '../../../../../components/SVG/Unemployed'
import { AuthContext } from '../../../../../context/Auth'
import { UserLifeStage, useUpdateSelfUserMutation } from '@graphql'
import {
    Wrapper,
    Left,
    Right,
    Steps,
    Step,
    Span,
    Circle,
    Illustration,
    Title,
    Subtitle,
    Close,
    Button,
    LogoWrapper,
    MobileProgress,
    MobileSteps,
    Item,
    Skip
} from '../styled'

const LifeStage = () => {
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()
    const { user } = useContext(AuthContext)
    const [selected, setSelected] = useState(user?.lifeStage?.value)

    const state = {
        total: 2,
        active: 1
    }

    const [update, { loading }] = useUpdateSelfUserMutation()

    const submit = async () => {
        if (!selected) return

        try {
            const { data, errors } = await update({
                variables: {
                    data: {
                        lifeStage: selected
                    }
                }
            })

            if (data && !errors) {
                history.push(Routes.QUESTIONNAIRE_DISTRICT)
                enqueueSnackbar(loco.notifications.update, { variant: 'success' })
            }

            errors?.forEach((err) => enqueueSnackbar(err.message, { variant: 'error' }))
        } catch (error) {
            console.error(error)
        }
    }

    const close = () => {
        history.push(Routes.HOME)
    }

    return (
        <Wrapper>
            <Left>
                <LogoWrapper>
                    <Link to={Routes.HOME}>
                        <Logo />
                    </Link>
                </LogoWrapper>

                <Steps activeStep={state.active} steps={state.total}>
                    <Step completed>
                        <Link to={Routes.QUESTIONNAIRE_EXPERIENCE}>
                            <Circle /> 01 <Span>{loco.questionnaire_exp}</Span>
                        </Link>
                    </Step>
                    <Step>
                        <Link to={Routes.QUESTIONNAIRE_LIFESTAGE}>
                            <Circle />
                            02 <Span>{loco.questionnaire_lifeStage}</Span>
                        </Link>
                    </Step>
                    <Step>
                        <Link to={Routes.QUESTIONNAIRE_DISTRICT}>
                            <Circle /> 03 <Span>{loco.questionnaire_district}</Span>
                        </Link>
                    </Step>

                    <MobileProgress />
                    <MobileSteps>
                        {state.active + 1} / {state.total + 1}
                    </MobileSteps>
                </Steps>
                <Illustration />
                <Close onClick={close} />
            </Left>

            <Right>
                <Title>{loco.questionnaire_lifeStage_title}</Title>

                <Item
                    selected={selected === UserLifeStage.STUDENT}
                    onClick={() => {
                        setSelected(UserLifeStage.STUDENT)
                    }}
                >
                    <StudentIcon />
                    <div>
                        <Typography>{loco.questionnaire_lifeStage_student_t}</Typography>
                        <Typography>{loco.questionnaire_lifeStage_student_s}</Typography>
                    </div>
                </Item>
                <Item
                    selected={selected === UserLifeStage.WORKING}
                    onClick={() => {
                        setSelected(UserLifeStage.WORKING)
                    }}
                >
                    <WorkingIcon />
                    <div>
                        <Typography>{loco.questionnaire_lifeStage_working_t}</Typography>
                        <Typography>{loco.questionnaire_lifeStage_working_s}</Typography>
                    </div>
                </Item>
                <Item
                    selected={selected === UserLifeStage.UNEMPLOYED}
                    onClick={() => {
                        setSelected(UserLifeStage.UNEMPLOYED)
                    }}
                >
                    <UnemployedIcon />
                    <div>
                        <Typography>{loco.questionnaire_lifeStage_unemp_t}</Typography>
                        <Typography>{loco.questionnaire_lifeStage_unemp_s}</Typography>
                    </div>
                </Item>
                <Item
                    selected={selected === UserLifeStage.PENSIONER}
                    onClick={() => {
                        setSelected(UserLifeStage.PENSIONER)
                    }}
                >
                    <OldIcon />
                    <div>
                        <Typography>{loco.questionnaire_lifeStage_old_t}</Typography>
                        <Typography>{loco.questionnaire_lifeStage_old_s}</Typography>
                    </div>
                </Item>

                <Button
                    size="large"
                    onClick={submit}
                    color="secondary"
                    variant="contained"
                    disabled={loading || !selected}
                >
                    {loco.common.continue}
                </Button>
                <Skip to={Routes.QUESTIONNAIRE_DISTRICT}>{loco.common.skip}</Skip>
            </Right>
        </Wrapper>
    )
}

export default LifeStage
