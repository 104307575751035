import { createTheme } from '@material-ui/core/styles'

import { colors } from '@variables'

// Configure Material UI theme
const theme = createTheme({
    typography: {
        fontFamily: ['"Montserrat"'].join(',')
    },
    palette: {
        type: 'light',
        primary: {
            main: colors.primaryButton.main,
            dark: colors.primaryButton.hover,
            contrastText: colors.primaryButton.contrastText
        },
        secondary: {
            main: colors.secondaryButton.main,
            dark: colors.secondaryButton.hover,
            contrastText: colors.secondaryButton.contrastText
        }
    },
    props: {
        MuiTypography: {
            variantMapping: {
                subtitle1: 'h2',
                subtitle2: 'h2',
                body1: 'p',
                body2: 'span'
            }
        }
    },
    overrides: {
        MuiTypography: {
            gutterBottom: {
                marginBottom: 15
            },
            h1: {
                fontSize: 32,
                fontWeight: 700,
                lineHeight: '40px',
                letterSpacing: 'normal'
            },
            h2: {
                fontSize: 28,
                fontWeight: 700,
                lineHeight: '36px',
                letterSpacing: 'normal'
            },
            h3: {
                fontSize: 24,
                fontWeight: 700,
                lineHeight: '34px',
                letterSpacing: 'normal'
            },
            h4: {
                fontSize: 22,
                fontWeight: 700,
                lineHeight: '32px',
                letterSpacing: 'normal'
            },
            h5: {
                fontSize: 20,
                fontWeight: 700,
                lineHeight: '30px',
                letterSpacing: 'normal'
            },
            h6: {
                fontSize: 14,
                fontWeight: 500,
                lineHeight: '28px',
                letterSpacing: 'normal',
                textTransform: 'uppercase'
            },
            body1: {
                fontSize: 16,
                fontWeight: 300,
                letterSpacing: 'normal'
            },
            body2: {
                fontSize: 14,
                fontWeight: 300,
                letterSpacing: 'normal'
            },
            paragraph: {
                fontSize: 14,
                fontWeight: 200,
                letterSpacing: 'normal'
            }
        },
        MuiFormLabel: {
            root: {
                fontSize: 14,
                color: 'hsla(216,15%,19%,0.5)',
                '&:placeholder': {
                    color: 'hsla(240,100%,14%,0.5) !important'
                }
            }
        },
        MuiPaper: {
            root: {
                color: 'hsla(240, 100%, 14%, 1)'
            },
            rounded: {
                borderRadius: 5,
                overflow: 'hidden'
            },
            elevation2: {
                boxShadow: '0px 10px 50px rgba(42, 48, 57, 0.05)'
            }
        },
        MuiExpansionPanelSummary: {
            content: {
                margin: '28px 0 24px 0'
            }
        },
        MuiCheckbox: {
            colorSecondary: {
                '&:hover': {
                    backgroundColor: 'rgba(55, 171, 210, 0.08)'
                },
                '&$checked': {
                    color: colors.primary.orange,
                    '&:hover': {
                        backgroundColor: 'rgba(55, 171, 210, 0.08)'
                    }
                }
            }
        },
        MuiExpansionPanelDetails: {
            root: {
                padding: 30
            }
        },
        MuiExpansionPanel: {
            root: {
                backgroundColor: '#FAFAFA'
            },
            rounded: {
                // marginBottom: 0,
                boxShadow: 'none',

                '&:before': {
                    display: 'none'
                }
                // '&$expanded': {
                //     margin: 'auto'
                // }
            }
        },
        MuiFormHelperText: {
            root: {
                fontWeight: 300
            }
        },
        MuiStepIcon: {
            root: {
                width: 10,
                height: 10,
                color: 'white',
                borderRadius: 15,
                border: '2px solid #EAEBEC'
            },
            active: {
                top: -4,
                width: 20,
                height: 20,
                border: 'none',
                position: 'relative',
                color: colors.primary.orange
            },
            completed: {
                top: 2,
                width: 10,
                height: 10,
                border: 'none',
                position: 'relative',
                color: colors.primary.orange
            },
            text: {
                display: 'none'
            }
        },
        MuiStepConnector: {
            alternativeLabel: {
                left: 'calc(-50% + 2px);',
                right: 'calc(50% + 2px);'
            }
        },
        MuiStepLabel: {
            alternativeLabel: {
                zIndex: 1
            },
            label: {
                color: '#C9CBCD',
                fontWeight: 300,
                margin: '10px 0 0 0 !important'
            }
        },
        MuiBackdrop: {
            root: {
                backgroundColor: 'rgba(4, 4, 113, 0.5)',

                '@media (max-width: 855px)': {
                    backgroundColor: 'transparent'
                }
            }
        },
        MuiIconButton: {
            edgeEnd: {
                marginRight: 0
            }
        },
        MuiSelect: {
            select: {
                '&:focus': {
                    backgroundColor: 'transparent'
                }
            }
        },
        MuiListItem: {
            button: {
                '&:hover': {
                    backgroundColor: '#F2FBFE'
                },
                '&:focus': {
                    backgroundColor: '#F2FBFE'
                }
            }
        },
        MuiDialog: {
            paperScrollPaper: {
                overflowX: 'hidden',
                '@media (max-width: 855px)': {
                    boxShadow: 'none'
                },
                '@media (min-width: 854px)': {
                    maxHeight: 'calc(100% - 40px)'
                }
            }
        },
        MuiInputBase: {
            root: {
                fontSize: 14,
                fontWeight: 300
            },
            input: {
                paddingBottom: 10
            }
        },
        MuiInputLabel: {
            root: {
                fontWeight: 300,
                '&$focused': {
                    color: '#9C9FA3'
                }
            }
        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: '1px solid #EAEAEA'
                },
                '&:hover:not(.Mui-disabled)': {
                    '&:before': {
                        borderBottom: '2px solid #EAEAEA'
                    }
                }
            }
        },
        MuiMobileStepper: {
            progress: {
                width: '100%',
                borderRadius: 5,
                backgroundColor: colors.primary.darkBlue
            }
        },
        MuiLinearProgress: {
            root: {
                borderRadius: 30
            },
            barColorPrimary: {
                backgroundColor: colors.primary.orange
            }
        },
        MuiButton: {
            root: {
                borderRadius: 5,
                '&&$disabled': {
                    cursor: 'not-allowed',
                    color: `${colors.primary.gray1}`,
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    border: `1px solid ${colors.primary.gray1}`
                }
            },
            sizeLarge: {
                padding: '12px 30px',
                fontSize: '14px !important'
            },
            sizeSmall: {
                padding: '8px 20px'
            },
            label: {
                fontWeight: 500,
                letterSpacing: 1
            },
            outlinedPrimary: {
                color: colors.primary.orange,
                backgroundColor: 'transparent',
                border: `1px solid hsla(0, 0%, 70%, 1)`,

                '&:hover': {
                    backgroundColor: 'transparent',
                    border: `1px solid ${colors.primary.orange}`
                }
            },
            outlinedSecondary: {
                color: colors.primary.red,
                backgroundColor: 'transparent',
                border: `1px solid hsla(0, 0%, 70%, 1)`,

                '&:hover': {
                    backgroundColor: 'transparent',
                    border: `1px solid ${colors.primary.red}`
                }
            },
            containedSecondary: {
                backgroundColor: colors.primary.darkBlue,

                '&:hover': {
                    backgroundColor: '#040471'
                }
            },
            contained: {
                boxShadow: 'none !important'
            }
        },
        MuiFilledInput: {
            root: {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0
            }
        },
        MuiContainer: {
            maxWidthXl: {
                maxWidth: 1235, // 1170px
                '@media (min-width: 1920px)': {
                    maxWidth: 1235
                }
            },
            maxWidthLg: {
                maxWidth: 1235, // 1170px
                '@media (min-width: 1280px)': {
                    maxWidth: 1235
                }
            }
        }
    }
})

export default theme
