import React, { ReactNode } from 'react'

import Tooltip from '../Tooltip'
import * as loco from '@loco'
import { Wrapper, Circle, IconWrapper, Inner, Outer, Middle } from './styled'

type Props = {
    icon: ReactNode
    size?: number
    style?: object
    onlyLvl?: boolean
    outerValue: number
    innerValue?: number
    middleValue?: number
}

const StatisticPreview = ({
    icon,
    onlyLvl,
    outerValue = 1,
    middleValue = 1,
    innerValue = 1,
    size = 185,
    style = {}
}: Props) => {
    if (onlyLvl) {
        return (
            <Wrapper size={size}>
                <Circle size={size} style={style}>
                    <Tooltip title={loco.tooltips.profile.lvl}>
                        <Inner variant="static" thickness={1.25} value={outerValue} />
                    </Tooltip>
                    <IconWrapper onlyOuterValue>{icon}</IconWrapper>
                </Circle>
            </Wrapper>
        )
    }

    return (
        <Wrapper size={size}>
            <Circle size={size} style={style}>
                <Tooltip title={loco.tooltips.profile.coins}>
                    <Outer variant="static" thickness={1.25} value={outerValue} />
                </Tooltip>
            </Circle>

            <Circle size={size - 20}>
                <Tooltip title={loco.tooltips.profile.lectures}>
                    <Middle variant="static" thickness={1.25} value={middleValue} />
                </Tooltip>
            </Circle>
            <Circle size={size - 40}>
                <Tooltip title={loco.tooltips.profile.lvl}>
                    <Inner variant="static" thickness={1.25} value={innerValue} />
                </Tooltip>
                <IconWrapper>{icon}</IconWrapper>
            </Circle>
        </Wrapper>
    )
}

export default StatisticPreview
