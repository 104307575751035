import { Location } from 'history'
import { useSnackbar } from 'notistack'
import React, { useContext } from 'react'
import { object, string, mixed } from 'yup'
import Button from '@material-ui/core/Button'
import { Field, Formik, FormikHelpers } from 'formik'
import { useToggle, useBeforeUnload } from 'react-use'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { FormValues } from '..'
import * as loco from '@loco'
import { Routes } from '@variables'
import { translateGQLError } from '@utils'
import Head from '../../../../../components/Head'
import { ButtonWrapper, UploadWrapper } from '../styled'
import { Box } from '../../../../../components/shared/styled'
import InputField from '../../../../../components/Fields/Input'
import UploadField from '../../../../../components/Fields/Upload'
import { AuthContext } from '../../../../../context/Auth'
import BackButton from '../../../../../components/shared/BackButton'
import RouteLeavingGuard from '../../../../../components/RouteLeavingGuard'
import { Role, AdminChaptersDocument, useCreateChapterMutation } from '@graphql'
import { Container, SmallContainer, Form } from '../../../../../components/shared/styled'

const CreateChapter = () => {
    const history = useHistory()
    const location = useLocation()
    const { enqueueSnackbar } = useSnackbar()

    const { user } = useContext(AuthContext)

    const [dirty, toggleDirty] = useToggle(false)
    // On close or reload
    useBeforeUnload(dirty, loco.admin.popup.leave.title)

    const [createChapter, { loading }] = useCreateChapterMutation()

    const submit = async (values: FormValues, { resetForm }: FormikHelpers<any>) => {
        const { name, description } = values

        const { data, errors } = await createChapter({
            refetchQueries: [{ query: AdminChaptersDocument }],
            variables: {
                data: {
                    name: name,
                    description: description,
                    icon: {
                        png: {
                            gcsId: values.png.gcsId
                        },
                        svg: {
                            gcsId: values.svg.gcsId
                        }
                    }
                }
            }
        })

        if (!errors && data) {
            toggleDirty()
            resetForm()
            history.push({
                pathname: Routes.DASHBOARD,
                search: Routes.CHAPTERS_SEARCH_PARAM
            })
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }
    }

    const handleChange = () => {
        if (!dirty) toggleDirty()
    }

    return (
        <>
            <Head title={loco.seo.chapter.create.title} />

            <RouteLeavingGuard
                when={dirty}
                title={loco.admin.popup.leave.title}
                btnText={loco.admin.popup.leave.confirm}
                description={loco.admin.popup.leave.subtitle}
                navigate={(path: string) => history.push(path)}
                shouldBlockNavigation={(newLocation: Location<any>) => {
                    if (dirty) {
                        if (location.pathname !== newLocation.pathname) {
                            return true
                        }
                    }
                    return false
                }}
            />

            <Container>
                <SmallContainer>
                    <BackButton
                        position="relative"
                        style={{
                            margin: '40px 0 20px 0',
                            fontWeight: 400,
                            fontSize: 18
                        }}
                        onClick={() => {
                            history.push(Routes.DASHBOARD)
                        }}
                    >
                        {loco.admin.create.chapter.title}
                    </BackButton>

                    <Box>
                        <Formik
                            onSubmit={submit}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                        >
                            {({ values, isValid, isSubmitting }) => (
                                <Form spellCheck={false} onChange={handleChange}>
                                    <Field
                                        fullWidth
                                        key="name"
                                        name="name"
                                        value={values.name}
                                        component={InputField}
                                        className="customInput"
                                        disabled={loading || isSubmitting}
                                        label={loco.admin.create.chapter.name.label}
                                        placeholder={loco.admin.create.chapter.name.placeholder}
                                    />
                                    <Field
                                        fullWidth
                                        multiline
                                        key="description"
                                        name="description"
                                        component={InputField}
                                        className="customInput"
                                        value={values.description}
                                        disabled={loading || isSubmitting}
                                        label={loco.admin.create.chapter.description.label}
                                        placeholder={
                                            loco.admin.create.chapter.description.placeholder
                                        }
                                    />

                                    <UploadWrapper>
                                        <UploadField
                                            name="png"
                                            previewType="img"
                                            accept="image/png"
                                            buttonText={loco.admin.create.chapter.upload}
                                            label={loco.admin.create.chapter.icon.png}
                                            disabled={loading || isSubmitting}
                                            style={{ width: 170 }}
                                        />

                                        <UploadField
                                            name="svg"
                                            buttonText={loco.admin.create.chapter.upload}
                                            label={loco.admin.create.chapter.icon.svg}
                                            disabled={loading || isSubmitting}
                                            style={{ width: 170 }}
                                            accept="image/svg+xml"
                                            previewType="img"
                                        />
                                    </UploadWrapper>

                                    <ButtonWrapper>
                                        <div style={{ marginLeft: 'auto' }}>
                                            <Link
                                                to={Routes.DASHBOARD}
                                                className={
                                                    loading || isSubmitting ? 'disabledLink' : ''
                                                }
                                            >
                                                <Button
                                                    size="large"
                                                    type="button"
                                                    color="secondary"
                                                    variant="outlined"
                                                    disabled={loading || isSubmitting}
                                                >
                                                    {loco.common.cancel}
                                                </Button>
                                            </Link>
                                            <Button
                                                size="large"
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                                style={{ marginLeft: 30 }}
                                                disabled={!isValid || loading || isSubmitting}
                                            >
                                                {user?.role === Role.SUPER_ADMIN
                                                    ? loco.common.create
                                                    : loco.common.sendToReview}
                                            </Button>
                                        </div>
                                    </ButtonWrapper>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </SmallContainer>
            </Container>
        </>
    )
}

const initialValues = {
    name: '',
    png: {
        filename: undefined,
        src: undefined,
        gcsId: undefined
    },
    svg: {
        filename: undefined,
        src: undefined,
        gcsId: undefined
    },
    description: ''
}

const validationSchema = object({
    name: string().required(loco.validation.common.required),
    description: string().required(loco.validation.common.required),
    png: mixed()
        .test('fileSize', `${loco.validation.fileLimit} 0.5MB`, (value) => {
            if (value) return value.size <= 500000
            else return true
        })
        .required(loco.validation.common.required),
    svg: mixed()
        .test('fileSize', `${loco.validation.fileLimit} 0.5MB`, (value) => {
            if (value) return value.size <= 500000
            else return true
        })
        .required(loco.validation.common.required)
})

export default CreateChapter
