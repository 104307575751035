import styled from 'styled-components'

import { media } from '@variables'

export const ButtonWrapper = styled.div`
    width: 100%;
    margin: 30px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    ${media.tablet} {
        margin-bottom: 50px;
    }
`
