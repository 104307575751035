import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="54"
            height="54"
            fill="none"
            viewBox="0 0 54 54"
            {...props}
        >
            <circle cx="27" cy="27" r="27" fill="#F59100" opacity="0.1"></circle>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M26.25 33.25h-10.5v-16.5h22.5v4.5M15.75 25.75l7.5 7.5M30.75 16.75l4.5 4.5M31.237 32.483l-.8 5.879a.751.751 0 001.269.666l2.049-2.05 2.049 2.05a.751.751 0 001.269-.666l-.8-5.88"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M33.75 33.25a4.5 4.5 0 100-9 4.5 4.5 0 000 9z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M15.75 24.25l7.5-7.5M33.75 28.375a.375.375 0 100 .75.375.375 0 000-.75"
            ></path>
        </svg>
    )
}

export default Icon
