import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import { Title1, Title3 } from '../styled'

import { colors, media } from '@variables'

export const TopTitle = styled(Title3)``
export const Title = styled(Title1)``

export const Img = styled.img``

export const Wrapper = styled.div`
    width: 100%;
    padding: 60px 0;
    color: ${colors.primary.darkBlue};
    background-color: ${colors.primary.orange};

    ${media.tablet} {
        padding: 110px 0;
    }

    ${Title} {
        margin: 20px 0 40px 0;

        ${media.tablet} {
            margin: 10px 0 60px 0;
        }
    }
    ${TopTitle} {
        @media (max-width: 853px) {
            padding-top: 30px;
        }
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    ${media.xl} {
        flex-wrap: nowrap;
    }
`

export const ItemText = styled(Typography)`
    @media (max-width: 1169px) {
        padding-right: 30px;
    }
    @media (max-width: 853px) {
        padding-right: 0px;
    }
`
export const ItemTitle = styled(Typography)``

export const Item = styled.div`
    flex: 1 0 1;
    max-width: 340px;
    margin-bottom: 45px;

    ${media.tablet} {
        flex: 1 0 50%;
        max-width: 420px;
    }

    ${media.xl} {
        flex: 1 0 25%;
        max-width: 250px;
        margin-bottom: 0px;
    }

    ${ItemTitle} {
        margin: 15px 0;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
    }

    ${ItemText} {
        font-size: 16px;
        line-height: 24px;
    }
`
