import styled from 'styled-components'
import {
    Grid as MuiGrid,
    Button as MuiButton,
    Container as MuiContainer,
    Typography as MuiTypography
} from '@material-ui/core'
import MuiQueryBuilder from '@material-ui/icons/QueryBuilder'

import { media, colors } from '@variables'
import { Box } from '../../../components/shared/styled'
import { Wrapper as ChapterWrapper } from '../../../components/Chapter/styled'

export const Column = styled(MuiGrid)``
export const Title = styled(MuiTypography)``
export const LectureColumn = styled(MuiGrid)``
export const Duration = styled(MuiTypography)``
export const LectureButton = styled(MuiButton)``
export const Description = styled(MuiTypography)``
export const QueryBuilder = styled(MuiQueryBuilder)``

export const Container = styled(MuiContainer)`
    margin-bottom: 100px;

    @media (max-width: 855px) {
        margin-bottom: 50px;
    }
`

export const Section = styled(Box)`
    margin-top: 40px;
    padding: 90px 30px 30px 30px;

    ${Title} {
        font-size: 18px;
        line-height: initial;

        ${media.custom(960)} {
            margin-bottom: 5px;
        }
    }

    ${Description} {
        opacity: 0.8;
        margin: 15px 0;

        ${media.custom(960)} {
            max-width: 90%;
            margin: 25px 0 35px 0;
        }
    }

    ${Duration} {
        display: flex;
        margin-bottom: 0;
        font-weight: 500;
        align-items: center;
        line-height: normal;
        color: ${colors.primary.darkBlue};

        @media (max-width: 959px) {
            margin-bottom: 20px;
        }
    }

    ${Column} {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    ${LectureColumn} {
        display: flex;
        padding-left: 40px;
        flex-direction: column;
        justify-content: center;
        width: calc(100% - 120px);

        @media (max-width: 600px) {
            width: 100%;
            padding-left: 0;
            margin-top: 15px;
        }
    }

    ${LectureButton} {
        width: 100%;
        padding: 12px;

        ${media.desktop} {
            max-width: 90%;
        }
    }

    ${QueryBuilder} {
        width: 20px;
        margin-right: 10px;
        color: ${colors.primary.darkBlue};
    }

    ${media.custom(960)} {
        padding: 125px 90px 30px 90px;

        ${Title} {
            font-size: 32px;
            line-height: 40px;
        }
    }
`

export const Wrapper = styled(ChapterWrapper)`
    padding: 15px 0 0 0;

    ${media.tablet} {
        padding: 30px 0 50px 0;
    }
`
