import styled from 'styled-components'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { colors, media } from '@variables'
import { RadioButtonGroup as QuestionsWrapper } from '../../../../components/Test/components/RadioGroup'

export const Text = styled(Typography)``
export const ShowMore = styled(MuiButton)``
export const SubmitButton = styled(MuiButton)``

export const Wrapper = styled.div`
    margin-top: 20px;
    border-radius: 5px;
    position: relative;
    background-color: white;
    box-shadow: 0px 30px 100px rgba(42, 48, 57, 0.05);

    ${media.tablet} {
        margin-top: 55px;
    }

    ${Text} {
        font-size: 16px;
        margin-bottom: 0;

        ${media.tablet} {
            font-size: 18px;
        }
    }

    ${ShowMore} {
        margin: 0 0 20px 0;

        ${media.tablet} {
            margin: 0 25px 0 0;
        }
    }
`

export const Row = styled.div<{ readonly isLoading?: boolean }>`
    justify-content: center;
    display: inline-flex;
    align-items: center;
    width: 100%;

    ${SubmitButton} {
        @media (max-width: 600px) {
            width: 100%;
            margin: 0 20px;
        }

        ${(props) =>
            props.isLoading &&
            `
                :disabled {
                    color: #cdcecf !important;
                    border: 1px solid #eaeaea !important;
                    background-color: rgba(0, 0, 0, 0) !important;
                }
        `}
    }
`

export const Header = styled.header<{ readonly isCorrect: boolean }>`
    color: white;
    display: flex;
    overflow: hidden;
    margin-bottom: 25px;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 20px 20px;
    background-color: ${(props) =>
        props.isCorrect ? colors.primary.orange : colors.primary.darkBlue};

    div {
        display: flex;
        padding: 40px 0;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        ${media.tablet} {
            padding: 50px 0 40px 0;
        }
    }
`

export const Img = styled.img`
    padding-top: 40px;
    max-width: 307px;
`

export const Question = styled(Typography)`
    max-width: 300px;
    font-weight: 300;
    padding: 0 25px;
    margin: 0 auto !important;
    font-size: 20px !important;
    line-height: 26px !important;

    ${media.phone} {
        max-width: 650px;
    }
`

export const RightAnswer = styled.div<{ readonly color: string }>`
    width: 100%;
    display: flex;
    padding: 30px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: ${(props) => props.color};
`

export const RadioButtonGroup = styled(QuestionsWrapper)`
    @media (max-width: 855px) {
        width: 90%;
        margin: 30px auto 0 auto;
    }
`

export const LastStepButtonWrapper = styled(Row)`
    padding: 40px 0;

    @media (max-width: 855px) {
        flex-direction: column;
    }

    ${media.tablet} {
        padding: 40px 0 75px 0;
    }

    button {
        width: 100%;
        max-width: 90%;

        ${media.tablet} {
            max-width: 280px;
        }
    }
`
