import styled from 'styled-components'
import MuiContainer from '@material-ui/core/Container'

export const Container = styled(MuiContainer)`
    margin-bottom: 130px !important;

    @media (max-width: 855px) {
        margin-bottom: 80 !important;
    }
    @media (max-width: 600px) {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
`

export const TextWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    p {
        max-width: 770px;
    }

    code {
        white-space: break-spaces;
    }
    li {
        :not(:last-of-type) {
            padding-bottom: 10px;
        }
    }
`
