import * as loco from '@loco'

export enum BasicRoutes {
    LP = '/',
    HOME = '/vzdelavani',
    TEST = '/vzdelavani/test/:id',
    LECTURE = '/vzdelavani/lekce/:id',
    CHAPTER = '/vzdelavani/kapitola/:id',
    COOKIES = '/cookies',
    TERMS = '/obchodni-podminky',
    ACCOUNT_DELETION = '/smazani-uctu',

    // Generated on backend and used in emails
    INVITE = '/pozvanka',
    NEW_PASSWORD = '/heslo',
    VERIFY_EMAIL = '/overeni-adresy',

    // Firebase Deeplinks
    DEEP_APP = '/app',
    // TODO: remove
    DEEP_RESET_PWD = '/reset-password'
}

export enum PrivateRoutes {
    PROFILE = '/vzdelavani/profil',
    LIBRARY = '/vzdelavani/knihovna',
    PROFILE_EDIT = '/vzdelavani/profil/upravit',
    DAILY_QUESTION = '/vzdelavani/otazka-dne',
    LIBRARY_TEXT = '/vzdelavani/knihovna/prepis/:id',
    LIBRARY_VIDEO = '/vzdelavani/knihovna/video/:id',
    DIPLOMA = '/vzdelavani/diplom',
    QUESTIONNAIRE_EXPERIENCE = '/dotaznik/zkusenost',
    QUESTIONNAIRE_LIFESTAGE = '/dotaznik/povolani',
    QUESTIONNAIRE_DISTRICT = '/dotaznik/okres'
}

export enum ExternalRoutes {
    ABOUT_COOKIES = 'https://aboutcookies.org/',
    RESET_PWD_PROD = 'https://investo.page.link/app',
    RESET_PWD_DEV = 'https://investobeta.page.link/app',
    PATRIA_COOKIES = 'https://www.patria.cz/cookies.html',
    // Stores
    APP_STORE = 'https://apps.apple.com/us/app/investo-patria/id1616467797',
    GOOGLE_PLAY = 'https://play.google.com/store/apps/details?id=com.patria.investo.app'
}

export const timeZone = 'Europe/Prague'

export enum AdminRoutes {
    DASHBOARD = '/admin',
    REVIEW = '/admin/schvalovani',
    ACTIVITY = '/admin/aktivity',
    SUPER_ADMIN = '/admin/sprava-uzivatelu',
    STATISTICS = '/admin/statistiky',
    CHAPTER_CREATE = '/admin/kapitola/vytvorit',
    CHAPTER_EDIT = '/admin/kapitola/upravit/:id',
    CHAPTER_REVIEW = '/admin/kapitola/schvalit/:id',
    LECTURE_CREATE = '/admin/lekce/vytvorit',
    LECTURE_EDIT = '/admin/lekce/upravit/:id',
    LECTURE_REVIEW = '/admin/lekce/schvalit/:id',
    VOCABULARY_CREATE = '/admin/slovnik/vytvorit',
    VOCABULARY_EDIT = '/admin/slovnik/upravit/:id',
    NOTIFICATION_CREATE = '/admin/oznameni/vytvorit',
    NOTIFICATION_EDIT = '/admin/oznameni/upravit/:id',
    NOTIFICATION_REVIEW = '/admin/oznameni/schvalit/:id'
}

export enum SearchParams {
    TEST_SEARCH_PARAM = 'test',
    TOKEN_SEARCH_PARAM = 'token',
    SEQ_Q_SEARCH_PARAM = 'sequrity-question',
    // ADMIN DASHBOARD PAGE
    CHAPTERS_SEARCH_PARAM = 'chapters',
    VOCABULARY_SEARCH_PARAM = 'vocabulary',
    NOTIFICATIONS_SEARCH_PARAM = 'notifications',
    // SUPER ADMIN PAGE
    ADMINS_SEARCH_PARAM = 'admins',
    DIPLOMAS_SEARCH_PARAM = 'diplomas',
    SCHOOLS_SEARCH_PARAM = 'schools',
    USERS_SEARCH_PARAM = 'users',
    STUDENTS_SEARCH_PARAM = 'students',
    INVITATIONS_SEARCH_PARAM = 'invitations',
    AMBASSADORS_SEARCH_PARAM = 'ambassadors',
    SCHOOL_USERS_SEARCH_PARAM = 'school-users',
    TEACHERS_SEARCH_PARAM = 'teachers'
}

export enum HashParams {
    APP_HASH_PARAM = 'app',
    EDU_HASH_PARAM = 'edu',
    FAQ_HASH_PARAM = 'faq',
    ABOUT_HASH_PARAM = 'about',
    INVESTO_HASH_PARAM = 'investo',
    PERSONAL_HASH_PARAM = 'personal'
}

export type RoutesType =
    | BasicRoutes
    | PrivateRoutes
    | AdminRoutes
    | SearchParams
    | HashParams
    | ExternalRoutes

export const Routes = {
    ...BasicRoutes,
    ...AdminRoutes,
    ...PrivateRoutes,
    ...SearchParams,
    ...HashParams,
    ...ExternalRoutes
}

export enum TableTitle {
    CHAPTER_AND_LECTURE = 'Kapitoly a Lekce',
    NOTIFICATION = 'Oznámení',
    MATERIALS = 'Materiály',
    VOCABULARY = 'Slovník'
}

export const colors = {
    primary: {
        black: 'hsla(216,15%,19%,1)',
        orange: 'hsla(36,100%,48%,1)',
        green: 'hsla(128, 96%, 35%, 1)',
        darkBlue: 'hsla(240, 93%, 23%, 1)',
        gray1: 'hsla(0,0%,53%,1)',
        gray2: 'hsla(220,14%,91%,1)',
        red: 'hsla(348, 100%, 68%, 1)'
    },
    primaryButton: {
        main: 'hsla(36, 100%, 48%, 1)',
        hover: 'hsla(36, 100%, 48%, 0.9)',
        contrastText: 'white'
    },
    secondaryButton: {
        main: 'hsla(240,100,14,1)',
        hover: 'hsla(240,100,14,0.9)',
        contrastText: 'white'
    }
}

export const tableLocalization = {
    header: {
        actions: ''
    },
    body: {
        emptyDataSourceMessage: loco.table.body.emptyDataSourceMessage,
        addTooltip: loco.table.body.addTooltip,
        deleteTooltip: loco.table.body.deleteTooltip,
        filterRow: {
            filterTooltip: loco.table.body.filterRow.filterTooltip
        },
        editRow: {
            deleteText: loco.table.body.editRow.deleteText,
            cancelTooltip: loco.table.body.editRow.cancelTooltip,
            saveTooltip: loco.table.body.editRow.saveTooltip
        }
    },
    grouping: {
        placeholder: loco.table.grouping.placeholder
    },
    pagination: {
        labelRowsSelect: '',
        labelRowsPerPage: loco.table.pagination.labelRowsPerPage,
        firstAriaLabel: loco.table.pagination.firstAriaLabel,
        firstTooltip: loco.table.pagination.firstTooltip,
        previousAriaLabel: loco.table.pagination.previousAriaLabel,
        previousTooltip: loco.table.pagination.previousTooltip,
        nextAriaLabel: loco.table.pagination.nextAriaLabel,
        nextTooltip: loco.table.pagination.nextTooltip,
        lastAriaLabel: loco.table.pagination.lastAriaLabel,
        lastTooltip: loco.table.pagination.lastTooltip
    },
    toolbar: {
        addRemoveColumns: loco.table.toolbar.addRemoveColumns,
        showColumnsTitle: loco.table.toolbar.showColumnsTitle,
        showColumnsAriaLabel: loco.table.toolbar.showColumnsAriaLabel,
        searchTooltip: loco.table.toolbar.searchTooltip,
        searchPlaceholder: loco.table.toolbar.searchPlaceholder
    }
}

export const transition = {
    main: `all ease-in-out 0.2s`
}

export const snackbarTheme = {
    variantInfo: 'snackbarInfo',
    variantError: 'snackbarError',
    variantSuccess: 'snackbarSuccess',
    variantWarning: 'snackbarWarning',
    root: 'snackbarRoot'
}

export const notificationMsgs = {
    update: loco.notifications.update,
    logout: loco.notifications.logout,
    passwordReset: loco.notifications.passwordReset,
    wrongUsername: loco.notifications.wrongUsername,
    adminEmail: loco.notifications.adminEmail
}

const customMediaQuery = (minWidth: number) => `@media (min-width: ${minWidth}px)`

export const media = {
    custom: customMediaQuery,
    xl: customMediaQuery(1170),
    desktop: customMediaQuery(960),
    tablet: customMediaQuery(854),
    phone: customMediaQuery(480)
}

export const isIE11 = () => {
    const userAgent = window && window.navigator.userAgent

    let isIE = /MSIE|Trident/.test(userAgent)
    if (isIE === undefined) isIE = true

    return Boolean(isIE)
}
