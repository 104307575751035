import React, { useContext } from 'react'
import Headset from '@material-ui/icons/HeadsetOutlined'

import PlayButton from '../PlayButton'
import PauseButton from '../PauseButton'
import { formatSecondsToMinutes } from '@utils'
import { AudioPlayerContext } from '../../../context/AudioPlayer'
import { Audio, AudioBoxContent, AudioBoxTitle, AudioBoxDuration } from './styled'

type Props = {
    id: string
    src: string
    playBtnColor: string
    name?: string | null
    duration?: number | null
}

const AudioBox = ({ playBtnColor, name, duration, id, src }: Props) => {
    const { playing, load, pause, file } = useContext(AudioPlayerContext)

    return (
        <Audio>
            {playing && file.id === id ? (
                <PauseButton role="button" onClick={pause} />
            ) : (
                <PlayButton
                    type="audio"
                    playBtnColor={playBtnColor}
                    onClick={() => load(id, src)}
                />
            )}

            <AudioBoxContent>
                {name && <AudioBoxTitle title={name}>{name}</AudioBoxTitle>}

                <AudioBoxDuration>
                    <Headset
                        style={{
                            color: '#92959A',
                            height: 16,
                            width: 16
                        }}
                    />
                    <span>{formatSecondsToMinutes(duration || 0)} min</span>
                </AudioBoxDuration>
            </AudioBoxContent>
        </Audio>
    )
}

export default AudioBox
