import React, { useContext } from 'react'

import * as loco from '@loco'
import { AuthContext } from '../../../context/Auth'
import { Wrapper, Row, Title, Score } from './styled'
import StatsCountryIcon from '../../SVG/StatsCountry'
import StatsDistrictIcon from '../../SVG/StatsDistrict'

const Statistics = () => {
    const { user } = useContext(AuthContext)
    const regionRank = user?.student?.progress.regionRank || 0
    const countryRank = user?.student?.progress.countryRank || 0

    return (
        <Wrapper>
            <Row>
                <StatsCountryIcon />
                <div>
                    <Title>{loco.statistics.czech}</Title>
                    <Score>Top {countryRank}%</Score>
                </div>
            </Row>
            <Row>
                <StatsDistrictIcon />
                <div>
                    <Title>{loco.statistics.district}</Title>
                    <Score>Top {regionRank}%</Score>
                </div>
            </Row>
        </Wrapper>
    )
}

export default Statistics
