import React from 'react'
import Typography from '@material-ui/core/Typography'

import Head from '../../../components/Head'
import { Header, Title, Main } from './styled'

const AccountDeletionPage = () => (
    <>
        <Head title="Smazání účtu" />

        <section>
            <Header>
                <Title variant="h1" align="center">
                    Smazání účtu
                </Title>
            </Header>
            <Main>
                <Typography variant="h3" align="left">
                    Chci smazat svůj účet
                </Typography>
                <Typography align="left">
                    Svůj účet můžete kdykoli smazat. Napište nám na{' '}
                    <a
                        style={{ color: 'black', textDecoration: 'underline' }}
                        href="mailto:investo@patria.cz"
                    >
                        investo@patria.cz
                    </a>{' '}
                    a my se vám ozveme zpět. Když smažete svůj účet, vaše osobní údaje budou trvale
                    smazány. Váš profil a uložená data budou odstraněna z databáze a nebude možné je
                    obnovit.
                </Typography>
                <Typography variant="h3" align="left">
                    Aplikace
                </Typography>
                <Typography align="left">
                    <p>investo</p>
                    <p>
                        <a
                            style={{ color: 'black', textDecoration: 'underline' }}
                            target={'_blank'}
                            rel={'noopener noreferrer'}
                            href="https://play.google.com/store/apps/details?id=com.patria.investo.app&hl=cs"
                        >
                            Google Play
                        </a>{' '}
                        (Android)
                    </p>
                    <p>
                        <a
                            style={{ color: 'black', textDecoration: 'underline' }}
                            target={'_blank'}
                            rel={'noopener noreferrer'}
                            href="https://apps.apple.com/cz/app/investo-patria/id1616467797"
                        >
                            App Store
                        </a>{' '}
                        (iOS)
                    </p>
                </Typography>
                <Typography variant="h3" align="left">
                    Vývojář
                </Typography>
                <Typography align="left">Futured</Typography>
                <Typography variant="h3" align="left">
                    Webové stránky
                </Typography>
                <Typography align="left">
                    <p>
                        <a
                            style={{ color: 'black', textDecoration: 'underline' }}
                            href="https://investo.patria.cz/"
                            target={'_blank'}
                            rel={'noopener noreferrer'}
                        >
                            investo.patria.cz
                        </a>
                    </p>
                </Typography>
            </Main>
        </section>
    </>
)

export default AccountDeletionPage
