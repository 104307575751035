import React, { useState } from 'react'

import { Wrapper } from './styled'
import * as loco from '@loco'
import Tooltip from '../../../shared/Tooltip'
import InfoDialog from '../../../Dialogs/Info'

type Props = {
    icon: string
    name: string
    description: string
    variant: 'horizontal' | 'vertical'
}

const Achievement = ({ icon, name, description, variant }: Props) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <>
            <InfoDialog
                isOpen={isOpen}
                toggleOpen={() => setIsOpen(!isOpen)}
                buttonProps={{
                    text: loco.common.close,
                    onClick: () => setIsOpen(!isOpen)
                }}
                data={[
                    {
                        icon: icon,
                        title: name,
                        description: description
                    }
                ]}
            />

            <Tooltip title={description}>
                <Wrapper onClick={() => setIsOpen(!isOpen)} variant={variant}>
                    <img src={icon} alt={name} loading="lazy" />
                </Wrapper>
            </Tooltip>
        </>
    )
}

export default Achievement
