import React from 'react'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container'

import * as loco from '@loco'
import { Routes } from '@variables'
import Head from '../../../components/Head'
import Illustration from '../../../components/SVG/404'
import { Section, Title, Text, Button } from './styled'

const ErrorPage = () => (
    <>
        <Head title={loco.seo.error.title} />

        <Container>
            <Section>
                <Illustration />

                <Title variant="h1" align="center">
                    {loco['error-page'].title}
                </Title>
                <Text paragraph align="center">
                    {loco['error-page'].subtitle}
                </Text>
                <Link to={Routes.HOME}>
                    <Button size="large" color="primary" variant="contained">
                        {loco['error-page'].button}
                    </Button>
                </Link>
            </Section>
        </Container>
    </>
)

export default ErrorPage
