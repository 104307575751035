import { useSnackbar } from 'notistack'
import { Edit } from '@material-ui/icons'
import React, { forwardRef } from 'react'
import { useHistory } from 'react-router-dom'
import MaterialTable, { MTableHeader } from 'material-table'

import * as loco from '@loco'
import Pagination from '../../Pagination'
import { getRouteWithId, translateGQLError } from '@utils'
import { tableIcons, notificationColumns as columns } from '../../../shared'
import { tableLocalization, Routes, notificationMsgs } from '@variables'
import {
    OrderByDirectionInput,
    CustomNotificationsQuery,
    CustomNotificationsDocument,
    useDeleteCustomNotificationMutation,
    BatchCustomNotificationsOrderByColumnInput
} from '@graphql'

type Notification = CustomNotificationsQuery['customNotifications']['data'][0]

type Props = {
    page: number
    title: string
    loading: boolean
    pageSize: number
    totalCount: number
    searchQuery: string
    data: Array<Notification>
    onChangePage: (page: number) => void
    onChangeItemsPerPage: (page: number) => void
    onSearchChange: (searchQuery: string) => void
    sortedColumnDirection: OrderByDirectionInput
    sortedColumnId: BatchCustomNotificationsOrderByColumnInput
    handleSortOrderChange: (orderedColumnId: number, orderDirection: OrderByDirectionInput) => void
}

const NotificationTable = ({
    page,
    data,
    title,
    loading,
    pageSize,
    totalCount,
    searchQuery,
    onChangePage,
    sortedColumnId,
    onSearchChange,
    onChangeItemsPerPage,
    handleSortOrderChange,
    sortedColumnDirection
}: Props) => {
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()

    const [deleteNotification, { loading: deleteLoading }] = useDeleteCustomNotificationMutation({
        refetchQueries: [
            {
                query: CustomNotificationsDocument,
                variables: {
                    orderBy: {
                        column: sortedColumnId,
                        direction: sortedColumnDirection
                    },
                    filter: { search: searchQuery },
                    itemsPerPage: 10,
                    page: 0
                }
            }
        ]
    })

    const onDelete = async (row: Notification) => {
        const { data, errors } = await deleteNotification({
            variables: { where: { id: row.id } }
        })

        if (data && !errors) {
            enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }
    }

    return (
        <MaterialTable
            data={data}
            page={page}
            title={title}
            columns={columns}
            icons={tableIcons}
            totalCount={totalCount}
            onChangePage={onChangePage}
            onSearchChange={onSearchChange}
            localization={tableLocalization}
            isLoading={loading || deleteLoading}
            editable={{ onRowDelete: onDelete }}
            onChangeRowsPerPage={onChangeItemsPerPage}
            components={{
                Header: (props) =>
                    (
                        <MTableHeader
                            {...props}
                            onOrderChange={handleSortOrderChange}
                            orderDirection={sortedColumnDirection}
                            orderBy={
                                sortedColumnId === BatchCustomNotificationsOrderByColumnInput.title
                                    ? 0
                                    : sortedColumnId ===
                                      BatchCustomNotificationsOrderByColumnInput.author
                                    ? 1
                                    : 2
                            }
                        />
                    ) as any,
                Pagination: Pagination
            }}
            options={{
                actionsColumnIndex: -1,
                debounceInterval: 500,
                pageSize: pageSize,
                thirdSortClick: false,
                draggable: false
            }}
            actions={[
                (editRow: Notification) => ({
                    tooltip: loco.common.edit,
                    icon: forwardRef(function Icon(props, ref: any) {
                        return <Edit style={{ color: '#666666' }} ref={ref} {...props} />
                    }) as any,
                    onClick: (e) => {
                        return history.push(getRouteWithId(Routes.NOTIFICATION_EDIT, editRow.id))
                    }
                })
            ]}
        />
    )
}

export default NotificationTable
