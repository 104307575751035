import { isString } from 'lodash'
import { useMedia } from 'react-use'
import React, { useState } from 'react'
import { Button, Typography, Dialog } from '@material-ui/core'
import PrevArrow from '@material-ui/icons/ArrowBackIosRounded'
import NextArrow from '@material-ui/icons/ArrowForwardIosRounded'

import Close from '../../shared/Close'
import { DialogContent } from '../../shared/styled'
import { IconWrapper, ButtonWrapper, Item, Prev, Next } from './styled'

type DataItem = {
    title: string
    description?: string
    icon?: React.ReactNode | string
}

type Props = {
    isOpen: boolean
    toggleOpen: () => void
    data: Array<DataItem>
    buttonProps?: {
        text: string
        onClick: () => void
        isSubmitting?: boolean
    }
}

const InfoDialog = ({ data, isOpen, toggleOpen, buttonProps }: Props) => {
    const isWide = useMedia('(min-width: 854px)')

    const [currentIdx, setCurrentIdx] = useState(0)

    const prev = () => {
        if (currentIdx > 0) setCurrentIdx(currentIdx - 1)
    }
    const next = () => {
        if (currentIdx < data.length - 1) setCurrentIdx(currentIdx + 1)
    }

    return (
        <Dialog
            open={isOpen}
            onClose={toggleOpen}
            fullScreen={!isWide}
            aria-labelledby="info-dialog"
            PaperProps={{
                style: {
                    justifyContent: 'center'
                }
            }}
        >
            <DialogContent>
                <Close onClick={toggleOpen} />

                {data.length > 1 && (
                    <>
                        <Prev onClick={prev} disabled={currentIdx === 0}>
                            <PrevArrow />
                        </Prev>
                        <Next onClick={next} disabled={currentIdx == data.length - 1}>
                            <NextArrow />
                        </Next>
                    </>
                )}

                {data.map((item: DataItem, idx: number) => (
                    <Item
                        visible={currentIdx === idx}
                        key={`${item.title}-${idx}`}
                        style={buttonProps ? {} : { marginBottom: 50 }}
                    >
                        {item.icon && (
                            <IconWrapper>
                                {isString(item.icon) ? (
                                    <img src={item.icon} alt={item.title} loading="lazy" />
                                ) : (
                                    item.icon
                                )}
                            </IconWrapper>
                        )}

                        <Typography
                            variant="h3"
                            align="center"
                            style={{ width: '100%', margin: '15px 0' }}
                        >
                            {item.title}
                        </Typography>

                        {item?.description && (
                            <Typography
                                paragraph
                                align="center"
                                style={{ margin: 0, width: '100%', fontSize: 14 }}
                            >
                                {item.description}
                            </Typography>
                        )}
                    </Item>
                ))}

                {buttonProps && (
                    <ButtonWrapper>
                        <Button
                            fullWidth
                            size="large"
                            color="primary"
                            variant="contained"
                            onClick={buttonProps.onClick}
                            disabled={Boolean(buttonProps.isSubmitting)}
                        >
                            {buttonProps.text}
                        </Button>
                    </ButtonWrapper>
                )}
            </DialogContent>
        </Dialog>
    )
}

export default InfoDialog
