import Typography from '@material-ui/core/Typography'
import { Link, useHistory } from 'react-router-dom'
import React, { useState, useContext } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import Scrollbar from 'react-scrollbars-custom'
import { useSnackbar } from 'notistack'

import * as loco from '@loco'
import { Routes } from '@variables'
import Logo from '../../../../../components/SVG/Logo'
import { AuthContext } from '../../../../../context/Auth'
import { useDistrictsQuery, useUpdateSelfUserMutation, District } from '@graphql'
import {
    Wrapper,
    Left,
    Right,
    Steps,
    Step,
    Span,
    Circle,
    SearchFieldWrapper,
    Illustration,
    Title,
    Subtitle,
    Close,
    Button,
    Items,
    SearchField,
    LogoWrapper,
    MobileProgress,
    MobileSteps,
    Item,
    Skip
} from '../styled'

const District = () => {
    const history = useHistory()
    const { user } = useContext(AuthContext)
    const { enqueueSnackbar } = useSnackbar()

    const [searchQuery, setSearchQuery] = useState('')
    const [selected, setSelected] = useState(user?.district?.id)

    const { data: districtsData } = useDistrictsQuery()
    const districts = districtsData?.districts || []

    const state = {
        total: 2,
        active: 2
    }

    const [update, { loading }] = useUpdateSelfUserMutation()

    const submit = async () => {
        if (!selected) return

        try {
            const { data, errors } = await update({
                variables: {
                    data: {
                        district: {
                            id: selected
                        }
                    }
                }
            })

            if (data && !errors) {
                history.push(Routes.HOME)
                enqueueSnackbar(loco.notifications.update, { variant: 'success' })
            }

            errors?.forEach((err) => enqueueSnackbar(err.message, { variant: 'error' }))
        } catch (error) {
            console.error(error)
        }
    }

    const close = () => {
        history.push(Routes.HOME)
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 0) {
            setSearchQuery(e.target.value)
        } else {
            setSearchQuery(e.target.value)
        }
    }

    const filterDistricts = (d: District) => {
        if (!searchQuery) return true
        return d.name
            .toLocaleLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(
                searchQuery
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
            )
    }

    return (
        <Wrapper>
            <Left>
                <LogoWrapper>
                    <Link to={Routes.HOME}>
                        <Logo />
                    </Link>
                </LogoWrapper>

                <Steps activeStep={state.active} steps={state.total}>
                    <Step completed>
                        <Link to={Routes.QUESTIONNAIRE_EXPERIENCE}>
                            <Circle /> 01 <Span>{loco.questionnaire_exp}</Span>
                        </Link>
                    </Step>
                    <Step completed>
                        <Link to={Routes.QUESTIONNAIRE_LIFESTAGE}>
                            <Circle />
                            02 <Span>{loco.questionnaire_lifeStage}</Span>
                        </Link>
                    </Step>
                    <Step>
                        <Link to={Routes.QUESTIONNAIRE_DISTRICT}>
                            <Circle style={{ bottom: 0 }} /> 03{' '}
                            <Span>{loco.questionnaire_district}</Span>
                        </Link>
                    </Step>

                    <MobileProgress />
                    <MobileSteps>
                        {state.active + 1} / {state.total + 1}
                    </MobileSteps>
                </Steps>
                <Illustration />
                <Close onClick={close} />
            </Left>

            <Right>
                <Title>{loco.questionnaire_district_title}</Title>

                {/* Search input */}
                <SearchFieldWrapper>
                    <SearchIcon
                        style={{
                            position: 'absolute',
                            color: '#C0C0C0',
                            left: 30,
                            top: 13
                        }}
                    />
                    <SearchField
                        // @ts-ignore
                        minLength={1}
                        value={searchQuery}
                        debounceTimeout={250}
                        onChange={handleSearch}
                        placeholder={loco.library.search.placeholder}
                    />
                </SearchFieldWrapper>

                <Scrollbar
                    noScrollX
                    className="questionnaireScrollbar"
                    thumbYProps={{ className: 'thumbY' }}
                    trackYProps={{ className: 'trackY' }}
                    style={{
                        height: 390,
                        maxWidth: 670,
                        marginBottom: 10
                    }}
                >
                    <Items>
                        {districts.filter(filterDistricts).map((d) => (
                            <Item
                                key={d.id}
                                selected={d.id === selected}
                                onClick={() => setSelected(d.id)}
                            >
                                <Typography>{d.name}</Typography>
                            </Item>
                        ))}
                    </Items>
                </Scrollbar>

                <Button
                    size="large"
                    onClick={submit}
                    color="secondary"
                    variant="contained"
                    disabled={loading || !selected}
                >
                    {loco.common.continue}
                </Button>
                <Skip to={Routes.HOME}>{loco.common.skip}</Skip>
            </Right>
        </Wrapper>
    )
}

export default District
