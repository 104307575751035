import styled from 'styled-components'
import MuiButton from '@material-ui/core/Button'
import Icon from '@material-ui/icons/CloseRounded'

import { colors, media } from '@variables'

export const CloseRounded = styled(Icon)``
export const Button = styled(MuiButton)`
    position: relative;
    overflow: hidden;
`

export const Wrapper = styled.div<{ readonly isLoading?: boolean }>`
    ${(props) =>
        props.isLoading &&
        `
        ${Button} {
            :disabled {
                color: #cdcecf !important;
                border: 1px solid #eaeaea !important;
                background-color: rgba(0, 0, 0, 0) !important;
            }
        }
    `}
`

export const IconPreview = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    position: relative;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    border: 1px solid #e0e0e0;

    ${CloseRounded} {
        color: ${colors.primary.orange};
        position: absolute;
        cursor: pointer;
        z-index: 2;
        right: 5px;
        top: 5px;
    }

    img {
        width: 50px;
        height: auto;
        max-width: 50px;
        max-height: 50px;
    }
`
