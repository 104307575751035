import React from 'react'
import Container from '@material-ui/core/Container'
import { Link, useLocation } from 'react-router-dom'

import GooglePlay from '../SVG/GooglePlay'
import { Routes } from '@variables'
import AppleStore from '../SVG/Apple'
import * as loco from '@loco'
import Logo from '../SVG/Patria'
import {
    Top,
    Title,
    LogoWrapper,
    BottomText,
    Mail,
    MailLink,
    Bottom,
    Wrapper,
    MarketLink,
    TopWrapper,
    BottomWrapper,
    MarketWrapper,
    Links
} from './styled'

const Footer = () => {
    const location = useLocation()

    if (
        location.pathname.includes(Routes.DIPLOMA) ||
        location.pathname.includes(Routes.DAILY_QUESTION) ||
        location.pathname.includes(Routes.TEST.replace('/:id', '/')) ||
        location.pathname.includes(Routes.COOKIES) ||
        location.pathname.includes(Routes.TERMS) ||
        location.pathname.includes('dotaznik')
    ) {
        return null
    }

    return (
        <Wrapper>
            <Top>
                <Container>
                    <TopWrapper>
                        <Link to={Routes.HOME}>
                            <LogoWrapper>
                                <Logo />
                            </LogoWrapper>
                        </Link>

                        <div>
                            <Title variant="body1">{loco.footer.info}</Title>
                            <MailLink
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`mailto:${loco.footer.supportEmail}`}
                            >
                                <Mail />
                                {loco.footer.supportEmail}
                            </MailLink>
                        </div>

                        <div>
                            <Title variant="body1">{loco.footer.subtitle}</Title>
                            <MarketWrapper>
                                <MarketLink href={Routes.GOOGLE_PLAY}>
                                    <GooglePlay />
                                </MarketLink>
                                <MarketLink
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={Routes.APP_STORE}
                                >
                                    <AppleStore />
                                </MarketLink>
                            </MarketWrapper>
                        </div>
                    </TopWrapper>
                </Container>
            </Top>

            <Bottom>
                <Container>
                    <BottomWrapper>
                        <BottomText variant="body2">
                            {new Date().getFullYear()} {loco.footer.copyright}
                        </BottomText>
                        <Links>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={Routes.PATRIA_COOKIES}
                            >
                                Informace o cookies
                            </a>
                            <Link to={Routes.TERMS}>
                                <BottomText variant="body2">{loco.footer.terms}</BottomText>
                            </Link>
                        </Links>
                    </BottomWrapper>
                    <p>
                        Informace uveřejněné na stránkách investo.patria.cz nejsou analýzou
                        investičních příležitostí ani investičním doporučením. Hodnota, cena či
                        příjem z investičních nástrojů se může měnit anebo být ovlivněna pohybem
                        směnných kurzů. V důsledku těchto změn může být hodnota investice do
                        investičních nástrojů znehodnocena. Návratnost investice není vždy jistá,
                        každá investice obnáší riziko. Vysoký výnos představuje vyšší riziko, nízký
                        výnos není vždy zárukou nižšího rizika. Úspěšné investice v minulosti
                        neindikují ani nezaručují příznivé výsledky do budoucna.
                    </p>
                </Container>
            </Bottom>
        </Wrapper>
    )
}

export default Footer
