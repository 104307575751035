import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import { Title3, Title1 } from '../styled'
import { colors, media } from '@variables'

export const Title = styled(Title1)``
export const TopTitle = styled(Title3)``
export const Text = styled(Typography)``

export const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const Items = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${media.desktop} {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
`

export const Item = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    ${media.phone} {
        max-width: 420px;
    }
    ${media.tablet} {
        max-width: 520px;
    }

    ${media.desktop} {
        max-width: 320px;
        margin-bottom: 0px;
    }

    h3 {
        margin: 10px 0;
        font-size: 20px;
        font-weight: normal;

        ${media.tablet} {
            margin: 16px 0;
            font-size: 24px;
        }
    }
    p {
        line-height: 26px;
        font-weight: 200;
    }
`

export const Img = styled.img`
    width: 180px;
    height: auto;

    ${media.desktop} {
        width: 240px;
    }
`

export const Top = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;

    ${media.desktop} {
        flex-wrap: wrap;
        margin-bottom: 80px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    ${Text} {
        max-width: 580px;
        font-size: 18px;
        line-height: 30px;
        margin: 0;
    }
    ${TopTitle} {
        padding-bottom: 20px;
    }
    ${Title} {
        margin-bottom: 30px;
        color: ${colors.primary.orange};

        ${media.desktop} {
            max-width: 450px;
            margin-bottom: 0;
        }
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${media.desktop} {
        flex-direction: row;
        justify-content: space-between;
    }
`

export const Wrapper = styled.div`
    width: 100%;
    color: white;
    text-align: left;
    padding: 70px 0 90px 0;
    background-color: ${colors.primary.darkBlue};

    ${media.desktop} {
        padding: 140px 0;
    }
`
