import styled, { css } from 'styled-components'
import { Form as FormikForm } from 'formik'
import MuiGrid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import MuiContainer from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import { media, colors, transition } from '@variables'

export const Form = styled(FormikForm)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

export const Container = styled(MuiContainer)`
    margin-bottom: 50px !important;

    ${media.tablet} {
        margin-bottom: 100px !important;
    }
`

export const AdminButton = styled(MuiButton)`
    width: 160px !important;
    margin: 0 15px !important;

    @media (max-width: 1000px) {
        width: 140px !important;
        margin: 0 10px !important;
    }
`

export const TermsLink = styled.a`
    padding-left: 5px;
    text-decoration: underline;
    transition: ${transition.main};

    ${media.tablet} {
        :hover {
            text-decoration: none;
        }
    }
`

export const StartButton = styled.button`
    border: none;
    outline: none;
    cursor: pointer;
    padding: 7px 10px;
    border-radius: 5px;
    letter-spacing: 1.25px;
    text-decoration: none;
    text-transform: uppercase;
    transition: ${transition.main};
    color: ${colors.primary.orange};
    background-color: hsla(36, 100%, 48%, 0.2);

    ${media.tablet} {
        :hover {
            color: white;
            background-color: ${colors.primary.orange};
        }
    }

    a,
    :disabled {
        cursor: not-allowed;
    }

    :disabled {
        color: #0070b3;
        background-color: #edf6f9;
    }

    a {
        color: inherit;
    }
`

export const BlurWrapper = styled.div`
    position: relative;
    overflow: hidden;
`

export const Blur = styled.div`
    z-index: 2;
    cursor: not-allowed;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: -moz-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(250, 250, 250, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(250, 250, 250, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(250, 250, 250, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#fafafa',GradientType=0 ); /* IE6-9 */

    /* Blur rule only for IE10+ */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0.5) 0%,
            rgba(250, 250, 250, 1) 100%
        );

        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0.05);

        :before {
            transform: scale(0.05);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            content: '';
            background-image: inherit;
            background-size: contain;
            background-repeat: inherit;
        }
    }
`

export const Box = styled.div`
    width: 100%;
    padding: 30px;
    display: flex;
    border-radius: 4px;
    position: relative;
    align-items: center;
    background-color: white;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 12px 60px rgba(42, 48, 57, 0.11);
`

export const TopButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
`

export const Tabs = styled.div`
    width: 100%;
    display: flex;
    padding: 50px 0;
    align-items: center;
    justify-content: center;
`

export const Tab = styled.button<{ readonly active?: boolean }>`
    border: none;
    height: 50px;
    width: 165px;
    outline: none;
    font-size: 18px;
    cursor: pointer;
    line-height: 28px;
    text-align: center;
    background-color: transparent;
    transition: ${transition.main};
    font-weight: ${(props) => (props.active ? '700' : '400')};
    color: ${(props) => (props.active ? colors.primary.darkBlue : '#c0c0c0')};
    border-bottom: ${(props) =>
        props.active ? `2px solid ${colors.primary.darkBlue}` : '2px solid #D3D5D8'};
`

export const Label = styled.p`
    width: 100%;
    font-size: 12px;
    text-align: left;
    line-height: 14px;
    margin-top: 30px;
    color: ${colors.primary.gray1};
`

export const EditorLabel = styled.p`
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    text-align: left;
    width: 100%;
    margin: 25px 0 10px 0;
`

export const ErrorMsg = styled.p`
    margin: 0;
    font-size: 0.75rem;
    margin-top: 8px;
    min-height: 1em;
    text-align: left;
    font-weight: 400;
    line-height: 1em;
    letter-spacing: 0.03333em;
    color: ${colors.primary.orange};
`

export const PreviewLink = styled.a`
    margin: 0;
    margin-top: 8px;
    min-height: 1em;
    text-align: left;
    font-weight: 400;
    line-height: 1em;
    font-size: 0.75rem;
    letter-spacing: 0.03333em;
    color: ${colors.primary.orange};
    transition: ${transition.main};

    ${media.tablet} {
        :hover {
            text-decoration: underline;
        }
    }
`

export const AccessibleButton = styled.button`
    /* Reset */
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    outline: 0; /* We disable the focus ring for mouse, touch and keyboard users. */
    font: inherit;
    overflow: visible;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    line-height: normal;
    text-decoration: none;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    background: transparent;

    ::-moz-focus-inner {
        border-style: none; /* Remove Firefox dotted outline. */
    }
    :disabled {
        pointer-events: none; /* Disable link interactions */
        cursor: default;
    }
`

export const SmallContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    max-width: 710px;
`

export const TipLink = styled.p`
    opacity: 0.2;
    font-size: 10px;
    cursor: pointer;
    font-weight: 700;
    margin-bottom: 0;
    margin-bottom: 0;
    letter-spacing: 1px;
    text-decoration: none;
    color: ${colors.primary.darkBlue};

    ${media.tablet} {
        &:hover {
            text-decoration: underline;
        }
    }
`

export const DialogContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0 auto;
    min-width: 310px;
    max-width: 470px;
    position: relative;
    align-items: center;
    flex-direction: column;
    padding: 55px 30px 0px 30px;

    ${media.tablet} {
        padding: 55px 55px 0px 55px;
    }

    ${media.phone} {
        min-width: 470px;
    }
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const WrapperForHeader = styled.div<{ readonly route: string }>`
    width: 100%;
    height: inherit;
    min-height: inherit;
    padding: 90px 0 0 0;

    ${(props) =>
        props.route.includes('dotaznik') &&
        css`
            padding: 0px;
        `}
`

export const ToggleWrapper = styled.div`
    position: absolute;
    top: 20px;
    right: 30px;
    color: ${colors.primary.gray2};
`
export const ToggleItem = styled.span<{ readonly isActive: boolean }>`
    font-size: 14px;
    cursor: pointer;
    text-transform: uppercase;

    ${(props) => props.isActive && ` color: ${colors.primary.orange}; `}

    ${media.tablet} {
        :hover {
            text-decoration: underline;
        }
    }
`

export const Title = styled(Typography)`
    padding: 35px 0 20px 0;
`

export const Right = styled(MuiGrid)`
    ${media.xl} {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
`

export const Left = styled(MuiGrid)`
    padding-bottom: 0px !important;

    ${media.desktop} {
        padding-bottom: 90px !important;
    }
`

export const Aside = styled.aside`
    margin-bottom: 50px;
    position: relative;
    width: 100%;
    height: 100%;

    ${media.desktop} {
        margin-bottom: 0;
        background-color: white;
        padding: 40px 0 40px 40px;

        :after {
            position: absolute;
            top: 0;
            content: '';
            z-index: -1;
            height: 100%;
            right: -100vw;
            width: 100vw;
            display: block;
            background-color: white;
        }
    }
`

export const AsideInner = styled.div`
    width: 100%;
`

export const HelpIconWrapper = styled.div`
    width: 22px;
    height: 22px;
    color: #c0c0c0;
    margin-left: 10px;

    svg {
        height: auto;
        max-width: 100%;
    }
`

export const TitleWrapper = styled.div`
    padding: 35px 0 20px 0;
    align-items: center;
    display: flex;

    h6 {
        padding: 0;
    }
`
