import React from 'react'

import { Props } from '.'

function Icon({ color }: Props) {
    return (
        <svg
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M5.25 6.75a3 3 0 100-6 3 3 0 000 6zM7.5 23.25l.75-7.5h1.5v-3a4.5 4.5 0 10-9 0v3h1.5l.75 7.5h4.5z"
                clipRule="evenodd"
            ></path>
            <path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M23.25 6.75a6 6 0 11-6-6M23.25 2.249l-5.47 5.47a.75.75 0 01-1.06 0L15 6"
            ></path>
        </svg>
    )
}

export default Icon
