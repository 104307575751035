import React from 'react'
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'

import { Wrapper } from './styled'
import Arrow from '../../shared/Arrow'

type Props = {
    title: string
    description: string
}

const ExpansionLibrary = ({ title, description }: Props) => (
    <Wrapper>
        <Accordion>
            <AccordionSummary
                aria-controls="panel1a-content"
                expandIcon={<Arrow variant="bottom" />}
            >
                <Typography variant="body1">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails
                style={{
                    backgroundColor: 'white',
                    border: '5px solid #FAFAFA'
                }}
            >
                <Typography paragraph style={{ margin: 0 }}>
                    {description}
                </Typography>
            </AccordionDetails>
        </Accordion>
    </Wrapper>
)

export default ExpansionLibrary
