import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import { colors, media } from '@variables'

export const Title = styled(Typography)``

export const Header = styled.header`
    width: 100%;
    height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.primary.orange};

    ${Title} {
        color: white;
        letter-spacing: 1px;

        ${media.desktop} {
            font-size: 54px;
        }
    }
`

export const Main = styled.section`
    width: 100%;
    padding: 0 15px;
    max-width: 810px;
    margin: 50px auto 120px auto;

    a {
        ${media.tablet} {
            :hover {
                text-decoration: underline;
            }
        }
    }

    h5 {
        font-size: 18px;
        margin: 30px 0;
    }
    h6 {
        font-size: 14px;
    }

    h6,
    p {
        margin-bottom: 15px;
    }
    p {
        line-height: 22px;
    }
`
