import React from 'react'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'

import { Button } from './styled'

type Props = {
    type?: 'button' | 'submit'
    position?: 'relative' | 'absolute'
    children?: React.ReactNode
    onClick?: (e: React.ChangeEvent<{}>) => void
    className?: string
    style?: object
}

const BackButton = ({
    position = 'absolute',
    type = 'button',
    className = '',
    onClick,
    children,
    style = {}
}: Props) => (
    <Button type={type} onClick={onClick} position={position} className={className} style={style}>
        <ArrowBackIos />
        {children}
    </Button>
)

export default BackButton
