import React from 'react'
import { Pause } from '@material-ui/icons'

import { Wrapper } from './styled'
import { colors } from '@variables'

type Props = {
    role?: string
    onClick: () => void
}

const PauseButton = ({ onClick, role }: Props) => (
    <Wrapper onClick={onClick} role={role || ''}>
        <Pause style={{ color: colors.primary.orange }} />
    </Wrapper>
)

export default PauseButton
