import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="154"
            height="130"
            fill="none"
            viewBox="0 0 154 130"
            {...props}
        >
            <g opacity="0.3">
                <path
                    fill="#fff"
                    d="M152.535.497v129.006H.497V.497h152.038zm.498-.497H0v130h153.033V0z"
                    opacity="0.3"
                ></path>
                <g opacity="0.3">
                    <path
                        fill="#fff"
                        d="M37.484 0h-.465v130h.465V0zM56.956 0h-.465v130h.465V0z"
                        opacity="0.3"
                    ></path>
                    <path
                        fill="#fff"
                        d="M37.484 0h-.465v130h.465V0zM18.012 0h-.465v130h.465V0zM76.428 0h-.465v130h.465V0zM95.9 0h-.465v130h.465V0zM115.131 0h-.465v130h.465V0zM134.363 0h-.465v130h.465V0z"
                        opacity="0.3"
                    ></path>
                </g>
            </g>
            <path
                fill="#fff"
                d="M10.297 60.87a.658.658 0 100-1.315.658.658 0 000 1.315zM85.891 108.299a.658.658 0 100-1.316.658.658 0 000 1.316zM131.636 4.507a.658.658 0 100-1.316.658.658 0 000 1.316zM85.25 24.83a.658.658 0 100-1.316.658.658 0 000 1.315zM10.955 14.083a.658.658 0 100-1.316.658.658 0 000 1.316zM147.21 44.75a.61.61 0 100-1.22.61.61 0 000 1.22zM31.79 82.41a.61.61 0 100-1.218.61.61 0 000 1.219z"
            ></path>
            <path
                fill="#FDC068"
                d="M97.6 72.595l-1.876 2.967c-1.315 2.085-4.074 2.695-6.16 1.38a4.474 4.474 0 01-1.7-5.566l1.733-3.93 8.003 5.149z"
            ></path>
            <path
                fill="#6B9C83"
                d="M89.597 67.446l7.474-17.002-.593 1.652 4.828-23.193a6.156 6.156 0 017.298-4.78c3.336.706 5.485 3.962 4.779 7.298l-4.811 23.177-.081.37a4.283 4.283 0 01-.513 1.282L97.617 72.596l-8.02-5.149z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M87.544 78.962a1.65 1.65 0 01-1.315-.673 1.595 1.595 0 01.385-2.23l13.521-9.527a1.594 1.594 0 012.229.385 1.594 1.594 0 01-.385 2.23l-13.52 9.527a1.584 1.584 0 01-.915.288z"
            ></path>
            <path
                fill="#F5BE6D"
                d="M24.589 123.36a1.65 1.65 0 01-1.316-.674 1.594 1.594 0 01.386-2.229l62.954-44.398a1.595 1.595 0 012.23.385 1.595 1.595 0 01-.385 2.23L25.519 123.07c-.289.192-.61.289-.93.289z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M69.435 117.585H62.17c-.48 0-.93-.208-1.235-.577l-11.083-13.184c-.578-.674-.481-1.685.192-2.262.674-.577 1.685-.481 2.262.193l10.602 12.607h4.908V90.174c0-.882.722-1.604 1.604-1.604.882 0 1.604.722 1.604 1.604v25.808a1.584 1.584 0 01-1.588 1.603z"
            ></path>
            <path
                fill="#6B9C83"
                d="M101.674 44.542c-.625-6.224 3.69-6.368 1.62-10.378-2.069-4.01-.753-10.057 4.026-10.538 3.689-.369 3.914-.545 5.47-.706 4.908-.497 8.789 4.17 7.859 9.015 0 0-.272 1.106-.096 2.502.176 1.395 3.833 3.128 4.266 7.41l-23.145 2.695z"
            ></path>
            <path
                fill="#fff"
                d="M115.87 23.321l-5.341 10.827h-3.497l-1.187-10.169s1.139-.481 2.791-.674c1.652-.192 5.1-.4 5.1-.4s.834-.21 2.134.416z"
            ></path>
            <path
                fill="#000046"
                d="M119.494 48.343l-11.42 37.292.369-2.021-2.726 42.713-11.966-.77c-.561-3.609-.914-7.202-1.267-10.811a347.576 347.576 0 01-.818-10.778c-.096-1.796-.209-3.593-.273-5.373l-.112-2.695-.096-2.695a478.228 478.228 0 01-.24-10.746v-.401l.368-1.604a371.263 371.263 0 012.326-9.48c.834-3.143 1.684-6.287 2.614-9.398.93-3.112 5.422-17.083 5.422-17.083l17.819 3.85z"
            ></path>
            <path
                fill="#000020"
                d="M110.319 57.229c-3.304 5.774 2.342 13.746-.545 22.856l3.737-11.837-3.192-11.02z"
            ></path>
            <path
                fill="#000046"
                d="M126.6 42.873l4.715 39.233-.721-2.662 16.248 36.04-10.923 4.924c-2.069-2.678-3.962-5.437-5.855-8.196a277.364 277.364 0 01-5.437-8.388c-.866-1.412-1.748-2.823-2.599-4.25l-1.283-2.134-1.251-2.15a384.185 384.185 0 01-4.892-8.629 4.363 4.363 0 01-.465-1.299l-.241-1.331a296.646 296.646 0 01-1.539-9.046 245.283 245.283 0 01-1.251-9.079 192.841 192.841 0 01-.899-9.11l-.16-2.294c-.032-.77-.096-1.524-.112-2.294l-.097-2.293-.016-1.155-.016-.577v-.578l16.794-4.731z"
            ></path>
            <path
                fill="#000046"
                d="M124.804 41.847c.32 3.288-2.615 8.55-8.325 9.159l-4.619.352c-5.213.562-9.864-3.656-10.217-7.201l23.161-2.31z"
            ></path>
            <path
                fill="#FDC068"
                d="M104.995 20.402l4.266-.657a4.035 4.035 0 003.385-4.62l-.995-6.448a6.475 6.475 0 00-7.394-5.421 6.476 6.476 0 00-5.421 7.394l.914 5.919c.385 2.486 2.743 4.218 5.245 3.833z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M99.718 12.51c0 .322-.209.594-.481.69l-.225-1.427a.74.74 0 01.706.738zM107.481 27.09l-4.33-1.475s1.94-1.764 4.154-1.989l.176 3.465z"
            ></path>
            <path
                fill="#FDC068"
                d="M109.486 27.187l3.978-2.198a1.887 1.887 0 00.93-2.005l-2.181-10.762-6.817 1.043 1.251 12.43c.128 1.364 1.62 2.15 2.839 1.492z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M101.065 14.484c-.032 0-.064 0-.096-.016l-.674-.273a.824.824 0 01-.368-.465.806.806 0 01.08-.578l.738-1.218c.064-.113.224-.145.336-.08.113.063.145.224.081.336l-.738 1.22a.306.306 0 00-.032.208.255.255 0 00.128.16l.626.257a.23.23 0 01.128.304c-.016.08-.112.145-.209.145zM101.98 16.761c-.016 0-.016 0 0 0-.61 0-1.011-.497-1.027-.513a.25.25 0 01.032-.337.249.249 0 01.337.032s.273.337.658.337c.272 0 .561-.176.834-.513a.249.249 0 01.337-.032c.096.08.112.24.032.337-.385.465-.786.69-1.203.69zM106.086 20.226l2.791-.433L106.375 23l-.289-2.775zM103.15 12.64a.738.738 0 100-1.476.738.738 0 000 1.475zM84.705 129.808l19.151-.081c.994 0 1.813-.802 1.845-1.812l.048-2.422-12.11.176-9.175 2.294c-1.058.273-.866 1.845.24 1.845z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M144.692 129.054H6.4v.657h138.292v-.657zM129.567 127.738l16.713-9.367c.866-.497 1.203-1.588.722-2.47l-1.139-2.133-10.506 6.031-6.897 6.464c-.786.738.145 2.021 1.107 1.475z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M24.589 129.808a8.052 8.052 0 100-16.104 8.052 8.052 0 000 16.104z"
            ></path>
            <path
                fill="#fff"
                d="M24.589 125.958a4.202 4.202 0 100-8.404 4.202 4.202 0 000 8.404z"
            ></path>
            <path fill="#FD8945" d="M44.093 77.134h-30.62v8.902h30.62v-8.902z"></path>
            <path
                fill="#D87345"
                d="M16.97 77.134h-.481v8.902h.481v-8.902zM21.3 77.134h-.48v8.902h.48v-8.902zM25.631 77.134h-.481v8.902h.481v-8.902zM29.962 77.134h-.481v8.902h.48v-8.902zM34.293 77.134h-.482v8.902h.482v-8.902zM38.623 77.134h-.48v8.902h.48v-8.902zM42.954 77.134h-.482v8.902h.482v-8.902z"
            ></path>
            <path fill="#2B2B2B" d="M13.473 77.134v4.202l14.837-4.202H13.474z"></path>
            <path fill="#FDB645" d="M40.147 59.442H9.528v8.902h30.62v-8.902z"></path>
            <path fill="#FD8945" d="M21.445 59.46H9.528v8.901h11.917v-8.902z"></path>
            <path fill="#D87345" d="M12.784 59.46H9.528v8.901h3.256v-8.902z"></path>
            <path fill="#FD8945" d="M37.773 59.442H25.856v8.902h11.917v-8.902z"></path>
            <path
                fill="#D87345"
                d="M37.773 59.442h-3.256v8.902h3.256v-8.902zM13.008 59.458h-.48v8.886h.48v-8.886zM17.339 59.458h-.481v8.886h.48v-8.886zM21.67 59.458h-.482v8.886h.482v-8.886zM26 59.458h-.48v8.886H26v-8.886zM30.33 59.458h-.48v8.886h.48v-8.886z"
            ></path>
            <path
                fill="#D87345"
                d="M34.661 59.458h-.48v8.886h.48v-8.886zM38.992 59.458h-.48v8.886h.48v-8.886z"
            ></path>
            <path fill="#2B2B2B" d="M25.872 68.344V62.49l10.361.85v4.908l-10.361.096z"></path>
            <path fill="#FDB645" d="M39.2 68.249H8.582v8.902h30.62v-8.902z"></path>
            <path fill="#FD8945" d="M20.499 68.249H8.58v8.902H20.5v-8.902z"></path>
            <path fill="#D87345" d="M11.837 68.249H8.581v8.902h3.256v-8.902z"></path>
            <path fill="#FD8945" d="M36.86 68.232H24.941v8.902h11.917v-8.902z"></path>
            <path
                fill="#D87345"
                d="M36.859 68.232h-3.256v8.902h3.256v-8.902zM12.078 68.248h-.481v8.886h.481v-8.886zM16.409 68.248h-.481v8.886h.48v-8.886zM20.74 68.248h-.482v8.886h.482v-8.886zM25.07 68.248h-.481v8.886h.481v-8.886zM29.4 68.248h-.48v8.886h.48v-8.886z"
            ></path>
            <path
                fill="#D87345"
                d="M33.732 68.248h-.482v8.886h.482v-8.886zM38.062 68.248h-.481v8.886h.481v-8.886z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M41.237 68.248v8.902h-6.271a15.285 15.285 0 01-2.454-8.34c0-.193 0-.37.016-.562h8.71z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M44.093 77.134v8.902h-3.256a15.422 15.422 0 01-9.303-8.902h12.559z"
            ></path>
            <path fill="#FD8945" d="M60.71 52.754H30.09v8.902h30.62v-8.902z"></path>
            <path fill="#2B2B2B" d="M60.71 52.77v4.202l-16.617-1.924 1.796-2.278h14.82z"></path>
            <path
                fill="#2B2B2B"
                d="M57.71 52.77h-.48v8.902h.48V52.77zM53.38 52.77h-.481v8.902h.481V52.77zM49.05 52.77h-.482v8.902h.481V52.77zM44.718 52.77h-.48v8.902h.48V52.77zM40.388 52.77h-.481v8.902h.481V52.77zM36.057 52.77h-.48v8.902h.48V52.77zM31.727 52.77h-.482v8.902h.482V52.77z"
            ></path>
            <path fill="#FDB645" d="M65.153 34.982h-30.62v8.902h30.62v-8.902z"></path>
            <path fill="#D87345" d="M37.79 34.982h-3.257v8.902h3.256v-8.902z"></path>
            <path fill="#FD8945" d="M62.795 34.982h-11.71v8.902h11.71v-8.902z"></path>
            <path
                fill="#D87345"
                d="M65.152 34.982h-5.517v8.902h5.517v-8.902zM38.03 34.982h-.482v8.902h.481v-8.902zM42.36 34.982h-.48v8.902h.48v-8.902zM46.691 34.982h-.481v8.902h.481v-8.902zM51.022 34.982h-.482v8.902h.482v-8.902zM55.352 34.982h-.48v8.902h.48v-8.902z"
            ></path>
            <path
                fill="#D87345"
                d="M59.683 34.982h-.481v8.902h.481v-8.902zM64.014 34.982h-.481v8.902h.48v-8.902z"
            ></path>
            <path fill="#FDB645" d="M64.56 43.885H33.94v8.902h30.62v-8.902z"></path>
            <path fill="#FD8945" d="M45.857 43.885H33.94v8.902h11.917v-8.902z"></path>
            <path fill="#D87345" d="M37.196 43.885H33.94v8.902h3.256v-8.902z"></path>
            <path fill="#FD8945" d="M62.201 43.868H50.284v8.902h11.917v-8.902z"></path>
            <path
                fill="#D87345"
                d="M64.575 43.868h-5.614v8.902h5.614v-8.902zM37.437 43.884h-.482v8.886h.482v-8.886z"
            ></path>
            <path fill="#2B2B2B" d="M41.767 43.884h-.48v8.886h.48v-8.886z"></path>
            <path
                fill="#D87345"
                d="M46.098 43.884h-.481v8.886h.48v-8.886zM50.428 43.884h-.48v8.886h.48v-8.886zM54.76 43.884h-.482v8.886h.481v-8.886zM59.09 43.884h-.482v8.886h.482v-8.886zM63.42 43.884h-.48v8.886h.48v-8.886z"
            ></path>
            <path fill="#2B2B2B" d="M64.56 43.9H41.525v.481H64.56v-.48z"></path>
            <path
                fill="#FD8945"
                d="M31.213 36.009v30.796h-3.962c-1.299 0-2.566-.16-3.769-.466a15.496 15.496 0 01-5.325-2.502l-.626-.481a16.148 16.148 0 01-2.053-2.005l-.016-.016c-.128-.16-.256-.305-.369-.465a15.358 15.358 0 01-1.94-3.24c-.08-.16-.145-.32-.209-.481a14.547 14.547 0 01-.898-3.224c-.032-.16-.048-.321-.08-.481a14.183 14.183 0 01-.129-2.037c0-.401.016-.802.049-1.203l.048-.482c.128-1.106.353-2.197.705-3.224.033-.08.049-.16.08-.24.033-.08.065-.16.08-.24.322-.867.723-1.7 1.188-2.487.064-.096.128-.208.192-.305.032-.064.08-.112.112-.176a14.758 14.758 0 011.99-2.47l.064-.064a15.452 15.452 0 014.026-2.903c.352-.177.705-.337 1.074-.482a15.367 15.367 0 015.774-1.122h3.994z"
            ></path>
            <path
                fill="#FFF6BB"
                d="M22.985 43.226v3.032h-10.25c.033-.08.065-.16.08-.24.322-.867.723-1.7 1.188-2.487.064-.096.128-.208.192-.305h8.79z"
            ></path>
            <path
                fill="#EA7A45"
                d="M32.047 61.335v5.453c-.272.016-.561.016-.834.016h-3.962c-1.299 0-2.566-.16-3.769-.465a15.492 15.492 0 01-5.325-2.502l-.625-.481a16.154 16.154 0 01-2.053-2.005l-.016-.016h16.584zM27.492 37.372c0 .128-.112.24-.24.24h-6.85c.354-.176.706-.336 1.075-.48h5.774c.129 0 .241.112.241.24zM23.883 40.34c0 .128-.112.24-.24.24h-7.346l.064-.064c.144-.145.289-.289.433-.417h6.833c.144 0 .256.112.256.24zM20.274 43.29c0 .129-.112.241-.24.241h-6.015c.064-.096.128-.208.192-.304.033-.065.08-.113.113-.177h5.71c.112 0 .208.08.224.177.016.016.016.048.016.064zM19.569 46.258c0 .128-.113.24-.241.24h-6.672c.032-.08.048-.16.08-.24.032-.08.064-.16.08-.24h6.496c.144 0 .256.112.256.24zM19.569 49.963c0 .128-.113.24-.24.24h-7.427l.048-.48h7.378c.128 0 .24.112.24.24zM19.569 53.684c0 .129-.113.24-.241.24h-7.266c-.032-.16-.048-.32-.08-.48h7.346c.128 0 .24.112.24.24zM19.569 57.39c0 .128-.113.24-.241.24h-6.16c-.08-.16-.143-.32-.208-.481h6.368c.128 0 .24.112.24.24z"
            ></path>
            <path
                fill="#EA7A45"
                d="M20.515 61.11a.241.241 0 01-.16.225c-.033.016-.049.016-.08.016h-4.78l-.017-.016c-.128-.16-.256-.305-.368-.465h5.164c.128 0 .24.112.24.24zM23.643 63.596c0 .129-.113.241-.241.241h-5.229l-.625-.481h5.854c.128 0 .24.112.24.24zM28.294 66.099c0 .128-.112.24-.24.24h-4.572a13.407 13.407 0 01-1.556-.48h6.127c.129 0 .24.111.24.24z"
            ></path>
            <path
                fill="#FDE545"
                d="M46.611 51.407c0 .61-.032 1.203-.112 1.796-.754 6.544-5.63 11.837-11.95 13.233-.818.176-1.652.304-2.502.352-.272.017-.561.017-.834.017-1.299 0-2.566-.16-3.769-.466a15.494 15.494 0 01-5.325-2.502l-.626-.481a16.148 16.148 0 01-2.053-2.005l-.016-.016c-.128-.16-.256-.305-.369-.465a15.353 15.353 0 01-1.94-3.24c-.065-.16-.145-.32-.209-.481a14.547 14.547 0 01-.898-3.224c-.032-.16-.048-.321-.08-.481a14.183 14.183 0 01-.129-2.037c0-.401.016-.802.048-1.203l.049-.482c.128-1.106.352-2.197.705-3.224.032-.08.048-.16.08-.24.033-.08.049-.16.08-.24.322-.867.723-1.7 1.188-2.487.064-.096.128-.208.192-.305.032-.064.08-.112.112-.176a14.748 14.748 0 011.99-2.47 15.214 15.214 0 014.106-2.967 12.92 12.92 0 011.074-.482 15.367 15.367 0 015.774-1.122c8.517 0 15.414 6.897 15.414 15.398z"
            ></path>
            <path
                fill="#FDB645"
                d="M31.213 62.49c6.121 0 11.083-4.962 11.083-11.084 0-6.12-4.962-11.083-11.083-11.083S20.13 45.285 20.13 51.407c0 6.12 4.962 11.083 11.083 11.083z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M46.5 53.203c-.755 6.544-5.63 11.837-11.95 13.233.77-6.529 5.63-11.822 11.95-13.233z"
            ></path>
            <path
                fill="#EA7A45"
                d="M29.048 46.114a.833.833 0 01-.4.112h.801a.69.69 0 01-.401-.112zm4.571-1.476c-.096 0-.176-.016-.272-.016h-6.352V52h6.352c.866 0 1.668-.305 2.293-.802a3.674 3.674 0 001.396-2.887c0-1.94-1.508-3.545-3.417-3.673zm-5.02 5.758v-4.17h4.748a2.06 2.06 0 012.085 2.085 2.08 2.08 0 01-2.085 2.085h-4.748z"
            ></path>
            <path
                fill="#EA7A45"
                d="M28.647 56.17c.144 0 .289.032.401.113a.834.834 0 01.401-.113h-.802zm6.993-4.972a3.688 3.688 0 00-2.293-.802h-6.352v7.378h6.352c.096 0 .176 0 .272-.016a3.691 3.691 0 003.417-3.673c0-1.17-.546-2.213-1.396-2.887zm-2.293 4.972h-4.748V52h4.748a2.06 2.06 0 012.085 2.085c0 1.155-.93 2.085-2.085 2.085zM28.647 46.226h-2.79a.794.794 0 01-.803-.802c0-.45.353-.802.802-.802h2.791c.45 0 .802.353.802.802 0 .449-.353.802-.802.802z"
            ></path>
            <path
                fill="#EA7A45"
                d="M29.45 46.226a.794.794 0 01-.803-.802v-2.198c0-.449.353-.802.802-.802.45 0 .802.353.802.802v2.198c0 .449-.353.802-.802.802zM32.817 46.226a.794.794 0 01-.802-.802v-2.198c0-.449.353-.802.802-.802.45 0 .802.353.802.802v2.198c0 .449-.353.802-.802.802zM29.45 59.956a.794.794 0 01-.803-.802v-2.182c0-.449.353-.802.802-.802.45 0 .802.353.802.802v2.182c0 .449-.353.802-.802.802zM32.817 59.956a.794.794 0 01-.802-.802v-2.182c0-.449.353-.802.802-.802.45 0 .802.353.802.802v2.182c0 .449-.353.802-.802.802z"
            ></path>
            <path
                fill="#EA7A45"
                d="M28.647 57.774h-2.79a.794.794 0 01-.803-.802c0-.449.353-.802.802-.802h2.791c.45 0 .802.353.802.802 0 .45-.353.802-.802.802z"
            ></path>
            <path
                fill="#FD8945"
                d="M54.599 53.395v30.796h-3.962c-3.4 0-6.544-1.106-9.095-2.967l-.625-.481a17.322 17.322 0 01-2.053-2.005c-.128-.16-.273-.321-.385-.481a15.655 15.655 0 01-1.94-3.224c-.081-.16-.145-.321-.21-.481a15.637 15.637 0 01-.914-3.24c-.032-.16-.048-.321-.08-.482a14.331 14.331 0 01-.128-2.037c0-.4.016-.802.048-1.203l.048-.48c.128-1.124.353-2.198.706-3.225.032-.08.048-.16.08-.24l.096-.241c.321-.866.722-1.7 1.187-2.486.064-.096.128-.209.193-.305.032-.064.08-.112.112-.176a14.748 14.748 0 012.053-2.534A15.214 15.214 0 0143.772 55a12.92 12.92 0 011.075-.482 15.367 15.367 0 015.774-1.123h3.977z"
            ></path>
            <path
                fill="#EA7A45"
                d="M50.878 54.759c0 .128-.112.24-.24.24h-6.85c.353-.176.706-.337 1.075-.48h5.774c.129 0 .24.111.24.24zM47.269 57.726c0 .128-.112.24-.24.24h-7.347l.064-.063c.145-.145.289-.29.433-.417h6.833c.145 0 .257.112.257.24z"
            ></path>
            <path
                fill="#FFF6BB"
                d="M45.472 60.613v3.032h-9.35l.096-.241c.32-.866.721-1.7 1.187-2.486.064-.096.128-.209.192-.305h7.875z"
            ></path>
            <path
                fill="#EA7A45"
                d="M43.66 60.677c0 .129-.112.241-.24.241h-6.016c.065-.096.129-.209.193-.305.032-.064.08-.112.112-.176h5.71c.113 0 .209.08.225.176.016.016.016.048.016.064zM42.954 63.645c0 .128-.112.24-.24.24H36.04c.032-.08.048-.16.08-.24l.097-.24h6.496c.128 0 .24.111.24.24zM42.954 67.35c0 .128-.112.24-.24.24h-7.427l.048-.48h7.379c.128 0 .24.111.24.24zM42.954 71.07c0 .13-.112.242-.24.242h-7.266c-.032-.16-.048-.321-.08-.482h7.346c.128 0 .24.113.24.24zM42.954 74.792c0 .128-.112.24-.24.24h-6.16c-.08-.16-.144-.32-.208-.48h6.368c.128 0 .24.112.24.24zM43.9 78.497c0 .129-.112.24-.24.24h-4.78c-.128-.16-.273-.32-.385-.48h5.165c.128 0 .24.112.24.24zM47.044 80.983c0 .129-.112.24-.24.24h-5.261l-.626-.48h5.87c.145 0 .257.112.257.24z"
            ></path>
            <path
                fill="#FDE545"
                d="M54.599 84.191c8.504 0 15.398-6.894 15.398-15.398s-6.894-15.398-15.398-15.398S39.2 60.29 39.2 68.793s6.894 15.398 15.398 15.398z"
            ></path>
            <path
                fill="#FDB645"
                d="M54.599 79.876c6.12 0 11.083-4.962 11.083-11.083 0-6.12-4.962-11.083-11.083-11.083s-11.083 4.962-11.083 11.083 4.962 11.083 11.083 11.083z"
            ></path>
            <path
                fill="#EA7A45"
                d="M56.748 69.387h-6.351v-7.379h6.351a3.69 3.69 0 010 7.379zm-4.747-1.604h4.747c1.155 0 2.085-.93 2.085-2.085 0-1.155-.93-2.085-2.085-2.085h-4.747v4.17z"
            ></path>
            <path
                fill="#EA7A45"
                d="M56.748 75.161h-6.351v-7.378h6.351a3.69 3.69 0 010 7.378zm-4.747-1.604h4.747c1.155 0 2.085-.93 2.085-2.085 0-1.155-.93-2.085-2.085-2.085h-4.747v4.17zM52.033 63.612h-2.775a.794.794 0 01-.802-.802c0-.449.353-.802.802-.802h2.775c.449 0 .802.353.802.802 0 .45-.353.802-.802.802z"
            ></path>
            <path
                fill="#EA7A45"
                d="M52.85 63.612a.794.794 0 01-.802-.802v-2.197c0-.449.353-.802.802-.802.45 0 .802.353.802.802v2.197c0 .45-.352.802-.802.802zM56.203 63.612a.794.794 0 01-.802-.802v-2.197c0-.449.353-.802.802-.802.45 0 .802.353.802.802v2.197c0 .45-.353.802-.802.802zM52.85 77.342a.794.794 0 01-.802-.802V74.36c0-.45.353-.802.802-.802.45 0 .802.353.802.802v2.181c0 .45-.352.802-.802.802zM56.203 77.342a.794.794 0 01-.802-.802V74.36c0-.45.353-.802.802-.802.45 0 .802.353.802.802v2.181c0 .45-.353.802-.802.802z"
            ></path>
            <path
                fill="#EA7A45"
                d="M52.033 75.161h-2.775a.794.794 0 01-.802-.802c0-.45.353-.802.802-.802h2.775c.449 0 .802.353.802.802 0 .45-.353.802-.802.802z"
            ></path>
            <path
                fill="#BE6361"
                d="M40.227 114.265L10.393 83.919h61.608v15.799l-31.774 14.547z"
            ></path>
            <path
                fill="#BE6361"
                d="M40.227 115.869c-.417 0-.834-.16-1.139-.481L9.255 85.058a1.608 1.608 0 01-.337-1.749 1.61 1.61 0 011.476-.978H72c.882 0 1.604.721 1.604 1.604v15.799c0 .625-.369 1.202-.93 1.459l-31.758 14.532a1.72 1.72 0 01-.69.144zm-26-30.347l26.369 26.819 29.801-13.65V85.522h-56.17z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M11.532 87.367h62.073V83.92l-65.249.24 3.176 3.208zM131.251 74.52a1.62 1.62 0 01-1.459-.93 1.614 1.614 0 01.786-2.134l15.959-7.314a1.614 1.614 0 012.133.786 1.614 1.614 0 01-.786 2.133l-15.959 7.314a1.699 1.699 0 01-.674.144z"
            ></path>
            <path
                fill="#F5BE6D"
                d="M24.589 123.36a1.62 1.62 0 01-1.46-.931 1.614 1.614 0 01.786-2.133l106.662-48.84a1.615 1.615 0 012.134.786 1.614 1.614 0 01-.786 2.133l-106.663 48.84c-.224.097-.449.145-.673.145z"
            ></path>
            <path
                fill="#BE6361"
                d="M74.15 85.523H7.283a1.609 1.609 0 01-1.604-1.604c0-.882.722-1.604 1.604-1.604h66.869c.882 0 1.604.722 1.604 1.604 0 .882-.722 1.604-1.604 1.604z"
            ></path>
            <path
                fill="#F5BE6D"
                d="M80.165 121.932H72.9c-.434 0-.835-.176-1.14-.465l-14.194-14.195a1.597 1.597 0 010-2.261 1.597 1.597 0 012.261 0l13.73 13.729h5.004V96.622c0-.882.722-1.604 1.604-1.604.883 0 1.604.722 1.604 1.604v23.706c0 .882-.721 1.604-1.604 1.604z"
            ></path>
            <path
                fill="#FDC068"
                d="M142.623 64.302l1.364 4.411a4.476 4.476 0 01-2.936 5.582c-2.133.658-4.395-.353-5.325-2.31l-2.021-4.154 8.918-3.529z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M117.281 42.6c.642 4.893 7.555 13.41 7.555 13.41l4.443 8.998-1.62-14.949-6.304-7.699-4.074.24z"
            ></path>
            <path
                fill="#6B9C83"
                d="M142.623 64.302l-5.405-17.419a3.961 3.961 0 00-.641-1.235l-.225-.288-14.532-18.702a6.19 6.19 0 00-5.325-2.374c.128.93.257 1.86.369 2.807.08 2.181-.369 4.218-1.363 6.11a11.303 11.303 0 01-1.684 3.305l12.43 15.991 7.442 15.334 8.934-3.529z"
            ></path>
            <path
                fill="#C96F4D"
                d="M98.835 8.71l6.994-1.075a.83.83 0 01.946.673l.706 3.898 1.395-.16.914-.385 3.24 4.539-1.331-9.96a4.291 4.291 0 00-5.133-3.626l-9.72 1.941 1.99 4.154z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M118.484 43.034a.23.23 0 01-.193-.096l-3.833-5.23a.25.25 0 01.048-.336.25.25 0 01.337.048l3.833 5.229a.25.25 0 01-.048.337.257.257 0 01-.144.048zM128.829 52.738h-2.566a.247.247 0 01-.241-.24c0-.13.113-.241.241-.241h2.566c.129 0 .241.112.241.24 0 .129-.112.24-.241.24zM101.674 44.782c-.144-.016-.24-.128-.224-.256l.738-7.635a.233.233 0 01.256-.209.233.233 0 01.209.257l-.738 7.635a.243.243 0 01-.241.208zM96.847 54.326a.255.255 0 01-.24-.193l-.37-2.005c-.016-.128.065-.256.193-.272a.226.226 0 01.272.192l.37 2.005c.016.128-.065.257-.193.273h-.032zM110.528 34.148l-2.342 7.699-1.155-7.699h3.497zM98.755 14.403a1.76 1.76 0 01-1.764-1.764 1.76 1.76 0 011.764-1.764 1.76 1.76 0 011.765 1.764 1.76 1.76 0 01-1.765 1.764zm0-3.047c-.706 0-1.283.577-1.283 1.283s.578 1.283 1.283 1.283c.706 0 1.283-.577 1.283-1.283s-.577-1.283-1.283-1.283zM103.086 13.666a1.76 1.76 0 01-1.764-1.765 1.76 1.76 0 011.764-1.764 1.76 1.76 0 011.764 1.764 1.76 1.76 0 01-1.764 1.765zm0-3.048c-.706 0-1.283.578-1.283 1.283 0 .706.577 1.284 1.283 1.284s1.283-.578 1.283-1.284c0-.705-.577-1.283-1.283-1.283z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M100.279 12.463a.26.26 0 01-.24-.177.24.24 0 01.176-.288l1.283-.321a.239.239 0 11.113.465l-1.284.32h-.048zM104.385 11.356a.24.24 0 01-.24-.208c-.016-.129.064-.257.192-.273l4.956-.834c.129-.016.257.064.273.192.016.128-.064.257-.193.273l-4.956.834c0 .016-.016.016-.032.016z"
            ></path>
            <path
                fill="#FDC068"
                d="M109.999 12.543l-1.075.144-.304-2.422a1.083 1.083 0 112.149-.288l.176 1.347a1.078 1.078 0 01-.946 1.219z"
            ></path>
            <path
                fill="#000020"
                d="M111.972 72.771a.243.243 0 01-.241-.208l-1.941-18.045a.25.25 0 01.209-.273.25.25 0 01.273.209l1.94 18.045a.249.249 0 01-.208.272h-.032z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M131.251 85.683a.246.246 0 01-.24-.24l-.096-4.78c0-.129.096-.241.24-.241.129 0 .241.096.241.24l.096 4.78c0 .129-.096.24-.241.24z"
            ></path>
            <path
                fill="#fff"
                d="M109.774 28.117l-1.54-3.143 6.416-3.577.433 2.23-2.726 2.95-2.583 1.54z"
            ></path>
            <path
                fill="#fff"
                d="M108.235 24.974l-1.38 3.143-.69-1.475-.304-2.663.513-.978 1.861 1.973z"
            ></path>
            <path fill="#2B2B2B" d="M108.235 24.974l1.475 2.999h-2.79l1.315-3z"></path>
            <path
                fill="#FDE545"
                d="M28.502 21.733l.064.16c.24.627.754 1.14 1.38 1.38l.16.064c.176.065.176.321 0 .385l-.16.065a2.432 2.432 0 00-1.38 1.379l-.064.16c-.064.177-.32.177-.385 0l-.064-.16a2.432 2.432 0 00-1.38-1.38l-.16-.064c-.176-.064-.176-.32 0-.385l.16-.064a2.432 2.432 0 001.38-1.38l.064-.16c.064-.176.32-.176.385 0zM74.343 52.289l.064.16c.24.626.754 1.139 1.38 1.38l.16.064c.176.064.176.32 0 .385l-.16.064a2.432 2.432 0 00-1.38 1.38l-.064.16c-.064.176-.32.176-.385 0l-.064-.16a2.432 2.432 0 00-1.38-1.38l-.16-.064c-.176-.065-.176-.321 0-.385l.16-.065a2.432 2.432 0 001.38-1.379l.064-.16c.08-.177.32-.177.385 0zM27.989 92.74l.064.16c.24.626.754 1.14 1.38 1.38l.16.064c.176.064.176.321 0 .385l-.16.064a2.432 2.432 0 00-1.38 1.38l-.064.16c-.064.177-.32.177-.385 0l-.064-.16a2.432 2.432 0 00-1.38-1.38L26 94.73c-.176-.064-.176-.32 0-.385l.16-.064a2.432 2.432 0 001.38-1.38l.064-.16c.064-.176.32-.176.385 0zM5.55 54.213l.064.16c.24.626.753 1.14 1.379 1.38l.16.064c.177.065.177.321 0 .385l-.16.064a2.432 2.432 0 00-1.38 1.38l-.064.16c-.064.177-.32.177-.385 0l-.064-.16a2.432 2.432 0 00-1.38-1.38l-.16-.064c-.176-.064-.176-.32 0-.385l.16-.064a2.432 2.432 0 001.38-1.38l.065-.16c.08-.176.32-.176.384 0zM46.803 33.186l.064.16c.241.626.754 1.139 1.38 1.38l.16.064c.177.064.177.32 0 .385l-.16.064a2.432 2.432 0 00-1.38 1.38l-.064.16c-.064.176-.32.176-.385 0l-.064-.16a2.432 2.432 0 00-1.38-1.38l-.16-.064c-.176-.064-.176-.321 0-.385l.16-.065a2.432 2.432 0 001.38-1.379l.064-.16c.064-.16.305-.16.385 0z"
            ></path>
            <path
                fill="#F5BE6D"
                d="M61.888 16.999l-2.104 3.05 1.32.91 2.104-3.05-1.32-.91zM17.912 17.003l-1.32.91 2.103 3.05 1.32-.91-2.103-3.05zM39.297 11.288l-.304 3.693 1.598.132.305-3.693-1.599-.132z"
            ></path>
        </svg>
    )
}

export default Icon
