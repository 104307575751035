import styled from 'styled-components'
import MuiTab from '@material-ui/core/Tab'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiContainer from '@material-ui/core/Container'
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@material-ui/icons'

import GridIcon from '../../../components/SVG/Grid'
import { media, colors, transition } from '@variables'

export const Tab = styled(MuiTab)``
export const Info = styled(Typography)``
export const Name = styled(Typography)``
export const Points = styled(Typography)``
export const Subtitle = styled(Typography)``
export const AudioButton = styled(MuiButton)``
export const Description = styled(Typography)``
export const MobilePoints = styled(Typography)``

export const Container = styled(MuiContainer)`
    margin-bottom: 50px;

    @media (min-width: 854px) {
        margin-bottom: 100px;
    }

    ${Tab} {
        transition: ${transition.main};

        ${media.tablet} {
            :hover {
                background-color: #f5fcfe;
            }
        }
    }

    ${Subtitle} {
        opacity: 0.5;
        margin: 10px 0;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    ${Description} {
        opacity: 0.7;
        margin-bottom: 30px;
    }

    ${Name} {
        margin: 0 0 15px 0;
    }

    ${Info} {
        color: #c0c0c0;
        margin-bottom: 0;
    }

    ${Points} {
        font-weight: 300;
        margin-bottom: 10px;
        display: inline-flex;
        align-items: center;

        svg {
            margin-right: 10px;
        }
    }

    ${MobilePoints} {
        font-weight: 300;
        margin-bottom: 30px;
        display: inline-flex;
        align-items: center;

        svg {
            margin-right: 10px;
        }
    }

    ${AudioButton} {
        width: 100%;
        padding: 14px;
        margin-top: 15px;
        transition: ${transition.main};
        color: ${colors.primary.darkBlue};
        border-color: ${colors.primary.darkBlue};

        :hover {
            color: ${colors.primary.darkBlue};
            border-color: ${colors.primary.darkBlue};
        }

        svg {
            transition: ${transition.main};
            fill: ${colors.primary.darkBlue};
        }

        ${media.tablet} {
            :hover {
                color: ${colors.primary.orange};
                border-color: ${colors.primary.orange};

                svg {
                    fill: ${colors.primary.orange};
                }
            }
        }

        @media (max-width: 959px) {
            margin-bottom: 15px;
        }
    }
`

export const TabsWrapper = styled.div`
    width: 100%;
    margin: 30px 0;
    border: 1px solid #ebebeb;

    ${media.tablet} {
        margin: 60px 0;
    }
`

export const ChapterIcon = styled(GridIcon)`
    transition: ${transition.main};

    ${media.phone} {
        margin-right: 10px;
    }
`

export const ChapterIconWrapper = styled(Typography)`
    display: inline-flex;
    align-items: center;
    transition: ${transition.main};

    span {
        display: none;
        font-weight: 700;

        ${media.phone} {
            display: block;
            height: 18px;
        }
    }

    ${media.tablet} {
        &:hover ${ChapterIcon} {
            transform: scale(1.1);
        }
    }
`

export const ArrowWrapper = styled(Typography)<{ readonly direction: 'left' | 'right' }>`
    display: inline-flex;
    align-items: center;

    ${media.tablet} {
        :hover {
            svg {
                ${(props) =>
                    props.direction === 'left' &&
                    `
                left: -5px;
            `}

                ${(props) =>
                    props.direction === 'right' &&
                    `
                right: -5px;
            `}
            }
        }
    }

    span {
        height: 18px;
        font-weight: 700;

        @media (max-width: 360px) {
            display: none;
        }
    }
`

export const LeftArrow = styled(ArrowBackIosRounded)`
    position: relative;
    left: 0;
    margin-right: 10px;
    font-size: 1rem !important;
    transition: ${transition.main};
`

export const RightArrow = styled(ArrowForwardIosRounded)`
    position: relative;
    right: 0;
    margin-left: 10px;
    font-size: 1rem !important;
    transition: ${transition.main};
`

export const Header = styled.header`
    width: 100%;
    margin-bottom: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    ${media.tablet} {
        margin-bottom: 60px;
    }
`

export const VideoWrapper = styled.div`
    width: 100%;
    height: 210px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;

    video {
        outline: none;
    }

    ${media.phone} {
        height: 280px;
    }
    ${media.tablet} {
        height: 340px;
    }
`

export const PlayButton = styled.div`
    width: 84px;
    height: 84px;
    cursor: pointer;
    border-radius: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.primary.orange};

    ${media.tablet} {
        &:hover {
            svg {
                transform: scale(1.1);
                transition: 0.3s all linear;
            }
        }
    }
`

export const Right = styled.div`
    height: 100%;
    display: flex;
    padding-left: 0;
    flex-direction: column;
    justify-content: space-between;

    ${media.desktop} {
        padding-left: 50px;
    }
    ${media.xl} {
        padding-left: 100px;
    }
`

export const Section = styled.div`
    position: relative;
    width: 100%;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 12px 60px rgba(42, 48, 57, 0.11);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 40px 0;
    padding: 30px;

    ${media.desktop} {
        padding: 50px 90px;
    }
`

export const OnlyMobile = styled.div`
    display: block;

    ${media.desktop} {
        display: none;
    }
`

export const OnlyDesktop = styled.div`
    display: none;

    ${media.desktop} {
        display: block;
    }
`
