import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import { media } from '@variables'
import { colors } from '@variables'

type IsRightTextProps = {
    readonly isRight: boolean
}

type TipResults = {
    readonly marginLeft: number
}

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 20px 0 40px 0;
    justify-content: center;
    flex-direction: column;

    ${media.tablet} {
        flex-direction: row;
        padding: 40px 0 60px 0;
    }
`

export const NextButtonWrapper = styled.div`
    width: 290px;
    margin: 0 0 20px 0;

    ${media.tablet} {
        margin: 0 20px 0 0;
    }
`
export const RepeatButtonWrapper = styled.div`
    width: 290px;
`

export const IsRightText = styled.p<IsRightTextProps>`
    margin: 0;
    font-size: 16px;
    font-weight: 200;
    line-height: 22px;
    padding-left: 10px;
    color: ${(props) => (props.isRight ? '#6AC69F' : '#FF5555')};
`

export const TipPriceText = styled.span`
    margin-left: 10px;
`

export const Text = styled(Typography)``

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    ${Text} {
        font-size: 18px;
        max-width: 450px;
        line-height: 28px;
        margin-bottom: 45px;
    }
`

export const LastStepCoins = styled.div`
    align-items: center;
    display: inline-flex;
    margin: 15px 0 30px 0;
`

export const TipResults = styled.div<TipResults>`
    display: inline-flex;
    align-items: center;
    margin-left: ${(props) => props.marginLeft}px;

    ${media.tablet} {
        margin-left: ${(props) => props.marginLeft * 2}px;
    }
`

export const TestLastStepIconWrapper = styled.div`
    width: 100%;

    @media (min-width: 420px) {
        margin-top: 20px;
    }

    svg {
        width: 100%;

        ${media.tablet} {
            width: inherit;
        }
    }
`
