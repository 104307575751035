import styled from 'styled-components'

import { media } from '@variables'

export const Wrapper = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: 1px solid #e5e6e7;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    float: left;

    ${media.tablet} {
        &:hover {
            transform: scale(1.1);
        }
    }
`
