import styled from 'styled-components'

import { transition, colors, media } from '@variables'

export const Outer = styled.div`
    width: 100%;
    height: 500px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    flex-direction: column;
    background-color: white;

    button {
        margin-top: 5px;
        min-width: 280px;
        transition: ${transition.main};
        color: ${colors.primary.darkBlue};

        ${media.tablet} {
            :hover {
                color: ${colors.primary.orange};
            }
        }
    }
`

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 100%;
    flex-direction: column;
    padding: 15px 10px 15px 15px;

    ${media.phone} {
        padding: 15px 15px 15px 25px;
    }
`
