import styled from 'styled-components'

import { Props } from '.'
import { colors } from '@variables'
import { getPercentages } from '@utils'

export const Svg = styled.svg`
    transform: rotate(270deg);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 4;
    left: 0;
    top: 0;
`

export const Rect = styled.rect<{ readonly progress: number }>`
    stroke-dashoffset: 1000;
    stroke-dasharray: 1000;
    stroke-linecap: square;
    position: relative;

    ${(props) => props.progress && ` stroke-dashoffset: ${1000 - props.progress}; `}
`

export const AvatarWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    img {
        width: 100%;
        height: auto;
    }
`

export const Wrapper = styled.div<Props & { readonly progress: number }>`
    justify-content: center;
    align-items: center;
    position: relative;

    display: flex;
    z-index: 1;

    ${(props) =>
        props.size === 'small' &&
        `
        border-radius: 4px;
        min-height: 45px;
        min-width: 45px;
        height: 45px;
        width: 45px;

        ${AvatarWrapper} {
            border-radius: 4px;
        }
    `}

    ${(props) =>
        props.size === 'medium' &&
        `
        border-radius: 11px;
        min-height: 64px;
        min-width: 64px;
        height: 64px;
        width: 64px;

        ${AvatarWrapper} {
            border-radius: 11px;
        }
    `}

    ${(props) =>
        props.size === 'large' &&
        `
        border-radius: 11px;
        min-height: 90px;
        min-width: 90px;
        height: 90px;
        width: 90px;

        ${AvatarWrapper} {
            border-radius: 11px;
        }
    `}

    :after {
        background-color: ${colors.primary.orange};
        display: ${(props) => (props.progress === 0 ? 'none' : 'block')};
        border: 1px solid white;
        position: absolute;
        border-radius: 50%;
        content: '';
        z-index: 5;

        ${(props) =>
            props.size === 'small' &&
            `
            height: 8px;
            width: 8px;
        `}
        ${(props) =>
            props.size === 'medium' &&
            `
            height: 10px;
            width: 10px;
        `}
        ${(props) =>
            props.size === 'large' &&
            `
            height: 12px;
            width: 12px;
        `}
    }

    ${(props) =>
        // Left
        props.progress >= 0 &&
        props.progress <= 25 &&
        `
        :after {
            ${props.size === 'small' ? `left: -4px;` : `left: -5px;`}
            bottom: ${getPercentages(props.progress, 25)}%;
            transform: translateY(${getPercentages(props.progress, 25)}%);
        }
    `}
    ${(props) =>
        // Top
        props.progress > 25 &&
        props.progress <= 50 &&
        `
        :after {
            ${props.size === 'small' ? `top: -4px;` : `top: -5px;`}
            left: ${getPercentages(props.progress - 25, 25)}%;
            transform: translateX(-${getPercentages(props.progress - 25, 25)}%);
        }
    `}
    ${(props) =>
        // Right
        props.progress > 50 &&
        props.progress <= 75 &&
        `
        :after {
            ${props.size === 'small' ? `right: -4px;` : `right: -5px;`}
            top: ${getPercentages(props.progress - 50, 25)}%;
            transform: translateY(-${getPercentages(props.progress - 50, 25)}%);
        }
    `}
    ${(props) =>
        // Bottom
        props.progress > 75 &&
        `
        :after {
            ${props.size === 'small' ? `bottom: -4px;` : `bottom: -5px;`}
            right: ${getPercentages(props.progress - 75, 25)}%;
            transform: translateX(${getPercentages(props.progress - 75, 25)}%);
        }
    `}
`
