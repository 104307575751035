import React from 'react'

const GoogleIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        fill="none"
        viewBox="0 0 22 22"
        {...props}
    >
        <path
            fill="#4385F5"
            fillRule="evenodd"
            d="M10.78 9.163v4.3h5.873a6.849 6.849 0 01-.542 1.333l-.043.074v.004l-.124.212-.132.208c-.045.066-.09.13-.137.195-.01.015-.023.028-.034.043-.041.051-.082.107-.115.151-.106.13-.217.255-.316.36-.057.06-.116.118-.166.168-.06.059-.122.115-.175.165a5.444 5.444 0 01-.175.147c-.064.053-.134.106-.192.151l3.371 2.912.15-.121h.004l.081-.07c.077-.065.151-.131.226-.199l.137-.12.085-.083.128-.121.09-.091.12-.121.089-.095a10.955 10.955 0 00.687-.796l.094-.126.18-.242c.055-.077.112-.155.166-.234l.008-.013.073-.108.094-.147c.02-.034.043-.066.064-.1l.093-.155.06-.1a10.581 10.581 0 00.24-.432c.089-.17.174-.344.255-.52l.013-.026.038-.086c.03-.065.058-.129.086-.195.01-.026.023-.052.034-.078.028-.068.054-.138.081-.207l.03-.074c.027-.07.051-.14.077-.212l.025-.07c.026-.073.053-.15.077-.224.007-.022.015-.043.021-.065.025-.076.046-.153.069-.23.022-.073.044-.146.064-.22l.021-.078.013-.056c.025-.098.05-.196.072-.294l.022-.087c.035-.159.065-.319.094-.48.016-.093.033-.187.047-.281l.004-.03.004-.03v-.01c.021-.147.04-.295.056-.445l.012-.147a7.785 7.785 0 00.026-.32v-.013c.006-.102.01-.205.013-.307v-.01a11.05 11.05 0 00-.15-2.154H10.78z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#34A855"
            fillRule="evenodd"
            d="M21.983 11.647c-.004.068-.012.136-.017.203.004-.045.01-.09.013-.134l.004-.07zm-.15 1.263zm-17.104.51a491.431 491.431 0 00-3.449 2.735A11.002 11.002 0 0011.002 22c2.601 0 4.988-.905 6.871-2.414l-3.371-2.912c-.975.727-2.196 1.134-3.59 1.134A6.56 6.56 0 014.73 13.42zm13.144 6.166l.154-.121h-.005l-.149.12zm3.845-6.1c-.022.095-.048.19-.072.285.024-.095.05-.19.072-.285zm-.363 1.228c-.02.057-.038.113-.06.169.022-.056.04-.113.06-.169zM21.25 15c-.037.095-.076.192-.115.285.01-.026.023-.051.034-.078.028-.068.054-.138.08-.207zm-.278.645c-.032.07-.064.138-.098.207.034-.069.066-.138.098-.207zm-.23.467zm-5.697.1zm-.175.164l-.175.147c.059-.049.118-.097.175-.147zm-.18.151zm5.254.874c-.059.082-.119.162-.18.242-.03.04-.058.082-.089.121.093-.118.181-.24.269-.363zm-.363.48zm-.392.463zm-.722.736c-.043.04-.088.077-.132.117.044-.04.09-.077.132-.117z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#FABC07"
            fillRule="evenodd"
            d="M4.703 9.047L1.135 6.13A10.946 10.946 0 000 11.002c0 1.862.463 3.616 1.28 5.153.92-.74 2.38-1.893 3.449-2.734a6.663 6.663 0 01-.376-2.215c0-.756.125-1.482.35-2.16z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#E94335"
            fillRule="evenodd"
            d="M11.002 0c-4.343 0-8.1 2.522-9.888 6.178l3.593 2.864c.876-2.628 3.298-4.52 6.154-4.52 1.616 0 3.011.6 4.059 1.596l3.312-3.401A10.95 10.95 0 0011.002 0z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#34A855"
            fillRule="evenodd"
            d="M1.058 6.29zM.862 6.728zM.704 7.121c-.018.047-.034.096-.05.143.016-.048.032-.095.05-.143zM.457 7.857zM.128 9.328c-.013.085-.023.17-.034.255.011-.086.021-.17.034-.255zM.077 9.709zM4.494 9.83zM4.464 9.985zM4.387 10.535zM4.362 10.864zM4.353 11.206zM.009 11.444c.002.07.008.14.012.211-.004-.07-.01-.14-.012-.211zM4.383 11.811c.005.064.01.128.017.19-.008-.063-.011-.126-.017-.19zM4.451 12.356c.015.087.033.17.051.256-.018-.086-.036-.169-.05-.256zM.346 13.75zM.623 14.654zM.7 14.866z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export default GoogleIcon
