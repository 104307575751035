import styled from 'styled-components'

import { colors, transition, media } from '@variables'
import { TopButtonWrapper } from '../../../../../../components/shared/styled'
import { Tab as MuiTab, Tabs as MuiTabs } from '../../../../../../components/shared/styled'

export const Wrapper = styled(TopButtonWrapper)`
    width: 100%;
    max-width: 100%;
    padding: 50px 0 30px 0;
    justify-content: space-between;
`

export const Left = styled.div`
    display: inline-flex;
    align-items: center;
`

export const Tabs = styled(MuiTabs)`
    width: initial;
    padding: 0;
`

export const Tab = styled(MuiTab)<{ readonly isActive: boolean }>`
    ${(props) =>
        !props.isActive &&
        `
        cursor: not-allowed;
    `}
`
