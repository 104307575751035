import React from 'react'
import Button from '@material-ui/core/Button'

import * as loco from '@loco'
import Illustration from '../../../SVG/PasswordReset'
import { Wrapper, H3, IllustrationWrapper } from '../styled'
import { Routes } from '@variables'

type Props = {
    close: () => void
}

const AfterNewPasswordSetted = ({ close }: Props) => (
    <Wrapper>
        <IllustrationWrapper>
            <Illustration style={{ maxWidth: 200, margin: '0 auto', display: 'block' }} />
        </IllustrationWrapper>
        <H3 style={{ textAlign: 'center', marginTop: 30 }}>
            {loco.dialogs['restore-password'].success.title}
        </H3>
        <a
            href={
                process.env.NODE_ENV === 'development'
                    ? Routes.RESET_PWD_DEV
                    : Routes.RESET_PWD_PROD
            }
        >
            <Button
                fullWidth
                size="large"
                type="button"
                color="primary"
                onClick={close}
                variant="contained"
            >
                {loco.dialogs['restore-password'].success.button}
            </Button>
        </a>
    </Wrapper>
)

export default AfterNewPasswordSetted
