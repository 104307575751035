import { useEffect } from 'react'
import { History } from 'history'
import { withRouter } from 'react-router-dom'

function ScrollToTop({ history }: { history: History }) {
    useEffect(() => {
        const unlisten = history.listen((location) => {
            const { search, hash } = location
            if (search.length > 0 || hash.length > 0) return
            window.scrollTo(0, 0)
        })
        return () => {
            unlisten()
        }
    }, [])

    return null
}

export default withRouter(ScrollToTop)
