import { Location } from 'history'
import { object, string } from 'yup'
import { useSnackbar } from 'notistack'
import React, { useContext } from 'react'
import Button from '@material-ui/core/Button'
import isUndefined from 'lodash/isUndefined'
import { useToggle, useBeforeUnload } from 'react-use'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Field, Formik, FormikProps, FormikHelpers } from 'formik'

import * as loco from '@loco'
import { ButtonWrapper } from '../styled'
import Head from '../../../../../components/Head'
import { useCreateWordMutation } from '@graphql'
import { Routes, notificationMsgs } from '@variables'
import { handleBack, translateGQLError } from '@utils'
import Loader from '../../../../../components/shared/Loader'
import InputField from '../../../../../components/Fields/Input'
import { AuthContext } from '../../../../../context/Auth'
import BackButton from '../../../../../components/shared/BackButton'
import RouteLeavingGuard from '../../../../../components/RouteLeavingGuard'
import { Container, Form, SmallContainer, Box } from '../../../../../components/shared/styled'

type FormValues = {
    word: string
    description: string
}

const CreateVocabulary = () => {
    const history = useHistory()
    const location = useLocation()
    const { enqueueSnackbar } = useSnackbar()

    const { user, loading: authLoading } = useContext(AuthContext)

    const [createWord, { loading }] = useCreateWordMutation()

    const [dirty, toggleDirty] = useToggle(false)
    // On close or reload
    useBeforeUnload(dirty, loco.admin.popup.leave.title)

    const handleChange = () => {
        if (!dirty) toggleDirty()
    }

    const submit = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
        const { word, description } = values

        const { data, errors } = await createWord({
            variables: {
                data: {
                    word: word,
                    description: description
                }
            }
        })

        if (!errors && data) {
            enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
            toggleDirty()
            resetForm()
            history.push({
                pathname: Routes.DASHBOARD,
                search: Routes.VOCABULARY_SEARCH_PARAM
            })
        } else {
            errors?.forEach((err) => {
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            })
        }
    }

    return (
        <>
            <Head title={loco.seo.vocabulary.create.title} />

            <RouteLeavingGuard
                when={dirty}
                title={loco.admin.popup.leave.title}
                btnText={loco.admin.popup.leave.confirm}
                description={loco.admin.popup.leave.subtitle}
                navigate={(path: string) => history.push(path)}
                shouldBlockNavigation={(newLocation: Location<any>) => {
                    if (dirty) {
                        if (location.pathname !== newLocation.pathname) {
                            return true
                        }
                    }
                    return false
                }}
            />

            <Container>
                <SmallContainer>
                    <BackButton
                        position="relative"
                        onClick={handleBack(history)}
                        style={{
                            margin: '40px 0 20px 0',
                            fontWeight: 400,
                            fontSize: 18
                        }}
                    >
                        {loco.admin.list.dictionary.label}
                    </BackButton>

                    <Box>
                        <Formik
                            validateOnMount
                            onSubmit={submit}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                        >
                            {({ isValid, isSubmitting, values }: FormikProps<FormValues>) => (
                                <Form spellCheck={false} onChange={handleChange}>
                                    <Field
                                        fullWidth
                                        key="word"
                                        name="word"
                                        value={values.word}
                                        component={InputField}
                                        className="customInput"
                                        disabled={loading || isSubmitting}
                                        label={loco.admin.create.dictionary.name.label}
                                        placeholder={loco.admin.create.dictionary.name.placeholder}
                                    />
                                    <Field
                                        fullWidth
                                        multiline
                                        key="description"
                                        name="description"
                                        component={InputField}
                                        className="customInput"
                                        value={values.description}
                                        disabled={loading || isSubmitting}
                                        label={loco.admin.create.dictionary.explanation.label}
                                        placeholder={
                                            loco.admin.create.dictionary.explanation.placeholder
                                        }
                                    />

                                    <ButtonWrapper>
                                        <Link
                                            className={
                                                loading || isSubmitting ? 'disabledLink' : ''
                                            }
                                            to={`${Routes.DASHBOARD}?${Routes.VOCABULARY_SEARCH_PARAM}`}
                                        >
                                            <Button
                                                size="large"
                                                type="button"
                                                color="secondary"
                                                variant="outlined"
                                                disabled={loading || isSubmitting}
                                            >
                                                {loco.common.cancel}
                                            </Button>
                                        </Link>
                                        <Button
                                            size="large"
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            style={{ marginLeft: 30 }}
                                            disabled={!isValid || loading || isSubmitting}
                                        >
                                            {loco.common.create}
                                        </Button>
                                    </ButtonWrapper>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </SmallContainer>
            </Container>
        </>
    )
}

const initialValues = {
    word: '',
    description: ''
}

const validationSchema = object({
    word: string().required(loco.validation.common.required),
    description: string().required(loco.validation.common.required)
})

export default CreateVocabulary
