import { useSnackbar } from 'notistack'
import { useSetState } from 'react-use'
import Edit from '@material-ui/icons/Edit'
import MaterialTable from 'material-table'
import { useHistory } from 'react-router-dom'
import React, { forwardRef, useContext } from 'react'
import BlockIcon from '@material-ui/icons/BlockRounded'
import UnblockIcon from '@material-ui/icons/HowToRegRounded'

import * as loco from '@loco'
import Pagination from '../../Pagination'
import BlockOrUnblockDialog from '../../../Dialogs/Info'
import { AuthContext } from '../../../../context/Auth'
import { getRouteWithId, translateGQLError } from '@utils'
import { tableLocalization, Routes, notificationMsgs } from '@variables'
import { tableIcons, chapterAndLectureColumns as columns } from '../../../shared'
import {
    useBlockChapterMutation,
    useBlockLectureMutation,
    useDeleteChapterMutation,
    useDeleteLectureMutation,
    usePublishChapterMutation,
    usePublishLectureMutation,
    AdminChaptersDocument,
    AdminChaptersQuery,
    CreationStatus,
    Role
} from '@graphql'

type ChapterOrLecture = AdminChaptersQuery['chapters'][0] & {
    parentId?: string
    tableData?: { isTreeExpanded?: boolean; id?: number }
}

type State = {
    isOpen: boolean
    currentRow?: ChapterOrLecture
}

type Props = {
    title: string
    loading: boolean
    pageSize: number
    data: Array<ChapterOrLecture>
    onChangeItemsPerPage: (page: number) => void
    setTreeExpanded: (data: ChapterOrLecture, is: boolean) => void
}

const refetchQueries = [{ query: AdminChaptersDocument }]

const ChapterAndLectureTable = ({
    data,
    title,
    loading,
    pageSize,
    setTreeExpanded,
    onChangeItemsPerPage
}: Props) => {
    const history = useHistory()
    const { user } = useContext(AuthContext)
    const { enqueueSnackbar } = useSnackbar()

    const [{ currentRow, isOpen }, setState] = useSetState<State>({
        currentRow: undefined,
        isOpen: false
    })

    const [blockChapter, { loading: blockChapterLoading }] = useBlockChapterMutation()
    const [blockLecture, { loading: blockLectureLoading }] = useBlockLectureMutation()
    const [deleteChapter, { loading: deleteChapterLoading }] = useDeleteChapterMutation()
    const [deleteLecture, { loading: deleteLectureLoading }] = useDeleteLectureMutation()
    const [publishChapter, { loading: publishChapterLoading }] = usePublishChapterMutation()
    const [publishLecture, { loading: publishLectureLoading }] = usePublishLectureMutation()

    const isLoading =
        loading ||
        blockChapterLoading ||
        blockLectureLoading ||
        deleteChapterLoading ||
        deleteLectureLoading ||
        publishChapterLoading ||
        publishLectureLoading

    const handleDialog = async (row: ChapterOrLecture) =>
        setState({ currentRow: row, isOpen: true })

    const blockOrDelete = async () => {
        if (!currentRow || isLoading) return

        if (Boolean(currentRow?.parentId)) {
            if (currentRow.status === CreationStatus.PENDING) {
                const { data, errors } = await deleteLecture({
                    refetchQueries: refetchQueries,
                    variables: { where: { id: currentRow.id } }
                })

                if (data && !errors) {
                    enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
                } else {
                    errors?.forEach((err) =>
                        enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
                    )
                }

                return
            }

            const { data, errors } = await blockLecture({
                refetchQueries: refetchQueries,
                variables: { where: { id: currentRow.id } }
            })

            if (data && !errors) {
                enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
            } else {
                errors?.forEach((err) =>
                    enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
                )
            }

            return
        }

        if (currentRow.status === CreationStatus.PENDING) {
            const { data, errors } = await deleteChapter({
                refetchQueries: refetchQueries,
                variables: { where: { id: currentRow.id } }
            })

            if (data && !errors) {
                enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
            } else {
                errors?.forEach((err) =>
                    enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
                )
            }

            return
        }

        const { data, errors } = await blockChapter({
            refetchQueries: refetchQueries,
            variables: { where: { id: currentRow.id } }
        })

        if (data && !errors) {
            enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }
    }

    const unblock = async () => {
        if (!currentRow || isLoading) return

        if (Boolean(currentRow?.parentId)) {
            const { data, errors } = await publishLecture({
                refetchQueries: refetchQueries,
                variables: { where: { id: currentRow.id } }
            })

            if (data && !errors) {
                enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
            } else {
                errors?.forEach((err) =>
                    enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
                )
            }

            return
        }

        const { data, errors } = await publishChapter({
            refetchQueries: refetchQueries,
            variables: { where: { id: currentRow.id } }
        })

        if (data && !errors) {
            enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }
    }

    const onClick = async () => {
        if (currentRow?.status === CreationStatus.BLOCKED) {
            await unblock()
        } else {
            await blockOrDelete()
        }
        setState({ isOpen: false, currentRow: undefined })
    }

    return (
        <>
            <BlockOrUnblockDialog
                isOpen={isOpen}
                toggleOpen={() => setState({ isOpen: !isOpen, currentRow: undefined })}
                buttonProps={{
                    onClick: onClick,
                    isSubmitting: isLoading,
                    text: getTooltip(currentRow?.status)
                }}
                data={[
                    {
                        title: getTitle(currentRow),
                        description: getDescription(currentRow)
                    }
                ]}
            />

            <MaterialTable
                data={data}
                title={title}
                columns={columns}
                icons={tableIcons}
                isLoading={isLoading}
                localization={tableLocalization}
                onTreeExpandChange={setTreeExpanded}
                onChangeRowsPerPage={onChangeItemsPerPage}
                parentChildData={(row, rows) => rows.find((a) => a.id === row.parentId)}
                components={{
                    Pagination: Pagination
                }}
                options={{
                    actionsColumnIndex: -1,
                    debounceInterval: 500,
                    thirdSortClick: false,
                    pageSize: pageSize,
                    draggable: false
                }}
                actions={
                    user?.role === Role.SUPER_ADMIN
                        ? [
                              (editRow: ChapterOrLecture) => ({
                                  position: 'row',
                                  tooltip: loco.common.edit,
                                  onClick: (e) => {
                                      if (editRow.parentId) {
                                          return history.push(
                                              getRouteWithId(Routes.LECTURE_EDIT, editRow.id)
                                          )
                                      }
                                      return history.push(
                                          getRouteWithId(Routes.CHAPTER_EDIT, editRow.id)
                                      )
                                  },
                                  icon: forwardRef(function icon(props, ref: any) {
                                      return (
                                          <Edit style={{ color: '#666666' }} ref={ref} {...props} />
                                      )
                                  }) as any
                              }),
                              (blockOrUnblockRow: ChapterOrLecture) => {
                                  const icon = getBlockOrUnblockRowIcon(blockOrUnblockRow.status)

                                  return {
                                      icon: icon,
                                      position: 'row',
                                      onClick: (e) => handleDialog(blockOrUnblockRow),
                                      tooltip: currentRow
                                          ? undefined
                                          : getTooltip(blockOrUnblockRow.status)
                                  }
                              }
                          ]
                        : [
                              (editRow: ChapterOrLecture) => ({
                                  position: 'row',
                                  tooltip: loco.common.edit,
                                  onClick: (e) => {
                                      if (editRow.parentId) {
                                          return history.push(
                                              getRouteWithId(Routes.LECTURE_EDIT, editRow.id)
                                          )
                                      }
                                      return history.push(
                                          getRouteWithId(Routes.CHAPTER_EDIT, editRow.id)
                                      )
                                  },
                                  icon: forwardRef(function icon(props, ref: any) {
                                      return (
                                          <Edit style={{ color: '#666666' }} ref={ref} {...props} />
                                      )
                                  }) as any
                              })
                          ]
                }
            />
        </>
    )
}

const getTooltip = (status?: CreationStatus): string => {
    if (status === CreationStatus.BLOCKED) return loco.common.unblock
    if (status === CreationStatus.PENDING) return loco.common.delete
    return loco.common.block
}

const getTitle = (row?: ChapterOrLecture): string => {
    const action = getTooltip(row?.status)

    if (!row) return `${loco.dialogs.common.sure} ${action}?`

    // LECTURE
    if (row.parentId)
        return `${loco.dialogs.common.sure} ${action} ${loco.dashboard.chapter.lecture} ${row.name}?`
    // CHAPTER
    return `${loco.dialogs.common.sure} ${action} ${loco.admin.create.chapter.label} ${row.name}?`
}

const getDescription = (row?: ChapterOrLecture): string => {
    const action: string = (() => {
        if (row?.status === CreationStatus.BLOCKED) return loco.common.unblock
        if (row?.status === CreationStatus.PENDING) return loco.common.delete
        return loco.common.block
    })()

    if (!row) return `${loco.table.comon.if} ${action}, ${loco.table.comon.notDislay}`

    // LECTURE
    if (row.parentId)
        return `${loco.table.comon.ifLecture} ${action}, ${loco.table.comon.notDislay}`
    // CHAPTER
    return `${loco.table.comon.ifChapter} ${action}, ${loco.table.comon.notDislay}`
}

const getBlockOrUnblockRowIcon = (status: CreationStatus) => {
    if (status === CreationStatus.BLOCKED) {
        return forwardRef(function icon(props, ref: any) {
            return <UnblockIcon style={{ color: '#666666' }} ref={ref} {...props} />
        }) as any
    }

    return forwardRef(function icon(props, ref: any) {
        return <BlockIcon style={{ color: '#666666' }} ref={ref} {...props} />
    }) as any
}

export default ChapterAndLectureTable
