import React from 'react'

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            fill="none"
            viewBox="0 0 24 25"
        >
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M4.087 6.242h15.826"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M8.561 1.742h6.878c.2 0 .39.08.531.22l3.724 3.724a.75.75 0 01-.026 1.086l-7.163 6.512a.751.751 0 01-1.01 0L4.332 6.772a.751.751 0 01-.026-1.086L8.03 1.962a.755.755 0 01.531-.22z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M8.269 1.802l3.647 11.672M15.731 1.802l-3.647 11.672M3.75 24.242l-2.014-2.517a4.5 4.5 0 01-.986-2.811v-5.172a1.5 1.5 0 011.5-1.5v0a1.5 1.5 0 011.5 1.5v3.75"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M8.25 24.106v-3a4.5 4.5 0 00-.757-2.5L6.351 16.9a1.4 1.4 0 00-1.977-.444v0a1.4 1.4 0 00-.476 1.79l1.352 2.25M20.25 24.242l2.014-2.517a4.5 4.5 0 00.986-2.811v-5.172a1.5 1.5 0 00-1.5-1.5v0a1.5 1.5 0 00-1.5 1.5v3.75"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M15.75 24.106v-3c0-.89.263-1.76.757-2.5l1.142-1.706a1.4 1.4 0 011.977-.444v0a1.4 1.4 0 01.476 1.79l-1.352 2.25"
            ></path>
        </svg>
    )
}

export default Icon
