import styled from 'styled-components'

import { transition, media } from '@variables'
import { AccessibleButton } from '../../shared/styled'

export const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
`

export const More = styled(AccessibleButton)`
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    transition: ${transition.main};

    ${media.tablet} {
        :hover {
            svg {
                right: -5px;
            }
        }
    }

    svg {
        right: 0px;
        width: 14px;
        height: 12px;
        margin-left: 5px;
        position: relative;
        transition: ${transition.main};
    }
`
