import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="none"
            viewBox="0 0 22 22"
            {...props}
        >
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M7.81 16.183a17.588 17.588 0 00-5.01-.884 1.243 1.243 0 01-1.175-1.246V2.917a1.25 1.25 0 011.337-1.25C8.232 1.942 11 3.823 11 5.262c0-1.429 3.096-3.298 8.034-3.59a1.25 1.25 0 011.341 1.25v10.5M11 5.262V8.5M8.785 6.457A20.36 20.36 0 004.14 5.373M17.178 8.684c.289-.047.584-.09.886-.129M8.785 9.636A20.843 20.843 0 004.14 8.552M8.785 13.005a21.032 21.032 0 00-4.687-1.083M13.417 6.463c1.516-.54 3.09-.904 4.69-1.084"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M20.28 20.376l.083-.935a2.5 2.5 0 00-1.947-2.667l-3.043-.676v-4.785a1.563 1.563 0 00-3.125 0v7.671l-1.23-.923a1.278 1.278 0 00-1.789 1.788l.395.527"
            ></path>
        </svg>
    )
}

export default Icon
