import { useBeforeUnload } from 'react-use'
import React, { useEffect, useContext } from 'react'
import BrowserInteractionTime from 'browser-interaction-time'

import { AuthContext } from './Auth'
import { useAddTimeSpentMutation, Role } from '@graphql'

type Props = {
    children: React.ReactNode
}

type Context = {
    // addTime: () => any
}

export const TimeContext = React.createContext<Context>({
    // addTime: async () => {}
})

const STORAGE_NAME = 'TIME_SPENT_IN_INVESTO_APP'

const { reset, destroy, startTimer, getTimeInMilliseconds } = new BrowserInteractionTime({
    timeIntervalEllapsedCallbacks: [],
    absoluteTimeEllapsedCallbacks: [],
    browserTabInactiveCallbacks: [],
    browserTabActiveCallbacks: [],
    stopTimerOnTabchange: false,
    idleTimeoutMs: 3000
})

const TimeProvider = ({ children }: Props) => {
    const [addTimeSpent] = useAddTimeSpentMutation()
    const { isAuthorized, user } = useContext(AuthContext)

    // Track only if user is STUDENT and Authorized
    const noNeedToTrack = !isAuthorized || user?.role !== Role.STUDENT

    const beforeUnload = () => {
        if (noNeedToTrack) return false

        const miliseconds = getTimeInMilliseconds()
        const seconds = Math.floor(miliseconds / 1000)

        if (seconds <= 0) return false

        console.log(`Time in-app: ${seconds} sec`)
        const secondFromLocalStorage = localStorage.getItem(STORAGE_NAME)

        localStorage.setItem(
            STORAGE_NAME,
            `${secondFromLocalStorage ? parseInt(secondFromLocalStorage, 10) + seconds : seconds}`
        )

        reset()
        destroy()

        console.log('Stop: time tracking')

        return false
    }

    // Hook that fires when user try to reload or close the page
    useBeforeUnload(beforeUnload, '')

    const addTime = async () => {
        const sec = localStorage.getItem(STORAGE_NAME)
        if (!sec) return

        const seconds = parseInt(sec, 10)
        if (seconds <= 0) return

        const { data, errors } = await addTimeSpent({
            variables: { seconds }
        })

        if (data && !errors) {
            localStorage.removeItem(STORAGE_NAME)
        }
        if (errors) {
            errors.forEach((err) => {
                console.error(err.message)
            })
        }
    }

    useEffect(() => {
        if (noNeedToTrack) {
            return
        }

        console.log('Start: time tracking')
        startTimer()

        addTime()
    }, [isAuthorized, user])

    return <TimeContext.Provider value={{}}>{children}</TimeContext.Provider>
}

export default TimeProvider
