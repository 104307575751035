import React, { useState } from 'react'
import styled from 'styled-components'

import * as loco from '@loco'
import { colors } from '@variables'
import { AccessibleButton } from '../styled'

export const Wrap = styled(AccessibleButton)`
    font-size: 14px;
    letter-spacing: 0.25px;
    color: ${colors.primary.orange};
`

type Props = {
    max: number
    text: string
    onClick?: () => void
}

const TruncatedText = ({ text, max, onClick }: Props) => {
    const [showMore, setShowMore] = useState(false)

    if (!text)
        return (
            <>
                <span></span>
            </>
        )
    if (text.length < max)
        return (
            <>
                <span>{text}</span>
            </>
        )

    if (text.length > max && !showMore) {
        return (
            <>
                <span role="button" style={{ cursor: 'pointer' }} onClick={onClick}>
                    {text.slice(0, max)}
                </span>
                {'.. '}
                <Wrap onClick={() => setShowMore(!showMore)}>
                    {showMore ? loco.common.less : loco.common.more}
                </Wrap>
            </>
        )
    }
    return (
        <>
            <span role="button" style={{ cursor: 'pointer' }} onClick={onClick}>
                {text}
            </span>{' '}
            <Wrap onClick={() => setShowMore(!showMore)}>
                {showMore ? loco.common.less : loco.common.more}
            </Wrap>
        </>
    )
}

export default TruncatedText
