import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import React, { useContext, CSSProperties } from 'react'

import * as loco from '@loco'
import CoinsIcon from '../../SVG/Coins'
import Icon from '../../SVG/DiplomaIcon'
import { Routes } from '@variables'
import { TestPreviewVariant } from '../../shared'
import { AuthContext } from '../../../context/Auth'
import { getRouteWithId, getServiceUrl } from '@utils'
import {
    Wrapper,
    Text,
    TextWrapper,
    IconWrapper,
    CoinsWrapper,
    ButtonWrapper,
    Button
} from './styled'

type Props = {
    testId?: string | null
    testScore: {
        maxPoints?: number | null
        acquiredPoints?: number | null
    }
}

const DiplomaBox = ({ testId, testScore }: Props) => {
    const { user } = useContext(AuthContext)

    const isCompleted = Boolean(user?.student?.finalTestStatus.isCompleted)

    const variant = getVariant(isCompleted, testId)
    const text = getText(variant)
    const points = `${user?.student?.finalTestStatus?.acquiredPoints || 0}/${
        user?.student?.finalTestStatus?.requiredPoints || 0
    } ${loco.common.points}`
    const linkStyle: CSSProperties =
        variant === TestPreviewVariant.NOT_ALLOW
            ? { width: '100%', cursor: 'not-allowed', pointerEvents: 'none' }
            : { width: '100%' }

    return (
        <Wrapper>
            <TextWrapper>
                <Typography variant="h5">{loco.profile.diplom['start-final']}</Typography>
                <CoinsWrapper>
                    <CoinsIcon color="white" style={{ width: 20, height: 20 }} />
                    <Typography paragraph>{points}</Typography>
                </CoinsWrapper>
                <Text paragraph>{text}</Text>
            </TextWrapper>
            <ButtonWrapper>
                {isCompleted && (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ width: '100%' }}
                        href={getServiceUrl('diploma')}
                    >
                        <Button fullWidth size="large" color="secondary" variant="contained">
                            {loco.profile.diplom.finished.button}
                        </Button>
                    </a>
                )}

                {!isCompleted && (
                    <Link to={getRouteWithId(Routes.TEST, testId || '#')} style={linkStyle}>
                        <Button
                            fullWidth
                            size="large"
                            color="secondary"
                            variant="contained"
                            disabled={variant === TestPreviewVariant.NOT_ALLOW}
                        >
                            {loco.profile.diplom.start}
                        </Button>
                    </Link>
                )}
            </ButtonWrapper>
            <IconWrapper>
                <Icon />
            </IconWrapper>
        </Wrapper>
    )
}

const getText = (variant: TestPreviewVariant): string => {
    if (variant === TestPreviewVariant.REPEAT) return loco.profile.diplom.finished.subtitle
    if (variant === TestPreviewVariant.NOT_ALLOW) return loco.chapter.test.subtitle5
    return loco.chapter.test.subtitle4
}

const getVariant = (isCompleted: boolean, testId?: string | null): TestPreviewVariant => {
    // REPEAT is completed => download diplom
    if (isCompleted) return TestPreviewVariant.REPEAT
    if (Boolean(testId)) return TestPreviewVariant.ALLOW
    return TestPreviewVariant.NOT_ALLOW
}

export default DiplomaBox
