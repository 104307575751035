import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="134"
            height="40"
            fill="none"
            viewBox="0 0 134 40"
            {...props}
        >
            <path
                fill="#F8A531"
                d="M42.037 39.879h5.225v-.743h-4.418V36.62h3.902v-.744h-3.902v-2.454h4.367v-.744h-5.174v7.2zm-4.41.121c1.29 0 2.123-.501 2.863-1.262l-.533-.527c-.688.657-1.333 1.037-2.312 1.037-1.582 0-2.768-1.305-2.768-2.973v-.017c0-1.651 1.186-2.948 2.768-2.948.98 0 1.633.415 2.252.986l.55-.597c-.705-.691-1.496-1.14-2.794-1.14-2.097 0-3.618 1.685-3.618 3.724v.018c0 2.066 1.53 3.699 3.593 3.699zm-11.508-.121h.791v-5.903l4.624 5.903h.645v-7.2h-.791v5.765l-4.512-5.765h-.757v7.2zm-8.775 0h.825l.851-1.901h3.893l.843 1.901h.868l-3.258-7.252h-.756l-3.266 7.252zm1.994-2.636l1.633-3.673 1.624 3.673h-3.257zm-9.54 2.636h.79v-5.903l4.625 5.903h.644v-7.2h-.79v5.765l-4.504-5.765h-.765v7.2zm-3.051 0h.808v-7.2h-.808v7.2zm-6.738 0h.807v-3.137h3.877v-.744H.816v-2.567h4.332v-.752H.008v7.2z"
            ></path>
            <path
                fill="#fff"
                d="M14.018 8.92c0 2.126-1.582 3.75-4.297 3.75H5.423V5.1h4.194c2.708 0 4.401 1.313 4.401 3.75v.07zm5.492-.104v-.07c0-5.099-3.558-8.573-9.437-8.573H0V24.96h5.423v-7.433H9.54c5.535 0 9.97-2.938 9.97-8.712zM45.922 24.97L35.358 0h-5.003L19.794 24.97h5.526l2.252-5.558h.008l1.951-4.814h-.017L32.79 6.56l2.724 6.698.026.026 2.458 6.137 2.252 5.557h5.672v-.008zM58.005 5.203h7.503V.173h-20.43v5.03h7.503v19.758h5.424V5.203zm27.357 3.51c0 2.125-1.479 3.508-4.152 3.508H75.72V5.1h5.39c2.672 0 4.262 1.167 4.262 3.544v.07h-.008zm6.24 16.257l-6.026-8.85c3.138-1.168 5.286-3.683 5.286-7.684v-.078c0-2.368-.705-4.253-2.114-5.67C87.09 1.02 84.7.173 81.564.173H70.296V24.96h5.423v-7.934h4.263l5.286 7.934h6.334v.009zm6.3 0h5.423V.138h-5.424V24.97zm36.097 0L123.429 0h-5.003l-10.562 24.97h5.526l2.252-5.558 1.951-4.814h-.017l3.274-8.038 2.733 6.698.017.018 2.45 6.136h.008l2.252 5.558H134z"
            ></path>
            <path
                fill="#F8A531"
                d="M120.927 17.269l-3.829 3.85 3.829 3.85 3.829-3.85-3.829-3.85zM32.856 17.263l-3.829 3.85 3.829 3.85 3.828-3.85-3.828-3.85z"
            ></path>
        </svg>
    )
}

export default Icon
