import styled from 'styled-components'
import MuiButton from '@material-ui/core/Button'

import { TestPreviewVariant } from '../shared'
import { colors, media } from '@variables'

export const Button = styled(MuiButton)``

export const Wrapper = styled.div<{ readonly variant: TestPreviewVariant }>`
    width: 100%;
    padding: 30px;
    display: flex;
    margin-top: 40px;
    border-radius: 5px;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${(props) =>
        ((props.variant === TestPreviewVariant.NOT_ALLOW ||
            props.variant === TestPreviewVariant.ALLOW) &&
            colors.primary.darkBlue) ||
        '#E5E7EB'};

    ${(props) =>
        (props.variant === TestPreviewVariant.ALLOW ||
            props.variant === TestPreviewVariant.NOT_ALLOW) &&
        `color: white;`}

    ${media.tablet} {
        padding: 50px;
    }
    ${media.desktop} {
        margin-top: 0px;
        height: 100%;
    }

    ${Button} {
        color: white !important;
        border: none !important;
        box-shadow: none !important;

        :disabled {
            background-color: #c0c0c0 !important;
        }
    }
`
