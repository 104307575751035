import React from 'react'
import { useSetState } from 'react-use'
import { useSnackbar } from 'notistack'

import * as loco from '@loco'
import { translateGQLError } from '@utils'
import { Role, useUserActivityQuery, useStudentActivityQuery } from '@graphql'

import { Container } from './styled'
import Head from '../../../../components/Head'
import { Tab, Tabs } from '../../../../components/shared/styled'
import UserActivityTable from '../../../../components/Tables/UserActivity'
import StudentActivityTable from '../../../../components/Tables/StudentActivity'

enum Title {
    STUDENTS = 'Studenti',
    ADMINS = 'Administrátoři'
}

type State = {
    title: Title
    admins: {
        page: number
        itemsPerPage: number
    }
    students: {
        page: number
        itemsPerPage: number
    }
}

const Activity = () => {
    const { enqueueSnackbar } = useSnackbar()

    const [state, setState] = useSetState<State>({
        title: Title.ADMINS,
        admins: {
            page: 0,
            itemsPerPage: 10
        },
        students: {
            page: 0,
            itemsPerPage: 10
        }
    })

    const { title } = state

    const { data: adminsActivityData, loading: adminsActivityLoading } = useUserActivityQuery({
        fetchPolicy: 'cache-and-network',
        skip: title !== Title.ADMINS,
        onError: (error) => enqueueSnackbar(translateGQLError(error.message), { variant: 'error' }),
        variables: {
            page: state.admins.page,
            itemsPerPage: state.admins.itemsPerPage
        }
    })

    const { data: studentActivityData, loading: studentActivityLoading } = useStudentActivityQuery({
        fetchPolicy: 'cache-and-network',
        skip: title !== Title.STUDENTS,
        onError: (error) => enqueueSnackbar(translateGQLError(error.message), { variant: 'error' }),
        variables: {
            page: state.students.page,
            itemsPerPage: state.students.itemsPerPage,
            filter: {
                role: Role.STUDENT
            }
        }
    })

    const setTitle = (newTitle: Title) => () => setState({ title: newTitle })

    const onChangePage = (page: number) => {
        if (title === Title.ADMINS) {
            setState({
                ...state,
                admins: {
                    ...state.admins,
                    page: page
                }
            })
        }
        if (title === Title.STUDENTS) {
            setState({
                ...state,
                students: {
                    ...state.students,
                    page: page
                }
            })
        }
    }

    const onChangeRowsPerPage = (pageSize: number) => {
        if (title === Title.ADMINS) {
            setState({
                ...state,
                admins: {
                    ...state.admins,
                    itemsPerPage: pageSize
                }
            })
        }
        if (title === Title.STUDENTS) {
            setState({
                ...state,
                students: {
                    ...state.students,
                    itemsPerPage: pageSize
                }
            })
        }
    }

    const adminsData = adminsActivityData?.userActivity.data || []
    const adminsMeta = adminsActivityData?.userActivity.meta

    const studentsData = studentActivityData?.studentActivity.data || []
    const studentsMeta = studentActivityData?.studentActivity.meta

    return (
        <>
            <Head title={loco.seo.activity.title} />

            <Container>
                <Tabs>
                    <Tab
                        type="button"
                        active={title === Title.ADMINS}
                        onClick={setTitle(Title.ADMINS)}
                    >
                        {Title.ADMINS}
                    </Tab>
                    <Tab
                        type="button"
                        active={title === Title.STUDENTS}
                        onClick={setTitle(Title.STUDENTS)}
                    >
                        {Title.STUDENTS}
                    </Tab>
                </Tabs>

                {title === Title.ADMINS && (
                    <UserActivityTable
                        data={adminsData}
                        page={state.admins.page}
                        onChangePage={onChangePage}
                        loading={adminsActivityLoading}
                        totalCount={adminsMeta?.items || 0}
                        itemsPerPage={state.admins.itemsPerPage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                    />
                )}

                {title === Title.STUDENTS && (
                    <StudentActivityTable
                        data={studentsData}
                        page={state.students.page}
                        onChangePage={onChangePage}
                        loading={studentActivityLoading}
                        totalCount={studentsMeta?.items || 0}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        itemsPerPage={state.students.itemsPerPage}
                    />
                )}
            </Container>
        </>
    )
}

export default Activity
