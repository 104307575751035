import React from 'react'
import Container from '@material-ui/core/Container'

import * as loco from '@loco'
import Icon from '../../SVG/Loader'
import { Wrapper, Text } from './styled'

type Props = {
    pure?: boolean
    style?: object
    halfHeight?: boolean
}

const Loader = ({ style, halfHeight, pure }: Props) => {
    if (pure) {
        return (
            <Wrapper style={style || {}}>
                <Icon />
                <Text align="center" variant="h6">
                    {loco.loader.title} ..
                </Text>
            </Wrapper>
        )
    }

    return (
        <Container
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: halfHeight ? '40vh' : '80vh'
            }}
        >
            <Wrapper style={style || {}}>
                <Icon />
                <Text align="center" variant="h6">
                    {loco.loader.title} ..
                </Text>
            </Wrapper>
        </Container>
    )
}

export default Loader
