/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useRef, ChangeEvent, useEffect } from 'react'
import { useSetState } from 'react-use'
import { get, sample } from 'lodash'

import { useLibraryAudioItemQuery, LibraryAudioItemQuery, AudioFragment } from '@graphql'

let seeking = false

export const AudioPlayerContext = createContext({
    file: {
        id: '',
        src: '',
        name: '',
        chapter: '',
        hasPrev: false,
        hasNext: false
    },

    played: 0,
    loaded: 0,
    duration: 0,
    volume: 0.2,
    player: null,
    muted: false,
    playing: false,
    playbackRate: 1.0,

    togglePlay: () => {},
    pause: () => {},
    play: () => {},
    stop: () => {},
    getPrev: () => {},
    getNext: () => {},
    load: (fileId: string, src: string) => {},
    onVolumeChange: (event: ChangeEvent<{}>, value: number | number[]): void => {},
    toggleMuted: () => {},
    setPlaybackRate: (event: any) => {},
    setDuration: (duration: number) => {},
    onProgress: (val: {
        loaded: number
        played: number
        playedSeconds: number
        loadedSeconds: number
    }) => {},
    onSeekMouseDown: (event: any): void => {},
    onSeekChange: (event: ChangeEvent<{}>, value: number | number[]) => {},
    onSeekMouseUp: (event: ChangeEvent<{}>, value: number | number[]) => {}
})

type Props = {
    children: any
}

type State = {
    file: {
        id: string
        src: string
        name: string
        chapter: string
        hasPrev: boolean
        hasNext: boolean
    }
    muted: boolean
    played: number
    loaded: number
    volume: number
    playing: boolean
    duration: number
    playbackRate: number
}

// https://github.com/CookPete/react-player/blob/master/src/demo/App.js
const AudioPlayerProvider = ({ children }: Props) => {
    const [state, setState] = useSetState<State>({
        file: {
            id: '',
            src: '',
            name: '',
            chapter: '',
            hasPrev: false,
            hasNext: false
        },
        played: 0,
        loaded: 0,
        duration: 0,
        volume: 0.2,
        muted: false,
        playing: false,
        playbackRate: 1.0
    })

    const player: any = useRef(null)

    const { data, refetch } = useLibraryAudioItemQuery({
        skip: !Boolean(state.file.id),
        onError: (err) => console.error(err),
        variables: { where: { id: state.file.id } },
        onCompleted: (data: LibraryAudioItemQuery) => {
            const similarItems: AudioFragment[] | [] = get(data, 'libraryItem.similarItems', [])

            setState({
                file: {
                    id: state.file.id,
                    src: state.file.src,
                    hasPrev: similarItems.length >= 1,
                    hasNext: similarItems.length >= 2,
                    name: get(data, 'libraryItem.name', ''),
                    chapter: get(data, 'libraryItem.lecture.chapter.name', '')
                }
            })
        }
    })

    useEffect(() => {
        if (state.file?.id) refetch()
    }, [state.file.id])

    const load = (fileId: string, src: string) => {
        setState({
            played: 0,
            loaded: 0,
            playing: true,
            file: {
                ...state.file,
                id: fileId,
                src: src
            }
        })
    }

    const getPrev = () => {
        const currentFile = data?.libraryItem
        const similarItems: AudioFragment[] | [] = get(currentFile, 'similarItems', [])

        if (similarItems.length > 0) {
            setState({
                played: 0,
                loaded: 0,
                playing: true,
                file: {
                    ...state.file,
                    id: sample(similarItems)?.id || '',
                    src: sample(similarItems)?.src || ''
                }
            })
        }
    }

    const getNext = () => {
        const currentFile = data?.libraryItem
        const similarItems: AudioFragment[] | [] = get(currentFile, 'similarItems', [])

        if (similarItems.length > 0) {
            setState({
                played: 0,
                loaded: 0,
                playing: true,
                file: {
                    ...state.file,
                    id: sample(similarItems)?.id || '',
                    src: sample(similarItems)?.src || ''
                }
            })
        }
    }

    const play = () => setState({ playing: true })

    const pause = () => setState({ playing: false })

    const togglePlay = () => setState({ playing: !state.playing })

    const stop = () =>
        setState({
            playing: false,
            file: { ...state.file, src: '', id: '', name: '', chapter: '' }
        })

    const onVolumeChange = (e: ChangeEvent<{}>, value: number | number[]) => {
        if (Array.isArray(value)) return
        if (value === 0) return setState({ volume: value, muted: true })
        return setState({ volume: value, muted: false })
    }

    const toggleMuted = () => setState({ muted: !state.muted })

    const setDuration = (duration: number) => setState({ duration: duration })

    const setPlaybackRate = (event: any) => {
        setState({ playbackRate: parseFloat(event.target.value) })
    }

    const onProgress = (newState: {
        loaded: number
        played: number
        playedSeconds: number
        loadedSeconds: number
    }) => {
        if (!seeking) {
            setState({
                loaded: newState.loadedSeconds,
                played: newState.playedSeconds,
                duration: player.current.getDuration()
            })
        }
    }

    const onSeekMouseDown = (event: any): void => {
        seeking = true
    }

    const onSeekChange = (e: ChangeEvent<{}>, value: number | number[]): void | undefined => {
        if (Array.isArray(value)) return
        setState({ played: value })
    }

    const onSeekMouseUp = (e: ChangeEvent<{}>, value: number | number[]): void | undefined => {
        seeking = false
        if (Array.isArray(value)) return
        player.current.seekTo(value)
    }

    return (
        <AudioPlayerContext.Provider
            value={{
                ...state,
                load,
                player,
                getPrev,
                getNext,
                stop,
                play,
                pause,
                setDuration,
                onProgress,
                togglePlay,
                onSeekChange,
                onSeekMouseUp,
                toggleMuted,
                onVolumeChange,
                onSeekMouseDown,
                setPlaybackRate
            }}
        >
            {children}
        </AudioPlayerContext.Provider>
    )
}

export default AudioPlayerProvider
