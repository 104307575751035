import React from 'react'
import PlayIcon from '@material-ui/icons/PlayArrow'

import { PlayBtnVideo, PlayBtnAudio } from './styled'

type Props = {
    type: string
    isSmall?: boolean
    role?: string
    playBtnColor: string
    onClick?: () => void
}

const PlayButton = ({ type, isSmall, role, playBtnColor, onClick }: Props) =>
    type === 'video' ? (
        <PlayBtnVideo isSmall={isSmall} onClick={onClick} role={role || 'button'}>
            <PlayIcon style={{ color: playBtnColor }} />
        </PlayBtnVideo>
    ) : (
        <PlayBtnAudio onClick={onClick} role={role || 'button'}>
            <PlayIcon style={{ color: playBtnColor }} />
        </PlayBtnAudio>
    )

export default PlayButton
