import React from 'react'

import { Wrapper, Inner, IconWrapper, Icon, Svg, Rect } from './styled'

export type Props = {
    color: string
    value: number
    icon?: string | null
    size: 'medium' | 'large'
}

const IconWithProgressBar = ({ icon, color, value, size }: Props) => (
    <Wrapper size={size} value={value}>
        {icon && (
            <Inner size={size}>
                <IconWrapper>
                    <Icon src={icon} alt="Chapter icon" size={size} />
                </IconWrapper>
            </Inner>
        )}

        <Svg
            width="95"
            height="95"
            fill="none"
            viewBox="0 0 95 95"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Rect
                x="1"
                y="1"
                rx="4"
                width="93"
                height="93"
                strokeWidth="2"
                stroke={color}
                progress={value * 3.6}
            />
        </Svg>
    </Wrapper>
)

export default IconWithProgressBar
