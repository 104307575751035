import styled from 'styled-components'
import MuiButton from '@material-ui/core/Button'
import Facebook from '@material-ui/icons/Facebook'
import Typography from '@material-ui/core/Typography'
import MuiContainer from '@material-ui/core/Container'
import EditOutlined from '@material-ui/icons/EditOutlined'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'

import Logo from '../../../../../components/SVG/AdminLogo'
import { colors, transition, media } from '@variables'
import Google from '../../../../../components/SVG/GoogleIcon'
import { StartButton } from '../../../../../components/shared/styled'

export const Title = styled(Typography)``
export const PersonalBtn = styled(MuiButton)``
export const DeactivateButton = styled(MuiButton)``
export const ExpansionPanel = styled(MuiExpansionPanel)``
export const ExpansionPanelSummary = styled(MuiExpansionPanelSummary)``
export const ExpansionPanelDetails = styled(MuiExpansionPanelDetails)``

export const SendEmailButton = styled(StartButton)`
    font-weight: 500;
    font-size: 12px;
`

export const ErrorLabel = styled.span`
    font-size: 10px;
    color: ${colors.primary.orange};
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
`

export const EmailFieldWrapper = styled.div<{ readonly isEmailVerified?: boolean }>`
    width: 100%;
    position: relative;

    ${SendEmailButton} {
        position: absolute;
        top: 32px;
        right: 0;
    }

    ${(props) =>
        !props.isEmailVerified &&
        `
        margin-bottom: 10px;
    `}
`

export const Container = styled(MuiContainer)`
    padding-left: 0 !important;
    padding-right: 0px !important;

    ${media.custom(600)} {
        max-width: 570px !important;
    }

    ${Title} {
        padding-left: 10px;
        font-size: 20px;
    }

    ${ExpansionPanel} {
        width: 100%;
        padding: 0 30px;
        background-color: transparent;

        @media (max-width: 520px) {
            padding: 0 10px;
        }
    }

    ${ExpansionPanelDetails} {
        padding: 0;
        flex-direction: column;
    }

    ${ExpansionPanelSummary} {
        min-height: 60px;
        padding: 0px;
    }

    ${DeactivateButton} {
        font-weight: 500;
        color: ${colors.primary.red};

        ${media.tablet} {
            :hover {
                border-color: ${colors.primary.red};
            }
        }
    }

    ${PersonalBtn} {
        height: 44px;
        padding: 0 30px;
        font-size: 11px !important;
        color: ${colors.primary.darkBlue};

        ${media.tablet} {
            :hover {
                color: ${colors.primary.orange};
            }
        }
    }
`

export const TitleWrapper = styled.div`
    margin: 35px 0 30px 0;

    @media (max-width: 600px) {
        margin: 25px;
    }

    h1 {
        line-height: normal;
        font-weight: 400;
        font-size: 18px;
    }
`

export const Section = styled.section`
    display: flex;
    border-radius: 5px;
    position: relative;
    background: #ffffff;
    align-items: center;
    margin-bottom: 50px;
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px 40px 20px;
    box-shadow: 0px 30px 100px rgba(42, 48, 57, 0.05);

    ${media.phone} {
        padding: 60px 70px;
    }

    .MuiCollapse-container {
        border: none;
    }

    .MuiExpansionPanelSummary-root {
        background-color: transparent;

        svg {
            opacity: 0.5;
            color: ${colors.primary.darkBlue};
        }
    }
    .MuiExpansionPanelSummary-content {
        align-items: center;
        margin: 15px 0;
    }
    .MuiExpansionPanelSummary-content.Mui-expanded {
        margin: 10px 0;
    }

    .MuiInput-input {
        &::placeholder {
            font-size: 14px;
        }
    }
`

export const AvatarWrapper = styled.div`
    width: 120px;
    height: 120px;
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 120px;
    flex-direction: column;
    justify-content: center;
`

export const ChooseAvatar = styled.label`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    display: flex;
    cursor: pointer;
    border-radius: 40px;
    align-items: center;
    z-index: 2;
    justify-content: center;
    background-color: white;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.05);

    svg {
        color: ${colors.primary.darkBlue};
        transition: ${transition.main};
    }

    ${media.tablet} {
        :hover {
            transform: scale(1.1);
            box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.05);
        }
    }
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const Col = styled.div`
    display: flex;
    flex-direction: column;
`

export const PlatformIcon = styled(Logo)``
export const GoogleIcon = styled(Google)``
export const FacebookIcon = styled(Facebook)``

export const SocialWrapper = styled(Col)`
    ${Row} {
        margin: 20px 0;
        align-items: center;
        justify-content: flex-start;

        svg {
            margin-right: 20px;
        }
    }

    span {
        font-weight: 700;
        letter-spacing: 0.5px;
    }

    ${GoogleIcon} {
        height: 22px;
    }

    ${FacebookIcon} {
        margin-right: 14px;
        color: #4968ad;
        height: 28px;
    }
`

export const EditSchoolIcon = styled(EditOutlined)``

export const SchoolInputWrapper = styled.div`
    width: 100%;
    position: relative;

    input {
        width: calc(100% - 30px);
    }

    ${media.tablet} {
        :hover {
            ${EditSchoolIcon} {
                transform: scale(1.1);
            }
        }
    }

    ${EditSchoolIcon} {
        right: 0;
        top: 33px;
        z-index: 2;
        cursor: pointer;
        position: absolute;
        background-color: white;
        color: ${colors.primary.orange};
        transition: ${transition.main};
    }
`
