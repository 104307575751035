import React from 'react'
import { string, object } from 'yup'
import { useSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import { Formik, Form, Field, FormikProps, FormikHelpers } from 'formik'

import { Step } from '..'
import * as loco from '@loco'
import { translateGQLError } from '@utils'
import InputField from '../../../Fields/Input'
import { useRecaptcha } from '../../../../context/Recaptcha'
import { useForgottenPasswordResetMutation } from '@graphql'
import { ButtonWrapper, Wrapper, H3, Text, BackButton } from '../styled'

type Props = {
    goBack: () => void
    setActiveStep: (step: Step) => void
}

const initialValues = Object.freeze({ email: '' })

type FormValues = typeof initialValues

const ForgotPassword = ({ setActiveStep, goBack }: Props) => {
    const recaptcha = useRecaptcha()
    const { enqueueSnackbar } = useSnackbar()

    const [forgottenPwdReset, { loading }] = useForgottenPasswordResetMutation()

    const submit = async ({ email }: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
        const token = await recaptcha.execute('forgotten-password')

        const { data, errors } = await forgottenPwdReset({
            variables: { data: { identificator: email } },
            context: {
                headers: {
                    'X-Recaptcha-Token': token
                }
            }
        })

        if (data && !errors) {
            resetForm()
            setActiveStep(Step.AFTER_FORGOT_PASSWORD)
        }

        errors?.forEach((err) =>
            enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
        )
    }

    return (
        <Wrapper>
            <H3>{loco.dialogs['forgot-password'].title}</H3>
            <Text>{loco.dialogs['forgot-password'].subtitle}</Text>
            <Formik
                onSubmit={submit}
                enableReinitialize
                isInitialValid={false}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {({ isValid, isSubmitting, values }: FormikProps<FormValues>) => (
                    <Form style={{ width: '100%' }} spellCheck={false}>
                        <Field
                            fullWidth
                            autoFocus
                            key="email"
                            name="email"
                            value={values.email}
                            component={InputField}
                            className="customInput"
                            label={loco.dialogs['restore-with-email'].input.label}
                            placeholder={loco.dialogs['restore-with-email'].input.placeholder}
                        />

                        <ButtonWrapper>
                            <Button
                                fullWidth
                                size="large"
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={!isValid || loading || isSubmitting}
                            >
                                {loco.dialogs['restore-with-email'].button}
                            </Button>
                        </ButtonWrapper>
                    </Form>
                )}
            </Formik>
            <BackButton
                fullWidth
                size="large"
                type="button"
                color="primary"
                onClick={goBack}
                variant="outlined"
            >
                {loco.common.back}
            </BackButton>
        </Wrapper>
    )
}

const validationSchema = object({
    email: string().email(loco.validation.email.common).required(loco.validation.common.required)
})

export default ForgotPassword
