import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import { media } from '@variables'

export const Title1 = styled(Typography)``
export const Title3 = styled(Typography)``

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    ${Title1} {
        font-weight: 700;
        font-size: 28px;
        line-height: 120%;
        letter-spacing: 1px;

        ${media.desktop} {
            font-size: 36px;
            line-height: 48px;
        }
    }
    ${Title3} {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
    }
`
