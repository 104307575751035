import styled from 'styled-components'

import { Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CloseRounded'
import { transition, colors, media } from '@variables'

export const Title = styled(Typography)``
export const Close = styled(CloseIcon)`
    cursor: pointer;
    background-color: white;
    transition: ${transition.main};

    ${media.tablet} {
        :hover {
            transform: scale(1.2);
        }
    }
`

export const Description = styled(Typography)`
    height: 40px;
    display: block;
    overflow: hidden;
    overflow-wrap: anywhere;
    color: ${colors.primary.gray1};
`

export const Wrapper = styled.div<{ readonly disabled: boolean }>`
    width: 100%;
    height: 100px;
    display: flex;
    padding: 20px;
    margin: 15px 0;
    position: relative;
    align-items: center;
    border-radius: 10px;
    background-color: white;
    justify-content: flex-start;
    box-shadow: 0px 12px 60px rgba(42, 48, 57, 0.1);

    ${Title} {
        height: 25px;
        overflow: hidden;
        margin-bottom: 5px;
        overflow-wrap: anywhere;
    }

    ${Close} {
        top: 15px;
        right: 15px;
        width: 18px;
        height: 18px;
        position: absolute;
        color: ${colors.primary.gray2};
    }

    ${Close} {
        ${(props) =>
            props.disabled &&
            `
            pointer-events: none;
    `}
    }
`

export const Img = styled.img`
    width: 52px;
    min-width: 52px;
    height: 52px;
    min-height: 52px;
    border-radius: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`
