import styled from 'styled-components'
import HomeIcon from '@material-ui/icons/Home'
import { Link as RLink } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

import { transition, colors } from '@variables'

export const Link = styled(RLink)``
export const Text = styled(Typography)``
export const Icon = styled(HomeIcon)``

export const Wrapper = styled.nav`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;

    span {
        transition: ${transition.main};
    }

    ${Link} {
        margin-bottom: 10px;

        :not(:first-of-type) {
            ${Text} {
                padding-left: 5px;
            }
        }
    }

    ${Icon} {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        color: ${colors.primary.orange};
    }

    ${Text} {
        display: inline-flex;
        color: ${colors.primary.orange};
    }
`
