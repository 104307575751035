import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Repeat from '@material-ui/icons/Repeat'

import * as loco from '@loco'
import { Routes } from '@variables'
import CoinsIcon from '../../../SVG/Coins'
import WrongIcon from '../../../SVG/WrongIcon'
import { SessionFragment } from '@graphql'
import CorrectIcon from '../../../SVG/CorrectIcon'
import TipCharacter from '../../../SVG/TipCharacter'
import { getRouteWithId, getServiceUrl } from '@utils'
import LastStepFinalTest from '../../../SVG/LastStepFinalTest'
import TestLastStepTitle from '../../../SVG/TestLastStepTitle'
import { AuthContext } from '../../../../context/Auth'
import StatisticPreview from '../../../shared/StatisticPreview'
import TestLastStepTitleFail from '../../../SVG/TestLastStepTitleFail'
import TestLastStepTitleSuccess from '../../../SVG/TestLastStepTitleSuccess'
import { DIALOG_ID, DialogContext } from '../../../../context/Dialog'
import {
    Text,
    Wrapper,
    TipResults,
    IsRightText,
    TipPriceText,
    ButtonWrapper,
    LastStepCoins,
    NextButtonWrapper,
    RepeatButtonWrapper,
    TestLastStepIconWrapper
} from './styled'

type Props = {
    isLoading: boolean
    finalTestId?: string | null
    session?: SessionFragment | null
    createNewTestSession: () => void
}

const LastStep = ({ session, finalTestId, isLoading, createNewTestSession }: Props) => {
    const history = useHistory()

    const { user } = useContext(AuthContext)
    const { setOpen } = useContext(DialogContext)

    const isTempUser = Boolean(user?.isTemporary)

    const nextLecture = session?.nextLecture
    const isThereNextLecture = Boolean(nextLecture)
    const summaryTest = session?.test.lecture?.chapter.summaryTest
    const isChapterSummary = Boolean(session?.test.isChapterSummary)

    const pointsTotal = session?.pointsTotal
    const wrongAnswersCount = session?.wrongAnswersCount
    const correctAnswersCount = session?.correctAnswersCount

    const isFinalTest = Boolean(session?.test.isFinalTest)
    const isFinalTestCompleted = Boolean(user?.student?.finalTestStatus.isCompleted)

    const isLastChapterSumTestAndFinalTestCompleted =
        !isThereNextLecture && isChapterSummary && isFinalTestCompleted

    const isNextLectureInDifChapter =
        isThereNextLecture && nextLecture?.chapter.id !== session?.test.chapter?.id

    const testResults =
        correctAnswersCount === 0
            ? 0
            : Math.round(((correctAnswersCount || 1) / (session?.testSteps.length || 1)) * 100)

    const header = getHeader(isFinalTest, isFinalTestCompleted)
    const btnText = getTextButton(
        isTempUser,
        isFinalTest,
        isFinalTestCompleted,
        isThereNextLecture,
        isChapterSummary,
        isNextLectureInDifChapter
    )

    const next = () => {
        if (isTempUser) {
            setOpen(DIALOG_ID.REGISTRATION)
            return history.push(Routes.HOME)
        }

        // If next lecture is chapter summary
        if (!isThereNextLecture && summaryTest?.id) {
            return history.push(getRouteWithId(Routes.TEST, summaryTest.id))
        }

        if (isFinalTest) {
            return history.push(Routes.PROFILE)
        }

        if (isThereNextLecture) {
            return history.push(getRouteWithId(Routes.LECTURE, nextLecture?.id || ''))
        }

        if (finalTestId) {
            return history.push(getRouteWithId(Routes.TEST, finalTestId))
        }
    }

    return (
        <>
            <Wrapper>
                <TestLastStepIconWrapper>{header}</TestLastStepIconWrapper>

                <LastStepCoins>
                    <CoinsIcon />
                    <TipPriceText>
                        {loco.test.acquire} {pointsTotal} {loco.common.points}
                    </TipPriceText>
                </LastStepCoins>

                {!isFinalTest || !isFinalTestCompleted ? (
                    <StatisticPreview
                        onlyLvl
                        size={240}
                        icon={<TipCharacter />}
                        outerValue={testResults}
                    />
                ) : (
                    <>
                        <Text paragraph align="center">
                            {loco.test.acquireDiplom}
                        </Text>
                        <LastStepFinalTest />
                    </>
                )}

                <div style={{ marginTop: 50 }}>
                    <TipResults marginLeft={0}>
                        <CorrectIcon />
                        <IsRightText isRight={true}>
                            {correctAnswersCount} {loco.test.right}
                        </IsRightText>
                    </TipResults>
                    <TipResults marginLeft={25}>
                        <WrongIcon />
                        <IsRightText isRight={false}>
                            {wrongAnswersCount} {loco.test.wrong}
                        </IsRightText>
                    </TipResults>
                </div>
            </Wrapper>

            <ButtonWrapper>
                <NextButtonWrapper
                    style={isLastChapterSumTestAndFinalTestCompleted ? { display: 'none' } : {}}
                >
                    {isFinalTest && isFinalTestCompleted ? (
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getServiceUrl('diploma')}
                            style={
                                isLoading
                                    ? { width: '100%', pointerEvents: 'none' }
                                    : { width: '100%' }
                            }
                        >
                            <Button
                                fullWidth
                                size="large"
                                type="button"
                                color="primary"
                                variant="contained"
                                disabled={isLoading}
                            >
                                {loco.profile.diplom.finished.button}
                            </Button>
                        </a>
                    ) : (
                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            onClick={next}
                            color="primary"
                            variant="contained"
                            disabled={isLoading}
                        >
                            {btnText}
                        </Button>
                    )}
                </NextButtonWrapper>
                <RepeatButtonWrapper>
                    <Button
                        fullWidth
                        size="large"
                        type="button"
                        color="primary"
                        variant="outlined"
                        disabled={isLoading}
                        onClick={() => {
                            createNewTestSession()
                        }}
                    >
                        <Repeat style={{ paddingRight: 10 }} />
                        {loco.lecture.repeat}
                    </Button>
                </RepeatButtonWrapper>
            </ButtonWrapper>
        </>
    )
}

const getHeader = (isFinalTest: boolean, isFinalTestCompleted: boolean) => {
    if (!isFinalTest) return <TestLastStepTitle />
    if (isFinalTestCompleted) return <TestLastStepTitleSuccess />
    return <TestLastStepTitleFail />
}

const getTextButton = (
    isTempUser: boolean,
    isFinalTest: boolean,
    isFinalTestCompleted: boolean,
    isThereNextLecture: boolean,
    isChapterSummary: boolean,
    isNextLectureInDifChapter: boolean
) => {
    // DOKONČIT ÚČET
    if (isTempUser) return loco.test.summary['finish-profile']
    // Stáhnout diplom
    if (isFinalTest && isFinalTestCompleted) return loco.profile.diplom.finished.button
    // ZPĚT NA PROFIL
    if (isFinalTest) return loco.test.summary.backToProfile
    // DALŠÍ KAPITOLA
    if (isNextLectureInDifChapter) return loco.test.summary.next.chapter
    // DALŠÍ LEKCE
    if (isThereNextLecture) return loco.test.summary.next.lecture
    // SPUSTIT TEST KAPITOLY
    if (!isThereNextLecture && !isChapterSummary) return loco.test.summary['chapter-test']
    // SPUSTIT ZÁVĚREČNÝ TEST
    return loco.test.summary['final-test']
}

export default LastStep
