import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import { colors } from '@variables'
import { DialogContent } from '../../shared/styled'

export const Title = styled(Typography)``
export const Subtitle = styled(Typography)``

export const Wrapper = styled(DialogContent)`
    min-width: 740px;
    padding: 35px 45px;
    align-items: flex-start;

    ${Title} {
        opacity: 0.4;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
    }
    ${Subtitle} {
        font-size: 16px;
        font-weight: bold;
    }
`

export const Coins = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 35px 0;
`

export const Iconography = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
`

export const Row = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    margin-bottom: 35px;

    div {
        padding-left: 20px;
    }
`

export const Badges = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    flex-direction: row;
`

export const Badge = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    padding: 7px;
    max-width: 48px;
    border-radius: 4px;
    align-items: center;
    margin: 0 10px 10px 0;
    justify-content: center;
    background: ${colors.primary.gray2};

    img {
        width: 100%;
        height: auto;
    }
`
