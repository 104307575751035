// @ts-ignore
import {
    Role,
    Question,
    CreationStatus,
    useAdminLectureQuery,
    ChaptersWithIdDocument,
    useUpdateLectureMutation,
    useChaptersWithoutLecturesQuery,
    useUpdateLectureUpdateDraftMutation,
    useCreateLectureUpdateDraftMutation
} from '@graphql'
import get from 'lodash/get'
import last from 'lodash/last'
import Button from '@material-ui/core/Button'
import { EditorState } from 'draft-js'
import { stateToMarkdown } from '../../../../../../common/stateToMarkdown'
import { stateFromMarkdown } from 'draft-js-import-markdown'
import { ArrayHelpers, Field, FieldArray, FieldProps, Form, Formik } from 'formik'

import { Location } from 'history'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect } from 'react'
import { array, boolean, object, string } from 'yup'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useBeforeUnload, useSetState, useToggle } from 'react-use'

import * as loco from '@loco'
import { translateGQLError } from '@utils'
import { notificationMsgs, Routes } from '@variables'

import { addLocalQuestion } from '..'
import Header from '../components/Header'
import Loader from '../components/Loader'
import Head from '../../../../../components/Head'
import { ButtonWrapper, CustomBox } from '../styled'
import { TabType } from '../../../../../components/shared'
import WYSIWYG from '../../../../../components/Fields/WYSIWYG'
import InputField from '../../../../../components/Fields/Input'
import PageLoader from '../../../../../components/shared/Loader'
import UploadField from '../../../../../components/Fields/Upload'
import { AuthContext } from '../../../../../context/Auth'
import Autocomplete from '../../../../../components/Fields/Autocomplete'
import RouteLeavingGuard from '../../../../../components/RouteLeavingGuard'
import ExpansionPanelTest, { PanelVariant } from '../../../../../components/ExpansionPanels/Test'
import {
    Box,
    Label,
    Container,
    ToggleItem,
    ToggleWrapper
} from '../../../../../components/shared/styled'

type State = {
    tab: TabType
    type: 'DRAFT' | 'NORMAL'
    editorState: EditorState
    data: {
        title: string
        description: string
        video: any
        audio: any
        preview: any
        questions: any[]
        transcript: string
        status?: CreationStatus
        authorId?: string
        chapter: {
            id: string
            name: string
        }
    }
}

const EditLecture = () => {
    const history = useHistory()
    const { search } = useLocation()
    const { enqueueSnackbar } = useSnackbar()
    const params = useParams<{ readonly id: string }>()

    const { user } = useContext(AuthContext)

    const [dirty, toggleDirty] = useToggle(false)
    // On close or reload
    useBeforeUnload(dirty, loco.admin.popup.leave.title)

    const onChange = () => {
        if (!dirty) toggleDirty()
    }

    const [state, setState] = useSetState<State>(initialState)

    const { tab, editorState } = state

    const handleTab = (newTab: TabType) => setState({ tab: newTab })

    const handleEditorState = (newEditorState: EditorState) =>
        setState({ editorState: newEditorState })

    const { data: chaptersData } = useChaptersWithoutLecturesQuery()
    const { data, loading, error, refetch } = useAdminLectureQuery({
        fetchPolicy: 'network-only',
        variables: { where: { id: params.id } }
    })

    const [createDraft, { loading: createDraftLoading }] = useCreateLectureUpdateDraftMutation({
        onError: (err) => enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
    })
    const [updateDraft, { loading: updateDraftLoading }] = useUpdateLectureUpdateDraftMutation({
        onCompleted: async () => refetch(),
        refetchQueries: [{ query: ChaptersWithIdDocument }],
        onError: (err) => enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
    })
    const [updateLecture, { loading: updateLectureLoading }] = useUpdateLectureMutation({
        onCompleted: async () => refetch(),
        refetchQueries: [{ query: ChaptersWithIdDocument }],
        onError: (err) => enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
    })

    const isLoading = createDraftLoading || updateDraftLoading || updateLectureLoading

    useEffect(() => {
        if (search.includes(Routes.TEST_SEARCH_PARAM)) return handleTab(TabType.TEST)
        return () => history.push({ search: '' })
    }, [search])

    useEffect(() => {
        if (!data?.lecture) return

        const { lecture } = data

        const lectureVideo = lecture?.files?.find(({ type }) => type === 'VIDEO')
        const lectureAudio = lecture?.files?.find(({ type }) => type === 'AUDIO')

        const lastDraft = last(lecture.drafts.filter((d) => !d.isMerged && !d.isRejected))

        if (state.type === 'DRAFT' && lastDraft?.id) {
            const draftVideo = lastDraft?.files?.find(({ type }) => type === 'VIDEO')
            const draftAudio = lastDraft?.files?.find(({ type }) => type === 'AUDIO')

            const newEditorState = EditorState.createWithContent(
                stateFromMarkdown(lastDraft?.transcript || lecture.transcript)
            )

            setState({
                editorState: newEditorState,
                data: {
                    title: lastDraft.name,
                    chapter: lastDraft.chapter,
                    description: lastDraft.description,
                    video: draftVideo?.id
                        ? {
                              ...draftVideo,
                              gcsId: draftVideo?.gcsId || ''
                          }
                        : {
                              ...lectureVideo,
                              gcsId: lectureVideo?.gcsId || ''
                          },
                    audio: draftAudio?.id
                        ? {
                              ...draftAudio,
                              gcsId: draftAudio?.gcsId || ''
                          }
                        : {
                              ...lectureAudio,
                              gcsId: lectureAudio?.gcsId || ''
                          },
                    preview: draftVideo?.id
                        ? {
                              src: draftVideo?.previewSrc,
                              gcsId: draftVideo?.preview || '',
                              filename: draftVideo?.filename || ''
                          }
                        : {
                              src: lectureVideo?.previewSrc,
                              gcsId: lectureVideo?.preview || '',
                              filename: lectureVideo?.filename || ''
                          },
                    questions: lecture?.test?.questions || [],
                    // Only for handle dirty
                    transcript: ' '
                }
            })
        } else {
            const newEditorState = EditorState.createWithContent(
                stateFromMarkdown(lecture.transcript)
            )

            setState({
                editorState: newEditorState,
                data: {
                    title: lecture.name,
                    status: lecture.status,
                    chapter: lecture.chapter,
                    description: lecture.description,
                    authorId: lecture.author.id,
                    video: {
                        ...lectureVideo,
                        gcsId: lectureVideo?.gcsId || ''
                    },
                    audio: {
                        ...lectureAudio,
                        gcsId: lectureAudio?.gcsId || ''
                    },
                    preview: {
                        src: lectureVideo?.previewSrc,
                        gcsId: lectureVideo?.preview || '',
                        filename: lectureVideo?.filename || ''
                    },
                    questions: lecture?.test?.questions || [],
                    // Only for handle dirty
                    transcript: ' '
                }
            })
        }
    }, [data, state.type])

    if (loading) return <PageLoader />
    if (!data || error) {
        error && enqueueSnackbar(translateGQLError(error.message), { variant: 'error' })
        return null
    }

    const { lecture } = data

    const lastDraft = last(lecture.drafts.filter((d) => !d.isMerged && !d.isRejected))
    const isSameAuthor =
        (!lastDraft && state.data.status === CreationStatus.APPROVED) ||
        (lecture?.author.id === user?.id && state.type === 'NORMAL') ||
        (lastDraft?.author.id === user?.id && state.type === 'DRAFT') ||
        user?.role === Role.SUPER_ADMIN

    const isUpdateDisabled =
        (user?.role === Role.ADMIN && state.type === 'NORMAL' && Boolean(lastDraft?.id)) ||
        lecture.status === CreationStatus.BLOCKED ||
        (user?.role === Role.ADMIN &&
            (state.data?.status === CreationStatus.PENDING ||
                state.data?.status === CreationStatus.REJECTED) &&
            state.data?.authorId !== user?.id)

    const toggleContentType = () => {
        if (state.type === 'NORMAL') setState({ type: 'DRAFT' })
        else setState({ type: 'NORMAL' })
    }

    const submit = async (values: typeof state.data) => {
        if (lecture.status === CreationStatus.BLOCKED) {
            return enqueueSnackbar(loco.validation.common.blockedContent, { variant: 'info' })
        }

        if (!isSameAuthor) return

        const onSuccess = (data?: any | null) => {
            if (!data) return

            toggleDirty()
            handleTab(TabType.TEST)
            enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
        }

        const { title, description, video, preview, audio } = values
        const transcript = stateToMarkdown(editorState.getCurrentContent())

        const newData = {
            chapter: { id: values.chapter.id },
            name: title,
            transcript: transcript,
            description: description,
            video: {
                gcsId: video?.gcsId
            },
            audio: {
                gcsId: audio?.gcsId
            },
            videoPreview: {
                gcsId: preview?.gcsId
            }
        }

        if (
            (user?.role === Role.SUPER_ADMIN && state.type === 'NORMAL') ||
            ((state.data?.status === CreationStatus.PENDING ||
                state.data?.status === CreationStatus.REJECTED) &&
                state.data?.authorId === user?.id)
        ) {
            const response = await updateLecture({
                variables: {
                    data: newData,
                    where: { id: lecture.id }
                }
            })
            if (response?.data) {
                return onSuccess(response.data)
            }
            if (response?.errors) {
                console.log(response.errors)
            }
            return
        }

        // Create/Update lecture draft
        if (
            lecture.status === CreationStatus.APPROVED &&
            (state.type === 'DRAFT' || !lastDraft?.id)
        ) {
            const newDrafts = lecture.drafts.filter((d) => !d.isMerged && !d.isRejected)

            if (newDrafts.length > 0) {
                const lastDraft = last(newDrafts)
                if (!lastDraft?.id) return

                const { data } = await updateDraft({
                    variables: {
                        data: { ...newData },
                        where: { id: lastDraft.id }
                    }
                })

                onSuccess(data)
            } else {
                const { data, errors } = await createDraft({
                    variables: { where: { id: lecture.id } }
                })

                if (data && !errors) {
                    const { data: res } = await updateDraft({
                        variables: {
                            data: { ...newData },
                            where: { id: data.createLectureUpdateDraft.id }
                        }
                    })
                    onSuccess(res)
                }
            }
        }
    }

    return (
        <>
            <Head title={loco.seo.lecture.edit.title} />

            <RouteLeavingGuard
                when={dirty}
                title={loco.admin.popup.leave.title}
                btnText={loco.admin.popup.leave.confirm}
                description={loco.admin.popup.leave.subtitle}
                navigate={(path: string) => history.push(path)}
                shouldBlockNavigation={(newLocation: Location<any>) => {
                    if (dirty) {
                        if (location.pathname !== newLocation.pathname) {
                            return true
                        }
                    }
                    return false
                }}
            />

            <Container>
                <Formik
                    onSubmit={submit}
                    enableReinitialize
                    isInitialValid={false}
                    initialValues={state.data}
                    validationSchema={
                        state.tab === TabType.LECTURE
                            ? validationSchema
                            : validationSchemaWithQuestions
                    }
                >
                    {({ dirty, values, errors, isValid, isSubmitting, setFieldValue }) => {
                        const { questions, title, description } = values

                        return (
                            <Form style={{ width: '100%' }} spellCheck={false} onChange={onChange}>
                                <Header
                                    tab={tab}
                                    isValid={isValid}
                                    handleTab={handleTab}
                                    isSameAuthor={isSameAuthor}
                                    variant={PanelVariant.EDIT}
                                    isUpdateDisabled={isUpdateDisabled}
                                    isSubmitting={isSubmitting || isLoading}
                                />

                                {tab === TabType.LECTURE && (
                                    <Box style={{ padding: '30px 40px 40px 40px' }}>
                                        {lastDraft && (
                                            <ToggleWrapper>
                                                <ToggleItem
                                                    onClick={toggleContentType}
                                                    isActive={state.type === 'NORMAL'}
                                                >
                                                    {loco.common.content.actual}
                                                </ToggleItem>{' '}
                                                /{' '}
                                                <ToggleItem
                                                    onClick={toggleContentType}
                                                    isActive={state.type === 'DRAFT'}
                                                >
                                                    {loco.common.content.draft}
                                                </ToggleItem>
                                            </ToggleWrapper>
                                        )}

                                        {(isLoading || isSubmitting) && <Loader />}

                                        <Field
                                            fullWidth
                                            key="title"
                                            name="title"
                                            value={title}
                                            component={InputField}
                                            className="customInput"
                                            disabled={
                                                isSubmitting ||
                                                isLoading ||
                                                !isSameAuthor ||
                                                isUpdateDisabled
                                            }
                                            label={loco.admin.create.lecture.name.label}
                                            placeholder={loco.admin.create.lecture.name.placeholder}
                                        />

                                        <Field key="chapter" name="chapter">
                                            {({ field, form, meta }: FieldProps) => (
                                                <Autocomplete
                                                    meta={meta}
                                                    form={form}
                                                    field={field}
                                                    autoComplete
                                                    className="customInput"
                                                    options={chaptersData?.chapters || []}
                                                    getOptionLabel={(option) => option.name || ''}
                                                    disabled={
                                                        isSubmitting ||
                                                        isLoading ||
                                                        !isSameAuthor ||
                                                        isUpdateDisabled
                                                    }
                                                    textFieldProps={{
                                                        fullWidth: true,
                                                        label: loco.admin.create.lecture.chapter
                                                    }}
                                                />
                                            )}
                                        </Field>

                                        <Field
                                            fullWidth
                                            multiline
                                            key="description"
                                            name="description"
                                            value={description}
                                            component={InputField}
                                            className="customInput"
                                            disabled={
                                                isSubmitting ||
                                                isLoading ||
                                                !isSameAuthor ||
                                                isUpdateDisabled
                                            }
                                            label={loco.admin.create.lecture.description.label}
                                            placeholder={
                                                loco.admin.create.lecture.description.placeholder
                                            }
                                        />

                                        <Label>{loco.admin.create.lecture.transcription}</Label>
                                        {editorState && (
                                            <WYSIWYG
                                                editorState={editorState}
                                                setState={(newEditorState: EditorState) => {
                                                    if (!isSameAuthor || isUpdateDisabled) return

                                                    // Set dirty => true
                                                    if (!dirty) setFieldValue('transcript', '  ')

                                                    onChange()
                                                    handleEditorState(newEditorState)
                                                }}
                                            />
                                        )}

                                        <ButtonWrapper>
                                            <UploadField
                                                name="video"
                                                disabled={
                                                    isSubmitting ||
                                                    isLoading ||
                                                    !isSameAuthor ||
                                                    isUpdateDisabled
                                                }
                                                buttonText={loco.admin.create.chapter.upload}
                                                label={loco.admin.create.lecture.video}
                                                style={{ width: '49%' }}
                                                previewType="none"
                                                accept="video/mp4"
                                            />

                                            <UploadField
                                                name="audio"
                                                disabled={
                                                    isSubmitting ||
                                                    isLoading ||
                                                    !isSameAuthor ||
                                                    isUpdateDisabled
                                                }
                                                buttonText={loco.admin.create.chapter.upload}
                                                label={loco.admin.create.lecture.audio}
                                                style={{ width: '49%' }}
                                                previewType="none"
                                                accept=".mp3"
                                            />
                                        </ButtonWrapper>

                                        <UploadField
                                            name="preview"
                                            disabled={
                                                isSubmitting ||
                                                isLoading ||
                                                !isSameAuthor ||
                                                isUpdateDisabled
                                            }
                                            buttonText={loco.admin.create.chapter.upload}
                                            label={loco.admin.create.lecture.icon}
                                            style={{ width: '100%' }}
                                            previewType="img"
                                            accept="image/*"
                                        />
                                    </Box>
                                )}

                                {tab === TabType.TEST && (
                                    <FieldArray
                                        key="questions"
                                        name="questions"
                                        validateOnChange
                                        render={(arrayHelpers: ArrayHelpers) =>
                                            values.questions?.length
                                                ? values.questions?.map(
                                                      (q: Question, idx: number) => (
                                                          <CustomBox
                                                              key={q.id}
                                                              style={{
                                                                  paddingTop: 0,
                                                                  paddingBottom: 0
                                                              }}
                                                          >
                                                              <ExpansionPanelTest
                                                                  index={idx}
                                                                  question={q}
                                                                  isSameAuthor
                                                                  toggleDirty={toggleDirty}
                                                                  arrayHelpers={arrayHelpers}
                                                                  testId={lecture.test?.id || ''}
                                                                  panelVariant={PanelVariant.EDIT}
                                                                  isValid={
                                                                      !Boolean(
                                                                          get(
                                                                              errors,
                                                                              `questions.${idx}`
                                                                          )
                                                                      )
                                                                  }
                                                              />
                                                          </CustomBox>
                                                      )
                                                  )
                                                : null
                                        }
                                    />
                                )}

                                {tab === TabType.TEST && (
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="button"
                                        color="primary"
                                        variant="outlined"
                                        style={{ marginTop: 20 }}
                                        onClick={() => addLocalQuestion(questions, setFieldValue)}
                                        disabled={
                                            (values?.questions?.length || 0) >
                                            (lecture?.test?.questions?.length || 0)
                                        }
                                    >
                                        {loco.admin.create.lecture.test.question.new}
                                    </Button>
                                )}
                            </Form>
                        )
                    }}
                </Formik>
            </Container>
        </>
    )
}

const initialState: State = {
    type: 'NORMAL',
    tab: TabType.LECTURE,
    editorState: EditorState.createEmpty(),
    data: {
        title: '',
        description: '',
        video: {
            src: null,
            filename: ''
        },
        audio: {
            src: null,
            filename: ''
        },
        preview: {
            src: null,
            filename: ''
        },
        questions: [],
        transcript: '',
        chapter: {
            id: '',
            name: ''
        }
    }
}

const validationSchema = object({
    title: string().required(loco.validation.common.required),
    description: string().required(loco.validation.common.required),
    transcript: string().required()
})

const validationSchemaWithQuestions = object({
    questions: array().of(
        object({
            text: string()
                .max(80, loco.validation.test.text.max)
                .required(loco.validation.common.required),
            description: string().max(300, loco.validation.test.description.max),
            answers: array().of(
                object({
                    isCorrect: boolean(),
                    text: string()
                        .required(loco.validation.common.required)
                        .max(75, loco.validation.test.question.max)
                })
            )
        })
    )
})

export default EditLecture
