import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
            {...props}
        >
            <circle cx="16" cy="16" r="16" fill="#FF5B7C" opacity="0.1"></circle>
            <path
                fillRule="evenodd"
                stroke="#FF5B7C"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 12.77a1.846 1.846 0 100-3.693 1.846 1.846 0 000 3.693zM19.231 16.923a3.23 3.23 0 10-6.462 0v1.385h1.385l.462 4.615h2.769l.461-4.615h1.385v-1.385z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default Icon
