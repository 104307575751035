import React, { useState, useEffect, useRef, MouseEvent, ChangeEvent, useContext } from 'react'
import SpeedIcon from '@material-ui/icons/Speed'
import Popover from '@material-ui/core/Popover'
import { useSetState } from 'react-use'
import ReactPlayer from 'react-player'

import { colors } from '@variables'
import PlayButton from '../../components/shared/PlayButton'
import { Wrapper, IconWrapper, More, MoreButton } from './styled'
import { AudioPlayerContext } from '../../context/AudioPlayer'

type State = {
    src: string
    preview: string
    playing: boolean
    playedSeconds: number
    playbackRate: number
}

type Props = {
    src?: string | null
    preview?: string | null
}

const VideoPlayer = (props: Props) => {
    const player = useRef(null)

    const { stop: stopAudio } = useContext(AudioPlayerContext)

    const [state, setState] = useSetState<State>({
        src: '',
        preview: '',
        playing: false,
        playedSeconds: 0,
        playbackRate: 1.0
    })

    const { src, preview, playing, playedSeconds, playbackRate } = state

    useEffect(() => {
        if (props.src) setState({ src: props.src })
    }, [props.src])

    useEffect(() => {
        if (props.preview) setState({ preview: props.preview })
    }, [props.preview])

    const play = () => {
        stopAudio()
        setState({ playing: true })
    }

    const pause = () => setState({ playing: false })

    const onProgress = (state: {
        played: number
        playedSeconds: number
        loaded: number
        loadedSeconds: number
    }) => setState({ playedSeconds: state.playedSeconds })

    const setPlaybackRate = (e: React.ChangeEvent<any>) =>
        setState({ playbackRate: parseFloat(e.target.value) })

    // ========== Popover MORE options ==========>
    const [anchorMore, setAnchorMore] = useState<HTMLButtonElement | null>(null)
    const onMouseEnterMore = (event: MouseEvent<any>) => setAnchorMore(event.currentTarget)
    const closeMore = () => setAnchorMore(null)

    const isMoreOpen = Boolean(anchorMore)
    const moreId = isMoreOpen ? 'more-options-popover' : undefined

    const onPlaybackRateChange = (e: ChangeEvent<any>) => {
        setPlaybackRate(e)
        closeMore()
    }

    return (
        <Wrapper>
            {playedSeconds > 0 && (
                <IconWrapper onClick={onMouseEnterMore} aria-describedby={moreId}>
                    <SpeedIcon />
                </IconWrapper>
            )}

            <Popover
                disablePortal
                open={isMoreOpen}
                onClose={closeMore}
                anchorEl={anchorMore}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <More onMouseLeave={closeMore}>
                    <MoreButton value={1} onClick={onPlaybackRateChange}>
                        speed 1x
                    </MoreButton>
                    <MoreButton value={1.5} onClick={onPlaybackRateChange}>
                        speed 1.5x
                    </MoreButton>
                    <MoreButton value={2} onClick={onPlaybackRateChange}>
                        speed 2x
                    </MoreButton>
                </More>
            </Popover>

            <ReactPlayer
                controls
                url={src}
                pip={false}
                width="100%"
                height="100%"
                ref={player}
                onPlay={play}
                onPause={pause}
                playing={playing}
                onProgress={onProgress}
                playbackRate={playbackRate}
                light={playedSeconds > 0 ? false : preview}
                config={{ file: { attributes: { disablePictureInPicture: true } } }}
                playIcon={
                    <PlayButton type="video" onClick={play} playBtnColor={colors.primary.orange} />
                }
            />
        </Wrapper>
    )
}

export default VideoPlayer
