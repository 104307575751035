import React from 'react'
import get from 'lodash/get'
import remove from 'lodash/remove'
import { useSetState } from 'react-use'
import { useSnackbar } from 'notistack'
import findIndex from 'lodash/findIndex'
import { useParams } from 'react-router-dom'
import { DeleteOutlineOutlined, EditOutlined } from '@material-ui/icons'
import { FieldProps, ArrayHelpers, FieldArray, FastField } from 'formik'
import { Button, Typography, ExpansionPanel, ExpansionPanelSummary } from '@material-ui/core'

import * as loco from '@loco'
import Dialog from '../../Dialogs/Info'
import Tooltip from '../../shared/Tooltip'
import WrongIcon from '../../SVG/WrongIcon'
import InputField from '../../Fields/Input'
import CorrectIcon from '../../SVG/CorrectIcon'
import { notificationMsgs } from '@variables'
import { translateGQLError } from '@utils'
import { Wrapper, IconsWrapper, TitleRow, ButtonWrapper, ExpansionPanelDetails } from './styled'
import {
    Question,
    AnswerFragment,
    AdminLectureDocument,
    useDeleteTestQuestionMutation,
    useCreateTestQuestionMutation,
    useUpdateTestQuestionMutation,
    useCreateQuestionAnswersMutation,
    useUpdateQuestionAnswersMutation
} from '@graphql'

export const enum PanelVariant {
    CREATE = 0,
    EDIT = 1,
    REVIEW = 2
}

const enum Step {
    NEW_QUESION = 0,
    QUESION_WITH_ANSWERS = 1
}

interface State {
    step: Step
    isOpen: boolean
    expanded: boolean
}

interface Props {
    index: number
    testId: string
    isValid: boolean
    question: Question
    isSameAuthor?: boolean
    toggleDirty?: () => void
    panelVariant: PanelVariant
    arrayHelpers?: ArrayHelpers
}

const ExpansionTest = ({
    index,
    testId,
    isValid,
    question,
    toggleDirty,
    arrayHelpers,
    panelVariant,
    isSameAuthor
}: Props) => {
    const { enqueueSnackbar } = useSnackbar()
    const { id: lectureId } = useParams<{ readonly id: string }>()

    const isReview = panelVariant === PanelVariant.REVIEW

    const [{ isOpen, expanded, step }, setState] = useSetState<State>({
        isOpen: false,
        expanded: question.id.length > 0,
        step: question.id.length > 0 ? Step.QUESION_WITH_ANSWERS : Step.NEW_QUESION
    })

    const [deleteQuestion, { loading: deleteQuestionLoading }] = useDeleteTestQuestionMutation({
        onError: (err) => enqueueSnackbar(translateGQLError(err.message), { variant: 'error' }),
        update: (caches, { data }) => {
            try {
                if (!data?.deleteTestQuestion) {
                    throw new Error('Updated question is undefined')
                }

                const { lecture }: any = caches.readQuery({
                    query: AdminLectureDocument,
                    variables: { where: { id: lectureId } }
                })

                const newQuestions: Question[] = [...lecture.test.questions]
                remove(newQuestions, (q: Question) => q.id === data.deleteTestQuestion.id)

                caches.writeQuery({
                    query: AdminLectureDocument,
                    variables: { where: { id: lectureId } },
                    data: {
                        lecture: {
                            ...lecture,
                            test: {
                                ...lecture.test,
                                questions: newQuestions
                            }
                        }
                    }
                })
            } catch (error) {
                console.error(error)
            }
        }
    })

    const [updateQuestion, { loading: updateQuestionLoading }] = useUpdateTestQuestionMutation({
        onError: (err) => enqueueSnackbar(translateGQLError(err.message), { variant: 'error' }),
        update: (caches, { data }) => {
            try {
                if (!data?.updateTestQuestion) {
                    throw new Error('Updated question is undefined')
                }

                const { lecture }: any = caches.readQuery({
                    query: AdminLectureDocument,
                    variables: { where: { id: lectureId } }
                })

                const index = findIndex(lecture.test.questions, { id: data.updateTestQuestion.id })
                const newQuestions: Question[] = [...lecture.test.questions]
                newQuestions.splice(index, 1, data.updateTestQuestion)

                caches.writeQuery({
                    query: AdminLectureDocument,
                    variables: { where: { id: lectureId } },
                    data: {
                        lecture: {
                            ...lecture,
                            test: {
                                ...lecture.test,
                                questions: newQuestions
                            }
                        }
                    }
                })
            } catch (error) {
                console.error(error)
            }
        }
    })

    const [createTestQuestion, { loading: createQuestionLoading }] = useCreateTestQuestionMutation({
        onError: (err) => enqueueSnackbar(translateGQLError(err.message), { variant: 'error' }),
        update: (caches, { data }) => {
            try {
                const { lecture }: any = caches.readQuery({
                    query: AdminLectureDocument,
                    variables: { where: { id: lectureId } }
                })

                caches.writeQuery({
                    query: AdminLectureDocument,
                    variables: { where: { id: lectureId } },
                    data: {
                        lecture: {
                            ...lecture,
                            test: {
                                ...lecture.test,
                                questions: lecture.test.questions.concat([data?.createTestQuestion])
                            }
                        }
                    }
                })
            } catch (error) {
                console.error(error)
            }
        }
    })

    const [
        createQuestionAnswers,
        { loading: createQuestionAnswersLoading }
    ] = useCreateQuestionAnswersMutation({
        onError: (err) => enqueueSnackbar(translateGQLError(err.message), { variant: 'error' }),
        update: (caches, { data }) => {
            try {
                if (!data?.createQuestionAnswers) {
                    throw new Error('Answers is undefined')
                }

                const { lecture }: any = caches.readQuery({
                    query: AdminLectureDocument,
                    variables: { where: { id: lectureId } }
                })

                const index = findIndex(
                    lecture.test.questions,
                    (q: Question) => q.answers.length === 0
                )
                const newQuestions: Question[] = [...lecture.test.questions]
                newQuestions.splice(index, 1, {
                    ...lecture.test.questions[index],
                    answers: [...data?.createQuestionAnswers]
                })

                caches.writeQuery({
                    query: AdminLectureDocument,
                    variables: { where: { id: lectureId } },
                    data: {
                        lecture: {
                            ...lecture,
                            test: {
                                ...lecture.test,
                                questions: newQuestions
                            }
                        }
                    }
                })
            } catch (error) {
                console.error(error)
            }
        }
    })

    const [
        updateQuestionAnaswers,
        { loading: updateQuestionAnaswersLoading }
    ] = useUpdateQuestionAnswersMutation({
        onError: (err) => enqueueSnackbar(translateGQLError(err.message), { variant: 'error' }),
        update: (caches, { data }) => {
            try {
                const { lecture }: any = caches.readQuery({
                    query: AdminLectureDocument,
                    variables: { where: { id: lectureId } }
                })

                const index = findIndex(lecture.test.questions, { id: question.id })
                const newQuestions: Question[] = [...lecture.test.questions]
                newQuestions.splice(index, 1, {
                    ...question,
                    answers: data?.updateQuestionAnswers || question.answers
                })

                caches.writeQuery({
                    query: AdminLectureDocument,
                    variables: { where: { id: lectureId } },
                    data: {
                        lecture: {
                            ...lecture,
                            test: {
                                ...lecture.test,
                                questions: newQuestions
                            }
                        }
                    }
                })
            } catch (error) {
                console.error(error)
            }
        }
    })

    const isLoading =
        deleteQuestionLoading ||
        createQuestionLoading ||
        updateQuestionLoading ||
        createQuestionAnswersLoading ||
        updateQuestionAnaswersLoading

    const submitQuestion = async () => {
        const { data, errors } = await createTestQuestion({
            variables: {
                test: { id: testId },
                data: {
                    text: question.text,
                    description: question.description
                }
            }
        })

        if (data && !errors) {
            const { createTestQuestion } = data

            arrayHelpers?.replace(index, {
                answers: question.answers,
                id: createTestQuestion.id,
                text: createTestQuestion.text,
                description: createTestQuestion.description
            })

            submitAnswers(createTestQuestion.id)
        }
    }

    const submitAnswers = async (questionId: string) => {
        const { data, errors } = await createQuestionAnswers({
            variables: {
                data: fakeAnswers,
                question: { id: questionId }
            }
        })

        if (data && !errors) {
            setState({ step: Step.QUESION_WITH_ANSWERS })
            enqueueSnackbar(notificationMsgs.update, { variant: 'success' })

            arrayHelpers?.replace(index, {
                id: questionId,
                text: question.text,
                description: question.description,
                answers: data.createQuestionAnswers
            })
        }
    }

    const submitDeleteQuestion = async () => {
        if (!Boolean(question?.id)) {
            return arrayHelpers?.remove(index)
        }

        await deleteQuestion({
            variables: { where: { id: question.id } }
        })
    }

    const onUpdate = async () => {
        const { data, errors } = await updateQuestion({
            variables: {
                where: { id: question.id },
                data: {
                    text: question.text,
                    description: question.description
                }
            }
        })

        if (data && !errors) {
            arrayHelpers?.replace(index, {
                ...data.updateTestQuestion,
                answers: question.answers
            })

            const response = await updateQuestionAnaswers({
                variables: {
                    data: [
                        ...question.answers.map((q) => {
                            return {
                                id: q.id,
                                text: q.text,
                                isCorrect: q.isCorrect
                            }
                        })
                    ]
                }
            })

            if (response?.data && !response?.errors) {
                if (toggleDirty) toggleDirty()
                enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
            }
        }
    }

    return (
        <>
            <Dialog
                isOpen={isOpen}
                toggleOpen={() => setState({ isOpen: !isOpen })}
                data={[{ title: loco.dialogs.common.delete.title }]}
                buttonProps={{
                    text: loco.common.delete,
                    isSubmitting: isLoading,
                    onClick: () => submitDeleteQuestion()
                }}
            />

            <Wrapper expanded={expanded}>
                <ExpansionPanel expanded={expanded}>
                    <ExpansionPanelSummary
                        disabled={isLoading}
                        style={{ padding: 0 }}
                        onClick={() => setState({ expanded: !expanded })}
                    >
                        <TitleRow>
                            <Typography variant="h6">
                                {loco.admin.create.lecture.test.question.title} {index + 1} -{' '}
                                {question.text}
                            </Typography>

                            {isSameAuthor && (
                                <IconsWrapper hidden={isReview || expanded}>
                                    <Tooltip title={loco.common.edit}>
                                        <EditOutlined
                                            onClick={(e) => {
                                                if (isLoading) return
                                                e.stopPropagation()
                                                setState({ expanded: !expanded })
                                            }}
                                        />
                                    </Tooltip>

                                    <Tooltip title={loco.common.delete}>
                                        <DeleteOutlineOutlined
                                            onClick={(e) => {
                                                if (isLoading) return
                                                e.stopPropagation()
                                                setState({ isOpen: true })
                                            }}
                                        />
                                    </Tooltip>
                                </IconsWrapper>
                            )}
                        </TitleRow>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails>
                        <FastField key={`questions.${index}.text`} name={`questions.${index}.text`}>
                            {({ field, form }: FieldProps) => (
                                <InputField
                                    fullWidth
                                    multiline
                                    form={form}
                                    field={field}
                                    className="customInput"
                                    style={{ marginTop: 0 }}
                                    disabled={isReview || !isSameAuthor || isLoading}
                                    label={loco.admin.create.lecture.test.question.subtitle}
                                />
                            )}
                        </FastField>

                        <FastField
                            key={`questions.${index}.description`}
                            name={`questions.${index}.description`}
                        >
                            {({ field, form }: FieldProps) => (
                                <InputField
                                    fullWidth
                                    multiline
                                    form={form}
                                    field={field}
                                    className="customInput"
                                    InputProps={{ startAdornment: getIcon(0) }}
                                    disabled={isReview || !isSameAuthor || isLoading}
                                    label={loco.admin.create.lecture.test.question.explanation}
                                />
                            )}
                        </FastField>

                        {step === Step.QUESION_WITH_ANSWERS && (
                            <FieldArray
                                key={`questions.${index}.answers`}
                                name={`questions.${index}.answers`}
                                render={(arrayHelpers: ArrayHelpers) =>
                                    question?.answers &&
                                    question.answers.length > 0 &&
                                    question.answers
                                        // Right answer alway the first
                                        .sort(
                                            (a: AnswerFragment, b: AnswerFragment) =>
                                                Number(b.isCorrect) - Number(a.isCorrect)
                                        )
                                        .map((answer: AnswerFragment, i: number) => (
                                            <FastField
                                                key={`questions.${index}.answers.${i}.text`}
                                                name={`questions.${index}.answers.${i}.text`}
                                            >
                                                {({ field, form }: FieldProps) => {
                                                    const errMsg = getErrorMsg(form, index, i)
                                                    return (
                                                        <InputField
                                                            fullWidth
                                                            multiline
                                                            form={form}
                                                            field={field}
                                                            label={getLabel(i)}
                                                            helperText={errMsg}
                                                            withError={Boolean(errMsg)}
                                                            className="customInput"
                                                            InputProps={{
                                                                startAdornment: getIcon(i)
                                                            }}
                                                            disabled={
                                                                isReview ||
                                                                !isSameAuthor ||
                                                                isLoading
                                                            }
                                                        />
                                                    )
                                                }}
                                            </FastField>
                                        ))
                                }
                            />
                        )}

                        <ButtonWrapper>
                            {step === Step.NEW_QUESION && (
                                <>
                                    <Button
                                        size="small"
                                        color="secondary"
                                        variant="outlined"
                                        style={{ marginRight: 15 }}
                                        disabled={isLoading || !isSameAuthor}
                                        onClick={() => {
                                            arrayHelpers?.remove(index)
                                        }}
                                    >
                                        {loco.common.cancel}
                                    </Button>
                                    <Button
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        onClick={submitQuestion}
                                        disabled={
                                            isLoading || !Boolean(question.text) || !isSameAuthor
                                        }
                                    >
                                        {loco.common.continue}
                                    </Button>
                                </>
                            )}

                            {step === Step.QUESION_WITH_ANSWERS && !isReview && (
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={onUpdate}
                                    disabled={isLoading || !isValid || !isSameAuthor}
                                >
                                    {loco.common.save}
                                </Button>
                            )}
                        </ButtonWrapper>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Wrapper>
        </>
    )
}

const fakeAnswers = [
    {
        text: '',
        isCorrect: true
    },
    {
        text: '',
        isCorrect: false
    },
    {
        text: '',
        isCorrect: false
    }
]

const getLabel = (idx: number) => {
    if (idx === 0) return loco.admin.create.lecture.test.question.right
    return `${loco.dialogs['restore-with-question'].answer.label} ${idx}`
}

const getIcon = (idx: number) => {
    if (idx === 0) return <CorrectIcon style={{ marginRight: 10, alignSelf: 'flex-start' }} />
    return <WrongIcon style={{ marginRight: 10, alignSelf: 'flex-start' }} />
}

const getErrorMsg = (form: { errors: {} }, index: number, i: number) => {
    return get(form, `errors.questions.${index}.answers.${i}.text`)
}

export default ExpansionTest
