import React from 'react'

function StepLabelActiveIcon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
            {...props}
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                stroke="hsla(240,100%,14%,1)"
                strokeWidth="2"
                d="M10 19a9 9 0 100-18 9 9 0 000 18z"
                clipRule="evenodd"
            />
            <path
                fill="hsla(240,100%,14%,1)"
                fillRule="evenodd"
                d="M10 15a5 5 0 100-10 5 5 0 000 10z"
                clipRule="evenodd"
            />
        </svg>
    )
}

export default StepLabelActiveIcon
