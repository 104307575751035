import styled from 'styled-components'
import { Link as L } from 'react-router-dom'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { Box } from '../../shared/styled'
import { colors, media } from '@variables'

export const Link = styled(L)``
export const ProfileLink = styled(L)``
export const Button = styled(MuiButton)``
export const Status = styled(Typography)``
export const Username = styled(Typography)``

export const Wrapper = styled(Box)`
    align-items: flex-start;
    justify-content: space-between;

    ${Button} {
        height: 45px;
        min-height: 45px;
        padding: 7px 20px;
        margin-left: auto;
        position: relative;
        letter-spacing: 0.5px;
        font-size: 12px !important;
        color: ${colors.primary.orange};

        width: 100%;
        margin-top: 25px;

        ${media.tablet} {
            width: initial;
            margin-top: initial;
        }

        :hover {
            color: ${colors.primary.orange};
            border-color: ${colors.primary.orange};
        }

        svg {
            margin-right: 10px;
        }

        ${Link} {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }

    ${Status} {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;

        ${media.tablet} {
            font-size: 16px;
        }
    }

    ${Username} {
        font-size: 16px;

        ${media.tablet} {
            font-size: 22px;
        }
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
`

export const Col = styled.div`
    display: flex;
    margin-left: 15px;
    flex-direction: column;
    justify-content: center;

    ${media.tablet} {
        margin-left: 25px;
    }
`

export const Title = styled(Typography)``
export const Subtitle = styled(Typography)``

export const Info = styled.div`
    display: flex;
    margin-top: 30px;
    flex-direction: row;

    ${media.tablet} {
        margin-top: 40px;
    }

    :not(:last-of-type) {
        margin-right: 50px;
    }

    div {
        display: flex;
        padding-left: 15px;
        flex-direction: column;
        justify-content: center;
    }

    ${Title} {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;

        ${media.tablet} {
            font-size: 16px;
        }
    }
    ${Subtitle} {
        opacity: 0.4;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 0;
        text-transform: uppercase;
    }

    ${ProfileLink} {
        color: ${colors.primary.orange};

        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
    }
`
