import React, { useContext } from 'react'

import { Button } from './styled'
import * as loco from '@loco'
import GoogleIcon from '../../SVG/GoogleIcon'
import { AuthContext } from '../../../context/Auth'

const GoogleButton = () => {
    const { isLoggingIn, loginWithGoogle, logoutLoading, loading } = useContext(AuthContext)

    const isLoading = isLoggingIn || logoutLoading || loading

    return (
        <Button
            type="button"
            disabled={isLoading}
            onClick={async () => {
                await loginWithGoogle()
            }}
        >
            <GoogleIcon style={{ marginRight: 10 }} />
            <span>{isLoggingIn ? loco.dialogs.login.logginIn : loco.dialogs.login.google}</span>
        </Button>
    )
}

export default GoogleButton
