import { Fade } from 'react-reveal'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { useInView } from 'react-intersection-observer'

import * as loco from '@loco'
import { Routes } from '@variables'
import Img1 from '@static/images/lp-about-box1.png'
import Img2 from '@static/images/lp-about-box2.png'
import Img3 from '@static/images/lp-about-box3.png'
import { Wrapper, TopTitle, Title, Text, Wrap, Top, Row, Items, Item, Img } from './styled'

const About = () => {
    const history = useHistory()
    const { ref, inView } = useInView({
        threshold: 0.2
    })

    useEffect(() => {
        if (inView) {
            history.replace({ hash: Routes.INVESTO_HASH_PARAM })
        }
    }, [inView])

    return (
        <Wrapper id={Routes.INVESTO_HASH_PARAM}>
            <Container>
                <Wrap ref={ref}>
                    <Top>
                        <Row>
                            <Fade>
                                <TopTitle>{loco.lp.about.topTitle}</TopTitle>
                            </Fade>
                        </Row>
                        <Row>
                            <Fade delay={100}>
                                <Title
                                    variant="h2"
                                    dangerouslySetInnerHTML={{ __html: loco.lp.about.title }}
                                ></Title>
                            </Fade>
                            <Fade delay={200}>
                                <Text
                                    paragraph
                                    dangerouslySetInnerHTML={{ __html: loco.lp.about.subtitle }}
                                ></Text>
                            </Fade>
                        </Row>
                    </Top>

                    <Items>
                        <Item>
                            <Img src={Img1} loading="lazy" width="240" height="240" />
                            <Typography
                                variant="h3"
                                dangerouslySetInnerHTML={{ __html: loco.lp.about.box1.title }}
                            ></Typography>
                            <Typography
                                variant="body1"
                                dangerouslySetInnerHTML={{
                                    __html: loco.lp.about.box1.text
                                }}
                            ></Typography>
                        </Item>
                        <Item>
                            <Img src={Img2} loading="lazy" width="240" height="240" />
                            <Typography
                                variant="h3"
                                dangerouslySetInnerHTML={{ __html: loco.lp.about.box2.title }}
                            ></Typography>
                            <Typography
                                variant="body1"
                                dangerouslySetInnerHTML={{ __html: loco.lp.about.box2.text }}
                            ></Typography>
                        </Item>
                        <Item>
                            <Img src={Img3} loading="lazy" width="240" height="240" />
                            <Typography
                                variant="h3"
                                dangerouslySetInnerHTML={{ __html: loco.lp.about.box3.title }}
                            ></Typography>
                            <Typography
                                variant="body1"
                                dangerouslySetInnerHTML={{ __html: loco.lp.about.box3.text }}
                            ></Typography>
                        </Item>
                    </Items>
                </Wrap>
            </Container>
        </Wrapper>
    )
}

export default About
