//@ts-ignore
import { createUploadLink } from 'apollo-upload-client'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import smoothscroll from 'smoothscroll-polyfill'
import { isIE } from 'react-device-detect'
import React from 'react'
import { hydrate } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ApolloClient } from 'apollo-client'
import { ApolloProvider } from 'react-apollo'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { SnackbarProvider } from 'notistack'
import apolloLogger from 'apollo-link-logger'
import { ThemeProvider } from '@material-ui/styles'
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns'
import cs from 'date-fns/locale/cs'
import { LocalizationProvider } from '@material-ui/pickers'

import App from './App'
import theme from '../common/theme'
import AuthProvider from './context/Auth'
import { snackbarTheme } from '@variables'
import ScrollToTop from './components/ScrollToTop'
import BadgeProvider from './context/Badge'
import DialogProvider from './context/Dialog'
import { RecaptchaProvider } from './context/Recaptcha'
import LibraryProvider from './context/Library'
import AudioPlayerProvider from './context/AudioPlayer'
import NotificationProvider from './context/Notification'
import ChaptersTableProvider from './context/ChaptersTable'
import DailyQuestionProvider from './context/DailyQuestion'
import TimeProvider from './context/Time'

/**
 * Kick off the polyfills if it needed
 */
smoothscroll.polyfill()

if (typeof window !== 'undefined' && isIE) {
    // @ts-ignore
    import('date-time-format-timezone')
}

if (typeof window !== 'undefined') {
    if (typeof window.IntersectionObserver === 'undefined') {
        // @ts-ignore
        import('intersection-observer')
    }
}

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
        __schema: {
            types: []
        }
    }
})

const API_URL = process.env.RAZZLE_API_URL

/**
 * Cache are restored from window object
 * sent from the server
 */
const cache = new InMemoryCache({
    cacheRedirects: {
        Query: {}
    },
    fragmentMatcher: fragmentMatcher
    // @ts-ignore
}).restore(window.__APOLLO_STATE__)

/**
 * Catching errors
 */
/**
 * Catching errors
 */
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )

            if (
                extensions?.code === 'FORBIDDEN' &&
                window?.location.pathname.startsWith('/admin')
            ) {
                window?.location?.replace('/')
            }
        })
    }
    if (networkError) {
        console.log(networkError)
    }
})

const client = new ApolloClient({
    link: ApolloLink.from(
        process.env.NODE_ENV === 'development'
            ? [
                  apolloLogger,
                  errorLink,
                  createUploadLink({
                      uri: API_URL,
                      credentials: 'include',
                      includeExtensions: true
                  })
              ]
            : [
                  errorLink,
                  createUploadLink({
                      uri: API_URL,
                      credentials: 'include',
                      includeExtensions: true
                  })
              ]
    ),
    defaultOptions: {
        query: {
            errorPolicy: 'all'
        },
        mutate: {
            errorPolicy: 'all'
        },
        watchQuery: {
            errorPolicy: 'all'
        }
    },
    cache
})

hydrate(
    <LocalizationProvider dateAdapter={DateFnsUtils} locale={cs}>
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3} classes={{ ...snackbarTheme }}>
                <ApolloProvider client={client}>
                    <BrowserRouter>
                        <RecaptchaProvider>
                            <AudioPlayerProvider>
                                <DialogProvider>
                                    <AuthProvider>
                                        <LibraryProvider>
                                            <DailyQuestionProvider>
                                                <BadgeProvider>
                                                    <NotificationProvider>
                                                        <TimeProvider>
                                                            <ChaptersTableProvider>
                                                                <ScrollToTop />
                                                                <App />
                                                            </ChaptersTableProvider>
                                                        </TimeProvider>
                                                    </NotificationProvider>
                                                </BadgeProvider>
                                            </DailyQuestionProvider>
                                        </LibraryProvider>
                                    </AuthProvider>
                                </DialogProvider>
                            </AudioPlayerProvider>
                        </RecaptchaProvider>
                    </BrowserRouter>
                </ApolloProvider>
            </SnackbarProvider>
        </ThemeProvider>
    </LocalizationProvider>,
    document.getElementById('root')
)

// @ts-ignore
if (module.hot) {
    // @ts-ignore
    module.hot.accept()
}
