import { useSnackbar } from 'notistack'
import Box from '@material-ui/core/Box'
import Tabs from '@material-ui/core/Tabs'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Link, useParams } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import HeadsetOutlined from '@material-ui/icons/HeadsetOutlined'
import React, { useContext, useEffect, useState, ChangeEvent } from 'react'

import * as loco from '@loco'
import Head from '../../../components/Head'
import { colors, Routes } from '@variables'
import { AuthContext } from '../../../context/Auth'
import CoinsIcon from '../../../components/SVG/Coins'
import Loader from '../../../components/shared/Loader'
import VideoPlayer from '../../../components/VideoPlayer'
import Breadcrumbs from '../../../components/Breadcrumbs'
import { AudioPlayerContext } from '../../../context/AudioPlayer'
import TruncatedText from '../../../components/shared/TruncatedText'
import ExpansionPanel from '../../../components/ExpansionPanels/Lecture'
import LecturePreview from '../../../components/Chapter/components/LecturePreview'
import { AudioFragment, CreationStatus, useLectureQuery, VideoFragment } from '@graphql'
import { sortByCreationStatus, markdownToHtml, getRouteWithId, translateGQLError } from '@utils'
import {
    Tab,
    Info,
    Name,
    Right,
    Header,
    Points,
    Section,
    Subtitle,
    Container,
    LeftArrow,
    OnlyMobile,
    RightArrow,
    ChapterIcon,
    Description,
    AudioButton,
    TabsWrapper,
    OnlyDesktop,
    MobilePoints,
    VideoWrapper,
    ArrowWrapper,
    ChapterIconWrapper
} from './styled'

const LecturePage = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { id: lectureId } = useParams<{ id: string }>()

    const audioPlayer = useContext(AudioPlayerContext)
    const { isAuthorized, user } = useContext(AuthContext)

    const [tabIdx, setTabIdx] = useState(1)
    const onChange = (e: ChangeEvent<{}>, newValue: number) => {
        // setTabIdx(newValue)
    }

    const { data, loading, error, refetch } = useLectureQuery({
        variables: { where: { id: lectureId } }
    })

    useEffect(() => {
        refetch()
    }, [user])

    if (loading) return <Loader />
    if (!data || !data.lecture || error) {
        error && enqueueSnackbar(translateGQLError(error.message), { variant: 'error' })
        return null
    }

    const { lecture } = data

    const {
        id,
        name,
        test,
        index,
        chapter,
        library,
        maxPoints,
        transcript,
        description,
        acquiredPoints
    } = lecture

    // @ts-ignore
    const audio: AudioFragment | undefined = library?.find((file) => file.__typename === 'Audio')
    // @ts-ignore
    const video: VideoFragment | undefined = library?.find((file) => file.__typename === 'Video')

    const { lectures } = chapter
    const filteredLectures = lectures?.filter(
        (l) =>
            l.status === CreationStatus.APPROVED ||
            (l.status === CreationStatus.BLOCKED && (l?.acquiredPoints || 0) > 0)
    )

    const prev = [...lectures]
        .reverse()
        .find((l) => l.index < index && l.status === CreationStatus.APPROVED)
    const next = lectures.find((l) => l.index > index && l.status === CreationStatus.APPROVED)

    const load = () => audioPlayer.load(audio?.id || '', audio?.src || '#')

    const coinsColor = Boolean(acquiredPoints) ? colors.primary.orange : colors.primary.darkBlue

    return (
        <>
            <Head title={`${loco.seo.lecture.title} ${name}`} />

            <Container>
                <Section style={{ marginBottom: 0 }}>
                    <Header>
                        <Link
                            className={getButtonClass(prev)}
                            to={getRouteWithId(Routes.LECTURE, prev?.id || '#')}
                        >
                            <ArrowWrapper direction="left" variant="body2">
                                <LeftArrow /> <span>{loco.lecture.previous}</span>
                            </ArrowWrapper>
                        </Link>

                        <Link
                            style={{ color: 'inherit' }}
                            to={getRouteWithId(Routes.CHAPTER, chapter.id)}
                        >
                            <ChapterIconWrapper variant="body2">
                                <ChapterIcon />
                                <span>{loco.lecture.chapter}</span>
                            </ChapterIconWrapper>
                        </Link>

                        <Link
                            className={getButtonClass(next)}
                            to={getRouteWithId(Routes.LECTURE, next?.id || '#')}
                        >
                            <ArrowWrapper direction="right" variant="body2">
                                <span>{loco.lecture.next}</span> <RightArrow />
                            </ArrowWrapper>
                        </Link>
                    </Header>

                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <OnlyMobile>
                                <Breadcrumbs
                                    lecture={{ name: name, id: id }}
                                    chapter={{ id: chapter.id, name: chapter.name }}
                                />

                                <Name variant="h6">{name}</Name>
                                <Description paragraph>{description}</Description>
                                <Subtitle paragraph>{loco.lecture.rewards}</Subtitle>

                                <MobilePoints paragraph>
                                    <CoinsIcon color={coinsColor} />
                                    {acquiredPoints || 0}/{maxPoints} {loco.common.points}
                                </MobilePoints>
                            </OnlyMobile>

                            <VideoWrapper>
                                <VideoPlayer src={video?.src} preview={video?.preview} />
                            </VideoWrapper>

                            <AudioButton
                                size="large"
                                color="primary"
                                variant="outlined"
                                onClick={load}
                            >
                                <HeadsetOutlined
                                    style={{
                                        color: colors.primary.orange,
                                        marginRight: 10,
                                        height: 18,
                                        width: 18
                                    }}
                                />
                                {loco.lecture.listen}
                            </AudioButton>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Right>
                                <OnlyDesktop>
                                    <Breadcrumbs
                                        lecture={{ name: name, id: id }}
                                        chapter={{ id: chapter.id, name: chapter.name }}
                                    />

                                    <Typography variant="h1" style={{ margin: '10px 0 15px 0' }}>
                                        {name}
                                    </Typography>

                                    <Typography
                                        paragraph
                                        title={description}
                                        style={{ marginBottom: 0 }}
                                    >
                                        <TruncatedText text={description} max={200} />
                                    </Typography>
                                </OnlyDesktop>

                                <OnlyDesktop>
                                    <Subtitle paragraph>{loco.lecture.rewards}</Subtitle>
                                    <Points paragraph>
                                        <CoinsIcon color={coinsColor} />
                                        {acquiredPoints || 0}/{maxPoints} {loco.common.points}
                                    </Points>
                                </OnlyDesktop>

                                <div>
                                    <Link to={getRouteWithId(Routes.TEST, test?.id || '#')}>
                                        <Button
                                            size="large"
                                            color="secondary"
                                            variant="contained"
                                            style={{ width: '100%', marginBottom: 15 }}
                                        >
                                            {acquiredPoints || 0 > 0
                                                ? loco.lecture.repeat
                                                : loco.lecture.start}
                                        </Button>
                                    </Link>

                                    <Info paragraph>{loco.lecture.info}</Info>
                                </div>
                            </Right>
                        </Grid>
                    </Grid>

                    {/* Tabs */}
                    <TabsWrapper>
                        <Tabs
                            value={tabIdx}
                            variant="fullWidth"
                            textColor="primary"
                            onChange={onChange}
                            indicatorColor="primary"
                            aria-label="Chapter content"
                            style={{ borderBottom: '2px solid #EBEBEB' }}
                        >
                            <Tab
                                {...a11yProps(1)}
                                style={{ fontSize: 14 }}
                                label={loco.lecture.transcript}
                            />
                        </Tabs>

                        <TabPanel value={tabIdx} index={1}>
                            <ExpansionPanel type="transcript" value={markdownToHtml(transcript)} />
                        </TabPanel>
                    </TabsWrapper>

                    {/* Similar lectures */}
                    {!isAuthorized || Boolean(user?.isTemporary) ? null : (
                        <div style={{ width: '100%' }}>
                            <Typography
                                variant="h3"
                                align="left"
                                gutterBottom
                                style={{ fontWeight: 400 }}
                            >
                                {loco.lecture.other}
                            </Typography>

                            <Grid container spacing={2}>
                                {filteredLectures
                                    .sort(sortByCreationStatus)
                                    .map((lecture, idx: number) => {
                                        // @ts-ignore
                                        const video:
                                            | VideoFragment
                                            | undefined = lecture?.library?.find(
                                            (f) => f.__typename === 'Video'
                                        )
                                        const isLectureBlocked =
                                            lecture.status === CreationStatus.BLOCKED

                                        return !lecture.test?.isChapterSummary &&
                                            lecture.id !== lectureId ? (
                                            <Grid
                                                item
                                                sm={6}
                                                md={4}
                                                lg={3}
                                                xs={12}
                                                key={lecture.id}
                                            >
                                                <LecturePreview
                                                    idx={idx}
                                                    id={lecture.id}
                                                    name={lecture.name}
                                                    style={{ maxWidth: '100%' }}
                                                    isBlocked={isLectureBlocked}
                                                    maxPoints={lecture.maxPoints}
                                                    videoPreview={video?.preview || ''}
                                                    acquiredPoints={lecture.acquiredPoints}
                                                />
                                            </Grid>
                                        ) : null
                                    })}
                            </Grid>
                        </div>
                    )}
                </Section>
            </Container>
        </>
    )
}

type TabPanelProps = {
    children?: React.ReactNode
    className?: string
    index: number
    dir?: string
    value?: any
}

const TabPanel = ({ children, value, index, className, ...other }: TabPanelProps) => (
    <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        className={className || ''}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
    >
        <Box p={3} style={{ backgroundColor: '#FAFAFA', fontSize: 14 }}>
            {children}
        </Box>
    </Typography>
)

const a11yProps = (index: number) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    }
}

const getButtonClass = (lecture?: { status: CreationStatus } | null) => {
    if (!lecture || lecture?.status !== CreationStatus.APPROVED) return 'lectureDisabledButton'
    return 'lectureActiveButton'
}

export default LecturePage
