import get from 'lodash/get'
import React, { useState, useContext } from 'react'

import Arrow from '../shared/Arrow'
import Carousel from './components/Carousel'
import { sortByCreationStatus } from '@utils'
import { Blur, BlurWrapper } from '../shared/styled'
import { AuthContext } from '../../context/Auth'
import LecturePreview from './components/LecturePreview'
import ChapterPreview from './components/ChapterPreview'
import { CreationStatus, ChaptersQuery } from '@graphql'
import { Wrapper, ArrowWrapper, Accordion, AccordionSummary, AccordionDetails } from './styled'

type Props = {
    isLastActive?: boolean
    chapter: ChaptersQuery['chapters'][0]
}

const Chapter = ({ chapter, isLastActive }: Props) => {
    const { isAuthorized, user } = useContext(AuthContext)

    const { lectures, id } = chapter
    const isTempOrNotAuth = !isAuthorized || Boolean(user?.isTemporary)

    const [expanded, setExpanded] = useState(false)

    // Doubling the FIRST LECTURE beacause of need to show fake(blurred) lecture in carousel
    // When user isTempOrNotAuth lectures.length always must be === 1
    const Lectures = isTempOrNotAuth ? [...lectures, ...lectures] : [...lectures]
    const filtredLectures = Lectures.filter(
        (l) =>
            !l.test?.isChapterSummary &&
            (l.status === CreationStatus.APPROVED ||
                (l.status === CreationStatus.BLOCKED && (l?.acquiredPoints || 0) > 0))
    )

    return (
        <Accordion expanded={expanded}>
            <AccordionSummary role="article" data-cy="chapter-preview-expansion-panel-summary">
                <ChapterPreview
                    chapter={chapter}
                    expanded={expanded}
                    isLastActive={isLastActive}
                    lecturesLength={filtredLectures.length}
                />
                {!expanded && filtredLectures.length > 0 && (
                    <ArrowWrapper
                        onClick={() => setExpanded(!expanded)}
                        data-cy="chapter-preview-open-arrow"
                    >
                        <Arrow variant="bottom" />
                    </ArrowWrapper>
                )}
            </AccordionSummary>

            <AccordionDetails style={{ padding: 0 }}>
                <Wrapper>
                    <Carousel
                        chapter={chapter}
                        element={`glide-${id}`}
                        options={carouselOptions}
                        prevButton={<Arrow variant="left" />}
                        nextButton={<Arrow variant="right" style={{ marginRight: 30 }} />}
                    >
                        {filtredLectures.length > 0 &&
                            filtredLectures
                                .sort(sortByCreationStatus)
                                .map((lecture, idx: number) => {
                                    const video = lecture?.library?.find(
                                        (v) => v.__typename === 'Video'
                                    )
                                    const isLectureBlocked =
                                        lecture.status === CreationStatus.BLOCKED

                                    return (
                                        <BlurWrapper key={lecture.id}>
                                            {isTempOrNotAuth && idx > 0 && <Blur />}
                                            <LecturePreview
                                                idx={idx}
                                                id={lecture.id}
                                                name={lecture.name}
                                                isBlocked={isLectureBlocked}
                                                maxPoints={lecture.maxPoints}
                                                acquiredPoints={lecture.acquiredPoints}
                                                videoPreview={get(video, 'preview', '')}
                                                style={
                                                    isTempOrNotAuth && idx > 0
                                                        ? { filter: 'blur(3.5px)' }
                                                        : undefined
                                                }
                                            />
                                        </BlurWrapper>
                                    )
                                })}
                    </Carousel>

                    {expanded && (
                        <ArrowWrapper
                            onClick={() => setExpanded(!expanded)}
                            data-cy="chapter-preview-close-arrow"
                        >
                            <Arrow variant="top" />
                        </ArrowWrapper>
                    )}
                </Wrapper>
            </AccordionDetails>
        </Accordion>
    )
}

const carouselOptions = {
    swipeThreshold: 10,
    dragThreshold: 10,
    type: 'slider',
    rewind: false,
    bound: false,
    startAt: 0,
    perView: 3,
    gap: 20,
    peek: {
        before: 140,
        after: -70
    },
    breakpoints: {
        1170: {
            perView: 2,
            peek: {
                before: 140,
                after: 70
            }
        },
        1050: {
            perView: 2,
            peek: {
                before: 140,
                after: 50
            }
        },
        854: {
            perView: 3,
            peek: {
                before: 30,
                after: 70
            }
        },
        680: {
            perView: 2,
            peek: {
                before: 30,
                after: 70
            }
        },
        540: {
            perView: 1,
            peek: {
                before: 30,
                after: 120
            }
        },
        400: {
            perView: 1,
            peek: {
                before: 30,
                after: 50
            }
        }
    }
}

export default Chapter
