import React from 'react'
import { useSnackbar } from 'notistack'
import { Link, useParams } from 'react-router-dom'
import { Grid, Typography } from '@material-ui/core'

import * as loco from '@loco'
import { translateGQLError } from '@utils'
import { colors, Routes } from '@variables'
import Head from '../../../../../components/Head'
import Loader from '../../../../../components/shared/Loader'
import { Container, Tag, Video, VideoWrapper } from './styled'
import VideoPlayer from '../../../../../components/VideoPlayer'
import VideoBox from '../../../../../components/shared/VideoBox'
import BackButton from '../../../../../components/shared/BackButton'
import { CustomLink, BottomHeader, Section, Header } from '../styled'
import { VideoFragment as VFragment, useLibraryVideoItemQuery } from '@graphql'

interface VideoFragment extends VFragment {
    similarItems: Array<Omit<VFragment, 'author'>>
}

const VideoDetail = () => {
    const { enqueueSnackbar } = useSnackbar()
    const params = useParams<{ readonly id: string }>()

    const { data, error, loading } = useLibraryVideoItemQuery({
        variables: { where: { id: params.id } }
    })

    if (loading) {
        return (
            <Container>
                <Section>
                    <Loader pure />
                </Section>
            </Container>
        )
    }

    // @ts-ignore
    const video: VideoFragment | undefined = data?.libraryItem

    if (!data || !video) {
        error && enqueueSnackbar(translateGQLError(error.message), { variant: 'error' })
        return null
    }

    return (
        <>
            <Head title={`${loco.seo.library.video.title} ${video?.name || ''}`} />

            <Container>
                <Section>
                    <div>
                        <Link to={Routes.LIBRARY}>
                            <BackButton>{loco.common.backToLibrary}</BackButton>
                        </Link>
                        <Header>
                            <Typography variant="h3" align="center" style={{ marginBottom: 10 }}>
                                {video.name}
                            </Typography>
                            <Tag>{video.lecture.name}</Tag>
                        </Header>
                    </div>

                    <VideoWrapper>
                        <Video>
                            <VideoPlayer src={video?.src} preview={video?.preview} />
                        </Video>
                    </VideoWrapper>

                    {(video?.similarItems?.length || 0) > 0 && (
                        <footer>
                            <BottomHeader>
                                <Typography variant="h5" style={{ fontWeight: 400 }}>
                                    {loco.library.video.similar}
                                </Typography>
                                <CustomLink to={Routes.LIBRARY}>
                                    {loco.library.video.all}
                                </CustomLink>
                            </BottomHeader>

                            <Grid container spacing={2}>
                                {video?.similarItems
                                    // @ts-ignore
                                    .map((video: VideoFragment) => (
                                        <Grid item xs={12} sm={6} md={3} key={video.id}>
                                            <VideoBox
                                                id={video.id}
                                                name={video.name}
                                                preview={video?.preview}
                                                duration={video?.length}
                                                tag={video?.lecture?.chapter?.name}
                                                playBtnColor={colors.primary.orange}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                        </footer>
                    )}
                </Section>
            </Container>
        </>
    )
}

export default VideoDetail
