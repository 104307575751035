import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="257"
            height="237"
            fill="none"
            viewBox="0 0 257 237"
            {...props}
        >
            <path
                fill="#64937B"
                d="M158.151 153.338l-8.006 11.279 5.035 3.502 8.007-11.278-5.036-3.503z"
            ></path>
            <path
                fill="#F7E5B5"
                d="M153.79 123.522l-8.677 15.96 9.485 5.053 8.677-15.961-9.485-5.052z"
            ></path>
            <path
                fill="#A6CEE8"
                d="M196.147 152.757l-12.309 9.139 3.692 4.873 12.31-9.138-3.693-4.874zM134.132 99.897l-4.456 9.202 5.549 2.634 4.457-9.202-5.55-2.634zM179.138 109.809l-2.938 6.123 3.683 1.731 2.937-6.123-3.682-1.731z"
            ></path>
            <path
                fill="url(#paint0_linear)"
                d="M90.373 106.485l5.25 24.067.075.38c.107.472.291.924.546 1.335l12.922 19.955a4.636 4.636 0 008.114-4.431l-9.768-21.685.637 1.7-5.232-24.052a6.422 6.422 0 00-7.637-4.91 6.42 6.42 0 00-4.908 7.641h.001z"
            ></path>
            <path
                fill="url(#paint1_linear)"
                d="M103.509 126.521l13.772 21.27 7.826 18.858v30.476l-12.724 2.385-2.736-24.026-26.366-47.521 20.228-1.442z"
            ></path>
            <path
                fill="#3552A7"
                d="M80.212 114.847c-2.336 4.188-1.183 10.683 3.883 11.366 3.898.531 4.156.728 5.793.956 5.203.713 9.51-4.097 8.691-9.287l-18.367-3.035z"
            ></path>
            <path
                fill="url(#paint2_linear)"
                d="M79.862 116.076l-.334.531-.318.546-.637 1.078-.622 1.092-.607 1.093c-.41.728-.788 1.472-1.183 2.201l-1.153 2.215c-1.531 2.959-2.973 5.964-4.368 8.984-.349.759-.697 1.502-1.046 2.276l-1.017 2.276c-.348.759-.667 1.533-1.002 2.292a140.2 140.2 0 00-.985 2.291l-.956 2.306c-.318.774-.637 1.533-.956 2.322-.621 1.547-1.258 3.096-1.85 4.659l.606-1.077-1.41 2.549-.697 1.275-.683 1.274c-.925 1.7-1.851 3.415-2.745 5.129l-1.35 2.58-1.335 2.595c-.895 1.73-1.745 3.475-2.624 5.205a396.76 396.76 0 00-5.021 10.562c-.394.895-.819 1.775-1.198 2.671-.394.895-.789 1.79-1.168 2.685-.379.911-.773 1.806-1.153 2.702l-.56 1.366-.274.683-.273.683 10.436 6.813 25.33-38.803c.232-.347.435-.712.607-1.092l17.124-36.117-16.578-7.845z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M61.964 204.591l-18.656-6.935a1.94 1.94 0 01-1.153-2.428l.819-2.383 11.755 4.507 8.13 5.524c.94.637.182 2.109-.895 1.715zM134.708 199.565l-19.552 3.723a1.936 1.936 0 01-2.242-1.481l-.531-2.463 12.39-2.228 9.814.519c1.134.058 1.247 1.71.121 1.93z"
            ></path>
            <path
                fill="url(#paint3_linear)"
                d="M98.58 117.867a8.39 8.39 0 01-.016-2.671c.228-1.488 4.202-3.187 4.824-7.724.47-3.491-2.458-9.181-8.51-10.061l-4.914-.546c-5.324-.774-10.269 3.217-11.072 6.874h-.016c-.015.106-.03.212-.045.303 0 .031-.015.061-.015.091-.654 6.207 3.67 6.586 1.38 10.699l18.383 3.035z"
            ></path>
            <path
                fill="#FDC068"
                d="M98.382 93.95l-4.444-.651a4.2 4.2 0 01-3.55-4.78l.986-6.708a6.755 6.755 0 017.645-5.69 6.75 6.75 0 014.422 2.648 6.76 6.76 0 011.266 5l-.91 6.144a4.78 4.78 0 01-5.415 4.038z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M103.812 85.726a.755.755 0 00.501.713l.212-1.471a.754.754 0 00-.713.758z"
            ></path>
            <path
                fill="#FDC068"
                d="M93.786 101.038l-5.415-2.93L90.798 85.5l7.099 1.017-1.169 12.929a1.998 1.998 0 01-2.942 1.593z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M102.432 87.73a.11.11 0 00.075-.016l.697-.288a.748.748 0 00.36-.756.74.74 0 00-.102-.276l-.773-1.26a.226.226 0 00-.136-.1.22.22 0 00-.168.024.231.231 0 00-.064.058.224.224 0 00-.012.246l.774 1.26a.313.313 0 01-.091.424l-.653.273a.221.221 0 00-.119.12.224.224 0 00-.003.169.244.244 0 00.215.121zM97.23 93.784l-2.898-.425 2.64 3.308.258-2.883zM100.233 85.863a.759.759 0 110-1.518.759.759 0 010 1.518zM102.886 90.43a.213.213 0 00.213-.182.235.235 0 00-.182-.258l-2.321-.41a.223.223 0 10-.076.44l2.321.41h.045z"
            ></path>
            <path
                fill="#5D5D5D"
                d="M97.85 80.627l-1.289 6.996-1.577-.288.395-2.141a.378.378 0 00-.637-.334l-4.748 4.902 1.29-8.407s.439-1.578 1.182-2.383c.652-.682 5.385 1.655 5.385 1.655z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M98.123 75.422l2.291.424a5.89 5.89 0 014.732 6.86l-13.878-2.55a5.892 5.892 0 016.855-4.734z"
            ></path>
            <path
                fill="#FDC068"
                d="M93.073 86.849a2.534 2.534 0 11.002-5.067 2.534 2.534 0 01-.002 5.067z"
            ></path>
            <path
                fill="#fff"
                d="M86.991 96.925l6.735 11.229 4.474-9.635s-2.745-1.564-5.612-1.853a21.394 21.394 0 00-5.597.259z"
            ></path>
            <path
                fill="#FDC068"
                d="M96.865 97.881l-2.821 3.612-5.46-4.795 1.926-1.078 6.416 1.548-.061.713z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M105.829 129.369l-10.845.85-3.26-11.503h3.715l10.39 10.653z"
            ></path>
            <path
                fill="url(#paint4_linear)"
                d="M93.893 117.108l-.728 10.486-19.855-.365 8.024-14.841 12.559 4.72z"
            ></path>
            <path
                fill="url(#paint5_linear)"
                d="M92.588 115.636l4.065 12.458 9.617 1.184-7.13-12.747-6.552-.895z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M84.078 150.007l-20.643 1.168 2.2-5.903 20.066 1.29-1.623 3.445z"
            ></path>
            <path
                fill="#454545"
                d="M62.01 148.262l28.333.045a2.792 2.792 0 002.79-2.777l.031-16.829a2.791 2.791 0 00-2.776-2.792l-28.333-.045a2.792 2.792 0 00-2.79 2.777l-.031 16.828a2.782 2.782 0 002.775 2.793z"
            ></path>
            <path
                fill="#454545"
                d="M71.368 131.418H81.79a1.204 1.204 0 001.198-1.199v-8.665a1.203 1.203 0 00-1.198-1.199H71.37a1.204 1.204 0 00-1.198 1.199v8.665a1.19 1.19 0 00.738 1.109c.146.06.302.09.46.09zm9.223-2.397h-8.009v-6.253h8.009v6.253z"
            ></path>
            <path
                fill="#FDC068"
                d="M72.264 121.235l2.624 1.867a4.633 4.633 0 007.304-4.039 4.637 4.637 0 00-1.404-3.078l-2.594-2.504"
            ></path>
            <path
                fill="url(#paint6_linear)"
                d="M78.194 113.466L63.678 99.459l-3.899 11.366 24.86-.728 1.078-12.884-26.301.774-.486.016a6.244 6.244 0 00-5.802 4.439 6.25 6.25 0 002.375 6.911l16.775 11.882 5.916-7.769z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M98.746 114.953a.246.246 0 00.167-.076c1.805-1.973 2.943-3.232 3.625-4.31a.221.221 0 00.033-.164.229.229 0 00-.033-.079.228.228 0 00-.06-.06.229.229 0 00-.079-.033.221.221 0 00-.085.001.21.21 0 00-.139.093c-.683 1.045-1.805 2.306-3.595 4.249a.215.215 0 00.015.303.191.191 0 00.151.076zM103.296 124.164a.28.28 0 00.106-.03.222.222 0 00.076-.304l-1.532-2.716a.224.224 0 00-.222-.105.222.222 0 00-.157.333l1.546 2.716a.186.186 0 00.183.106zM74.614 110.233a.16.16 0 00.084-.011.167.167 0 00.068-.049.23.23 0 000-.304l-4.49-4.324a.215.215 0 00-.15-.058.213.213 0 00-.212.211c-.001.056.02.11.058.151l4.49 4.325a.194.194 0 00.152.059zM95.576 99.52l3.853-.228a11.692 11.692 0 00-2.564-1.427l-1.29 1.655zM80.105 110.446a.246.246 0 00.167-.076l1.243-1.441a.219.219 0 00.038-.238.211.211 0 00-.05-.068.211.211 0 00-.239-.034.211.211 0 00-.067.051l-.94 1.093-.228-1.351a.223.223 0 00-.44.076l.304 1.806a.21.21 0 00.153.166.091.091 0 00.059.016zM102.523 158.611h.03a.24.24 0 00.146-.083.237.237 0 00.052-.16l-.456-3.839a.225.225 0 00-.024-.084.225.225 0 00-.219-.117.223.223 0 00-.149.082.215.215 0 00-.047.164l.455 3.84a.216.216 0 00.067.14c.04.036.091.057.145.057z"
            ></path>
            <path
                fill="url(#paint7_linear)"
                d="M194.181 74.128l10.567 23.298-24.304-18.048-10.356 25.046-3.486-25.68-12.967 7.974-1.721-11.456-25.471 5.949 20.852-19.71-13.807-14.494 16.906 1.407-2.817-21.39 22.406 16.63 10.99-20.994 2.958 22.79 11.125-3.435 1.233 9.46 18.597-5.489-14.758 16.22 23.962 13.228-29.909-1.306z"
            ></path>
            <path
                fill="#BFD9FF"
                d="M132.441 67.006l-1.285-1.62 5.784-4.577-2.603-2.634 1.473-1.452 4.224 4.274-7.593 6.01zM198.347 46.46l-2.115-10.39-6.553 1.688-.517-2.002 8.663-2.23 2.111 10.368 15.633-4.184.536 1.997-17.758 4.752z"
            ></path>
            <path
                fill="#A6CEE8"
                d="M215.242 60.752l-5.542.22.132 3.304 5.541-.22-.131-3.304zM137.285 18.417l-1.718 1.363 2.292 2.88 1.717-1.362-2.291-2.881z"
            ></path>
            <path
                fill="#F7E5B5"
                d="M136.828 30.506l-1.702 1.176 5.463 7.89 1.702-1.175-5.463-7.891z"
            ></path>
            <path
                fill="#A6CEE8"
                d="M183.212 88.322l-2.009.496 1.987 8.028 2.009-.496-1.987-8.028z"
            ></path>
            <path
                fill="#64937B"
                d="M161.627 88.492l-4.71 5.82 1.609 1.3 4.71-5.82-1.609-1.3z"
            ></path>
            <path
                fill="#7F9F8E"
                d="M177.856 52.447c.047 1.003.036 1.532.083 2.535a.868.868 0 01-.129.351c-.062.094-.179.119-.335.151l-.039.008c-.827-.07-1.724-.087-2.74-.079-1.016.008-1.95 0-2.831.062a7.805 7.805 0 00-.913.108 1.364 1.364 0 00-.668.3c-.18.13-.312.314-.376.525a1.7 1.7 0 00.023.886c.06.282.213.536.434.72.391.278.808.517 1.246.713 1.347.612 2.028.956 3.375 1.567a6.577 6.577 0 012.589 1.893 5.532 5.532 0 011.071 2.208c.19.79.223 1.608.098 2.41a4.4 4.4 0 01-.819 1.913 5.036 5.036 0 01-1.614 1.391 6.578 6.578 0 01-2.279.758c.039.964.019 1.454.059 2.418a.646.646 0 01-.495.549c-1.161.08-1.723.115-2.884.194a.461.461 0 01-.534-.415l-.016-.078c-.031-.925-.043-1.368-.073-2.294-.599.022-1.198.011-1.795-.032-.537-.05-1.074-.1-1.537-.166a.468.468 0 01-.487-.385l-.016-.078c-.047-1.002-.036-1.532-.083-2.535a.868.868 0 01.129-.35.406.406 0 01.334-.151l.039-.008c.353.025.707.032 1.061.022.445-.011.889-.023 1.381-.004.483-.019.936.008 1.372-.042.444-.011.842-.054 1.131-.033.397-.041.755-.076 1.106-.149.296-.061.575-.188.815-.371.2-.177.348-.404.43-.657a2.44 2.44 0 00-.024-1.09 1.392 1.392 0 00-.443-.758 6.175 6.175 0 00-1.224-.8l-3.642-1.875a6.351 6.351 0 01-2.284-1.794 5.126 5.126 0 01-.953-2.03 5.41 5.41 0 01-.065-2.255 4.472 4.472 0 01.734-1.733c.39-.509.885-.929 1.451-1.232a7.213 7.213 0 012.043-.708c-.021-.889-.035-1.332-.057-2.218a.616.616 0 01.168-.36.47.47 0 01.373-.16 79.259 79.259 0 012.892-.156.424.424 0 01.44.219.421.421 0 01.047.166l.025.117c.014.848.026 1.29.041 2.138.772 0 1.513.05 2.215.108.702.057 1.249.146 1.625.19a.71.71 0 01.313.137c.091.06.165.143.215.24h-.003z"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="103.962"
                    x2="103.962"
                    y1="99.019"
                    y2="175.806"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6B9C83"></stop>
                    <stop offset="0.401" stopColor="#A6A6A4"></stop>
                    <stop offset="0.501" stopColor="#9F9FC8"></stop>
                    <stop offset="0.591" stopColor="#9FCCEC"></stop>
                    <stop offset="1" stopColor="#FFE7B0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="-5886.35"
                    x2="-5886.35"
                    y1="12486.7"
                    y2="35372.2"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6B9C83"></stop>
                    <stop offset="0.401" stopColor="#A6A6A4"></stop>
                    <stop offset="0.501" stopColor="#9F9FC8"></stop>
                    <stop offset="0.591" stopColor="#9FCCEC"></stop>
                    <stop offset="1" stopColor="#FFE7B0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="-16067.4"
                    x2="-16067.4"
                    y1="-57179.4"
                    y2="-27627.7"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6B9C83"></stop>
                    <stop offset="0.401" stopColor="#A6A6A4"></stop>
                    <stop offset="0.501" stopColor="#9F9FC8"></stop>
                    <stop offset="0.591" stopColor="#9FCCEC"></stop>
                    <stop offset="1" stopColor="#FFE7B0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint3_linear"
                    x1="-4930.27"
                    x2="-4930.27"
                    y1="-14319.3"
                    y2="-6883.82"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6B9C83"></stop>
                    <stop offset="0.401" stopColor="#A6A6A4"></stop>
                    <stop offset="0.501" stopColor="#9F9FC8"></stop>
                    <stop offset="0.591" stopColor="#9FCCEC"></stop>
                    <stop offset="1" stopColor="#FFE7B0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint4_linear"
                    x1="-4810.54"
                    x2="-4810.54"
                    y1="-10276.5"
                    y2="-4918.16"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6B9C83"></stop>
                    <stop offset="0.401" stopColor="#A6A6A4"></stop>
                    <stop offset="0.501" stopColor="#9F9FC8"></stop>
                    <stop offset="0.591" stopColor="#9FCCEC"></stop>
                    <stop offset="1" stopColor="#FFE7B0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint5_linear"
                    x1="-2159.47"
                    x2="-2159.47"
                    y1="-9205.4"
                    y2="-4397.83"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6B9C83"></stop>
                    <stop offset="0.401" stopColor="#A6A6A4"></stop>
                    <stop offset="0.501" stopColor="#9F9FC8"></stop>
                    <stop offset="0.591" stopColor="#9FCCEC"></stop>
                    <stop offset="1" stopColor="#FFE7B0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint6_linear"
                    x1="-9902.32"
                    x2="-9902.32"
                    y1="-16315.7"
                    y2="-7850.3"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6B9C83"></stop>
                    <stop offset="0.401" stopColor="#A6A6A4"></stop>
                    <stop offset="0.501" stopColor="#9F9FC8"></stop>
                    <stop offset="0.591" stopColor="#9FCCEC"></stop>
                    <stop offset="1" stopColor="#FFE7B0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint7_linear"
                    x1="126.443"
                    x2="224.09"
                    y1="63.542"
                    y2="63.542"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7AAEFC"></stop>
                    <stop offset="0.538" stopColor="#D2D4D4"></stop>
                    <stop offset="1" stopColor="#fff"></stop>
                </linearGradient>
            </defs>
        </svg>
    )
}

export default Icon
