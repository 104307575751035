import React from 'react'

import { Props } from '.'

function Icon({ color }: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="none"
            viewBox="0 0 25 25"
        >
            <path
                fillRule="evenodd"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M8.97 21.277a1.5 1.5 0 01-2.126 0L4.72 19.152a1.5 1.5 0 010-2.125l14.4-14.4a3.005 3.005 0 014.25 4.25l-14.4 14.4z"
                clipRule="evenodd"
            ></path>
            <path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M19.117 2.63a3.006 3.006 0 00-4.25 0l-3.242 3.242M13.75 7.997l4.25 4.25M4.719 23.402l-2.124-2.125M3.657 22.34l-1.91 1.91"
            ></path>
        </svg>
    )
}

export default Icon
