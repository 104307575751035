import { useSetState } from 'react-use'
import React from 'react'

type Context = {
    itemsPerPage: number
    expandedIdxs: number[]
    setChaptersTableState: (state: { itemsPerPage?: number; expandedIdxs?: number[] }) => any
}

export const ChaptersTableContext = React.createContext<Context>({
    itemsPerPage: 10,
    expandedIdxs: [],
    setChaptersTableState: (state: { itemsPerPage?: number; expandedIdxs?: number[] }) => {}
})

const ChaptersTable = ({ children }: { children: any }) => {
    const [state, setState] = useSetState<Omit<Context, 'setChaptersTableState'>>({
        itemsPerPage: 10,
        expandedIdxs: []
    })

    const setChaptersTableState = (newState: {
        itemsPerPage?: number
        expandedIdxs?: number[]
    }) => {
        if (newState.itemsPerPage) setState({ itemsPerPage: newState.itemsPerPage })
        if (newState.expandedIdxs) setState({ expandedIdxs: newState.expandedIdxs })
    }

    return (
        <ChaptersTableContext.Provider value={{ ...state, setChaptersTableState }}>
            {children}
        </ChaptersTableContext.Provider>
    )
}

export default ChaptersTable
