import React, { useContext } from 'react'

import * as loco from '@loco'
import { Routes } from '@variables'
import Icon from '../../components/SVG/Daily'
import { Wrapper, Inner, Link, Title, Text, Button, Description } from './styled'
import { DailyQuestionContext } from '../../context/DailyQuestion'

const DailyQuestion = () => {
    const { isVisible } = useContext(DailyQuestionContext)

    if (!isVisible) return null

    return (
        <Wrapper>
            <Inner>
                <Icon style={{ width: 72, minWidth: 72, heigth: 'auto' }} />

                <Text>
                    <Title variant="h6">{loco['daily-question-box'].title}</Title>
                    <Description paragraph>{loco['daily-question-box'].subtitle}</Description>
                </Text>

                <Link to={Routes.DAILY_QUESTION}>
                    <Button size="large" variant="contained" color="secondary">
                        {loco['daily-question-box'].confirm}
                    </Button>
                </Link>
            </Inner>
        </Wrapper>
    )
}

export default DailyQuestion
