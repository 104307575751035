import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import React, { useState, useEffect, useCallback, useRef } from 'react'

import * as loco from '@loco'
import Head from '../../../../components/Head'
import Tooltip from '../../../../components/shared/Tooltip'
import Leaderboard from '../../../../components/Leaderboard'
import LevelBox from '../../../../components/shared/LevelBox'
import DiplomaBox from '../../../../components/shared/Diploma'
import Statistics from '../../../../components/shared/Statistics'
import ProfileBox from '../../../../components/Profile/ProfileBox'
import Achievements from '../../../../components/shared/Achievements'
import {
    BadgesFragment,
    useBadgesQuery,
    useFinalTestQuery,
    useLeaderboardQuery,
    LeaderboardFilterEnum
} from '@graphql'
import {
    Right,
    Left,
    Title,
    Aside,
    AsideInner,
    TitleWrapper,
    HelpIconWrapper
} from '../../../../components/shared/styled'

const initialState = Object.freeze({
    filters: [],
    isOpen: false,
    isNearBottom: false,
    page: undefined,
    itemsPerPage: 10,
    findIndicator: false
})

const MAX_ITEMS_PER_PAGE = 9999999

type State = {
    page?: number | null
    itemsPerPage: number
    isNearBottom: boolean
    isOpen: boolean
    findIndicator: boolean
    filters: LeaderboardFilterEnum[]
}

const Profile = () => {
    const [state, setState] = useState<State>(initialState)

    const scrollbarRef = useRef(null)

    const { data } = useLeaderboardQuery({
        variables: {
            page: state.page,
            filter: [...state.filters],
            itemsPerPage: state.itemsPerPage
        },
        onCompleted: () => {
            if (typeof document === 'undefined') return
            if (!state.findIndicator) return
            const el = document.getElementById('leadrboard-active')
            const offsetTop = el?.offsetTop
            // @ts-ignore
            scrollbarRef?.current.scrollTo(0, offsetTop)
            setState((prevState) => ({ ...prevState, findIndicator: false }))
        }
    })

    const setLeaderboardFilters = useCallback(
        (newFilters: LeaderboardFilterEnum[]) => {
            setState({ ...state, filters: newFilters })
        },
        [state]
    )

    const { data: finalTestData } = useFinalTestQuery({
        fetchPolicy: 'no-cache'
    })
    const { data: badgesData, loading: badgesLoading } = useBadgesQuery()

    const leaderboard = data?.leaderboard?.data || []
    const badges: BadgesFragment[] = badgesData?.badges || []

    const loadMore = useCallback(() => {
        setState({
            ...state,
            itemsPerPage: state.itemsPerPage + 10
        })
    }, [state])

    const findYourSelf = useCallback(() => {
        setState({
            ...state,
            findIndicator: true,
            itemsPerPage: MAX_ITEMS_PER_PAGE
        })
    }, [state])

    const toggleOpen = useCallback(() => {
        setState({ ...state, isOpen: !state.isOpen })
    }, [state])

    useEffect(() => {
        if (state.isNearBottom) {
            loadMore()
            setState((state) => {
                return { ...state, isNearBottom: false }
            })
            return
        }
    }, [state.isNearBottom])

    /**
     * Check if scroll near the end and set state if true
     *
     * @param state Scoll state
     * @param state.clientHeight - Outer
     * @param state.contentScrollHeight - Inner (overflowed)
     * @param state.scrollTop - Content's native scrollTop parameter
     */
    const onScroll = (state: {
        scrollTop: number
        clientHeight: number
        contentScrollHeight: number
    }): void => {
        const scrollTop = state.scrollTop
        const clientHeight = state.clientHeight
        const contentScrollHeight = state.contentScrollHeight

        const height: number = contentScrollHeight - clientHeight
        const isNearBottom = height - 100 <= Math.round(scrollTop)

        if (isNearBottom) {
            setState((state) => {
                return { ...state, isNearBottom: true }
            })
            return
        }
    }

    return (
        <>
            <Head title={loco.seo.profile.title} />

            <Container>
                <Grid container spacing={6}>
                    <Left item xs={12} md={8}>
                        <Title variant="h6">{loco.profile.profile.title}</Title>
                        <ProfileBox />

                        <Title variant="h6">{loco.profile.profile.leaderboard}</Title>
                        <Leaderboard
                            scrollbarRef={scrollbarRef}
                            isOpen={state.isOpen}
                            filters={state.filters}
                            onScroll={onScroll}
                            toggleOpen={toggleOpen}
                            findYourSelf={findYourSelf}
                            data={leaderboard}
                            setLeaderboardFilters={setLeaderboardFilters}
                        />

                        <TitleWrapper>
                            <Title variant="h6">{loco.dashboard.badges.title}</Title>
                            <HelpIconWrapper>
                                <Tooltip title={loco.tooltips.badge} withHelpIcon />
                            </HelpIconWrapper>
                        </TitleWrapper>
                        <Achievements
                            badges={badges}
                            variant="horizontal"
                            loading={badgesLoading}
                        />
                    </Left>

                    <Right item xs={12} md={4}>
                        <Aside>
                            <AsideInner>
                                <Typography variant="h6" gutterBottom>
                                    {loco.profile.preview}
                                </Typography>
                                <LevelBox />

                                <Typography variant="h6" style={{ padding: '40px 0 15px 0' }}>
                                    {loco.profile.statistics}
                                </Typography>
                                <Statistics />

                                <Typography variant="h6" style={{ padding: '40px 0 15px 0' }}>
                                    {loco.dashboard.diplom.title}
                                </Typography>
                                <DiplomaBox
                                    testId={finalTestData?.finalTest?.id}
                                    testScore={{
                                        maxPoints: finalTestData?.finalTest?.maxPoints,
                                        acquiredPoints: finalTestData?.finalTest?.acquiredPoints
                                    }}
                                />
                            </AsideInner>
                        </Aside>
                    </Right>
                </Grid>
            </Container>
        </>
    )
}

export default Profile
