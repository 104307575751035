import styled from 'styled-components'

import { Box as SharedBox, Container as BaseContainer } from '../../../../components/shared/styled'

export const Container = styled(BaseContainer)`
    .MuiTableCell-body {
        padding: 8px 16px !important;
    }
`

export const Box = styled(SharedBox)<{ readonly isLoading: boolean }>`
    padding: 0;
    height: 500px;
    margin-bottom: 30px;
    align-items: flex-start;

    ${(props) =>
        props.isLoading &&
        `
            display: flex;
            align-items: center;
            justify-content: center;
    `}
`
