import {
    Pause,
    Close,
    SkipNext,
    VolumeUp,
    VolumeOff,
    MoreVert,
    SkipPrevious,
    PlayArrow as Play
} from '@material-ui/icons'
import ReactPlayer from 'react-player'
import Slider from '@material-ui/core/Slider'
import Popover from '@material-ui/core/Popover'
import MuiContainer from '@material-ui/core/Container'
import React, { useContext, useState, MouseEvent, ChangeEvent } from 'react'

import { truncateText } from '@utils'
import { AuthContext } from '../../context/Auth'
import Tooltip from '../../components/shared/Tooltip'
import { AudioPlayerContext } from '../../context/AudioPlayer'
import {
    More,
    Button,
    Wrapper,
    FileName,
    Controls,
    Download,
    Container,
    MoreButton,
    FileChapter,
    InfoWrapper,
    VolumeWrapper,
    ProgressWrapper
} from './styled'
import Duration from './Duration'

// https://github.com/CookPete/react-player/blob/master/src/demo/App.js
const MyAudioPlayer = () => {
    const {
        file,
        muted,
        player,
        volume,
        played,
        playing,
        duration,
        stop,
        play,
        pause,
        getNext,
        getPrev,
        onProgress,
        togglePlay,
        setDuration,
        playbackRate,
        onSeekChange,
        onSeekMouseUp,
        onVolumeChange,
        onSeekMouseDown,
        setPlaybackRate
    } = useContext(AudioPlayerContext)

    const { user } = useContext(AuthContext)

    // ========== Popover VOLUME ==========>
    const [anchorVolume, setAnchorVolume] = useState<HTMLButtonElement | null>(null)

    const closeVolume = () => setAnchorVolume(null)
    const onMouseEnterVolume = (event: MouseEvent<any>) => setAnchorVolume(event.currentTarget)

    const isVolumeOpen = Boolean(anchorVolume)
    const volumeId = isVolumeOpen ? 'volume-popover' : undefined

    // ========== Popover MORE options ==========>
    const [anchorMore, setAnchorMore] = useState<HTMLButtonElement | null>(null)

    const onMouseEnterMore = (event: MouseEvent<any>) => setAnchorMore(event.currentTarget)
    const closeMore = () => setAnchorMore(null)

    const onPlaybackRateChange = (e: ChangeEvent<{}>) => {
        setPlaybackRate(e)
        closeMore()
    }

    const isMoreOpen = Boolean(anchorMore)
    const moreId = isMoreOpen ? 'more-options-popover' : undefined

    return Boolean(file.src) ? (
        <Wrapper>
            <ReactPlayer
                controls
                pip={false}
                width="100%"
                height="100%"
                ref={player}
                muted={muted}
                volume={volume}
                playing={playing}
                onPlay={play}
                onPause={pause}
                onDuration={setDuration}
                onProgress={onProgress}
                playbackRate={playbackRate}
                style={{ display: 'none' }}
                config={{ file: { attributes: { disablePictureInPicture: true } } }}
                url={file.src.length > 0 && file.src.includes('http') ? file.src : ''}
            />
            <MuiContainer style={{ height: '100%' }}>
                <Container>
                    <InfoWrapper>
                        <Tooltip title={file.name}>
                            <FileName>{truncateText(file.name, 12)}</FileName>
                        </Tooltip>
                        <Tooltip title={file.chapter}>
                            <FileChapter>{truncateText(file.chapter, 14)}</FileChapter>
                        </Tooltip>
                    </InfoWrapper>

                    <Controls>
                        <Button onClick={getPrev} disabled={!file.hasPrev || user?.isTemporary}>
                            <SkipPrevious />
                        </Button>

                        <Button onClick={togglePlay}>
                            {playing && <Pause style={{ margin: 0 }} />}
                            {!playing && <Play style={{ margin: 0 }} />}
                        </Button>

                        <Button onClick={getNext} disabled={!file.hasNext || user?.isTemporary}>
                            <SkipNext />
                        </Button>
                    </Controls>

                    <ProgressWrapper>
                        <Duration seconds={played} />
                        <Slider
                            min={0}
                            max={duration}
                            value={played}
                            onChange={onSeekChange}
                            onMouseDown={onSeekMouseDown}
                            aria-labelledby="input-slider"
                            onChangeCommitted={onSeekMouseUp}
                        />
                        <Duration seconds={duration} />
                    </ProgressWrapper>

                    <Popover
                        disablePortal
                        open={isVolumeOpen}
                        anchorEl={anchorVolume}
                        onClose={closeVolume}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                    >
                        <VolumeWrapper onMouseLeave={closeVolume}>
                            <Slider
                                min={0}
                                max={1}
                                step={0.01}
                                value={volume}
                                orientation="vertical"
                                onChange={onVolumeChange}
                                aria-labelledby="input-slider-vol"
                            />
                        </VolumeWrapper>
                    </Popover>

                    <Button aria-describedby={volumeId} onClick={onMouseEnterVolume}>
                        {volume === 0 || muted ? <VolumeOff /> : <VolumeUp />}
                    </Button>

                    <Popover
                        disablePortal
                        open={isMoreOpen}
                        anchorEl={anchorMore}
                        onClose={closeMore}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                    >
                        <More onMouseLeave={closeMore}>
                            <MoreButton value={1} onClick={onPlaybackRateChange}>
                                speed 1x
                            </MoreButton>
                            <MoreButton value={1.5} onClick={onPlaybackRateChange}>
                                speed 1.5x
                            </MoreButton>
                            <MoreButton value={2} onClick={onPlaybackRateChange}>
                                speed 2x
                            </MoreButton>
                            <Download
                                download
                                target="_blank"
                                href={file.src}
                                rel="noopener noreferrer"
                            >
                                stáhnout
                            </Download>
                        </More>
                    </Popover>

                    <Button onClick={onMouseEnterMore}>
                        <MoreVert aria-describedby={moreId} style={{ margin: 0 }} />
                    </Button>

                    <Button onClick={stop}>
                        <Close />
                    </Button>
                </Container>
            </MuiContainer>
        </Wrapper>
    ) : null
}

export default MyAudioPlayer
