import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import { media, transition } from '@variables'
import { AccessibleButton } from '../../../shared/styled'

export const Title = styled(Typography)`
    width: 100%;
    text-align: left;

    ${media.tablet} {
        text-align: center;
    }
`

export const LoginLink = styled(AccessibleButton)`
    color: #2a3039;
    margin-left: 5px;
    font-size: inherit;
    transition: ${transition.main};

    ${media.tablet} {
        &:hover {
            text-decoration: underline;
        }
    }
`

export const SocialButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    button,
    a {
        :not(:last-child) {
            margin-bottom: 20px;
        }
    }
`

export const Divider = styled.div`
    width: 100%;
    display: flex;
    color: #95989c;
    font-size: 12px;
    flex-basis: 100%;
    align-items: center;
    margin: 20px 0 0 0;
    text-transform: uppercase;

    &::before,
    &::after {
        content: '';
        height: 1px;
        flex-grow: 1;
        font-size: 0px;
        line-height: 0px;
        background: #f2f3f3;
    }

    &::before {
        margin-right: 8px;
    }
    &::after {
        margin-left: 8px;
    }
`

export const BottomButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0 15px 0;

    ${media.tablet} {
        max-width: 340px;
        margin: 10px auto 15px auto;
    }
`

export const CheckboxesWrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${media.tablet} {
        margin: 0 auto;
        max-width: 340px;
    }
`

export const Check = styled.div`
    display: flex;
    font-weight: 300;
    align-items: center;
    flex-direction: row;
`
