import React from 'react'
import { useMedia } from 'react-use'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'

import * as loco from '@loco'
import { UsersWithStatsQuery } from '@graphql'
import { getProfileName, getIcon } from '@utils'

import Close from '../../shared/Close'
import CoinsIcon from '../../SVG/Coins'
import StatsCountryIcon from '../../SVG/StatsCountry'
import StatsDistrictIcon from '../../SVG/StatsDistrict'
import StatsLifestageIcon from '../../SVG/StatsLifestage'
import StatsInvestmentExperienceIcon from '../../SVG/StatsInvestmentExperience'
import { Wrapper, Coins, Iconography, Row, Title, Subtitle, Badges, Badge } from './styled'

type Row = UsersWithStatsQuery['users']['data'][0]

type Props = {
    row?: Row
    isOpen: boolean
    close: () => void
}

const StudentStatisticDialog = ({ row, isOpen, close }: Props) => {
    const isWide = useMedia('(min-width: 854px)')

    const username = getProfileName(row)
    const lifeStage = row?.lifeStage?.name
    const investmentExperience = row?.investmentExperience?.name
    const regionRank = row?.student?.progress.regionRank
    const countryRank = row?.student?.progress.countryRank

    return (
        <Dialog
            open={isOpen}
            onClose={close}
            fullScreen={!isWide}
            aria-labelledby="student-statistics-dialog"
            PaperProps={{
                style: { maxWidth: 740 }
            }}
        >
            <Wrapper>
                <Close onClick={close} />

                <Typography variant="h5" gutterBottom>
                    {username}
                </Typography>
                {(row?.student?.progress.points.reached || 0) > 0 && (
                    <Coins>
                        <CoinsIcon style={{ marginRight: 8 }} />
                        <Typography>
                            {row?.student?.progress.points.reached || 0} /{' '}
                            {row?.student?.progress.points.total || 0}
                        </Typography>
                    </Coins>
                )}

                <Iconography>
                    <Row>
                        <StatsCountryIcon />
                        <div>
                            <Title>{loco.statistics.czech}</Title>
                            <Subtitle>Top {countryRank || 1}%</Subtitle>
                        </div>
                    </Row>
                    <Row>
                        <StatsDistrictIcon />
                        <div>
                            <Title>{loco.statistics.district}</Title>
                            <Subtitle>Top {regionRank || 1}%</Subtitle>
                        </div>
                    </Row>
                    {investmentExperience && (
                        <Row>
                            <StatsInvestmentExperienceIcon />
                            <div>
                                <Title>{loco.statistics_table_investmentExperience}</Title>
                                <Subtitle>{investmentExperience}</Subtitle>
                            </div>
                        </Row>
                    )}
                    {lifeStage && (
                        <Row>
                            <StatsLifestageIcon />
                            <div>
                                <Title>{loco.statistics_table_lifeStage}</Title>
                                <Subtitle>{lifeStage}</Subtitle>
                            </div>
                        </Row>
                    )}
                </Iconography>

                {(row?.student?.badgeBoard.length || 0) > 0 && (
                    <>
                        <Title>{loco.statistics_dialog_badges}</Title>
                        <Badges>
                            {row?.student?.badgeBoard.map((b) => (
                                <Badge key={b.id} title={b.badge.name}>
                                    <img
                                        loading="lazy"
                                        alt={b.badge.name}
                                        src={getIcon(b.badge.icon)}
                                    />
                                </Badge>
                            ))}
                        </Badges>
                    </>
                )}
            </Wrapper>
        </Dialog>
    )
}

export default StudentStatisticDialog
