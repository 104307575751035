/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSetState } from 'react-use'

import { Routes } from '@variables'

type InfoData = {
    icon: any
    title: string
    subtitle: string
    buttonProps: {
        text: string
        onClick: () => any
    }
}

export const DialogContext = createContext({
    id: '',
    infoData: {
        title: '',
        subtitle: '',
        icon: undefined,
        buttonProps: {
            text: '',
            onClick: () => {}
        }
    },
    setOpen: (id: DIALOG_ID) => {},
    setInfoDialogData: (data: InfoData) => {}
})

export enum DIALOG_ID {
    NOT_DISPLAYED = '',
    INFO = 'infoDialog',
    BADGE = 'badgeDialog',
    LOGIN = 'loginDialog',
    INVITE = 'inviteDialog',
    REGISTRATION = 'registrationDialog'
}

type Props = {
    children: any
}

type State = {
    id: DIALOG_ID
    infoData: InfoData
}

const DialogProvider = ({ children }: Props) => {
    const location = useLocation()

    const [state, setState] = useSetState<State>({
        id: DIALOG_ID.NOT_DISPLAYED,
        infoData: {
            title: '',
            subtitle: '',
            icon: undefined,
            buttonProps: {
                text: '',
                onClick: () => {}
            }
        }
    })

    useEffect(() => {
        if (
            location.pathname.includes(Routes.INVITE) &&
            location.search.includes(Routes.TOKEN_SEARCH_PARAM)
        ) {
            setState({ id: DIALOG_ID.INVITE })
        }
        if (
            location.pathname.includes(Routes.NEW_PASSWORD) &&
            location.search.includes(Routes.TOKEN_SEARCH_PARAM)
        ) {
            setState({ id: DIALOG_ID.LOGIN })
        }
    }, [location])

    const setOpen = (id: DIALOG_ID): void => {
        if (id === DIALOG_ID.NOT_DISPLAYED) {
            setInfoDialogData({
                title: '',
                subtitle: '',
                icon: undefined,
                buttonProps: {
                    text: '',
                    onClick: () => {}
                }
            })
        }

        if (id === DIALOG_ID.INFO && location.pathname === Routes.PROFILE_EDIT) return
        setState({ id: id })
    }

    const setInfoDialogData = (data: InfoData) => setState({ infoData: data })

    return (
        <DialogContext.Provider value={{ ...state, setOpen, setInfoDialogData }}>
            {children}
        </DialogContext.Provider>
    )
}

export default DialogProvider
