import styled from 'styled-components'
import MuiContainer from '@material-ui/core/Container'

import { media } from '@variables'

export const Container = styled(MuiContainer)`
    margin-bottom: 130px !important;

    @media (max-width: 855px) {
        margin-bottom: 80px !important;
    }
    @media (max-width: 600px) {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
`

export const Tag = styled.p`
    opacity: 0.5;
    font-size: 18px;
    line-height: 28px;
    margin: 0 0 15px 0;
`

export const VideoWrapper = styled.div`
    padding: 0;

    ${media.tablet} {
        padding: 0 100px;
    }
`

export const Video = styled.div`
    width: 100%;
    height: 200px;
    display: block;
    margin: 0 auto;
    max-width: 770px;
    position: relative;
    background-color: transparent;
    border-radius: 5px;
    overflow: hidden;

    video {
        outline: none;
    }

    ${media.phone} {
        height: 300px;
    }
    ${media.tablet} {
        height: 400px;
    }
    ${media.desktop} {
        height: 470px;
    }
`
