import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                fillRule="evenodd"
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M12 19.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M12 .75V4.5M.75 12H4.5M12 23.25V19.5M23.25 12H19.5"
            ></path>
        </svg>
    )
}

export default Icon
