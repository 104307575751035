import React from 'react'

import { Props } from '.'

function Icon({ color }: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fillRule="evenodd"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M.75 3.75a1.5 1.5 0 011.5-1.5h19.5a1.5 1.5 0 011.5 1.5v18a1.5 1.5 0 01-1.5 1.5H2.25a1.5 1.5 0 01-1.5-1.5v-18z"
                clipRule="evenodd"
            ></path>
            <path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M5.25.75v3M18.75.75v3M12.75 18h4.5M12.75 10.5h4.5M9.9 15.3l-3.15 4.2-2.25-2.25M9.9 7.5l-3.15 4.2L4.5 9.45"
            ></path>
        </svg>
    )
}

export default Icon
