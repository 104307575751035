// @ts-ignore
import {
    Chapter,
    useCreateTestMutation,
    ChaptersWithIdDocument,
    useCreateLectureMutation,
    useChaptersWithoutLecturesQuery
} from '@graphql'
import * as loco from '@loco'
import { getRouteWithId, translateGQLError } from '@utils'
import { Routes } from '@variables'
import { EditorState } from 'draft-js'
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import { Location } from 'history'
import { useSnackbar } from 'notistack'
import React from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useHistory, useLocation } from 'react-router-dom'
import { useBeforeUnload, useSetState, useToggle } from 'react-use'
import { object, string, mixed } from 'yup'
import { PanelVariant } from '../../../../../components/ExpansionPanels/Test'
import Autocomplete from '../../../../../components/Fields/Autocomplete'
import InputField from '../../../../../components/Fields/Input'
import UploadField from '../../../../../components/Fields/Upload'
import WYSIWYG from '../../../../../components/Fields/WYSIWYG'
import Head from '../../../../../components/Head'
import RouteLeavingGuard from '../../../../../components/RouteLeavingGuard'
import { TabType } from '../../../../../components/shared'
import { Container, Label } from '../../../../../components/shared/styled'
import Header from '../components/Header'
import Loader from '../components/Loader'
import { stateToMarkdown } from '../../../../../../common/stateToMarkdown'
import { ButtonWrapper, CustomBox } from '../styled'

type State = {
    tab: TabType
    editorState: EditorState
}

type FormValues = {
    title: string
    description: string
    chapter: Pick<Chapter, 'id' | 'name'> | undefined

    // Files
    audio?: any
    video?: any
    preview?: any
}

const CreateLecture = () => {
    const history = useHistory()
    const location = useLocation()
    const { enqueueSnackbar } = useSnackbar()

    const [dirty, toggleDirty] = useToggle(false)
    // On close or reload page
    useBeforeUnload(dirty, loco.admin.popup.leave.title)

    const [state, setState] = useSetState<State>({
        tab: TabType.LECTURE,
        editorState: EditorState.createEmpty()
    })

    const { tab, editorState } = state

    const handleTab = (newTab: TabType) => setState({ tab: newTab })
    const handleEditorState = (newState: EditorState) => setState({ editorState: newState })

    const [createTest, { loading: createTestLoading }] = useCreateTestMutation({
        refetchQueries: [{ query: ChaptersWithIdDocument }]
    })
    const [createLecture, { loading: createLectureLoading }] = useCreateLectureMutation({
        refetchQueries: [{ query: ChaptersWithIdDocument }]
    })

    const isLoading: boolean = createTestLoading || createLectureLoading

    const { data } = useChaptersWithoutLecturesQuery()

    const handleChange = () => {
        if (!dirty) toggleDirty()
    }

    const submitTest = async (lectureId: string) => {
        const { data, errors } = await createTest({
            variables: {
                lecture: {
                    id: lectureId
                }
            }
        })

        if (data && !errors) {
            history.push({
                pathname: getRouteWithId(Routes.LECTURE_EDIT, lectureId),
                search: Routes.TEST_SEARCH_PARAM
            })
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }
    }

    const submit = async (values: FormValues) => {
        const { title, description, chapter } = values
        const transcript = stateToMarkdown(editorState.getCurrentContent())

        if (!chapter?.id) {
            enqueueSnackbar('Kapitola nenalezená', { variant: 'error' })
            return
        }

        const { data, errors } = await createLecture({
            variables: {
                data: {
                    name: title,
                    summary: '',
                    transcript: transcript,
                    description: description,
                    chapter: { id: chapter.id },
                    videoPreview: {
                        gcsId: values.preview?.gcsId
                    },
                    video: {
                        gcsId: values.video?.gcsId
                    },
                    audio: {
                        gcsId: values.audio?.gcsId
                    }
                }
            }
        })

        if (!errors && data) {
            toggleDirty()
            await submitTest(data.createLecture.id)
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }
    }

    return (
        <>
            <Head title={loco.seo.lecture.create.title} />

            <Container>
                <RouteLeavingGuard
                    when={dirty}
                    title={loco.admin.popup.leave.title}
                    btnText={loco.admin.popup.leave.confirm}
                    description={loco.admin.popup.leave.subtitle}
                    navigate={(path: string) => history.push(path)}
                    shouldBlockNavigation={(newLocation: Location<any>) => {
                        if (dirty) {
                            if (location.pathname !== newLocation.pathname) {
                                return true
                            }
                        }
                        return false
                    }}
                />

                <Formik
                    onSubmit={submit}
                    enableReinitialize
                    isInitialValid={false}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {(props: FormikProps<FormValues>) => {
                        const { values, isValid, isSubmitting } = props
                        const { title, description } = values

                        return (
                            <Form
                                style={{ width: '100%' }}
                                spellCheck={false}
                                onChange={handleChange}
                            >
                                <Header
                                    tab={tab}
                                    isSameAuthor
                                    isValid={isValid}
                                    handleTab={handleTab}
                                    variant={PanelVariant.CREATE}
                                    isSubmitting={isSubmitting || isLoading}
                                />

                                <CustomBox>
                                    {(isLoading || isSubmitting) && <Loader />}

                                    <Field
                                        fullWidth
                                        key="title"
                                        name="title"
                                        value={title}
                                        component={InputField}
                                        className="customInput"
                                        disabled={isSubmitting || isLoading}
                                        label={loco.admin.create.lecture.name.label}
                                        placeholder={loco.admin.create.lecture.name.placeholder}
                                    />

                                    <Field key="chapter" name="chapter" style={{ width: '100%' }}>
                                        {({ field, form, meta }: FieldProps) => (
                                            <Autocomplete
                                                meta={meta}
                                                form={form}
                                                field={field}
                                                autoComplete
                                                className="customInput"
                                                options={data?.chapters || []}
                                                disabled={isSubmitting || isLoading}
                                                getOptionLabel={(option) => option.name || ''}
                                                textFieldProps={{
                                                    fullWidth: true,
                                                    label: loco.admin.create.lecture.chapter
                                                }}
                                            />
                                        )}
                                    </Field>

                                    <Field
                                        fullWidth
                                        multiline
                                        key="description"
                                        name="description"
                                        value={description}
                                        component={InputField}
                                        className="customInput"
                                        disabled={isSubmitting || isLoading}
                                        label={loco.admin.create.lecture.description.label}
                                        placeholder={
                                            loco.admin.create.lecture.description.placeholder
                                        }
                                    />

                                    <Label>{loco.admin.create.lecture.transcription}</Label>
                                    {editorState && (
                                        <WYSIWYG
                                            editorState={editorState}
                                            setState={(newEditorState: EditorState) => {
                                                handleChange()
                                                handleEditorState(newEditorState)
                                            }}
                                        />
                                    )}

                                    <ButtonWrapper>
                                        <UploadField
                                            name="video"
                                            buttonText={loco.admin.create.chapter.upload}
                                            label={loco.admin.create.lecture.video}
                                            accept="video/mp4"
                                            previewType="none"
                                            disabled={isSubmitting || isLoading}
                                            style={{ width: '49%' }}
                                        />

                                        <UploadField
                                            name="audio"
                                            buttonText={loco.admin.create.chapter.upload}
                                            label={loco.admin.create.lecture.audio}
                                            accept=".mp3"
                                            previewType="none"
                                            disabled={isSubmitting || isLoading}
                                            style={{ width: '49%' }}
                                        />
                                    </ButtonWrapper>

                                    <UploadField
                                        name="preview"
                                        accept="image/*"
                                        previewType="img"
                                        buttonText={loco.admin.create.chapter.upload}
                                        label={loco.admin.create.lecture.icon}
                                        style={{ width: '100%' }}
                                        disabled={isSubmitting || isLoading}
                                    />
                                </CustomBox>
                            </Form>
                        )
                    }}
                </Formik>
            </Container>
        </>
    )
}

const initialValues: FormValues = {
    title: '',
    description: '',
    video: {
        src: undefined,
        filename: undefined
    },
    audio: {
        src: undefined,
        filename: undefined
    },
    chapter: undefined,
    preview: {
        src: undefined,
        filename: undefined
    }
}

const validationSchema = object({
    title: string().required(loco.validation.common.required),
    description: string().required(loco.validation.common.required),
    audio: mixed()
        .test('fileSize', `${loco.validation.fileLimit} 5MB`, (value) => {
            if (value) return value.size <= 5000000
            else return true
        })
        .required(loco.validation.common.required),
    video: mixed()
        .test('fileSize', `${loco.validation.fileLimit} 50MB`, (value) => {
            if (value) return value.size <= 50000000
            else return true
        })
        .required(loco.validation.common.required),
    preview: mixed()
        .test('fileSize', `${loco.validation.fileLimit} 0.5MB`, (value) => {
            if (value) return value.size <= 500000
            else return true
        })
        .required(loco.validation.common.required)
})

export default CreateLecture
