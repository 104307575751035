import styled from 'styled-components'

import { colors, media } from '@variables'

export const Wrapper = styled.div`
    width: 100%;
    color: white;
    height: 340px;
    display: flex;
    border-radius: 6px;
    position: relative;
    text-align: center;
    align-items: center;
    flex-direction: column;
    background-color: ${colors.primary.darkBlue};

    padding: 40px 0 0 0;
    margin-bottom: 30px;

    svg {
        transition: all ease-out 0.5s;
        position: absolute;
        z-index: 1;
        bottom: 0;
        transform: translateX(-50%);
        left: 50%;
    }

    ${media.custom(580)} {
        text-align: left;
        flex-direction: row;
        padding: 0 40px;
        height: 180px;

        svg {
            transform: initial;
            left: initial;
            right: 40px;
        }
    }
    ${media.tablet} {
        padding: 0 80px;

        svg {
            right: 80px;
        }
    }
`

export const Title = styled.p`
    margin: 0;
    font-size: 16px;
    font-weight: 500;
`

export const Subtitle = styled.p`
    font-size: 14px;
    margin: 10px 0;
`

export const TextWrapper = styled.div`
    width: 100%;
    display: flex;
    max-width: 220px;
    flex-direction: column;
    z-index: 2;
    position: relative;

    a {
        font-weight: 500;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: ${colors.primary.orange};
    }
`
