import { useSetState } from 'react-use'
import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import { useLocation, useHistory } from 'react-router-dom'
import { DateRange } from '@material-ui/pickers/DateRangePicker/RangeTypes'

import * as loco from '@loco'
import { Routes } from '@variables'
import { translateGQLError } from '@utils'
import Head from '../../../../components/Head'
import { Container, Header, Tab, Top, Title, TopItem } from './styled'
import Dialog from '../../../../components/Dialogs/StudentStatistic'
import UsersTable from '../../../../components/Tables/Statistics/Users'
import DiplomasTable from '../../../../components/Tables/Statistics/Diplomas'
import UsersCountIcon from '../../../../components/SVG/UsersCount'
import DiplomasCountIcon from '../../../../components/SVG/CertificatesCount'
import {
    DiplomasOrderByColumnInput,
    useUsersWithStatsQuery,
    UsersWithStatsQuery,
    UsersOrderByColumnInput,
    OrderByDirectionInput,
    useDiplomasQuery,
    useUserStatsQuery,
    Role
} from '@graphql'

type User = UsersWithStatsQuery['users']['data'][0]

const enum TableTitle {
    STUDENTS = 'Studenti',
    DIPLOMAS = 'Certifikáty',
    ADMINS = 'Administratoři'
}

type State = {
    title?: TableTitle
    isOpen: boolean
    itemsPerPage: number
    users: {
        page: number
        search: string
        orderBy: {
            column: UsersOrderByColumnInput
            direction: OrderByDirectionInput
        }
    }
    diplomas: {
        page: number
        search: string
        orderBy: {
            column: DiplomasOrderByColumnInput
            direction: OrderByDirectionInput
        }
    }
}

const initialState = Object.freeze({
    isOpen: false,
    itemsPerPage: 10,
    title: TableTitle.STUDENTS,
    users: {
        search: '',
        page: 0,
        orderBy: {
            direction: OrderByDirectionInput.asc,
            column: UsersOrderByColumnInput.username
        }
    },
    diplomas: {
        page: 0,
        search: '',
        orderBy: {
            direction: OrderByDirectionInput.asc,
            column: DiplomasOrderByColumnInput.createdAt
        }
    }
})

const initValues = {
    page: 0,
    search: ''
}

// TODO: "Datum registrace" and date range picker for certificates
const Statistics = () => {
    const history = useHistory()
    const { search } = useLocation()

    const [user, setUser] = useState<User | undefined>(undefined)
    const [selectedDate, setSelectedDate] = useState<DateRange<Date | null>>([null, null])
    const [state, setState] = useSetState<State>(initialState)

    const close = () => {
        setState({ isOpen: false })
        setUser(undefined)
    }

    const { data, loading } = useUsersWithStatsQuery({
        skip: state.title === TableTitle.DIPLOMAS,
        fetchPolicy: 'no-cache',
        variables: {
            page: state.users.page,
            itemsPerPage: state.itemsPerPage,
            filter: {
                role: state.title === TableTitle.STUDENTS ? Role.STUDENT : Role.ADMIN,
                search: state.users.search,
                createdFrom: selectedDate[0],
                createdUntil: selectedDate[1]
            },
            orderBy: {
                column: state.users.orderBy.column,
                direction: state.users.orderBy.direction
            }
        },
        onError: (err) => console.error(translateGQLError(err.message))
    })

    const { data: diplomasData, loading: diplomasLoading } = useDiplomasQuery({
        skip: state.title !== TableTitle.DIPLOMAS,
        variables: {
            page: state.diplomas.page,
            itemsPerPage: state.itemsPerPage,
            filter: {
                search: state.diplomas.search,
                createdFrom: selectedDate[0],
                createdUntil: selectedDate[1]
            },
            orderBy: {
                column: state.diplomas.orderBy.column,
                direction: state.diplomas.orderBy.direction
            }
        },
        onError: (err) => console.error(translateGQLError(err.message))
    })

    const { data: statsData } = useUserStatsQuery()

    useEffect(() => {
        if (search.includes(Routes.DIPLOMAS_SEARCH_PARAM)) {
            setState({ title: TableTitle.DIPLOMAS })
            return
        }
        if (search.includes(Routes.ADMINS_SEARCH_PARAM)) {
            setState({ title: TableTitle.ADMINS })
            return
        }

        setState({ title: TableTitle.STUDENTS })
        return
    }, [search])

    const setOpen = (is: boolean) => setState({ isOpen: is })

    const onChangePage = (page: number) => {
        if (state.title === TableTitle.STUDENTS || state.title === TableTitle.ADMINS) {
            setState({
                users: {
                    ...state.users,
                    page: page
                }
            })
        }
        if (state.title === TableTitle.DIPLOMAS) {
            setState({
                diplomas: {
                    ...state.diplomas,
                    page: page
                }
            })
        }
    }

    const onChangeItemsPerPage = (newItemsPerPage: number) => {
        setState({ itemsPerPage: newItemsPerPage })
    }

    {
        /**
         * Bug: pageSize dont change if new props in option appear
         *
         * https://github.com/mbrn/material-table/issues/1644
         */
    }
    const onSearchChange = (searchQuery: string) => {
        if (state.title === TableTitle.STUDENTS || state.title === TableTitle.ADMINS) {
            setState({
                users: {
                    ...state.users,
                    page: 0,
                    search: searchQuery,
                    orderBy: initialState.users.orderBy
                }
            })
        }
        if (state.title === TableTitle.DIPLOMAS) {
            setState({
                diplomas: {
                    ...state.diplomas,
                    page: 0,
                    search: searchQuery,
                    orderBy: initialState.diplomas.orderBy
                }
            })
        }
    }

    const setTitle = (newTitle: TableTitle, isOpen?: boolean) => (e: React.ChangeEvent<{}>) => {
        setState({
            title: newTitle,
            diplomas: {
                ...initValues,
                orderBy: initialState.diplomas.orderBy
            },
            users: {
                ...initValues,
                orderBy: initialState.users.orderBy
            }
        })

        setOpen(false)
        setUser(undefined)
        setSelectedDate([null, null])

        if (newTitle === TableTitle.DIPLOMAS) {
            history.push({ search: Routes.DIPLOMAS_SEARCH_PARAM })
        }
        if (newTitle === TableTitle.STUDENTS) {
            history.push({ search: Routes.STUDENTS_SEARCH_PARAM })
        }
        if (newTitle === TableTitle.ADMINS) {
            history.push({ search: Routes.ADMINS_SEARCH_PARAM })
        }
    }

    // https://github.com/mbrn/material-table/issues/871#issuecomment-515026168
    const handleSortOrderChange = (
        orderedColumnId: number,
        orderDirection: OrderByDirectionInput
    ) => {
        if (state.title === TableTitle.STUDENTS || state.title === TableTitle.ADMINS) {
            if (orderedColumnId > 1) return

            const column = UsersOrderByColumnInput.username
            // if (orderedColumnId === 1) column = UsersOrderByColumnInput.points

            setState({
                users: {
                    ...state.users,
                    orderBy: {
                        column: column,
                        direction: orderDirection
                    }
                }
            })
        }
        if (state.title === TableTitle.DIPLOMAS) {
            if (orderedColumnId !== 1) return
            const column = DiplomasOrderByColumnInput.createdAt

            setState({
                diplomas: {
                    ...state.diplomas,
                    orderBy: {
                        column: column,
                        direction: orderDirection
                    }
                }
            })
        }
    }

    const users = data?.users.data || []
    const diplomas = diplomasData?.diplomas.data || []

    return (
        <>
            <Head title={`${loco.seo.statistics.classes.students.title}`} />

            <Dialog isOpen={state.isOpen} row={user} close={close} />

            <Container>
                <Title>{loco.navigation.statistics}</Title>
                <Top>
                    <TopItem>
                        <UsersCountIcon />
                        <Typography>{loco['users_count']}</Typography>
                        <Typography variant="h3">{statsData?.userStats.userCount || 0}</Typography>
                    </TopItem>
                    <TopItem>
                        <DiplomasCountIcon />
                        <Typography>{loco['diplomas_count']}</Typography>
                        <Typography variant="h3">
                            {statsData?.userStats.diplomaCount || 0}
                        </Typography>
                    </TopItem>
                </Top>

                <Header>
                    <Tab
                        type="button"
                        onClick={setTitle(TableTitle.STUDENTS)}
                        active={state.title === TableTitle.STUDENTS}
                    >
                        {TableTitle.STUDENTS}
                    </Tab>
                    <Tab
                        type="button"
                        onClick={setTitle(TableTitle.DIPLOMAS)}
                        active={state.title === TableTitle.DIPLOMAS}
                    >
                        {TableTitle.DIPLOMAS}
                    </Tab>
                    <Tab
                        type="button"
                        onClick={setTitle(TableTitle.ADMINS)}
                        active={state.title === TableTitle.ADMINS}
                    >
                        {TableTitle.ADMINS}
                    </Tab>
                </Header>

                {(state.title === TableTitle.STUDENTS || state.title === TableTitle.ADMINS) && (
                    <UsersTable
                        data={users}
                        setOpen={setOpen}
                        loading={loading}
                        sortedColumnId={0}
                        title={state.title}
                        page={state.users.page}
                        onSearch={onSearchChange}
                        onChangePage={onChangePage}
                        selectedDate={selectedDate}
                        pageSize={state.itemsPerPage}
                        setRow={(data) => setUser(data)}
                        totalCount={data?.users.meta.items || 0}
                        onChangeItemsPerPage={onChangeItemsPerPage}
                        handleSortOrderChange={handleSortOrderChange}
                        handleDateChange={(date) => setSelectedDate(date)}
                        sortedColumnDirection={state.users.orderBy.direction}
                    />
                )}
                {state.title === TableTitle.DIPLOMAS && (
                    <DiplomasTable
                        data={diplomas}
                        title={state.title}
                        sortedColumnId={1}
                        onSearch={onSearchChange}
                        loading={diplomasLoading}
                        page={state.diplomas.page}
                        onChangePage={onChangePage}
                        selectedDate={selectedDate}
                        pageSize={state.itemsPerPage}
                        onChangeItemsPerPage={onChangeItemsPerPage}
                        handleSortOrderChange={handleSortOrderChange}
                        totalCount={diplomasData?.diplomas.meta.items || 0}
                        sortedColumnDirection={state.diplomas.orderBy.direction}
                        handleDateChange={(date) => setSelectedDate(date)}
                    />
                )}
            </Container>
        </>
    )
}

export default Statistics
