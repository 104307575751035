import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="24"
            fill="none"
            viewBox="0 0 26 24"
            {...props}
        >
            <path
                fill="#F55"
                fillRule="evenodd"
                stroke="#F55"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M11.73 1.357a1.63 1.63 0 012.54 0l1.512 1.881c.348.434.893.66 1.446.6l2.4-.26a1.63 1.63 0 011.8 1.8l-.261 2.4c-.06.552.166 1.097.6 1.445l1.881 1.512a1.63 1.63 0 010 2.54l-1.887 1.505c-.434.349-.66.894-.6 1.447l.261 2.4a1.629 1.629 0 01-1.8 1.8l-2.4-.26a1.628 1.628 0 00-1.446.6L14.27 22.64a1.63 1.63 0 01-2.54 0l-1.511-1.88a1.631 1.631 0 00-1.447-.6l-2.4.261a1.628 1.628 0 01-1.8-1.8l.261-2.4a1.63 1.63 0 00-.6-1.447l-1.88-1.51a1.63 1.63 0 010-2.54l1.88-1.513c.434-.348.66-.893.6-1.446l-.261-2.4a1.629 1.629 0 011.8-1.8l2.4.261a1.632 1.632 0 001.447-.6l1.511-1.869z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M10 14.999l6-6M10 8.999l6 6"
            ></path>
        </svg>
    )
}

export default Icon
