import 'date-fns'
import { Location } from 'history'
import { useSnackbar } from 'notistack'
import { Link } from 'react-router-dom'
import { string, object, date } from 'yup'
import { zonedTimeToUtc } from 'date-fns-tz'
import Button from '@material-ui/core/Button'
import { DatePicker } from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField'
import { useToggle, useBeforeUnload } from 'react-use'
import React, { useState, useContext, ChangeEvent } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { setHours, setMinutes, getMinutes, getHours, isDate } from 'date-fns'
import { Formik, Field, FieldProps, FormikProps, FormikHelpers } from 'formik'

import * as loco from '@loco'
import { translateGQLError } from '@utils'
import Head from '../../../../../components/Head'
import { ButtonWrapper, SelectWrapper } from '../styled'
import Loader from '../../../../../components/shared/Loader'
import Select from '../../../../../components/Fields/Select'
import InputField from '../../../../../components/Fields/Input'
import { AuthContext } from '../../../../../context/Auth'
import BackButton from '../../../../../components/shared/BackButton'
import { Routes, notificationMsgs, timeZone } from '@variables'
import RouteLeavingGuard from '../../../../../components/RouteLeavingGuard'
import { Hours, Repeat, Minutes, mapByRepeat, mapByNotificationSchedule } from '..'
import { SmallContainer, Container, Form, Box } from '../../../../../components/shared/styled'
import {
    useUpdateCustomNotificationMutation,
    useCustomNotificationQuery,
    CustomNotificationQuery,
    Role
} from '@graphql'

type Notification = CustomNotificationQuery['customNotification']

type FormValues = {
    date: Date
    title: string
    hours: string
    repeat: Repeat
    minutes: string
    body?: string | null
}

const EditNotification = () => {
    const { user } = useContext(AuthContext)
    const { enqueueSnackbar } = useSnackbar()

    const history = useHistory()
    const location = useLocation()
    const { id } = useParams<{ readonly id: string }>()

    const [dirty, toggleDirty] = useToggle(false)
    // On close or reload
    useBeforeUnload(dirty, loco.admin.popup.leave.title)

    const [selectedDate, setSelectedDate] = useState<Date>()

    const [updateNotification, { loading }] = useUpdateCustomNotificationMutation()

    const { data, error, loading: queryLoading } = useCustomNotificationQuery({
        onCompleted: async (data) => {
            const date: Date = new Date(data.customNotification?.runAt)
            isDate(date) && setSelectedDate(date)
        },
        variables: {
            where: {
                id: id
            }
        }
    })

    if (queryLoading) return <Loader halfHeight />
    if (!data || !data.customNotification || error) {
        error && enqueueSnackbar(translateGQLError(error.message), { variant: 'error' })
        return null
    }

    const notification: Notification = data.customNotification

    const onChange = () => {
        if (!dirty) toggleDirty()
    }

    const onDateChange = (date: Date | null) => {
        if (!date) return

        onChange()
        setSelectedDate(date)
    }

    const submit = async (values: FormValues, { resetForm }: FormikHelpers<any>) => {
        if (!selectedDate) return

        const date: Date = setMinutes(
            setHours(selectedDate, Number(values.hours)),
            Number(values.minutes)
        )

        const { data, errors } = await updateNotification({
            variables: {
                data: {
                    body: values.body?.length || 0 > 0 ? values.body : undefined,
                    runAt: zonedTimeToUtc(date, timeZone),
                    repeat: mapByRepeat(values.repeat),
                    title: values.title
                },
                where: {
                    id: id
                }
            }
        })

        if (data && !errors) {
            toggleDirty()
            resetForm()
            enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
            history.push({
                pathname: Routes.DASHBOARD,
                search: Routes.NOTIFICATIONS_SEARCH_PARAM
            })
        } else {
            errors?.forEach((err) => err.message, { variant: 'error' })
        }
    }

    const isSameAuthor = notification.author.id === user?.id || user?.role === Role.SUPER_ADMIN

    return (
        <>
            <Head title={loco.seo.notifications.edit.title} />

            <RouteLeavingGuard
                when={dirty}
                title={loco.admin.popup.leave.title}
                btnText={loco.admin.popup.leave.confirm}
                description={loco.admin.popup.leave.subtitle}
                navigate={(path: string) => history.push(path)}
                shouldBlockNavigation={(newLocation: Location<any>) => {
                    if (dirty) {
                        if (location.pathname !== newLocation.pathname) {
                            return true
                        }
                    }
                    return false
                }}
            />

            <Container>
                <SmallContainer>
                    <BackButton
                        position="relative"
                        onClick={() => history.push(Routes.DASHBOARD)}
                        style={{
                            fontSize: 18,
                            fontWeight: 400,
                            margin: '40px 0 20px 0'
                        }}
                    >
                        {loco.admin.edit.notification.title.name}
                    </BackButton>

                    <Box>
                        <Formik
                            onSubmit={submit}
                            enableReinitialize
                            isInitialValid={false}
                            validationSchema={validationSchema}
                            initialValues={{
                                repeat: mapByNotificationSchedule(notification.repeat),
                                minutes: `${getMinutes(new Date(notification.runAt))}`,
                                hours: `${getHours(new Date(notification.runAt))}`,
                                date: new Date(notification.runAt),
                                body: notification?.body || '',
                                title: notification.title
                            }}
                        >
                            {({ errors, isSubmitting }: FormikProps<FormValues>) => {
                                const isValid = !Object.keys(errors).length
                                return (
                                    <Form onChange={onChange} spellCheck={false}>
                                        <Field
                                            fullWidth
                                            key="title"
                                            name="title"
                                            component={InputField}
                                            className="customInput"
                                            disabled={isSubmitting || loading || !isSameAuthor}
                                            label={loco.admin.create.notification.title.label}
                                            placeholder={
                                                loco.admin.create.notification.title.placeholder
                                            }
                                        />
                                        <Field
                                            fullWidth
                                            key="body"
                                            name="body"
                                            component={InputField}
                                            className="customInput"
                                            disabled={isSubmitting || loading || !isSameAuthor}
                                            label={loco.admin.create.notification.text.label}
                                            placeholder={
                                                loco.admin.create.notification.text.placeholder
                                            }
                                        />

                                        <SelectWrapper>
                                            <DatePicker
                                                disablePast
                                                allowKeyboardControl
                                                showToolbar={false}
                                                inputFormat="d MMM yyyy"
                                                value={selectedDate}
                                                onChange={onDateChange}
                                                label={loco.admin.create.notification.date}
                                                disabled={isSubmitting || loading || !isSameAuthor}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        name="date"
                                                        helperText=""
                                                        style={{
                                                            width: '185px !important',
                                                            marginRight: 25
                                                        }}
                                                    />
                                                )}
                                            />

                                            <Field
                                                fullWidth
                                                key="hours"
                                                name="hours"
                                                className="customInput"
                                            >
                                                {({ field }: FieldProps) => (
                                                    <Select
                                                        disabled={
                                                            isSubmitting || loading || !isSameAuthor
                                                        }
                                                        style={{ width: 100, marginRight: 25 }}
                                                        label={loco.admin.create.notification.hours}
                                                        options={[
                                                            ...Object.keys(Hours).map(
                                                                (i) => Hours[i]
                                                            )
                                                        ]}
                                                        {...field}
                                                        onChange={(e: ChangeEvent<{}>) => {
                                                            onChange()
                                                            field.onChange(e)
                                                        }}
                                                    />
                                                )}
                                            </Field>

                                            <Field
                                                fullWidth
                                                key="minutes"
                                                name="minutes"
                                                className="customInput"
                                            >
                                                {({ field }: FieldProps) => (
                                                    <Select
                                                        disabled={
                                                            isSubmitting || loading || !isSameAuthor
                                                        }
                                                        style={{ width: 100, marginRight: 25 }}
                                                        label={
                                                            loco.admin.create.notification.minutes
                                                        }
                                                        options={[
                                                            ...Object.keys(Minutes).map(
                                                                (i) => Minutes[i]
                                                            )
                                                        ]}
                                                        {...field}
                                                        onChange={(e: ChangeEvent<{}>) => {
                                                            onChange()
                                                            field.onChange(e)
                                                        }}
                                                    />
                                                )}
                                            </Field>

                                            <Field
                                                fullWidth
                                                key="repeat"
                                                name="repeat"
                                                className="customInput"
                                            >
                                                {({ field }: FieldProps) => (
                                                    <Select
                                                        style={{ width: 185 }}
                                                        disabled={
                                                            isSubmitting || loading || !isSameAuthor
                                                        }
                                                        label={
                                                            loco.admin.create.notification.repeat
                                                                .repeat
                                                        }
                                                        options={[
                                                            ...Object.keys(Repeat).map(
                                                                (k) => Repeat[k]
                                                            )
                                                        ]}
                                                        {...field}
                                                        onChange={(e: ChangeEvent<{}>) => {
                                                            onChange()
                                                            field.onChange(e)
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </SelectWrapper>

                                        <ButtonWrapper>
                                            <Link
                                                to={`${Routes.DASHBOARD}?${Routes.NOTIFICATIONS_SEARCH_PARAM}`}
                                            >
                                                <Button
                                                    size="large"
                                                    type="button"
                                                    color="secondary"
                                                    variant="outlined"
                                                    disabled={isSubmitting || loading}
                                                >
                                                    {loco.common.cancel}
                                                </Button>
                                            </Link>
                                            <Button
                                                size="large"
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                                style={{ marginLeft: 30 }}
                                                disabled={
                                                    !isValid ||
                                                    isSubmitting ||
                                                    loading ||
                                                    !isSameAuthor ||
                                                    !dirty
                                                }
                                            >
                                                {user?.role === Role.SUPER_ADMIN
                                                    ? loco.common.save
                                                    : loco.common.sendToReview}
                                            </Button>
                                        </ButtonWrapper>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Box>
                </SmallContainer>
            </Container>
        </>
    )
}

const validationSchema = object({
    body: string().nullable(),
    date: date().required(loco.validation.common.required),
    title: string().required(loco.validation.common.required),
    hours: string().required(loco.validation.common.required),
    repeat: string().required(loco.validation.common.required),
    minutes: string().required(loco.validation.common.required)
})

export default EditNotification
