import axios from 'axios'
import { useSetState } from 'react-use'
import { useState, useEffect } from 'react'
import { useCreateResumableUploadMutation, useGetSignedUrlMutation } from '@graphql'

type Result = {
    id: string
    src: string
}

type ReturnType = [
    (file: File) => void,
    {
        progress: number
        result?: Result
        // Cancel upload
        cancel: () => Promise<void>
        reset: () => void
    }
]

export type Options = {
    chunkSize?: number
    prefix?: undefined | 'media/' | 'avatars/' | 'badges/' | 'documents/'
    onComplete?: (result: { data: Result; file: File }) => void
}

export const useUpload = (options: Options): ReturnType => {
    const [url, setUrl] = useState<string>()
    const [file, setFile] = useState<File>()
    const [result, setResult] = useState<Result>()
    const [progress, setProgress] = useState<number>(0)

    const [getSignedUrl] = useGetSignedUrlMutation()
    const [createResumableUpload] = useCreateResumableUploadMutation()

    const chunkSize = options?.chunkSize || 256 * 1024 * 5

    // Run upload
    useEffect(() => {
        if (file) {
            setProgress(1)
            if (chunkSize < file.size) {
                uploadFile(file)
            } else {
                singleUpload(file)
            }
        }
    }, [file, url])

    const reset = () => {
        setProgress(0)
        setResult(undefined)
        setUrl(undefined)
        setFile(undefined)
    }

    const singleUpload = async (file: File) => {
        if (!url) {
            await getResumableUrl()
        }
        if (!url) {
            return
        }
        const result = await axios.put(url, file, {
            headers: {
                'Content-Type': file.type
            }
        })
        if (result.status === 200) {
            await setUploadResult(result)
        }
    }

    const setUploadResult = async (result: any) => {
        if (!file) {
            return
        }
        setProgress(100)
        const gcsId = result.data?.name
        const { data } = await getSignedUrl({
            variables: {
                gcsId
            }
        })

        if (data?.getSignedUrl) {
            const uploadResult = {
                id: gcsId,
                src: data?.getSignedUrl
            }
            setResult(uploadResult)
            options.onComplete?.({ data: uploadResult, file })
        }

        setProgress(0)
    }

    const getResumableUrl = async () => {
        const { data, errors } = await createResumableUpload({
            variables: {
                data: {
                    prefix: options.prefix,
                    type: file?.type,
                    filename: file?.name
                }
            }
        })

        if (data?.createResumableUpload) {
            setUrl(data?.createResumableUpload)
        } else {
            console.error(errors)
        }

        return
    }

    const cancel = async () => {
        try {
            if (url) {
                await axios.delete(url)
                setProgress(0)
            }
            return
        } catch (error) {
            return error
        }
    }

    const uploadFile = async (file: File, args?: { from?: number; to?: number }) => {
        try {
            if (!url) {
                await getResumableUrl()
            }
            if (!url) {
                return
            }
            // Chunk ranges
            const from = args?.from || 0
            const to = args?.to || chunkSize

            // Send data chunk to GCS
            const chunk = file.slice(from, to + 1, file.type)

            const result = await axios.put(url, chunk, {
                headers: {
                    'Content-Type': file.type,
                    'Content-Range': `bytes ${from}-${to}/${file.size}`
                },
                validateStatus: (status) => status === 308 || status === 200
            })

            // Send another chunk
            if (result.status === 308) {
                const topRange = result.headers?.range?.split('-')?.[1]
                if (topRange) {
                    const topRangeInt = parseInt(topRange, 10)
                    const toRange =
                        topRangeInt + chunkSize >= file.size
                            ? file.size - 1
                            : topRangeInt + chunkSize

                    setProgress(Math.round((topRangeInt / file.size) * 100))
                    uploadFile(file, { from: topRangeInt, to: toRange })
                }
            }
            // Upload completed
            if (result.status === 200) {
                setUploadResult(result)
            }
        } catch (error) {
            console.log({ error })
        }
    }

    return [setFile, { progress, result, cancel, reset }]
}
