import styled from 'styled-components'

import { Title1 } from '../styled'
import { colors, media } from '@variables'

export const Title = styled(Title1)``

export const Wrapper = styled.div`
    width: 100%;
    padding-bottom: 70px;
    background-color: white;
    color: ${colors.primary.darkBlue};

    ${Title} {
        margin: 40px 0;

        ${media.tablet} {
            margin: 0 0 60px 0;
        }
    }
`
