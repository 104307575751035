import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="247"
            height="173"
            fill="none"
            viewBox="0 0 247 173"
            {...props}
        >
            <mask id="mask0" width="247" height="173" x="0" y="0" maskUnits="userSpaceOnUse">
                <path fill="#C4C4C4" d="M0 0H247V172.123H0z"></path>
            </mask>
            <g mask="url(#mask0)">
                <g opacity="0.3">
                    <path
                        fill="#fff"
                        d="M240.302 169.336H6.328v.711h233.974v-.711zM240.302 140.907H6.328v.712h233.974v-.712z"
                        opacity="0.3"
                    ></path>
                    <path
                        fill="#fff"
                        d="M240.302 169.336H6.328v.711h233.974v-.711zM240.302 112.444H6.328v.712h233.974v-.712zM240.302 83.982H6.328v.712h233.974v-.712zM240.303 55.52H6.328v.711h233.975v-.712z"
                        opacity="0.3"
                    ></path>
                </g>
                <path
                    fill="#fff"
                    d="M215.393 148.328H31.203v3.321h184.19v-3.321zM215.291 151.682h-4.914v75.087h4.914v-75.087zM189.298 151.682h-4.914v75.087h4.914v-75.087z"
                ></path>
                <path
                    fill="#fff"
                    d="M189.298 150.7h-4.914v7.047h4.914V150.7zM215.291 150.7h-4.914v15.925h4.914V150.7zM36.252 151.682h-4.914v75.087h4.914v-75.087zM62.245 151.682h-4.914v75.087h4.914v-75.087z"
                ></path>
                <path
                    fill="#fff"
                    d="M62.245 150.7h-4.914v7.047h4.914V150.7zM36.252 150.7h-4.914v7.047h4.914V150.7z"
                ></path>
                <path
                    fill="#F5BE6D"
                    d="M162.576 43.82l-5.668 29.653 2.397.458 5.668-29.654-2.397-.458zM101.967 43.952l-2.435.166 2.026 29.682 2.435-.166-2.026-29.682z"
                ></path>
                <path fill="#9C9C9C" d="M179.098 70.327H67.533v66.785h111.565V70.327z"></path>
                <path
                    fill="#9C9C9C"
                    d="M180.08 138.095H66.55V69.344h113.53v68.751zm-111.565-1.966h109.6v-64.82h-109.6v64.82z"
                ></path>
                <path
                    fill="#F5BE6D"
                    d="M53.535 96.348c-1.592 0-3.083-.745-4.202-2.1-1.457-1.729-1.762-3.762-.915-5.625.475-1.085 1.56-2.508 3.83-3.83-5.999-5.929-15.657-13.383-15.759-13.451l1.491-1.932c.475.373 10.574 8.166 16.606 14.266 3.355-1.39 8.473-2.474 16.132-2.779l.101 2.44c-5.964.237-10.777.982-14.403 2.27.034.034.102.136.136.17 2.745 3.15 2.406 5.76 1.965 7.047a5.26 5.26 0 01-4.067 3.422c-.305.068-.61.102-.915.102zm.509-9.724c-1.729.88-2.88 1.897-3.355 2.981-.475 1.017-.271 2.067.576 3.05 1.017 1.254 2.169 1.254 2.779 1.118a2.793 2.793 0 002.169-1.83c.474-1.321-.034-2.981-1.491-4.608-.204-.203-.441-.44-.678-.711zM126.161 78.662H75.293v2.44h50.868v-2.44zM171.336 78.662h-34.161v2.44h34.161v-2.44zM101.625 85.71H75.293v2.44h26.332v-2.44zM171.336 85.71H112.64v2.44h58.696v-2.44zM126.161 92.792H75.293v2.44h50.868v-2.44zM171.302 92.792h-38.736v2.44h38.736v-2.44zM126.161 99.874H75.293v2.44h50.868v-2.44zM171.336 99.874h-34.161v2.44h34.161v-2.44zM126.161 106.922H75.293v2.44h50.868v-2.44zM171.336 106.922h-34.161v2.44h34.161v-2.44zM93.966 113.969H75.293v2.44h18.673v-2.44zM171.303 113.969h-66.39v2.44h66.39v-2.44zM114.232 121.051h-38.94v2.44h38.94v-2.44zM171.303 121.051h-46.09v2.44h46.09v-2.44zM126.161 128.133H75.293v2.44h50.868v-2.44zM171.336 128.133h-34.161v2.44h34.161v-2.44z"
                ></path>
                <path
                    fill="#49775F"
                    d="M126.263 150.869l-35.313-1.118c-5.727-.17-10.506 4.303-10.675 10.03-.034.44 5.897 59.195 5.897 59.195l14.437-.339 1.016-44.828 10.71 4.98 13.928-27.92z"
                ></path>
                <path
                    fill="#FFF0E2"
                    d="M81.935 141.924a8.654 8.654 0 009.862 7.217c.204-.034.373-.068.542-.102l.983-.237c4.61-1.016 7.49-5.557 6.507-10.165a8.556 8.556 0 00-8.574-6.709l-1.017.034c-4.744.135-8.54 4.1-8.404 8.843 0 .407.067.746.101 1.119zM170.083 139.043c-.746 4.71-5.118 7.929-9.828 7.218-.204-.034-.373-.068-.577-.102l-.982-.237c-4.575-1.051-7.49-5.625-6.439-10.199.915-3.931 4.473-6.743 8.506-6.641l1.017.033c4.744.136 8.54 4.1 8.404 8.844 0 .373-.068.746-.101 1.084z"
                ></path>
                <path
                    fill="#49775F"
                    d="M165.779 144.228l-1.051 13.418-14.267-.915 1.83-20.94h5.693a7.845 7.845 0 017.862 7.861c-.067.169-.067.372-.067.576z"
                ></path>
                <path
                    fill="#49775F"
                    d="M121.281 150.869l35.313-1.118c5.728-.17 10.506 4.303 10.675 10.03.034.44-5.896 59.195-5.896 59.195l-14.437-.339-1.017-44.828-14.234 3.998-10.404-26.938z"
                ></path>
                <path
                    fill="url(#paint0_linear)"
                    d="M103.252 157.375c-3.322 9.081 1.084 21.652 11.387 21.381 7.93-.204 8.506.101 11.827.033 10.608-.271 17.623-11.215 14.336-21.313l-37.55-.101z"
                ></path>
                <path
                    fill="#6B9C83"
                    d="M159.78 146.938l4.914 10.674c2.101 4.574.068 9.928-4.473 12.029-.068.034-.136.034-.204.068a9.064 9.064 0 01-10.607-2.643l-14.098-17.485 2.914-28.394 6.677 5.794a59.507 59.507 0 0114.877 19.957zM85.29 146.938l-4.914 10.674c-2.1 4.574-.067 9.928 4.474 12.029.068.034.136.034.203.068a9.064 9.064 0 0010.608-2.643l14.098-17.485-2.915-28.394-6.642 5.828a58.689 58.689 0 00-14.912 19.923z"
                ></path>
                <path
                    fill="#FFF0E2"
                    d="M113.592 97.16a5.117 5.117 0 10-10.103-1.637 5.117 5.117 0 0010.103 1.637zM135.515 101.907a5.117 5.117 0 10.001-10.235 5.117 5.117 0 00-.001 10.235z"
                ></path>
                <path
                    fill="#2B2B2B"
                    d="M127.72 125.862l8.846-5.624s-3.593-2.338-9.727-2.27l.881 7.894z"
                ></path>
                <path
                    fill="#FFF0E2"
                    d="M114.266 119.56l.644-20.67 14.471-.236 1.186 20.669c.271 4.303-3.016 8.03-7.32 8.302-.17 0-.305.033-.475.033h-.61a7.845 7.845 0 01-7.862-7.861c-.034-.067-.034-.135-.034-.237z"
                ></path>
                <path
                    fill="#000046"
                    d="M122.129 113.19c-6.338-.136-11.591-5.015-12.201-11.351l-.982-11.08c.135-7.489 6.337-13.486 13.86-13.35 7.49.135 13.489 6.336 13.353 13.858l-1.423 11.046a12.434 12.434 0 01-12.607 10.877z"
                ></path>
                <path
                    fill="#6B9C83"
                    d="M140.835 157.476a16.632 16.632 0 01-.881-5.286c0-3.049 7.32-7.691 7.117-16.908-.169-7.082-7.862-17.518-20.198-17.281l-9.964.475c-10.845.203-19.385 9.724-19.825 17.247h-.034c0 .237.034.406.034.644v.203c.677 12.537 9.455 11.927 6.202 20.839l37.549.067z"
                ></path>
                <path
                    fill="#2B2B2B"
                    d="M162.592 153.41c-.135 0-.305-.101-.338-.237-3.22-8.573-9.185-16.806-9.252-16.908-.136-.169-.068-.407.067-.508.17-.136.407-.068.509.068.034.067 6.066 8.403 9.353 17.077a.366.366 0 01-.237.474c-.034.034-.068.034-.102.034zM140.599 156.562c-.102 0-.238-.034-.305-.135l-3.627-4.474c-.135-.135-.101-.406.034-.508.136-.136.407-.102.509.034l3.626 4.473c.135.136.102.407-.034.509-.034.067-.102.101-.203.101zM103.252 157.747h-.034c-.204-.033-.339-.237-.305-.406l1.626-10.674c.034-.203.238-.339.407-.305.204.034.339.238.305.407l-1.627 10.673a.367.367 0 01-.372.305z"
                ></path>
                <path
                    fill="#fff"
                    d="M154.222 170.623h.034c2.982.238 5.219 2.88 4.982 5.862l-.237 2.88c-.238 2.982-2.881 5.219-5.863 4.981h-.034c-2.983-.237-5.219-2.88-4.982-5.862l.237-2.88c.237-3.015 2.847-5.218 5.863-4.981zM92.95 184.38h-.035c-2.982.136-5.557-2.202-5.625-5.218l-.136-2.88c-.135-2.982 2.203-5.557 5.22-5.625h.033c2.983-.135 5.558 2.203 5.626 5.218l.135 2.88c.136 2.982-2.202 5.49-5.219 5.625z"
                ></path>
                <path
                    fill="#fff"
                    d="M141.107 202.034h-35.584c-6.202 0-11.353-4.812-11.794-11.012l-1.864-27.413c-.44-6.505 4.474-12.13 10.98-12.571.271-.033.543-.033.814-.033h39.312c6.507 0 11.827 5.285 11.827 11.825 0 .271 0 .542-.034.813l-1.864 27.412c-.44 6.167-5.591 10.979-11.793 10.979z"
                ></path>
                <path
                    fill="#F5BE6D"
                    d="M28.085 107.159h17.792c4.846 0 8.743 3.93 8.743 8.742v4.1c0 4.845-3.93 8.742-8.743 8.742H28.05c-4.846 0-8.744-3.931-8.744-8.742v-4.1c0-4.812 3.931-8.742 8.778-8.742zM91.865 25.735h17.826c4.846 0 8.744 3.931 8.744 8.742v4.1c0 4.846-3.932 8.743-8.744 8.743H91.865c-4.846 0-8.744-3.931-8.744-8.742V34.51c-.033-4.845 3.898-8.776 8.744-8.776zM17.782 55.723h17.826c4.846 0 8.744 3.93 8.744 8.742v4.1c0 4.845-3.932 8.742-8.744 8.742H17.782c-4.846 0-8.744-3.93-8.744-8.742v-4.1c.034-4.812 3.932-8.742 8.744-8.742zM202.753 111.123h17.825c4.847 0 8.744 3.931 8.744 8.742v4.1c0 4.845-3.931 8.742-8.744 8.742h-17.825c-4.847 0-8.744-3.93-8.744-8.742v-4.066c.034-4.879 3.931-8.776 8.744-8.776zM156.866 25.735h17.826c4.846 0 8.743 3.931 8.743 8.742v4.1c0 4.846-3.931 8.743-8.743 8.743h-17.826c-4.847 0-8.744-3.931-8.744-8.742V34.51c0-4.845 3.931-8.776 8.744-8.776z"
                ></path>
                <path
                    stroke="#000046"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.118"
                    d="M160.99 31.983h8.844"
                ></path>
                <path
                    stroke="#000046"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.118"
                    d="M163.49 29.47h3.844a.42.42 0 01.296.122l2.081 2.08a.42.42 0 01-.014.607l-4.003 3.639a.42.42 0 01-.564 0l-4.003-3.639a.418.418 0 01-.015-.607l2.081-2.08a.423.423 0 01.297-.123z"
                    clipRule="evenodd"
                ></path>
                <path
                    stroke="#000046"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.118"
                    d="M163.326 29.503l2.038 6.521M167.497 29.503l-2.038 6.521M160.801 42.04l-1.125-1.406c-.357-.446-.551-1-.551-1.57v-2.89c0-.463.375-.838.838-.838v0c.463 0 .838.375.838.838v2.095"
                ></path>
                <path
                    stroke="#000046"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.118"
                    d="M163.316 41.964v-1.676a2.508 2.508 0 00-.423-1.397l-.638-.953a.781.781 0 00-1.105-.248v0a.782.782 0 00-.266 1l.756 1.257M170.022 42.04l1.125-1.406c.357-.446.551-1 .551-1.57v-2.89a.838.838 0 00-.838-.838v0a.838.838 0 00-.838.838v2.095"
                ></path>
                <path
                    stroke="#000046"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.118"
                    d="M167.507 41.964v-1.676c0-.497.147-.983.423-1.397l.638-.953a.786.786 0 011.105-.248v0a.782.782 0 01.266 1l-.755 1.257"
                ></path>
                <path
                    fill="#F5BE6D"
                    d="M194.415 94.113c-.305 0-.61-.034-.915-.067-1.864-.306-3.423-1.627-4.067-3.423-.44-1.287-.813-3.863 1.966-7.048.034-.034.102-.135.135-.17-3.592-1.287-8.472-2.032-14.403-2.27l.102-2.439c7.659.305 12.776 1.39 16.131 2.779 6.033-6.133 16.132-13.893 16.606-14.266l1.492 1.932c-.136.101-9.761 7.522-15.759 13.452 2.27 1.321 3.321 2.744 3.829 3.829.848 1.897.509 3.896-.915 5.624-1.084 1.322-2.609 2.067-4.202 2.067zm-.474-9.725c-.238.272-.475.509-.678.746-1.424 1.626-1.966 3.287-1.491 4.608.339.949 1.186 1.627 2.169 1.83.61.102 1.796.102 2.779-1.118.847-1.017 1.016-2.033.576-3.05-.475-1.084-1.627-2.1-3.355-3.016z"
                ></path>
                <path
                    fill="#F5BE6D"
                    d="M213.495 55.079h17.826c4.846 0 8.743 3.93 8.743 8.742v4.1c0 4.845-3.931 8.742-8.743 8.742h-17.826c-4.846 0-8.744-3.93-8.744-8.742v-4.1c-.034-4.846 3.898-8.742 8.744-8.742zM198.076 121.051H177.2v2.44h20.876v-2.44zM69.972 121.051H49.096v2.44h20.876v-2.44z"
                ></path>
                <path
                    fill="#F5BE6D"
                    d="M194.415 94.113c-.305 0-.61-.034-.915-.067-1.864-.306-3.423-1.627-4.067-3.423-.44-1.287-.813-3.863 1.966-7.048.034-.034.102-.135.135-.17-3.592-1.287-8.472-2.032-14.403-2.27l.102-2.439c7.659.305 12.776 1.39 16.131 2.779 6.033-6.133 16.132-13.893 16.606-14.266l1.492 1.932c-.136.101-9.761 7.522-15.759 13.452 2.27 1.321 3.321 2.744 3.829 3.829.848 1.897.509 3.896-.915 5.624-1.084 1.322-2.609 2.067-4.202 2.067zm-.474-9.725c-.238.272-.475.509-.678.746-1.424 1.626-1.966 3.287-1.491 4.608.339.949 1.186 1.627 2.169 1.83.61.102 1.796.102 2.779-1.118.847-1.017 1.016-2.033.576-3.05-.475-1.084-1.627-2.1-3.355-3.016z"
                ></path>
                <path
                    fill="#fff"
                    d="M145.716 169.675h-44.802a.376.376 0 01-.373-.373c0-.203.169-.373.373-.373h44.836c.203 0 .372.17.372.373 0 .203-.203.373-.406.373z"
                ></path>
            </g>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.118"
                d="M22.772 70.54c0-.463.375-.839.838-.839h5.03c.463 0 .838.376.838.839v.838a.838.838 0 01-.838.838h-5.03a.838.838 0 01-.838-.838v-.838zM23.61 68.025c0-.463.376-.838.838-.838h5.03c.463 0 .838.376.838.838v.838a.838.838 0 01-.838.838h-5.03a.838.838 0 01-.838-.838v-.838z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.118"
                d="M21.096 65.511c0-.463.375-.838.838-.838h5.03c.462 0 .838.375.838.838v.838a.838.838 0 01-.839.838h-5.03a.838.838 0 01-.837-.838v-.838zM21.934 62.997c0-.463.375-.838.838-.838h5.03c.462 0 .838.375.838.838v.838a.838.838 0 01-.838.838h-5.03a.838.838 0 01-.838-.838v-.838z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.118"
                d="M31.154 63.416v1.676M30.316 64.254h1.677M20.257 59.644v1.677M19.42 60.482h1.676M96.397 35.13a.21.21 0 110 .42.21.21 0 010-.42M104.78 39.32a.21.21 0 11-.001.42.21.21 0 01.001-.42"
            ></path>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.118"
                d="M94.302 34.921c0-.926.75-1.676 1.676-1.676h9.221c.926 0 1.676.75 1.676 1.676v5.028c0 .926-.75 1.676-1.676 1.676h-9.22c-.927 0-1.677-.75-1.677-1.676v-5.028z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.118"
                d="M100.588 39.111a1.677 1.677 0 100-3.353 1.677 1.677 0 000 3.353z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.118"
                d="M95.14 31.568h10.897M96.816 29.892h7.545M228.279 68.862v-2.514h-2.514"
            ></path>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.118"
                d="M228.279 66.348l-2.822 2.823a1.258 1.258 0 01-1.25.315l-3.192-.853a1.258 1.258 0 00-1.214.325l-3.257 3.259M225.682 64.666a4.19 4.19 0 10-6.623 2.522"
            ></path>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.118"
                d="M220.735 65.51v-3.352h1.258a.838.838 0 110 1.676.838.838 0 110 1.676h-1.258z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.118"
                d="M220.735 63.834h1.258M221.574 62.158v-.839M221.574 65.51v.838M36.603 115.657h-1.136a.75.75 0 00-.28 1.446l1.154.461a.75.75 0 01-.28 1.445h-1.134M35.765 115.657v-.419M35.765 119.429v-.419"
            ></path>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.118"
                d="M41.633 117.333a2.514 2.514 0 00-1.677-2.37v-.982a.838.838 0 00-.838-.838h-6.706a.838.838 0 00-.838.838v.982a2.514 2.514 0 000 4.741v.982a.837.837 0 00.838.838h6.706a.838.838 0 00.838-.838v-.982a2.514 2.514 0 001.677-2.371zM209.419 118.87c1.852 0 3.353-.751 3.353-1.676 0-.926-1.501-1.677-3.353-1.677-1.851 0-3.353.751-3.353 1.677 0 .925 1.502 1.676 3.353 1.676z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.118"
                d="M212.772 118.87c0 .925-1.501 1.676-3.353 1.676-1.852 0-3.353-.751-3.353-1.676M212.772 120.546v-3.353"
            ></path>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.118"
                d="M206.066 117.193v8.381c0 .926 1.501 1.677 3.353 1.677a6.32 6.32 0 001.257-.125"
            ></path>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.118"
                d="M206.066 123.898c0 .926 1.501 1.676 3.353 1.676.422.001.843-.041 1.257-.124M206.066 122.222c0 .926 1.501 1.676 3.353 1.676.422.001.843-.041 1.257-.124M206.066 120.546c0 .926 1.501 1.676 3.353 1.676.422.001.843-.041 1.257-.125"
            ></path>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.118"
                d="M215.287 124.736c1.851 0 3.353-.75 3.353-1.676 0-.926-1.502-1.676-3.353-1.676-1.852 0-3.353.75-3.353 1.676 0 .926 1.501 1.676 3.353 1.676z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.118"
                d="M211.934 123.06v3.353c0 .925 1.501 1.676 3.353 1.676 1.852 0 3.353-.751 3.353-1.676v-3.353"
            ></path>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.118"
                d="M218.64 124.736c0 .926-1.501 1.677-3.353 1.677-1.852 0-3.353-.751-3.353-1.677"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="121.995"
                    x2="119.483"
                    y1="157.915"
                    y2="303.285"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9774AC"></stop>
                    <stop offset="0.4" stopColor="#47A6D3"></stop>
                    <stop offset="0.5" stopColor="#A7A7AE"></stop>
                    <stop offset="0.59" stopColor="#E89AC0"></stop>
                    <stop offset="1" stopColor="#BFE7D1"></stop>
                </linearGradient>
            </defs>
        </svg>
    )
}

export default Icon
