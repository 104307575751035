import styled from 'styled-components'

import { StartButton as SB } from '../../../shared/styled'
import { media, colors, transition } from '@variables'

export const StartButton = styled(SB)``

export const ImgWrapper = styled.div`
    width: 100%;
    height: 130px;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    transition: ${transition.main};

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: ${transition.main};
    }
`

export const ContentWrapper = styled.div`
    padding: 15px 15px 5px 15px;
`

export const CoinsWrapper = styled.div`
    display: flex;
    align-items: center;

    span {
        font-weight: 500;
        font-size: 14px;
    }
`

export const Wrapper = styled.div<{ readonly blurred: boolean; readonly isBlocked: boolean }>`
    height: 250px;
    max-width: 100%;
    cursor: pointer;
    border-radius: 5px;
    position: relative;
    background: #fafafa;
    border: 1px solid #ebebeb;
    pointer-events: ${(props) => (props.blurred || props.isBlocked ? 'none' : 'inherit')};

    ${media.tablet} {
        ${(props) =>
            !props.blurred &&
            `
            &:hover ${ImgWrapper} {
                img {
                    transform: scale(1.1);
                }
            }
            :hover {
                ${StartButton} {
                    color: white;
                    background-color: ${colors.primary.orange};
                }
            }
        `}
    }

    ${(props) =>
        props.isBlocked &&
        `
        ${ImgWrapper}, ${ContentWrapper} {
            opacity: 0.2;
        }
    `}
`

export const CompleteIconWrapper = styled.div`
    width: 26px;
    height: 26px;
    margin-left: 5px;
`

export const Title = styled.div`
    margin: 0;
    height: 45px;
    display: flex;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;

    h6 {
        font-size: 16px;
        line-height: 24px;
    }
`

export const ContentBottom = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    span {
        padding-left: 10px;
        color: ${colors.primary.darkBlue};
    }
`
