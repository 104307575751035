import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import MuiContainer from '@material-ui/core/Container'

import { Title3 } from '../styled'
import { colors, media, transition } from '@variables'
import RhombusImg from '@static/images/rhombus.svg'
import BkgImg from '@static/images/LP-header-right.png'
import MobileBkgImg from '@static/images/LP-header-right-mobile.png'

export const Title = styled(Typography)``
export const Subtitle = styled(Typography)``
export const DownloadTitle = styled(Title3)``
export const Container = styled(MuiContainer)``

export const Satellite = styled.img`
    position: absolute;
    bottom: -460px;
    left: initial;
    width: 70px;
    height: 70px;
    right: 20px;

    ${media.custom(420)} {
        bottom: -520px;
    }

    ${media.desktop} {
        width: 113px;
        height: 113px;
        bottom: -230px;
        right: initial;
        left: 40px;
    }
    ${media.custom(1350)} {
        left: -50px;
    }
`

export const Header = styled.header`
    width: 100%;
    color: white;
    background-color: white;
    padding: 50px 0 440px 0;
    color: ${colors.primary.darkBlue};

    background-repeat: no-repeat;
    background-image: url(${MobileBkgImg});

    background-size: 340px auto;
    background-position: calc(100% + 40px) 90%;

    ${media.custom(420)} {
        padding: 50px 0 500px 0;

        background-size: 409px auto;
        background-position: center 90%;
    }

    ${media.desktop} {
        padding: 200px 0;
        background-size: 720px auto;
        background-image: url(${BkgImg});
        background-position: calc(100% + 320px) calc(100% - 200px);
    }
    ${media.xl} {
        background-size: 1045px auto;
        background-position: calc(100% + 420px) calc(100% - 140px);
    }
    ${media.custom(1650)} {
        background-position: calc(100% + 200px) calc(100% - 140px);
    }
    ${media.custom(2200)} {
        background-position: calc(100%) calc(100% - 140px);
    }

    ${Container} {
        position: relative;
    }

    ${Title} {
        line-height: 120%;
        letter-spacing: 1px;
        margin-bottom: 25px;
        text-align: center;
        font-weight: 700;
        position: relative;

        ${media.desktop} {
            :after {
                content: '';
                width: 55px;
                height: 55px;
                background-size: 55px auto;
                display: block;
                position: absolute;
                top: -130px;
                right: 130px;
                background-repeat: no-repeat;
                background-image: url(${RhombusImg});
            }

            max-width: 640px;
            text-align: left;
            font-size: 64px;
        }
    }

    ${Subtitle} {
        width: 100%;
        margin: 0 auto;
        font-size: 16px;
        max-width: 470px;
        text-align: center;

        ${media.desktop} {
            margin: 0;
            text-align: left;
            font-size: 20px;
            line-height: 36px;
        }
    }

    ${DownloadTitle} {
        text-align: center;
        margin: 25px 0 15px 0;

        ${media.desktop} {
            text-align: left;
            margin: 100px 0 15px 0;
        }
    }
`

export const MarketWrapper = styled.div`
    display: flex;
    justify-content: center;

    ${media.desktop} {
        justify-content: flex-start;
    }
`

export const MarketLink = styled.a`
    display: flex;
    height: 46px;

    :last-of-type {
        margin-left: 15px;
    }

    ${media.tablet} {
        :hover {
            img {
                transform: scale(1.1);
            }
        }
    }

    ${media.desktop} {
        height: 58px;
    }
`

export const MarketImg = styled.img`
    width: 100%;
    height: auto;
    transition: ${transition.main};
`
