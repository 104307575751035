import styled from 'styled-components'
import { KeyboardArrowDownRounded } from '@material-ui/icons'

import { media } from '@variables'
import { transition } from '@variables'

export const Wrapper = styled.div<{ readonly open: boolean }>`
    width: 100%;
    overflow: hidden;
    margin-bottom: 60px;
    height: ${(props) => (props.open ? '100%' : '86px')};

    code {
        white-space: break-spaces;
    }
    li {
        :not(:last-of-type) {
            padding-bottom: 10px;
        }
    }
`

export const BottomArrow = styled(KeyboardArrowDownRounded)<{ readonly open: boolean }>`
    top: 0;
    position: relative;
    transition: ${transition.main};

    ${(props) => (props.open ? `transform: rotate(180deg)` : 'transform: inherit')};
`

export const Arrow = styled.div`
    position: absolute;
    bottom: -60px;
    width: 100%;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;

    ${media.tablet} {
        &:hover ${BottomArrow} {
            top: 5px;
        }
    }
`
