import styled from 'styled-components'

import { colors } from '@variables'
import { CreationStatus } from '@graphql'

type Props = {
    readonly child: boolean
    readonly status: CreationStatus
}

export const StatusCircle = styled.div<Props>`
    width: 12px;
    height: 12px;
    display: block;
    min-width: 12px;
    min-height: 12px;
    margin-right: 25px;
    border-radius: 12px;
    background-color: ${(props) =>
        (props.status === CreationStatus.APPROVED && colors.primary.green) ||
        (props.status === CreationStatus.REJECTED && colors.primary.orange) ||
        colors.primary.orange};
    margin-left: ${(props) => (props.child ? '25px' : '0px')};
`

export const Row = styled.div`
    display: flex;
    min-width: 180px;
    align-items: center;
    flex-direction: row;
    position: relative;
    font-weight: 300;
`
