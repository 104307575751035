import { Link } from 'react-router-dom'
import React, { useContext } from 'react'

import * as loco from '@loco'
import { Routes } from '@variables'
import { AuthContext } from '../../context/Auth'
import Illustration from '../SVG/Questionnaire'
import { Wrapper, TextWrapper, Title, Subtitle } from './styled'

const Questionnaire = () => {
    const { user } = useContext(AuthContext)

    const isHidden =
        Boolean(user?.district?.id) &&
        Boolean(user?.lifeStage?.name) &&
        Boolean(user?.investmentExperience?.name)

    if (isHidden) {
        return null
    }

    return (
        <Wrapper>
            <TextWrapper>
                <Title>{loco.questionnaire_banner_title}</Title>
                <Subtitle>{loco.questionnaire_banner_subtitle}</Subtitle>
                <Link to={Routes.QUESTIONNAIRE_EXPERIENCE}>{loco.questionnaire_banner_link}</Link>
            </TextWrapper>

            <Illustration />
        </Wrapper>
    )
}

export default Questionnaire
