import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            fill="none"
            viewBox="0 0 48 48"
            {...props}
        >
            <circle cx="24" cy="24" r="24" fill="#F59100" opacity="0.1"></circle>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M25.312 24.625h-1.694a1.118 1.118 0 00-.417 2.156l1.72.688a1.118 1.118 0 01-.417 2.156h-1.692M24.062 24.625V24M24.062 30.25v-.625M19.687 20.875h8.75M27.025 18.197l1.198-2.397a.625.625 0 00-.838-.838l-1.583.79a.625.625 0 01-.8-.213l-.419-.636a.625.625 0 00-1.04 0l-.424.636a.624.624 0 01-.799.213l-1.58-.79a.626.626 0 00-.84.838l1.175 2.35"
            ></path>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M20.03 22.75l-2.3 2.667a4.943 4.943 0 003.918 7.957h4.825a4.943 4.943 0 003.916-7.957l-2.3-2.667"
            ></path>
        </svg>
    )
}

export default Icon
