import React from 'react'
import Typography from '@material-ui/core/Typography'

import * as loco from '@loco'
import { colors } from '@variables'
import Head from '../../../components/Head'
import { Header, Title, Main } from './styled'

const TermsPage = () => (
    <>
        <Head title={loco.seo.terms.title} />

        <section>
            <Header>
                <Title variant="h1" align="center">
                    {loco.termsPage.title}
                </Title>
            </Header>
            <Main>
                <Typography variant="h3" align="left">
                    Všeobecná ustanovení
                </Typography>
                <Typography align="left">
                    1. Poskytovatelem aplikace Investo je Patria Finance, a. s., se sídlem Výmolova
                    353/3,150 00 Praha 5, 150 00, IČO 26455064, zapsaná v obchodním rejstříku
                    vedeném Městským soudem v Praze, oddíl B, vložka 7215 (dále jen „Patria
                    Finance“) vydává tyto Podmínky používání aplikace Investo od Patria Finance
                    (dále jen „Podmínky“).
                </Typography>
                <Typography align="left">
                    2. Aplikace Investo od Patria Finance (dále jen „Aplikace“) je mobilní a webovou
                    aplikací, která umožňuje registrovaným Uživatelům:
                    <ol style={{ listStyle: 'none' }}>
                        <li>
                            a. Poskytnout pomocí vzdělávacího obsahu Uživateli základní přehled v
                            oblasti investic,
                        </li>
                        <li>
                            b. Zobrazit vzdělávací obsah Aplikace, a to konkrétně přehrát vzdělávací
                            video a vzdělávací audio, nebo si přečíst vzdělávací text,
                        </li>
                        <li>
                            c. Ověřit si svůj stav znalostí prostřednictvím ověřovacích testů, a to:
                        </li>
                        <li>
                            <ul>
                                <li>
                                    i. po každé lekci (jednotlivém vzdělávacím videu, audiu nebo
                                    textu)
                                </li>
                                <li>
                                    ii. po každé kapitole (sekci vzdělávacích videí, audií či textů)
                                </li>
                                <li>
                                    iii. při splnění konkrétních podmínek prostřednictvím
                                    závěrečného testu (ze všech vzdělávacích videí, audií nebo
                                    textů),
                                </li>
                            </ul>
                        </li>
                        <li>
                            d. Získat odznaky za dosažené výsledky v Aplikaci. Aplikace odměňuje
                            uživatele odznakem při splnění určitých podmínek např. pokud Uživatel
                            absolvuje test na 100 %, tzn. zodpoví všechny otázky správně apod.,
                        </li>
                        <li>
                            e. Sbírat body neboli „patriony“ v průběhu vzdělávání, a to na základě
                            skóre v testech a otázce dne (za každou správnou odpověď Uživatel získá
                            10 patrionů). S vyšším počtem patrionů Uživatel postupuje v platformě do
                            vyšších úrovní. Patriony také slouží jako ukazatel obecného skóre, podle
                            kterého Uživatel může sledovat ve statistikách svoje konkrétní skóre,
                            ale i skupin (např. krajů), do kterých uživatel patří,
                        </li>
                        <li>
                            f. Za splnění podmínek získat certifikát. Certifikát získá Uživatel po
                            úspěšném absolvování závěrečného testu, který se zpřístupní Uživateli ve
                            chvíli, kdy získá 80 % patrionů ze všech lekcí a kapitol dostupných v
                            Aplikaci. Za úspěšné absolvování testu se považuje, pokud Uživatel
                            zodpoví alespoň 80% otázek správně,
                        </li>
                        <li>
                            g. Měnit či doplnit v profilu některé údaje (změnit lze např. heslo),
                        </li>
                        <li>
                            h. Bez registrace do Aplikace nahlédnout na jednu lekci jedné kapitoly a
                            absolvovat test dané lekce.
                        </li>
                    </ol>
                </Typography>
                <Typography align="left">
                    3. Samotné používání Aplikace je bezplatné a Poskytovatel si nenárokuje žádné
                    poplatky spojené s využíváním Aplikace.
                </Typography>
                <Typography variant="h3" align="left">
                    Podmínky používání Aplikace
                </Typography>
                <Typography align="left">
                    4. Aplikaci si může aktivovat Uživatel starší 18 let. S ohledem na výlučně
                    vzdělávací charakter Aplikace není využívání Aplikace Uživatelem žádným způsobem
                    podmíněno souhlasem další osoby.
                </Typography>
                <Typography align="left">
                    5. Technickým předpokladem používání Aplikace je mobilní zařízení s operačním
                    systémem Android verze 7.0 nebo vyšším nebo s operačním systémem iOS 12 nebo
                    vyšším nebo Zařízení (PC, notebook, tablet apod.) s přístupem na internet (s
                    běžnou verzí prohlížečů Google Chrome, Safari, Firefox, Microsoft Edge).
                </Typography>
                <Typography align="left">
                    6. Technickým předpokladem používání notifikací Aplikace jsou povolené push
                    notifikace v nastavení mobilního zařízení.
                </Typography>
                <Typography align="left">
                    7. Využívání této Aplikace není podmíněno využíváním jakéhokoliv produktu či
                    služby poskytovatele.
                </Typography>
                <Typography variant="h3" align="left">
                    Instalace a přístup do Aplikace s registrací a bez registrace
                </Typography>
                <Typography align="left">
                    8. Mobilní aplikace:
                    <ol style={{ listStyle: 'none' }}>
                        <li>
                            a. Uživatel je oprávněn instalovat Aplikaci výhradně z obchodu Obchod
                            Play nebo App Store.
                        </li>
                        <li>
                            b. Při opakované instalaci Aplikace v dalším, ale i v původním mobilním
                            zařízení, nepřichází uživatel o žádná aplikační data (Aplikace běží v
                            cloudu společnosti Google Ireland Limited, Gordon House, Barrow Street,
                            Dublin 4, Ireland, VAT number: IE 6388047V).
                        </li>
                        <li>
                            c. Uživatel může Aplikaci využívat ve více mobilních zařízeních či
                            zařízení. Na jednom mobilním zařízení či zařízení může být nainstalovaná
                            jen jedna Aplikace.
                        </li>
                    </ol>
                </Typography>
                <Typography align="left">
                    9. Mobilní i webová aplikace funguje na mobilním zařízení, pro tablety a
                    desktopové zařízení lze využít výhradně webovou aplikaci.
                </Typography>
                <Typography align="left">
                    10. Uživatel má plný přístup do Aplikace po registraci nebo omezený přístup bez
                    registrace (omezený přístup je definován výše bod 2 h.). Bez registrace je možné
                    využít mobilní zařízení i webovou aplikaci.
                </Typography>
                <Typography variant="h3" align="left">
                    Registrace a přihlášení do Aplikace
                </Typography>
                <Typography align="left">
                    11. Uživatel má možnost registrovat se do Aplikace prostřednictvím:
                    <ol style={{ listStyle: 'none' }}>
                        <li>a. Zadáním své e-mailové adresy a hesla nebo,</li>
                        <li>b. svého Google nebo Facebook účtu nebo Apple účtu.</li>
                    </ol>
                </Typography>
                <Typography align="left">
                    12. Heslo musí mít minimálně 8 znaků, dále musí obsahovat alespoň jedno velké
                    písmeno a minimálně jedno malé písmeno a jedno číslo.
                </Typography>
                <Typography align="left">
                    13. Konkrétní e-mailovou adresu nemůže využít pro registraci vícero uživatelů.
                </Typography>
                <Typography align="left">
                    14. Registrace a přihlášení do webové aplikace je Uživateli umožněno
                    prostřednictvím dedikované přihlašovací stránky investo.patria.cz. Webová
                    aplikace běží na samostatné doméně 3. řádu a je ve správě Poskytovatele.
                </Typography>
                <Typography align="left">
                    15. Před samotným spuštěním aplikace bude Uživatel v registračním procesu vyzván
                    ke zvolení profilového obrázku. V případě přihlášení přes sociální sítě (Google,
                    Facebook, Apple ID) se nastaví profilový obrázek shodný s daným profilem na
                    sociální síti.
                </Typography>
                <Typography align="left">
                    16. V procesu registrace a přihlášení Aplikace používá službu reCaptcha, která
                    předchází nabourání do systému.
                </Typography>
                <Typography variant="h3" align="left">
                    Zapomenuté heslo
                </Typography>
                <Typography align="left">
                    17. V případě, že Uživatel zapomene své přístupové heslo do Aplikace, má možnost
                    jej obnovit. V případě, že je k účtu Uživatele vedený e-mail, má Uživatel
                    možnost kliknutím na příslušné tlačítko „Obnovit heslo“ požádat o odeslání
                    e-mailu s odkazem pro změnu hesla.
                </Typography>
                <Typography variant="h3" align="left">
                    Odhlášení z účtu
                </Typography>
                <Typography align="left">
                    18. Uživatel se odhlásí z účtu z mobilní či webové aplikace kliknutím na
                    tlačítko „Odhlásit se“.
                </Typography>
                <Typography variant="h3" align="left">
                    Deaktivace uživatelského účtu
                </Typography>
                <Typography align="left">
                    19. Uživatel má možnost svůj profil deaktivovat tlačítkem „Deaktivace účtu“ v
                    nastavení svého profilu. Uživatel má možnost svůj účet obnovit do 30 dní
                    opětovným přihlášením. Po uplynutí 30 dnů od data provedení deaktivace je
                    uživatelský profil neaktivní a Uživatel ho již nemůže použít. Společnost Google
                    po deaktivaci následně uchovává data po dobu 180 dnů, ve kterých k nim již není
                    umožněn přístup žádné straně. Po uplynutí 180 dnů jsou data již zcela a
                    nenávratně smazána společně s veškerými zálohami dat.
                </Typography>
                <Typography align="left">
                    20. Profil uživatele je možné smazat také z administrace Poskytovatele, tedy
                    Patria Finance, a.s.
                </Typography>
                <Typography align="left">
                    21. Uživatelský profil, který bude neaktivní po dobu delší než 12 měsíců, bude
                    automaticky deaktivován prvním dnem po uplynutí této doby.
                </Typography>
                <Typography align="left">
                    22. Pokud si Uživatel „jen“ odinstaluje svoji aplikaci (bez předchozí deaktivace
                    svého přístupu), je zde stejný proces jako u deaktivace neaktivního účtu, tzn.
                    po 12 měsících dojde k automatickému smazání držených dat spojených s daným
                    profilem.
                </Typography>
                <Typography align="left">
                    23. Pokud Uživatel deaktivuje svůj účet sám, tak svůj účet může obnovit
                    opětovným přihlášením do platformy ve lhůtě 30 dnů. Přihlášení v této lhůtě se
                    bere jako obnovení účtu.
                </Typography>
                <Typography align="left">
                    24. Pokud je Uživatel zablokován administrativní rolí poskytovatele a chce
                    zažádat o obnovení, tak Uživatel musí kontaktovat poskytovatele tedy Patria
                    Finance, a.s. Poskytovatel má možnost obnovit profil Uživatele ve lhůtě do 30.
                    dne ode dne zablokování.
                </Typography>
                <Typography align="left">
                    25. Pokud je účet Uživatele deaktivován z důvodu neaktivity (tzn. Uživatel
                    naposledy vstoupil do Aplikace před více než 12 měsíci), tak Uživatel již nemá
                    možnost účet obnovit.
                </Typography>
                <Typography align="left">
                    26. Při mazání profilu je v administraci Aplikace uvedený záznam, kdo smazání
                    provedl.
                </Typography>
                <Typography variant="h3" align="left">
                    Ukončení, deaktivace a dočasné pozastavení Aplikace ze strany poskytovatele
                </Typography>
                <Typography align="left">
                    27. Poskytovatel je oprávněn kdykoliv z důvodů technických, bezpečnostních, z
                    důvodů údržby, správy nebo z jiných důvodů dočasně pozastavit používání Aplikace
                    na potřebnou dobu, aniž by Patria Finance vznikl z tohoto důvodu jakýkoliv
                    závazek vůči Uživateli nebo odpovědnost za škodu vzniklou v důsledku toho
                    Uživateli či třetí osobě.
                </Typography>
                <Typography align="left">
                    28. Patria Finance je oprávněna trvale deaktivovat Aplikaci při porušení
                    Podmínek ze strany Uživatele, dále z bezpečnostních nebo obchodních důvodů
                    (např. rozhodnutí Patria Finance o dalším neposkytování Aplikace). V takovém
                    případě Patria Finance není odpovědná za škodu vzniklou z důvodu trvalé
                    deaktivace Aplikace Uživateli či třetí osobě. O trvalé deaktivaci Aplikace
                    Patria Finance Uživatele informuje prostřednictvím informace zveřejněné na svých
                    webových stránkách investo.patria.cz.
                </Typography>
                <Typography variant="h3" align="left">
                    Práva a povinnosti Uživatele
                </Typography>
                <Typography align="left">
                    29. Uživatel je povinen se podrobně seznámit s Podmínkami, dodržovat je a řídit
                    se bezpečnostními zásadami uveřejněnými na{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.csob.cz/bezpecnost"
                        style={{ color: colors.primary.orange }}
                    >
                        www.csob.cz/bezpecnost
                    </a>
                    .
                </Typography>
                <Typography align="left">
                    30. Při instalaci Aplikace je Uživatel povinen zkontrolovat, že je jejím
                    poskytovatelem Patria Finance.
                </Typography>
                <Typography align="left">
                    31. Uživatel je povinen zajistit, aby bylo mobilní zařízení či zařízení typu PC,
                    notebook, tablet apod. vybaveno:
                    <ol style={{ listStyle: 'none' }}>
                        <li>
                            a) aktualizovaným operačním systémem (pravidelné aktualizace zajišťují
                            odstranění bezpečnostních slabin systému odhalených až při jeho
                            využívání),
                        </li>
                        <li>
                            b) funkčním (trvale zapnutým) a aktualizovaným antivirovým programem s
                            nastavením pravidelné antivirové kontroly mobilu.
                        </li>
                    </ol>
                </Typography>
                <Typography align="left">
                    32. Uživatel je povinen:
                    <ol style={{ listStyle: 'none' }}>
                        <li>
                            a) instalovat do mobilu či jiného zařízení pouze programy z
                            důvěryhodných zdrojů nebo zdrojů doporučených výrobcem zařízení (Obchod
                            Play a App Store) a věnovat zároveň pozornost oprávněním požadovaným
                            instalovanou aplikací,
                        </li>
                        <li>
                            b) nepoužívat Aplikaci v mobilech či v jiném zařízení, u nichž byly
                            provedeny změny nastavení, tzv. „root“ (tyto změny mohou vést k narušení
                            bezpečnosti daného Mobilu nebo zařízení např. snížením jeho odolnosti
                            proti škodlivému softwaru),
                        </li>
                        <li>
                            c) mít mobilní či další zařízení pod trvalou kontrolou a využívat takové
                            zabezpečení, které zabrání třetí osobě v přístupu do tohoto zařízení
                            (např. zabezpečení přístroje pomocí vstupního hesla),
                        </li>
                        <li>
                            d) využívat zabezpečené WiFi sítě, případně datové připojení poskytnuté
                            mobilním operátorem. V Aplikaci je potřeba povolit užívání dat.
                        </li>
                        <li>
                            e) nevydávat se při registraci za někoho jiného (pro využívání Aplikace
                            není potřeba identita Uživatele) a nevytvářet účet někoho jiného, pokud
                            nemá jeho výslovné svolení. Poskytované registrační údaje Uživatele musí
                            být přesné a aktuální,
                        </li>
                        <li>f) využívat Aplikaci v mezích zákona pouze ke schválenému účelu,</li>
                        <li>g) neuvádět v uživatelském jménu název domény nebo URL,</li>
                        <li>
                            h) dodržovat zásady Aplikace s cílem nenarušit nebo nezhoršit správné
                            fungování služby, nesmí se pokoušet zobrazit či získat informace
                            neschválenými způsoby, jako je například vytváření účtů nebo získávání
                            informací automatizovaným způsobem, dále se nesmí pokoušet koupit,
                            prodat nebo převést jakoukoli část svého účtu (včetně uživatelského
                            jména) nebo nabízet, sbírat nebo používat přihlašovací údaje či štítky
                            jiných uživatelů.
                        </li>
                    </ol>
                </Typography>
                <Typography align="left">
                    33. Uživatel není oprávněn jakýmkoli způsobem zasahovat do Aplikace a jejího
                    zdrojového kódu, zejména používat programové řešení ovlivňující funkcionalitu
                    nebo bezpečnost Aplikace, popř. jakýmkoli způsobem porušující práva třetích osob
                    nebo Patria Finance, ani používat programová řešení, jež nejsou v souladu s
                    platnými právními předpisy.
                </Typography>
                <Typography variant="h3" align="left">
                    Ochrana a zpracování osobních údajů
                </Typography>
                <Typography align="left">
                    34. V této souvislosti bychom vás rádi informovali, že Patria Finance, jakožto
                    správce osobních údajů, zpracovává osobní údaje Uživatele za účelem přihlášení
                    do Aplikace, a to v rozsahu přezdívka (Nick name) a e-mailová adresa. Další
                    údaje, které Patria Finance může zpracovávat, jsou kraj, povolání, zkušenosti s
                    investováním a profilový obrázek. Tyto osobní údaje jsou zpřístupněny
                    společnosti Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
                    Ireland, VAT number: IE 6388047V, která poskytuje technické zázemí Aplikace pro
                    společnost Qusion (Qusion s.r.o., U Potoka 751, Litomyšlské Předměstí, 566 01
                    Vysoké Mýto, IČO: 06869742). Společnost Qusion je dodavatelem Aplikace (tzn. i
                    zpracovatelem osobních údajů) pro Patria Finance (tzn. Patria Finance
                    spolupracuje se společností Qusion).
                </Typography>
                <Typography align="left">
                    35. Dále bychom Uživateli chtěli sdělit, že za účelem vyhodnocování jednotlivých
                    testů dochází ke zpracování osobních údajů, která jsou výhradně statistického
                    charakteru, na základě nichž nelze identifikovat konkrétního Uživatele. Účelem
                    takového zpracování je možnost porovnání výsledků uživatelů podle okresů, a/nebo
                    povolání, a/nebo zkušenosti s investováním. Osobní údaje jsou zpracovány
                    výhradně po dobu užívání Aplikace, pokud zákon neukládá zpracovávat osobní údaje
                    déle.
                </Typography>
                <Typography align="left">
                    36. Detailní informace o zpracovávání osobních údajů a právech Uživatele, které
                    s tímto zpracováváním souvisejí, jsou uvedeny v dokumentu „Informace o
                    zpracování osobních údajů“ přístupném na{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.csob.cz/portal/csob/ochrana-osobnich-udaju"
                        style={{ color: colors.primary.orange }}
                    >
                        Webových stránkách
                    </a>{' '}
                    a v provozních prostorách Patria Finance.
                </Typography>
                <Typography align="left">
                    37. Pokud si nepřejete, abychom si předávali vaše osobní údaje za marketingovými
                    účely ve skupině, tedy chcete omezit nebo odvolat souhlas se zpracováním a
                    sdílením údajů ve skupině ČSOB pro marketingové účely, zavolejte na 800 023 003,
                    navštivte pobočku nebo nám napište na{' '}
                    <a
                        href="mailto:osobni-data@csob.cz"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: colors.primary.orange }}
                    >
                        osobni-data@csob.cz
                    </a>{' '}
                    a my se vám ozveme zpět. Pro možnost ověřovacího hovoru tedy uveďte své
                    telefonní číslo. Nastavení souhlasu si můžete také změnit v některých našich
                    elektronických portálech, pokud máte v rámci našich služeb do nich zřízen
                    přístup. Sami si můžete zvolit, zda chcete dostávat nabídky SMS, e-mailem,
                    telefonicky, v listovních zásilkách, v elektronických portálech nebo v mobilních
                    aplikacích.
                </Typography>
                <Typography align="left">
                    Více informací naleznete{' '}
                    <a
                        href="https://www.csob.cz/csob/ochrana-osobnich-udaju"
                        target="_blank"
                        style={{ color: colors.primary.orange }}
                        rel="noopener noreferrer"
                    >
                        zde
                    </a>
                    .
                </Typography>

                <Typography variant="h3" align="left">
                    Závěrečná ustanovení
                </Typography>
                <Typography align="left">
                    38. Informace, obsah, grafika, text, obrázky, ochranné známky, obchodní názvy,
                    loga a jiné duševní vlastnictví obsažené nebo použité ve spojení s Aplikací jsou
                    chráněny autorským právem a dalšími právními předpisy o duševním vlastnictví.
                </Typography>
                <Typography align="left">
                    39. Patria Finance neodpovídá za škodu, která vznikla Uživateli či třetím osobám
                    v souvislosti s používáním Aplikace či její dočasnou nefunkčností.
                </Typography>
                <Typography align="left">
                    40. Patria Finance může s okamžitou účinností provést takovou změnu Podmínek,
                    kterou jednostranně nezasahuje do práv a povinností Uživatele. Touto změnou může
                    být zejména úprava Podmínek doplněním nové funkcionality, zvýšením bezpečnosti,
                    technologickým rozvojem či změnou relevantních právních předpisů. O takto
                    provedené změně Patria Finance Uživatele informuje prostřednictvím e-mailu
                    Aplikace nebo Aplikace samotné a rovněž svých Webových stránkách.
                </Typography>
                <Typography align="left">
                    41. V souvislosti se změnou Podmínek je Patria Finance oprávněna požadovat, aby
                    Uživatel provedl novou aktivaci Aplikace.
                </Typography>
                <Typography align="left">
                    42. Podmínky nabývají účinnosti dne 1. 5. 2021.
                </Typography>
                <Typography variant="h3" align="left">
                    Patria Finance, a. s.
                </Typography>
                <Typography align="left">
                    Výmolova 353/3, Radlice, 150 00 Praha 5; IČO: 26455064
                </Typography>
                <Typography align="left">
                    zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl B, vložka
                    7215
                </Typography>
            </Main>
        </section>
    </>
)

export default TermsPage
