import styled from 'styled-components'
import MuiButton from '@material-ui/core/Button'

import { Tab as TabBase, Container as ContainerBase } from '../../../../components/shared/styled'

export const Button = styled(MuiButton)``

export const Header = styled.div`
    width: 100%;
    margin: 60px 0 40px 0;
    display: inline-flex;
`

export const Container = styled(ContainerBase)`
    ${Button} {
        min-width: 190px;
        margin-left: auto;
    }
`

export const Tab = styled(TabBase)``
