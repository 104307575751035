import styled from 'styled-components'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import { colors, media } from '@variables'

export const AccordionDetails = styled(MuiAccordionDetails)``

export const Divider = styled.div`
    height: 1px;
    margin: 0 auto;
    width: calc(100% - 48px);
    background-color: #c0c0c0;

    @media (max-width: 479px) {
        width: calc(100% - 8px);
    }
`

export const Wrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    max-width: 980px;

    ${AccordionDetails} {
        padding: 15px 60px 25px 27px;

        a {
            text-decoration: underline;
            color: ${colors.primary.orange};

            ${media.tablet} {
                :hover {
                    text-decoration: none;
                }
            }
        }

        @media (max-width: 479px) {
            padding: 15px 50px 25px 8px;
        }
    }

    .MuiExpansionPanelSummary-root {
        @media (max-width: 479px) {
            padding: 0 4px;
        }
    }

    .MuiIconButton-edgeEnd {
        padding: 0;
    }

    p {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.5px;
    }
`
