import styled from 'styled-components'

import { Box as BaseBox } from '../../../../components/shared/styled'

export const CustomBox = styled(BaseBox)`
    position: relative;
    margin-bottom: 15px;
    padding: 30px 40px 40px 40px;
`

export const TestBox = styled(BaseBox)`
    padding: 0 40px;

    :not(:last-of-type) {
        margin-bottom: 15px;
    }
`

export const ButtonWrapper = styled.div`
    width: 100%;
    align-items: flex-start;
    display: inline-flex;
    justify-content: space-between;
`
