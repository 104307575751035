import styled from 'styled-components'

import { media } from '@variables'

export const Button = styled.button`
    width: 100%;
    height: 50px;
    color: white;
    border: none;
    outline: none;
    display: flex;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    background-color: #4968ad;

    :disabled {
        pointer-events: none;
    }

    ${media.tablet} {
        letter-spacing: -0.4px;
    }
`
