import React from 'react'
import styled from 'styled-components'
import { SvgIconProps } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CloseRounded'

import { media, colors, transition } from '@variables'

const Icon = styled(CloseIcon)`
    top: 25px;
    right: 25px;
    opacity: 0.25;
    cursor: pointer;
    position: absolute;
    color: ${colors.primary.darkBlue};
    transition: ${transition.main};

    ${media.tablet} {
        :hover {
            opacity: 0.35;
        }
    }
`

const Close = ({ ref, ...rest }: SvgIconProps) => <Icon role="button" {...rest} />

export default Close
