import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="22"
            fill="none"
            viewBox="0 0 20 22"
            {...props}
        >
            <path
                fillRule="evenodd"
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M10 10.375a4.375 4.375 0 100-8.75 4.375 4.375 0 000 8.75z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M1.875 20.375a8.125 8.125 0 0116.25 0"
            ></path>
        </svg>
    )
}

export default Icon
