import React from 'react'
import Button from '@material-ui/core/Button'

import * as loco from '@loco'
import Illustration from '../../../SVG/Welcome'
import { Wrapper, H3, Text, IllustrationWrapper } from '../styled'

type Props = {
    close: () => void
}

const Welcome = ({ close }: Props) => (
    <Wrapper>
        <IllustrationWrapper>
            <Illustration />
        </IllustrationWrapper>
        <H3 style={{ margin: '15px 0' }}>{loco.dialogs.welcome.title}</H3>
        <Text>{loco.dialogs.welcome.text}</Text>
        <Button
            fullWidth
            size="large"
            color="primary"
            onClick={close}
            variant="contained"
            style={{ marginTop: 30 }}
        >
            {loco.dialogs.welcome.button}
        </Button>
    </Wrapper>
)

export default Welcome
