import React, { useContext } from 'react'
import Typography from '@material-ui/core/Typography'

import * as loco from '@loco'
import CoinsIcon from '../../SVG/Coins'
import { colors } from '@variables'
import Progress from '../../shared/Progress'
import { getPercentages } from '@utils'
import { AuthContext } from '../../../context/Auth'
import { Wrapper, Points, PointsText, Level, LevelRow } from './styled'

const ProfileLevelBox = () => {
    const { user } = useContext(AuthContext)

    const reachedPoints = user?.student?.progress.points.reached || 0
    const level = user?.student?.progress.level.progress || 0
    const reachedLectures = user?.student?.progress.lectures.reached || 0
    const totalLectures = user?.student?.progress.lectures.total || 0

    const levelName = user?.student?.progress.level.name || ''
    const nextLevelName = user?.student?.progress.level.nextLevelName || ''

    return (
        <Wrapper>
            <Points>
                <CoinsIcon color={colors.primary.orange} style={{ height: 55, width: 55 }} />
                <PointsText>{reachedPoints}</PointsText>
                <Typography variant="body2">{loco.profile.coins}</Typography>
            </Points>

            <Level>
                <Typography variant="h6">{loco.profile.lvl}</Typography>
                <LevelRow>
                    <Typography variant="body2">{levelName}</Typography>
                    <Typography variant="body2">{nextLevelName}</Typography>
                </LevelRow>
                <Progress
                    style={{ marginTop: 10 }}
                    variant="determinate"
                    color="primary"
                    value={level}
                />

                <Typography variant="h6" style={{ marginTop: 30 }}>
                    {loco.profile.lectures}
                </Typography>
                <LevelRow>
                    <Typography variant="body2">{reachedLectures}</Typography>
                    <Typography variant="body2">{totalLectures}</Typography>
                </LevelRow>
                <Progress
                    value={getPercentages(reachedLectures, totalLectures)}
                    style={{ marginTop: 10 }}
                    variant="determinate"
                    color="primary"
                />
            </Level>
        </Wrapper>
    )
}

export default ProfileLevelBox
