import { useSnackbar } from 'notistack'
import { useSetState } from 'react-use'
import { useHistory, useLocation } from 'react-router-dom'
import React, { useContext, ChangeEvent, useEffect } from 'react'

import * as loco from '@loco'
import { Routes } from '@variables'
import { translateGQLError } from '@utils'
import Head from '../../../../components/Head'
import { AuthContext } from '../../../../context/Auth'
import { Header, Container, Button, Tab } from './styled'
import { Title } from '../../../../components/Tables/Superadmin'
import UsersTable from '../../../../components/Tables/Superadmin/Users'
import StudentsTable from '../../../../components/Tables/Superadmin/Students'

import {
    Role,
    useAdminUsersQuery,
    useStudentUsersQuery,
    OrderByDirectionInput,
    UsersOrderByColumnInput
} from '@graphql'

type State = {
    title?: Title
    open: boolean
    itemsPerPage: number
    notActiveSchoolSearchQuery: string
    students: {
        page: number
        search: string
        orderBy: {
            column: UsersOrderByColumnInput
            direction: OrderByDirectionInput
        }
    }
    admins: {
        page: number
        search: string
        orderBy: {
            column: UsersOrderByColumnInput
            direction: OrderByDirectionInput
        }
    }
}

const initOrderBy = {
    direction: OrderByDirectionInput.asc,
    column: UsersOrderByColumnInput.name
}

const initValues = {
    page: 0,
    search: ''
}

const SuperAdmin = () => {
    const history = useHistory()
    const { search } = useLocation()
    const { enqueueSnackbar } = useSnackbar()

    const { user } = useContext(AuthContext)

    const [state, setState] = useSetState<State>({
        open: false,
        title: undefined,
        itemsPerPage: 10,
        notActiveSchoolSearchQuery: '',
        students: {
            page: 0,
            search: '',
            orderBy: {
                direction: OrderByDirectionInput.asc,
                column: UsersOrderByColumnInput.username
            }
        },
        admins: {
            page: 0,
            search: '',
            orderBy: { ...initOrderBy }
        }
    })

    const { title, open } = state

    const {
        data: studentsData,
        error: studentsError,
        loading: studentsLoading,
        refetch: refetchStudents
    } = useStudentUsersQuery({
        skip: title !== Title.STUDENT,
        variables: {
            page: state.students.page,
            search: state.students.search,
            itemsPerPage: state.itemsPerPage,
            orderBy: {
                column: state.students.orderBy.column,
                direction: state.students.orderBy.direction
            }
        }
    })

    const {
        data: adminsData,
        error: adminsError,
        loading: adminsLoading,
        refetch: refetchAdmins
    } = useAdminUsersQuery({
        skip: title !== Title.ADMIN,
        variables: {
            page: state.admins.page,
            search: state.admins.search,
            itemsPerPage: state.itemsPerPage,
            orderBy: {
                column: state.admins.orderBy.column,
                direction: state.admins.orderBy.direction
            }
        }
    })

    if (studentsError) {
        enqueueSnackbar(translateGQLError(studentsError.message), {
            variant: 'error'
        })
    }
    if (adminsError) {
        enqueueSnackbar(translateGQLError(adminsError.message), {
            variant: 'error'
        })
    }

    useEffect(() => {
        if (search.includes(Routes.ADMINS_SEARCH_PARAM)) {
            return setState({ title: Title.ADMIN })
        }

        return setState({ title: Title.STUDENT })
    }, [search])

    const setOpen = (is: boolean) => setState({ open: is })

    const onChangePage = (page: number) => {
        if (title === Title.STUDENT) {
            setState({
                students: {
                    page: page,
                    search: state.students.search,
                    orderBy: state.students.orderBy
                }
            })
        }
        if (title === Title.ADMIN) {
            setState({
                admins: {
                    page: page,
                    search: state.admins.search,
                    orderBy: state.admins.orderBy
                }
            })
        }
    }

    const onChangeItemsPerPage = (itemsPerPage: number) => {
        setState({ itemsPerPage: itemsPerPage })
    }

    {
        /**
         * Bug: pageSize dont change if new props in option appear
         *
         * https://github.com/mbrn/material-table/issues/1644
         */
    }
    const onSearch = (searchQuery: string) => {
        if (title === Title.STUDENT) {
            setState({
                students: {
                    ...initValues,
                    search: searchQuery,
                    orderBy: {
                        direction: OrderByDirectionInput.asc,
                        column: UsersOrderByColumnInput.username
                    }
                }
            })
        }
        if (title === Title.ADMIN) {
            setState({
                admins: {
                    ...initValues,
                    search: searchQuery,
                    orderBy: { ...initOrderBy }
                }
            })
        }
    }

    const setTitle = (newTitle: Title, isOpen?: boolean) => (e: ChangeEvent<{}>) => {
        setState({
            title: newTitle,
            admins: {
                ...initValues,
                orderBy: { ...initOrderBy }
            },
            students: {
                ...initValues,
                orderBy: {
                    direction: OrderByDirectionInput.asc,
                    column: UsersOrderByColumnInput.username
                }
            }
        })

        if (Boolean(isOpen)) {
            setOpen(Boolean(isOpen))
        }

        if (newTitle === Title.ADMIN) {
            history.push({ search: Routes.ADMINS_SEARCH_PARAM })
        }
        if (newTitle === Title.STUDENT) {
            history.push({ search: Routes.STUDENTS_SEARCH_PARAM })
        }
    }

    // https://github.com/mbrn/material-table/issues/871#issuecomment-515026168
    const handleSortOrderChange = (
        orderedColumnId: number,
        orderDirection: OrderByDirectionInput
    ) => {
        if (title === Title.STUDENT) {
            if (orderedColumnId > 1) return

            let column = UsersOrderByColumnInput.username
            if (orderedColumnId === 1) column = UsersOrderByColumnInput.email

            setState({
                students: {
                    ...state.students,
                    orderBy: {
                        column: column,
                        direction: orderDirection
                    }
                }
            })
        }
        if (title === Title.ADMIN) {
            if (orderedColumnId > 1) return

            let column = UsersOrderByColumnInput.name
            if (orderedColumnId === 1) column = UsersOrderByColumnInput.email

            setState({
                admins: {
                    ...state.admins,
                    orderBy: {
                        column: column,
                        direction: orderDirection
                    }
                }
            })
        }
    }

    const admins = adminsData?.users.data || []
    const students = studentsData?.users.data || []

    return (
        <>
            <Head title={loco.seo['super-admin'].title} />

            <Container>
                <Header>
                    <Tab
                        type="button"
                        active={title === Title.STUDENT}
                        onClick={setTitle(Title.STUDENT)}
                    >
                        {Title.STUDENT}
                    </Tab>
                    {user?.role === Role.SUPER_ADMIN && (
                        <Tab
                            type="button"
                            active={title === Title.ADMIN}
                            onClick={setTitle(Title.ADMIN)}
                        >
                            {Title.ADMIN}
                        </Tab>
                    )}

                    {user?.role === Role.SUPER_ADMIN && title === Title.ADMIN && (
                        <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            onClick={setTitle(Title.ADMIN, true)}
                        >
                            {loco.admin.users.admin}
                        </Button>
                    )}
                </Header>

                {title === Title.STUDENT && (
                    <StudentsTable
                        title={title}
                        users={students}
                        onSearch={onSearch}
                        refetch={refetchStudents}
                        loading={studentsLoading}
                        page={state.students.page}
                        onChangePage={onChangePage}
                        pageSize={state.itemsPerPage}
                        onChangeItemsPerPage={onChangeItemsPerPage}
                        totalCount={studentsData?.users.meta.items || 0}
                        handleSortOrderChange={handleSortOrderChange}
                        sortedColumnDirection={state.students.orderBy.direction}
                        sortedColumnId={
                            state.students.orderBy.column === UsersOrderByColumnInput.username
                                ? 0
                                : 1
                        }
                    />
                )}

                {title === Title.ADMIN && user?.role === Role.SUPER_ADMIN && (
                    <UsersTable
                        open={open}
                        title={title}
                        users={admins}
                        setOpen={setOpen}
                        onSearch={onSearch}
                        refetch={refetchAdmins}
                        loading={adminsLoading}
                        page={state.admins.page}
                        onChangePage={onChangePage}
                        pageSize={state.itemsPerPage}
                        onChangeItemsPerPage={onChangeItemsPerPage}
                        handleSortOrderChange={handleSortOrderChange}
                        totalCount={adminsData?.users.meta.items || 0}
                        sortedColumnDirection={state.admins.orderBy.direction}
                        sortedColumnId={
                            state.admins.orderBy.column === UsersOrderByColumnInput.name ? 0 : 1
                        }
                    />
                )}
            </Container>
        </>
    )
}

export default SuperAdmin
