import { format } from 'date-fns'
import isArray from 'lodash/isArray'
import React, { forwardRef } from 'react'
import MaterialTable, { MTableHeader, Column } from 'material-table'
import { DateRange } from '@material-ui/pickers/DateRangePicker/RangeTypes'

import * as loco from '@loco'
import Row from '../../../shared/Row'
import { getProfileName } from '@utils'
import Pagination from '../../Pagination'
import { tableLocalization } from '@variables'
import Icon from '../../../../components/SVG/RowStats'
import { tableIcons, headerStyle } from '../../../shared'
import Toolbar from '../../../../components/Tables/Toolbar'
import { UsersWithStatsQuery, OrderByDirectionInput } from '@graphql'

type Data = UsersWithStatsQuery['users']['data']
type User = Data[0]

type Props = {
    data: Data
    page: number
    title: string
    loading: boolean
    pageSize: number
    totalCount: number
    sortedColumnId: number
    setRow: (data: User) => void
    setOpen: (is: boolean) => void
    onChangePage: (page: number) => void
    onSearch: (searchQuery: string) => void
    onChangeItemsPerPage: (page: number) => void
    sortedColumnDirection: OrderByDirectionInput
    handleSortOrderChange: (orderedColumnId: number, orderDirection: OrderByDirectionInput) => void
    selectedDate: DateRange<Date | null>
    handleDateChange: (date: DateRange<Date | null>) => void
}

const UsersTable = ({
    data,
    page,
    title,
    setRow,
    setOpen,
    loading,
    onSearch,
    pageSize,
    totalCount,
    onChangePage,
    sortedColumnId,
    onChangeItemsPerPage,
    sortedColumnDirection,
    handleSortOrderChange,
    selectedDate,
    handleDateChange
}: Props) => {
    return (
        <MaterialTable
            data={data}
            page={page}
            title={title}
            columns={columns}
            icons={tableIcons}
            isLoading={loading}
            totalCount={totalCount}
            onSearchChange={onSearch}
            onChangePage={onChangePage}
            localization={tableLocalization}
            onChangeRowsPerPage={onChangeItemsPerPage}
            options={{
                pageSize,
                draggable: false,
                thirdSortClick: false,
                actionsColumnIndex: -1
            }}
            components={{
                Header: (props) =>
                    (
                        <MTableHeader
                            {...props}
                            orderBy={sortedColumnId}
                            onOrderChange={handleSortOrderChange}
                            orderDirection={sortedColumnDirection}
                        />
                    ) as any,
                Toolbar: (props) =>
                    (
                        <Toolbar
                            title={props.title}
                            selectedDate={selectedDate}
                            searchText={props.searchText}
                            onSearchChanged={props.onSearchChanged}
                            handleDateChange={(date) => handleDateChange(date)}
                        />
                    ) as any,
                Pagination: Pagination
            }}
            actions={[
                (row: User) => {
                    return {
                        position: 'row',
                        tooltip: row ? undefined : loco.common.more,
                        icon: forwardRef(function icon(props, ref) {
                            return <Icon ref={ref} {...props} />
                        }) as any,
                        onClick: (e) => {
                            if (row && !isArray(row)) {
                                setRow(row)
                                setOpen(true)
                            }
                        }
                    }
                }
            ]}
        />
    )
}

const sortByUsername = (a: User, b: User) => {
    if (!a.username || !b.username) return 0
    return a.username.localeCompare(b.username)
}

const sortByPoints = (a: User, b: User) => {
    return (a.student?.progress.points.reached || 0) - (b.student?.progress.points.reached || 0)
}

const sortByBadges = (a: User, b: User) => {
    return (a.student?.badgeBoard.length || 0) - (b.student?.badgeBoard.length || 0)
}

const columns: Column<User>[] = [
    {
        sorting: false,
        field: 'countryRank',
        headerStyle: { ...headerStyle, width: 140 },
        title: loco['users_countryRank'],
        render: function render(row: User) {
            const countryRank = row?.student?.progress?.countryRank || ''
            return <Row text={`${countryRank}`} />
        }
    },
    {
        sorting: false,
        field: 'regionRank',
        headerStyle: { ...headerStyle, width: 140 },
        title: loco['users_regionRank'],
        render: function render(row: User) {
            const regionRank = row?.student?.progress?.regionRank || ''
            return <Row text={`${regionRank}`} />
        }
    },
    {
        searchable: true,
        field: 'email',
        headerStyle: headerStyle,
        customSort: sortByUsername,
        title: loco.statistics_table_username,
        render: function render(row: User) {
            const username = getProfileName(row)
            return <Row text={username} />
        }
    },
    {
        sorting: false,
        field: 'lifeStage',
        title: loco.statistics_table_lifeStage,
        headerStyle: { ...headerStyle, width: 180 },
        render: function render(row: User) {
            const lifeStage = row.lifeStage?.name
            return <Row text={lifeStage} />
        }
    },
    {
        sorting: false,
        field: 'investmentExperience',
        headerStyle: { ...headerStyle, width: 180 },
        title: loco.statistics_table_investmentExperience,
        render: function render(row: User) {
            const investmentExperience = row.investmentExperience?.name
            return <Row text={investmentExperience} />
        }
    },
    {
        sorting: false,
        field: 'points',
        customSort: sortByPoints,
        title: loco.statistics_table_points,
        headerStyle: { ...headerStyle, width: 140 },
        render: function render(row: User) {
            return <Row text={`${row.student?.progress.points.reached || 0}`} />
        }
    },
    {
        sorting: false,
        field: 'badges',
        customSort: sortByBadges,
        title: loco.statistics_table_badgesCount,
        headerStyle: { ...headerStyle, width: 160 },
        render: function render(row: User) {
            const badgesCount = row.student?.badgeBoard.length || 0
            return <Row text={`${badgesCount}`} />
        }
    },
    {
        sorting: false,
        field: 'createdAt',
        title: loco.registration_date,
        headerStyle: { ...headerStyle, width: 160 },
        render: function render(row: User) {
            return <Row text={`${format(new Date(row.createdAt), 'dd.MM.Y')}`} />
        }
    }
]

export default UsersTable
