import React from 'react'

import { Props } from '.'

function Icon({ color }: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="none"
            viewBox="0 0 25 25"
        >
            <path
                fillRule="evenodd"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M5.5 7a3 3 0 100-6 3 3 0 000 6zM7.75 23.5L8.5 16H10v-3a4.5 4.5 0 10-9 0v3h1.5l.75 7.5h4.5z"
                clipRule="evenodd"
            ></path>
            <path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M13 16.75h9a1.5 1.5 0 001.5-1.5V2.5A1.5 1.5 0 0022 1H11.5"
            ></path>
        </svg>
    )
}

export default Icon
