import styled from 'styled-components'

import { media, transition } from '@variables'

export const Wrapper = styled.a`
    background-color: black;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: white;
    border-radius: 8px;
    width: 100%;
    height: 54px;
    display: flex;
    cursor: pointer;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    will-change: opacity;
    transition: ${transition.main};
`
