/* eslint-disable prettier/prettier */
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React, { useContext, useEffect } from 'react'

import * as loco from '@loco'
import Head from '../../../components/Head'
import Chapter from '../../../components/Chapter'
import { sortByCreationStatus } from '@utils'
import SignInBox from '../../../components/SignInBox'
import Tooltip from '../../../components/shared/Tooltip'
import LevelBox from '../../../components/shared/LevelBox'
import { AuthContext } from '../../../context/Auth'
import DiplomaBox from '../../../components/shared/Diploma'
import Questionnaire from '../../../components/Questionnaire'
import DailyQuestion from '../../../components/DailyQuestion'
import NotAllowed from '../../../components/shared/NotAllowed'
import Statistics from '../../../components/shared/Statistics'
import Achievements from '../../../components/shared/Achievements'
import { Blur, BlurWrapper } from '../../../components/shared/styled'
import SvgChapterIcon from '@static/images/fakeChapterIcon.svg'
import { Section, Container } from './styled'
import {
    BadgesFragment,
    CreationStatus,
    useBadgesQuery,
    useChaptersQuery,
    useFinalTestQuery,
    Chapter as ChapterType
} from '@graphql'
import {
    Left,
    Right,
    Title,
    Aside,
    AsideInner,
    TitleWrapper,
    HelpIconWrapper
} from '../../../components/shared/styled'

const Home = () => {
    const { isAuthorized, user, logoutLoading, isLoggingIn, loading } = useContext(AuthContext)
    const isUserNotAuthOrTemp = !isAuthorized || Boolean(user?.isTemporary)
    const isNewOne = !Boolean(user?.student?.progress.points.reached)

    const { data, loading: chaptersLoading } = useChaptersQuery()
    const { data: finalTestData } = useFinalTestQuery({
        ssr: false,
        skip: isUserNotAuthOrTemp,
        fetchPolicy: "no-cache"
    })
    const { data: badgesData, loading: badgesLoading } = useBadgesQuery({
        ssr: false,
        skip: isUserNotAuthOrTemp
    })

    const chapters = data?.chapters || []
    const badges: BadgesFragment[] = badgesData?.badges || []

    const isLoading = logoutLoading || isLoggingIn || chaptersLoading || loading

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <>
            <Head title={loco.seo.dashboard.title} />

            <Container style={{ minHeight: 'inherit' }}>
                <Grid container spacing={6} style={{ minHeight: 'inherit' }}>
                    <Left item xs={12} md={8}>
                        <DailyQuestion />

                        <Title variant="h6">{loco.dashboard.chapters}</Title>

                        {!isLoading &&
                            chapters
                                .filter(filterByCreationStatus)
                                .sort(sortByCreationStatus)
                                .map((c) => {
                                    const isBlocked = c.status === CreationStatus.BLOCKED
                                    const isLastActive =
                                        (c.index === 0 && (isUserNotAuthOrTemp || isNewOne)) ||
                                        c.isLastActive

                                    if (c.index === 1) {
                                        return [
                                            <Section
                                                key={c.id}
                                                isBlocked={isBlocked}
                                                isLastActive={isLastActive}
                                            >
                                                {isBlocked && <NotAllowed variant="chapter" />}
                                                <Chapter chapter={c} isLastActive={isLastActive} />
                                            </Section>,
                                            <Questionnaire key="questionnaire" />
                                        ]
                                    }

                                    return (
                                        <Section
                                            key={c.id}
                                            isBlocked={isBlocked}
                                            isLastActive={isLastActive}
                                        >
                                            {isBlocked && <NotAllowed variant="chapter" />}
                                            <Chapter chapter={c} isLastActive={isLastActive} />
                                        </Section>
                                    )
                                })}

                        {isLoading ||
                            // Hide fake chapters only when fetch all chapters
                            chapters.length <= 1 ? (
                                <BlurWrapper>
                                    <Blur />
                                    {fakeChapters.map((c: any, idx: number) => (
                                        <Section key={c.id} style={{ filter: `blur(${idx + 2.5}px)` }}>
                                            <Chapter chapter={c} />
                                        </Section>
                                    ))}
                                </BlurWrapper>
                            ) : (
                                <></>
                            )}
                    </Left>

                    <Right item xs={12} md={4}>
                        <Aside>
                            {isUserNotAuthOrTemp && <SignInBox />}

                            {!isUserNotAuthOrTemp && (
                                <AsideInner>
                                    <Typography variant="h6" gutterBottom>
                                        {loco.profile.preview}
                                    </Typography>
                                    <LevelBox />

                                    <Typography variant="h6" style={{ padding: '40px 0 15px 0' }}>
                                        {loco.profile.statistics}
                                    </Typography>
                                    <Statistics />

                                    <TitleWrapper>
                                        <Typography variant="h6">
                                            {loco.dashboard.badges.title}
                                        </Typography>
                                        <HelpIconWrapper>
                                            <Tooltip title={loco.tooltips.badge} withHelpIcon />
                                        </HelpIconWrapper>
                                    </TitleWrapper>
                                    <Achievements
                                        badges={badges}
                                        variant="vertical"
                                        loading={badgesLoading}
                                    />

                                    <Typography variant="h6" style={{ padding: '40px 0 15px 0' }}>
                                        {loco.dashboard.diplom.title}
                                    </Typography>
                                    <DiplomaBox
                                        testId={finalTestData?.finalTest?.id}
                                        testScore={{
                                            maxPoints: finalTestData?.finalTest?.maxPoints,
                                            acquiredPoints: finalTestData?.finalTest?.acquiredPoints
                                        }}
                                    />
                                </AsideInner>
                            )}
                        </Aside>
                    </Right>
                </Grid>
            </Container>
        </>
    )
}

const filterByCreationStatus = (c: Pick<ChapterType, 'status' | 'acquiredPoints'>) => {
    return (
        c.status === CreationStatus.APPROVED ||
        (c.status === CreationStatus.BLOCKED && (c?.acquiredPoints || 0) > 0)
    )
}

const fakeChapters = [
    {
        description:
            'Aut praesentium ipsa ratione sit. Sed consequuntur temporibus ipsa rerum rerum eveniet. Molestiae ad sed ut aliquam quia corrupti impedit id. Similique voluptatem error cumque voluptatem explicabo enim. Eveniet debitis cupiditate id nostrum est occaecati.',
        maxPoints: 250,
        totalLength: 2150,
        acquiredPoints: 0,
        color: '#FFCA30',
        name: 'Finanční produkty',
        id: 'fakeChapter1',
        lectures: [],
        status: CreationStatus.APPROVED,
        index: -9,
        icon: {
            png: '',
            svg: SvgChapterIcon
        }
    },
    {
        description:
            'Aut praesentium ipsa ratione sit. Sed consequuntur temporibus ipsa rerum rerum eveniet. Molestiae ad sed ut aliquam quia corrupti impedit id. Similique voluptatem error cumque voluptatem explicabo enim. Eveniet debitis cupiditate id nostrum est occaecati.',
        maxPoints: 250,
        totalLength: 2150,
        acquiredPoints: 0,
        color: '#FFCA30',
        name: 'Finanční produkty',
        id: 'fakeChapter2',
        lectures: [],
        status: CreationStatus.APPROVED,
        index: -9,
        icon: {
            png: '',
            svg: SvgChapterIcon
        }
    }
]

export default Home
