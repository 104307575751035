import MaterialTable, { MTableHeader, Column } from 'material-table'
import React, { ChangeEvent, useContext } from 'react'
import { useSetState } from 'react-use'
import { useSnackbar } from 'notistack'

import * as loco from '@loco'
import Row from '../../../shared/Row'
import { translateGQLError } from '@utils'
import Pagination from '../../Pagination'
import BlockOrUnblockDialog from '../../../Dialogs/Info'
import { tableIcons, headerStyle } from '../../../shared'
import { AuthContext } from '../../../../context/Auth'
import { tableLocalization, notificationMsgs } from '@variables'
import {
    Title,
    getTitle,
    ActionType,
    getButtonText,
    getDescription,
    getBlockOrUnblockOrDeleteRowIcon
} from '..'
import {
    Role,
    StudentUserFragment,
    useBlockUserMutation,
    StudentUsersDocument,
    useUnblockUserMutation,
    OrderByDirectionInput,
    useBlockAndDeleteUserMutation,
    SingnupType
} from '@graphql'
import { FacebookIcon, GoogleIcon, PlatformIcon, IconWrapper } from './styled'

type Props = {
    title: Title
    page: number
    loading: boolean
    pageSize: number
    totalCount: number
    refetch: () => void
    users: StudentUserFragment[] | []
    onChangePage: (page: number) => void
    onSearch: (searchQuery: string) => void
    onChangeItemsPerPage: (page: number) => void
    sortedColumnId: number
    sortedColumnDirection: OrderByDirectionInput
    handleSortOrderChange: (orderedColumnId: number, orderDirection: OrderByDirectionInput) => void
}

type State = {
    isOpen: boolean
    actionType: ActionType
    currentUser?: StudentUserFragment
}

const SuperadminTable = ({
    page,
    title,
    users,
    loading,
    pageSize,
    refetch,
    onSearch,
    totalCount,
    onChangePage,
    sortedColumnId,
    handleSortOrderChange,
    sortedColumnDirection,
    onChangeItemsPerPage
}: Props) => {
    const { user } = useContext(AuthContext)
    const { enqueueSnackbar } = useSnackbar()

    const [state, setState] = useSetState<State>({
        currentUser: undefined,
        actionType: undefined,
        isOpen: false
    })

    const { currentUser, isOpen } = state

    const [deleteUser, { loading: deleteLoading }] = useBlockAndDeleteUserMutation({
        onCompleted: () => refetch()
    })
    const [blockUser, { loading: blockLoading }] = useBlockUserMutation({
        refetchQueries: getRefetchQueries(currentUser)
    })
    const [unblockUser, { loading: unblockLoading }] = useUnblockUserMutation({
        refetchQueries: getRefetchQueries(currentUser)
    })

    const isLoading = blockLoading || unblockLoading || deleteLoading

    const onRowAction = (
        e: ChangeEvent<{}>,
        actionType: ActionType,
        user?: StudentUserFragment
    ) => {
        if (!user) return
        setState({ currentUser: user, isOpen: true, actionType: actionType })
    }

    const onClick = async () => {
        if (!currentUser?.id) return

        if (state.actionType === 'block') {
            const { data, errors } = await blockUser({
                variables: { where: { id: currentUser?.id } }
            })

            if (data && !errors) {
                enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
                setState({ isOpen: false })
            } else {
                errors?.forEach((err) =>
                    enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
                )
            }
        }

        if (state.actionType === 'unblock') {
            const { data, errors } = await unblockUser({
                variables: { where: { id: currentUser?.id } }
            })

            if (data && !errors) {
                enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
                setState({ isOpen: false })
            } else {
                errors?.forEach((err) =>
                    enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
                )
            }
        }

        if (state.actionType === 'delete') {
            const { data, errors } = await deleteUser({
                variables: { where: { id: currentUser?.id } }
            })

            if (data && !errors) {
                enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
                setState({ isOpen: false })
            } else {
                errors?.forEach((err) =>
                    enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
                )
            }
        }
    }

    return (
        <>
            <BlockOrUnblockDialog
                isOpen={isOpen}
                buttonProps={{
                    onClick: onClick,
                    isSubmitting: isLoading,
                    text: getButtonText(state.actionType)
                }}
                toggleOpen={() => {
                    setState({ isOpen: !isOpen, currentUser: undefined })
                }}
                data={[
                    {
                        title: getTitle(currentUser, state.actionType),
                        description: getDescription(currentUser, state.actionType)
                    }
                ]}
            />

            <MaterialTable
                page={page}
                data={users}
                title={title}
                columns={columns}
                icons={tableIcons}
                totalCount={totalCount}
                onSearchChange={onSearch}
                onChangePage={onChangePage}
                isLoading={loading || isLoading}
                localization={tableLocalization}
                onChangeRowsPerPage={onChangeItemsPerPage}
                options={{
                    pageSize: pageSize,
                    debounceInterval: 500,
                    actionsColumnIndex: -1,
                    thirdSortClick: false,
                    draggable: false
                }}
                components={{
                    Pagination: Pagination,
                    Header: (props) =>
                        (
                            <MTableHeader
                                {...props}
                                orderBy={sortedColumnId}
                                onOrderChange={handleSortOrderChange}
                                orderDirection={sortedColumnDirection}
                            />
                        ) as any
                }}
                actions={
                    user?.role === Role.SUPER_ADMIN
                        ? [
                              (blockOrUnblockRow) => {
                                  const icon = getBlockOrUnblockOrDeleteRowIcon(
                                      blockOrUnblockRow.isBlocked
                                  )

                                  return {
                                      icon: icon,
                                      position: 'row',
                                      tooltip: state.currentUser
                                          ? undefined
                                          : blockOrUnblockRow.isBlocked
                                          ? loco.common.unblock
                                          : loco.common.block,
                                      onClick: (e, rowData) =>
                                          onRowAction(
                                              e,
                                              blockOrUnblockRow.isBlocked ? 'unblock' : 'block',
                                              Array.isArray(rowData) ? undefined : rowData
                                          )
                                  }
                              },
                              (deleteUserRow) => {
                                  const icon = getBlockOrUnblockOrDeleteRowIcon()

                                  return {
                                      icon: icon,
                                      position: 'row',
                                      tooltip: state.currentUser
                                          ? undefined
                                          : loco.common.blockAndDelete,
                                      onClick: (e, rowData) =>
                                          onRowAction(
                                              e,
                                              'delete',
                                              Array.isArray(rowData) ? undefined : rowData
                                          )
                                  }
                              }
                          ]
                        : []
                }
            />
        </>
    )
}

const getRefetchQueries = (user?: StudentUserFragment) => {
    return [
        {
            query: StudentUsersDocument,
            variables: { search: user?.username }
        }
    ]
}

const columns: Column<StudentUserFragment>[] = [
    {
        sorting: true,
        field: 'username',
        headerStyle: { ...headerStyle },
        title: loco.dialogs.signup.username.label,
        render: function render(rowData: StudentUserFragment) {
            return <Row text={rowData?.username || ''} maxChars={60} />
        }
    },
    {
        field: 'email',
        sorting: true,
        headerStyle: { ...headerStyle },
        title: loco.dialogs.user.email.label,
        render: function render(rowData: StudentUserFragment) {
            return <Row text={rowData?.email || ''} maxChars={60} />
        }
    },
    {
        sorting: false,
        field: 'signupType',
        title: 'Přihlášení',
        headerStyle: { ...headerStyle },
        render: function render(rowData: StudentUserFragment) {
            let icon = <PlatformIcon />

            if (rowData.signupType === SingnupType.GOOGLE) icon = <GoogleIcon />
            if (rowData.signupType === SingnupType.FACEBOOK) icon = <FacebookIcon />

            return <IconWrapper>{icon}</IconWrapper>
        }
    }
]

export default SuperadminTable
