import React from 'react'
import Icon from '@material-ui/icons/HelpRounded'
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip'

type Props = {
    className?: string
    withHelpIcon?: boolean
    children?: React.ReactElement
}

const CustomTooltip = (props: Props & Omit<TooltipProps, 'children'>) => {
    const { title, children, style = {}, className = '', withHelpIcon = false } = props

    if (withHelpIcon || !children) {
        return (
            <div className={className} style={style}>
                <Tooltip title={title} classes={{ tooltip: 'customTooltip' }}>
                    <Icon style={{ color: '#C0C0C0' }} />
                </Tooltip>
            </div>
        )
    }

    return (
        <Tooltip
            title={title}
            style={style}
            className={className}
            classes={{ tooltip: 'customTooltip' }}
        >
            {children}
        </Tooltip>
    )
}

export default CustomTooltip
