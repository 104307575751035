import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M14.045 7.988C16.091 9.4 18.75 12.8 18.75 15.863c0 3.107-3.361 5.625-6.75 5.625s-6.75-2.518-6.75-5.625c0-3.063 2.659-6.463 4.705-7.875L8.4 4.281a.9.9 0 01.416-1.27 10.2 10.2 0 016.363 0 .9.9 0 01.421 1.27l-1.555 3.707z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M9.955 7.988h4.09M4.5 20.738c-3 0-3.75-3-3.75-5.114a7.512 7.512 0 013.58-6.136L3.066 7.665a.75.75 0 01.616-1.177H6M10.329 17.332c.44.578 1.133.905 1.858.876 1.139 0 2.063-.693 2.063-1.548 0-.855-.924-1.546-2.063-1.546-1.139 0-2.062-.693-2.062-1.548 0-.855.924-1.547 2.062-1.547a2.22 2.22 0 011.858.875M12.187 18.208v1.03M12.187 10.988v1.031M19.5 20.738c3 0 3.75-3 3.75-5.114a7.512 7.512 0 00-3.58-6.136l1.264-1.823a.75.75 0 00-.616-1.177H18"
            ></path>
        </svg>
    )
}

export default Icon
