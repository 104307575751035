import { useSetState } from 'react-use'
import React, { createContext, useContext, useEffect } from 'react'

import { AuthContext } from './Auth'
import { useDailyQuestionQuery, DailyQuestion } from '@graphql'

export const DailyQuestionContext = createContext({
    loading: true,
    isVisible: false,
    refetch: () => {},
    dailyQuestion: {}
})

type Props = {
    children: any
}

type State = {
    isVisible: boolean
    dailyQuestion: DailyQuestion | {}
}

const DailyQuestionProvider = ({ children }: Props) => {
    const { isAuthorized } = useContext(AuthContext)
    const [state, setState] = useSetState<State>({ isVisible: false, dailyQuestion: {} })

    const { refetch, data, loading } = useDailyQuestionQuery({
        skip: !isAuthorized,
        fetchPolicy: 'network-only'
    })

    useEffect(() => {
        setState({
            dailyQuestion: data?.dailyQuestion || {},
            isVisible: Boolean(data?.dailyQuestion?.question)
        })
    }, [data])

    return (
        <DailyQuestionContext.Provider value={{ ...state, refetch, loading }}>
            {children}
        </DailyQuestionContext.Provider>
    )
}

export default DailyQuestionProvider
