import styled from 'styled-components'

import { media } from '@variables'
import { colors } from '@variables'

export const Button = styled.button`
    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    display: flex;
    font-size: 12px;
    cursor: pointer;
    font-weight: 700;
    line-height: 20px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: ${colors.primary.darkBlue};
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);

    :disabled {
        pointer-events: none;
    }
`
