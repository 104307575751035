import { last } from 'lodash'
import { useSnackbar } from 'notistack'
import { GraphQLError } from 'graphql'
import Button from '@material-ui/core/Button'
import { Formik, Field, FormikProps } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import React, { useState, useContext } from 'react'

import * as loco from '@loco'
import { Routes, notificationMsgs } from '@variables'
import { translateGQLError, getRouteWithId } from '@utils'

import Head from '../../../../../components/Head'
import Loader from '../../../../../components/shared/Loader'
import Tooltip from '../../../../../components/shared/Tooltip'
import InputField from '../../../../../components/Fields/Input'
import { AuthContext } from '../../../../../context/Auth'
import RejectDialog from '../../../../../components/Dialogs/Reject'
import BackButton from '../../../../../components/shared/BackButton'
import { Box, Label } from '../../../../../components/shared/styled'
import { IconPreview, UploadWrapper, ButtonWrapper } from '../styled'
import { Container, Form, SmallContainer } from '../../../../../components/shared/styled'
import {
    Role,
    CreationStatus,
    AdminChapterQuery,
    useAdminChapterQuery,
    AdminChaptersDocument,
    useRejectChapterMutation,
    useApproveChapterMutation,
    useRejectChapterUpdateDraftMutation,
    useApproveChapterUpdateDraftMutation
} from '@graphql'

type Chapter = AdminChapterQuery['chapter']
type FormikValues = {
    name: string
    png?: string | null
    svg?: string | null
    description: string
}

const init = (chapter: Chapter): FormikValues => {
    if (chapter.status === CreationStatus.APPROVED) {
        const draft = chapter.drafts.find((d) => !d.isMerged && !d.isRejected)

        return {
            name: draft?.name || chapter.name,
            png: draft?.icon?.png || chapter.icon.png,
            svg: draft?.icon?.svg || chapter.icon.png,
            description: draft?.description || chapter.description
        }
    } else
        return {
            name: chapter.name,
            png: chapter.icon.png,
            svg: chapter.icon.svg,
            description: chapter.description
        }
}

const ReviewChapter = () => {
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()
    const params = useParams<{ readonly id: string }>()

    const { user } = useContext(AuthContext)

    const [open, setOpen] = useState(false)
    const toggleOpen = () => setOpen(!open)

    const { data, error, loading } = useAdminChapterQuery({
        skip: !params.id,
        variables: { where: { id: params.id } }
    })

    const [rejectChapter, { loading: rejectLoading }] = useRejectChapterMutation({
        refetchQueries: [{ query: AdminChaptersDocument }]
    })
    const [rejectDraft, { loading: rejectDraftLoading }] = useRejectChapterUpdateDraftMutation({
        refetchQueries: [{ query: AdminChaptersDocument }]
    })
    const [approveChapter, { loading: approverLoading }] = useApproveChapterMutation({
        refetchQueries: [{ query: AdminChaptersDocument }]
    })
    const [approveDraft, { loading: approveDraftLoading }] = useApproveChapterUpdateDraftMutation({
        refetchQueries: [{ query: AdminChaptersDocument }]
    })

    const isLoading = rejectLoading || approverLoading || approveDraftLoading || rejectDraftLoading

    if (loading) return <Loader />
    if (!data || error) {
        error && enqueueSnackbar(translateGQLError(error.message), { variant: 'error' })
        return null
    }

    const { chapter } = data

    const drafts = chapter.drafts
    const newDrafts = drafts.filter((d) => !d.isMerged && !d.isRejected)
    const lastDraft = last(newDrafts)

    const submit = async () => {
        if (chapter.status === CreationStatus.APPROVED) {
            if (newDrafts.length > 0) {
                const { data, errors } = await approveDraft({
                    variables: {
                        where: {
                            id: lastDraft?.id
                        }
                    }
                })

                if (!errors && data) {
                    enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
                    history.push({
                        pathname: Routes.REVIEW,
                        search: Routes.CHAPTERS_SEARCH_PARAM
                    })
                } else {
                    errors?.forEach((err) =>
                        enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
                    )
                }
                return
            }
        }

        const { data, errors } = await approveChapter({
            variables: { where: { id: params.id } }
        })

        if (!errors && data) {
            enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
            history.push({
                pathname: Routes.REVIEW,
                search: Routes.CHAPTERS_SEARCH_PARAM
            })
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }
    }

    const reject = async (reason: string): Promise<{ errors: GraphQLError[] | undefined }> => {
        if (chapter.status === CreationStatus.APPROVED) {
            if (newDrafts.length > 0) {
                const { data, errors } = await rejectDraft({
                    variables: {
                        where: {
                            id: lastDraft?.id
                        }
                    }
                })

                if (!errors && data) {
                    enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
                    history.push({
                        pathname: Routes.REVIEW,
                        search: Routes.CHAPTERS_SEARCH_PARAM
                    })
                } else {
                    errors?.forEach((err) =>
                        enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
                    )
                }

                return { errors }
            }
        }

        const { data, errors } = await rejectChapter({
            variables: {
                data: { reason: reason },
                where: { id: params.id }
            }
        })

        if (!errors && data) {
            enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
            history.push({
                pathname: Routes.REVIEW,
                search: Routes.CHAPTERS_SEARCH_PARAM
            })
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }

        return { errors }
    }

    const initValues = init(chapter)
    const isSameAuthor =
        chapter.status === CreationStatus.APPROVED && lastDraft?.id
            ? lastDraft.author.id === user?.id
            : chapter.author.id === user?.id

    return (
        <>
            <Head title={loco.seo.chapter.review.title} />

            <RejectDialog
                open={open}
                reject={reject}
                close={toggleOpen}
                title={loco.dialogs.reject.chapter}
            />

            <Container>
                <SmallContainer>
                    <BackButton
                        position="relative"
                        style={{
                            margin: '40px 0 20px 0',
                            fontWeight: 400,
                            fontSize: 18
                        }}
                        onClick={() => {
                            history.push(Routes.REVIEW)
                        }}
                    >
                        {loco.admin.review.chapter.title}
                    </BackButton>

                    <Box>
                        <Formik onSubmit={submit} initialValues={initValues}>
                            {(props: FormikProps<FormikValues>) => {
                                const { values, isSubmitting } = props
                                const { png, svg, description, name } = values

                                return (
                                    <Form spellCheck={false}>
                                        <Field
                                            disabled
                                            fullWidth
                                            multiline
                                            key="name"
                                            name="name"
                                            value={name}
                                            component={InputField}
                                            className="customInput"
                                            label={loco.admin.create.chapter.name.label}
                                        />
                                        <Field
                                            disabled
                                            fullWidth
                                            multiline
                                            key="description"
                                            name="description"
                                            value={description}
                                            component={InputField}
                                            className="customInput"
                                            label={loco.admin.create.chapter.description.label}
                                        />

                                        <UploadWrapper>
                                            <div>
                                                <Label>{loco.admin.create.chapter.icon.png}</Label>
                                                <IconPreview>
                                                    <img
                                                        src={png || ''}
                                                        loading="lazy"
                                                        alt={loco.admin.create.chapter.icon.png}
                                                    />
                                                </IconPreview>
                                            </div>
                                            <div>
                                                <Label>{loco.admin.create.chapter.icon.svg}</Label>
                                                <IconPreview>
                                                    <img
                                                        src={svg || ''}
                                                        loading="lazy"
                                                        alt={loco.admin.create.chapter.icon.svg}
                                                    />
                                                </IconPreview>
                                            </div>
                                        </UploadWrapper>

                                        <ButtonWrapper>
                                            {user?.role !== Role.SUPER_ADMIN && isSameAuthor && (
                                                <Tooltip
                                                    withHelpIcon
                                                    title={loco.tooltips.sameAuthor}
                                                />
                                            )}
                                            <div style={{ marginLeft: 'auto' }}>
                                                <Button
                                                    size="large"
                                                    type="button"
                                                    color="secondary"
                                                    variant="outlined"
                                                    onClick={toggleOpen}
                                                    disabled={
                                                        isLoading ||
                                                        isSubmitting ||
                                                        (isSameAuthor &&
                                                            user?.role !== Role.SUPER_ADMIN)
                                                    }
                                                >
                                                    {loco.common.reject}
                                                </Button>
                                                <Button
                                                    size="large"
                                                    type="button"
                                                    color="primary"
                                                    variant="contained"
                                                    style={{ marginLeft: 30 }}
                                                    onClick={() => {
                                                        history.push(
                                                            getRouteWithId(
                                                                Routes.CHAPTER_EDIT,
                                                                chapter.id
                                                            )
                                                        )
                                                    }}
                                                >
                                                    {loco.common.edit}
                                                </Button>
                                                <Button
                                                    size="large"
                                                    type="submit"
                                                    color="primary"
                                                    variant="contained"
                                                    style={{ marginLeft: 30 }}
                                                    disabled={
                                                        isLoading ||
                                                        isSubmitting ||
                                                        (isSameAuthor &&
                                                            user?.role !== Role.SUPER_ADMIN)
                                                    }
                                                >
                                                    {loco.common.approve}
                                                </Button>
                                            </div>
                                        </ButtonWrapper>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Box>
                </SmallContainer>
            </Container>
        </>
    )
}

export default ReviewChapter
