import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { Accordion, AccordionSummary } from '@material-ui/core'

import OpenIco from './Open'
import CloseIco from './Close'
import { Wrapper, Divider, AccordionDetails } from './styled'

type Props = {
    text: string
    title: string
    isLast?: boolean
    defaultExpanded?: boolean
}

const Panel = ({ title, text, defaultExpanded, isLast }: Props) => {
    const [expanded, setExpanded] = useState('')

    const onChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : '')
    }

    return (
        <Wrapper>
            <Accordion
                onChange={onChange(title)}
                expanded={expanded === title}
                style={{ backgroundColor: 'white' }}
                defaultExpanded={Boolean(defaultExpanded)}
                TransitionProps={{ unmountOnExit: true }}
            >
                <AccordionSummary expandIcon={expanded === title ? <CloseIco /> : <OpenIco />}>
                    <Typography
                        variant="h5"
                        style={{ paddingRight: 20, fontSize: 18 }}
                        dangerouslySetInnerHTML={{ __html: title }}
                    ></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography dangerouslySetInnerHTML={{ __html: text }}></Typography>
                </AccordionDetails>
            </Accordion>
            {!isLast && <Divider />}
        </Wrapper>
    )
}

export default Panel
