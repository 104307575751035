import React, { CSSProperties, useContext, ChangeEvent } from 'react'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'

import * as loco from '@loco'
import Tooltip from '../../../shared/Tooltip'
import CompleteIcon from '../../../SVG/Complete'
import { colors, Routes } from '@variables'
import CoinsIcon from '../../../SVG/Coins'
import NotAllowed from '../../../shared/NotAllowed'
import { getRouteWithId, truncateText } from '@utils'
import { AuthContext } from '../../../../context/Auth'
import {
    Title,
    Wrapper,
    ImgWrapper,
    StartButton,
    ContentBottom,
    ContentWrapper,
    CoinsWrapper,
    CompleteIconWrapper
} from './styled'

type Props = {
    id: string
    idx: number
    name: string
    isBlocked: boolean
    style?: CSSProperties
    maxPoints?: number | null
    videoPreview?: string | null
    acquiredPoints?: number | null
}

const LecturePreview = ({
    id,
    idx,
    name,
    isBlocked,
    maxPoints,
    style = {},
    videoPreview,
    acquiredPoints
}: Props) => {
    const history = useHistory()
    const { isAuthorized, user } = useContext(AuthContext)

    const isBlurred = (!isAuthorized || Boolean(user?.isTemporary)) && idx > 0

    const onClick = (e: ChangeEvent<{}>) => {
        if (isBlurred) return
        return history.push(getRouteWithId(Routes.LECTURE, id))
    }

    return (
        <Wrapper
            role="button"
            style={style}
            onClick={onClick}
            blurred={isBlurred}
            isBlocked={isBlocked}
        >
            {isBlocked && <NotAllowed variant="lecture" />}

            <ImgWrapper>
                {videoPreview && <img src={videoPreview} alt="Chapter image" loading="lazy" />}
            </ImgWrapper>

            <ContentWrapper>
                <Title>
                    <Tooltip title={name.length > 20 ? name : ''}>
                        <Typography variant="h6">{truncateText(name, 20)} </Typography>
                    </Tooltip>
                    {(acquiredPoints || 0) > 0 && (
                        <Tooltip title={loco.tooltips.lecture.completed}>
                            <CompleteIconWrapper>
                                <CompleteIcon />
                            </CompleteIconWrapper>
                        </Tooltip>
                    )}
                </Title>

                <ContentBottom>
                    <CoinsWrapper>
                        <CoinsIcon
                            color={
                                Boolean(acquiredPoints)
                                    ? colors.primary.orange
                                    : colors.primary.darkBlue
                            }
                        />
                        <span>
                            {acquiredPoints || 0}/{maxPoints || 0}
                        </span>
                    </CoinsWrapper>

                    <StartButton>{loco.common.open}</StartButton>
                </ContentBottom>
            </ContentWrapper>
        </Wrapper>
    )
}

export default LecturePreview
