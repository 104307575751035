import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="240"
            height="240"
            fill="none"
            viewBox="0 0 240 240"
            {...props}
        >
            <mask id="mask0" width="240" height="240" x="0" y="0" maskUnits="userSpaceOnUse">
                <circle cx="120" cy="120" r="120" fill="#C4C4C4"></circle>
            </mask>
            <g mask="url(#mask0)">
                <circle cx="120" cy="120" r="120" fill="#fff"></circle>
                <path
                    fill="#454545"
                    d="M68.87 155.125l-28.206.045a2.777 2.777 0 01-2.778-2.76l-.03-16.729a2.774 2.774 0 012.763-2.774l28.205-.046a2.778 2.778 0 012.778 2.761l.03 16.728a2.763 2.763 0 01-2.763 2.775z"
                ></path>
                <path
                    fill="#454545"
                    d="M59.553 138.382H49.18a1.198 1.198 0 01-1.193-1.191v-8.614a1.192 1.192 0 011.193-1.191h10.373a1.194 1.194 0 011.192 1.191v8.613a1.183 1.183 0 01-1.192 1.192zm-9.18-2.383h7.972v-6.215h-7.972v6.215z"
                ></path>
                <path fill="#F59100" d="M127.701 126.841h45.736v36.553h-45.736v-36.553z"></path>
                <path fill="#fff" d="M150.551 127.856h43.928v34.746h-43.928v-34.746z"></path>
                <path
                    fill="#F59100"
                    d="M149.539 163.614h45.952v-36.77h-45.952v36.77zm43.928-2.024h-41.904v-32.721h41.904v32.721z"
                ></path>
                <path
                    fill="#fff"
                    d="M194.497 162.602h30.135v51.665h-30.135v-51.665zM48.276 187.585h66.206v62.069H48.275v-62.069z"
                ></path>
                <path
                    fill="#F59100"
                    d="M193.593 215.172h31.942v-53.474h-31.942v53.474zm30.133-1.807H195.4v-49.857h28.328l-.002 49.857zM35.862 246.575h84.356v-53.473H35.862v53.473zm82.547-1.807h-80.74v-49.857h80.742l-.002 49.857z"
                ></path>
                <path fill="#fff" d="M128.605 151.936h43.927v34.745h-43.927v-34.745z"></path>
                <path
                    fill="#F59100"
                    d="M127.701 187.584h45.736v-36.553h-45.736v36.553zm43.928-1.807h-42.12v-32.938h42.12v32.938z"
                ></path>
                <path fill="#fff" d="M150.551 127.856h43.928v113.997h-43.928V127.856z"></path>
                <path
                    fill="#F59100"
                    d="M149.647 239.999h45.736V126.953h-45.736v113.046zm43.928-1.807h-42.12V128.761h42.12v109.431zM103.044 151.936h34.744v41.641h-34.744v-41.641zM37.24 194.481h23.448v52.414H37.24v-52.414z"
                ></path>
                <path
                    fill="#F59100"
                    d="M102.14 194.481h36.552v-43.45H102.14v43.45zm34.745-1.807h-32.937v-39.835h32.937v39.835z"
                ></path>
                <path fill="#fff" d="M120.435 151.936h30.134v98.193h-30.134v-98.193z"></path>
                <path
                    fill="#F59100"
                    d="M119.531 256.55h31.942V151.031h-31.942V256.55zm30.133-1.807h-28.326V152.839h28.326v101.904z"
                ></path>
                <path
                    fill="#FDC068"
                    d="M59.911 127.698l-1.918 2.094a3.916 3.916 0 01-5.298.437 3.894 3.894 0 01-.836-5.235l1.819-2.721 6.233 5.425z"
                ></path>
                <path
                    fill="url(#paint0_linear)"
                    d="M53.679 122.275l9.325-13.9c.223-.332.503-.622.827-.857l.28-.198 16.883-12.037a5.424 5.424 0 018.426 3.173 5.388 5.388 0 01-2.127 5.615l-16.882 12.037 1.108-1.056-11.624 12.647-6.216-5.424z"
                ></path>
                <path
                    fill="#FDC068"
                    d="M97.674 87.302l.513 1.137a.453.453 0 01-.247.594l-.81.198"
                ></path>
                <path
                    fill="#000046"
                    d="M97.13 89.411a.18.18 0 01-.182-.132.178.178 0 01.02-.135.177.177 0 01.112-.079l.794-.198a.26.26 0 00.156-.246.26.26 0 00-.024-.1l-.512-1.137a.187.187 0 01.1-.248.19.19 0 01.247.1l.513 1.137a.647.647 0 01-.347.84l-.033.017-.81.198c-.001-.017-.018-.017-.034-.017z"
                ></path>
                <path
                    fill="#FDC068"
                    d="M91.856 93.37l-3.637-1.056a3.557 3.557 0 01-2.126-1.691 3.536 3.536 0 01-.305-2.695l1.588-5.474a5.675 5.675 0 012.72-3.382 5.686 5.686 0 017.713 2.238 5.66 5.66 0 01.479 4.31l-1.455 5.028a3.996 3.996 0 01-1.923 2.39 4.017 4.017 0 01-3.054.331z"
                ></path>
                <path
                    fill="#000046"
                    d="M98.023 86.51l-.348 1.22a.647.647 0 01-.58-.674.644.644 0 01.928-.546z"
                ></path>
                <path
                    fill="#FDC068"
                    d="M87.176 98.744l-4.183-3.067 3.505-10.206 5.804 1.681-2.497 10.635a1.66 1.66 0 01-1.053 1.192 1.67 1.67 0 01-1.576-.235z"
                ></path>
                <path fill="#000046" d="M90.912 93.106l-2.364-.693 1.802 3.067.562-2.374z"></path>
                <path
                    fill="url(#paint1_linear)"
                    d="M91.987 114.689c1.24-5.326-2.48-5.936-.231-9.184 2.248-3.248 1.802-8.64-2.298-9.597-3.158-.742-3.34-.923-4.68-1.22-4.216-.99-8.118 2.622-7.853 6.925a7.039 7.039 0 01-.199 2.177c-.314 1.203-3.687 2.275-4.546 5.936l19.807 4.963z"
                ></path>
                <path
                    fill="url(#paint2_linear)"
                    d="M87.87 114.756c-.132 2.984-.463 5.952-.86 8.903-.396 2.952-.86 5.887-1.389 8.821a255.974 255.974 0 01-1.752 8.756l-.496 2.177c-.166.725-.331 1.451-.513 2.176-.33 1.451-.629 2.919-.943 4.37-1.29 5.837-2.678 11.642-4.233 17.445a287.087 287.087 0 01-2.447 8.689c-.43 1.451-.86 2.886-1.322 4.321-.463 1.436-.926 8.219-1.439 9.654L62 190l5.022-42.234c.215-1.484.397-2.968.628-4.436l.711-4.435 1.422-8.854 2.414-20.314 15.674 5.029z"
                ></path>
                <path
                    fill="url(#paint3_linear)"
                    d="M72.18 109.727c-.662 2.803 1.306 7.7 6.2 8.888l3.985.824c4.464 1.072 8.994-2.061 9.705-5.079l-4.414-3.182-15.476-1.451z"
                ></path>
                <path
                    fill="#000046"
                    d="M80.496 193.708l-16.799-.065a1.618 1.618 0 01-1.125-.466 1.604 1.604 0 01-.478-1.117l-.05-2.111 10.615.148 8.035 2.012a.815.815 0 01.613.888.809.809 0 01-.811.711z"
                ></path>
                <path
                    fill="url(#paint4_linear)"
                    d="M80.15 113.766l.38-.198.38-.181.76-.363 1.555-.676c.512-.231 1.041-.429 1.554-.643l1.572-.61c2.1-.808 4.216-1.55 6.365-2.242 2.133-.709 4.299-1.336 6.448-1.963 2.166-.593 4.349-1.17 6.548-1.714l1.306-.314a7.25 7.25 0 016.994 2.073 7.202 7.202 0 011.62 7.095c-.811 2.572-10.55 28.773-11.789 31.246l-10.136-3.117 6.895-31.411 10.035 8.953-25.016 4.865-5.472-10.8z"
                ></path>
                <path
                    fill="#000046"
                    d="M96.253 118.596c1.571.808 3.108 1.897 6.415.891s12.979-5.458 12.979-5.458.331-2.77.133-3.858c-.214-1.088-19.527 8.425-19.527 8.425zM110.307 150.997l-16.054-4.881a1.62 1.62 0 01-1.092-1.979l.58-2.044 10.118 3.182 7.109 4.238a.82.82 0 01.326 1.033.827.827 0 01-.987.451z"
                ></path>
                <path
                    fill="url(#paint5_linear)"
                    d="M114.424 112.512l-15.179 5.937a4.744 4.744 0 01-4.811-.825l-.314-.263-12.812-11.065a5.41 5.41 0 01-1.855-3.697 5.391 5.391 0 011.31-3.921 5.425 5.425 0 017.64-.543l12.813 11.063-5.11-1.088 14.782-3.232 3.536 7.634z"
                ></path>
                <path
                    fill="#FDC068"
                    d="M110.886 104.895l3.935-.858a3.92 3.92 0 012.817.441 3.888 3.888 0 011.543 5.067 3.906 3.906 0 01-2.095 1.929l-2.662 1.039-3.538-7.618z"
                ></path>
                <path
                    fill="#000046"
                    d="M98.386 85.29l-3.191.181-.43 1.715-1.637.297-2.513 2.259a3.528 3.528 0 01-3.72.627 1.762 1.762 0 01-1.058-1.517l-.05-1.17 1.802-5.87a5.26 5.26 0 011.951-2.729 5.284 5.284 0 013.208-.999l.826.017a5.275 5.275 0 014.548 2.787l.034.049a5.393 5.393 0 01.231 4.353h-.001z"
                ></path>
                <path
                    fill="#000046"
                    d="M97.344 85.34l1.984-.083a2.513 2.513 0 002.348-1.912l.182-.726a2.54 2.54 0 00-.926-2.622 2.55 2.55 0 00-2.447-.347l-2.232.873 1.091 4.816z"
                ></path>
                <path
                    fill="#FDC068"
                    d="M93.096 88.654a.735.735 0 01-.546-.874l.446-1.88a.733.733 0 01.876-.544.735.735 0 01.545.873l-.446 1.88a.72.72 0 01-.876.545z"
                ></path>
                <path
                    fill="#000046"
                    d="M83.126 125.028a4.765 4.765 0 01-3.422-1.468.244.244 0 01.173-.417c.065 0 .128.025.174.071a4.233 4.233 0 003.472 1.286l2.1-.181a.228.228 0 01.097.012c.03.01.06.026.085.048a.245.245 0 01.023.351.238.238 0 01-.172.083l-2.1.182a1.824 1.824 0 01-.43.033zM82.645 107.451s-1.008 7.783 10.202 8.805l-10.201-8.805zM97.708 124.351a.245.245 0 01-.231-.165.25.25 0 01.148-.313l4.531-1.533a.243.243 0 01.308.15.242.242 0 01-.143.311l-4.53 1.534a.148.148 0 01-.083.016zM72.064 110.435a.158.158 0 01-.082-.017.231.231 0 01-.084-.048.235.235 0 01-.065-.265c.579-1.649 2.315-3.611 3.34-4.782.297-.33.545-.627.694-.808a.245.245 0 01.163-.096.246.246 0 01.234.393c-.166.198-.413.478-.711.841-1.008 1.137-2.695 3.05-3.24 4.616a.297.297 0 01-.248.166zM92.847 102.224a.237.237 0 01-.165-.067l-2.034-1.748a.245.245 0 01-.043-.355.248.248 0 01.358-.024l2.033 1.748a.253.253 0 01.033.346.233.233 0 01-.079.072.232.232 0 01-.103.028zM97.56 108.044a.262.262 0 01-.248-.197.245.245 0 01.182-.297l1.72-.38a.253.253 0 01.18.035.248.248 0 01-.064.444l-1.72.379a.068.068 0 01-.023.014.05.05 0 01-.028.002zM66.938 148.656a.24.24 0 01-.198-.099.256.256 0 01.05-.346l2.546-1.847a.24.24 0 01.183-.047.247.247 0 01.114.443l-2.546 1.846a.266.266 0 01-.149.05z"
                ></path>
                <path
                    fill="#FD8945"
                    d="M169.469 101.782v25.878h-3.351a13.094 13.094 0 01-4.506-.795 13.136 13.136 0 01-3.189-1.699l-.529-.404a13.495 13.495 0 01-1.737-1.685l-.014-.013c-.109-.135-.217-.256-.312-.391a12.915 12.915 0 01-1.641-2.723 12.207 12.207 0 01-.936-3.113c-.027-.135-.041-.27-.068-.404a11.82 11.82 0 01-.108-1.712c0-.337.013-.674.04-1.011l.041-.404c.101-.922.301-1.83.597-2.709.027-.068.041-.135.068-.202a1.21 1.21 0 00.068-.203c.271-.724.607-1.424 1.004-2.089.055-.081.109-.175.163-.256.027-.054.068-.094.095-.148a12.424 12.424 0 011.682-2.076l.055-.054c.122-.121.245-.243.367-.351a13.086 13.086 0 013.039-2.089c.298-.148.597-.283.909-.404a13.072 13.072 0 014.885-.943h3.378z"
                ></path>
                <path
                    fill="#FFF6BB"
                    d="M162.51 107.847v2.546h-8.671a1.2 1.2 0 00.068-.202c.271-.724.607-1.423 1.004-2.088.055-.081.108-.175.163-.256h7.436z"
                ></path>
                <path
                    fill="#EA7A45"
                    d="M170.176 123.062v4.583c-.231.014-.475.014-.706.014h-3.352a13.135 13.135 0 01-4.505-.796 13.14 13.14 0 01-3.19-1.698l-.529-.404a13.693 13.693 0 01-1.737-1.685l-.013-.014h14.032zM166.322 102.926a.207.207 0 01-.204.202h-5.794c.299-.148.597-.283.909-.404h4.885a.207.207 0 01.204.202zM163.27 105.421a.207.207 0 01-.204.202h-6.215l.055-.055c.122-.121.244-.242.366-.35h5.78a.211.211 0 01.218.203zM160.215 107.901a.205.205 0 01-.061.141.212.212 0 01-.143.061h-5.088c.055-.081.108-.176.163-.256.027-.055.067-.095.095-.149h4.83a.2.2 0 01.122.041.184.184 0 01.068.108.079.079 0 01.014.054zM159.619 110.393a.207.207 0 01-.204.202h-5.644c.027-.068.04-.135.068-.202.028-.066.051-.133.067-.203h5.496c.056-.001.11.02.15.057.041.038.065.09.067.146zM159.619 113.507a.207.207 0 01-.203.202h-6.282l.04-.404h6.242a.207.207 0 01.203.202zM159.619 116.634a.207.207 0 01-.204.202h-6.147c-.027-.135-.041-.27-.068-.404h6.215a.21.21 0 01.143.06.216.216 0 01.061.142zM159.619 119.747a.207.207 0 01-.204.202h-5.21c-.068-.134-.122-.269-.177-.404h5.387a.207.207 0 01.204.202z"
                ></path>
                <path
                    fill="#EA7A45"
                    d="M160.419 122.874a.203.203 0 01-.136.189.119.119 0 01-.067.013h-4.044l-.014-.013c-.108-.135-.217-.256-.311-.391h4.369a.207.207 0 01.203.202zM163.066 124.963a.216.216 0 01-.061.142.21.21 0 01-.143.06h-4.424l-.529-.404h4.953a.21.21 0 01.143.06.216.216 0 01.061.142zM167 127.065a.21.21 0 01-.204.203h-3.867a11.58 11.58 0 01-1.317-.405h5.184a.21.21 0 01.204.202z"
                ></path>
                <path
                    fill="#FDE545"
                    d="M182.497 114.72c.003.505-.029 1.009-.095 1.509a12.903 12.903 0 01-3.269 7.161 13.054 13.054 0 01-6.84 3.959c-.697.153-1.405.252-2.117.296-.231.014-.475.014-.706.014a13.086 13.086 0 01-3.189-.391 13.143 13.143 0 01-4.506-2.103l-.529-.404a13.625 13.625 0 01-1.737-1.685l-.013-.013c-.109-.135-.218-.256-.312-.391a12.873 12.873 0 01-1.641-2.723c-.055-.134-.122-.269-.177-.404a12.15 12.15 0 01-.759-2.709c-.028-.135-.041-.27-.068-.404a11.804 11.804 0 01-.109-1.712c0-.336.014-.673.041-1.01l.041-.405c.1-.921.3-1.83.597-2.709.027-.067.04-.135.068-.202.027-.067.04-.135.067-.202.271-.725.608-1.424 1.005-2.089.055-.081.108-.175.162-.256.027-.055.068-.095.095-.149a12.423 12.423 0 011.683-2.075c.136-.135.271-.27.422-.405a12.838 12.838 0 013.052-2.089 13.123 13.123 0 0113.08.863 12.972 12.972 0 014.223 4.652 12.881 12.881 0 011.531 6.076z"
                ></path>
                <path
                    fill="#FDB645"
                    d="M169.47 124.034c5.179 0 9.377-4.17 9.377-9.314 0-5.143-4.198-9.313-9.377-9.313-5.178 0-9.376 4.17-9.376 9.313 0 5.144 4.198 9.314 9.376 9.314z"
                ></path>
                <path
                    fill="#FDE545"
                    d="M185.963 90.305l.164.4a6.06 6.06 0 001.393 2.082 6.218 6.218 0 002.13 1.36l.409.16a.52.52 0 01.341.481.52.52 0 01-.341.48l-.409.162a6.218 6.218 0 00-2.13 1.361 6.06 6.06 0 00-1.393 2.082l-.164.4a.51.51 0 01-.192.241.534.534 0 01-.791-.241l-.164-.402a6.043 6.043 0 00-1.393-2.08 6.223 6.223 0 00-2.129-1.36l-.41-.161a.51.51 0 01-.247-.772.524.524 0 01.247-.189l.41-.16a6.23 6.23 0 002.13-1.362 6.06 6.06 0 001.392-2.082l.164-.4a.516.516 0 01.492-.333c.107 0 .211.032.299.091a.51.51 0 01.192.242zM177.461 87.857l.122.292c.227.57.574 1.087 1.016 1.52.443.432.972.77 1.555.993l.299.118a.39.39 0 01.179.138.369.369 0 010 .425.391.391 0 01-.179.138l-.299.118a4.551 4.551 0 00-1.555.994 4.43 4.43 0 00-1.016 1.52l-.122.291a.39.39 0 01-.717 0l-.121-.292a4.43 4.43 0 00-1.016-1.52 4.55 4.55 0 00-1.555-.993l-.299-.118a.369.369 0 01-.179-.563.385.385 0 01.179-.138l.299-.118a4.536 4.536 0 001.555-.993c.443-.433.789-.95 1.017-1.52l.121-.292a.377.377 0 01.141-.174.39.39 0 01.434 0 .37.37 0 01.141.174z"
                ></path>
                <path
                    fill="#E8E993"
                    d="M135.72 83.639c-.112-.604-1.142-5.967.963-8.828l1.814 1.32c-1.276 1.764-.85 5.698-.56 7.083l-2.217.425z"
                ></path>
                <path
                    fill="#98C2FE"
                    d="M125.959 117.852l-1.611-1.542.806.782-.806-.783c.022-.022 1.88-2.011 1.589-4.581l2.216-.246c.403 3.621-2.082 6.258-2.194 6.37zM145.972 53.202l-1.612-1.542.806.782-.806-.782c.023-.022 1.881-2.011 1.59-4.581l2.216-.246c.403 3.62-2.082 6.257-2.194 6.369z"
                ></path>
                <path
                    fill="#C492D6"
                    d="M108.073 42.566l-2.126-.716 1.052.358-1.052-.358c0-.022.828-2.614-.538-4.804l1.903-1.185c1.947 3.107.828 6.548.761 6.705z"
                ></path>
                <path
                    fill="#FDE545"
                    d="M118.662 39.638l.091.223a3.386 3.386 0 001.925 1.922l.224.09a.289.289 0 01.184.269.286.286 0 01-.184.268l-.224.09a3.386 3.386 0 00-1.925 1.922l-.091.224a.289.289 0 01-.537 0l-.091-.224a3.398 3.398 0 00-1.925-1.922l-.224-.09a.289.289 0 01-.185-.268.287.287 0 01.185-.269l.224-.09a3.396 3.396 0 001.925-1.922l.091-.223a.297.297 0 01.427-.122c.047.029.085.071.11.122z"
                ></path>
                <path
                    fill="#F59100"
                    d="M138.092 103.282l-2.081-.805c1.902-5.006 5.887-8.067 10.096-11.308 4.119-3.173 8.372-6.458 10.633-11.776l2.059.871c-2.485 5.856-6.985 9.319-11.327 12.67-3.961 3.062-7.7 5.923-9.38 10.348zM133.089 62.99l-3.638 5.324 2.284 1.589 3.638-5.324-2.284-1.589zM57.028 62.998l-2.284 1.589 3.638 5.324 2.284-1.589-3.638-5.324zM94.017 53.023l-.528 6.445 2.765.23.528-6.445-2.765-.23z"
                ></path>
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="73.675"
                    x2="70.129"
                    y1="132.741"
                    y2="94.237"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#77879D"></stop>
                    <stop offset="0.089" stopColor="#A1978E"></stop>
                    <stop offset="0.188" stopColor="#C9A780"></stop>
                    <stop offset="0.279" stopColor="#E5B275"></stop>
                    <stop offset="0.358" stopColor="#F7B96F"></stop>
                    <stop offset="0.415" stopColor="#FDBB6D"></stop>
                    <stop offset="0.499" stopColor="#FDBB9F"></stop>
                    <stop offset="0.599" stopColor="#D1D2D1"></stop>
                    <stop offset="1" stopColor="#FECEAA"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="3001.77"
                    x2="3001.77"
                    y1="-10532.3"
                    y2="-14341.6"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#77879D"></stop>
                    <stop offset="0.089" stopColor="#A1978E"></stop>
                    <stop offset="0.188" stopColor="#C9A780"></stop>
                    <stop offset="0.279" stopColor="#E5B275"></stop>
                    <stop offset="0.358" stopColor="#F7B96F"></stop>
                    <stop offset="0.415" stopColor="#FDBB6D"></stop>
                    <stop offset="0.499" stopColor="#FDBB9F"></stop>
                    <stop offset="0.599" stopColor="#D1D2D1"></stop>
                    <stop offset="1" stopColor="#FECEAA"></stop>
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="74.973"
                    x2="74.973"
                    y1="185.042"
                    y2="112.678"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6B9C83"></stop>
                    <stop offset="0.401" stopColor="#A6A6A4"></stop>
                    <stop offset="0.501" stopColor="#9F9FC8"></stop>
                    <stop offset="0.591" stopColor="#9FCCEC"></stop>
                    <stop offset="1" stopColor="#FFE7B0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint3_linear"
                    x1="2823.32"
                    x2="2823.32"
                    y1="-3010.01"
                    y2="-6218.73"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6B9C83"></stop>
                    <stop offset="0.401" stopColor="#A6A6A4"></stop>
                    <stop offset="0.501" stopColor="#9F9FC8"></stop>
                    <stop offset="0.591" stopColor="#9FCCEC"></stop>
                    <stop offset="1" stopColor="#FFE7B0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint4_linear"
                    x1="7555.12"
                    x2="7555.12"
                    y1="-12660.6"
                    y2="-25790.1"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6B9C83"></stop>
                    <stop offset="0.401" stopColor="#A6A6A4"></stop>
                    <stop offset="0.501" stopColor="#9F9FC8"></stop>
                    <stop offset="0.591" stopColor="#9FCCEC"></stop>
                    <stop offset="1" stopColor="#FFE7B0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint5_linear"
                    x1="7205.13"
                    x2="7205.13"
                    y1="-11490.2"
                    y2="-15638.1"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#77879D"></stop>
                    <stop offset="0.089" stopColor="#A1978E"></stop>
                    <stop offset="0.188" stopColor="#C9A780"></stop>
                    <stop offset="0.279" stopColor="#E5B275"></stop>
                    <stop offset="0.358" stopColor="#F7B96F"></stop>
                    <stop offset="0.415" stopColor="#FDBB6D"></stop>
                    <stop offset="0.499" stopColor="#FDBB9F"></stop>
                    <stop offset="0.599" stopColor="#D1D2D1"></stop>
                    <stop offset="1" stopColor="#FECEAA"></stop>
                </linearGradient>
            </defs>
        </svg>
    )
}

export default Icon
