import styled from 'styled-components'
import { Form as FormikForm } from 'formik'
import Typography from '@material-ui/core/Typography'

export const Description = styled(Typography)``

export const Form = styled(FormikForm)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    ${Description} {
        width: 100%;
        margin: 15px 0 0 0;
    }
`
