import styled from 'styled-components'

import { colors } from '@variables'

/* https://stackoverflow.com/a/22018710 */
export const Wrapper = styled.div<{ readonly variant: 'horizontal' | 'vertical' }>`
    width: 74px;
    height: 74px;
    display: flex;
    flex: 1 0 74px;
    padding: 7px;
    max-width: 74px;
    cursor: pointer;
    border-radius: 4px;
    margin: 0 0 16px 0;
    align-items: center;
    justify-content: center;
    background: ${colors.primary.gray2};

    @media (max-width: 370px) {
        width: 60px;
        height: 60px;
        padding: 7px;
        flex: 1 0 60px;
        max-width: 60px;
    }

    ${(props) =>
        props.variant === 'horizontal' &&
        `
        margin: 0 16px 16px 0;
    `}

    @media (max-width: 1090px) and (min-width: 960px) {
        ${(props) =>
            props.variant === 'vertical' &&
            `
                width: 60px;
                height: 60px;
                padding: 7px;
                flex: 1 0 60px;
                max-width: 60px;
        `}
    }

    img {
        width: 100%;
        height: auto;
    }

    &:empty {
        cursor: default;
        user-select: none;
        height: 0;
    }
`
