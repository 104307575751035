import React, { forwardRef } from 'react'
import { isUndefined } from 'lodash'
import UnblockIcon from '@material-ui/icons/HowToRegRounded'
import BlockIcon from '@material-ui/icons/BlockRounded'
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined'

import * as loco from '@loco'
import { getProfileName } from '@utils'
import { Role, AdminUserFragment, AdminUsersDocument, StudentUserFragment } from '@graphql'

export type UserType = AdminUserFragment
export type UsersType = AdminUserFragment[]

export const enum Title {
    ADMIN = 'Administrátoři',
    STUDENT = 'Uživatelé'
}

export type ActionType = 'block' | 'unblock' | 'delete' | undefined

export const getBlockOrUnblockOrDeleteRowIcon = (isBlocked?: boolean) => {
    if (isUndefined(isBlocked)) {
        return forwardRef(function icon(props, ref: any) {
            return <DeleteIcon style={{ color: '#666666' }} ref={ref} {...props} />
        }) as any
    }

    if (isBlocked) {
        return forwardRef(function icon(props, ref: any) {
            return <UnblockIcon style={{ color: '#666666' }} ref={ref} {...props} />
        }) as any
    }

    return forwardRef(function icon(props, ref: any) {
        return <BlockIcon style={{ color: '#666666' }} ref={ref} {...props} />
    }) as any
}

export const getTitle = (
    user?: UserType | StudentUserFragment,
    actionType?: ActionType
): string => {
    if (!user) return ''

    const nickname: string = getProfileName(user)

    if (actionType === 'delete') return `${loco.dialogs.common.blockAndDelete.title} ${nickname}?`
    if (user.isBlocked) return `${loco.dialogs.common.unblock.title} ${nickname}?`
    return `${loco.dialogs.common.block.title} ${nickname}?`
}

export const getDescription = (
    user?: UserType | StudentUserFragment,
    actionType?: ActionType
): string => {
    if (!user) return ''

    const nickname: string = getProfileName(user)

    if (actionType === 'delete') {
        return `${loco.dialogs.common.blockAndDelete.description} ${nickname}`
    }
    if (user.isBlocked) return `${loco.dialogs.common.unblock.cancel} ${nickname}.`
    return `${loco.dialogs.common.block.if} ${nickname} ${loco.dialogs.common.block.ending}`
}

export const getButtonText = (actionType: ActionType): string => {
    if (actionType === 'delete') return loco.common.blockAndDelete
    if (actionType === 'block') return loco.common.block
    return loco.common.unblock
}

export const getRefetchQueries = (u?: UserType | null) => {
    if (u?.role === Role.ADMIN) {
        return [
            {
                query: AdminUsersDocument,
                variables: { search: u.username }
            }
        ]
    }
    return undefined
}
