import styled from 'styled-components'
import { Button as MuiButton, Typography as MuiTypography } from '@material-ui/core'
import { Link as RLink } from 'react-router-dom'

import { colors, media } from '@variables'

export const Button = styled(MuiButton)``
export const Title = styled(MuiTypography)``
export const Description = styled(MuiTypography)``

export const Link = styled(RLink)`
    @media (max-width: 539px) {
        width: 100%;
    }
    ${media.custom(540)} {
        margin-left: auto;
    }
`

export const Wrapper = styled.div`
    width: 100%;
    border-radius: 5px;
    background-color: ${colors.primary.orange};
`

export const Inner = styled.div`
    width: 100%;
    color: white;
    display: flex;
    max-width: 420px;
    padding: 15px 25px;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    margin: 35px auto 0 auto;
    justify-content: flex-start;

    ${media.custom(540)} {
        max-width: 100%;
        margin: 35px 0 0 0;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    ${Title} {
        font-size: 16px;
        text-transform: unset;
    }
    ${Description} {
        margin: 0;
    }
    ${Button} {
        @media (max-width: 539px) {
            width: 100%;
        }
    }
`

export const Text = styled.div`
    width: 100%;
    padding: 20px 0;
    max-width: 340px;

    ${media.custom(540)} {
        padding: 0 20px 0 35px;
    }
`
