import styled from 'styled-components'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiContainer from '@material-ui/core/Container'

import { Title3, Title1 } from '../styled'
import { colors, media, transition } from '@variables'
import RhombusImg from '@static/images/rhombus-dark.svg'

export const Text = styled(Typography)``
export const Button = styled(MuiButton)``
export const Container = styled(MuiContainer)``

export const Title = styled(Title1)`
    text-align: center;
    max-width: 340px;
    margin: 0 auto !important;

    ${media.desktop} {
        margin: 0 !important;
        text-align: left;
    }
`

export const TopTitle = styled(Title3)`
    text-align: center;
    padding-bottom: 20px;

    ${media.desktop} {
        text-align: left;
    }
`

export const Wrapper = styled.div`
    width: 100%;
    padding: 36px 0 90px 0;
    background-color: white;
    color: ${colors.primary.darkBlue};

    ${media.tablet} {
        padding: 100px 0 180px 0;
    }

    ${Container} {
        position: relative;

        :after {
            right: 20px;
            bottom: -104px;
            position: absolute;

            content: '';
            width: 57px;
            height: 57px;
            display: block;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url(${RhombusImg});

            ${media.tablet} {
                right: 0;
                bottom: -236px;
                width: 113px;
                height: 113px;
            }
        }
    }
`

export const Wrap = styled.div`
    display: flex;
    flex-direction: column;

    ${media.desktop} {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }

    ${Text} {
        margin: 0;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.5px;

        ${media.desktop} {
            font-size: 18px;
            line-height: 28px;
            text-align: left;
        }
    }

    ${Button} {
        padding: 12px;

        span {
            letter-spacing: initial;
        }

        ${media.phone} {
            padding: 12px 30px;
        }
        ${media.desktop} {
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }
`

export const Left = styled.div`
    order: 2;
    width: 100%;
    position: relative;

    :after {
        top: -40px;
        left: 30px;
        position: absolute;

        content: '';
        width: 42px;
        height: 42px;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(${RhombusImg});

        display: none;

        ${media.desktop} {
            display: block;
        }
    }

    @media (max-width: 959px) {
        margin-top: 20px;
    }

    ${media.desktop} {
        order: 1;
        width: 50%;
    }

    img {
        width: 100%;
        height: auto;
        max-width: 491px;

        @media (max-width: 959px) {
            margin: 0 auto;
            display: block;
        }
    }
`

export const Right = styled.div`
    order: 1;
    width: 100%;

    ${media.desktop} {
        order: 2;
        width: 50%;

        max-width: 440px;
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    margin: 20px 0;
    flex-direction: row;

    align-items: flex-start;
    justify-content: center;

    ${media.desktop} {
        margin: 30px 0 40px 0;
        justify-content: flex-start;
    }

    a {
        :not(:last-child) {
            margin-right: 5px;
            ${media.phone} {
                margin-right: 10px;
            }
            ${media.desktop} {
                margin-right: 15px;
            }
        }
    }

    img {
        width: 56px;
        transition: ${transition.main};

        @media (max-width: 959px) {
            width: 48px;
        }

        ${media.tablet} {
            :hover {
                transform: scale(1.1);
            }
        }
    }
`
