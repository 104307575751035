import get from 'lodash/get'
import last from 'lodash/last'
import { useMedia } from 'react-use'
import { useSnackbar } from 'notistack'
import { useLocation } from 'react-router-dom'
import React, { useState, useEffect, useContext } from 'react'

import Welcome from './Welcome'
import * as loco from '@loco'
import LoginForm from './LoginForm'
import { Routes } from '@variables'
import Illustration from '../../SVG/Login'
import ForgotPassword from './ForgotPassword'
import SetNewPassword from './SetNewPassword'
import { translateGQLError } from '@utils'
import AfterForgotPassword from './AfterForgotPassword'
import AfterNewPasswordSetted from './AfterNewPasswordSetted'
import { useVerifyEmailMutation, MeDocument } from '@graphql'
import { DIALOG_ID, DialogContext } from '../../../context/Dialog'
import { Left, Right, Dialog, RightText, CloseIcon, LoginWrapper } from './styled'

export const enum Step {
    LOGIN = 0,
    FORGOT_PASSWORD = 1,
    AFTER_FORGOT_PASSWORD = 2,
    SET_NEW_PASSWORD = 3,
    AFTER_NEW_PASSWORD_SETTED = 4,
    WELCOME = 5
}

const LoginDialog = () => {
    const location = useLocation()
    const { enqueueSnackbar } = useSnackbar()
    const isWide = useMedia('(min-width: 854px)')

    const { setOpen, id } = useContext(DialogContext)

    const isOpen = id === DIALOG_ID.LOGIN
    const token = get(location.search.split('='), '1', '')

    const [activeStep, setActiveStep] = useState<Step>(Step.LOGIN)

    const [verifyEmail] = useVerifyEmailMutation({ refetchQueries: [{ query: MeDocument }] })

    useEffect(() => {
        if (
            location.search.includes(Routes.TOKEN_SEARCH_PARAM) &&
            location.pathname.includes(Routes.NEW_PASSWORD)
        ) {
            setActiveStep(Step.SET_NEW_PASSWORD)
        }
        if (
            location.search.includes(Routes.TOKEN_SEARCH_PARAM) &&
            location.pathname.includes(Routes.VERIFY_EMAIL)
        ) {
            onVerifyEmail()
        }
    }, [location])

    const onVerifyEmail = async () => {
        const token = last(location.search.split('='))
        if (!token) return

        const { data, errors } = await verifyEmail({ variables: { data: { token: token } } })

        if (data && !errors) {
            setActiveStep(Step.WELCOME)
            enqueueSnackbar(loco.common.emailVerified, { variant: 'success' })
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }
    }

    const close = () => {
        setActiveStep(Step.LOGIN)
        setOpen(DIALOG_ID.NOT_DISPLAYED)
    }

    const goBack = () => setActiveStep((prevStep) => prevStep - 1)

    return (
        <Dialog
            open={isOpen}
            onClose={close}
            fullScreen={!isWide}
            id={DIALOG_ID.LOGIN}
            aria-labelledby={DIALOG_ID.LOGIN}
        >
            <CloseIcon role="button" onClick={close} />

            <LoginWrapper>
                <Left>
                    {activeStep === Step.LOGIN && <LoginForm setActiveStep={setActiveStep} />}

                    {activeStep === Step.FORGOT_PASSWORD && (
                        <ForgotPassword goBack={goBack} setActiveStep={setActiveStep} />
                    )}

                    {activeStep === Step.AFTER_FORGOT_PASSWORD && (
                        <AfterForgotPassword setActiveStep={setActiveStep} />
                    )}

                    {activeStep === Step.SET_NEW_PASSWORD && (
                        <SetNewPassword setActiveStep={setActiveStep} token={token} />
                    )}

                    {activeStep === Step.AFTER_NEW_PASSWORD_SETTED && (
                        <AfterNewPasswordSetted close={close} />
                    )}

                    {activeStep === Step.WELCOME && <Welcome close={close} />}
                </Left>

                <Right>
                    <Illustration />
                    <RightText variant="h2" align="center">
                        {loco.dialogs.login.tooltip}
                    </RightText>
                </Right>
            </LoginWrapper>
        </Dialog>
    )
}

export default LoginDialog
