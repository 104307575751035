import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import {
    Mail as MailIcon,
    Twitter as TwitterIcon,
    YouTube as YouTubeIcon,
    LinkedIn as LinkedInIcon,
    Facebook as FacebookIcon,
    Instagram as InstagramIcon
} from '@material-ui/icons'

import { colors, transition, media } from '@variables'

export const Mail = styled(MailIcon)``
export const Twitter = styled(TwitterIcon)``
export const YouTube = styled(YouTubeIcon)``
export const LinkedIn = styled(LinkedInIcon)``
export const Facebook = styled(FacebookIcon)``
export const Instagram = styled(InstagramIcon)``

export const Title = styled(Typography)``
export const BottomText = styled(Typography)``

export const LogoWrapper = styled.div`
    svg {
        transition: ${transition.main};
    }

    ${media.tablet} {
        :hover {
            svg {
                transform: scale(1.1);
            }
        }
    }
`

export const Wrapper = styled.footer`
    z-index: 2;
    width: 100%;
    margin-top: auto;
    position: relative;

    ${Title} {
        margin: 30px 0 15px 0;
        text-align: center;

        ${media.desktop} {
            text-align: left;
            margin: 0 0 30px 0;
        }
    }

    ${BottomText} {
        @media (max-width: 855px) {
            margin: 10px 0;
        }
    }

    ${Twitter}, ${YouTube}, ${LinkedIn}, ${Facebook}, ${Instagram} {
        width: 28px;
        height: 28px;
        margin-right: 20px;
        color: ${colors.primary.orange};
        transition: ${transition.main};

        ${media.tablet} {
            :hover {
                transform: scale(1.1);
            }
        }
        ${media.desktop} {
            width: 22px;
            height: 22px;
            margin-right: 15px;
        }
    }

    ${Mail} {
        width: 22px;
        height: 22px;
        margin-right: 15px;
        transition: ${transition.main};
        color: ${colors.primary.orange};
    }
`

export const MailLink = styled.a`
    color: inherit;
    font-weight: 500;
    align-items: center;
    display: inline-flex;
    letter-spacing: 0.25px;

    ${media.tablet} {
        :hover {
            ${Mail} {
                transform: scale(1.1);
            }
        }
    }
`

export const Top = styled.div`
    color: white;
    padding: 40px 0;
    background-color: ${colors.primary.darkBlue};

    ${media.desktop} {
        padding: 75px 0 60px 0;
    }
`

export const TopWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    ${media.desktop} {
        flex-direction: row;
        align-items: flex-start;
    }
`

export const Bottom = styled.div`
    padding: 20px 0;
    background-color: #e6e8ec;
`

export const BottomWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;

    ${media.tablet} {
        padding-bottom: 20px;
    }

    ${media.desktop} {
        flex-direction: row;
    }
`

export const Row = styled.div`
    width: 100%;
    display: inline-flex;
`

export const MarketWrapper = styled.div`
    ${media.custom(431)} {
        min-width: 275px;
    }

    @media (max-width: 430px) {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
`

export const MarketLink = styled.a`
    margin: 0;

    svg {
        transition: ${transition.main};
    }

    :last-of-type {
        margin: 10px 0 0 0;
    }

    ${media.custom(420)} {
        :last-of-type {
            margin: 0 0 0 15px;
        }
    }
    ${media.tablet} {
        :hover {
            svg {
                transform: scale(1.1);
            }
        }
    }
    ${media.desktop} {
        :last-of-type {
            margin: 0 0 0 25px;
        }
    }
`

export const Links = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
        :first-child {
            padding: 0 0 8px 0;
        }
    }

    ${media.tablet} {
        flex-direction: row;

        a {
            :first-child {
                padding: 0 40px 0 0;
            }
        }
    }
`
