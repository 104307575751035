import styled from 'styled-components'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'

import { transition, colors } from '@variables'

export const ExpansionPanelDetails = styled(MuiExpansionPanelDetails)``

export const Wrapper = styled.div<{ readonly expanded: boolean }>`
    width: 100%;
    transition: ${transition.main};
    padding: ${(props) => (props.expanded ? '20px 0' : '0px')};

    .MuiCollapse-container {
        border: none;
    }
    .MuiExpansionPanel-rounded {
        background-color: white;
    }

    ${ExpansionPanelDetails} {
        padding: 0;
        width: 100%;
        border: none;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
`

export const IconsWrapper = styled.div<{ readonly hidden: boolean }>`
    display: ${(props) => (props.hidden ? 'none' : 'flex')};

    svg {
        :last-of-type {
            margin-left: 15px;
        }
    }
`

export const TitleRow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
        color: ${colors.primary.darkBlue};
    }
`

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
`
