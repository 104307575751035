import React, { CSSProperties } from 'react'
import Select from '@material-ui/core/Select'
import { FormControl, InputLabel, MenuItem } from '@material-ui/core'
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded'

type Props = {
    name: string
    label: string
    value: string
    onChange: any
    required?: boolean
    disabled?: boolean
    className?: string
    style?: CSSProperties
    options: Array<string>
    MenuProps?: object
}

// For complicated cases use "Autocomplete" component
const SelectField = ({
    name,
    value,
    label,
    options,
    disabled,
    required,
    onChange,
    MenuProps,
    style = {},
    className = ''
}: Props) => (
    <FormControl
        fullWidth
        className={className}
        style={{
            marginTop: 10,
            marginBottom: 10,
            ...style
        }}
    >
        <InputLabel htmlFor={`${name}-simple`}>{label}</InputLabel>
        <Select
            fullWidth
            value={value}
            disabled={disabled}
            onChange={onChange}
            MenuProps={MenuProps}
            required={Boolean(required)}
            IconComponent={ExpandMoreRoundedIcon}
            inputProps={{ id: `${name}-simple`, name: `${name}` }}
        >
            {options?.map((item) => (
                <MenuItem key={item} value={item}>
                    {item}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
)

export default SelectField
