import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                fillRule="evenodd"
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M18 14.25a1.5 1.5 0 01-1.5 1.5h-9a1.5 1.5 0 010-3h9a1.5 1.5 0 011.5 1.5z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M12 15.75v7.5M5.463 23.25A6.658 6.658 0 0112 18.75a6.66 6.66 0 016.537 4.5M3 9.75v3a3 3 0 003 3h1.5M1.5 9.75h3M21 9.75v3a3 3 0 01-3 3h-1.5M22.5 9.75h-3"
            ></path>
            <path
                fillRule="evenodd"
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M16.5 5.25a4.5 4.5 0 10-9 0v7.5h9v-7.5z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default Icon
