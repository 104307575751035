import React from 'react'

import { Props } from '.'

function Icon({ color }: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fillRule="evenodd"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M12 6.75a3 3 0 100-6 3 3 0 000 6zM17.25 13.5a5.25 5.25 0 10-10.5 0v2.25H9l.75 7.5h4.5l.75-7.5h2.25V13.5z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default Icon
