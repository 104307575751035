import React, { createContext, useContext } from 'react'

export interface Recaptcha {
    execute: (action: string) => Promise<string>
}

const Context = createContext<Recaptcha>({
    execute: async () => 'test'
})

export const RecaptchaProvider = ({ children }: { children: React.ReactNode }) => {
    React.useEffect(() => {
        const container = document.getElementById('g-recaptcha')
        if (container?.innerHTML) {
            return
        }
        // @ts-ignore
        window?.grecaptcha?.ready(() => {
            // @ts-ignore
            grecaptcha.render('g-recaptcha', {
                sitekey: process.env.RAZZLE_RECAPTCHA_SITE_KEY
            })
        })
    })

    const execute = async () =>
        new Promise((resolve) => {
            // @ts-ignore
            grecaptcha.execute()
            const checkForToken = setInterval(async () => {
                // @ts-ignore
                const token = grecaptcha.getResponse()
                if (token) {
                    // @ts-ignore
                    grecaptcha.reset()
                    resolve(token)
                    clearInterval(checkForToken)
                }
            }, 1000)
        }) as any

    return <Context.Provider value={{ execute }}>{children}</Context.Provider>
}

export const useRecaptcha = () => useContext(Context)
