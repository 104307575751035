import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import { media } from '@variables'
import { colors, transition } from '@variables'
import { AccessibleButton } from '../shared/styled'

export const Wrapper = styled.div`
    box-shadow: 3px -5px 15px rgba(42, 48, 57, 0.1);
    background-color: white;
    position: fixed;
    z-index: 10;
    height: 70px;
    width: 100%;
    bottom: 0;
    left: 0;

    .MuiSlider-rail {
        opacity: 1;
        color: #eaebec;
        height: 1px;
        border-radius: 1px;
    }
    .MuiSlider-track {
        border-radius: 4px;
        height: 4px;
    }
    .MuiSlider-thumb {
        margin-top: -4px;
    }
`

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0 auto;
    padding: 0 5px;
    align-items: center;
    justify-content: space-between;

    ${media.phone} {
        padding: 0 25px;
    }
`

export const ProgressWrapper = styled.div`
    width: 100%;
    height: 28px;
    display: flex;
    padding: 0 10px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
`

export const VolumeWrapper = styled.div`
    height: 120px;
    overflow: hidden;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const More = styled.div`
    display: flex;
    padding: 20px;
    align-items: flex-start;
    flex-direction: column;
`

export const FileName = styled(Typography)``
export const FileChapter = styled(Typography)``

export const InfoWrapper = styled.div`
    width: 210px;
    display: none;
    flex-direction: column;

    ${media.tablet} {
        display: flex;
    }

    ${FileName} {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        text-align: left;
        margin: 0;
    }
    ${FileChapter} {
        opacity: 0.5;
        font-size: 14px;
        text-align: left;
        line-height: 18px;
        margin: 0;
    }
`

export const Controls = styled.div`
    display: flex;
    width: 150px;
    justify-content: space-between;
`

export const MoreButton = styled.button`
    padding: 0;
    border: none;
    outline: none;
    margin: 10px 0;
    cursor: pointer;
    font-size: 12px;
    background: none;
    transition: ${transition};
    text-transform: uppercase;

    :first-of-type {
        margin-top: 0;
    }

    ${media.tablet} {
        :hover {
            color: ${colors.primary.orange};
        }
    }
`

export const Download = styled.a`
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 12px;
    background: none;
    margin: 5px 0 0 0;
    letter-spacing: 0.5px;
    transition: ${transition};
    text-transform: uppercase;

    ${media.tablet} {
        :hover {
            color: ${colors.primary.orange};
        }
    }
`

export const Button = styled(AccessibleButton)`
    svg {
        width: 22px;
        height: 22px;
        margin: 0 1px;
        color: #2a3039;
        display: block;

        @media (min-width: 600px) {
            margin: 0 8px;
        }
    }

    :disabled {
        svg {
            color: ${colors.primary.gray2};
        }
    }
`
