import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
            {...props}
        >
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M3.333 16.25a2.083 2.083 0 012.084-2.083h11.25"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M5.417 1.667h11.25v16.666H5.417a2.083 2.083 0 01-2.084-2.083V3.75a2.083 2.083 0 012.084-2.083v0z"
            ></path>
        </svg>
    )
}

export default Icon
