import React, { useContext } from 'react'
import { Button } from '@material-ui/core'

import * as loco from '@loco'
import { Wrapper, Img, Title } from './styled'
import { AuthContext } from '../../context/Auth'
import SigninImg from '@static/images/signin.svg'
import { DialogContext, DIALOG_ID } from '../../context/Dialog'

const SignInBox = () => {
    const { setOpen } = useContext(DialogContext)
    const { isLoggingIn, logoutLoading, loading } = useContext(AuthContext)

    const isLoading = isLoggingIn || logoutLoading || loading

    return (
        <Wrapper>
            <Img src={SigninImg} alt="signin" loading="lazy" />
            <Title align="center" variant="body1">
                {loco.signInBox.title}
            </Title>
            <Button
                fullWidth
                size="large"
                color="primary"
                variant="outlined"
                disabled={isLoading}
                style={{ marginBottom: 15 }}
                onClick={() => setOpen(DIALOG_ID.LOGIN)}
            >
                {loco.signInBox.login}
            </Button>
            <Button
                fullWidth
                size="large"
                color="primary"
                variant="contained"
                disabled={isLoading}
                onClick={() => setOpen(DIALOG_ID.REGISTRATION)}
            >
                {loco.signInBox.signUp}
            </Button>
        </Wrapper>
    )
}

export default SignInBox
