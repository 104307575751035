import isNumber from 'lodash/isNumber'
import { useSetState } from 'react-use'
import { useSnackbar } from 'notistack'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState, useContext } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'

import { Box } from './styled'
import * as loco from '@loco'
import Head from '../../../../components/Head'
import { translateGQLError } from '@utils'
import { Routes, TableTitle } from '@variables'
import { ChaptersTableContext } from '../../../../context/ChaptersTable'
import VocabularyTable from '../../../../components/Tables/Admin/Vocabulary'
import illustration from '@static/images/girlWithTable.png'
import NotificationTable from '../../../../components/Tables/Admin/Notification'
import ChapterAndLectureTable from '../../../../components/Tables/Admin/ChapterAndLecture'
import {
    Tab,
    Tabs,
    Container,
    AdminButton,
    TopButtonWrapper
} from '../../../../components/shared/styled'
import {
    CreationStatus,
    LibraryCategory,
    AdminChaptersQuery,
    useAdminLibraryQuery,
    useAdminChaptersQuery,
    OrderByDirectionInput,
    useCustomNotificationsQuery,
    BatchCustomNotificationsOrderByColumnInput,
    BatchLibraryOrderByColumnInput
} from '@graphql'

type State = {
    title?: TableTitle
    itemsPerPage: number
    vocabulary: {
        page: number
        search: string
        orderBy: {
            column: BatchLibraryOrderByColumnInput
            direction: OrderByDirectionInput
        }
    }
    notifications: {
        page: number
        search: string
        orderBy: {
            column: BatchCustomNotificationsOrderByColumnInput
            direction: OrderByDirectionInput
        }
    }
}

const initValues = {
    page: 0,
    search: ''
}

type Lecture = AdminChaptersQuery['chapters'][0]['lectures'][0]
type ChapterOrLecture = AdminChaptersQuery['chapters'][0] & {
    parentId?: string
    tableData?: { isTreeExpanded?: boolean; id?: number }
}

const Admin = () => {
    const history = useHistory()
    const location = useLocation()
    const { enqueueSnackbar } = useSnackbar()

    const { expandedIdxs, itemsPerPage, setChaptersTableState } = useContext(ChaptersTableContext)

    const [state, setState] = useSetState<State>({
        title: undefined,
        itemsPerPage: 10,
        vocabulary: {
            page: 0,
            search: '',
            orderBy: {
                column: BatchLibraryOrderByColumnInput.word,
                direction: OrderByDirectionInput.asc
            }
        },
        notifications: {
            page: 0,
            search: '',
            orderBy: {
                direction: OrderByDirectionInput.asc,
                column: BatchCustomNotificationsOrderByColumnInput.title
            }
        }
    })

    const { title } = state
    const { search } = location

    const [chaptersAndLectures, setChaptersAndLectures] = useState<ChapterOrLecture[]>([])

    const { data, loading } = useAdminChaptersQuery({
        fetchPolicy: 'network-only',
        skip: title !== TableTitle.CHAPTER_AND_LECTURE,
        onError: (error) => enqueueSnackbar(translateGQLError(error.message), { variant: 'error' })
    })

    const { data: libData, loading: libLoading, refetch: refetchLibrary } = useAdminLibraryQuery({
        fetchPolicy: 'cache-and-network',
        skip: title !== TableTitle.VOCABULARY,
        onError: (error) => enqueueSnackbar(translateGQLError(error.message), { variant: 'error' }),
        variables: {
            page: state.vocabulary.page,
            itemsPerPage: state.itemsPerPage,
            filter: {
                search: state.vocabulary.search,
                type: LibraryCategory.VOCABULARY
            },
            orderBy: {
                column: state.vocabulary.orderBy.column,
                direction: state.vocabulary.orderBy.direction
            }
        }
    })

    const { data: notificationData, loading: notificationLoading } = useCustomNotificationsQuery({
        fetchPolicy: 'cache-and-network',
        skip: title !== TableTitle.NOTIFICATION,
        onError: (error) => enqueueSnackbar(translateGQLError(error.message), { variant: 'error' }),
        variables: {
            filter: {
                search: state.notifications.search
            },
            orderBy: {
                column: state.notifications.orderBy.column,
                direction: state.notifications.orderBy.direction
            },
            page: state.notifications.page,
            itemsPerPage: state.itemsPerPage
        }
    })

    useEffect(() => {
        const combinedData = data?.chapters?.reduce((acc: any[], item: ChapterOrLecture) => {
            const currentChapter = chaptersAndLectures.find((i) => i.id === item.id)

            const isTreeExpanded =
                Boolean(currentChapter?.tableData?.isTreeExpanded) ||
                expandedIdxs.indexOf(item.index) !== -1

            return acc
                .concat({
                    ...item,
                    tableData: {
                        isTreeExpanded: isTreeExpanded
                    }
                })
                .concat(
                    ...item.lectures.map((lec: Lecture) => {
                        return {
                            ...lec,
                            parentId: item.id
                        }
                    })
                )
        }, [])

        const newData: ChapterOrLecture[] = combinedData?.sort(sortByStatus) || []
        setChaptersAndLectures(newData)
    }, [data])

    useEffect(() => {
        if (search.includes(Routes.NOTIFICATIONS_SEARCH_PARAM)) {
            return setState({
                title: TableTitle.NOTIFICATION,
                vocabulary: {
                    ...initValues,
                    orderBy: {
                        column: state.vocabulary.orderBy.column,
                        direction: state.vocabulary.orderBy.direction
                    }
                },
                notifications: {
                    ...initValues,
                    orderBy: {
                        column: state.notifications.orderBy.column,
                        direction: state.notifications.orderBy.direction
                    }
                }
            })
        }

        if (search.includes(Routes.VOCABULARY_SEARCH_PARAM)) {
            return setState({
                title: TableTitle.VOCABULARY,
                vocabulary: {
                    ...initValues,
                    orderBy: {
                        column: state.vocabulary.orderBy.column,
                        direction: state.vocabulary.orderBy.direction
                    }
                },
                notifications: {
                    ...initValues,
                    orderBy: {
                        column: state.notifications.orderBy.column,
                        direction: state.notifications.orderBy.direction
                    }
                }
            })
        }

        return setState({
            title: TableTitle.CHAPTER_AND_LECTURE,
            vocabulary: {
                ...initValues,
                orderBy: {
                    column: state.vocabulary.orderBy.column,
                    direction: state.vocabulary.orderBy.direction
                }
            },
            notifications: {
                ...initValues,
                orderBy: {
                    column: state.notifications.orderBy.column,
                    direction: state.notifications.orderBy.direction
                }
            }
        })
    }, [search])

    const setTreeExpanded = (row: ChapterOrLecture, is: boolean) => {
        if (!isNumber(row?.tableData?.id)) return
        const newData = [...chaptersAndLectures]

        let currentChapter = chaptersAndLectures.find((i) => i.id === row.id)
        if (!currentChapter) return

        currentChapter = {
            ...currentChapter,
            tableData: {
                ...currentChapter?.tableData,
                isTreeExpanded: is
            }
        }

        const idx = row?.tableData?.id || 0
        newData[idx] = currentChapter

        if (is) {
            let newExpanded = []

            if (expandedIdxs.length > 0) {
                newExpanded = [...expandedIdxs, currentChapter.index]
            } else {
                newExpanded.push(currentChapter.index)
            }

            setChaptersTableState({ expandedIdxs: newExpanded })
        } else {
            const index = expandedIdxs.indexOf(currentChapter.index)
            const newExpanded = [...expandedIdxs]

            newExpanded.splice(index, 1)
            setChaptersTableState({ expandedIdxs: newExpanded })
        }

        setChaptersAndLectures(newData)
    }

    // https://github.com/mbrn/material-table/issues/871#issuecomment-515026168
    const handleSortOrderChangeNotifications = (
        orderedColumnId: number,
        orderDirection: OrderByDirectionInput
    ) => {
        if (orderedColumnId > 2) return

        let column = BatchCustomNotificationsOrderByColumnInput.title
        if (orderedColumnId === 1) column = BatchCustomNotificationsOrderByColumnInput.author
        if (orderedColumnId === 2) column = BatchCustomNotificationsOrderByColumnInput.runAt

        setState({
            notifications: {
                ...state.notifications,
                orderBy: {
                    column: column,
                    direction: orderDirection
                }
            }
        })
    }

    const handleSortOrderChangeVocabulary = (
        orderedColumnId: number,
        orderDirection: OrderByDirectionInput
    ) => {
        if (orderedColumnId > 1) return

        let column = BatchLibraryOrderByColumnInput.word
        if (orderedColumnId === 1) column = BatchLibraryOrderByColumnInput.author

        setState({
            vocabulary: {
                ...state.vocabulary,
                orderBy: {
                    column: column,
                    direction: orderDirection
                }
            }
        })
    }

    const setTitle = (newTitle: TableTitle) => () => {
        setState({
            title: newTitle,
            vocabulary: {
                ...initValues,
                orderBy: {
                    column: state.vocabulary.orderBy.column,
                    direction: state.vocabulary.orderBy.direction
                }
            },
            notifications: {
                ...initValues,
                orderBy: {
                    column: state.notifications.orderBy.column,
                    direction: state.notifications.orderBy.direction
                }
            }
        })

        if (newTitle === TableTitle.CHAPTER_AND_LECTURE) {
            history.push({ search: Routes.CHAPTERS_SEARCH_PARAM })
        }
        if (newTitle === TableTitle.NOTIFICATION) {
            history.push({ search: Routes.NOTIFICATIONS_SEARCH_PARAM })
        }
        if (newTitle === TableTitle.VOCABULARY) {
            history.push({ search: Routes.VOCABULARY_SEARCH_PARAM })
        }
    }

    const onChangePage = (page: number) => {
        if (title === TableTitle.VOCABULARY) {
            setState({
                vocabulary: {
                    page: page,
                    search: state.vocabulary.search,
                    orderBy: {
                        column: state.vocabulary.orderBy.column,
                        direction: state.vocabulary.orderBy.direction
                    }
                }
            })
        }
        if (title === TableTitle.NOTIFICATION) {
            setState({
                notifications: {
                    page: page,
                    search: state.notifications.search,
                    orderBy: {
                        column: state.notifications.orderBy.column,
                        direction: state.notifications.orderBy.direction
                    }
                }
            })
        }
    }

    const onChangeItemsPerPage = (newItemsPerPage: number) => {
        setState({ itemsPerPage: newItemsPerPage })
    }

    const onSearchChange = (searchQuery: string) => {
        if (title === TableTitle.VOCABULARY) {
            setState({
                vocabulary: {
                    ...initValues,
                    search: searchQuery,
                    orderBy: {
                        column: state.vocabulary.orderBy.column,
                        direction: state.vocabulary.orderBy.direction
                    }
                }
            })
        }
        if (title === TableTitle.NOTIFICATION) {
            setState({
                notifications: {
                    ...initValues,
                    search: searchQuery,
                    orderBy: {
                        column: state.notifications.orderBy.column,
                        direction: state.notifications.orderBy.direction
                    }
                }
            })
        }
    }

    return (
        <>
            <Head title={loco.seo['admin-dashboard'].title} />

            <Container>
                <Box>
                    <img width="164" height="160" loading="lazy" src={illustration} />

                    <Typography variant="h1" style={{ margin: '25px 0', textAlign: 'center' }}>
                        {loco.admin.create.title}
                    </Typography>

                    <TopButtonWrapper>
                        <Link to={`${Routes.CHAPTER_CREATE}`}>
                            <AdminButton size="large" color="primary" variant="contained">
                                {loco.admin.create.chapter.label}
                            </AdminButton>
                        </Link>
                        <Link to={`${Routes.LECTURE_CREATE}`}>
                            <AdminButton size="large" color="primary" variant="contained">
                                {loco.admin.create.lecture.label}
                            </AdminButton>
                        </Link>
                        <Link to={Routes.NOTIFICATION_CREATE}>
                            <AdminButton size="large" color="primary" variant="contained">
                                {TableTitle.NOTIFICATION}
                            </AdminButton>
                        </Link>
                        <Link to={Routes.VOCABULARY_CREATE}>
                            <AdminButton size="large" color="primary" variant="contained">
                                {TableTitle.VOCABULARY}
                            </AdminButton>
                        </Link>
                    </TopButtonWrapper>
                </Box>

                <Tabs>
                    <Tab
                        type="button"
                        active={title === TableTitle.CHAPTER_AND_LECTURE}
                        onClick={setTitle(TableTitle.CHAPTER_AND_LECTURE)}
                    >
                        {TableTitle.CHAPTER_AND_LECTURE}
                    </Tab>
                    <Tab
                        type="button"
                        active={title === TableTitle.NOTIFICATION}
                        onClick={setTitle(TableTitle.NOTIFICATION)}
                    >
                        {TableTitle.NOTIFICATION}
                    </Tab>
                    <Tab
                        type="button"
                        active={title === TableTitle.VOCABULARY}
                        onClick={setTitle(TableTitle.VOCABULARY)}
                    >
                        {TableTitle.VOCABULARY}
                    </Tab>
                </Tabs>

                {title === TableTitle.CHAPTER_AND_LECTURE && (
                    <ChapterAndLectureTable
                        loading={loading}
                        pageSize={itemsPerPage}
                        data={chaptersAndLectures}
                        setTreeExpanded={setTreeExpanded}
                        title={loco.admin.list.chapter.lecture.title}
                        onChangeItemsPerPage={(itemsPerPage) =>
                            setChaptersTableState({ itemsPerPage: itemsPerPage })
                        }
                    />
                )}

                {title === TableTitle.NOTIFICATION && (
                    <NotificationTable
                        onChangePage={onChangePage}
                        loading={notificationLoading}
                        pageSize={state.itemsPerPage}
                        title={TableTitle.NOTIFICATION}
                        page={state.notifications.page}
                        onSearchChange={onSearchChange}
                        searchQuery={state.notifications.search}
                        onChangeItemsPerPage={onChangeItemsPerPage}
                        sortedColumnId={state.notifications.orderBy.column}
                        data={notificationData?.customNotifications.data || []}
                        handleSortOrderChange={handleSortOrderChangeNotifications}
                        sortedColumnDirection={state.notifications.orderBy.direction}
                        totalCount={notificationData?.customNotifications.meta.items || 0}
                    />
                )}

                {title === TableTitle.VOCABULARY && (
                    <VocabularyTable
                        loading={libLoading}
                        refetch={refetchLibrary}
                        onChangePage={onChangePage}
                        page={state.vocabulary.page}
                        title={TableTitle.VOCABULARY}
                        pageSize={state.itemsPerPage}
                        onSearchChange={onSearchChange}
                        // @ts-ignore
                        data={libData?.library.data || []}
                        onChangeItemsPerPage={onChangeItemsPerPage}
                        totalCount={libData?.library?.meta.items || 0}
                        sortedColumnId={state.vocabulary.orderBy.column}
                        handleSortOrderChange={handleSortOrderChangeVocabulary}
                        sortedColumnDirection={state.vocabulary.orderBy.direction}
                    />
                )}
            </Container>
        </>
    )
}

const sortByStatus = (a: ChapterOrLecture, b: ChapterOrLecture) => {
    if (a.status === b.status) return 0 // not change
    if (b.status === CreationStatus.APPROVED) {
        // b first
        return 1
    }
    // a first
    return -1
}

export default Admin
