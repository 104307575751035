import React, { useRef, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button } from '@material-ui/core'

import * as loco from '@loco'
import { Role } from '@graphql'
import ActivityPopper from '../ActivityPopper'
import { Routes, RoutesType } from '@variables'
import { AuthContext } from '../../../../context/Auth'
import { isActivePathMobile, smoothScrollToId } from '@utils'
import { DialogContext, DIALOG_ID } from '../../../../context/Dialog'
import { Ul, Li, Link, Divider, BottomText, MobileMenuWrapper, Inner } from './styled'

type Props = {
    open: boolean
    toggleMobileMenu: () => void
}

const MobileMenu = ({ open, toggleMobileMenu }: Props) => {
    const location = useLocation()
    const { pathname, hash } = location

    const { setOpen: setOpenDialog } = useContext(DialogContext)
    const { user, logout, isAuthorized, isLoggingIn, logoutLoading, loading } = useContext(
        AuthContext
    )

    const role = user?.role
    const isTempOrNotAuth = !isAuthorized || Boolean(user?.isTemporary)

    const menuRef = useRef(null)
    const [isActivityOpen, setIsActivityOpen] = useState(false)

    const closeActivity = () => setIsActivityOpen(false)
    const toggleActivity = () => setIsActivityOpen(!isActivityOpen)

    const isActive = (page: RoutesType) => pathname.includes(page)

    const isLoading = isLoggingIn || logoutLoading || loading

    const isAdminPages = pathname.includes(Routes.DASHBOARD)
    const isOtherPages =
        pathname === Routes.LP || pathname === Routes.TERMS || pathname === Routes.COOKIES

    const handleScroll = (id: string) => (e: any) => {
        toggleMobileMenu()
        smoothScrollToId(id)
    }

    return (
        <MobileMenuWrapper open={open} ref={menuRef}>
            <ActivityPopper
                open={isActivityOpen}
                anchorEl={menuRef.current}
                handleClose={closeActivity}
            />

            <Inner>
                {/* ADMIN MENU */}
                {!isOtherPages && isAdminPages && (
                    <Ul>
                        <Li active={pathname === Routes.DASHBOARD}>
                            <Link
                                to={Routes.DASHBOARD}
                                onClick={toggleMobileMenu}
                                activeClassName={isActivePathMobile(pathname === Routes.DASHBOARD)}
                            >
                                {role === Role.ADMIN || role === Role.SUPER_ADMIN
                                    ? loco.navigation.create
                                    : loco.navigation.home}
                            </Link>
                        </Li>

                        {role === Role.SUPER_ADMIN && (
                            <Li active={isActive(Routes.SUPER_ADMIN)}>
                                <Link
                                    to={Routes.SUPER_ADMIN}
                                    onClick={toggleMobileMenu}
                                    activeClassName="activeMobileLink"
                                >
                                    {loco.navigation.users}
                                </Link>
                            </Li>
                        )}

                        {(role === Role.SUPER_ADMIN || role === Role.ADMIN) && (
                            <Li active={isActive(Routes.REVIEW)}>
                                <Link
                                    to={Routes.REVIEW}
                                    onClick={toggleMobileMenu}
                                    activeClassName="activeMobileLink"
                                >
                                    {loco.navigation.approve}
                                </Link>
                            </Li>
                        )}

                        {role === Role.SUPER_ADMIN && (
                            <Li active={isActive(Routes.ACTIVITY)}>
                                <Link
                                    to={Routes.ACTIVITY}
                                    onClick={toggleMobileMenu}
                                    activeClassName="activeMobileLink"
                                >
                                    {loco.navigation.activity}
                                </Link>
                            </Li>
                        )}

                        <Li active={isActive(Routes.STATISTICS)}>
                            <Link
                                to={Routes.STATISTICS}
                                onClick={toggleMobileMenu}
                                activeClassName="activeMobileLink"
                            >
                                {loco.navigation.statistics}
                            </Link>
                        </Li>

                        {/* COMMON LINKS */}
                        <Li>
                            <Link to={Routes.PROFILE} onClick={toggleMobileMenu}>
                                {loco.profile.dropdown.profile}
                            </Link>
                        </Li>
                        <Li>
                            <Link to={Routes.HOME} onClick={toggleMobileMenu}>
                                {loco.navigation.app}
                            </Link>
                        </Li>
                    </Ul>
                )}

                {/* APP MENU */}
                {!isOtherPages && !isAdminPages && (
                    <Ul>
                        <Li active={pathname === Routes.HOME}>
                            <Link
                                to={Routes.HOME}
                                onClick={toggleMobileMenu}
                                activeClassName={isActivePathMobile(pathname === Routes.HOME)}
                            >
                                {loco.navigation.home}
                            </Link>
                        </Li>

                        {!isTempOrNotAuth && (
                            <>
                                <Li active={isActive(Routes.LIBRARY)}>
                                    <Link
                                        to={Routes.LIBRARY}
                                        onClick={toggleMobileMenu}
                                        activeClassName="activeMobileLink"
                                    >
                                        {loco.navigation.library}
                                    </Link>
                                </Li>
                                <Li active={isActive(Routes.PROFILE)}>
                                    <Link
                                        to={Routes.PROFILE}
                                        onClick={toggleMobileMenu}
                                        activeClassName="activeMobileLink"
                                    >
                                        {loco.profile.dropdown.profile}
                                    </Link>
                                </Li>

                                {role !== Role.STUDENT && role !== Role.TEACHER && (
                                    <Li active={isActive(Routes.DASHBOARD)}>
                                        <Link
                                            to={Routes.DASHBOARD}
                                            onClick={toggleMobileMenu}
                                            activeClassName="activeMobileLink"
                                        >
                                            {loco.profile.dropdown.administration}
                                        </Link>
                                    </Li>
                                )}

                                <Li onClick={toggleActivity}>
                                    <Link to="#">{loco.navigation.notification}</Link>
                                </Li>
                                <Li>
                                    <Link to={Routes.LP} onClick={toggleMobileMenu}>
                                        {loco.profile.dropdown.lp}
                                    </Link>
                                </Li>
                            </>
                        )}
                    </Ul>
                )}

                {/* OTHER PAGES MENU */}
                {isOtherPages && (
                    <Ul>
                        <Li active={hash.includes(Routes.INVESTO_HASH_PARAM)}>
                            <Link
                                to={`${Routes.LP}#${Routes.INVESTO_HASH_PARAM}`}
                                onClick={handleScroll(Routes.INVESTO_HASH_PARAM)}
                                activeClassName={isActivePathMobile(
                                    hash.includes(Routes.INVESTO_HASH_PARAM)
                                )}
                            >
                                {loco.profile.dropdown.lp}
                            </Link>
                        </Li>
                        <Li active={hash.includes(Routes.APP_HASH_PARAM)}>
                            <Link
                                to={`${Routes.LP}#${Routes.APP_HASH_PARAM}`}
                                onClick={handleScroll(Routes.APP_HASH_PARAM)}
                                activeClassName={isActivePathMobile(
                                    hash.includes(Routes.APP_HASH_PARAM)
                                )}
                            >
                                {loco.navigation.app}
                            </Link>
                        </Li>
                        <Li active={hash.includes(Routes.ABOUT_HASH_PARAM)}>
                            <Link
                                to={`${Routes.LP}#${Routes.ABOUT_HASH_PARAM}`}
                                onClick={handleScroll(Routes.ABOUT_HASH_PARAM)}
                                activeClassName={isActivePathMobile(
                                    hash.includes(Routes.ABOUT_HASH_PARAM)
                                )}
                            >
                                {loco.navigation.about}
                            </Link>
                        </Li>
                        <Li active={hash.includes(Routes.EDU_HASH_PARAM)}>
                            <Link
                                to={`${Routes.LP}#${Routes.EDU_HASH_PARAM}`}
                                onClick={handleScroll(Routes.EDU_HASH_PARAM)}
                                activeClassName={isActivePathMobile(
                                    hash.includes(Routes.EDU_HASH_PARAM)
                                )}
                            >
                                {loco.navigation.edu}
                            </Link>
                        </Li>
                        <Li active={hash.includes(Routes.FAQ_HASH_PARAM)}>
                            <Link
                                to={`${Routes.LP}#${Routes.FAQ_HASH_PARAM}`}
                                onClick={handleScroll(Routes.FAQ_HASH_PARAM)}
                                activeClassName={isActivePathMobile(
                                    hash.includes(Routes.FAQ_HASH_PARAM)
                                )}
                            >
                                {loco.navigation.faq}
                            </Link>
                        </Li>
                        <Li>
                            <Link to={Routes.HOME} onClick={toggleMobileMenu}>
                                {loco.navigation.platforma}
                            </Link>
                        </Li>
                    </Ul>
                )}

                <Divider style={Boolean(user?.id) ? { marginTop: 10 } : {}} />

                {/* BUTTONS */}
                <div>
                    {!isOtherPages && isTempOrNotAuth && (
                        <>
                            <Button
                                fullWidth
                                size="large"
                                color="primary"
                                variant="contained"
                                disabled={isLoading}
                                style={{ marginBottom: 15 }}
                                onClick={() => {
                                    toggleMobileMenu()
                                    setOpenDialog(DIALOG_ID.LOGIN)
                                }}
                            >
                                {loco.dialogs.signup.login}
                            </Button>
                            <Button
                                fullWidth
                                size="large"
                                color="primary"
                                variant="outlined"
                                disabled={isLoading}
                                onClick={() => {
                                    toggleMobileMenu()
                                    setOpenDialog(DIALOG_ID.REGISTRATION)
                                }}
                            >
                                {loco.dialogs.signup.confirm}
                            </Button>
                        </>
                    )}

                    {isOtherPages && (!isAuthorized || Boolean(user?.isTemporary)) && (
                        <Button
                            fullWidth
                            size="large"
                            color="primary"
                            variant="contained"
                            disabled={isLoading}
                            style={{ fontSize: 14 }}
                            onClick={() => {
                                toggleMobileMenu()
                                setOpenDialog(DIALOG_ID.LOGIN)
                            }}
                        >
                            {loco.dialogs.signup.toApp}
                        </Button>
                    )}

                    {!isTempOrNotAuth && (
                        <Button
                            fullWidth
                            size="large"
                            color="primary"
                            variant="contained"
                            disabled={isLoading}
                            onClick={() => {
                                logout()
                                toggleMobileMenu()
                            }}
                        >
                            {loco.profile.dropdown.logout}
                        </Button>
                    )}
                </div>

                <BottomText>
                    {new Date().getFullYear()} {loco.footer.copyright}
                </BottomText>
            </Inner>
        </MobileMenuWrapper>
    )
}

export default MobileMenu
