import TextField from '@material-ui/core/TextField'
import { FormikProps } from 'formik/dist/types'
import { get, isUndefined } from 'lodash'
import React from 'react'

interface InputFieldProps {
    label: string
    fullWidth?: boolean
    withError?: boolean
    multiline?: boolean
    defaultValue?: string | number
    disabled?: boolean
    className?: string
    helperText?: string
    style?: object
    InputProps?: any
    type?: string
    onChange?: (e: any) => void
    value?: string | number
    field: {
        name: string
        value: any
    }
    form: FormikProps<any>
}

const InputField = ({
    label,
    multiline,
    fullWidth,
    style = {},
    disabled,
    helperText,
    className,
    field,
    withError,
    defaultValue,
    InputProps,
    type,
    value,
    form: { dirty, touched, errors },
    ...other
}: InputFieldProps) => {
    const errorText = get(errors, field.name)

    const hasError = dirty && get(touched, field.name) && errorText !== undefined

    const val = !isUndefined(value) ? value : !isUndefined(field?.value) ? field.value : ''

    return (
        <TextField
            // disable autocomplete
            autoComplete="new-password"
            {...field}
            {...other}
            label={label}
            style={style}
            disabled={disabled}
            type={type || 'text'}
            multiline={multiline}
            fullWidth={fullWidth}
            error={hasError || withError}
            InputProps={InputProps}
            // Prevent change field controlled vs uncontrolled
            value={val}
            defaultValue={defaultValue}
            className={className || ''}
            helperText={helperText ? helperText : hasError ? errorText : ''}
        />
    )
}

export default InputField
