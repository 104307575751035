import get from 'lodash/get'
import { Location } from 'history'
import { GraphQLError } from 'graphql'
import { useSetState } from 'react-use'
import { useSnackbar } from 'notistack'
import React, { useContext } from 'react'
import { Formik, FormikProps, Form } from 'formik'
import { useHistory, useLocation, Prompt } from 'react-router-dom'
import { Typography, Button, CircularProgress } from '@material-ui/core'

import CoinsIcon from '../../../../components/SVG/Coins'
import Illustration from '../../../../components/SVG/Answer'
import MoneyIllustration from '../../../../components/SVG/Money'
import WrongImg from '@static/images/daily-question-wrong.png'
import CorrectImg from '@static/images/daily-question-correct.png'

import * as loco from '@loco'
import Head from '../../../../components/Head'
import { translateGQLError } from '@utils'
import Loader from '../../../../components/shared/Loader'
import { Routes, notificationMsgs } from '@variables'
import InfoDialog from '../../../../components/Dialogs/Info'
import RejectForm from '../../../../components/Dialogs/Reject'
import { Container, TipLink } from '../../../../components/shared/styled'
import { RadioButton } from '../../../../components/Test/components/RadioGroup'
import { DailyQuestionContext } from '../../../../context/DailyQuestion'
import {
    Img,
    Row,
    Text,
    Header,
    Wrapper,
    Question,
    ShowMore,
    RightAnswer,
    SubmitButton,
    RadioButtonGroup,
    LastStepButtonWrapper
} from './styled'
import {
    DailyQuestionAnswer,
    DailyQuestionDocument,
    useReportIssueMutation,
    SubmitDailyQuestionMutation,
    useSubmitDailyQuestionMutation
} from '@graphql'

type FormValues = {
    answer?: any
}

type State = {
    isInfoDialogOpen: boolean
    isRejectDialogOpen: boolean
    userAnswer: SubmitDailyQuestionMutation['submitDailyQuestion'] | null
}

const DailyQuestion = () => {
    const history = useHistory()
    const location = useLocation()
    const { enqueueSnackbar } = useSnackbar()

    const { refetch, loading, dailyQuestion } = useContext(DailyQuestionContext)

    const [state, setState] = useSetState<State>({
        isRejectDialogOpen: false,
        isInfoDialogOpen: false,
        userAnswer: null
    })

    const { isRejectDialogOpen, isInfoDialogOpen, userAnswer } = state

    const closeRejectDialog = () => setState({ isRejectDialogOpen: false })
    const toggleRejectDialog = () => setState({ isRejectDialogOpen: !isRejectDialogOpen })

    const [reportIssue] = useReportIssueMutation()
    const [submitDailyQuestion, { loading: mutationLoading }] = useSubmitDailyQuestionMutation({
        refetchQueries: [{ query: DailyQuestionDocument }]
    })

    if (loading) return <Loader />

    const answers = get(dailyQuestion, 'answers')
    const question = get(dailyQuestion, 'question')
    const questionText = get(question, 'text', '')
    const acquiredPoints = userAnswer?.acquiredPoints || 0

    // Will be called with the next location the user is attempting to navigate to.
    // Return a string to show a prompt to the user or true to allow the transition.
    const cleanup = (nextLocation: Location<any>) => {
        // I don't return a string beacause I don't want to show the default browser pop-up(prompt).
        if (location.pathname !== nextLocation.pathname && Boolean(userAnswer)) {
            setState({ userAnswer: null })
            refetch()
        }

        return true
    }

    const onAnswer = async (values: FormValues) => {
        const { data, errors } = await submitDailyQuestion({
            variables: {
                data: {
                    question: {
                        id: question.id
                    },
                    answer: {
                        id: values.answer
                    }
                }
            }
        })

        if (data && !errors) {
            const { submitDailyQuestion } = data
            setState({ userAnswer: submitDailyQuestion })
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }
    }

    const onReport = async (reason: string): Promise<{ errors: GraphQLError[] | undefined }> => {
        const { data, errors } = await reportIssue({
            variables: {
                issue: reason,
                question: {
                    id: question.id
                }
            }
        })

        if (data && !errors) {
            enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }

        return { errors }
    }

    const title = get(dailyQuestion, 'question.text', '')

    return (
        <>
            <Head title={`${loco.seo['daily-question'].title} ${title}`} />

            <Prompt message={cleanup} />

            <RejectForm
                reject={onReport}
                open={isRejectDialogOpen}
                close={closeRejectDialog}
                title={loco.dialogs.common.report.title}
                description={loco.dialogs.common.report.description}
            />

            <InfoDialog
                isOpen={isInfoDialogOpen}
                toggleOpen={() => setState({ isInfoDialogOpen: false })}
                buttonProps={{
                    text: loco.common.close,
                    onClick: () => setState({ isInfoDialogOpen: false })
                }}
                data={[
                    {
                        title: loco.test.more,
                        icon: <Illustration />,
                        description: userAnswer?.description || ''
                    }
                ]}
            />

            <Container>
                <Wrapper>
                    <Header isCorrect={!userAnswer || Boolean(userAnswer?.isCorrect)}>
                        {!userAnswer ? (
                            <div>
                                <MoneyIllustration />
                                <Typography variant="h1" align="center" style={{ paddingTop: 25 }}>
                                    {loco['daily-question'].title}
                                </Typography>
                            </div>
                        ) : userAnswer.isCorrect ? (
                            <Img
                                src={CorrectImg}
                                width="307"
                                height="258"
                                alt="correct"
                                loading="lazy"
                            />
                        ) : (
                            <Img
                                alt="wrong"
                                loading="lazy"
                                src={WrongImg}
                                width="258"
                                height="257"
                                style={{ maxWidth: 258 }}
                            />
                        )}
                    </Header>

                    {userAnswer ? (
                        <div>
                            {userAnswer.isCorrect ? (
                                <>
                                    <Typography
                                        variant="h1"
                                        align="center"
                                        style={{
                                            paddingBottom: 20,
                                            maxWidth: '90%',
                                            margin: '0 auto'
                                        }}
                                    >
                                        {loco['daily-question'].right.title}
                                    </Typography>
                                    <Text paragraph align="center">
                                        {loco['daily-question'].right.text}
                                    </Text>
                                    <Text paragraph align="center">
                                        {loco['daily-question'].right.subtitle}
                                    </Text>

                                    <Row style={{ paddingTop: 20 }}>
                                        <CoinsIcon style={{ marginRight: 10 }} />
                                        <Typography
                                            paragraph
                                            align="center"
                                            style={{ marginBottom: 0 }}
                                        >
                                            {loco.test.acquire} {acquiredPoints}{' '}
                                            {loco.common.points}
                                        </Typography>
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <Typography variant="h1" align="center" gutterBottom>
                                        {loco['daily-question'].wrong.title}
                                    </Typography>
                                    <Text paragraph align="center">
                                        {loco['daily-question'].wrong.subtitle1}
                                    </Text>
                                    <Text paragraph align="center">
                                        {loco['daily-question'].wrong.subtitle2}
                                    </Text>
                                </>
                            )}

                            <LastStepButtonWrapper>
                                <ShowMore
                                    size="large"
                                    type="button"
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        setState({ isInfoDialogOpen: !isInfoDialogOpen })
                                    }}
                                >
                                    {isInfoDialogOpen ? loco.common.hide : loco.common.show}{' '}
                                    {loco['daily-question'].rightAnswer}
                                </ShowMore>
                                <Button
                                    size="large"
                                    type="button"
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => history.push(Routes.HOME)}
                                >
                                    {loco.common.close}
                                </Button>
                            </LastStepButtonWrapper>
                        </div>
                    ) : (
                        <Formik onSubmit={onAnswer} initialValues={{ answer: undefined }}>
                            {({
                                errors,
                                values,
                                touched,
                                isSubmitting
                            }: FormikProps<FormValues>) => (
                                <Form>
                                    <Question
                                        paragraph
                                        align="center"
                                        style={{ padding: '25px 35px 15px 35px' }}
                                    >
                                        {questionText}
                                    </Question>

                                    <RadioButtonGroup
                                        id="radioGroup"
                                        error={errors}
                                        touched={touched}
                                    >
                                        {answers?.map((answer: DailyQuestionAnswer) => (
                                            <RadioButton
                                                name="answer"
                                                key={answer.id}
                                                value={answer.id}
                                                label={answer.text}
                                                isWrong={false}
                                                isCorrect={false}
                                                disabled={Boolean(userAnswer)}
                                                isSubmitting={isSubmitting || mutationLoading}
                                            />
                                        ))}
                                    </RadioButtonGroup>

                                    <Row
                                        style={{ padding: '30px 0 40px 0' }}
                                        isLoading={isSubmitting || mutationLoading}
                                    >
                                        <SubmitButton
                                            size="large"
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            style={{ minWidth: 160 }}
                                            disabled={
                                                Boolean(userAnswer) ||
                                                !Boolean(values.answer) ||
                                                isSubmitting ||
                                                mutationLoading
                                            }
                                        >
                                            {loco['daily-question'].button}
                                            {(isSubmitting || mutationLoading) && (
                                                <CircularProgress
                                                    style={{
                                                        width: 14,
                                                        height: 14,
                                                        marginLeft: 10
                                                    }}
                                                />
                                            )}
                                        </SubmitButton>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    )}

                    {userAnswer && (
                        <RightAnswer color={userAnswer.isCorrect ? '#F5FDF6' : '#FFEFF2'}>
                            <Typography align="center">{userAnswer.correctAnswer.text}</Typography>
                            <TipLink role="button" onClick={toggleRejectDialog}>
                                {loco.test.report}
                            </TipLink>
                        </RightAnswer>
                    )}
                </Wrapper>
            </Container>
        </>
    )
}

export default DailyQuestion
