import { get, last } from 'lodash'
import { useMedia } from 'react-use'
import { useSnackbar } from 'notistack'
import React, { useContext } from 'react'
import { Dialog, Typography, Button } from '@material-ui/core'

import * as loco from '@loco'
import Close from '../../shared/Close'
import Loader from '../../shared/Loader'
import PluralBadge from '../../SVG/PluralBadge'
import { translateGQLError } from '@utils'
import { Wrapper, IconWrapper, Description } from './styled'
import { BadgeContext } from '../../../context/Badge'
import { useAcceptBadgesMutation, BadgesDocument } from '@graphql'
import { DialogContext, DIALOG_ID } from '../../../context/Dialog'

const BadgeDialog = () => {
    const { enqueueSnackbar } = useSnackbar()
    const isWide = useMedia('(min-width: 854px)')

    const [acceptBadges, { loading: acceptLoading }] = useAcceptBadgesMutation({
        refetchQueries: [{ query: BadgesDocument }]
    })

    const { setOpen, id } = useContext(DialogContext)
    const { unacceptedBadges, loading } = useContext(BadgeContext)

    const length = unacceptedBadges.length
    const isPlural = length > 1

    const submit = async () => {
        const { data, errors } = await acceptBadges({
            variables: {
                id: [...unacceptedBadges.map((badge) => badge.id)]
            }
        })

        if (data && !errors) {
            setOpen(DIALOG_ID.NOT_DISPLAYED)
        }
        errors?.forEach((err) =>
            enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
        )
    }

    const close = async () => {
        await submit()
        setOpen(DIALOG_ID.NOT_DISPLAYED)
    }

    const lastBadge = last(unacceptedBadges)

    return (
        <Dialog
            onClose={close}
            fullScreen={!isWide}
            id={DIALOG_ID.BADGE}
            className="customDialog"
            open={id === DIALOG_ID.BADGE}
            aria-labelledby={DIALOG_ID.BADGE}
        >
            <Wrapper>
                {!loading && <Close onClick={close} />}

                {loading && <Loader pure style={{ padding: '100px 80px' }} />}

                {!loading && (
                    <>
                        <IconWrapper>
                            {isPlural ? (
                                <PluralBadge />
                            ) : (
                                <img
                                    alt="Badge"
                                    loading="lazy"
                                    src={
                                        get(lastBadge, 'badge.icon.svg') ||
                                        get(lastBadge, 'badge.icon.png', '')
                                    }
                                />
                            )}
                        </IconWrapper>

                        <Typography variant="h3" align="center">
                            {isPlural
                                ? loco.dialogs.badge.plural.title
                                : loco.dialogs.badge.single.title}
                        </Typography>

                        <Description align="center" paragraph>
                            {isPlural
                                ? loco.dialogs.badge.plural.subtitle
                                : loco.dialogs.badge.single.subtitle}

                            {!isPlural && (
                                <b>
                                    {get(
                                        unacceptedBadges[length - 1],
                                        'badge.name',
                                        loco.tooltips.hiddenBadge
                                    )}
                                </b>
                            )}
                        </Description>

                        <Button
                            fullWidth
                            size="large"
                            color="primary"
                            onClick={close}
                            variant="contained"
                            disabled={acceptLoading}
                        >
                            {loco.common.close}
                        </Button>
                    </>
                )}
            </Wrapper>
        </Dialog>
    )
}

export default BadgeDialog
