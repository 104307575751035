import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="180"
            height="160"
            fill="none"
            viewBox="0 0 180 160"
            {...props}
        >
            <g clipPath="url(#clip0)">
                <g opacity="0.3">
                    <path
                        fill="#C4ECFE"
                        d="M178.129 4.944V153.89H2.592V4.944H178.13zm.574-.574H2.018v150.093h176.685V4.37z"
                        opacity="0.3"
                    ></path>
                    <g opacity="0.3">
                        <path
                            fill="#C4ECFE"
                            d="M45.295 4.37h-.537v150.093h.537V4.37zM67.778 4.37h-.538v150.093h.538V4.37z"
                            opacity="0.3"
                        ></path>
                        <path
                            fill="#C4ECFE"
                            d="M45.295 4.37h-.537v150.093h.537V4.37zM22.814 4.37h-.537v150.093h.537V4.37zM90.258 4.37h-.537v150.093h.537V4.37zM112.74 4.37h-.537v150.093h.537V4.37zM134.944 4.37h-.537v150.093h.537V4.37zM157.147 4.37h-.537v150.093h.537V4.37z"
                            opacity="0.3"
                        ></path>
                    </g>
                </g>
                <path
                    fill="#BFD9FF"
                    d="M13.908 74.648a.76.76 0 100-1.518.76.76 0 000 1.518zM94.741 125.481a.759.759 0 10.001-1.517.759.759 0 00-.001 1.517zM154 9.574a.759.759 0 100-1.517.759.759 0 000 1.517zM122.87 33.13a.76.76 0 100-1.519.76.76 0 000 1.519zM14.667 20.63a.76.76 0 100-1.519.76.76 0 000 1.519zM162.76 79.185a.704.704 0 100-1.407.704.704 0 000 1.407zM38.723 99.519a.704.704 0 100-1.408.704.704 0 000 1.407z"
                ></path>
                <path
                    fill="url(#paint0_linear)"
                    d="M93.481 154.463c-20.222 0-29.463-29.667-53.296-26.778-7.148.871-15.13-13.796-18.389-19.315-5.426-9.185-19.778-5.463-19.778 5.13v40.981h91.463v-.018z"
                ></path>
                <path
                    fill="#2B2B2B"
                    d="M140.241 145.333H79.074c-4.5 0-7.722-4.352-6.389-8.666l10.834-34.926 6.018-19.426c1.5-4.834 0-10.056-3.778-13.426a35.77 35.77 0 01-7.018-8.611c-3.093-5.278-4.852-11.445-4.852-18 0-19.556 15.704-35.445 35.185-35.76 19.852-.314 36.056 15.37 36.352 35.223.167 10.815-4.482 20.555-11.944 27.222a12.842 12.842 0 00-3.704 13.389l16.852 54.352c1.314 4.259-1.889 8.629-6.389 8.629z"
                ></path>
                <path
                    fill="#FDC068"
                    d="M50.538 75.833l-1.76 3.87a5.134 5.134 0 01-6.815 2.538 5.146 5.146 0 01-2.814-6.074l.759-2.741"
                ></path>
                <path
                    fill="url(#paint1_linear)"
                    d="M39.889 73.445l6.148-21.982a5.69 5.69 0 012.519-3.352l.444-.26 23.296-14.314a7.148 7.148 0 019.815 2.333 7.148 7.148 0 01-2.333 9.815L56.48 60l2.963-3.63-8.888 19.482"
                ></path>
                <path
                    fill="#C7982A"
                    d="M130.093 50.296c-.945-4.5-5.37-7.389-9.87-6.444l-9.038 1.907c-.333.019-.648.074-.981.13a6.36 6.36 0 00-1.056.296L55.408 57.5l-.056-.278C52.5 43.185 38.741 34.074 24.704 36.944c-6.796 1.39-12.667 5.334-16.481 11.13-3.834 5.796-5.186 12.722-3.797 19.537L7.89 84.593c1.39 6.796 5.333 12.666 11.13 16.481a25.753 25.753 0 0014.296 4.315c1.74 0 3.5-.167 5.24-.537 6.797-1.389 12.667-5.333 16.482-11.13 3.834-5.796 5.185-12.722 3.797-19.537l-.075-.389 46.667-9.833 1.315 6.444c.796 3.945 4.278 6.667 8.148 6.667.556 0 1.111-.055 1.667-.166 4.518-.927 7.426-5.315 6.5-9.834l-1.333-6.555 1.962-.408c4.482-.907 7.352-5.315 6.408-9.815zM41.111 84.537a9.307 9.307 0 01-5.907 4 9.243 9.243 0 01-7-1.352 9.307 9.307 0 01-4-5.907l-3.463-16.982a9.243 9.243 0 011.352-7 9.307 9.307 0 015.907-4c.63-.13 1.241-.185 1.87-.185 4.334 0 8.241 3.056 9.13 7.463l3.463 16.982a9.214 9.214 0 01-1.352 6.981z"
                ></path>
                <path
                    fill="#FDE545"
                    d="M132.111 49.648c-.945-4.5-5.37-7.389-9.87-6.444L57.426 56.852l-.056-.278c-2.852-14.037-16.61-23.148-30.648-20.278-6.796 1.39-12.667 5.334-16.481 11.13-3.834 5.796-5.186 12.722-3.797 19.537l3.463 16.982c1.39 6.796 5.334 12.666 11.13 16.481a25.753 25.753 0 0014.296 4.315c1.74 0 3.5-.167 5.24-.537 6.797-1.389 12.668-5.333 16.482-11.13 3.834-5.796 5.186-12.722 3.797-19.537l-.074-.389 46.666-9.833 1.315 6.444c.796 3.945 4.278 6.667 8.148 6.667.556 0 1.111-.055 1.667-.167 4.518-.926 7.426-5.314 6.5-9.833l-1.333-6.556 1.963-.407c4.481-.889 7.351-5.296 6.407-9.815zM43.13 83.908a9.307 9.307 0 01-5.908 4 9.243 9.243 0 01-7-1.352 9.307 9.307 0 01-4-5.908L22.76 63.667a9.244 9.244 0 011.352-7 9.308 9.308 0 015.907-4c.63-.13 1.241-.185 1.87-.185 4.334 0 8.241 3.055 9.13 7.462l3.463 16.982a9.214 9.214 0 01-1.352 6.981z"
                ></path>
                <path fill="#2B2B2B" d="M57.352 56.593l6.519-.89-2.963 17.742-3.556-16.852z"></path>
                <path
                    fill="#FDC068"
                    d="M98.353 25.704l.444 1.592a.584.584 0 01-.426.723l-.796.092"
                ></path>
                <path
                    fill="#2B2B2B"
                    d="M97.575 28.352c-.13 0-.223-.093-.241-.222-.019-.13.074-.26.204-.26l.777-.092a.267.267 0 00.204-.167c.056-.074.056-.166.037-.26l-.444-1.592a.238.238 0 01.166-.296c.13-.037.26.037.297.167l.444 1.592a.83.83 0 01-.074.63.79.79 0 01-.518.389h-.019l-.796.092c-.019.019-.037.019-.037.019z"
                ></path>
                <path
                    fill="#FDC068"
                    d="M90 32.24l-4.537-2.073a4.66 4.66 0 01-2.297-6.204l3.13-6.852a7.487 7.487 0 1113.611 6.24l-2.87 6.279c-1.223 2.648-4.37 3.833-7.037 2.61z"
                ></path>
                <path
                    fill="#2B2B2B"
                    d="M99.352 24.556l-.686 1.518a.867.867 0 01-.352-.685.862.862 0 011.038-.833z"
                ></path>
                <path
                    fill="#FDC068"
                    d="M80.241 35.74l-1.26-1.258a1.828 1.828 0 01-.333-2.13l5.926-11.481 7.241 3.314-3.944 9.945c-1.241 3.13-5.26 3.963-7.63 1.61z"
                ></path>
                <path fill="#2B2B2B" d="M88.833 31.704l-2.37-1.093 1.444 3.445.926-2.352z"></path>
                <path
                    fill="url(#paint2_linear)"
                    d="M89.944 58.519c1.649-7.019-3.24-7.852-.277-12.112 2.963-4.277 2.388-11.407-3-12.666-4.167-.982-4.408-1.222-6.167-1.63-5.537-1.315-10.685 3.426-10.352 9.111 0 0 .148 1.297-.278 2.87-.426 1.575-4.87 3-6 7.834l26.074 6.593z"
                ></path>
                <path
                    fill="url(#paint3_linear)"
                    d="M83.445 58.74l-.037.704-.056.686-.111 1.389-.278 2.759c-.092.926-.203 1.833-.315 2.76l-.352 2.74a273.56 273.56 0 01-1.74 10.926c-.648 3.63-1.37 7.24-2.13 10.852a363.569 363.569 0 01-2.481 10.796l-.352 1.444a6.86 6.86 0 01-1.408 2.76 602.6 602.6 0 01-7.796 9.018l-1.981 2.222L62.389 120c-1.333 1.482-2.704 2.926-4.055 4.389-2.741 2.907-5.5 5.796-8.352 8.611-2.87 2.815-6.297 6.704-9.352 9.37l-6.519-13.777 23.667-33.87L56 98.981l7.89-46.686 19.555 6.445z"
                ></path>
                <path
                    fill="url(#paint4_linear)"
                    d="M63.87 51.926c-.87 3.704 1.705 10.167 8.149 11.74l5.24 1.112c5.871 1.444 11.853-2.685 12.797-6.685l-26.185-6.167z"
                ></path>
                <path
                    fill="#2B2B2B"
                    d="M41.927 153.333l-10.519-19.444a2.137 2.137 0 01.815-2.87l1.889-2.426L41.186 142l2.74 10.556c.297 1.24-1.407 1.907-2 .777z"
                ></path>
                <path
                    fill="url(#paint5_linear)"
                    d="M113.889 145.333h-14.74L85.87 108.37l-.963-2.814-.482-1.408-.537-1.389-.389-1.018-1.722-4.519-4.24-11.092-8.463-22.185 9.648-3.686 9.389-3.574c1.833 3.574 3.425 7.24 4.963 10.926a289.809 289.809 0 014.314 11.167c.667 1.889 1.352 3.759 1.982 5.648.315.944.648 1.889.963 2.833l.926 2.852c.314.945.611 1.907.907 2.852l.889 2.852.426 1.444.389 1.445.759 2.907c2 7.741 3.87 15.556 5.519 23.407a362.688 362.688 0 012.333 11.834c.352 1.981.722 3.963 1.018 5.963.13.833.26 1.666.389 2.518z"
                ></path>
                <path
                    fill="#FDC068"
                    d="M111.87 75.389l3.408 2.722c2.222 1.778 2.574 5.019.814 7.24a5.135 5.135 0 01-6.592 1.242l-2.722-1.574"
                ></path>
                <path
                    fill="#2B2B2B"
                    d="M79.13 55.519s-.259 18.685 3.611 21.703c3.87 3.019 16.834 7.815 16.834 7.815L90.37 61.426l-5.926-5.389-5.315-.518z"
                ></path>
                <path
                    fill="url(#paint6_linear)"
                    d="M106.778 85.037L86.63 73.407a6.003 6.003 0 01-2.908-4.092l-.092-.519-4.37-23.814c-.704-3.87 1.851-7.593 5.722-8.297 3.87-.703 7.592 1.852 8.296 5.722l4.37 23.815-3-4.592 17.222 13.777"
                ></path>
                <path
                    fill="#B37F56"
                    d="M100.575 20.667l-1.112.092a3.875 3.875 0 00-3.462 3.074L95.314 27l-2.574-.389-5.037 2.667a4.677 4.677 0 01-3.76.26l-3.24-1.186 5.74-11.815c1.76-3.611 6.056-5.204 9.742-3.592l1.777.777a5.17 5.17 0 012.612 6.945z"
                ></path>
                <path
                    fill="#B37F56"
                    d="M95.852 19.574l5.203 1.537a2.936 2.936 0 003.408-1.426l.592-1.11a2.932 2.932 0 00-1.463-4.112 2.865 2.865 0 00-1.926-.111l-2.018.574-3.796 4.648z"
                ></path>
                <path
                    fill="#FDC068"
                    d="M93.11 27.574a.991.991 0 01-.721-1.185l.703-2.852a.991.991 0 011.186-.722c.518.13.851.666.722 1.185l-.704 2.852c-.111.518-.648.852-1.185.722z"
                ></path>
                <path
                    fill="#2B2B2B"
                    d="M81.278 56.26a.238.238 0 01-.241-.204l-1.352-7.315c-.019-.13.055-.26.185-.278.13-.019.26.056.278.185l1.352 7.315c.018.13-.056.26-.185.278 0 .018-.019.018-.037.018zM97.168 63.889c-.056 0-.093-.019-.149-.056l-3.018-2.222c-.111-.074-.13-.222-.056-.333.074-.111.223-.13.334-.056l3.018 2.222c.111.075.13.223.056.334a.204.204 0 01-.185.111zM63.87 52.167c-.036 0-.073 0-.092-.019-.13-.055-.185-.204-.13-.315l3.241-7.389c.056-.13.204-.185.315-.13.13.056.185.205.13.316l-3.24 7.389a.24.24 0 01-.223.148z"
                ></path>
                <path fill="#fff" d="M77.612 31.945l2.315-2.963 7.944 5.166-10.26-2.204z"></path>
                <path
                    fill="#2B2B2B"
                    d="M78.852 89.76a.24.24 0 01-.223-.149l-7.352-19.24c-.055-.13.019-.26.149-.315.13-.056.259.018.314.148l7.352 19.24c.056.13-.018.26-.148.315h-.093zM56.371 101.241a.245.245 0 01-.24-.241v-4.278c0-.13.11-.24.24-.24s.241.11.241.24V101c0 .13-.111.241-.24.241zM107.408 63.611a.239.239 0 01-.241-.204c-.018-.13.056-.259.186-.277l3.703-.686c.13-.018.259.056.278.186.018.13-.056.259-.185.277l-3.704.686c0 .018-.018.018-.037.018z"
                ></path>
                <path
                    fill="#2B2B2B"
                    d="M99.167 145.333L95.5 135.13h16.481l1.908 10.203H99.167z"
                    opacity="0.4"
                ></path>
                <path fill="#2B2B2B" d="M104.703 153.685H30.018v.759h74.685v-.759z"></path>
                <path
                    fill="url(#paint7_linear)"
                    d="M130.241 154.463c16.074 0 21.815-20.722 30.315-18.37 4.944 1.37 8.204-2.186 11.278-5.389 2.5-2.593 6.889-.834 6.889 2.759v20.981l-48.482.019z"
                ></path>
                <path
                    fill="#F9D5FF"
                    d="M157.815 68.185l-1.852-.222c.908-7.444 6.815-10.981 12.019-14.111 4.907-2.945 9.148-5.5 9.148-10.593h1.852c0 6.148-5.111 9.222-10.037 12.185-5.093 3.037-10.334 6.204-11.13 12.741zM68.037 24.296h-1.852c0-4.166-5.26-5.314-10.815-6.537-5.537-1.204-11.259-2.463-11.259-7.296h1.852c0 3.111 3.889 4.204 9.815 5.5 5.74 1.24 12.259 2.667 12.259 8.333z"
                ></path>
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="47.75"
                    x2="47.75"
                    y1="112.339"
                    y2="152.779"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFE272"></stop>
                    <stop offset="0.996" stopColor="#fff"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="61.528"
                    x2="61.528"
                    y1="71.731"
                    y2="34.601"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFE0C2"></stop>
                    <stop offset="0.401" stopColor="#7AAEFC"></stop>
                    <stop offset="0.591" stopColor="#B8C1FC"></stop>
                    <stop offset="1" stopColor="#D2D4D4"></stop>
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="77.657"
                    x2="77.657"
                    y1="77.963"
                    y2="34.943"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFE0C2"></stop>
                    <stop offset="0.401" stopColor="#7AAEFC"></stop>
                    <stop offset="0.591" stopColor="#B8C1FC"></stop>
                    <stop offset="1" stopColor="#D2D4D4"></stop>
                </linearGradient>
                <linearGradient
                    id="paint3_linear"
                    x1="58.778"
                    x2="58.778"
                    y1="52.296"
                    y2="152.053"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9774AC"></stop>
                    <stop offset="0.401" stopColor="#47A6D3"></stop>
                    <stop offset="0.501" stopColor="#A7A7AE"></stop>
                    <stop offset="0.591" stopColor="#E89AC0"></stop>
                    <stop offset="1" stopColor="#BFE7D1"></stop>
                </linearGradient>
                <linearGradient
                    id="paint4_linear"
                    x1="76.881"
                    x2="76.881"
                    y1="55.37"
                    y2="150.567"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9774AC"></stop>
                    <stop offset="0.401" stopColor="#47A6D3"></stop>
                    <stop offset="0.501" stopColor="#A7A7AE"></stop>
                    <stop offset="0.591" stopColor="#E89AC0"></stop>
                    <stop offset="1" stopColor="#BFE7D1"></stop>
                </linearGradient>
                <linearGradient
                    id="paint5_linear"
                    x1="91.482"
                    x2="91.482"
                    y1="55.478"
                    y2="142.813"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9774AC"></stop>
                    <stop offset="0.401" stopColor="#47A6D3"></stop>
                    <stop offset="0.501" stopColor="#A7A7AE"></stop>
                    <stop offset="0.591" stopColor="#E89AC0"></stop>
                    <stop offset="1" stopColor="#BFE7D1"></stop>
                </linearGradient>
                <linearGradient
                    id="paint6_linear"
                    x1="95.507"
                    x2="95.507"
                    y1="76.123"
                    y2="35.015"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFE0C2"></stop>
                    <stop offset="0.401" stopColor="#7AAEFC"></stop>
                    <stop offset="0.591" stopColor="#B8C1FC"></stop>
                    <stop offset="1" stopColor="#D2D4D4"></stop>
                </linearGradient>
                <linearGradient
                    id="paint7_linear"
                    x1="154.482"
                    x2="154.482"
                    y1="133.941"
                    y2="153.639"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C0DDFC"></stop>
                    <stop offset="0.996" stopColor="#fff"></stop>
                </linearGradient>
                <clipPath id="clip0">
                    <path fill="#fff" d="M0 0H180V160H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default Icon
