import { useMedia } from 'react-use'
import { object, string } from 'yup'
import { GraphQLError } from 'graphql'
import React, { useState } from 'react'
import { Button, Typography, Dialog } from '@material-ui/core'
import { Field, Formik, FormikProps, FormikHelpers } from 'formik'

import * as loco from '@loco'
import Close from '../../shared/Close'
import InputField from '../../Fields/Input'
import { Form, Description } from './styled'
import { DialogContent } from '../../shared/styled'
import illustration from '@static/images/girlWithTable.png'

type Props = {
    open: boolean
    title: string
    subject?: string
    close: () => void
    description?: string
    reject?: (reason: string) => Promise<{ errors: GraphQLError[] | undefined }>
}

type FormValues = {
    reason: string
}

enum Step {
    FIRST = 0,
    LAST = 1
}

const RejectForm = ({ open, close, reject, subject, title, description }: Props) => {
    const isWide = useMedia('(min-width: 854px)')

    const [step, setStep] = useState<Step>(Step.FIRST)

    const submit = async ({ reason }: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
        if (step === Step.FIRST) {
            if (reject) {
                const { errors } = await reject(reason)
                if (!errors) setStep(Step.LAST)
            }
        } else {
            resetForm()
            setStep(Step.FIRST)
            close()
        }
    }

    return (
        <Dialog open={open} fullScreen={!isWide} onClose={close} aria-labelledby="reject-dialog">
            <DialogContent>
                <Close onClick={close} />

                <Formik
                    onSubmit={submit}
                    isInitialValid={false}
                    initialValues={{ reason: '' }}
                    validationSchema={validationSchema}
                >
                    {({ isSubmitting, values, isValid }: FormikProps<{ reason: string }>) => (
                        <Form spellCheck={false}>
                            {step === Step.FIRST && (
                                <>
                                    <Typography
                                        align="center"
                                        variant="h3"
                                        style={{ width: '100%' }}
                                    >
                                        {title}
                                    </Typography>

                                    {description && (
                                        <Description paragraph align="center">
                                            {description}
                                        </Description>
                                    )}

                                    <Field
                                        fullWidth
                                        multiline
                                        key="reason"
                                        name="reason"
                                        value={values.reason}
                                        component={InputField}
                                        label={loco.dialogs.reject.reason.label}
                                        style={{ marginTop: 15, marginBottom: 25 }}
                                        placeholder={loco.dialogs.reject.reason.placeholder}
                                    />
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        style={{ marginBottom: 50 }}
                                        disabled={isSubmitting || !isValid}
                                    >
                                        {Boolean(reject) && loco.dialogs.reject.button}
                                        {!Boolean(reject) && (
                                            <a
                                                href={`mailto:${loco.test.supportEmail}?subject=${
                                                    subject || 'Chyba v testu'
                                                }&body=${values.reason}`}
                                                style={{ color: 'white' }}
                                            >
                                                {loco.dialogs.reject.button}
                                            </a>
                                        )}
                                    </Button>
                                </>
                            )}

                            {step === Step.LAST && (
                                <>
                                    <img
                                        width="164"
                                        height="160"
                                        loading="lazy"
                                        src={illustration}
                                    />

                                    <Typography
                                        variant="h3"
                                        align="center"
                                        style={{ width: '100%', margin: '0 0 15px 0' }}
                                    >
                                        {loco.feedback_confirmation_title}
                                    </Typography>

                                    <Description
                                        paragraph
                                        align="center"
                                        style={{ margin: '0 0 25px 0' }}
                                    >
                                        {loco.feedback_confirmation_subtitle}
                                    </Description>

                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        style={{ marginBottom: 50 }}
                                    >
                                        {loco.feedback_confirmation_button}
                                    </Button>
                                </>
                            )}
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}

const validationSchema = object({
    reason: string().required(loco.validation.common.required)
})

export default RejectForm
