import { get, isUndefined } from 'lodash'
import { Location } from 'history'
import { object, string } from 'yup'
import { useSnackbar } from 'notistack'
import React, { useContext } from 'react'
import Button from '@material-ui/core/Button'
import { useToggle, useBeforeUnload } from 'react-use'
import { Field, Formik, FormikProps, FormikHelpers } from 'formik'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'

import * as loco from '@loco'
import { handleBack, translateGQLError } from '@utils'
import { ButtonWrapper } from '../styled'
import Head from '../../../../../components/Head'
import { Routes, notificationMsgs } from '@variables'
import Loader from '../../../../../components/shared/Loader'
import InputField from '../../../../../components/Fields/Input'
import { AuthContext } from '../../../../../context/Auth'
import BackButton from '../../../../../components/shared/BackButton'
import RouteLeavingGuard from '../../../../../components/RouteLeavingGuard'
import { Container, Form, SmallContainer, Box } from '../../../../../components/shared/styled'
import {
    Role,
    LibraryWordItemDocument,
    useUpdateWordMutation,
    useLibraryWordItemQuery
} from '@graphql'

type FormValues = {
    word: string
    description: string
}

const EditVocabulary = () => {
    const history = useHistory()
    const location = useLocation()
    const { id } = useParams<{ readonly id: string }>()

    const { user, loading: authLoading } = useContext(AuthContext)
    const { enqueueSnackbar } = useSnackbar()

    const [dirty, toggleDirty] = useToggle(false)
    // On close or reload
    useBeforeUnload(dirty, loco.admin.popup.leave.title)

    const [updateWord, { loading: updateLoading }] = useUpdateWordMutation({
        update: (caches, { data }) => {
            try {
                if (!data?.updateWord) {
                    throw new Error('Updated word is undefined')
                }

                caches.writeQuery({
                    query: LibraryWordItemDocument,
                    variables: { where: { id: id } },
                    data: {
                        libraryItem: { ...data.updateWord }
                    }
                })
            } catch (error) {
                console.error(error)
            }
        }
    })

    const { data, error, loading } = useLibraryWordItemQuery({
        variables: { where: { id: id } }
    })

    if (loading) return <Loader halfHeight />
    if (!data || error) {
        error && enqueueSnackbar(translateGQLError(error.message), { variant: 'error' })
        return null
    }

    const word = get(data, 'libraryItem.word', '')
    const authorId = get(data, 'libraryItem.author.id', '')
    const description = get(data, 'libraryItem.description', '')
    const isSameAuthor = user?.id === authorId || user?.role === Role.SUPER_ADMIN

    const handleChange = () => {
        if (!dirty) toggleDirty()
    }

    const submit = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
        if (!isSameAuthor) {
            enqueueSnackbar(loco.notifications.notAllowed, { variant: 'info' })
            return
        }

        const { word, description } = values

        const { data, errors } = await updateWord({
            variables: {
                where: { id: id },
                data: {
                    word: word,
                    description: description
                }
            }
        })

        if (data && !errors) {
            enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
            toggleDirty()
            resetForm()
            history.push({
                pathname: Routes.DASHBOARD,
                search: Routes.VOCABULARY_SEARCH_PARAM
            })
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }
    }

    return (
        <>
            <Head title={loco.seo.vocabulary.edit.title} />

            <Container>
                <SmallContainer>
                    <BackButton
                        position="relative"
                        onClick={handleBack(history)}
                        style={{
                            margin: '40px 0 20px 0',
                            fontWeight: 400,
                            fontSize: 18
                        }}
                    >
                        {loco.admin.list.dictionary.label}
                    </BackButton>

                    <RouteLeavingGuard
                        when={dirty}
                        title={loco.admin.popup.leave.title}
                        btnText={loco.admin.popup.leave.confirm}
                        description={loco.admin.popup.leave.subtitle}
                        navigate={(path: string) => history.push(path)}
                        shouldBlockNavigation={(newLocation: Location<any>) => {
                            if (dirty) {
                                if (location.pathname !== newLocation.pathname) {
                                    return true
                                }
                            }
                            return false
                        }}
                    />

                    <Box>
                        <Formik
                            onSubmit={submit}
                            isInitialValid={false}
                            validationSchema={validationSchema}
                            initialValues={{ word: word, description: description }}
                        >
                            {({ isValid, isSubmitting, values }: FormikProps<FormValues>) => (
                                <Form spellCheck={false} onChange={handleChange}>
                                    <Field
                                        fullWidth
                                        key="word"
                                        name="word"
                                        value={values.word}
                                        component={InputField}
                                        className="customInput"
                                        label={loco.admin.create.dictionary.name.label}
                                        disabled={updateLoading || isSubmitting || !isSameAuthor}
                                        placeholder={loco.admin.create.dictionary.name.placeholder}
                                    />
                                    <Field
                                        fullWidth
                                        multiline
                                        key="description"
                                        name="description"
                                        component={InputField}
                                        className="customInput"
                                        value={values.description}
                                        disabled={updateLoading || isSubmitting || !isSameAuthor}
                                        label={loco.admin.create.dictionary.explanation.label}
                                        placeholder={
                                            loco.admin.create.dictionary.explanation.placeholder
                                        }
                                    />

                                    <ButtonWrapper>
                                        <Link
                                            to={`${Routes.DASHBOARD}?${Routes.VOCABULARY_SEARCH_PARAM}`}
                                            className={
                                                updateLoading || isSubmitting ? 'disabledLink' : ''
                                            }
                                        >
                                            <Button
                                                size="large"
                                                type="button"
                                                color="secondary"
                                                variant="outlined"
                                                disabled={updateLoading || isSubmitting}
                                            >
                                                {loco.common.cancel}
                                            </Button>
                                        </Link>
                                        <Button
                                            size="large"
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            style={{ marginLeft: 30 }}
                                            disabled={
                                                !isValid ||
                                                updateLoading ||
                                                isSubmitting ||
                                                !isSameAuthor
                                            }
                                        >
                                            {loco.common.save}
                                        </Button>
                                    </ButtonWrapper>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </SmallContainer>
            </Container>
        </>
    )
}

const validationSchema = object({
    word: string().required(loco.validation.common.required),
    description: string().required(loco.validation.common.required)
})

export default EditVocabulary
