import Menu from '@material-ui/core/Menu'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import { useMedia, useSetState } from 'react-use'
import { Link, useLocation } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useContext, useRef, ChangeEvent } from 'react'

import Logo from '../../components/SVG/Logo'
import NotificationIcon from '../../components/SVG/Notification'
import AboutIcon from '../../components/SVG/ProfileMenu/About'
import LogoutIcon from '../../components/SVG/ProfileMenu/Logout'
import ProfileIcon from '../../components/SVG/ProfileMenu/Account'
import PlatformIcon from '../../components/SVG/ProfileMenu/Platform'
import AdministrationIcon from '../../components/SVG/ProfileMenu/Administration'

import Avatar from '../Avatar'
import * as loco from '@loco'
import { Role } from '@graphql'
import Tooltip from '../shared/Tooltip'
import MobileMenu from './components/MobileMenu'
import { Routes, colors } from '@variables'
import ActivityPopper from './components/ActivityPopper'
import { AuthContext } from '../../context/Auth'
import useDocumentScrollThrottled from './useDocumentScrollThrottled'
import { DialogContext, DIALOG_ID } from '../../context/Dialog'
import { getProfileName, isActivePath, smoothScrollToIdWithEvent } from '@utils'
import {
    Nav,
    Burger,
    TopMenu,
    Profile,
    Account,
    LogoLink,
    Username,
    NavContent,
    TopMenuItem,
    ProfileInfo,
    CustomButton,
    NotificationIconWrapper
} from './styled'

const NavBar = () => {
    const location = useLocation()
    if (location.pathname.includes('dotaznik')) return null

    const isWide = useMedia('(min-width: 1170px)')

    const { setOpen, id: dialogId } = useContext(DialogContext)
    const { user, logout, isLoggingIn, isAuthorized, logoutLoading, loading } = useContext(
        AuthContext
    )

    const profileRef = useRef(null)
    const notificationIcon = useRef(null)

    const [state, setState] = useSetState({
        visible: true,
        prevScrollpos: 0,
        isMenuOpen: false,
        isActivityOpen: false,
        isMobileMenuOpen: false
    })

    const { pathname, hash } = location
    const { visible, isMenuOpen, isActivityOpen, isMobileMenuOpen } = state

    useDocumentScrollThrottled(
        ({
            previousScrollTop,
            currentScrollTop
        }: {
            previousScrollTop: number
            currentScrollTop: number
        }) => {
            const isScrolledUp = previousScrollTop > currentScrollTop

            setTimeout(() => {
                setState({ visible: currentScrollTop < 150 ? true : isScrolledUp })
            }, 0)
        }
    )

    useEffect(() => {
        if (isWide) {
            setState({ isMobileMenuOpen: false })
            document.body.classList.remove('block-scroll')
        }
    }, [isWide])

    const toggleMenu = () => setState({ isMenuOpen: !state.isMenuOpen })

    const setDialogId = (dialogId: DIALOG_ID) => (e: ChangeEvent<{}>) => setOpen(dialogId)

    const toggleActivity = () => {
        setState({ isActivityOpen: !state.isActivityOpen })

        if (isMobileMenuOpen) {
            document.body.classList.remove('block-scroll')
        } else {
            document.body.classList.add('block-scroll')
        }
    }

    const toggleMobileMenu = () => {
        setState({ isMobileMenuOpen: !state.isMobileMenuOpen })

        if (isMobileMenuOpen) {
            document.body.classList.remove('block-scroll')
        } else {
            document.body.classList.add('block-scroll')
        }
    }

    const isTempOrNotAuth = !isAuthorized || Boolean(user?.isTemporary)
    const isLoading = isLoggingIn || logoutLoading || loading

    const isAdminPage = location.pathname.includes(Routes.DASHBOARD)
    const isOtherPages =
        location.pathname === Routes.LP ||
        location.pathname === Routes.TERMS ||
        location.pathname === Routes.COOKIES

    const role = user?.role
    const isAdmin = role === Role.ADMIN || role === Role.SUPER_ADMIN

    const logoLink = isAdminPage
        ? Routes.DASHBOARD
        : isOtherPages && isTempOrNotAuth
        ? Routes.LP
        : Routes.HOME

    const isVisible = visible || dialogId !== DIALOG_ID.NOT_DISPLAYED || isMobileMenuOpen

    return (
        <>
            <Nav isVisible={isVisible}>
                <NavContent>
                    {/* LOGO */}
                    <LogoLink>
                        <Link
                            to={logoLink}
                            onClick={() => {
                                !isWide && isMobileMenuOpen && toggleMobileMenu()
                                setDialogId(DIALOG_ID.NOT_DISPLAYED)
                            }}
                        >
                            <Logo />
                        </Link>
                    </LogoLink>

                    {/* ADMIN MENU */}
                    {!isOtherPages &&
                        isAdminPage &&
                        !(role === Role.STUDENT || role === Role.TEACHER) && (
                            <TopMenu>
                                <li>
                                    <TopMenuItem
                                        to={Routes.DASHBOARD}
                                        activeClassName={isActivePath(
                                            pathname === Routes.DASHBOARD
                                        )}
                                    >
                                        {role === Role.ADMIN || role === Role.SUPER_ADMIN
                                            ? loco.navigation.create
                                            : loco.navigation.home}
                                    </TopMenuItem>
                                </li>
                                {!(role === Role.SUPER_ADMIN || role === Role.ADMIN) && (
                                    <li>
                                        <TopMenuItem
                                            to={Routes.STATISTICS}
                                            activeClassName="activeNavLink"
                                        >
                                            {loco.navigation.statistics}
                                        </TopMenuItem>
                                    </li>
                                )}

                                {(role === Role.SUPER_ADMIN || role === Role.ADMIN) && (
                                    <>
                                        <li>
                                            <TopMenuItem
                                                to={Routes.REVIEW}
                                                activeClassName="activeNavLink"
                                            >
                                                {loco.navigation.approve}
                                            </TopMenuItem>
                                        </li>
                                        {role === Role.SUPER_ADMIN && (
                                            <li>
                                                <TopMenuItem
                                                    to={Routes.SUPER_ADMIN}
                                                    activeClassName="activeNavLink"
                                                >
                                                    {loco.navigation.users}
                                                </TopMenuItem>
                                            </li>
                                        )}
                                        <li>
                                            <TopMenuItem
                                                to={Routes.STATISTICS}
                                                activeClassName="activeNavLink"
                                            >
                                                {loco.navigation.statistics}
                                            </TopMenuItem>
                                        </li>
                                    </>
                                )}
                                {role === Role.SUPER_ADMIN && (
                                    <li>
                                        <TopMenuItem
                                            to={Routes.ACTIVITY}
                                            activeClassName="activeNavLink"
                                        >
                                            {loco.navigation.activity}
                                        </TopMenuItem>
                                    </li>
                                )}
                            </TopMenu>
                        )}

                    {/* OTHER PAGES MENU */}
                    {isOtherPages && (
                        <TopMenu isLp>
                            <li>
                                <TopMenuItem
                                    to={`${Routes.LP}#${Routes.INVESTO_HASH_PARAM}`}
                                    onClick={smoothScrollToIdWithEvent(Routes.INVESTO_HASH_PARAM)}
                                    activeClassName={isActivePath(
                                        hash.includes(Routes.INVESTO_HASH_PARAM)
                                    )}
                                >
                                    {loco.profile.dropdown.lp}
                                </TopMenuItem>
                            </li>
                            <li>
                                <TopMenuItem
                                    to={`${Routes.LP}#${Routes.APP_HASH_PARAM}`}
                                    onClick={smoothScrollToIdWithEvent(Routes.APP_HASH_PARAM)}
                                    activeClassName={isActivePath(
                                        hash.includes(Routes.APP_HASH_PARAM)
                                    )}
                                >
                                    {loco.navigation.app}
                                </TopMenuItem>
                            </li>
                            <li>
                                <TopMenuItem
                                    to={`${Routes.LP}#${Routes.ABOUT_HASH_PARAM}`}
                                    onClick={smoothScrollToIdWithEvent(Routes.ABOUT_HASH_PARAM)}
                                    activeClassName={isActivePath(
                                        hash.includes(Routes.ABOUT_HASH_PARAM)
                                    )}
                                >
                                    {loco.navigation.about}
                                </TopMenuItem>
                            </li>
                            <li>
                                <TopMenuItem
                                    to={`${Routes.LP}#${Routes.EDU_HASH_PARAM}`}
                                    onClick={smoothScrollToIdWithEvent(Routes.EDU_HASH_PARAM)}
                                    activeClassName={isActivePath(
                                        hash.includes(Routes.EDU_HASH_PARAM)
                                    )}
                                >
                                    {loco.navigation.edu}
                                </TopMenuItem>
                            </li>
                            <li>
                                <TopMenuItem
                                    to={`${Routes.LP}#${Routes.FAQ_HASH_PARAM}`}
                                    onClick={smoothScrollToIdWithEvent(Routes.FAQ_HASH_PARAM)}
                                    activeClassName={isActivePath(
                                        hash.includes(Routes.FAQ_HASH_PARAM)
                                    )}
                                >
                                    {loco.navigation.faq}
                                </TopMenuItem>
                            </li>
                        </TopMenu>
                    )}

                    {/* PLATFORM MENU */}
                    {!isOtherPages && !isAdminPage && (
                        <TopMenu>
                            <li>
                                <TopMenuItem
                                    to={Routes.HOME}
                                    activeClassName={isActivePath(pathname === Routes.HOME)}
                                >
                                    {loco.navigation.home}
                                </TopMenuItem>
                            </li>
                            <Tooltip title={isTempOrNotAuth ? loco.tooltips.library : ``}>
                                <li>
                                    <TopMenuItem
                                        to={Routes.LIBRARY}
                                        disabled={isTempOrNotAuth}
                                        activeClassName="activeNavLink"
                                    >
                                        {loco.navigation.library}
                                    </TopMenuItem>
                                </li>
                            </Tooltip>
                        </TopMenu>
                    )}

                    {/* PROFILE || LOGIN BUTTONS */}
                    <Account>
                        {isAuthorized && !Boolean(user?.isTemporary) && (
                            <Profile ref={profileRef}>
                                {/* AVATAR */}
                                <button
                                    type="button"
                                    aria-haspopup="true"
                                    onClick={toggleMenu}
                                    aria-controls="simple-menu"
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        cursor: 'pointer',
                                        background: 'none'
                                    }}
                                >
                                    <Avatar size="small" />
                                </button>

                                {/* PROFILE INFO */}
                                <ProfileInfo
                                    role="button"
                                    onClick={toggleMenu}
                                    data-cy="nav-profile"
                                    ref={notificationIcon}
                                >
                                    <Username paragraph>{getProfileName(user)}</Username>

                                    <Menu
                                        keepMounted
                                        open={isMenuOpen}
                                        onClose={toggleMenu}
                                        anchorEl={profileRef.current}
                                        classes={{ paper: 'topNavMenuPaper' }}
                                    >
                                        <Link to={Routes.PROFILE} style={{ color: 'inherit' }}>
                                            <MenuItem
                                                onClick={toggleMenu}
                                                className="topNavMenuItem"
                                            >
                                                <ProfileIcon className="navPopUpIcon" />
                                                {loco.profile.dropdown.profile}
                                            </MenuItem>
                                        </Link>

                                        <Link to={Routes.HOME} style={{ color: 'inherit' }}>
                                            <MenuItem
                                                onClick={toggleMenu}
                                                className="topNavMenuItem"
                                            >
                                                <PlatformIcon className="navPopUpIcon" />
                                                {loco.navigation.platforma}
                                            </MenuItem>
                                        </Link>

                                        {isAdmin && (
                                            <Link
                                                to={Routes.DASHBOARD}
                                                style={{ color: 'inherit' }}
                                            >
                                                <MenuItem
                                                    onClick={toggleMenu}
                                                    disabled={logoutLoading}
                                                    className="topNavMenuItem"
                                                >
                                                    <AdministrationIcon className="navPopUpIcon" />
                                                    {logoutLoading
                                                        ? `${loco.profile.dropdown.administration} ..`
                                                        : loco.profile.dropdown.administration}
                                                </MenuItem>
                                            </Link>
                                        )}

                                        <Link to={Routes.LP} style={{ color: 'inherit' }}>
                                            <MenuItem
                                                onClick={toggleMenu}
                                                className="topNavMenuItem"
                                            >
                                                <AboutIcon className="navPopUpIcon" />
                                                {loco.profile.dropdown.lp}
                                            </MenuItem>
                                        </Link>

                                        <MenuItem
                                            disabled={logoutLoading}
                                            className="topNavMenuItem"
                                            onClick={() => {
                                                logout()
                                                toggleMenu()
                                            }}
                                        >
                                            <LogoutIcon className="navPopUpIcon" />
                                            {logoutLoading
                                                ? `${loco.profile.dropdown.logout} ..`
                                                : loco.profile.dropdown.logout}
                                        </MenuItem>
                                    </Menu>

                                    {user?.student?.progress && (
                                        <Typography
                                            paragraph
                                            style={{ fontWeight: 500, margin: 0 }}
                                        >
                                            {user?.student?.progress.level.name}
                                        </Typography>
                                    )}
                                </ProfileInfo>

                                {/* NOTIFICATIONS */}
                                <NotificationIconWrapper role="button" onClick={toggleActivity}>
                                    <NotificationIcon color={colors.primary.darkBlue} />
                                </NotificationIconWrapper>
                                <ActivityPopper
                                    open={isActivityOpen}
                                    anchorEl={notificationIcon.current}
                                    handleClose={() => {
                                        setState({ isActivityOpen: false })
                                        document.body.classList.remove('block-scroll')
                                    }}
                                />
                            </Profile>
                        )}
                        {!isOtherPages && (!isAuthorized || Boolean(user?.isTemporary)) && (
                            <>
                                <CustomButton
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                    disabled={isLoading}
                                    data-cy="nav-login-button"
                                    onClick={setDialogId(DIALOG_ID.LOGIN)}
                                >
                                    {loco.dialogs.signup.login}
                                </CustomButton>
                                <CustomButton
                                    size="large"
                                    color="primary"
                                    variant="outlined"
                                    disabled={isLoading}
                                    onClick={setDialogId(DIALOG_ID.REGISTRATION)}
                                >
                                    {loco.dialogs.signup.confirm}
                                </CustomButton>
                            </>
                        )}
                        {isOtherPages && (!isAuthorized || Boolean(user?.isTemporary)) && (
                            <Button
                                size="large"
                                color="primary"
                                variant="contained"
                                disabled={isLoading}
                                onClick={setDialogId(DIALOG_ID.LOGIN)}
                                style={{ fontSize: 14, maxWidth: 230, padding: 12 }}
                            >
                                {loco.dialogs.signup.toApp}
                            </Button>
                        )}
                    </Account>

                    <Burger open={isMobileMenuOpen} onClick={toggleMobileMenu}>
                        <span />
                        <span />
                        <span />
                    </Burger>
                </NavContent>
            </Nav>

            <MobileMenu open={isMobileMenuOpen} toggleMobileMenu={toggleMobileMenu} />
        </>
    )
}

export default NavBar
