import styled from 'styled-components'

import { media } from '@variables'

export const PlayBtnVideo = styled.div<{ readonly isSmall?: boolean }>`
    width: ${(props) => (props.isSmall ? '50px' : '80px')};
    height: ${(props) => (props.isSmall ? '50px' : '80px')};
    position: absolute;
    border-radius: ${(props) => (props.isSmall ? '50px' : '80px')};
    background-color: white;
    box-shadow: 3px 3px 8px hsla(220, 62%, 15%, 0.2);
    cursor: pointer;

    ${media.tablet} {
        &:hover {
            transform: scale(1.1);
        }
    }

    /* Centred */
    left: 50%;
    margin-left: ${(props) => (props.isSmall ? '-25px' : '-40px')};
    top: 50%;
    margin-top: ${(props) => (props.isSmall ? '-25px' : '-40px')};

    display: flex;
    align-items: center;
    justify-content: center;
`

export const PlayBtnAudio = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: 1px solid #e5e6e7;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    float: left;

    ${media.tablet} {
        &:hover {
            transform: scale(1.1);
        }
    }
`
