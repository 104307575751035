import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { DebounceInput } from 'react-debounce-input'
import Typography from '@material-ui/core/Typography'
import MuiContainer from '@material-ui/core/Container'

import { colors, media } from '@variables'

export const Title = styled(Typography)``

export const Container = styled(MuiContainer)`
    margin-bottom: 130px !important;

    @media (max-width: 855px) {
        margin-bottom: 80px !important;
    }
    @media (max-width: 600px) {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    ${Title} {
        margin: 35px 0 30px 0;
        line-height: normal;
        font-weight: 400;
        font-size: 18px;

        @media (max-width: 600px) {
            padding-right: 40px;
            padding-left: 40px;
            margin: 25px 0px;
        }
    }
`

export const LibraryWrapper = styled.div`
    padding: 40px;
    min-height: 425px;
    border-radius: 5px;
    position: relative;
    background-color: white;
    box-shadow: 0px 30px 100px rgba(42, 48, 57, 0.05);

    ${media.tablet} {
        padding: 40px 100px 100px 100px;
    }
`

export const SearchFieldWrapper = styled.div`
    width: 100%;
    height: 50px;
    position: relative;
    margin-bottom: 30px;
`

export const SearchField = styled(DebounceInput)`
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    line-height: 24px;
    border-radius: 8px;
    padding: 0 30px 0 70px;
    background-color: #f1f3f4;
    color: ${colors.primary.darkBlue};

    &::placeholder {
        color: #c0c0c0;
    }
`

export const Menu = styled.ul`
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0 0 30px 0;
`

// SHARED
export const Section = styled.section`
    padding: 0;
    margin-top: 20px;
    border-radius: 5px;
    position: relative;
    background-color: white;
    padding: 70px 40px 40px 40px;
    box-shadow: 0px 30px 100px rgba(42, 48, 57, 0.05);

    ${media.tablet} {
        margin-top: 95px;
        padding: 90px;
    }
`

export const Header = styled.header`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    h3 {
        line-height: 30px;
    }
`

export const BottomHeader = styled.header`
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 45px 0 25px 0;
    justify-content: space-between;
`

export const CustomLink = styled(Link)`
    opacity: 0.5;
    font-size: 13px;
    line-height: 22px;
    text-align: right;
    text-decoration: none;
    color: ${colors.primary.darkBlue};

    ${media.phone} {
        font-size: 14px;
    }

    ${media.tablet} {
        &:hover {
            text-decoration: underline;
        }
    }
`
