import last from 'lodash/last'
import { string, object } from 'yup'
import { useSnackbar } from 'notistack'
import { GraphQLError } from 'graphql'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import React, { useState, useContext } from 'react'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { Form, Field, Formik, FormikProps, FormikHelpers } from 'formik'

import { Step } from '..'
import * as loco from '@loco'
import Icon from '../../../SVG/Loader'
import InputField from '../../../Fields/Input'
import { translateGQLError } from '@utils'
import GoogleButton from '../../../SocialButtons/Google'
import AppleButton from '../../../SocialButtons/Apple'
import { FieldsWrapper } from '../../Registration/styled'
import { useRecaptcha } from '../../../../context/Recaptcha'
import FacebookButton from '../../../SocialButtons/Facebook'
import { AuthContext } from '../../../../context/Auth'
import { DialogContext, DIALOG_ID } from '../../../../context/Dialog'
import { useRegisterTemporaryStudentMutation, MeDocument } from '@graphql'
import {
    H1,
    Forgot,
    Wrapper,
    Divider,
    CreateNewLink,
    BottomButtonWrapper,
    SocialButtonsWrapper
} from './styled'
import { Routes } from '@variables'

type Props = {
    setActiveStep: (step: Step) => void
}

const initialValues = Object.freeze({ email: '', password: '' })

type FormValues = typeof initialValues

const LoginForm = ({ setActiveStep }: Props) => {
    const history = useHistory()
    const recaptcha = useRecaptcha()
    const { enqueueSnackbar } = useSnackbar()

    const { setOpen, setInfoDialogData } = useContext(DialogContext)
    const { login, isAuthorized, user, isLoggingIn } = useContext(AuthContext)

    const [registerTempUser, { loading }] = useRegisterTemporaryStudentMutation({
        refetchQueries: [{ query: MeDocument }]
    })

    const [showPassword, setShowPassword] = useState(false)
    const toggleShowPwd = () => setShowPassword(!showPassword)

    const openReactivateDialog = () => {
        setInfoDialogData({
            title: loco.dialogs.reactivated.title,
            subtitle: loco.dialogs.reactivated.subtitle,
            icon: <Icon style={{ width: 95, height: 'auto' }} />,
            buttonProps: {
                text: loco.common.continue,
                onClick: () => setOpen(DIALOG_ID.NOT_DISPLAYED)
            }
        })
        setOpen(DIALOG_ID.INFO)
    }

    const submit = async (
        { email, password }: FormValues,
        { setErrors }: FormikHelpers<FormValues>
    ) => {
        try {
            const token = await recaptcha.execute('login')

            const { data, errors } = await login(email, password, token)

            if (data) {
                if (data?.login?.isReactivated) {
                    return openReactivateDialog()
                }

                setOpen(DIALOG_ID.NOT_DISPLAYED)
            }
            if (errors) {
                const error: GraphQLError | undefined = last(errors)
                if (error) setErrors({ password: error.message })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const createTempUser = async () => {
        const { errors } = await registerTempUser()
        errors?.forEach((err) =>
            enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
        )
        setOpen(DIALOG_ID.NOT_DISPLAYED)
        history.push(Routes.HOME)
    }

    return (
        <Wrapper>
            <H1>{loco.dialogs.login.title}</H1>

            <Formik
                onSubmit={submit}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {({ isValid, isSubmitting }: FormikProps<FormValues>) => (
                    <Form style={{ width: '100%' }} spellCheck={false}>
                        <SocialButtonsWrapper>
                            <FacebookButton />
                            <GoogleButton />
                            <AppleButton />
                        </SocialButtonsWrapper>

                        <Divider>{loco.dialogs.login.or}</Divider>

                        <FieldsWrapper>
                            <Field
                                required
                                fullWidth
                                autoFocus
                                key="email"
                                name="email"
                                component={InputField}
                                className="customInput"
                                disabled={isLoggingIn || isSubmitting}
                                label={loco.dialogs.login.email.label}
                                placeholder={loco.dialogs.login.email.placeholder}
                                inputProps={{
                                    autoCorrect: 'off',
                                    autoComplete: 'off',
                                    autoCapitalize: 'none'
                                }}
                            />
                            <Field
                                required
                                fullWidth
                                key="password"
                                name="password"
                                component={InputField}
                                className="customInput"
                                autoComplete="current-password"
                                disabled={isLoggingIn || isSubmitting}
                                label={loco.dialogs.login.password.label}
                                type={showPassword ? 'text' : 'password'}
                                placeholder={loco.dialogs.login.password.placeholder}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                onClick={toggleShowPwd}
                                                style={{ margin: '0 0 20px 0' }}
                                                aria-label="toggle password visibility"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </FieldsWrapper>

                        <BottomButtonWrapper>
                            <Button
                                fullWidth
                                size="large"
                                type="submit"
                                color="primary"
                                variant="contained"
                                data-cy="login-dialog-submit"
                                disabled={!isValid || isSubmitting}
                            >
                                {isSubmitting
                                    ? loco.dialogs.login.logginIn
                                    : loco.dialogs.login.confirm}
                            </Button>

                            {!isAuthorized && !Boolean(user?.isTemporary) && (
                                <Button
                                    fullWidth
                                    size="large"
                                    type="button"
                                    color="primary"
                                    variant="outlined"
                                    onClick={createTempUser}
                                    disabled={loading || isSubmitting}
                                >
                                    {loco.dialogs.login.trial}
                                </Button>
                            )}
                        </BottomButtonWrapper>
                    </Form>
                )}
            </Formik>

            <Typography align="center" variant="body1" style={{ margin: '15px 0 10px 0' }}>
                {loco.dialogs.login.first}
                <CreateNewLink onClick={() => setOpen(DIALOG_ID.REGISTRATION)}>
                    <strong>{loco.dialogs.login.signup}</strong>
                </CreateNewLink>
            </Typography>
            <Forgot onClick={() => setActiveStep(Step.FORGOT_PASSWORD)}>
                {loco.dialogs.login.forgot}
            </Forgot>
        </Wrapper>
    )
}

const validationSchema = object({
    email: string().required(loco.validation.common.required),
    password: string().required(loco.validation.password.common)
})

export default LoginForm
