import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M6.75 6.75c3.314 0 6-1.343 6-3s-2.686-3-6-3-6 1.343-6 3 2.686 3 6 3z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M12.75 6.75c0 1.657-2.686 3-6 3s-6-1.343-6-3M12.75 9.75v-6"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M.75 3.75v15c0 1.657 2.686 3 6 3 .756.001 1.51-.073 2.25-.223"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M.75 15.75c0 1.657 2.686 3 6 3 .756.001 1.51-.073 2.25-.223M.75 12.75c0 1.657 2.686 3 6 3 .756.001 1.51-.073 2.25-.223M.75 9.75c0 1.657 2.686 3 6 3 .756.001 1.51-.073 2.25-.223"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M17.25 17.25c3.314 0 6-1.343 6-3s-2.686-3-6-3-6 1.343-6 3 2.686 3 6 3z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M11.25 14.25v6c0 1.657 2.686 3 6 3s6-1.343 6-3v-6"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M23.25 17.25c0 1.657-2.686 3-6 3s-6-1.343-6-3"
            ></path>
        </svg>
    )
}

export default Icon
