import { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField'
import { Autocomplete, AutocompleteProps } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import React, { isValidElement } from 'react'
import { FieldProps, getIn } from 'formik'

const AnyAutocomplete = Autocomplete as any

export interface FormikAutocompleteProps<V extends any = any, FormValues extends any = any>
    extends FieldProps<FormValues>,
        AutocompleteProps<V> {
    textFieldProps: MuiTextFieldProps
}

// https://github.com/mui-org/material-ui/issues/18331
const noOp = () => {}

const FormikAutocomplete = <V extends any = any, FormValues extends any = any>({
    textFieldProps,
    ...props
}: Omit<FormikAutocompleteProps<V, FormValues>, 'renderInput'>) => {
    const {
        form: { setTouched, setFieldValue, touched }
    } = props
    const { error, helperText, ...field } = fieldToTextField(props as any)
    const { name } = field
    const onInputChangeDefault = props.onInputChange ?? noOp
    const onInputChange = !props.freeSolo
        ? props.onInputChange
        : (event: React.ChangeEvent<{}>, value: string, reason: any) => {
              setFieldValue(name!, value)
              onInputChangeDefault(event, value, reason)
          }

    return (
        <AnyAutocomplete
            {...props}
            {...field}
            value={field.value || ''}
            onInputChange={onInputChange}
            onBlur={() => setTouched({ ...touched, [name!]: true })}
            onChange={(_: any, value: any) => setFieldValue(name!, value)}
            renderInput={(props: any) => (
                <TextField
                    {...props}
                    {...textFieldProps}
                    error={error}
                    helperText={isValidElement(helperText) ? helperText : undefined}
                />
            )}
        />
    )
}

export default FormikAutocomplete

interface TextFieldProps extends FieldProps, Omit<MuiTextFieldProps, 'name' | 'value' | 'error'> {}

function fieldToTextField({
    disabled,
    field,
    form: { isSubmitting, touched, errors },
    ...props
}: TextFieldProps): MuiTextFieldProps {
    const fieldError = getIn(errors, field.name)
    const showError = getIn(touched, field.name) && !!fieldError

    return {
        ...props,
        ...field,
        error: showError,
        helperText: showError ? fieldError : props.helperText,
        disabled: disabled ?? isSubmitting,
        variant: props.variant
    }
}
