import { get, last } from 'lodash'
import { GraphQLError } from 'graphql'
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import { useSnackbar } from 'notistack'
import { useSetState } from 'react-use'
import Button from '@material-ui/core/Button'
import React, { useState, useContext } from 'react'
import { HeadsetOutlined } from '@material-ui/icons'
import { useHistory, useParams } from 'react-router-dom'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Formik, FormikProps, Form, Field } from 'formik'

import { FormValues } from '..'
import * as loco from '@loco'
import Header from '../components/Header'
import Head from '../../../../../components/Head'
import { TestBox, ButtonWrapper } from '../styled'
import { TabType } from '../../../../../components/shared'
import { Routes, notificationMsgs } from '@variables'
import Loader from '../../../../../components/shared/Loader'
import InputField from '../../../../../components/Fields/Input'
import { AuthContext } from '../../../../../context/Auth'
import RejectDialog from '../../../../../components/Dialogs/Reject'
import { markdownToHtml, translateGQLError } from '@utils'
import { Container, Box, Label } from '../../../../../components/shared/styled'
import { AudioPlayerContext } from '../../../../../context/AudioPlayer'
import ExpansionPanelTest, { PanelVariant } from '../../../../../components/ExpansionPanels/Test'
import {
    Role,
    Question,
    CreationStatus,
    useAdminLectureQuery,
    ChaptersWithIdDocument,
    useRejectLectureMutation,
    useApproveLectureMutation,
    useRejectLectureUpdateDraftMutation,
    useApproveLectureUpdateDraftMutation,
    FileType
} from '@graphql'

type State = {
    tab: TabType
}

const EditLecture = () => {
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()
    const params = useParams<{ readonly id: string }>()

    const { user } = useContext(AuthContext)
    const { load } = useContext(AudioPlayerContext)

    const [state, setState] = useSetState<State>({ tab: TabType.LECTURE })
    const { tab } = state

    const [open, setOpen] = useState(false)
    const toggleOpen = () => setOpen(!open)

    const handleTab = (newTab: TabType) => setState({ tab: newTab })

    const [reject, { loading: rejectLoading }] = useRejectLectureMutation({
        refetchQueries: [{ query: ChaptersWithIdDocument }]
    })
    const [rejectDraft, { loading: rejectDraftLoading }] = useRejectLectureUpdateDraftMutation({
        refetchQueries: [{ query: ChaptersWithIdDocument }]
    })
    const [approve, { loading: approveLoading }] = useApproveLectureMutation({
        refetchQueries: [{ query: ChaptersWithIdDocument }]
    })
    const [approveDraft, { loading: approveDraftLoading }] = useApproveLectureUpdateDraftMutation({
        refetchQueries: [{ query: ChaptersWithIdDocument }]
    })

    const isLoading = rejectLoading || approveLoading || approveDraftLoading || rejectDraftLoading

    const { data, loading, error } = useAdminLectureQuery({
        variables: { where: { id: params.id } }
    })

    if (loading) return <Loader />
    if (!data || error) {
        error && enqueueSnackbar(translateGQLError(error.message), { variant: 'error' })
        return null
    }

    const { lecture } = data

    const lectureVideo = lecture?.library?.find((i) => i?.__typename === 'Video')
    const lectureAudio = lecture?.library?.find((i) => i?.__typename === 'Audio')

    const drafts = lecture.drafts
    const newDrafts = drafts.filter((d) => !d.isMerged && !d.isRejected)
    const lastDraft = last(newDrafts)

    const submit = async () => {
        if (lecture.status === CreationStatus.APPROVED) {
            if (newDrafts.length > 0) {
                const { data, errors } = await approveDraft({
                    variables: {
                        where: {
                            id: lastDraft?.id
                        }
                    }
                })

                if (!errors && data) {
                    enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
                    history.push({
                        pathname: Routes.REVIEW,
                        search: Routes.CHAPTERS_SEARCH_PARAM
                    })
                } else {
                    errors?.forEach((err) => enqueueSnackbar(err.message, { variant: 'error' }))
                }

                return
            }
        }

        const { data: resData, errors } = await approve({
            variables: { where: { id: lecture.id } }
        })

        if (!errors && resData) {
            enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
            history.push({
                pathname: Routes.REVIEW,
                search: Routes.CHAPTERS_SEARCH_PARAM
            })
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }
    }

    const onReject = async (reason: string): Promise<{ errors: GraphQLError[] | undefined }> => {
        if (lecture.status === CreationStatus.APPROVED) {
            if (newDrafts.length > 0) {
                const { data, errors } = await rejectDraft({
                    variables: {
                        where: {
                            id: lastDraft?.id
                        }
                    }
                })

                if (!errors && data) {
                    enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
                    history.push({
                        pathname: Routes.REVIEW,
                        search: Routes.CHAPTERS_SEARCH_PARAM
                    })
                } else {
                    errors?.forEach((err) =>
                        enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
                    )
                }

                return { errors }
            }
        }

        const { data: resData, errors } = await reject({
            variables: {
                data: { reason: reason },
                where: { id: lecture.id }
            }
        })

        if (!errors && resData) {
            enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
            history.push({
                pathname: Routes.REVIEW,
                search: Routes.CHAPTERS_SEARCH_PARAM
            })
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }

        return { errors }
    }

    const init = (): FormValues => {
        if (lecture.status === CreationStatus.APPROVED) {
            const draftFiles = get(lastDraft, 'files', [])
            const draftVideo = last(draftFiles.filter((d) => d.type === FileType.VIDEO))
            const draftAudio = last(draftFiles.filter((d) => d.type === FileType.AUDIO))

            return {
                title: lastDraft?.name || lecture.name,
                summary: lastDraft?.summary || lecture.summary,
                chapter: lastDraft?.chapter || lecture.chapter,
                video: draftVideo?.src || get(lectureVideo, 'src', ''),
                audio: draftAudio?.src || get(lectureAudio, 'src', ''),
                description: lastDraft?.description || lecture.description,
                preview: draftVideo?.previewSrc || get(lectureVideo, 'preview', ''),
                questions: lastDraft?.test?.questions || get(lecture, 'test.questions', [])
            }
        } else
            return {
                title: lecture.name,
                summary: lecture.summary,
                chapter: lecture.chapter,
                description: lecture.description,
                video: get(lectureVideo, 'src', ''),
                audio: get(lectureAudio, 'src', ''),
                preview: get(lectureVideo, 'preview', ''),
                questions: get(lecture, 'test.questions', [])
            }
    }

    const initValues = init()
    const isSameAuthor =
        (lecture.status === CreationStatus.APPROVED && lastDraft?.id
            ? lastDraft.author.id === user?.id
            : lecture.author.id === user?.id) || user?.role === Role.SUPER_ADMIN

    return (
        <>
            <Head title={loco.seo.lecture.review.title} />

            <Container>
                <RejectDialog
                    open={open}
                    reject={onReject}
                    close={toggleOpen}
                    title={loco.dialogs.reject.lecture}
                />

                <Formik onSubmit={submit} initialValues={initValues}>
                    {(props: FormikProps<FormValues>) => {
                        const { values, isSubmitting } = props
                        const {
                            video,
                            title,
                            preview,
                            chapter,
                            summary,
                            questions,
                            description
                        } = values

                        return (
                            <Form style={{ width: '100%' }} spellCheck={false}>
                                <Header
                                    isSubmitting={isSubmitting || isLoading}
                                    variant={PanelVariant.REVIEW}
                                    isSameAuthor={isSameAuthor}
                                    handleOpen={toggleOpen}
                                    lectureId={lecture.id}
                                    handleTab={handleTab}
                                    tab={tab}
                                    isValid
                                />

                                {tab === TabType.LECTURE && (
                                    <Box style={{ padding: '30px 40px 40px 40px' }}>
                                        <Field
                                            disabled
                                            fullWidth
                                            key="title"
                                            name="title"
                                            value={title}
                                            component={InputField}
                                            className="customInput"
                                            label={loco.admin.create.lecture.name.label}
                                        />

                                        <Field
                                            disabled
                                            fullWidth
                                            key="chapter"
                                            name="chapter"
                                            value={chapter?.name}
                                            component={InputField}
                                            className="customInput"
                                            label={loco.admin.create.lecture.chapter}
                                        />

                                        <Field
                                            disabled
                                            multiline
                                            fullWidth
                                            key="description"
                                            name="description"
                                            value={description}
                                            component={InputField}
                                            className="customInput"
                                            label={loco.admin.create.lecture.description.label}
                                        />

                                        <Field
                                            disabled
                                            fullWidth
                                            multiline
                                            key="summary"
                                            name="summary"
                                            value={summary}
                                            component={InputField}
                                            className="customInput"
                                            label={loco.admin.create.lecture.summary.label}
                                        />

                                        <ButtonWrapper>
                                            <div style={{ width: '49%' }}>
                                                <Label>{loco.admin.create.lecture.video}</Label>
                                                <ReactPlayer
                                                    muted
                                                    controls
                                                    url={video}
                                                    pip={false}
                                                    width="100%"
                                                    height="210px"
                                                    config={{
                                                        file: {
                                                            attributes: {
                                                                disablePictureInPicture: true
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div style={{ width: '49%' }}>
                                                <Label>{loco.admin.create.lecture.icon}</Label>
                                                <Preview src={preview} />
                                            </div>
                                        </ButtonWrapper>

                                        <Label>{loco.admin.create.lecture.audio}</Label>
                                        <Button
                                            fullWidth
                                            size="large"
                                            type="button"
                                            color="primary"
                                            variant="outlined"
                                            onClick={() =>
                                                load(
                                                    get(lectureAudio, 'id', ''),
                                                    get(lectureAudio, 'src', '#')
                                                )
                                            }
                                        >
                                            <HeadsetOutlined style={{ marginRight: 10 }} />
                                            {loco.lecture.listen}
                                        </Button>

                                        <Label>{loco.admin.create.lecture.transcription}</Label>
                                        <div
                                            style={{ width: '100%' }}
                                            dangerouslySetInnerHTML={{
                                                __html: markdownToHtml(lecture.transcript)
                                            }}
                                        />
                                    </Box>
                                )}

                                {tab === TabType.TEST &&
                                    questions.map((q: Question, idx: number) => (
                                        <TestBox key={q.id}>
                                            <ExpansionPanelTest
                                                panelVariant={PanelVariant.REVIEW}
                                                testId={lecture.test?.id || ''}
                                                isValid={true}
                                                question={q}
                                                index={idx}
                                            />
                                        </TestBox>
                                    ))}
                            </Form>
                        )
                    }}
                </Formik>
            </Container>
        </>
    )
}

const Preview = styled.img`
    width: 100%;
    height: 210px;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 0px 5px 5px rgba(42, 48, 57, 0.05);
`

export default EditLecture
