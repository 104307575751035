import styled from 'styled-components'

import { media, colors } from '@variables'
import { DialogContent as Content } from '../../shared/styled'

export const DialogContent = styled(Content)<{ registration?: boolean }>`
    ${media.desktop} {
        ${(props) => props.registration && ` padding: 65px 40px 0 40px; `}
    }
`

export const Forgot = styled.a`
    color: ${colors.primary.darkBlue};
    text-decoration: underline;
    cursor: pointer;

    ${media.tablet} {
        &:hover {
            text-decoration: none;
        }
    }
`

export const FieldsWrapper = styled.div`
    width: 100%;

    ${media.tablet} {
        margin: 0 auto;
        max-width: 340px;
    }
`

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    margin: 30px 0;
    align-items: center;
    flex-direction: column;

    ${media.tablet} {
        margin-bottom: 50px;
    }
`

export const Wrapper = styled.div<{ readonly visible: boolean }>`
    width: 100%;
    display: ${(props) => (props.visible ? 'block' : 'none')};
`
