import React, { useContext } from 'react'

import { AuthContext } from '../../../context/Auth'
import CoinsIcon from '../../SVG/Coins'
import { Wrapper, Avatar, Name, Score, Place, Left, Right } from './styled'

type Props = {
    idx: number
    name: string
    place: string
    score: number
    avatar: string
    style?: React.CSSProperties
}

const Leader = ({ idx, name, place, score, avatar, style }: Props) => {
    const { user } = useContext(AuthContext)
    const active = user?.username === name

    return (
        <Wrapper
            active={active}
            place={place}
            style={style || {}}
            id={active ? 'leadrboard-active' : ''}
        >
            <Left>
                <Place paragraph style={{ width: 48 }}>
                    {place}
                </Place>
                <Avatar src={avatar} alt={name} loading="lazy" />
                <Name>{name}</Name>
            </Left>

            <Right>
                <Score>{score}</Score>
                <CoinsIcon />
            </Right>
        </Wrapper>
    )
}

export default Leader
