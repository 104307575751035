import React from 'react'
import { Link } from 'react-router-dom'

import * as loco from '@loco'
import { Routes } from '@variables'
import { getRouteWithId } from '@utils'
import { Wrapper, Title, Description, Text } from './styled'

type Props = {
    id: string
    title: string
    description: string
}

const LectureBox = ({ title, description, id }: Props) => (
    <Link to={getRouteWithId(Routes.LIBRARY_TEXT, id)}>
        <Wrapper>
            <Title variant="body1" title={title}>
                {title}
            </Title>
            <Description variant="body2" title={description}>
                {description}
            </Description>
            <Text variant="body2">{loco.common.open}</Text>
        </Wrapper>
    </Link>
)

export default LectureBox
