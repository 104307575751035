import React from 'react'
import { Typography, CircularProgress } from '@material-ui/core'

import { LoadMore } from '../styled'
import { TabPanel, Props } from '..'
import * as loco from '@loco'
import ExpansionPanel from '../../ExpansionPanels/Library'
import { WordFragment, LibraryCategory } from '@graphql'

const VocabularyLibrary = (props: Props) => {
    const { selected, maxItems, loadMore, data, isLoading } = props

    return (
        <TabPanel value={selected} index={LibraryCategory.VOCABULARY}>
            {data.length === 0 && !isLoading && (
                <Typography variant="h6" align="center" style={{ paddingTop: 80, width: '100%' }}>
                    {loco.library.search['no-results']}
                </Typography>
            )}

            {isLoading && data.length === 0 && (
                <CircularProgress
                    style={{
                        display: 'block',
                        margin: '100px auto 0 auto'
                    }}
                />
            )}

            {data
                // @ts-ignore
                .map((item: WordFragment) => (
                    <ExpansionPanel
                        key={item.id}
                        title={item.word}
                        description={item.description}
                    />
                ))}
            {data.length > 0 && maxItems > data.length && (
                <LoadMore onClick={loadMore} disabled={isLoading}>
                    {isLoading ? `${loco.library.more} ..` : loco.library.more}
                </LoadMore>
            )}
        </TabPanel>
    )
}

export default VocabularyLibrary
