import styled from 'styled-components'

import { getColor } from './Icons'
import { ActivityType } from '@graphql'

export const IconWrapper = styled.div<{ readonly activity: ActivityType }>`
    width: 32px;
    height: 32px;
    display: flex;
    min-width: 32px;
    min-height: 32px;
    margin-right: 15px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => getColor(props.activity, 0.1)};

    svg {
        width: 50%;
    }
`
