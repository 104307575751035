import React, { CSSProperties } from 'react'

import Tooltip from '../Tooltip'
import { Wrapper } from './styled'
import { truncateText } from '@utils'

type Props = {
    maxChars?: number
    className?: string
    text?: string | null
    style?: CSSProperties
    icon?: React.ReactNode
    parentId?: string | null
    children?: Array<React.ReactNode>
}

const Row = ({ text, parentId, style, className, icon, children, maxChars = 0 }: Props) => {
    const isChild = Boolean(parentId)
    return (
        <Wrapper style={style || {}} className={className || ''}>
            {children ? (
                children.map((children) => children)
            ) : (
                <>
                    {icon || null}{' '}
                    <Tooltip title={text && maxChars && text.length > maxChars ? text : ''}>
                        <span style={isChild ? { opacity: 0.5 } : {}}>
                            {maxChars ? truncateText(text || '', maxChars) : text}
                        </span>
                    </Tooltip>
                </>
            )}
        </Wrapper>
    )
}

export default Row
