import { Question, Chapter } from '@graphql'

export type FormValues = {
    title: string
    summary: string
    description: string
    questions: Array<Question>
    chapter: Pick<Chapter, 'id' | 'name'> | undefined

    // Files
    audio?: any
    video?: any
    preview?: any
}

export const addLocalQuestion = async (
    questions: Question[] | undefined,
    setFieldValue: Function
) => {
    const arr = questions ? questions : []

    const localQuestion = {
        id: '',
        text: '',
        description: '',
        answers: [
            {
                text: '',
                isCorrect: true
            },
            {
                text: '',
                isCorrect: false
            },
            {
                text: '',
                isCorrect: false
            }
        ]
    }

    setFieldValue('questions', [...arr, localQuestion])
}
