import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="24"
            fill="none"
            viewBox="0 0 23 24"
            {...props}
        >
            <path
                fill="#F8A531"
                d="M11.282.762L0 12.107l11.282 11.346 11.282-11.346L11.282.762z"
            ></path>
        </svg>
    )
}

export default Icon
