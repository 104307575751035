import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            fill="none"
            viewBox="0 0 26 26"
            {...props}
        >
            <circle cx="13" cy="13" r="12" stroke="#F59100" strokeWidth="2"></circle>
            <path stroke="#F59100" strokeWidth="2" d="M6.143 13h14M13.143 20V6"></path>
        </svg>
    )
}

export default Icon
