import styled from 'styled-components'
import { Button } from '@material-ui/core'

import { media } from '@variables'
import { colors } from '@variables'

type IsRightTextProps = {
    readonly isRight: boolean
}

type TipProps = {
    readonly color: string
    readonly isLoading: boolean
}

export const NextButton = styled(Button)``

export const TipWrapper = styled.div<TipProps>`
    display: flex;
    padding: 40px 0;
    min-height: 140px;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${(props) => props.color};

    ${media.desktop} {
        padding: 0 100px;
        flex-direction: row;
    }

    ${NextButton} {
        width: 250px;

        ${media.tablet} {
            width: 220px;
        }

        ${(props) =>
            props.isLoading &&
            `
                :disabled {
                    color: #cdcecf !important;
                    border: 1px solid #eaeaea !important;
                    background-color: rgba(0, 0, 0, 0) !important;
                }
        `}
    }
`

export const TipCharacterWrapper = styled.div`
    height: auto;
    width: 100px;
    min-width: 100px;
`

export const TipCenter = styled.div`
    display: flex;
    max-width: 90%;
    padding: 30px 0;
    text-align: center;
    flex-direction: column;

    ${media.desktop} {
        max-width: 55%;
        padding: 30px 0 30px 80px;
    }
`

export const IsRight = styled.div`
    align-items: center;
    display: inline-flex;
    justify-content: center;
    margin-bottom: 15px;
`
export const IsRightText = styled.p<IsRightTextProps>`
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    padding-left: 10px;
    color: ${(props) => (props.isRight ? colors.primary.green : colors.primary.red)};
`

export const TipPrice = styled.div`
    display: flex;
    margin-top: 10px;
    align-items: center;
    flex-direction: row;
    justify-content: center;
`

export const TipPriceText = styled.span`
    margin-left: 10px;
`
