import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import { Title1, Title3 } from '../styled'
import { colors, media } from '@variables'

export const Title = styled(Title1)``
export const TopTitle = styled(Title3)``
export const ItemText = styled(Typography)``

export const Wrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    background-color: white;
    color: ${colors.primary.darkBlue};

    padding: 40px 0;

    ${media.tablet} {
        padding: 180px 0;
    }
`

export const Image = styled.img`
    width: calc(100% + 40px);
    position: relative;

    ${media.xl} {
        position: absolute;
        transform: translateY(-50%);
        right: -240px;
        width: 930px;
        top: 50%;
    }
    ${media.custom(1500)} {
        right: 50px;
    }
    ${media.custom(2200)} {
        right: 200px;
    }
    ${media.custom(2500)} {
        right: 400px;
    }
    ${media.custom(2900)} {
        right: 600px;
    }
    ${media.custom(3300)} {
        right: 800px;
    }
`

export const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: flex-start;
    justify-content: space-between;

    ${Title} {
        margin: 20px 0 40px 0;

        ${media.tablet} {
            margin: 10px 0 60px 0;
        }
    }
`

export const Left = styled.div`
    width: 100%;

    ${media.xl} {
        width: 45%;
    }
`

export const Row = styled.div`
    display: flex;
    flex-direction: column;
`

export const ItemImg = styled.img`
    width: 40px;
    height: 40px;

    ${media.tablet} {
        width: 70px;
        height: 70px;
    }
`

export const Item = styled.div`
    display: flex;
    flex: 1 0 100%;
    max-width: 380px;
    flex-direction: row;
    align-items: center;
    margin-bottom: 36px;

    ${media.tablet} {
        flex: 1 0 50%;
    }

    ${media.xl} {
        :nth-of-type(odd) {
            ${ItemText} {
                padding-right: 20px;
            }
        }
    }

    ${ItemText} {
        font-weight: 500;
        margin-left: 20px;
        font-size: 16px;
        line-height: 26px;

        ${media.tablet} {
            font-size: 20px;
            line-height: 32px;
        }
    }
`
