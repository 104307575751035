import React, { useContext } from 'react'

import { truncateText } from '@utils'
import { useDeleteNotificationsMutation } from '@graphql'
import { Wrapper, Img, Description, Title, Close } from './styled'
import { NotificationContext } from '../../../../../context/Notification'

type Props = {
    id: string
    img: string
    title: string
    body?: string | null
}

const Activity = ({ id, img, title, body }: Props) => {
    const { refetch } = useContext(NotificationContext)

    const [deleteNotification, { loading: deleteLoading }] = useDeleteNotificationsMutation({
        variables: { id: [id] },
        onCompleted: async () => {
            await refetch()
        }
    })

    return (
        <Wrapper disabled={deleteLoading}>
            <Close onClick={() => deleteNotification()} />
            <Img src={img} alt={title} loading="lazy" />
            <div style={{ paddingLeft: 20 }}>
                <Title
                    title={title}
                    variant="body1"
                    style={body ? {} : { height: 'inherit', margin: 0 }}
                >
                    {truncateText(title, body ? 35 : 85)}
                </Title>

                {body && (
                    <Description title={body} variant="body2">
                        {truncateText(body, 69)}
                    </Description>
                )}
            </div>
        </Wrapper>
    )
}

export default Activity
