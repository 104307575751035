import styled from 'styled-components'

import { media } from '@variables'

export const Time = styled.time`
    font-size: 14px;
    line-height: 22px;
    opacity: 0.5;

    :first-of-type {
        margin-right: 10px;
    }
    :last-of-type {
        margin-left: 10px;
    }
`
