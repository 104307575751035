import styled from 'styled-components'
import { Typography, Button } from '@material-ui/core'

import { media } from '@variables'

export const Question = styled(Typography)`
    max-width: 300px;
    font-weight: 300;
    margin: 0 auto !important;
    font-size: 20px !important;
    line-height: 26px !important;

    ${media.phone} {
        max-width: 650px;
    }
`

export const AnswerButton = styled(Button)``

export const Wrapper = styled.div`
    padding: 30px 30px 40px 30px;
`

export const ButtonWrapper = styled.div<{ readonly isLoading: boolean }>`
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ${media.tablet} {
        flex-direction: row;
    }

    ${AnswerButton} {
        ${media.phone} {
            max-width: 290px;
        }

        ${(props) =>
            props.isLoading &&
            `
                :disabled {
                    color: #cdcecf !important;
                    border: 1px solid #eaeaea !important;
                    background-color: rgba(0, 0, 0, 0) !important;
                }
        `}
    }
`
