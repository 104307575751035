import styled from 'styled-components'

import { colors } from '@variables'

export const Audio = styled.div`
    width: 100%;
    height: 120px;
    padding: 20px;
    position: relative;
    border-radius: 5px;
    background-color: #fafafa;
`

export const AudioBoxContent = styled.div`
    float: right;
    width: calc(100% - 55px);
`

export const AudioBoxTitle = styled.h6`
    margin: 0;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    overflow: hidden;
    color: ${colors.primary.darkBlue};
`

export const AudioBoxDuration = styled.div`
    color: #929599;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    left: 75px;

    span {
        margin-left: 5px;
    }
`
