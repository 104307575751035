import React from 'react'
import Grid from '@material-ui/core/Grid'

import * as loco from '@loco'
import Arrow from '../../../shared/Arrow'
import { getRouteWithId } from '@utils'
import { colors, Routes } from '@variables'
import DiamondIcon from '../../../SVG/Coins'
import { Wrapper, Container, Box, Link, NavText, PointsText, Title } from './styled'
import { ChaptersWithoutLecturesQuery, Chapter, CreationStatus } from '@graphql'

type Props = {
    chapter: Pick<Chapter, 'index'>
    chapters: ChaptersWithoutLecturesQuery['chapters']
}

const Pagination = ({ chapters, chapter }: Props) => {
    const prev = [...chapters]
        .reverse()
        .find((c) => c.index < chapter.index && c.status === CreationStatus.APPROVED)
    const next = chapters.find(
        (c) => c.index > chapter.index && c.status === CreationStatus.APPROVED
    )

    if (!prev && !next) return null

    return (
        <Wrapper>
            <Container container spacing={3}>
                {/* Prev chapter */}
                <Grid item xs={12} sm={6}>
                    {prev && (
                        <Box bkgColor={'white'}>
                            <Link to={getRouteWithId(Routes.CHAPTER, prev.id)} />
                            <NavText variant="body1">
                                <Arrow variant="left" style={{ marginRight: 5, height: 20 }} />
                                {loco.chapter.navigation.prev}
                            </NavText>
                            <Title variant="h3">
                                {prev.name}

                                <PointsText variant="body2">
                                    <DiamondIcon
                                        style={{ marginRight: 10 }}
                                        color={
                                            (!Boolean(prev?.acquiredPoints) ||
                                                prev?.acquiredPoints === 0) &&
                                            colors.primary.gray1
                                        }
                                    />
                                    {prev?.acquiredPoints || 0}/{prev?.maxPoints}{' '}
                                    {loco.common.points}
                                </PointsText>
                            </Title>
                        </Box>
                    )}
                </Grid>

                {/* Next chapter */}
                <Grid item xs={12} sm={6}>
                    {next && (
                        <Box bkgColor={colors.primary.orange}>
                            <Link to={getRouteWithId(Routes.CHAPTER, next.id)} />
                            <NavText variant="body1">
                                {loco.chapter.navigation.next}
                                <Arrow
                                    variant="right"
                                    style={{
                                        color: 'white',
                                        marginLeft: 5,
                                        height: 20
                                    }}
                                />
                            </NavText>
                            <Title variant="h3">
                                {next.name}

                                <PointsText variant="body2">
                                    <DiamondIcon
                                        style={{ marginRight: 10 }}
                                        color={
                                            (!Boolean(next?.acquiredPoints) ||
                                                next?.acquiredPoints === 0) &&
                                            'white'
                                        }
                                    />
                                    {next?.acquiredPoints || 0}/{next?.maxPoints}{' '}
                                    {loco.common.points}
                                </PointsText>
                            </Title>
                        </Box>
                    )}
                </Grid>
            </Container>
        </Wrapper>
    )
}

export default Pagination
