import styled from 'styled-components'
import MuiButton from '@material-ui/core/Button'
import { NavLink as Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

import { colors, media, transition } from '@variables'

export const CustomButton = styled(MuiButton)``

export const Nav = styled.nav<{ readonly isVisible: boolean }>`
    width: 100%;
    height: 90px;
    z-index: 1000;
    background-color: white;
    transition: top ease-in 0.2s;
    border-bottom: 1px solid #f7f7f7;

    top: ${(props) => (props.isVisible ? 0 : '-90px')};
    position: fixed;
    left: 0;

    &:after {
        background: #f8f8f9;
        position: initial;
        height: 25px;
        width: 100%;
        content: '';
        left: 0;
        top: 0;
    }

    ${CustomButton} {
        font-size: 10px !important;

        :last-of-type {
            margin-left: 10px;
        }
    }
`

export const NavContent = styled.div`
    height: 100%;
    display: flex;
    padding: 0 15px;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    ${media.phone} {
        padding: 0 30px;
    }
`

export const Logo = styled.img`
    width: 100px;
    height: auto;

    ${media.phone} {
        width: 140px;
        height: auto;
    }
`

export const LogoLink = styled.div`
    order: 1;
    border: none;
    outline: none;
`

export const TopMenuItem = styled(Link)<{ readonly disabled?: boolean }>`
    font-size: 14px;
    position: relative;
    display: inline-block;
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;
    color: ${colors.primary.darkBlue};

    ${(props) =>
        props.disabled &&
        `
        pointer-events: none;
        cursor: default;
        color: #878787;
    `}

    ${media.tablet} {
        :hover {
            :not(.activeNavLink) {
                :after {
                    width: 100%;
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: -35px;
                    width: 50px;
                    height: 4px;
                    border-radius: 5px 5px 0 0;

                    /* Centered */
                    margin-left: auto;
                    margin-right: auto;
                    left: 0;
                    right: 0;

                    background-color: #ededed;
                }
            }
        }
    }
`

export const TopMenu = styled.ul<{ readonly isLp?: boolean }>`
    order: 2;
    margin: 0;
    padding: 0;
    display: none;
    max-width: 60%;
    text-indent: 0;
    list-style-type: none;

    ${({ isLp }) =>
        isLp &&
        `
        padding-left: 40px;
        
        ${TopMenuItem} {
            font-weight: 400;
            text-transform: none;
        }    
    `}

    ${media.xl} {
        display: inline-flex;
    }

    li {
        :not(:last-child) {
            padding-right: 20px;

            ${media.xl} {
                padding-right: 30px;
            }
        }
    }
`

export const Account = styled.div`
    order: 3;
    display: none;

    ${media.xl} {
        display: block;
    }
`

export const NotificationIconWrapper = styled.div`
    cursor: pointer;

    ${media.tablet} {
        :hover {
            svg {
                transform: scale(1.1);
                transition: ${transition.main};
            }
        }
    }
`

export const Burger = styled.div<{ readonly open: boolean }>`
    top: 50px;
    left: 50px;
    z-index: 1;
    order: 4;
    display: block;
    user-select: none;
    -webkit-user-select: none;

    ${media.xl} {
        display: none;
    }

    span {
        z-index: 1;
        width: 18px;
        height: 2px;
        display: block;
        margin-bottom: 4px;
        position: relative;
        border-radius: 3px;
        transform-origin: 5px 1px;
        background: ${colors.primary.darkBlue};

        &:first-of-type {
            transform: ${(props) => (props.open ? 'rotate(45deg)' : 'inherit')};
        }
        &:last-of-type {
            transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'inherit')};
        }

        &:nth-of-type(2) {
            display: ${(props) => (props.open ? 'none' : 'block')};
        }
    }
`

export const Profile = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`

export const Username = styled(Typography)``

export const ProfileInfo = styled.div`
    display: flex;
    cursor: pointer;
    position: relative;
    padding: 0 45px 0 15px;
    flex-direction: column;
    align-items: self-start;

    ${Username} {
        margin: 0;
        font-weight: 700;
    }
`
