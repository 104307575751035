import styled from 'styled-components'
import MuiStepper from '@material-ui/core/Stepper'
import MuiContainer from '@material-ui/core/Container'

import { media, colors } from '@variables'

export const Stepper = styled(MuiStepper)``

export const Container = styled(MuiContainer)`
    margin-bottom: 100px !important;

    @media (max-width: 855px) {
        margin-bottom: 50px !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    ${Stepper} {
        padding: 0px 200px;

        @media (max-width: 855px) {
            padding: 0px 20px;
        }
    }
`

export const Section = styled.div`
    margin-top: 20px;
    padding-top: 80px;
    border-radius: 5px;
    position: relative;
    background-color: white;
    box-shadow: 0px 30px 100px rgba(42, 48, 57, 0.05);

    ${media.tablet} {
        margin-top: 55px;
        padding-top: 50px;
    }
`

export const Span = styled.span`
    font-weight: 300;
    color: ${colors.primary.darkBlue};
`

export const MobileStepper = styled.div`
    top: 35px;
    right: 35px;
    display: flex;
    color: #a4a6aa;
    font-weight: 700;
    position: absolute;
    align-items: center;
    flex-direction: row;
    justify-content: center;
`
