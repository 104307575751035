import React from 'react'
import { Link } from 'react-router-dom'

import PlayButton from '../PlayButton'
import { Routes } from '@variables'
import { formatSecondsToMinutes, getRouteWithId } from '@utils'
import { Video, VideoPreview, VideoTitle, VideoTag, Duration } from './styled'

type Props = {
    id: string
    tag?: string | null
    name?: string | null
    playBtnColor: string
    preview?: string | null
    duration?: number | null
}

const VideoBox = ({ id, playBtnColor, tag, name, preview, duration }: Props) => (
    <Video>
        <Link to={getRouteWithId(Routes.LIBRARY_VIDEO, id)}>
            <VideoPreview img={preview || ''}>
                <PlayButton playBtnColor={playBtnColor} type="video" isSmall={true} />
                <Duration>{formatSecondsToMinutes(duration || 0)}</Duration>
            </VideoPreview>
        </Link>

        <Link to={getRouteWithId(Routes.LIBRARY_VIDEO, id)}>
            {name ? <VideoTitle title={name}>{name}</VideoTitle> : ''}
        </Link>
        <VideoTag>{tag || ''}</VideoTag>
    </Video>
)

export default VideoBox
