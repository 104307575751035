import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import ErrIcon from '../../SVG/404'

export const Text = styled(Typography)``

export const Wrapper = styled.span`
    box-shadow: 0px 10px 50px rgba(42, 48, 57, 0.05);
    background-color: white;
    border-radius: 5px;
    position: absolute;
    height: 50px;
    right: 10px;
    z-index: 2;
    top: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px 10px 0;
    justify-content: space-between;

    ${Text} {
        font-size: 14px;
        margin-left: 10px;
        line-height: 20px;
    }
`

export const Icon = styled(ErrIcon)`
    width: 55px;
    height: auto;
`
