import React from 'react'

import { Time } from './styled'
import { formatSecondsToMinutes } from '@utils'

type Props = {
    seconds: number
    className?: string
}

export default function Duration({ seconds, className }: Props) {
    return (
        <Time dateTime={`P${Math.round(seconds)}S`} className={className || ''}>
            {formatSecondsToMinutes(seconds)}
        </Time>
    )
}
