import React, { useContext } from 'react'
import Icon from '@material-ui/icons/Apple'

import loco from '@loco'
import { Wrapper } from './styled'
import { AuthContext } from '../../../context/Auth'

const Apple = () => {
    const { loading, isLoggingIn, logoutLoading } = useContext(AuthContext)

    const appleClientId = process.env.RAZZLE_APPLE_CLIENT_ID
    const redirectUrl = `https://${process.env.RAZZLE_HOST}/verify-apple`

    const isLoading = loading || isLoggingIn || logoutLoading

    return (
        <Wrapper
            style={isLoading ? { pointerEvents: 'none', opacity: 0.8 } : {}}
            href={`https://appleid.apple.com/auth/authorize?client_id=${appleClientId}&redirect_uri=${redirectUrl}&response_type=code&response_mode=form_post&scope=email`}
        >
            <Icon style={{ marginRight: 10 }} />
            <span>{isLoggingIn ? loco.dialogs.login.logginIn : loco.dialogs.login.apple}</span>
        </Wrapper>
    )
}

export default Apple
