import React from 'react'
import { Grid, Typography, CircularProgress } from '@material-ui/core'

import * as loco from '@loco'
import { TabPanel, Props } from '..'
import { LoadMore } from '../styled'
import { colors } from '@variables'
import VideoBox from '../../shared/VideoBox'
import { LibraryCategory, VideoFragment } from '@graphql'

const VideoLibrary = (props: Props) => {
    const { selected, maxItems, loadMore, data, isLoading } = props

    return (
        <TabPanel value={selected} index={LibraryCategory.VIDEO}>
            <Grid container spacing={2}>
                {data.length === 0 && !isLoading && (
                    <Typography
                        variant="h6"
                        align="center"
                        style={{ paddingTop: 80, width: '100%' }}
                    >
                        {loco.library.search['no-results']}
                    </Typography>
                )}

                {isLoading && data.length === 0 && (
                    <CircularProgress
                        style={{
                            display: 'block',
                            margin: '100px auto 0 auto'
                        }}
                    />
                )}

                {data
                    // @ts-ignore
                    .map((item: VideoFragment) => (
                        <Grid item xs={12} sm={6} md={3} key={item.id}>
                            <VideoBox
                                id={item.id}
                                name={item.name}
                                preview={item.preview}
                                duration={item.length}
                                tag={item?.lecture?.chapter?.name}
                                playBtnColor={colors.primary.orange}
                            />
                        </Grid>
                    ))}
            </Grid>
            {data.length > 0 && maxItems > data.length && (
                <LoadMore onClick={loadMore} disabled={isLoading}>
                    {isLoading ? `${loco.library.more} ..` : loco.library.more}
                </LoadMore>
            )}
        </TabPanel>
    )
}

export default VideoLibrary
