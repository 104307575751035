import styled from 'styled-components'

import { colors } from '@variables'

type MenuItemProps = {
    readonly active: boolean
}

export const MenuItem = styled.li<MenuItemProps>`
    display: inline;
    cursor: pointer;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    position: relative;
    margin-right: 35px;
    outline: none !important;
    color: ${(props) => (props.active ? '#555F70' : '#d4d7db')};

    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        bottom: -5px;
        background-color: ${colors.primary.orange};
        display: ${(props) => (props.active ? 'block' : 'none')};
    }
`

export const LoadMore = styled.button`
    border: 0;
    width: 100%;
    height: 45px;
    outline: none;
    display: flex;
    margin: 0 auto;
    margin-top: 30px;
    cursor: pointer;
    font-size: 12px;
    max-width: 200px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    background-color: #fafafa;
    color: ${colors.primary.darkBlue};

    :disabled {
        cursor: not-allowed;
        pointer-events: none;
    }
`
