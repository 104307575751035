import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { Link as RLink } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

import { media } from '@variables'
import { transition } from '@variables'

export const PointsText = styled(Typography)``
export const NavText = styled(Typography)``
export const Title = styled(Typography)``
export const Container = styled(Grid)``
export const Link = styled(RLink)``

export const Wrapper = styled.div`
    ${Container} {
        z-index: 1;
        padding: 40px 0 0 0;
        justify-content: center;

        @media (max-width: 855px) {
            padding: 25px 0 20px 0;
        }
        @media (max-width: 480px) {
            justify-content: space-between;
        }
    }

    ${Title} {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 855px) {
            font-size: 14px;
        }
    }

    ${NavText} {
        display: inline-flex;
        align-items: center;
        margin-bottom: 25px;

        @media (max-width: 855px) {
            font-size: 13px;
            margin-bottom: 20px;
        }
    }

    ${PointsText} {
        display: inline-flex;
        margin-top: 5px;
    }

    ${Link} {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 2;
        left: 0;
        top: 0;
    }
`

export const Box = styled.div<{ readonly bkgColor: string }>`
    z-index: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    transition: ${transition.main};
    background-color: ${(props) => props.bkgColor};
    box-shadow: 0px 30px 100px rgba(42, 48, 57, 0.05);
    color: ${(props) => (props.bkgColor === 'white' ? 'inherit' : 'white')};

    ${media.tablet} {
        padding: 30px;

        :hover {
            box-shadow: 0px 10px 50px rgba(42, 48, 57, 0.1);
        }
    }
`
