import React from 'react'

import * as loco from '@loco'
import { Routes } from '@variables'
import { getRouteWithId } from '@utils'
import { Wrapper, Text, Link, Icon } from './styled'

type Props = {
    chapter: {
        name: string
        id: string
    }
    lecture: {
        name: string
        id: string
    }
}

const Breadcrumbs = ({ chapter, lecture }: Props) => (
    <Wrapper>
        <Link to={Routes.HOME} style={{ display: 'flex' }}>
            <Icon />
            <Text variant="body2">{loco.navigation.home} /</Text>
        </Link>

        <Link to={getRouteWithId(Routes.CHAPTER, chapter.id)}>
            <Text variant="body2">{chapter.name} /</Text>
        </Link>

        <Link to={getRouteWithId(Routes.LECTURE, lecture.id)}>
            <Text variant="body2">
                <b>{lecture.name}</b>
            </Text>
        </Link>
    </Wrapper>
)

export default Breadcrumbs
