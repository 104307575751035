import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
            {...props}
        >
            <g fill="#f59100">
                <path d="M9.967 18.333H2.492a.832.832 0 01-.83-.833v-15c0-.46.371-.833.83-.833h7.475c.46 0 .83-.373.83-.834A.831.831 0 009.967 0H2.492A2.499 2.499 0 000 2.5v15C0 18.878 1.118 20 2.492 20h7.475c.46 0 .83-.372.83-.833s-.37-.834-.83-.834z"></path>
                <path d="M19.753 9.407l-5.05-5a.829.829 0 00-1.175.008.834.834 0 00.009 1.178l3.608 3.574h-9.67c-.46 0-.83.372-.83.833 0 .46.37.833.83.833h9.67l-3.608 3.574a.835.835 0 00.583 1.426c.21 0 .421-.08.583-.24l5.05-5a.836.836 0 000-1.186z"></path>
            </g>
        </svg>
    )
}

export default Icon
