import React from 'react'

function StepLabelCompletedIcon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            fill="none"
            viewBox="0 0 10 10"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="hsla(240,100%,14%,1)"
                d="M5 10A5 5 0 105 0a5 5 0 000 10z"
            />
        </svg>
    )
}

export default StepLabelCompletedIcon
