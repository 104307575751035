import React, { ReactNode, cloneElement, useEffect, useState } from 'react'
import { useMedia } from 'react-use'
import Glide from '@glidejs/glide'

import { ChaptersQuery } from '@graphql'
import { CarouselWrapper, Header, Controls, Button } from './styled'

export type State = {
    isStart: boolean
    isEnd: boolean
}

type Props = {
    element?: string
    children: ReactNode | Array<ReactNode>
    prevButton: ReactNode
    nextButton: ReactNode
    chapter?: ChaptersQuery['chapters'][0]
    options: {
        type?: string
        startAt?: number
        perView?: number
        focusAt?: number
        gap?: number
        autoplay?: boolean
        hoverpause?: boolean
        keyboard?: boolean
        bound?: boolean
        swipeThreshold?: number
        dragThreshold?: number
        perTouch?: boolean
        touchRatio?: number
        touchAngle?: number
        animationDuration?: number
        rewind?: boolean
        rewindDuration?: number
        animationTimingFunc?: string
        throttle?: number
        direction?: string
        peek?: {
            before: number
            after: number
        }
        breakpoints?: object
        classes?: object
    }
}

const Carousel = ({
    element = 'glide',
    options,
    children,
    prevButton,
    nextButton,
    chapter
}: Props) => {
    const isWide = useMedia('(min-width: 541px)')

    const [state, setState] = useState<State>({
        isStart: true,
        isEnd: false
    })

    useEffect(() => {
        const slider = new Glide(`.${element}`, options).mount()

        const currentIdx: number = slider._i
        const length: number = Array.isArray(children) ? children.length : 0

        setState({
            isStart: slider._c.Run.isStart(),
            isEnd: isWide ? currentIdx >= length - 2 : currentIdx >= length - 1
        })

        slider.on(['mount.after', 'run'], function () {
            const currentIdx: number = slider._i
            const length: number = Array.isArray(children) ? children.length : 0

            setState({
                isStart: slider._c.Run.isStart(),
                isEnd: isWide ? currentIdx >= length - 2 : currentIdx >= length - 1
            })
        })

        return () => slider.destroy()
        // https://github.com/glidejs/glide/pull/457
        // Don't remove children from deps array, see pull request
    }, [children, isWide])

    return (
        <CarouselWrapper className={element}>
            <Header>
                {children && Array.isArray(children) && (
                    <Controls
                        isEnd={state.isEnd}
                        isStart={state.isStart}
                        data-glide-el="controls"
                        style={chapter?.id ? {} : { marginLeft: 'auto' }}
                    >
                        <Button data-glide-dir="<" disabled={state.isStart}>
                            {prevButton}
                        </Button>
                        <Button data-glide-dir=">" disabled={state.isEnd}>
                            {nextButton}
                        </Button>
                    </Controls>
                )}
            </Header>

            <div className="glide__track" data-glide-el="track">
                <div className="glide__slides" style={{ display: 'flex' }}>
                    {children && Array.isArray(children)
                        ? children.map((slide: any, index: number) => {
                              return cloneElement(slide, {
                                  key: index,
                                  className: `${slide.props.className} glide__slide`
                              })
                          })
                        : children}
                </div>
            </div>
        </CarouselWrapper>
    )
}

export default Carousel
