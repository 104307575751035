import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import { media, transition } from '@variables'
import { AccessibleButton, DialogContent } from '../../shared/styled'

export const Title = styled(Typography)``
export const Subtitle = styled(Typography)``

export const Wrapper = styled(DialogContent)`
    ${Subtitle} {
        margin: 20px 0;
    }
`

export const CheckboxesWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const Check = styled.div`
    display: inline-flex;
    align-items: center;
`

export const ButtonWrapper = styled.div`
    padding: 20px 0;
`

export const LoginLink = styled(AccessibleButton)`
    color: #2a3039;
    margin-left: 5px;
    font-size: inherit;
    transition: ${transition.main};

    ${media.tablet} {
        &:hover {
            text-decoration: underline;
        }
    }
`
