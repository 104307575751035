import React from 'react'
import { format } from 'date-fns'
import { cs } from 'date-fns/locale'
import MaterialTable, { MTableHeader } from 'material-table'

import { getColor } from './Icons'
import TestIcon from './Icons/Test'
import WordIcon from './Icons/Word'
import UserIcon from './Icons/User'
import AdminIcon from './Icons/Admin'
import SchoolIcon from './Icons/School'
import ChapterIcon from './Icons/Chapter'
import TeacherIcon from './Icons/Teacher'
import LectureIcon from './Icons/Lecture'
import MaterialIcon from './Icons/Material'
import AmbassadorIcon from './Icons/Ambassador'
import NotificationIcon from './Icons/Notification'
import Pagination from '../Pagination'

import * as loco from '@loco'
import { tableLocalization } from '@variables'
import { getProfileName, getRole } from '@utils'

import Row from '../../shared/Row'
import { IconWrapper } from './styled'
import { tableIcons, headerStyle } from '../../shared'
import { ActivityType, EntityType, Role, UserActivityQuery } from '@graphql'

type Data = UserActivityQuery['userActivity']['data'][0]['data']
type Activity = UserActivityQuery['userActivity']['data'][0]

type Props = {
    data: any[]
    page: number
    loading: boolean
    totalCount: number
    itemsPerPage: number
    onChangePage: (page: number) => void
    onChangeRowsPerPage: (pageSize: number) => void
}

const UserActivity = ({
    data,
    page,
    loading,
    totalCount,
    itemsPerPage,
    onChangePage,
    onChangeRowsPerPage
}: Props) => (
    <MaterialTable
        page={page}
        data={data}
        columns={columns}
        icons={tableIcons}
        isLoading={loading}
        totalCount={totalCount}
        title={loco.admin.activity}
        onChangePage={onChangePage}
        localization={tableLocalization}
        onChangeRowsPerPage={onChangeRowsPerPage}
        options={{
            actionsColumnIndex: -1,
            debounceInterval: 500,
            pageSize: itemsPerPage,
            thirdSortClick: false,
            draggable: false,
            sorting: false,
            search: false
        }}
        components={{
            Header: (props) =>
                (
                    <MTableHeader
                        {...props}
                        // orderBy={sortedColumnId}
                        // onOrderChange={handleSortOrderChange}
                        // orderDirection={sortedColumnDirection}
                    />
                ) as any,
            Pagination: Pagination
        }}
    />
)

const columns = [
    {
        field: 'name',
        title: 'Akce',
        headerStyle: { ...headerStyle },
        render: function render(row: Activity) {
            const username = getProfileName(row.user)
            const text = getContent(
                row.data,
                transformActivity(row.activity),
                // @ts-ignore
                transformEntity(row.entity, row.data?.role)
            )
            const icon = getIcon(
                row.activity,
                row.entity,
                // @ts-ignore
                row.data?.role
            )
            return <Row text={username + ' - ' + text} icon={icon} maxChars={85} />
        }
    },
    {
        field: 'role',
        title: 'Role',
        headerStyle: { ...headerStyle },
        render: function render(row: Activity) {
            return <Row text={getRole(row.user.role)} />
        }
    },
    {
        title: 'Datum',
        field: 'createdAt',
        headerStyle: { ...headerStyle },
        render: function render(row: Activity) {
            return <Row text={format(new Date(row.createdAt), 'dd.MM.Y HH:mm', { locale: cs })} />
        }
    }
]

const getContent = (data: Data, activity: string, entity: string): string => {
    const content =
        // @ts-ignore
        data?.title ||
        // @ts-ignore
        data?.name ||
        // @ts-ignore
        data?.lecture?.name ||
        // @ts-ignore
        data?.word ||
        // @ts-ignore
        data?.email ||
        // @ts-ignore
        data?.inviteEmail ||
        // @ts-ignore
        data?.username ||
        // @ts-ignore
        data?.id ||
        // @ts-ignore
        data?.text

    return `${activity} ${entity} ${Boolean(content) ? `- ${content}` : ''}`
}

const getIcon = (activity: ActivityType, entity: EntityType, userRole?: Role) => {
    if (
        entity === EntityType.QUESTION ||
        entity === EntityType.ANSWER ||
        entity === EntityType.TEST
    ) {
        return (
            <IconWrapper activity={activity}>
                <TestIcon color={getColor(activity)} />
            </IconWrapper>
        )
    }
    // if (entity === EntityType.CHAPTER || entity === EntityType.CHAPTER_DRAFT) {
    //     return (
    //         <IconWrapper activity={activity}>
    //             <ChapterIcon color={getColor(activity)} />
    //         </IconWrapper>
    //     )
    // }
    if (entity === EntityType.DOCUMENT) {
        return (
            <IconWrapper activity={activity}>
                <MaterialIcon color={getColor(activity)} />
            </IconWrapper>
        )
    }
    if (entity === EntityType.NOTIFICATION) {
        return (
            <IconWrapper activity={activity}>
                <NotificationIcon color={getColor(activity)} />
            </IconWrapper>
        )
    }
    // if (entity === EntityType.LECTURE || entity === EntityType.LECTURE_DRAFT) {
    //     return (
    //         <IconWrapper activity={activity}>
    //             <LectureIcon color={getColor(activity)} />
    //         </IconWrapper>
    //     )
    // }
    // if (entity === EntityType.SCHOOL) {
    //     return (
    //         <IconWrapper activity={activity}>
    //             <SchoolIcon color={getColor(activity)} />
    //         </IconWrapper>
    //     )
    // }
    if (entity === EntityType.WORD) {
        return (
            <IconWrapper activity={activity}>
                <WordIcon color={getColor(activity)} />
            </IconWrapper>
        )
    }
    if (userRole === Role.ADMIN) {
        return (
            <IconWrapper activity={activity}>
                <AdminIcon color={getColor(activity)} />
            </IconWrapper>
        )
    }
    // if (userRole === Role.AMBASSADOR) {
    //     return (
    //         <IconWrapper activity={activity}>
    //             <AmbassadorIcon color={getColor(activity)} />
    //         </IconWrapper>
    //     )
    // }
    // if (userRole === Role.SCHOOL) {
    //     return (
    //         <IconWrapper activity={activity}>
    //             <SchoolIcon color={getColor(activity)} />
    //         </IconWrapper>
    //     )
    // }
    if (userRole === Role.TEACHER) {
        return (
            <IconWrapper activity={activity}>
                <TeacherIcon color={getColor(activity)} />
            </IconWrapper>
        )
    }

    return (
        <IconWrapper activity={activity}>
            <UserIcon color={getColor(activity)} />
        </IconWrapper>
    )
}

const transformEntity = (entity: EntityType, userRole?: Role): string => {
    const result = ''

    // if (entity === EntityType.INVITATION) {
    //     return loco.activity.admin.entity.invite
    // }
    if (entity === EntityType.ANSWER) {
        return loco.activity.admin.entity.answer
    }
    if (entity === EntityType.CHAPTER) {
        return loco.activity.admin.entity.chapter
    }
    // if (entity === EntityType.CHAPTER_DRAFT) {
    //     return loco.activity.admin.entity.chapterDraft
    // }
    if (entity === EntityType.DOCUMENT) {
        return loco.activity.admin.entity.document
    }
    if (entity === EntityType.LECTURE) {
        return loco.activity.admin.entity.lecture
    }
    // if (entity === EntityType.LECTURE_DRAFT) {
    //     return loco.activity.admin.entity.lectureDraft
    // }
    if (entity === EntityType.QUESTION) {
        return loco.activity.admin.entity.question
    }
    // if (entity === EntityType.SCHOOL) {
    //     return loco.activity.admin.entity.school
    // }
    if (entity === EntityType.TEST) {
        return loco.activity.admin.entity.test
    }
    if (entity === EntityType.NOTIFICATION) {
        return loco.activity.admin.entity.notification
    }
    if (entity === EntityType.WORD) {
        return loco.activity.admin.entity.word
    }
    if (entity === EntityType.USER) {
        if (userRole === Role.ADMIN) {
            return loco.activity.admin.entity.admin
        }
        // if (userRole === Role.AMBASSADOR) {
        //     return loco.activity.admin.entity.ambassador
        // }
        // if (userRole === Role.SCHOOL) {
        //     return loco.activity.admin.entity.schoolUser
        // }
        if (userRole === Role.TEACHER) {
            return loco.activity.admin.entity.teacher
        }
        return loco.activity.admin.entity.user
    }

    return result
}

const transformActivity = (activity: ActivityType): string => {
    let result = ''

    if (activity === ActivityType.APPROVED) {
        result = loco.activity.admin.actions.approved
    }
    // if (activity === ActivityType.INVITED) {
    //     result = loco.activity.admin.actions.invited
    // }
    if (activity === ActivityType.BLOCKED) {
        result = loco.activity.admin.actions.blocked
    }
    if (activity === ActivityType.CREATED) {
        result = loco.activity.admin.actions.created
    }
    if (
        activity === ActivityType.DELETED
        // activity === ActivityType.REMOVED_MANAGER ||
        // activity === ActivityType.REMOVED_AMBASSADOR
    ) {
        result = loco.activity.admin.actions.deleted
    }
    if (activity === ActivityType.PUBLISHED) {
        result = loco.activity.admin.actions.published
    }
    if (activity === ActivityType.REJECTED) {
        result = loco.activity.admin.actions.rejected
    }
    if (activity === ActivityType.UNBLOCKED) {
        result = loco.activity.admin.actions.unblocked
    }
    if (activity === ActivityType.UPDATED) {
        result = loco.activity.admin.actions.updated
    }

    return result
}

export default UserActivity
