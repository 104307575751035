import { Fade } from 'react-reveal'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import { useInView } from 'react-intersection-observer'

import Panel from './Panel'
import * as loco from '@loco'
import { Routes } from '@variables'
import { Wrapper, Title } from './styled'

const FAQ = () => {
    const history = useHistory()
    const { ref, inView } = useInView({
        threshold: 0.2
    })

    useEffect(() => {
        if (inView) {
            history.replace({ hash: Routes.FAQ_HASH_PARAM })
        }
    }, [inView])

    return (
        <Wrapper id={Routes.FAQ_HASH_PARAM} ref={ref}>
            <Container>
                <Fade>
                    <Title
                        variant="h2"
                        align="center"
                        dangerouslySetInnerHTML={{ __html: loco.lp.faq.title }}
                    ></Title>
                </Fade>

                <Fade delay={50}>
                    <Panel
                        defaultExpanded
                        text={loco.lp.faq.panel1.text}
                        title={loco.lp.faq.panel1.title}
                    />
                </Fade>
                <Fade delay={100}>
                    <Panel title={loco.lp.faq.panel2.title} text={loco.lp.faq.panel2.text} />
                </Fade>
                <Fade delay={150}>
                    <Panel title={loco.lp.faq.panel3.title} text={loco.lp.faq.panel3.text} />
                </Fade>
                <Fade delay={200}>
                    <Panel title={loco.lp.faq.panel4.title} text={loco.lp.faq.panel4.text} />
                </Fade>
                <Fade delay={250}>
                    <Panel title={loco.lp.faq.panel5.title} text={loco.lp.faq.panel5.text} />
                </Fade>
                <Fade delay={300}>
                    <Panel title={loco.lp.faq.panel6.title} text={loco.lp.faq.panel6.text} />
                </Fade>
                <Fade delay={350}>
                    <Panel title={loco.lp.faq.panel7.title} text={loco.lp.faq.panel7.text} />
                </Fade>
                <Fade delay={400}>
                    <Panel isLast title={loco.lp.faq.panel8.title} text={loco.lp.faq.panel8.text} />
                </Fade>
            </Container>
        </Wrapper>
    )
}

export default FAQ
