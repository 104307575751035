import React, { useContext } from 'react'

import { colors } from '@variables'
import { AuthContext } from '../../context/Auth'
import { Wrapper, AvatarWrapper, Svg, Rect } from './styled'
import FakeAvatar from '@static/images/avatar.png'

export type Props = {
    size: 'small' | 'medium' | 'large'
}

const Avatar = ({ size }: Props) => {
    const { user } = useContext(AuthContext)

    const pic = user?.profilePicture || FakeAvatar
    const progress = user?.student?.progress.level.progress || 0

    return (
        <Wrapper size={size} progress={Math.round(progress)}>
            <AvatarWrapper>
                <img src={pic} alt="avatar" />
            </AvatarWrapper>

            <Svg
                width="95"
                height="95"
                fill="none"
                viewBox="0 0 95 95"
                xmlns="http://www.w3.org/2000/svg"
            >
                <Rect
                    rx="9"
                    x="1"
                    y="1"
                    width="93"
                    height="93"
                    strokeWidth="2"
                    stroke={colors.primary.orange}
                    progress={Math.round(progress * 3.4)}
                />
            </Svg>
        </Wrapper>
    )
}

export default Avatar
