import React from 'react'

import { Wrapper } from './styled'
import * as loco from '@loco'
import Header from './Header'
import About from './About'
import FAQ from './FAQ'
import Functions from './Functions'
import Education from './Education'
import Application from './Application'
import Head from '../../../components/Head'

const LPPage = () => (
    <>
        <Head title={loco.seo.lp.title} />

        <Wrapper>
            <Header />
            <About />
            <Application />
            <Functions />
            <Education />
            <FAQ />
        </Wrapper>
    </>
)

export default LPPage
