import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="125"
            height="110"
            fill="none"
            viewBox="0 0 125 110"
            {...props}
        >
            <g opacity="0.3">
                <path
                    fill="#C4ECFE"
                    d="M115.98 9.116v90.856H8.903V9.116H115.98zm.35-.35H8.552v91.556H116.33V8.766z"
                    opacity="0.3"
                ></path>
                <g opacity="0.3">
                    <path
                        fill="#C4ECFE"
                        d="M116.329 73.674H8.551V74h107.778v-.327zM116.329 60.559H8.551v.327h107.778v-.327z"
                        opacity="0.3"
                    ></path>
                    <path
                        fill="#C4ECFE"
                        d="M116.329 73.674H8.551V74h107.778v-.327zM116.329 86.777H8.551v.328h107.778v-.328zM116.329 47.455H8.551v.328h107.778v-.328zM116.329 34.34H8.551v.327h107.778v-.327zM116.329 21.236H8.551v.328H116.33v-.328z"
                        opacity="0.3"
                    ></path>
                </g>
            </g>
            <path
                fill="url(#paint0_linear)"
                d="M16.696 100.322l-.011-.655c0 .215.011.429.011.655z"
            ></path>
            <path
                fill="#CAE6C4"
                d="M108.128 77.425a.626.626 0 01-.621-.621v-21.18c0-.34.282-.622.621-.622s.621.282.621.621v21.18a.626.626 0 01-.621.622z"
            ></path>
            <path
                fill="#CAE6C4"
                d="M108.128 60.266c-.034 0-.068 0-.101-.011a.617.617 0 01-.509-.712 3.265 3.265 0 013.468-2.722.628.628 0 01.576.666.628.628 0 01-.666.576 2.028 2.028 0 00-2.158 1.683.617.617 0 01-.61.52z"
            ></path>
            <path
                fill="#CAE6C4"
                d="M108.128 60.266a.618.618 0 01-.61-.52 2.013 2.013 0 00-2.157-1.683.621.621 0 01-.667-.576.614.614 0 01.576-.666c1.706-.125 3.197 1.039 3.468 2.722a.615.615 0 01-.508.712c-.034.011-.068.011-.102.011zM108.128 63.372c-.034 0-.068 0-.101-.011a.616.616 0 01-.509-.712 3.265 3.265 0 013.468-2.722.628.628 0 01.576.666.628.628 0 01-.666.577 2.028 2.028 0 00-2.158 1.683.617.617 0 01-.61.52z"
            ></path>
            <path
                fill="#CAE6C4"
                d="M108.128 63.372a.617.617 0 01-.61-.52 2.013 2.013 0 00-2.157-1.682.621.621 0 01-.667-.577.614.614 0 01.576-.666c1.706-.124 3.197 1.04 3.468 2.723a.615.615 0 01-.508.711c-.034.011-.068.011-.102.011zM100.153 80.43a.627.627 0 01-.621-.622V63.451c0-.339.282-.62.621-.62s.621.281.621.62v16.357a.626.626 0 01-.621.622z"
            ></path>
            <path
                fill="#CAE6C4"
                d="M100.153 68.094c-.034 0-.068 0-.102-.011a.616.616 0 01-.508-.712 3.265 3.265 0 013.468-2.722.628.628 0 01.576.666.628.628 0 01-.666.576 2.028 2.028 0 00-2.158 1.683.617.617 0 01-.61.52z"
            ></path>
            <path
                fill="#CAE6C4"
                d="M100.153 68.094a.618.618 0 01-.61-.52 2.013 2.013 0 00-2.158-1.683.62.62 0 01-.666-.576.62.62 0 01.576-.666 3.248 3.248 0 013.468 2.722.615.615 0 01-.508.712c-.034.011-.068.011-.102.011zM100.153 71.212c-.034 0-.068 0-.102-.011a.616.616 0 01-.508-.712 3.265 3.265 0 013.468-2.722.628.628 0 01.576.666.628.628 0 01-.666.576 2.028 2.028 0 00-2.158 1.684.617.617 0 01-.61.52z"
            ></path>
            <path
                fill="#CAE6C4"
                d="M100.153 71.212a.618.618 0 01-.61-.52 2.013 2.013 0 00-2.158-1.682.62.62 0 01-.666-.577.614.614 0 01.576-.666c1.706-.124 3.197 1.04 3.468 2.722a.615.615 0 01-.508.712c-.034.011-.068.011-.102.011zM15.216 92.607a.627.627 0 01-.621-.621V78.645c0-.34.282-.622.621-.622s.621.283.621.622v13.34a.627.627 0 01-.62.622z"
            ></path>
            <path
                fill="#CAE6C4"
                d="M15.216 83.288c-.034 0-.067 0-.101-.01a.616.616 0 01-.509-.713 3.264 3.264 0 013.468-2.722.629.629 0 01.576.666.629.629 0 01-.666.577 2.027 2.027 0 00-2.158 1.683.618.618 0 01-.61.52z"
            ></path>
            <path
                fill="#CAE6C4"
                d="M15.216 83.288a.618.618 0 01-.61-.52 2.013 2.013 0 00-2.157-1.682.62.62 0 01-.667-.576.62.62 0 01.576-.667 3.248 3.248 0 013.468 2.722.616.616 0 01-.508.712c-.034.011-.068.011-.102.011z"
            ></path>
            <path
                fill="url(#paint1_linear)"
                d="M116.363 82.395v9.997a7.682 7.682 0 01-7.681 7.682H64.558s1.65-13.431 21.667-13.431c11.24 0 8.743-5.886 16.21-11.365 5.784-4.247 13.928-.045 13.928 7.117z"
            ></path>
            <path
                fill="url(#paint2_linear)"
                d="M9.048 99.802H43.39c0-7.127-3.728-8.573-7.806-8.72-3.547-.136-4.428-6.834-10.37-5.908-3.592.565-3.818 3.626-6.97 4.304-2.778.598-9.195 3.095-9.195 10.325z"
            ></path>
            <path
                fill="url(#paint3_linear)"
                d="M41.412 43.964c2.18-3.614 5.298-5.953 9.252-9.5 3.954-3.547 4.27-11.714 5.016-15.566.44-2.26 4.337-6.688 1.423-8.777 0 0-3.604-.69-7.015 3.355-1.322.666-2.576 1.254-4.406 2.18-5.444 2.722-3.83 7.602-6.213 10.155-2.372 2.553-21.248 3.366-25.54 9.128-9.275 12.448 17.927 24.897 27.483 9.025z"
            ></path>
            <path fill="#2B2B2B" d="M94.076 99.746h-72.07v.463h72.07v-.463z"></path>
            <path
                fill="#565656"
                d="M79.402 46.664l-9.511-1.22a3.152 3.152 0 01-1.729-.802l-.248-.237-12.709-11.613a4.354 4.354 0 01-.282-6.145 4.354 4.354 0 016.145-.282l12.708 11.612L71.8 36.95l9.172 2.983-1.57 6.732z"
            ></path>
            <path
                fill="#FDC068"
                d="M80.972 39.932l3.276 1.061a3.159 3.159 0 012.022 3.965 3.147 3.147 0 01-3.389 2.147l-3.468-.44 1.559-6.733z"
            ></path>
            <path
                fill="#565656"
                d="M64.107 39.808c-.26-4.778-3.525-4.383-2.429-7.659s-.599-7.692-4.27-7.5c-2.824.146-3.027.045-4.225.113-3.773.203-6.167 4.168-4.891 7.726 0 0 .328.802.361 1.887.034 1.084-2.541 2.813-2.36 6.1l17.814-.667z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M37.549 98.763l-10.81-8.653c-.565-.452-.69-1.254-.283-1.807l.994-1.333 6.778 5.535 4.225 5.366a.637.637 0 01-.904.892z"
            ></path>
            <path
                fill="url(#paint4_linear)"
                d="M46.292 40.463c.136 2.519 2.971 6.156 7.365 5.953l3.536-.271c4.01-.181 7.038-3.92 6.891-6.631l-17.792.949z"
            ></path>
            <path
                fill="url(#paint5_linear)"
                d="M27.439 86.97l6.473 5.637c.361-.43.689-.882 1.039-1.322.339-.44.666-.892 1.005-1.333l.497-.666.486-.678.972-1.356c.327-.452.643-.904.96-1.367.316-.452.632-.915.937-1.367.305-.463.622-.915.927-1.378l.915-1.39a251.829 251.829 0 006.924-11.295l.418-.712c.068-.124.147-.237.204-.362l.18-.372c.249-.486.486-.983.723-1.48l.723-1.491.283-.599.418-.892c.474-.994.915-2 1.378-3.005.226-.497.44-1.006.666-1.514.226-.508.44-1.005.655-1.514.012-.01.012-.034.023-.045.859-2.01 1.66-4.055 2.418-6.111.203-.554.406-1.107.598-1.672.17-.486.34-.971.497-1.468l.509-1.582.237-.802.113-.395.113-.407-2.361-1.062-3.615-1.593-6.462-2.914-4.055 12.516-2.451 6.055-.497 1.231-.735 1.796-.61 1.514-.158.373c-.045.124-.113.248-.17.373l-.338.745-9.839 21.904z"
            ></path>
            <path
                fill="url(#paint6_linear)"
                d="M52.042 42.18l2.915 13.758.756 3.559.7 3.32c.091.419.181.837.26 1.255.407 1.875.825 3.75 1.085 5.637l4.473 27.664 8.405-.305c.034-.587.022-1.412.045-2 .023-.587.034-1.174.045-1.761l.023-.882c.011-.293 0-.587 0-.88v-2.643l-.011-.882c-.012-.587-.012-1.175-.023-1.762l-.045-1.762-.057-1.763c-.158-4.699-.452-9.387-.824-14.086-.102-1.175-.17-2.35-.362-3.513l-.26-1.751-.124-.881-.136-.882-.282-1.75-.305-1.751-.158-.87-.158-.87-.328-1.75c-.452-2.328-.96-4.644-1.514-6.96-.564-2.315-1.717-5.455-2.473-7.737l-8.02.824-2.87 1.119-.757.305z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M77.278 99.814l-13.499-.056c-.711 0-1.333-.565-1.423-1.277l-.203-1.706 8.54.125 6.62 1.615c.767.192.745 1.3-.035 1.3z"
            ></path>
            <path
                fill="#FDC068"
                d="M58.775 21.925h-3.31a3.102 3.102 0 01-3.106-3.095v-4.993a4.951 4.951 0 014.959-4.96 4.951 4.951 0 014.959 4.96v4.575a3.493 3.493 0 01-3.502 3.513z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M61.452 15.837a.565.565 0 100-1.13.565.565 0 000 1.13zM57.668 27.29l2.62-1.48s-.722-1.332-3.66-1.174l1.04 2.655z"
            ></path>
            <path
                fill="#FDC068"
                d="M56.177 27.585l-4.248-1.559.44-9.432h5.276l.497 9.545c.057 1.04-.983 1.808-1.965 1.446z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M60.074 16.978a.157.157 0 01-.136-.08.152.152 0 01.057-.214l.452-.26a.236.236 0 00.079-.147.186.186 0 00-.057-.17l-.7-.835a.161.161 0 01.022-.226.161.161 0 01.226.023l.7.835a.555.555 0 01-.078.78l-.023.011-.463.271c-.023.012-.057.012-.08.012zM57.928 21.926l-2.158.011 2.27 2.124-.112-2.135zM58.233 15.86a.565.565 0 100-1.13.565.565 0 000 1.13z"
            ></path>
            <path
                fill="url(#paint7_linear)"
                d="M34.94 43.615c4.01-3.434 2.428-5.084 7.997-7.693 5.57-2.61 6.586-12.765 9.422-17.091 1.66-2.53 7.681-6.213 6.224-9.975 0 0-2.88-2.36-8.235 1.062-1.57.248-3.039.43-5.208.757-6.461.96-7.749 7.534-11.33 9.602-3.592 2.067-11.048-2.327-18.164 2.801C.249 34.171 17.374 58.684 34.94 43.615z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M34.702 21.191h-.01l-3.932-.384a.165.165 0 11.034-.328l3.931.385c.09.011.158.09.147.18a.171.171 0 01-.17.147zM51.048 21.643h-.022a.165.165 0 01-.147-.18l.248-2.384a.165.165 0 11.328.034l-.249 2.383c0 .08-.067.147-.158.147z"
            ></path>
            <path
                fill="#FDC068"
                d="M41.412 17.847l1.491-2.191a3.156 3.156 0 014.372-.836 3.14 3.14 0 011.118 3.84l-.847 1.966-6.134-2.779z"
            ></path>
            <path
                fill="#565656"
                d="M47.558 20.638L43.4 30.34l-3.231-6.405 10.381 1.537a4.351 4.351 0 013.728 4.891 4.351 4.351 0 01-4.891 3.728l-10.382-1.537-.395-.056a4.086 4.086 0 01-3.502-4.598c.09-.643.328-1.242.666-1.75l5.649-8.303 6.134 2.79z"
            ></path>
            <path
                fill="url(#paint8_linear)"
                d="M59.023 9.082s.69 2.756 3.265 4.756c0 0 .192-4.598-3.265-4.756z"
            ></path>
            <path fill="#F5FDF6" d="M85.095 25.382h-2.26v29.766h2.26V25.382z"></path>
            <path fill="#2B2B2B" d="M85.095 25.382h-2.26v8.833h2.26v-8.833z"></path>
            <path
                fill="#04AE1C"
                d="M88.416 9.805h-8.901l-6.292 6.292v8.901l6.292 6.292h8.901l6.304-6.292v-8.901l-6.304-6.292z"
            ></path>
            <path
                fill="#F5FDF6"
                d="M88.416 32.126h-8.901a.801.801 0 01-.588-.248l-6.292-6.292a.84.84 0 01-.248-.588v-8.901c0-.226.09-.44.248-.588l6.292-6.292a.84.84 0 01.588-.248h8.901c.226 0 .43.09.588.248l6.303 6.292a.84.84 0 01.248.588v8.901c0 .226-.09.44-.248.588l-6.303 6.292a.82.82 0 01-.588.248zm-8.551-1.672h8.212l5.818-5.806v-8.201l-5.818-5.806h-8.212l-5.806 5.806v8.212l5.806 5.795z"
            ></path>
            <path
                fill="#FDC068"
                d="M85.276 41.559s-2.553.745-2.711 1.4c-.237 1.017-.893 3.841-.316 4.067.576.226 3.479-1.062 3.479-1.062l-.452-4.405z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M55.036 56.672c-.023 0-.046 0-.057-.011-.09-.034-.135-.136-.102-.215l2.712-7.274c.034-.09.135-.136.214-.102.09.034.136.135.102.215l-2.711 7.274a.17.17 0 01-.158.113zM50.608 34.295h-.023l-1.988-.271a.175.175 0 01-.147-.192.175.175 0 01.192-.147l1.988.27c.09.012.158.103.147.193a.17.17 0 01-.17.147zM63.96 40.903c-.012 0-.034 0-.046-.012-.09-.022-.146-.124-.113-.203.577-2.044-.587-3.773-2.18-6.168l-.147-.214c-.056-.08-.033-.181.046-.237.079-.057.18-.034.237.045l.147.214c1.649 2.463 2.846 4.248 2.225 6.44a.168.168 0 01-.17.135zM71.935 37.82a.18.18 0 01-.17-.136.168.168 0 01.125-.203l1.186-.26a.168.168 0 11.079.327l-1.186.26c-.012.011-.023.011-.034.011z"
            ></path>
            <path
                fill="#BFD9FF"
                d="M23.508 55.08a.463.463 0 100-.926.463.463 0 000 .927zM103.452 92.9a.464.464 0 10-.001-.926.464.464 0 00.001.927zM103.903 17.396a.464.464 0 100-.927.464.464 0 000 .927zM70.76 30.556a.463.463 0 100-.926.463.463 0 000 .926zM47.817 20.243a.463.463 0 100-.927.463.463 0 000 .927zM83.66 78.43a.43.43 0 100-.859.43.43 0 000 .859zM23.508 83.095a.43.43 0 100-.859.43.43 0 000 .859z"
            ></path>
            <path
                fill="#F9D5FF"
                d="M89.986 66.762l-.327-1.085c4.846-1.457 7.647-5.761 10.37-9.93 2.813-4.315 5.467-8.392 10.11-8.573l.045 1.13c-4.055.158-6.439 3.818-9.206 8.054-2.689 4.134-5.739 8.833-10.992 10.404zM22.75 74.375c-2.315-.712-4.134-4.09-5.907-7.354-1.446-2.689-3.095-5.727-4.44-5.727v-1.13c2.022 0 3.604 2.926 5.434 6.315 1.581 2.925 3.378 6.235 5.241 6.811l-.327 1.085z"
            ></path>
            <path
                fill="#2B2B2B"
                fillRule="evenodd"
                d="M59.049 19.049a.167.167 0 01.236 0 .624.624 0 00.32.166c.128.02.282-.005.444-.166a.167.167 0 11.236.236c-.239.238-.501.296-.733.259a.957.957 0 01-.503-.26.167.167 0 010-.235z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M80.47 19.861a.75.75 0 011.06 0l1.666 1.666 3.86-3.861a.75.75 0 011.061 1.06l-4.391 4.392a.75.75 0 01-1.06 0l-2.196-2.196a.75.75 0 010-1.06z"
                clipRule="evenodd"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="16.691"
                    x2="16.691"
                    y1="99.667"
                    y2="100.288"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFE0C2"></stop>
                    <stop offset="0.401" stopColor="#7AAEFC"></stop>
                    <stop offset="0.591" stopColor="#B8C1FC"></stop>
                    <stop offset="1" stopColor="#D2D4D4"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="90.461"
                    x2="90.461"
                    y1="73.742"
                    y2="98.213"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD8A1"></stop>
                    <stop offset="0.996" stopColor="#fff"></stop>
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="26.219"
                    x2="26.219"
                    y1="84.442"
                    y2="100.457"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD8A1"></stop>
                    <stop offset="0.996" stopColor="#fff"></stop>
                </linearGradient>
                <linearGradient
                    id="paint3_linear"
                    x1="59.957"
                    x2="25.289"
                    y1="12.042"
                    y2="41.941"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.029" stopColor="#2A7CF9"></stop>
                    <stop offset="0.401" stopColor="#418BFB"></stop>
                    <stop offset="0.591" stopColor="#AAB5FB"></stop>
                    <stop offset="1" stopColor="#D2D4D4"></stop>
                </linearGradient>
                <linearGradient
                    id="paint4_linear"
                    x1="55.191"
                    x2="55.191"
                    y1="99.324"
                    y2="39.665"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#77879D"></stop>
                    <stop offset="0.409" stopColor="#FDBB6D"></stop>
                    <stop offset="0.499" stopColor="#FDBB9F"></stop>
                    <stop offset="0.599" stopColor="#D1D2D1"></stop>
                    <stop offset="1" stopColor="#FECEAA"></stop>
                </linearGradient>
                <linearGradient
                    id="paint5_linear"
                    x1="43.084"
                    x2="43.084"
                    y1="99.377"
                    y2="39.25"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#77879D"></stop>
                    <stop offset="0.409" stopColor="#FDBB6D"></stop>
                    <stop offset="0.499" stopColor="#FDBB9F"></stop>
                    <stop offset="0.599" stopColor="#D1D2D1"></stop>
                    <stop offset="1" stopColor="#FECEAA"></stop>
                </linearGradient>
                <linearGradient
                    id="paint6_linear"
                    x1="61.398"
                    x2="61.398"
                    y1="99.341"
                    y2="39.172"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#77879D"></stop>
                    <stop offset="0.409" stopColor="#FDBB6D"></stop>
                    <stop offset="0.499" stopColor="#FDBB9F"></stop>
                    <stop offset="0.599" stopColor="#D1D2D1"></stop>
                    <stop offset="1" stopColor="#FECEAA"></stop>
                </linearGradient>
                <linearGradient
                    id="paint7_linear"
                    x1="67.426"
                    x2="26.76"
                    y1="10.614"
                    y2="32.784"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFE0C2"></stop>
                    <stop offset="0.401" stopColor="#7AAEFC"></stop>
                    <stop offset="0.591" stopColor="#B8C1FC"></stop>
                    <stop offset="1" stopColor="#D2D4D4"></stop>
                </linearGradient>
                <linearGradient
                    id="paint8_linear"
                    x1="50.505"
                    x2="65.632"
                    y1="16.689"
                    y2="8.896"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFE0C2"></stop>
                    <stop offset="0.401" stopColor="#7AAEFC"></stop>
                    <stop offset="0.591" stopColor="#B8C1FC"></stop>
                    <stop offset="1" stopColor="#D2D4D4"></stop>
                </linearGradient>
            </defs>
        </svg>
    )
}

export default Icon
