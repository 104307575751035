import { EmailShareButton, FacebookShareButton, TwitterShareButton } from 'react-share'
import styled from 'styled-components'
import {
    Avatar as MuiAvatar,
    Container as MuiContainer,
    Typography as MuiTypography
} from '@material-ui/core'

import { transition, media, colors } from '@variables'

export const DiplomaLink = styled.a`
    width: 100%;
    max-width: 295px;
`

export const Avatar = styled(MuiAvatar)`
    img {
        width: 60%;
        height: auto;
    }
`

export const Container = styled(MuiContainer)`
    height: calc(100vh - 270px);
`

export const Title = styled(MuiTypography)`
    position: absolute;
    color: #555f70;
    bottom: 10px;
`

export const Name = styled(MuiTypography)``

export const Description = styled(MuiTypography)``

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    max-width: 595px;
    margin: 90px auto;
    padding: 45px 30px;
    border-radius: 5px;
    position: relative;
    align-items: center;
    background: #ffffff;
    flex-direction: column;
    box-shadow: 0px 10px 50px rgba(42, 48, 57, 0.05);

    ${Avatar} {
        width: 55px;
        height: 55px;
        background-color: #e6f7fe;
    }

    ${Name} {
        margin: 25px 0;
        font-size: 40px;
        line-height: 26px;
    }

    ${Description} {
        font-size: 14px;
        line-height: 22px;
        max-width: 370px;
        width: 100%;
    }
`

export const DiplomaHeader = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;

    svg {
        width: 115%;
    }
`

export const DiplomaCoins = styled.div`
    display: inline-flex;
    align-items: center;
    margin-top: 40px;

    svg {
        margin-right: 10px;
    }

    span {
        font-size: 13px;
        opacity: 0.5;
    }
`

export const FacebookButton = styled(FacebookShareButton)``
export const TwitterButton = styled(TwitterShareButton)``
export const EmailButton = styled(EmailShareButton)``

export const SocialWrapper = styled.div`
    width: 100%;
    display: flex;
    margin: 30px 0;
    max-width: 230px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    ${media.tablet} {
        margin: 60px 0 40px 0;
    }

    span {
        opacity: 0.5;
        font-size: 14px;
        line-height: 18px;
    }

    ${FacebookButton},
    ${TwitterButton},
    ${EmailButton} {
        width: 26px;
        height: 26px;
        display: flex;
        cursor: pointer;
        align-items: center;
        border-radius: 26px;
        justify-content: center;
        transition: ${transition.main};
        background-color: #d4d6d7 !important;

        ${media.tablet} {
            :hover {
                background-color: ${colors.primary.orange} !important;
            }
        }

        svg {
            width: auto;
            height: 15px;
            color: white;
        }
    }
`
