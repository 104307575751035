import styled from 'styled-components'

import { State } from '.'
import { media } from '@variables'

export const CarouselWrapper = styled.div`
    overflow-x: hidden;
    user-select: none;
    max-width: 100vw;
`

export const Header = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 0 20px 0;
    justify-content: space-between;
`

export const Button = styled.button`
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;

    &:last-of-type {
        margin-left: 20px;
    }

    :disabled {
        pointer-events: none;
    }
`

export const Controls = styled.div<State>`
    margin-left: auto;

    ${Button} {
        :nth-of-type(1) {
            svg {
                ${(props) => props.isStart && ` color: #F3F3F3; &:hover { right: 0; } `};
            }
        }

        :nth-of-type(2) {
            svg {
                ${(props) => props.isEnd && ` color: #F3F3F3; &:hover { left: 0; }`};
            }
        }
    }
`
