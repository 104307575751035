import { useMedia } from 'react-use'
import React, { useContext } from 'react'

import Avatar from '../../Avatar'
import * as loco from '@loco'
import JobIcon from '../../SVG/Job'
import EditIcon from '../../SVG/Edit'
import { Routes } from '@variables'
import DistrictIcon from '../../SVG/District'
import ExperienceIcon from '../../SVG/Experience'
import { AuthContext } from '../../../context/Auth'
import { getProfileName } from '@utils'
import {
    Row,
    Col,
    Info,
    Link,
    Title,
    Button,
    Status,
    Wrapper,
    Username,
    Subtitle,
    ProfileLink
} from './styled'

const ProfileBox = () => {
    const { user } = useContext(AuthContext)
    const isWide = useMedia('(min-width: 854px)')

    const username = getProfileName(user)
    const district = user?.district?.name
    const level = user?.student?.progress.level.name || ''
    const lifeStage = user?.lifeStage?.name
    const experience = user?.investmentExperience?.name

    return (
        <Wrapper>
            <Row>
                <Avatar size={isWide ? 'large' : 'medium'} />
                <Col>
                    <Username variant="h4">{username}</Username>
                    <Status paragraph>{level}</Status>
                </Col>
                <Button size="large" color="primary" variant="outlined">
                    <EditIcon />
                    {loco.profile.profile.edit}
                    <Link to={Routes.PROFILE_EDIT}></Link>
                </Button>
            </Row>

            <Row>
                <Info>
                    <ExperienceIcon />
                    <div>
                        <Subtitle paragraph>{loco.profile.profile.experience}</Subtitle>
                        {user?.investmentExperience ? (
                            <Title paragraph>{experience}</Title>
                        ) : (
                            <ProfileLink
                                to={`${Routes.PROFILE_EDIT}#${Routes.PERSONAL_HASH_PARAM}`}
                            >
                                {loco.common.add}
                            </ProfileLink>
                        )}
                    </div>
                </Info>
                <Info>
                    <JobIcon />
                    <div>
                        <Subtitle paragraph>{loco.profile.profile.lifeStage}</Subtitle>
                        {user?.lifeStage ? (
                            <Title paragraph>{lifeStage}</Title>
                        ) : (
                            <ProfileLink
                                to={`${Routes.PROFILE_EDIT}#${Routes.PERSONAL_HASH_PARAM}`}
                            >
                                {loco.common.add}
                            </ProfileLink>
                        )}
                    </div>
                </Info>
                <Info>
                    <DistrictIcon />
                    <div>
                        <Subtitle paragraph>{loco.profile.profile.district}</Subtitle>
                        {district ? (
                            <Title paragraph>{district}</Title>
                        ) : (
                            <ProfileLink
                                to={`${Routes.PROFILE_EDIT}#${Routes.PERSONAL_HASH_PARAM}`}
                            >
                                {loco.common.add}
                            </ProfileLink>
                        )}
                    </div>
                </Info>
            </Row>
        </Wrapper>
    )
}

export default ProfileBox
