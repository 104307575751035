import React, { useState } from 'react'
import { Typography } from '@material-ui/core'

import * as loco from '@loco'
import { Wrapper, Arrow, BottomArrow } from './styled'

type Props = {
    value: string
    type: 'summary' | 'transcript'
}

const LectureTranscriptExpansionPanel = ({ value, type }: Props) => {
    const [open, setOpen] = useState(false)

    return (
        <div style={{ position: 'relative' }}>
            <Wrapper open={open} dangerouslySetInnerHTML={{ __html: value }} />
            {((type === 'summary' && value.length > 600) || type === 'transcript') && (
                <Arrow onClick={() => setOpen(!open)}>
                    <Typography
                        paragraph
                        role="button"
                        style={{ fontWeight: 600, marginBottom: 0 }}
                    >
                        {open ? loco.common.less : loco.common.more}
                    </Typography>
                    <BottomArrow open={open} />
                </Arrow>
            )}
        </div>
    )
}

export default LectureTranscriptExpansionPanel
