import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="290"
            height="257"
            fill="none"
            viewBox="0 0 290 257"
            {...props}
        >
            <path
                fill="#FDC068"
                d="M185.597 141.483l-3.789 5.991c-2.655 4.21-8.225 5.441-12.435 2.785-3.822-2.429-5.182-7.222-3.433-11.237l3.497-7.935 16.16 10.396z"
            ></path>
            <path
                fill="#6B9C83"
                d="M169.438 131.088l15.091-34.328-1.198 3.335 9.748-46.828c1.392-6.736 7.999-11.076 14.735-9.651 6.736 1.425 11.076 7.999 9.651 14.735l-9.716 46.796-.162.745c-.194.939-.583 1.814-1.036 2.591l-20.921 33-16.192-10.395z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M165.293 154.34a3.332 3.332 0 01-2.656-1.36c-1.036-1.458-.68-3.498.778-4.502l27.3-19.236c1.457-1.037 3.498-.68 4.502.777 1.036 1.457.68 3.497-.778 4.501l-27.3 19.237a3.197 3.197 0 01-1.846.583z"
            ></path>
            <path
                fill="#F5BE6D"
                d="M38.182 243.981a3.33 3.33 0 01-2.656-1.36c-1.036-1.457-.68-3.497.777-4.501l127.111-89.642c1.457-1.036 3.498-.68 4.502.777 1.036 1.458.68 3.498-.778 4.502L40.06 243.398a3.372 3.372 0 01-1.878.583z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M128.73 232.323h-14.671a3.213 3.213 0 01-2.493-1.166l-22.378-26.62c-1.166-1.361-.972-3.401.388-4.567 1.36-1.166 3.4-.971 4.567.389l21.406 25.454h9.91v-48.836a3.247 3.247 0 013.238-3.238 3.248 3.248 0 013.239 3.238v52.107c.032 1.782-1.425 3.239-3.206 3.239z"
            ></path>
            <path
                fill="#6B9C83"
                d="M193.823 84.842c-1.263-12.566 7.449-12.857 3.271-20.953-4.177-8.097-1.522-20.306 8.129-21.277 7.449-.745 7.902-1.101 11.043-1.425 9.91-1.004 17.747 8.42 15.869 18.2 0 0-.551 2.235-.194 5.052.356 2.818 7.74 6.315 8.614 14.962l-46.732 5.44z"
            ></path>
            <path
                fill="#fff"
                d="M222.485 41.997l-10.784 21.86h-7.06l-2.397-20.532s2.3-.972 5.635-1.36c3.336-.39 10.299-.81 10.299-.81s1.684-.421 4.307.842z"
            ></path>
            <path
                fill="#000046"
                d="M229.804 92.517l-23.058 75.295.745-4.081-5.506 86.241-24.159-1.554c-1.133-7.287-1.846-14.541-2.558-21.827-.681-7.255-1.199-14.509-1.652-21.763-.194-3.627-.421-7.254-.551-10.849l-.226-5.441-.195-5.44c-.259-7.222-.388-14.444-.485-21.698v-.81l.744-3.238a753.382 753.382 0 014.696-19.14c1.684-6.347 3.401-12.695 5.279-18.977 1.878-6.283 10.946-34.49 10.946-34.49l35.98 7.772z"
            ></path>
            <path
                fill="#000020"
                d="M211.278 110.458c-6.671 11.659 4.729 27.754-1.101 46.149l7.546-23.9-6.445-22.249z"
            ></path>
            <path
                fill="#000046"
                d="M244.149 81.474l9.522 79.213-1.458-5.376 32.806 72.769-22.054 9.942c-4.178-5.408-7.999-10.978-11.82-16.548-3.789-5.603-7.417-11.238-10.979-16.938-1.749-2.85-3.53-5.699-5.246-8.582l-2.591-4.307-2.526-4.339a773.025 773.025 0 01-9.877-17.424 8.774 8.774 0 01-.94-2.623l-.485-2.688a594.753 594.753 0 01-3.109-18.265c-.939-6.088-1.814-12.209-2.526-18.33a387.616 387.616 0 01-1.814-18.394l-.324-4.631c-.065-1.555-.194-3.077-.226-4.631l-.195-4.631-.032-2.332-.033-1.166v-1.166l33.907-9.553z"
            ></path>
            <path
                fill="#000046"
                d="M240.523 79.401c.648 6.639-5.279 17.261-16.808 18.492l-9.327.712c-10.525 1.134-19.916-7.383-20.629-14.54l46.764-4.664z"
            ></path>
            <path
                fill="#FDC068"
                d="M200.527 36.102l8.614-1.327c4.47-.68 7.546-4.858 6.834-9.327l-2.008-13.019a13.076 13.076 0 00-14.93-10.946 13.075 13.075 0 00-10.946 14.93l1.846 11.95c.777 5.019 5.538 8.517 10.59 7.74z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M189.873 20.17c0 .647-.421 1.197-.972 1.392l-.453-2.883c.777.033 1.425.68 1.425 1.49zM205.548 49.607l-8.744-2.98s3.919-3.562 8.388-4.015l.356 6.995z"
            ></path>
            <path
                fill="#FDC068"
                d="M209.595 49.801l8.032-4.436c1.425-.81 2.202-2.43 1.878-4.049l-4.404-21.73-13.764 2.105 2.526 25.098c.259 2.753 3.271 4.34 5.732 3.012z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M192.593 24.152c-.064 0-.129 0-.194-.032l-1.36-.55c-.356-.195-.615-.551-.745-.94a1.633 1.633 0 01.162-1.166l1.49-2.46c.129-.228.453-.292.68-.163.226.13.291.454.162.68l-1.49 2.462a.618.618 0 00-.065.42c.033.13.13.26.259.324l1.263.518a.463.463 0 01.259.616c-.032.162-.226.291-.421.291zM194.44 28.751c-.032 0-.032 0 0 0-1.231 0-2.04-1.004-2.073-1.036a.502.502 0 01.065-.68.502.502 0 01.68.064s.551.68 1.328.68c.55 0 1.133-.356 1.684-1.036a.502.502 0 01.68-.065.502.502 0 01.065.68c-.777.94-1.587 1.393-2.429 1.393zM202.73 35.746l5.635-.874-5.052 6.477-.583-5.603zM196.803 20.428a1.49 1.49 0 100-2.98 1.49 1.49 0 000 2.98zM159.56 257l38.668-.162c2.008 0 3.659-1.619 3.724-3.659l.097-4.89-24.45.356-18.524 4.631c-2.138.55-1.749 3.724.485 3.724z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M280.68 255.478H1.458v1.328H280.68v-1.328zM250.141 252.822l33.745-18.912c1.749-1.004 2.429-3.206 1.457-4.988l-2.299-4.307-21.212 12.177-13.926 13.051c-1.587 1.49.292 4.081 2.235 2.979z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M38.182 257c8.979 0 16.257-7.279 16.257-16.257 0-8.979-7.278-16.257-16.257-16.257-8.978 0-16.257 7.278-16.257 16.257 0 8.978 7.279 16.257 16.257 16.257z"
            ></path>
            <path
                fill="#fff"
                d="M38.181 249.228a8.485 8.485 0 100-16.97 8.485 8.485 0 000 16.97z"
            ></path>
            <path fill="#FD8945" d="M77.562 150.648H15.739v17.974h61.823v-17.974z"></path>
            <path
                fill="#D87345"
                d="M22.798 150.648h-.971v17.974h.971v-17.974zM31.543 150.648h-.972v17.974h.972v-17.974zM40.286 150.648h-.972v17.974h.972v-17.974zM49.03 150.648h-.972v17.974h.972v-17.974zM57.774 150.648h-.971v17.974h.971v-17.974zM66.518 150.648h-.971v17.974h.971v-17.974zM75.261 150.648h-.971v17.974h.971v-17.974z"
            ></path>
            <path fill="#2B2B2B" d="M15.739 150.648v8.485l29.956-8.485H15.739z"></path>
            <path fill="#FDB645" d="M69.596 114.927H7.773v17.974h61.823v-17.974z"></path>
            <path fill="#FD8945" d="M31.834 114.962H7.773v17.974h24.061v-17.974z"></path>
            <path fill="#D87345" d="M14.347 114.962H7.773v17.974h6.574v-17.974z"></path>
            <path fill="#FD8945" d="M64.803 114.927H40.74v17.974h24.062v-17.974z"></path>
            <path
                fill="#D87345"
                d="M64.802 114.927h-6.574v17.974h6.574v-17.974zM14.8 114.96h-.971v17.941h.971V114.96zM23.543 114.96h-.971v17.941h.971V114.96zM32.288 114.96h-.972v17.941h.972V114.96zM41.032 114.96h-.972v17.941h.972V114.96zM49.775 114.96h-.971v17.941h.971V114.96z"
            ></path>
            <path
                fill="#D87345"
                d="M58.52 114.96h-.972v17.941h.971V114.96zM67.264 114.96h-.972v17.941h.972V114.96z"
            ></path>
            <path fill="#2B2B2B" d="M40.772 132.901v-11.82l20.921 1.716v9.91l-20.92.194z"></path>
            <path fill="#FDB645" d="M67.684 132.709H5.862v17.974h61.822v-17.974z"></path>
            <path fill="#FD8945" d="M29.924 132.709H5.861v17.974h24.062v-17.974z"></path>
            <path fill="#D87345" d="M12.436 132.709H5.862v17.974h6.574v-17.974z"></path>
            <path fill="#FD8945" d="M62.957 132.675H38.895v17.973h24.062v-17.973z"></path>
            <path
                fill="#D87345"
                d="M62.956 132.675h-6.574v17.973h6.574v-17.973zM12.922 132.707h-.972v17.941h.972v-17.941zM21.666 132.707h-.971v17.941h.971v-17.941zM30.41 132.707h-.971v17.941h.971v-17.941zM39.154 132.707h-.972v17.941h.972v-17.941zM47.898 132.707h-.972v17.941h.972v-17.941z"
            ></path>
            <path
                fill="#D87345"
                d="M56.642 132.707h-.971v17.941h.971v-17.941zM65.385 132.707h-.971v17.941h.971v-17.941z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M71.797 132.707v17.973H59.134a30.857 30.857 0 01-4.955-16.84c0-.388 0-.745.033-1.133h17.585z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M77.562 150.648v17.974h-6.574c-8.582-2.947-15.448-9.554-18.783-17.974h25.357z"
            ></path>
            <path fill="#FD8945" d="M111.113 101.423H49.29v17.973h61.823v-17.973z"></path>
            <path
                fill="#2B2B2B"
                d="M111.113 101.455v8.485l-33.551-3.886 3.627-4.599h29.924z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M105.057 101.455h-.971v17.974h.971v-17.974zM96.313 101.455h-.971v17.974h.971v-17.974zM87.569 101.455h-.972v17.974h.972v-17.974zM78.825 101.455h-.971v17.974h.971v-17.974zM70.082 101.455h-.972v17.974h.972v-17.974zM61.337 101.455h-.971v17.974h.971v-17.974zM52.593 101.455h-.971v17.974h.971v-17.974z"
            ></path>
            <path fill="#FDB645" d="M120.083 65.54H58.261v17.974h61.822V65.54z"></path>
            <path fill="#D87345" d="M64.835 65.54H58.26v17.974h6.574V65.54z"></path>
            <path fill="#FD8945" d="M115.323 65.54H91.682v17.974h23.641V65.54z"></path>
            <path
                fill="#D87345"
                d="M120.083 65.54h-11.141v17.974h11.141V65.54zM65.32 65.54h-.971v17.974h.971V65.54zM74.064 65.54h-.972v17.974h.972V65.54zM82.808 65.54h-.971v17.974h.971V65.54zM91.552 65.54h-.971v17.974h.971V65.54zM100.296 65.54h-.972v17.974h.972V65.54z"
            ></path>
            <path
                fill="#D87345"
                d="M109.04 65.54h-.972v17.974h.972V65.54zM117.784 65.54h-.971v17.974h.971V65.54z"
            ></path>
            <path fill="#FDB645" d="M118.885 83.517H57.062v17.973h61.823V83.517z"></path>
            <path fill="#FD8945" d="M81.124 83.517H57.062v17.973h24.062V83.517z"></path>
            <path fill="#D87345" d="M63.636 83.517h-6.574v17.973h6.574V83.517z"></path>
            <path fill="#FD8945" d="M114.125 83.482H90.063v17.973h24.062V83.482z"></path>
            <path
                fill="#D87345"
                d="M118.917 83.482h-11.334v17.973h11.334V83.482zM64.122 83.514h-.971v17.941h.971V83.514z"
            ></path>
            <path fill="#2B2B2B" d="M72.867 83.514h-.972v17.941h.972V83.514z"></path>
            <path
                fill="#D87345"
                d="M81.61 83.514h-.972v17.941h.972V83.514zM90.354 83.514h-.972v17.941h.972V83.514zM99.098 83.514h-.971v17.941h.971V83.514zM107.841 83.514h-.971v17.941h.971V83.514zM116.586 83.514h-.972v17.941h.972V83.514z"
            ></path>
            <path fill="#2B2B2B" d="M118.885 83.546H72.38v.972h46.505v-.972z"></path>
            <path
                fill="#FD8945"
                d="M51.557 67.614v62.179h-8c-2.622 0-5.18-.324-7.61-.94a27.158 27.158 0 01-3.141-.971c-2.753-1.004-5.279-2.397-7.61-4.081l-1.263-.971c-1.49-1.231-2.883-2.559-4.146-4.048l-.032-.033c-.26-.324-.518-.615-.745-.939-1.554-2.008-2.882-4.178-3.919-6.542-.161-.323-.291-.647-.42-.971a29.375 29.375 0 01-1.814-6.51c-.065-.323-.097-.647-.162-.971-.194-1.328-.26-2.72-.26-4.113 0-.81.033-1.62.098-2.429l.097-.971c.26-2.235.713-4.437 1.425-6.51.065-.162.097-.324.162-.486.065-.161.13-.323.162-.485a31.051 31.051 0 012.397-5.02c.129-.194.259-.421.388-.615.065-.13.162-.227.227-.356a29.78 29.78 0 014.016-4.988l.13-.13c.29-.29.582-.582.873-.841a31.197 31.197 0 017.255-5.02c.712-.356 1.425-.68 2.17-.972a31.023 31.023 0 0111.658-2.266h8.064z"
            ></path>
            <path
                fill="#FFF6BB"
                d="M34.943 82.186v6.121H14.25c.065-.162.13-.324.162-.486a31.051 31.051 0 012.397-5.02c.13-.194.259-.42.389-.615h17.746z"
            ></path>
            <path
                fill="#EA7A45"
                d="M53.241 118.749v11.011c-.55.032-1.133.032-1.684.032h-7.999c-2.623 0-5.181-.324-7.61-.939a26.969 26.969 0 01-3.142-.972c-2.752-1.004-5.278-2.396-7.61-4.08l-1.263-.972c-1.49-1.23-2.882-2.558-4.145-4.048l-.033-.032h33.486zM44.044 70.366c0 .259-.227.486-.486.486H29.73c.712-.357 1.425-.68 2.17-.972h11.658c.26 0 .486.227.486.486zM36.758 76.357c0 .26-.227.486-.486.486H21.44l.13-.13c.29-.291.582-.583.874-.842H36.24c.291 0 .518.227.518.486zM29.471 82.316a.498.498 0 01-.486.486H16.841c.13-.195.26-.421.389-.616.064-.13.162-.226.226-.356h11.53c.226 0 .42.162.453.356.032.033.032.097.032.13zM28.046 88.307c0 .26-.227.486-.486.486H14.088c.065-.162.097-.324.162-.486.065-.162.13-.323.162-.486h13.116c.291 0 .518.227.518.486zM28.046 95.788c0 .26-.227.486-.486.486H12.566l.097-.972H27.56c.26 0 .486.227.486.486zM28.046 103.301a.498.498 0 01-.486.486H12.89c-.066-.324-.098-.648-.163-.972H27.56c.259 0 .486.227.486.486zM28.046 110.782a.498.498 0 01-.486.486H15.124c-.162-.324-.291-.648-.42-.972H27.56c.259 0 .486.227.486.486z"
            ></path>
            <path
                fill="#EA7A45"
                d="M29.956 118.296c0 .194-.13.388-.324.453-.065.032-.097.032-.162.032h-9.65l-.033-.032c-.259-.324-.518-.615-.745-.939H29.47c.26 0 .486.227.486.486zM36.272 123.315a.498.498 0 01-.486.486H25.228l-1.263-.972h11.82c.26 0 .487.227.487.486zM45.663 128.367a.498.498 0 01-.486.486h-9.23a26.969 26.969 0 01-3.14-.972h12.37c.26 0 .486.227.486.486z"
            ></path>
            <path
                fill="#FDE545"
                d="M82.647 98.703c0 1.23-.065 2.428-.227 3.627-1.522 13.213-11.367 23.9-24.127 26.717-1.651.356-3.335.616-5.052.713-.55.032-1.133.032-1.684.032-2.623 0-5.181-.324-7.61-.939a26.969 26.969 0 01-3.141-.972c-2.753-1.004-5.28-2.396-7.61-4.08l-1.264-.972c-1.49-1.23-2.882-2.558-4.145-4.048l-.033-.032c-.259-.324-.518-.616-.744-.939-1.555-2.008-2.883-4.178-3.919-6.542-.13-.324-.291-.648-.421-.972a29.345 29.345 0 01-1.814-6.509c-.064-.324-.097-.648-.162-.972-.194-1.327-.258-2.72-.258-4.112 0-.81.032-1.62.097-2.43l.097-.97c.259-2.235.712-4.438 1.425-6.51.064-.162.097-.324.162-.486.064-.162.097-.324.162-.486a31.055 31.055 0 012.396-5.02c.13-.194.26-.42.389-.615.064-.13.162-.227.226-.356a29.784 29.784 0 014.016-4.987 30.72 30.72 0 018.29-5.991c.713-.357 1.425-.68 2.17-.972a31.025 31.025 0 0111.659-2.267c17.196 0 31.122 13.925 31.122 31.09z"
            ></path>
            <path
                fill="#FDB645"
                d="M51.557 121.081c12.359 0 22.378-10.019 22.378-22.378 0-12.36-10.02-22.378-22.378-22.378-12.36 0-22.378 10.019-22.378 22.378s10.019 22.378 22.378 22.378z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M82.42 102.33c-1.521 13.213-11.366 23.9-24.126 26.717 1.555-13.18 11.367-23.867 24.127-26.717z"
            ></path>
            <path
                fill="#EA7A45"
                d="M47.186 88.016c-.227.129-.518.226-.81.226h1.62c-.292 0-.583-.065-.81-.227zm9.23-2.98c-.195 0-.357-.032-.55-.032H43.04V99.9h12.824a7.446 7.446 0 004.631-1.62 7.419 7.419 0 002.818-5.829c0-3.918-3.044-7.157-6.898-7.416zM46.279 96.662v-8.42h9.586c2.364 0 4.21 1.846 4.21 4.21a4.202 4.202 0 01-4.21 4.21H46.28z"
            ></path>
            <path
                fill="#EA7A45"
                d="M46.376 108.321c.292 0 .583.065.81.227.227-.13.518-.227.81-.227h-1.62zm14.12-10.04a7.446 7.446 0 00-4.63-1.619H43.04v14.897h12.824c.194 0 .356 0 .55-.032 3.855-.291 6.899-3.498 6.899-7.416a7.42 7.42 0 00-2.818-5.83zm-4.63 10.04h-9.587v-8.42h9.586c2.364 0 4.21 1.846 4.21 4.21a4.201 4.201 0 01-4.21 4.21zM46.376 88.242h-5.635c-.907 0-1.62-.712-1.62-1.62 0-.906.713-1.618 1.62-1.618h5.635c.907 0 1.62.712 1.62 1.619s-.713 1.62-1.62 1.62z"
            ></path>
            <path
                fill="#EA7A45"
                d="M47.996 88.242c-.907 0-1.62-.712-1.62-1.619v-4.437c0-.906.713-1.619 1.62-1.619.907 0 1.619.713 1.619 1.62v4.436c0 .907-.712 1.62-1.62 1.62zM54.796 88.242c-.907 0-1.62-.712-1.62-1.619v-4.437c0-.906.713-1.619 1.62-1.619.907 0 1.62.713 1.62 1.62v4.436c0 .907-.713 1.62-1.62 1.62zM47.996 115.964c-.907 0-1.62-.713-1.62-1.619v-4.405c0-.906.713-1.619 1.62-1.619.907 0 1.619.713 1.619 1.619v4.405c0 .906-.712 1.619-1.62 1.619zM54.796 115.964c-.907 0-1.62-.713-1.62-1.619v-4.405c0-.906.713-1.619 1.62-1.619.907 0 1.62.713 1.62 1.619v4.405c0 .906-.713 1.619-1.62 1.619z"
            ></path>
            <path
                fill="#EA7A45"
                d="M46.376 111.56h-5.635c-.907 0-1.62-.713-1.62-1.62 0-.906.713-1.619 1.62-1.619h5.635c.907 0 1.62.713 1.62 1.619 0 .907-.713 1.62-1.62 1.62z"
            ></path>
            <path
                fill="#FD8945"
                d="M98.774 102.718v62.179h-8a31.06 31.06 0 01-18.362-5.991 1067.2 1067.2 0 00-1.263-.971 34.95 34.95 0 01-4.145-4.049c-.259-.323-.55-.647-.777-.971a31.632 31.632 0 01-3.919-6.509 12.546 12.546 0 01-.42-.972 31.584 31.584 0 01-1.847-6.542c-.064-.324-.097-.647-.161-.971-.195-1.361-.26-2.721-.26-4.113 0-.81.033-1.619.098-2.429l.097-.972c.259-2.267.712-4.436 1.425-6.509.064-.162.097-.324.162-.486l.194-.486a31.08 31.08 0 012.396-5.019c.13-.195.26-.421.389-.616.065-.129.162-.226.227-.356a29.797 29.797 0 014.015-4.987l.13-.13c.291-.291.583-.583.874-.842a30.745 30.745 0 017.287-5.019c.712-.356 1.425-.68 2.17-.972a31.03 31.03 0 0111.658-2.267h8.032z"
            ></path>
            <path
                fill="#EA7A45"
                d="M91.261 105.471a.498.498 0 01-.485.486H76.947c.713-.357 1.425-.68 2.17-.972h11.659c.259 0 .486.227.486.486zM83.975 111.462a.498.498 0 01-.486.486H68.657c.032-.032.064-.065.13-.129.29-.292.582-.583.873-.842h13.797c.29 0 .518.226.518.485z"
            ></path>
            <path
                fill="#FFF6BB"
                d="M80.348 117.292v6.12h-18.88l.194-.486a31.08 31.08 0 012.396-5.019c.13-.194.26-.421.389-.615h15.901z"
            ></path>
            <path
                fill="#EA7A45"
                d="M76.688 117.421a.498.498 0 01-.486.486H64.058c.13-.194.259-.421.388-.615.065-.13.162-.227.227-.357h11.53c.226 0 .42.162.453.357.032.032.032.097.032.129zM75.263 123.412a.498.498 0 01-.486.486H61.306c.065-.162.098-.324.162-.486l.195-.485h13.116c.259 0 .485.226.485.485zM75.263 130.893a.498.498 0 01-.486.486H59.783l.097-.972h14.897c.26 0 .486.227.486.486zM75.263 138.406c0 .26-.227.486-.486.486h-14.67c-.065-.324-.097-.647-.162-.971h14.832c.26 0 .486.226.486.485zM75.263 145.92a.498.498 0 01-.486.486H62.342a12.584 12.584 0 01-.421-.972h12.856c.26 0 .486.227.486.486zM77.174 153.401a.497.497 0 01-.486.485h-9.65c-.26-.324-.551-.647-.778-.971h10.428c.26 0 .486.226.486.486zM83.521 158.42a.498.498 0 01-.485.486H72.413l-1.263-.971h11.853c.292 0 .518.226.518.485z"
            ></path>
            <path
                fill="#FDE545"
                d="M98.775 164.897c17.17 0 31.089-13.919 31.089-31.089 0-17.17-13.919-31.09-31.09-31.09-17.17 0-31.089 13.92-31.089 31.09s13.92 31.089 31.09 31.089z"
            ></path>
            <path
                fill="#FDB645"
                d="M98.775 156.186c12.359 0 22.378-10.019 22.378-22.378s-10.019-22.378-22.378-22.378c-12.36 0-22.378 10.019-22.378 22.378s10.019 22.378 22.378 22.378z"
            ></path>
            <path
                fill="#EA7A45"
                d="M103.115 135.006H90.29v-14.897h12.825a7.449 7.449 0 010 14.897zm-9.586-3.239h9.586a4.202 4.202 0 004.21-4.21 4.202 4.202 0 00-4.21-4.21h-9.586v8.42z"
            ></path>
            <path
                fill="#EA7A45"
                d="M103.115 146.664H90.29v-14.897h12.825a7.45 7.45 0 010 14.897zm-9.586-3.238h9.586a4.202 4.202 0 004.21-4.21 4.202 4.202 0 00-4.21-4.21h-9.586v8.42zM93.593 123.347H87.99c-.906 0-1.619-.712-1.619-1.619s.713-1.619 1.62-1.619h5.602c.907 0 1.62.712 1.62 1.619s-.713 1.619-1.62 1.619z"
            ></path>
            <path
                fill="#EA7A45"
                d="M95.244 123.347c-.907 0-1.62-.712-1.62-1.619v-4.437c0-.906.713-1.619 1.62-1.619.907 0 1.62.713 1.62 1.619v4.437c0 .907-.713 1.619-1.62 1.619zM102.014 123.347c-.907 0-1.62-.712-1.62-1.619v-4.437c0-.906.713-1.619 1.62-1.619.906 0 1.619.713 1.619 1.619v4.437c0 .907-.713 1.619-1.619 1.619zM95.244 151.069c-.907 0-1.62-.713-1.62-1.619v-4.405c0-.907.713-1.619 1.62-1.619.907 0 1.62.712 1.62 1.619v4.405c0 .906-.713 1.619-1.62 1.619zM102.014 151.069c-.907 0-1.62-.713-1.62-1.619v-4.405c0-.907.713-1.619 1.62-1.619.906 0 1.619.712 1.619 1.619v4.405c0 .906-.713 1.619-1.619 1.619z"
            ></path>
            <path
                fill="#EA7A45"
                d="M93.593 146.665H87.99c-.906 0-1.619-.713-1.619-1.62 0-.907.713-1.619 1.62-1.619h5.602c.907 0 1.62.712 1.62 1.619s-.713 1.62-1.62 1.62z"
            ></path>
            <path
                fill="#BE6361"
                d="M69.756 225.619L9.52 164.347h124.391v31.899l-64.155 29.373z"
            ></path>
            <path
                fill="#BE6361"
                d="M69.756 228.857a3.159 3.159 0 01-2.3-.971l-60.235-61.24c-.907-.939-1.198-2.332-.68-3.53a3.25 3.25 0 012.98-1.975h124.39a3.247 3.247 0 013.238 3.238v31.899a3.256 3.256 0 01-1.878 2.947l-64.122 29.341a3.469 3.469 0 01-1.393.291zM17.26 167.585l53.241 54.148 60.171-27.56v-26.588H17.26z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M11.82 171.309h125.329v-6.962l-131.741.485 6.412 6.477zM253.541 145.369a3.27 3.27 0 01-2.947-1.878c-.745-1.619-.032-3.562 1.587-4.307l32.223-14.768c1.619-.745 3.562-.032 4.307 1.587.745 1.619.033 3.563-1.587 4.307l-32.223 14.768a3.43 3.43 0 01-1.36.291z"
            ></path>
            <path
                fill="#F5BE6D"
                d="M38.181 243.981a3.27 3.27 0 01-2.947-1.878c-.745-1.619-.032-3.562 1.587-4.307l215.36-98.612c1.619-.745 3.562-.033 4.307 1.587.745 1.619.032 3.562-1.587 4.307L39.541 243.69a3.434 3.434 0 01-1.36.291z"
            ></path>
            <path
                fill="#BE6361"
                d="M138.251 167.585H3.239A3.248 3.248 0 010 164.347a3.249 3.249 0 013.239-3.239H138.25a3.25 3.25 0 013.239 3.239 3.248 3.248 0 01-3.239 3.238z"
            ></path>
            <path
                fill="#F5BE6D"
                d="M150.395 241.099h-14.67a3.343 3.343 0 01-2.299-.939l-28.661-28.661a3.225 3.225 0 010-4.566 3.225 3.225 0 014.566 0l27.722 27.722h10.104v-44.659a3.248 3.248 0 013.238-3.239 3.25 3.25 0 013.239 3.239v47.865a3.248 3.248 0 01-3.239 3.238z"
            ></path>
            <path
                fill="#FDC068"
                d="M276.502 124.74l2.752 8.906a9.036 9.036 0 01-5.926 11.27c-4.307 1.328-8.873-.713-10.752-4.664l-4.08-8.387 18.006-7.125z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M225.334 80.923c1.296 9.878 15.253 27.074 15.253 27.074l8.971 18.168-3.271-30.183-12.727-15.544-8.226.485z"
            ></path>
            <path
                fill="#6B9C83"
                d="M276.503 124.74l-10.914-35.17c-.259-.874-.712-1.716-1.295-2.494l-.454-.583-29.34-37.76a12.5 12.5 0 00-10.752-4.793c.259 1.878.518 3.756.745 5.667.162 4.404-.745 8.517-2.753 12.339a22.793 22.793 0 01-3.4 6.67l25.098 32.289 15.026 30.96 18.039-7.125z"
            ></path>
            <path
                fill="#C96F4D"
                d="M188.091 12.494l14.12-2.17c.907-.13 1.749.454 1.911 1.36l1.425 7.87 2.817-.324 1.846-.777 6.542 9.165-2.688-20.111c-.68-4.988-5.441-8.356-10.363-7.32l-19.626 3.92 4.016 8.387z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M227.763 81.798a.462.462 0 01-.389-.195l-7.74-10.557a.503.503 0 01.097-.68.502.502 0 01.68.097l7.74 10.557a.502.502 0 01-.097.68.52.52 0 01-.291.098zM248.651 101.391h-5.181a.499.499 0 01-.486-.486c0-.259.227-.486.486-.486h5.181c.259 0 .486.227.486.486a.499.499 0 01-.486.486zM193.824 85.328c-.292-.033-.486-.26-.454-.519l1.49-15.415a.471.471 0 01.518-.42.471.471 0 01.421.517l-1.49 15.416a.49.49 0 01-.485.42zM184.076 104.597a.515.515 0 01-.485-.389l-.745-4.048c-.033-.26.129-.518.388-.55a.457.457 0 01.551.388l.745 4.048c.032.259-.13.518-.389.551h-.065zM211.7 63.856l-4.728 15.545-2.332-15.545h7.06zM187.93 23.99a3.552 3.552 0 01-3.563-3.562 3.552 3.552 0 013.563-3.562 3.552 3.552 0 013.562 3.562 3.552 3.552 0 01-3.562 3.563zm0-6.152a2.599 2.599 0 00-2.591 2.59 2.599 2.599 0 002.591 2.591 2.599 2.599 0 002.591-2.59 2.599 2.599 0 00-2.591-2.591zM196.673 22.5a3.552 3.552 0 01-3.562-3.561 3.552 3.552 0 013.562-3.563 3.552 3.552 0 013.563 3.563 3.552 3.552 0 01-3.563 3.562zm0-6.152a2.599 2.599 0 00-2.591 2.59 2.599 2.599 0 002.591 2.591 2.599 2.599 0 002.591-2.59 2.599 2.599 0 00-2.591-2.591z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M191.006 20.072a.525.525 0 01-.485-.356.483.483 0 01.356-.583l2.591-.648a.482.482 0 01.226.94l-2.591.647h-.097zM199.297 17.837a.486.486 0 01-.486-.42c-.032-.26.129-.519.389-.551l10.006-1.684c.26-.033.519.13.551.388.032.26-.13.519-.389.551l-10.007 1.684c0 .032-.032.032-.064.032z"
            ></path>
            <path
                fill="#FDC068"
                d="M210.631 20.234l-2.17.291-.615-4.89a2.19 2.19 0 114.34-.583l.356 2.72a2.178 2.178 0 01-1.911 2.462z"
            ></path>
            <path
                fill="#000020"
                d="M214.614 141.839a.491.491 0 01-.486-.421l-3.918-36.433a.503.503 0 01.421-.55.502.502 0 01.55.421l3.919 36.433a.503.503 0 01-.421.55h-.065z"
            ></path>
            <path
                fill="#2B2B2B"
                d="M253.542 167.909a.498.498 0 01-.486-.485l-.194-9.651c0-.259.194-.486.486-.486.259 0 .485.194.485.486l.195 9.651a.477.477 0 01-.486.485z"
            ></path>
            <path
                fill="#fff"
                d="M210.177 51.68l-3.108-6.348 12.954-7.222.874 4.502-5.506 5.959-5.214 3.109zM207.069 45.333l-2.785 6.347-1.393-2.98-.615-5.375 1.036-1.976 3.757 3.984z"
            ></path>
            <path fill="#2B2B2B" d="M207.069 45.332l2.979 6.056h-5.635l2.656-6.056z"></path>
        </svg>
    )
}

export default Icon
