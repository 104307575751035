import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    overflow: hidden;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: row;
    position: relative;
    font-weight: 300;
`
