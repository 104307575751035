import styled from 'styled-components'
import MuiButton from '@material-ui/core/Button'
import MuiDialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'

import { media, colors, transition } from '@variables'

export const BackButton = styled(MuiButton)``
export const RightText = styled(Typography)``
export const CloseIcon = styled(CloseRoundedIcon)``

export const Dialog = styled(MuiDialog)`
    @media (max-width: 855px) {
        top: 90px !important;
    }
    @media (min-width: 1170px) {
        .MuiDialog-paperWidthSm {
            max-width: 980px;
        }
    }

    ${RightText} {
        margin-top: 40px;
    }

    ${CloseIcon} {
        color: ${colors.primary.gray2};
        transition: ${transition.main};
        position: absolute;
        cursor: pointer;
        right: 25px;
        z-index: 2;
        top: 25px;

        ${media.tablet} {
            :hover {
                color: white;
            }
        }
    }

    ${BackButton} {
        margin: 15px 0 35px 0;

        ${media.tablet} {
            display: none;
        }
    }

    ${RightText} {
        color: white;
        margin-top: 40px;
        max-width: 340px;
    }
`

export const H3 = styled.h3`
    width: 100%;
    margin-top: 0;
    font-size: 24px;
    text-align: left;
    font-weight: 700;
    line-height: 36px;

    ${media.phone} {
        text-align: center;
    }
`

export const Text = styled.p`
    margin: 0;
    text-align: left;

    ${media.phone} {
        text-align: center;
    }
`

export const LoginWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0 auto;
    min-width: 310px;
    max-width: 470px;
    position: relative;
    align-items: center;
    flex-direction: column;

    ${media.tablet} {
        min-width: 470px;
    }
    ${media.xl} {
        display: flex;
        min-width: 980px;
        max-width: 980px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`

export const Left = styled.div`
    height: 100%;
    width: 100%;

    ${media.xl} {
        width: 50%;
    }
`

export const Right = styled.div`
    width: 50%;
    height: 100%;
    display: none;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: ${colors.primary.orange};

    ${media.xl} {
        display: flex;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
    }

    svg {
        width: 100%;
        height: auto;
        max-width: 200px;
    }
`

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 20px 35px 0 35px;

    ${media.tablet} {
        padding: 65px;
    }
`

export const ButtonWrapper = styled.div`
    padding-top: 20px;
`

export const IllustrationWrapper = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    img,
    svg {
        width: 100%;
        height: auto;
    }

    ${media.phone} {
        max-width: 280px;
    }
`
