/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, ChangeEvent } from 'react'
import { useSetState } from 'react-use'

import { LibraryCategory } from '@graphql'

type State = {
    maxItems: number
    searchQuery: string
    selected: LibraryCategory | 'Vše'
}

interface Context extends State {
    loadMore: () => void
    onSelect: (key: LibraryCategory | 'Vše') => void
    handleSearch: (event: ChangeEvent<HTMLInputElement>) => void
}

export const LibraryContext = createContext<Context>({
    maxItems: 8,
    searchQuery: '',
    selected: 'Vše',
    loadMore: () => {},
    onSelect: (key: LibraryCategory | 'Vše') => {},
    handleSearch: (event: ChangeEvent<HTMLInputElement>) => {}
})

type Props = {
    children: any
}

const LibraryProvider = ({ children }: Props) => {
    const [state, setState] = useSetState<State>({
        maxItems: 8,
        searchQuery: '',
        selected: 'Vše'
    })

    const loadMore = () => setState({ maxItems: state.maxItems + 8 })

    const onSelect = (key: LibraryCategory | 'Vše') => {
        window.scroll(0, 0)
        setState({ selected: key })
    }

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 0) {
            setState({ searchQuery: e.target.value })
        } else {
            setState({ searchQuery: e.target.value })
        }
    }

    return (
        <LibraryContext.Provider value={{ ...state, loadMore, onSelect, handleSearch }}>
            {children}
        </LibraryContext.Provider>
    )
}

export default LibraryProvider
