import styled from 'styled-components'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

export const Text = styled(Typography)``
export const Title = styled(Typography)``
export const Button = styled(MuiButton)``

export const Section = styled.section`
    width: 100%;
    height: 100%;
    padding: 50px;
    display: flex;
    margin: 50px 0;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: white;

    ${Title} {
        max-width: 550px;
        font-size: 44px;
        font-weight: 400;
        line-height: normal;

        @media (max-width: 855px) {
            font-size: 26px;
        }
    }

    ${Text} {
        font-size: 18px;
        margin: 35px 0px;
        max-width: 415px;

        @media (max-width: 855px) {
            font-size: 16px;
        }
    }

    ${Button} {
        min-height: 55px;
        line-height: 1.45;
    }
`
