import { useMedia } from 'react-use'
import Menu from '@material-ui/core/Menu'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import Scrollbar from 'react-scrollbars-custom'
import React, { useState, useRef, useEffect, useContext, useCallback } from 'react'

import * as loco from '@loco'
import Avatar from '@static/images/avatar.png'
import { AuthContext } from '../../../context/Auth'
import LocationIcon from '../../../components/SVG/Location'
import Leader from '../../../components/Leaderboard/Leader'
import { LeaderboardQuery, LeaderboardFilterEnum, Role } from '@graphql'
import { Wrapper, Header, Controls, Title, Close, Filter, MenuItem, MobileFilters } from './styled'

type Data = LeaderboardQuery['leaderboard']['data'][0]

type Props = {
    data: Data[]
    isOpen: boolean
    scrollbarRef: any
    toggleOpen: () => void
    filters: LeaderboardFilterEnum[]
    findYourSelf: () => void
    setLeaderboardFilters: (f: LeaderboardFilterEnum[]) => void
    onScroll: (state: {
        scrollTop: number
        clientHeight: number
        contentScrollHeight: number
    }) => void
}

const enum Step {
    MAIN = 'MAIN',
    FILTERS = 'FILTERS'
}

const LeaderboardDialog = ({
    data,
    filters,
    isOpen,
    onScroll,
    scrollbarRef,
    findYourSelf,
    toggleOpen,
    setLeaderboardFilters
}: Props) => {
    const { user } = useContext(AuthContext)
    const isWide = useMedia('(min-width: 960px)')

    const ref = useRef(null)

    const [step, setStep] = useState<Step>(Step.MAIN)

    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const close = useCallback(() => {
        setStep(Step.MAIN)
        toggleOpen()
    }, [toggleOpen])

    const toggleMenu = useCallback(() => {
        setIsMenuOpen(!isMenuOpen)
    }, [isMenuOpen])

    const toggleFilter = useCallback(
        (newFilter: LeaderboardFilterEnum) => {
            setLeaderboardFilters(
                filters.includes(newFilter)
                    ? filters.filter((f) => f !== newFilter)
                    : [...filters, newFilter]
            )
            setStep(Step.MAIN)
        },
        [filters, setLeaderboardFilters]
    )

    useEffect(() => {
        if (isWide) {
            setStep(Step.MAIN)
            setIsMenuOpen(false)
        }
    }, [isWide])

    const handleMyPosition = useCallback(() => {
        findYourSelf()
    }, [findYourSelf])

    const isThereDistrict = user?.district?.id
    const isThereLifeStage = user?.lifeStage?.name
    const isThereExp = user?.investmentExperience?.value

    return (
        <Dialog
            open={isOpen}
            onClose={close}
            fullScreen={!isWide}
            aria-labelledby="leaderboard-dialog"
            PaperProps={{ style: { maxWidth: 900 } }}
        >
            <Wrapper>
                <Header>
                    <Title variant="h2">
                        {step === Step.FILTERS ? 'Filtr' : loco.profile.profile.leaderboard}
                    </Title>

                    <Controls>
                        {isWide && step === Step.MAIN && (
                            <>
                                <Filter ref={ref} onClick={toggleMenu}>
                                    {loco.leaderboard_show}: <b>{loco.filters}</b>
                                    <Arrow />
                                </Filter>

                                <Menu
                                    keepMounted
                                    open={isMenuOpen}
                                    onClose={toggleMenu}
                                    anchorEl={ref.current}
                                    style={{ top: 50 }}
                                    MenuListProps={{
                                        style: {
                                            padding: 12,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start'
                                        }
                                    }}
                                    PaperProps={{
                                        style: {
                                            width: 235,
                                            boxShadow: '0px 10px 50px rgba(42, 48, 57, 0.1)'
                                        }
                                    }}
                                >
                                    {isThereDistrict && (
                                        <MenuItem
                                            onClick={() => {
                                                toggleFilter(LeaderboardFilterEnum.DISTRICT)
                                            }}
                                        >
                                            <span style={{ paddingRight: 6 }}>
                                                {getFilterName(LeaderboardFilterEnum.DISTRICT)}
                                            </span>
                                            {filters.includes(LeaderboardFilterEnum.DISTRICT) && (
                                                <BlackArrow />
                                            )}
                                        </MenuItem>
                                    )}
                                    {isThereExp && (
                                        <MenuItem
                                            onClick={() => {
                                                toggleFilter(
                                                    LeaderboardFilterEnum.INVESTMENT_EXPERIENCE
                                                )
                                            }}
                                        >
                                            <span style={{ paddingRight: 6 }}>
                                                {getFilterName(
                                                    LeaderboardFilterEnum.INVESTMENT_EXPERIENCE
                                                )}
                                            </span>
                                            {filters.includes(
                                                LeaderboardFilterEnum.INVESTMENT_EXPERIENCE
                                            ) && <BlackArrow />}
                                        </MenuItem>
                                    )}
                                    {isThereLifeStage && (
                                        <MenuItem
                                            onClick={() => {
                                                toggleFilter(LeaderboardFilterEnum.LIFE_STAGE)
                                            }}
                                        >
                                            <span style={{ paddingRight: 6 }}>
                                                {getFilterName(LeaderboardFilterEnum.LIFE_STAGE)}
                                            </span>
                                            {filters.includes(LeaderboardFilterEnum.LIFE_STAGE) && (
                                                <BlackArrow />
                                            )}
                                        </MenuItem>
                                    )}
                                </Menu>
                            </>
                        )}

                        {!isWide && step === Step.MAIN && (
                            <Button
                                size="large"
                                color="primary"
                                variant="outlined"
                                startIcon={<FilterIcon />}
                                style={{ margin: '15px 0 10px 0' }}
                                onClick={() => setStep(Step.FILTERS)}
                            >
                                {loco.leaderboard_filter}
                            </Button>
                        )}

                        {step === Step.MAIN && user?.role === Role.STUDENT && (
                            <Button
                                size="large"
                                color="primary"
                                variant="outlined"
                                startIcon={<LocationIcon />}
                                onClick={handleMyPosition}
                            >
                                {loco.leaderboard_find_yourself}
                            </Button>
                        )}

                        <Close onClick={close} />
                    </Controls>
                </Header>
                {step === Step.MAIN && (
                    <Scrollbar
                        noScrollX
                        ref={scrollbarRef}
                        onScrollStop={onScroll}
                        thumbYProps={{ className: 'thumbY' }}
                        trackYProps={{ className: 'trackY' }}
                        style={{ height: isWide ? 570 : '100%' }}
                        wrapperProps={{ style: { inset: '0 20px' } }}
                    >
                        <Wrapper>
                            {data.map((item: Data, idx: number) => (
                                <Leader
                                    idx={idx}
                                    place={item.rank}
                                    name={item.username}
                                    score={item.points}
                                    key={`${item.username}-${idx}`}
                                    avatar={item.profilePicture || Avatar}
                                    style={
                                        isWide
                                            ? { width: 'calc(100% - 40px)' }
                                            : { width: 'calc(100%)' }
                                    }
                                />
                            ))}
                        </Wrapper>
                    </Scrollbar>
                )}

                {step === Step.FILTERS && (
                    <MobileFilters>
                        {isThereDistrict && (
                            <MenuItem
                                onClick={() => {
                                    toggleFilter(LeaderboardFilterEnum.DISTRICT)
                                }}
                            >
                                <span>{getFilterName(LeaderboardFilterEnum.DISTRICT)}</span>
                                {filters.includes(LeaderboardFilterEnum.DISTRICT) && <BlackArrow />}
                            </MenuItem>
                        )}
                        {isThereExp && (
                            <MenuItem
                                onClick={() => {
                                    toggleFilter(LeaderboardFilterEnum.INVESTMENT_EXPERIENCE)
                                }}
                            >
                                <span>
                                    {getFilterName(LeaderboardFilterEnum.INVESTMENT_EXPERIENCE)}
                                </span>
                                {filters.includes(LeaderboardFilterEnum.INVESTMENT_EXPERIENCE) && (
                                    <BlackArrow />
                                )}
                            </MenuItem>
                        )}
                        {isThereLifeStage && (
                            <MenuItem
                                onClick={() => {
                                    toggleFilter(LeaderboardFilterEnum.LIFE_STAGE)
                                }}
                            >
                                <span>{getFilterName(LeaderboardFilterEnum.LIFE_STAGE)}</span>
                                {filters.includes(LeaderboardFilterEnum.LIFE_STAGE) && (
                                    <BlackArrow />
                                )}
                            </MenuItem>
                        )}

                        <Button
                            fullWidth
                            size="large"
                            color="primary"
                            variant="outlined"
                            style={{ marginTop: 15 }}
                            onClick={() => setStep(Step.MAIN)}
                        >
                            {loco.common.back}
                        </Button>
                    </MobileFilters>
                )}
            </Wrapper>
        </Dialog>
    )
}

const getFilterName = (f?: LeaderboardFilterEnum): string => {
    if (f === LeaderboardFilterEnum.DISTRICT) return loco.leaderboard_district
    if (f === LeaderboardFilterEnum.INVESTMENT_EXPERIENCE) return loco.leaderboard_exp
    if (f === LeaderboardFilterEnum.LIFE_STAGE) return loco.leaderboard_lifestage
    return loco.leaderboard_notset
}

const Arrow = () => (
    <svg
        width="6"
        height="4"
        fill="none"
        viewBox="0 0 6 4"
        style={{ marginLeft: 5 }}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fill="#F59100" d="M6 0H0l3 4 3-4z"></path>
    </svg>
)

const FilterIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="14"
            fill="none"
            viewBox="0 0 22 14"
        >
            <path fill="#F59100" d="M0 0H22V2H0z"></path>
            <path fill="#F59100" d="M3 6H19V8H3z"></path>
            <path fill="#F59100" d="M6 12H16V14H6z"></path>
        </svg>
    )
}

const BlackArrow = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            fill="none"
            viewBox="0 0 17 16"
        >
            <path
                fill="#000046"
                fillRule="evenodd"
                d="M5.855 13.131a.66.66 0 00.944.01l9.34-9.345a.66.66 0 00-.933-.934L6.33 11.739 2.128 7.534a.66.66 0 10-.935.934l4.662 4.663z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default LeaderboardDialog
