import React from 'react'
import { format } from 'date-fns'
import { cs } from 'date-fns/locale'
import MaterialTable, { MTableHeader } from 'material-table'

import TestIcon from './Icons/test'
import Pagination from '../Pagination'
import QuestionIcon from './Icons/question'
import AccountDeletedIcon from './Icons/deleted'
import RegistrationIcon from './Icons/registration'

import * as loco from '@loco'
import { tableLocalization } from '@variables'

import Row from '../../shared/Row'
import { AnswersRow } from './styled'
import WrongIcon from '../../SVG/WrongIcon'
import CorrectIcon from '../../SVG/CorrectIcon'
import { tableIcons, headerStyle } from '../../shared'
import { StudentActivityQuery, StudentActivityTypeEnum } from '@graphql'

type Activity = StudentActivityQuery['studentActivity']['data'][0]

type Props = {
    data: any[]
    page: number
    loading: boolean
    totalCount: number
    itemsPerPage: number
    onChangePage: (page: number) => void
    onChangeRowsPerPage: (pageSize: number) => void
}

const StudentActivity = ({
    data,
    page,
    loading,
    totalCount,
    itemsPerPage,
    onChangePage,
    onChangeRowsPerPage
}: Props) => (
    <MaterialTable
        page={page}
        data={data}
        columns={columns}
        icons={tableIcons}
        isLoading={loading}
        totalCount={totalCount}
        title={loco.admin.activity}
        onChangePage={onChangePage}
        localization={tableLocalization}
        onChangeRowsPerPage={onChangeRowsPerPage}
        components={{
            Header: (props) => (<MTableHeader {...props} />) as any,
            Pagination: Pagination
        }}
        options={{
            actionsColumnIndex: -1,
            debounceInterval: 500,
            pageSize: itemsPerPage,
            thirdSortClick: false,
            draggable: false,
            sorting: false,
            search: false
        }}
    />
)

const columns = [
    {
        field: 'action',
        title: 'Akce',
        headerStyle: { ...headerStyle },
        render: function render(row: Activity) {
            const icon = getIcon(row.type)
            const type = getType(row.type)
            return <Row text={type} icon={icon} />
        }
    },
    {
        field: 'username',
        title: 'Uživatel',
        headerStyle: { ...headerStyle },
        render: function render(row: Activity) {
            return <Row text={row?.username || 'anonymizovaný uživatel'} maxChars={22} />
        }
    },
    {
        title: 'Datum',
        field: 'createdAt',
        headerStyle: { ...headerStyle },
        render: function render(row: Activity) {
            return <Row text={format(new Date(row.createdAt), 'dd.MM.Y HH:mm', { locale: cs })} />
        }
    },
    {
        title: 'Body',
        field: 'points',
        headerStyle: { ...headerStyle },
        render: function render(row: Activity) {
            return <Row text={`${row?.points || ''}`} />
        }
    },
    {
        title: 'Odpovědi',
        field: 'answers',
        headerStyle: { ...headerStyle },
        render: function render(row: Activity) {
            const correct = row.answers?.correct || 0
            const wrong = row.answers?.wrong || 0

            if (wrong === 0 && correct === 0) return null

            return (
                <AnswersRow>
                    <CorrectIcon style={pointsStyles} /> {correct}
                    <WrongIcon style={{ ...pointsStyles, marginLeft: 10 }} /> {wrong}
                </AnswersRow>
            )
        }
    },
    {
        title: 'Název',
        field: 'testName',
        headerStyle: { ...headerStyle },
        render: function render(row: Activity) {
            return <Row text={`${row?.testName || ''}`} maxChars={40} />
        }
    }
]

const iconStyles = { marginRight: 15 }

const pointsStyles = { width: 16, height: 16, marginRight: 5 }

const getIcon = (type: StudentActivityTypeEnum) => {
    if (type === StudentActivityTypeEnum.TEST) {
        return <TestIcon style={iconStyles} />
    }
    if (type === StudentActivityTypeEnum.ACCOUNT_DELETED) {
        return <AccountDeletedIcon style={iconStyles} />
    }
    if (type === StudentActivityTypeEnum.REGISTRATION) {
        return <RegistrationIcon style={iconStyles} />
    }
    return <QuestionIcon style={iconStyles} />
}

const getType = (type: StudentActivityTypeEnum): string => {
    if (type === StudentActivityTypeEnum.TEST) return 'Test'
    if (type === StudentActivityTypeEnum.REGISTRATION) return 'Registrace'
    if (type === StudentActivityTypeEnum.ACCOUNT_DELETED) return 'Smazání účtu'
    return 'Denní otázka'
}

export default StudentActivity
