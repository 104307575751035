import 'date-fns'
import { format } from 'date-fns'
import { GraphQLError } from 'graphql'
import { cs } from 'date-fns/locale'
import { useSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import React, { useState, useContext } from 'react'
import { Formik, Field, FormikProps } from 'formik'
import { useHistory, useParams } from 'react-router-dom'

import * as loco from '@loco'
import { translateGQLError } from '@utils'
import { mapByNotificationSchedule } from '..'
import Head from '../../../../../components/Head'
import { ButtonWrapper, SelectWrapper } from '../styled'
import { Routes, notificationMsgs } from '@variables'
import Loader from '../../../../../components/shared/Loader'
import Tooltip from '../../../../../components/shared/Tooltip'
import InputField from '../../../../../components/Fields/Input'
import { AuthContext } from '../../../../../context/Auth'
import DialogReject from '../../../../../components/Dialogs/Reject'
import BackButton from '../../../../../components/shared/BackButton'
import { SmallContainer, Container, Form, Box } from '../../../../../components/shared/styled'
import {
    useApproveCustomNotificationMutation,
    useRejectCustomNotificationMutation,
    useCustomNotificationQuery,
    CustomNotificationQuery,
    Role
} from '@graphql'

type Notification = CustomNotificationQuery['customNotification']

const ReviewNotification = () => {
    const { enqueueSnackbar } = useSnackbar()

    const { user, loading: authLoading } = useContext(AuthContext)

    const [open, setOpen] = useState(false)
    const toggleOpen = () => setOpen(!open)

    const history = useHistory()
    const { id } = useParams<{ readonly id: string }>()

    const [approve, { loading: approveLoading }] = useApproveCustomNotificationMutation()
    const [rejectNotification, { loading: rejectLoading }] = useRejectCustomNotificationMutation()

    const isLoading = rejectLoading || approveLoading

    const { data, error, loading } = useCustomNotificationQuery({
        variables: { where: { id: id } }
    })

    if (loading) return <Loader halfHeight />
    if (!data || !data.customNotification || error) {
        error && enqueueSnackbar(translateGQLError(error.message), { variant: 'error' })
        return null
    }

    const notification: Notification = data.customNotification

    const submit = async () => {
        const { data, errors } = await approve({
            variables: { where: { id: id } }
        })

        if (data && !errors) {
            enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
            history.push({
                pathname: Routes.REVIEW,
                search: Routes.NOTIFICATIONS_SEARCH_PARAM
            })
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }
    }

    const reject = async (reason: string): Promise<{ errors: GraphQLError[] | undefined }> => {
        const { data, errors } = await rejectNotification({
            variables: {
                data: { reason: reason },
                where: { id: id }
            }
        })

        if (!errors && data) {
            enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
            history.push({
                pathname: Routes.REVIEW,
                search: Routes.NOTIFICATIONS_SEARCH_PARAM
            })
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }

        return { errors }
    }

    const isSameAuthor = notification.author.id === user?.id || user?.role === Role.SUPER_ADMIN

    return (
        <>
            <Head title={loco.seo.notifications.review.title} />

            <DialogReject
                open={open}
                reject={reject}
                close={toggleOpen}
                title={loco.dialogs.reject.notification}
            />

            <Container>
                <SmallContainer>
                    <BackButton
                        position="relative"
                        onClick={() => history.push(Routes.REVIEW)}
                        style={{
                            fontSize: 18,
                            fontWeight: 400,
                            margin: '40px 0 20px 0'
                        }}
                    >
                        {loco.admin.review.notification.title.name}
                    </BackButton>

                    <Box>
                        <Formik
                            onSubmit={submit}
                            initialValues={{
                                date: format(new Date(notification.runAt), 'd/M/Y HH:mm', {
                                    locale: cs
                                }),
                                repeat: mapByNotificationSchedule(notification.repeat),
                                body: notification?.body || '',
                                title: notification.title
                            }}
                        >
                            {({ isSubmitting }: FormikProps<any>) => (
                                <Form>
                                    <Field
                                        disabled
                                        fullWidth
                                        key="title"
                                        name="title"
                                        component={InputField}
                                        className="customInput"
                                        label={loco.admin.create.notification.title.label}
                                        placeholder={
                                            loco.admin.create.notification.title.placeholder
                                        }
                                    />
                                    <Field
                                        disabled
                                        fullWidth
                                        key="body"
                                        name="body"
                                        component={InputField}
                                        className="customInput"
                                        label={loco.admin.create.notification.text.label}
                                        placeholder={
                                            loco.admin.create.notification.text.placeholder
                                        }
                                    />
                                    <SelectWrapper>
                                        <Field
                                            disabled
                                            fullWidth
                                            key="date"
                                            name="date"
                                            component={InputField}
                                            className="customInput"
                                            style={{ width: 185, marginRight: 25 }}
                                            label={loco.admin.create.notification.date}
                                        />
                                        <Field
                                            disabled
                                            fullWidth
                                            key="repeat"
                                            name="repeat"
                                            component={InputField}
                                            style={{ width: 185 }}
                                            className="customInput"
                                            label={loco.admin.create.notification.repeat.repeat}
                                        />
                                    </SelectWrapper>

                                    <ButtonWrapper>
                                        {user?.role !== Role.SUPER_ADMIN && isSameAuthor && (
                                            <Tooltip
                                                withHelpIcon
                                                title={loco.tooltips.sameAuthor}
                                                style={{
                                                    justifySelf: 'flex-start',
                                                    marginRight: 'auto'
                                                }}
                                            />
                                        )}
                                        <Button
                                            size="large"
                                            type="button"
                                            color="secondary"
                                            variant="outlined"
                                            onClick={toggleOpen}
                                            disabled={
                                                isSubmitting ||
                                                isLoading ||
                                                (isSameAuthor && user?.role !== Role.SUPER_ADMIN)
                                            }
                                        >
                                            {loco.common.reject}
                                        </Button>
                                        <Button
                                            size="large"
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            style={{ marginLeft: 30 }}
                                            disabled={
                                                isSubmitting ||
                                                isLoading ||
                                                (isSameAuthor && user?.role !== Role.SUPER_ADMIN)
                                            }
                                        >
                                            {loco.common.approve}
                                        </Button>
                                    </ButtonWrapper>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </SmallContainer>
            </Container>
        </>
    )
}

export default ReviewNotification
