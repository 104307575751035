import { Fade } from 'react-reveal'
import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'

import * as loco from '@loco'
import { Routes } from '@variables'
import AppStore from '@static/images/LP-app-store.svg'
import PlayStore from '@static/images/LP-play-store.png'
import Img from '@static/images/LP-application-left.png'
import { Wrapper, Container, TopTitle, Wrap, Left, Right, Text, Row, Button, Title } from './styled'
import { openInNewTab } from '@utils'

const Application = () => {
    const history = useHistory()
    const { ref, inView } = useInView({
        threshold: 0.2
    })

    useEffect(() => {
        if (inView) {
            history.replace({ hash: Routes.APP_HASH_PARAM })
        }
    }, [inView])

    return (
        <Wrapper id={Routes.APP_HASH_PARAM}>
            <Container>
                <Wrap ref={ref}>
                    <Left>
                        <Fade>
                            <img src={Img} alt="mobile app image" loading="lazy" />
                        </Fade>
                    </Left>
                    <Right>
                        <Fade fade={50}>
                            <TopTitle
                                dangerouslySetInnerHTML={{ __html: loco.lp.app.topTitle }}
                            ></TopTitle>
                        </Fade>
                        <Fade fade={100}>
                            <Title
                                variant="h2"
                                dangerouslySetInnerHTML={{ __html: loco.lp.app.title }}
                            ></Title>
                        </Fade>
                        <Fade delay={150}>
                            <Row>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={Routes.APP_STORE}
                                >
                                    <img src={AppStore} alt="app in apple store" loading="lazy" />
                                </a>
                                <a href={Routes.GOOGLE_PLAY}>
                                    <img src={PlayStore} alt="app in play store" loading="lazy" />
                                </a>
                                <Link to={Routes.HOME}>
                                    <Button size="large" color="primary" variant="contained">
                                        {loco.lp.app.button}
                                    </Button>
                                </Link>
                            </Row>
                        </Fade>
                        <Fade delay={200}>
                            <Text
                                paragraph
                                dangerouslySetInnerHTML={{ __html: loco.lp.app.text }}
                            ></Text>
                        </Fade>
                    </Right>
                </Wrap>
            </Container>
        </Wrapper>
    )
}

export default Application
