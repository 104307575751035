import styled from 'styled-components'
import Facebook from '@material-ui/icons/Facebook'

import Logo from '../../../SVG/AdminLogo'
import Google from '../../../SVG/GoogleIcon'

import { media } from '@variables'

export const FacebookIcon = styled(Facebook)`
    color: #4968ad;
    height: 18px !important;
`

export const GoogleIcon = styled(Google)`
    height: 14px;
`

export const PlatformIcon = styled(Logo)`
    width: 14px;
    height: auto;

    ${media.tablet} {
        :hover {
            transform: none;
        }
    }
`

export const IconWrapper = styled.div`
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`
