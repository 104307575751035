import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

import { colors } from '@variables'

type CircleProps = {
    readonly size: number
}

export const Inner = styled(CircularProgress)``
export const Middle = styled(CircularProgress)``
export const Outer = styled(CircularProgress)``

export const Wrapper = styled.div<CircleProps>`
    position: relative;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;

    ${Inner} {
        height: calc(100% + 4px) !important;
        width: calc(100% + 4px) !important;
        color: ${colors.primary.orange};
        stroke-linecap: round;
        position: absolute;
        right: -3px;
        top: -2px;
    }
    ${Middle} {
        height: calc(100% + 4px) !important;
        width: calc(100% + 4px) !important;
        color: ${colors.primary.green};
        stroke-linecap: round;
        position: absolute;
        right: -3px;
        top: -2px;
    }
    ${Outer} {
        height: calc(100% + 4px) !important;
        width: calc(100% + 4px) !important;
        stroke-linecap: round;
        position: absolute;
        right: -3px;
        top: -3px;
    }
`

export const Circle = styled.div<CircleProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid #eaebec;
    border-radius: 185px;

    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    min-width: ${(props) => props.size}px;
    min-height: ${(props) => props.size}px;

    position: absolute;
    left: 50%;
    margin-left: -${(props) => props.size / 2}px;
    top: 50%;
    margin-top: -${(props) => props.size / 2}px;
`

export const IconWrapper = styled.div<{ readonly onlyOuterValue?: boolean }>`
    width: 98%;
    height: auto;

    top: 6px;
    position: relative;

    img,
    svg {
        width: 98%;
        height: auto;
    }
`
