import styled, { css } from 'styled-components'
import MuiToolbar from '@material-ui/core/Toolbar'

export const Wrap = styled(MuiToolbar)`
    width: 100%;
    display: flex;
    justify-content: space-between;

    .MuiPickersDateRangePickerInput-root {
        height: 100%;
    }
`

export const Fields = styled.div`
    display: flex;
`

export const FieldWrap = styled.div`
    height: 40px;
    position: relative;

    .MuiFormControl-root {
        width: 100%;
        height: 100%;
    }
    .MuiInputBase-root {
        height: 100%;
    }
    input {
        padding: 0 26px;
    }

    :first-of-type {
        width: 280px;
        margin-right: 36px;

        .MuiInputBase-root {
            margin: 0;
            padding: 6px 0;
        }
        .MuiInputBase-input {
            padding: 6px 26px 0 26px;
        }
    }
    :last-of-type {
        width: 200px;
    }
`

export const IconWrap = styled.div<{ edge: 'start' | 'end' }>`
    top: 10px;
    z-index: 9;
    width: 20px;
    height: 20px;
    position: absolute;

    ${({ edge }) =>
        edge === 'start'
            ? css`
                  left: 0;
              `
            : css`
                  right: 0;
                  cursor: pointer;
              `}

    svg {
        width: 100%;
        height: 100%;
        fill: #c0c0c0;
        color: #c0c0c0;
    }
`
