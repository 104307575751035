import { ActivityType } from '@graphql'

export type Props = {
    color: string
}

export const getColor = (activity: ActivityType, opacity?: number): string => {
    if (activity === ActivityType.APPROVED) {
        return `hsla(128, 96%, 35%, ${opacity || 1})`
    }
    if (activity === ActivityType.BLOCKED) {
        return `hsla(0, 0%, 53%, ${opacity || 1})`
    }
    if (activity === ActivityType.CREATED) {
        return `hsla(196, 100%, 46%, ${opacity || 1})`
    }
    if (activity === ActivityType.DELETED) {
        return `hsla(348, 100%, 68%, ${opacity || 1})`
    }
    if (activity === ActivityType.REJECTED) {
        return `hsla(32, 100%, 50%, ${opacity || 1})`
    }
    if (activity === ActivityType.UNBLOCKED) {
        return `hsla(234, 100%, 22%, ${opacity || 1})`
    }
    if (activity === ActivityType.PUBLISHED) {
        return `hsla(195, 100%, 34%, ${opacity || 1})`
    }
    if (activity === ActivityType.UPDATED) {
        return `hsla(281, 100%, 50%, ${opacity || 1})`
    }

    return `hsla(0, 0%, 0%, ${opacity || 1})`
}
