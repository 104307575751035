import styled from 'styled-components'
import { Button as MuiButton } from '@material-ui/core'

import { media } from '@variables'
import { transition, colors } from '@variables'

export const TitleWrapper = styled.div<{ readonly isIconThere: boolean }>`
    width: 100%;
    margin: 0 0 5px 0;
    position: relative;

    ::before {
        position: absolute;
        content: '';
        left: -12px;
        top: 4px;
        width: 2px;
        height: 20px;
        background-color: ${colors.primary.orange};
    }

    ${(props) =>
        props.isIconThere &&
        `
            display:flex;
            align-items: center;
            flex-direction: row;
    `}

    ${media.tablet} {
        :hover {
            span {
                color: ${colors.primary.orange};
            }
        }
    }

    span {
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: normal;
        transition: ${transition.main};
    }
`

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    border-radius: 5px;
    flex-direction: column;
    background-color: white;
    padding: 30px 30px 20px 30px;
`

export const IconWrapper = styled.div`
    display: flex;
    width: 75px;
    min-width: 75px;
    align-items: center;
    margin-bottom: 25px;
    cursor: pointer;

    ${media.tablet} {
        margin-bottom: 0;
    }
`

export const NextButton = styled(MuiButton)``

export const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    ${media.tablet} {
        flex-direction: row;
        align-items: flex-start;
    }

    ${NextButton} {
        min-width: 160px;
        margin-bottom: 20px;

        ${media.tablet} {
            margin-bottom: 0;
            margin-left: auto;
            justify-self: flex-end;
        }
    }
`

export const Description = styled.p`
    margin: 0;
    font-size: 14px;
    line-height: 20px;
`

export const Footer = styled.div`
    width: 100%;
    display: flex;
    padding-left: 0;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;

    ${media.tablet} {
        padding-left: 122px;
    }
`

export const CompleteIconWrapper = styled.div`
    width: 26px;
    height: 26px;
    margin-left: 10px;
`

export const Info = styled.div`
    display: inline-flex;
    align-items: center;
    padding-right: 30px;
    padding-bottom: 10px;

    span {
        font-weight: 500;
        padding-left: 10px;
    }
`

export const TextWrapper = styled.div`
    padding: 0 0 20px 0;

    ${media.tablet} {
        padding: 0 20px 20px 50px;
    }
`
