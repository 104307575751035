import React, { CSSProperties } from 'react'
import Scrollbar from 'react-scrollbars-custom'
import Loader from '@material-ui/core/CircularProgress'

import { Wrapper } from './styled'
import { getIcon } from '@utils'
import Achievement from './Achievement'
import { BadgesFragment } from '@graphql'
import { Wrapper as Placeholder } from './Achievement/styled'

export type Props = {
    loading: boolean
    style?: CSSProperties
    badges: BadgesFragment[]
    variant: 'horizontal' | 'vertical'
}

const Achievements = ({ badges, loading, variant, style = {} }: Props) => {
    return (
        <Scrollbar
            noScrollX
            thumbYProps={{ className: 'thumbY' }}
            trackYProps={{ className: 'trackY' }}
            style={{ height: variant === 'horizontal' ? 320 : 275, ...style }}
        >
            <Wrapper variant={variant}>
                {loading && <Loader className="scrollbar-loader" />}

                {!loading &&
                    badges.map((item: BadgesFragment) => (
                        <Achievement
                            variant={variant}
                            key={item.badge.id}
                            name={item.badge.name}
                            icon={getIcon(item.badge.icon)}
                            description={item.badge.description}
                        />
                    ))}

                {/**
                 * Render fake nodes to align the last row
                 * in flex container with justify-content: space-between.
                 *
                 * https://stackoverflow.com/a/22018710
                 */}
                {!loading &&
                    [...Array(Math.round(badges.length / 2))].map((item, idx) => (
                        <Placeholder
                            variant={variant}
                            style={{ padding: 0 }}
                            key={`placeholder-${idx}`}
                        />
                    ))}
            </Wrapper>
        </Scrollbar>
    )
}

export default Achievements
