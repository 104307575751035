import React from 'react'

import { colors } from '@variables'

function Icon({ color, ...rest }: any) {
    const fill = color ? color : colors.primary.orange
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="none"
            viewBox="0 0 22 22"
            {...rest}
        >
            <path
                stroke={fill}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M6.625 17.375a.75.75 0 01.75-.75h8.5a.75.75 0 01.75.75v2.25a.75.75 0 01-.75.75h-8.5a.75.75 0 01-.75-.75v-2.25zM7.875 13.625a.75.75 0 01.75-.75h8.5a.75.75 0 01.75.75v2.25a.75.75 0 01-.75.75h-8.5a.75.75 0 01-.75-.75v-2.25zM4.125 9.875a.75.75 0 01.75-.75h8.5a.75.75 0 01.75.75v2.25a.75.75 0 01-.75.75h-8.5a.75.75 0 01-.75-.75v-2.25zM5.375 6.125a.75.75 0 01.75-.75h8.5a.75.75 0 01.75.75v2.25a.75.75 0 01-.75.75h-8.5a.75.75 0 01-.75-.75v-2.25z"
                clipRule="evenodd"
            ></path>
            <path
                stroke={fill}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M19.125 7.25v2.5M17.875 8.5h2.5M2.875 1.625v2.5M1.625 2.875h2.5"
            ></path>
        </svg>
    )
}

export default Icon
