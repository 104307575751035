/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios'
import last from 'lodash/last'
import merge from 'lodash/merge'
import { useSetState } from 'react-use'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { osName, osVersion, browserName, browserVersion } from 'react-device-detect'

import * as loco from '@loco'
import { GraphQLError } from 'graphql'
import { translateGQLError } from '@utils'
import { Routes, notificationMsgs } from '@variables'
import Icon from '../components/SVG/Loader'
import { DialogContext, DIALOG_ID } from './Dialog'
import { AudioPlayerContext } from './AudioPlayer'
import illustration from '@static/images/girlWithTable.png'
import {
    useLoginWithFacebookMutation,
    useLoginWithGoogleMutation,
    useLoginWithAppleMutation,
    useChaptersLazyQuery,
    useLogoutMutation,
    useLoginMutation,
    LoginMutation,
    useMeQuery,
    User,
    AuthUser,
    useMeLazyQuery,
    MeQuery,
    Role,
    MeDocument,
    BadgesDocument,
    ChaptersDocument,
    FinalTestDocument
} from '@graphql'

const queriesToRefetch = [
    { query: MeDocument },
    { query: FinalTestDocument },
    { query: ChaptersDocument },
    { query: BadgesDocument }
]

type LoginResponse = Promise<{
    data: LoginMutation | undefined
    errors: GraphQLError[] | undefined
}>

export type ContextUser =
    | null
    | undefined
    | Partial<Omit<User & AuthUser & MeQuery['me'], '__typename'>>

/**
 * Props and State definitions
 */
type Props = {
    children: React.ReactNode
    user?: ContextUser | null
}

type State = {
    logoutLoading: boolean
    loading: boolean
    user: ContextUser
}

type Context = {
    isLoggingIn: boolean
    isAuthorized: boolean
    logoutLoading: boolean
    loading: boolean
    user: ContextUser
    logout: () => any
    loginWithGoogle: () => any
    refetchProfileData: () => any
    loginWithFacebook: (data: any) => any
    updateUser: (data: ContextUser) => any
    login: (email: string, password: string, recaptchaToken: string) => LoginResponse
}

/**
 * AuthContext
 */
export const AuthContext = React.createContext<Context>({
    user: undefined,
    loading: true,
    isLoggingIn: false,
    isAuthorized: false,
    logoutLoading: false,
    logout: async () => {},
    loginWithGoogle: async () => {},
    loginWithFacebook: async () => {},
    refetchProfileData: async () => {},
    updateUser: (newUser: ContextUser) => {},
    // @ts-ignore
    login: async (email: string, password: string, token: string) => {}
})

const AuthProvider = ({ children, user }: Props) => {
    const history = useHistory()
    const { hash } = useLocation()
    const { enqueueSnackbar } = useSnackbar()

    const deviceId = `OS: ${osName} - ${osVersion}, BROWSER: ${browserName} - ${browserVersion}`

    const { refetch: refetchMe, data } = useMeQuery({
        fetchPolicy: 'cache-and-network',
        onCompleted: async () => {
            setState({ loading: false })
        }
    })

    const [state, setState] = useSetState<State>({
        user: user,
        logoutLoading: false,
        loading: !user && !data?.me
    })

    const { stop: stopAudio } = useContext(AudioPlayerContext)
    const { setOpen, setInfoDialogData } = useContext(DialogContext)

    const [refecthChapters] = useChaptersLazyQuery({ fetchPolicy: 'network-only' })

    const [loginMutation, { loading: isLoggingIn }] = useLoginMutation({
        // @ts-ignore
        refetchQueries: (data) => {
            if (data?.data) {
                return queriesToRefetch
            }
            return undefined
        }
    })

    const [
        loginWithFacebookMutation,
        { loading: isLoggingInWithFB }
    ] = useLoginWithFacebookMutation({
        // @ts-ignore
        refetchQueries: (data) => {
            if (data?.data) {
                return queriesToRefetch
            }
            return undefined
        }
    })

    const [
        loginWithGoogleMutation,
        { loading: isLoggingInWithGoogle }
    ] = useLoginWithGoogleMutation({
        // @ts-ignore
        refetchQueries: (data) => {
            if (data?.data) {
                return queriesToRefetch
            }
            return undefined
        }
    })

    const [loginWithAppleMutation, { loading: isLoggingInWithApple }] = useLoginWithAppleMutation({
        // @ts-ignore
        refetchQueries: (data) => {
            if (data?.data) {
                return queriesToRefetch
            }
            return undefined
        }
    })

    const [logoutMutation] = useLogoutMutation()

    // Handle logout in multiple tabs
    useEffect(() => {
        const handleInvalidToken = (e: any) => {
            if (e.key === 'isAuth' && e.oldValue && !e.newValue) {
                onLogout()
            }
        }
        window.addEventListener('storage', handleInvalidToken)
        return function cleanup() {
            window.removeEventListener('storage', handleInvalidToken)
        }
    }, [])

    useEffect(() => {
        // @ts-ignore
        if (data?.me) updateUser(data.me)
    }, [data])

    // Handle sign-in with apple ID
    useEffect(() => {
        if (hash && hash.includes('#code=')) {
            const code = last(hash.split('#code='))
            if (code) loginWithApple(code)
        }
    }, [hash])

    const onLogout = async () => {
        localStorage.removeItem('isAuth')
        setState({ user: null })
        refecthChapters()
        stopAudio()

        enqueueSnackbar(notificationMsgs.logout, { variant: 'success' })

        setTimeout(() => {
            history.push({ pathname: Routes.LP, search: `logout` })
        }, 0)
    }

    const checkUserAndPushToDashboard = async (user: ContextUser) => {
        if (!user) return

        updateUser(user)
        localStorage.setItem('isAuth', 'true')

        if (user.role === Role.SUPER_ADMIN || user.role === Role.ADMIN) {
            history.push(Routes.DASHBOARD)
        } else {
            history.push(Routes.HOME)
        }

        if (user?.isReactivated) {
            setInfoDialogData({
                title: loco.dialogs.reactivated.title,
                subtitle: loco.dialogs.reactivated.subtitle,
                icon: <Icon style={{ width: 95, height: 'auto' }} />,
                buttonProps: {
                    text: loco.common.continue,
                    onClick: () => setOpen(DIALOG_ID.NOT_DISPLAYED)
                }
            })
            setOpen(DIALOG_ID.INFO)
            return
        }

        if (user?.lastLogin) {
            return setOpen(DIALOG_ID.NOT_DISPLAYED)
        }

        setInfoDialogData({
            icon: illustration,
            title: loco.dialogs.confirm.title,
            subtitle: loco.dialogs.confirm.subtitle,
            buttonProps: {
                text: loco.dialogs.confirm.button,
                onClick: () => {
                    history.push(Routes.QUESTIONNAIRE_EXPERIENCE)
                    setOpen(DIALOG_ID.NOT_DISPLAYED)
                }
            }
        })
        setOpen(DIALOG_ID.INFO)
    }

    const logout = async () => {
        setState({ logoutLoading: true })

        const { data, errors } = await logoutMutation()

        if (data && !errors) {
            await onLogout()
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }

        setState({ logoutLoading: false })
    }

    const updateUser = (newUser?: ContextUser) => {
        if (!newUser) return
        if (!state.user) setState({ user: newUser })
        else setState({ user: merge(state.user, newUser) })
    }

    /**
     * Native login
     * @param username
     * @param password
     * @param token
     */
    const login = async (
        id: string,
        password: string,
        token: string
    ): Promise<{
        data: LoginMutation | undefined
        errors: GraphQLError[] | undefined
    }> => {
        const { data, errors } = await loginMutation({
            variables: {
                data: {
                    password: password,
                    username: id,
                    deviceId
                }
            },
            context: {
                headers: {
                    'X-Recaptcha-Token': token
                }
            }
        })

        if (data && !errors) {
            await checkUserAndPushToDashboard(data.login)
        }

        return { data, errors }
    }

    const loginWithApple = async (code: string) => {
        const { data, errors } = await loginWithAppleMutation({
            variables: {
                data: {
                    authorizationCode: code,
                    isMobile: false,
                    deviceId
                }
            }
        })

        if (data?.loginWithApple && !errors) {
            await checkUserAndPushToDashboard(data.loginWithApple)
        }
        if (errors) {
            console.log(errors)
            errors.forEach((err) => {
                enqueueSnackbar(err.message, { variant: 'error' })
            })
        }
    }

    const loginWithFacebook = async ({ accessToken }: { accessToken: string }) => {
        const { errors, data: fbData } = await loginWithFacebookMutation({
            variables: { data: { accessToken: accessToken, deviceId } }
        })

        if (fbData && !errors) {
            await checkUserAndPushToDashboard(fbData.loginWithFacebook)
        }

        errors?.forEach((err) =>
            enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
        )
    }

    // https://github.com/google/google-api-javascript-client/issues/397
    // https://github.com/AurityLab/recaptcha-v3/issues/112
    const loginWithGoogle = async () => {
        const clientId = process.env.RAZZLE_GOOGLE_CLIENT_ID
        const clientSecret = process.env.RAZZLE_GOOGLE_CLIENT_SECRET
        const redirectUri = `https://${process.env.RAZZLE_HOST}`
        // @ts-ignore
        const gapi = window.gapi
        // @ts-ignore
        gapi.load('auth2', async () => {
            // @ts-ignore
            const auth2 = gapi.auth2.init({
                // eslint-disable-next-line @typescript-eslint/camelcase
                client_id: clientId
            })

            // Exchange code for access token and ID token
            // https://developers.google.com/identity/protocols/OpenIDConnect#exchangecode
            const res = await auth2.grantOfflineAccess()

            const URL = 'https://oauth2.googleapis.com/token'
            const body = `code=${res.code}&client_id=${clientId}&client_secret=${clientSecret}&redirect_uri=${redirectUri}&grant_type=authorization_code`

            let tokenRes
            try {
                tokenRes = await axios.post(URL, body, {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                })
            } catch (error) {
                console.error(error.toJSON())
            }

            if (!tokenRes) return

            const { errors, data } = await loginWithGoogleMutation({
                variables: { data: { idToken: tokenRes.data.id_token, deviceId } }
            })

            if (data && !errors) {
                await checkUserAndPushToDashboard(data.loginWithGoogle)
            }

            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        })
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                login,
                logout,
                loginWithGoogle,
                updateUser,
                loginWithFacebook,
                isAuthorized: !!state.user,
                refetchProfileData: refetchMe,
                isLoggingIn:
                    isLoggingIn ||
                    isLoggingInWithFB ||
                    isLoggingInWithGoogle ||
                    isLoggingInWithApple
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
