import 'date-fns'
import { Location } from 'history'
import { useSnackbar } from 'notistack'
import { Link } from 'react-router-dom'
import { string, object, date } from 'yup'
import { zonedTimeToUtc } from 'date-fns-tz'
import Button from '@material-ui/core/Button'
import { setHours, setMinutes } from 'date-fns'
import { DatePicker } from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField'
import { useToggle, useBeforeUnload } from 'react-use'
import { useHistory, useLocation } from 'react-router-dom'
import React, { useState, useContext, ChangeEvent } from 'react'
import { Formik, Field, FieldProps, FormikProps, FormikHelpers } from 'formik'

import * as loco from '@loco'
import Head from '../../../../../components/Head'
import { Hours, Repeat, Minutes, mapByRepeat } from '..'
import { ButtonWrapper, SelectWrapper } from '../styled'
import Select from '../../../../../components/Fields/Select'
import InputField from '../../../../../components/Fields/Input'
import { AuthContext } from '../../../../../context/Auth'
import BackButton from '../../../../../components/shared/BackButton'
import { Routes, notificationMsgs, timeZone } from '@variables'
import { Role, useCreateCustomNotificationMutation } from '@graphql'
import RouteLeavingGuard from '../../../../../components/RouteLeavingGuard'
import { SmallContainer, Container, Form, Box } from '../../../../../components/shared/styled'

type FormValues = {
    title: string
    body?: string
    date: Date
    hours: string
    repeat: Repeat
    minutes: string
}

const CreateNotification = () => {
    const { user, loading: authLoading } = useContext(AuthContext)
    const { enqueueSnackbar } = useSnackbar()

    const history = useHistory()
    const location = useLocation()

    const [dirty, toggleDirty] = useToggle(false)
    // On close or reload
    useBeforeUnload(dirty, loco.admin.popup.leave.title)

    const [selectedDate, setSelectedDate] = useState<Date>(new Date())

    const [createNotification, { loading }] = useCreateCustomNotificationMutation()

    const onChange = () => {
        if (!dirty) toggleDirty()
    }

    const onDateChange = (date: Date | null) => {
        if (!date) return

        onChange()
        setSelectedDate(date)
    }

    const submit = async (values: FormValues, { resetForm }: FormikHelpers<any>) => {
        const date: Date = setMinutes(
            setHours(selectedDate, Number(values.hours)),
            Number(values.minutes)
        )

        const { data, errors } = await createNotification({
            variables: {
                data: {
                    runAt: zonedTimeToUtc(date, timeZone),
                    repeat: mapByRepeat(values.repeat),
                    title: values.title,
                    body: values.body
                }
            }
        })

        if (data && !errors) {
            toggleDirty()
            resetForm()
            enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
            history.push({
                pathname: Routes.DASHBOARD,
                search: Routes.NOTIFICATIONS_SEARCH_PARAM
            })
        } else {
            errors?.forEach((err) => err.message, { variant: 'error' })
        }
    }

    return (
        <>
            <Head title={loco.seo.notifications.create.title} />

            <RouteLeavingGuard
                when={dirty}
                title={loco.admin.popup.leave.title}
                btnText={loco.admin.popup.leave.confirm}
                description={loco.admin.popup.leave.subtitle}
                navigate={(path: string) => history.push(path)}
                shouldBlockNavigation={(newLocation: Location<any>) => {
                    if (dirty) {
                        if (location.pathname !== newLocation.pathname) {
                            return true
                        }
                    }
                    return false
                }}
            />

            <Container>
                <SmallContainer>
                    <BackButton
                        position="relative"
                        onClick={() => history.push(Routes.DASHBOARD)}
                        style={{
                            fontSize: 18,
                            fontWeight: 400,
                            margin: '40px 0 20px 0'
                        }}
                    >
                        {loco.admin.create.notification.title.name}
                    </BackButton>

                    <Box>
                        <Formik
                            onSubmit={submit}
                            isInitialValid={false}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                        >
                            {({ isValid, isSubmitting }: FormikProps<FormValues>) => (
                                <Form onChange={onChange} spellCheck={false}>
                                    <Field
                                        fullWidth
                                        key="title"
                                        name="title"
                                        component={InputField}
                                        className="customInput"
                                        disabled={isSubmitting || loading}
                                        label={loco.admin.create.notification.title.label}
                                        placeholder={
                                            loco.admin.create.notification.title.placeholder
                                        }
                                    />
                                    <Field
                                        fullWidth
                                        key="body"
                                        name="body"
                                        component={InputField}
                                        className="customInput"
                                        disabled={isSubmitting || loading}
                                        label={loco.admin.create.notification.text.label}
                                        placeholder={
                                            loco.admin.create.notification.text.placeholder
                                        }
                                    />

                                    <SelectWrapper>
                                        <DatePicker
                                            disablePast
                                            allowKeyboardControl
                                            showToolbar={false}
                                            inputFormat="d MMM yyyy"
                                            value={selectedDate}
                                            onChange={onDateChange}
                                            label={loco.admin.create.notification.date}
                                            disabled={isSubmitting || loading}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    name="date"
                                                    helperText=""
                                                    style={{
                                                        width: '185px !important',
                                                        marginRight: 25
                                                    }}
                                                />
                                            )}
                                        />

                                        <Field
                                            fullWidth
                                            key="hours"
                                            name="hours"
                                            className="customInput"
                                        >
                                            {({ field }: FieldProps) => (
                                                <Select
                                                    disabled={isSubmitting || loading}
                                                    style={{ width: 100, marginRight: 25 }}
                                                    label={loco.admin.create.notification.hours}
                                                    options={[
                                                        ...Object.keys(Hours).map((i) => Hours[i])
                                                    ]}
                                                    {...field}
                                                    onChange={(e: ChangeEvent<{}>) => {
                                                        onChange()
                                                        field.onChange(e)
                                                    }}
                                                />
                                            )}
                                        </Field>

                                        <Field
                                            fullWidth
                                            key="minutes"
                                            name="minutes"
                                            className="customInput"
                                        >
                                            {({ field }: FieldProps) => (
                                                <Select
                                                    disabled={isSubmitting || loading}
                                                    style={{ width: 100, marginRight: 25 }}
                                                    label={loco.admin.create.notification.minutes}
                                                    options={[
                                                        ...Object.keys(Minutes).map(
                                                            (i) => Minutes[i]
                                                        )
                                                    ]}
                                                    {...field}
                                                    onChange={(e: ChangeEvent<{}>) => {
                                                        onChange()
                                                        field.onChange(e)
                                                    }}
                                                />
                                            )}
                                        </Field>

                                        <Field
                                            fullWidth
                                            key="repeat"
                                            name="repeat"
                                            className="customInput"
                                        >
                                            {({ field }: FieldProps) => (
                                                <Select
                                                    style={{ width: 185 }}
                                                    disabled={isSubmitting || loading}
                                                    label={
                                                        loco.admin.create.notification.repeat.repeat
                                                    }
                                                    options={[
                                                        ...Object.keys(Repeat).map((k) => Repeat[k])
                                                    ]}
                                                    {...field}
                                                    onChange={(e: ChangeEvent<{}>) => {
                                                        onChange()
                                                        field.onChange(e)
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </SelectWrapper>

                                    <ButtonWrapper>
                                        <Link to={Routes.DASHBOARD}>
                                            <Button
                                                size="large"
                                                type="button"
                                                color="secondary"
                                                variant="outlined"
                                                disabled={isSubmitting || loading}
                                            >
                                                {loco.common.cancel}
                                            </Button>
                                        </Link>
                                        <Button
                                            size="large"
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            style={{ marginLeft: 30 }}
                                            disabled={!isValid || isSubmitting || loading}
                                        >
                                            {user?.role === Role.SUPER_ADMIN
                                                ? loco.common.create
                                                : loco.common.sendToReview}
                                        </Button>
                                    </ButtonWrapper>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </SmallContainer>
            </Container>
        </>
    )
}

const initialValues = {
    title: '',
    hours: '14',
    minutes: '15',
    date: new Date(),
    repeat: Repeat.NOT_REPEAT
}

const validationSchema = object({
    body: string(),
    date: date().required(loco.validation.common.required),
    title: string().required(loco.validation.common.required),
    hours: string().required(loco.validation.common.required),
    repeat: string().required(loco.validation.common.required),
    minutes: string().required(loco.validation.common.required)
})

export default CreateNotification
