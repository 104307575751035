import { Location } from 'history'
import { useSetState } from 'react-use'
import React, { useEffect } from 'react'
import { Prompt } from 'react-router-dom'

import InfoDialog from '../Dialogs/Info'
import icon from '@static/images/infoChar.png'

type Props = {
    title: string
    description: string
    btnText: string
    when: boolean
    navigate: (path: string) => void
    shouldBlockNavigation: (location: Location<any>) => boolean
}

type State = {
    isLogout: boolean
    modalVisible: boolean
    confirmedNavigation: boolean
    lastLocation: Location<any> | null
}

// react-router Prompt with custom modal component
const RouteLeavingGuard = ({
    when,
    title,
    btnText,
    navigate,
    description,
    shouldBlockNavigation
}: Props) => {
    const [state, setState] = useSetState<State>({
        isLogout: false,
        modalVisible: false,
        lastLocation: null,
        confirmedNavigation: false
    })

    const { modalVisible, lastLocation, confirmedNavigation } = state

    useEffect(() => {
        if (state.confirmedNavigation && lastLocation) {
            navigate(lastLocation.pathname)
        }
    }, [state.confirmedNavigation, lastLocation])

    useEffect(() => {
        const isLogout = lastLocation?.search.includes('logout')
        setState({ isLogout: isLogout, confirmedNavigation: isLogout })

        if (lastLocation && isLogout) {
            return navigate(lastLocation.pathname)
        }
    }, [state.lastLocation])

    const showModal = (location: Location<any>) => {
        setState({
            modalVisible: true,
            lastLocation: location
        })
    }

    // Will be called with the next location the user is attempting to navigate to.
    // Return a string to show a prompt to the user or true to allow the transition.
    const handleBlockedNavigation = (nextLocation: Location<any>) => {
        // I don't return a string beacause I don't want to show the default browser pop-up(prompt).
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation) && !state.isLogout) {
            showModal(nextLocation)
            return false
        }

        return true
    }

    const handleConfirmNavigationClick = () => {
        setState({ modalVisible: !state.modalVisible })

        if (lastLocation) {
            // After that navigate to the previous blocked location with the help of useEffect.
            setState({ confirmedNavigation: true })
        }
    }

    return (
        <>
            <Prompt when={when && !state.isLogout} message={handleBlockedNavigation} />

            <InfoDialog
                isOpen={modalVisible}
                toggleOpen={() => setState({ modalVisible: !modalVisible })}
                buttonProps={{
                    text: btnText,
                    onClick: handleConfirmNavigationClick
                }}
                data={[
                    {
                        icon: icon,
                        title: title,
                        description: description
                    }
                ]}
            />
        </>
    )
}

export default RouteLeavingGuard
