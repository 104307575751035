import { Location } from 'history'
import { useSnackbar } from 'notistack'
import React, { FC, useEffect, useContext } from 'react'
import { Formik, FormikProps, FormikHelpers } from 'formik'
import { useSetState, useBeforeUnload, useToggle } from 'react-use'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { Step, StepLabel, StepConnector, Typography } from '@material-ui/core'

import * as loco from '@loco'
import Head from '../../../components/Head'
import { getRouteWithId } from '@utils'
import TestForm from '../../../components/Test'
import { colors, Routes } from '@variables'
import { translateGQLError } from '@utils'
import Loader from '../../../components/shared/Loader'
import InfoDialog from '../../../components/Dialogs/Info'
import { AuthContext } from '../../../context/Auth'
import { BadgeContext } from '../../../context/Badge'
import BackButton from '../../../components/shared/BackButton'
import TipCharacter from '../../../components/SVG/TipCharacter'
import RouteLeavingGuard from '../../../components/RouteLeavingGuard'
import { AudioPlayerContext } from '../../../context/AudioPlayer'
import { Container, Stepper, Section, Span, MobileStepper } from './styled'
import StepLabelActiveIcon from '../../../components/SVG/StepLabelActiveIcon'
import StepLabelCompletedIcon from '../../../components/SVG/StepLabelCompletedIcon'
import {
    useRegisterTemporaryStudentMutation,
    useCreateTestSessionMutation,
    useCurrentTestStepQuery,
    useNextTestStepMutation,
    useSubmitAnswerMutation,
    DailyQuestionDocument,
    FinalTestDocument,
    ChaptersDocument,
    useFinalTestQuery,
    StepFragment,
    MeDocument,
    TestStatus
} from '@graphql'

type State = Omit<typeof initialState, 'testStep'> & { testStep: StepFragment | null }

const initialState = Object.freeze({
    isOpen: false,
    testStep: null,
    showLast: false
})

const Test: FC = () => {
    const history = useHistory()
    const location = useLocation()
    const { enqueueSnackbar } = useSnackbar()
    const { id: testId } = useParams<{ id: string }>()

    const { isAuthorized, user } = useContext(AuthContext)
    const { playing, stop } = useContext(AudioPlayerContext)
    const { refetch: getUnaccepterBadges } = useContext(BadgeContext)

    const isUserNotAuthOrTemp = !isAuthorized || Boolean(user?.isTemporary)

    const [dirty, toggleDirty] = useToggle(false)
    // On close or reload
    useBeforeUnload(dirty, loco.test.notification)

    const [state, setState] = useSetState<State>(initialState)

    const { data } = useFinalTestQuery({ skip: isUserNotAuthOrTemp, fetchPolicy: 'no-cache' })

    const { loading: currentStepLoading } = useCurrentTestStepQuery({
        variables: {
            session: {
                id: state.testStep?.session?.id || ''
            }
        },
        skip:
            !Boolean(state.testStep?.session) ||
            state.testStep?.session?.testStatus !== TestStatus.STARTED,
        onCompleted: async ({ currentTestStep }) => {
            setState({ testStep: currentTestStep })
        },
        onError: (err) => enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
    })

    const [nextTestStep, { loading: nextStepLoading }] = useNextTestStepMutation()
    const [submitAnswer, { loading: submitAnswerLoading }] = useSubmitAnswerMutation()
    const [
        createTestSession,
        { loading: createTestSessionLoading }
    ] = useCreateTestSessionMutation()
    const [
        registerTemporaryStudent,
        { loading: registerTempStudentLoading }
    ] = useRegisterTemporaryStudentMutation({
        awaitRefetchQueries: true,
        refetchQueries: [{ query: MeDocument }]
    })

    const registerTempUser = async () => {
        const { errors, data } = await registerTemporaryStudent()

        if (data && !errors) {
            await createNewTestSession()
        }
        errors?.forEach((err) => {
            enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
        })
    }

    const createNewTestSession = async () => {
        const { data, errors } = await createTestSession({
            variables: {
                data: {
                    test: {
                        id: testId
                    }
                }
            }
        })

        if (data && !errors) {
            const { data: nxtStpData, errors: nxtStpErrors } = await nextTestStep({
                variables: {
                    data: {
                        session: {
                            id: data.createTestSession.id
                        }
                    }
                }
            })

            if (!nxtStpErrors && nxtStpData) {
                const { nextTestStep } = nxtStpData
                setState({ showLast: false, testStep: nextTestStep })
            } else {
                nxtStpErrors?.forEach((err) => {
                    enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
                })
            }
        } else {
            if (!isAuthorized) {
                await registerTempUser()
            }
        }
    }

    useEffect(() => {
        if (playing) stop()
        createNewTestSession()
    }, [])

    if (registerTempStudentLoading || !state.testStep?.session || !state.testStep?.id) {
        return <Loader halfHeight />
    }

    const { testStep, showLast } = state

    const session = testStep.session
    const test = session?.test
    const lecture = test?.lecture

    const isTempUser = Boolean(user?.isTemporary)

    // If nextLecture === null then nextTest will be chapterSummary
    const nextLecture = session?.nextLecture
    const isLastStep = testStep.step === test?.stepsTotal

    const isThereNextLecture = Boolean(nextLecture)
    const summaryTest = session?.test.lecture?.chapter.summaryTest
    const isChapterSummary = Boolean(session?.test.isChapterSummary)

    const isFinalTest = Boolean(session?.test.isFinalTest)
    const isFinalTestCompleted = Boolean(user?.student?.finalTestStatus.isCompleted)
    const finalTestId = data?.finalTest?.id

    const disallowGoToSummaryTest =
        !Boolean(summaryTest) && !isThereNextLecture && !isFinalTest && !isChapterSummary
    const disallowGoToFinalTest =
        !isFinalTestCompleted &&
        !isThereNextLecture &&
        isChapterSummary &&
        !isTempUser &&
        !finalTestId

    const submit = async ({ answer }: { answer?: string }, { resetForm }: FormikHelpers<any>) => {
        // Open dialog when can't go to next test: (When?)
        //  1. when the user did not attend previous lectures and try to go to
        //     the chapter test
        //  2. when the user did not attend previous chapters and try to go to
        //     the final test
        if (state.showLast && (disallowGoToSummaryTest || disallowGoToFinalTest)) {
            return setState({ isOpen: true })
        }

        // Create new session if user can go to the next test or want to repeat
        if (state.showLast) return await createNewTestSession()

        if (!state?.testStep?.answer) {
            if (!state.testStep?.id) return

            const { data, errors } = await submitAnswer({
                refetchQueries: getRefetchQuerires(isLastStep, isFinalTest, isChapterSummary),
                variables: {
                    data: {
                        answer: { id: answer },
                        step: { id: state.testStep.id }
                    }
                }
            })

            if (data && !errors) {
                const { submitAnswer } = data

                if (submitAnswer.step === 1 && !dirty) {
                    toggleDirty()
                }

                setState({ testStep: submitAnswer })

                if (isLastStep) {
                    toggleDirty()
                }
            } else {
                errors?.forEach((err: any) => {
                    enqueueSnackbar(translateGQLError(err?.message || ''), { variant: 'error' })
                })
            }
            return
        }

        // This actions fires when user click at button in Tip component
        resetForm()
        setState({ showLast: isLastStep })
        if (!state.showLast && isLastStep) await getUnaccepterBadges()

        if (isLastStep) return

        // Get the next step only if user not at the end of test
        const { data, errors } = await nextTestStep({
            variables: { data: { session: { id: state?.testStep?.session?.id } } }
        })

        if (data && !errors) {
            const { nextTestStep } = data
            setState({ testStep: nextTestStep })
        } else {
            errors?.forEach((err) => {
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            })
        }
    }

    // ========== UI HELPERS ==========>
    const renderStepLabel = (idx: number) => {
        const step = state.testStep?.step

        if (!step) {
            return (
                <StepLabel>
                    <Span>0{idx}</Span>
                </StepLabel>
            )
        }

        if (idx === step) {
            // active
            return (
                <StepLabel
                    icon={
                        <StepLabelActiveIcon
                            style={{ position: 'relative', top: '-1px', height: 14, width: 14 }}
                        />
                    }
                >
                    <Span>0{idx}</Span>
                </StepLabel>
            )
        } else if (idx < step) {
            // completed
            return (
                <StepLabel
                    icon={<StepLabelCompletedIcon style={{ position: 'relative', top: '2px' }} />}
                >
                    <Span>0{idx}</Span>
                </StepLabel>
            )
        } else {
            // disabled
            return (
                <StepLabel>
                    <Span>0{idx}</Span>
                </StepLabel>
            )
        }
    }

    const goBack = () => {
        if (isLoading) return

        if (Boolean(test?.isFinalTest)) return history.push(Routes.HOME)

        if (!Boolean(nextLecture) || test?.isChapterSummary) {
            const chapterId = test?.chapter?.id || ''
            return history.push(getRouteWithId(Routes.CHAPTER, chapterId))
        }

        if (history.length > 2) {
            history.goBack()
        } else {
            history.push(getRouteWithId(Routes.LECTURE, lecture?.id || ''))
        }
    }
    // ========== --------- ==========>

    const isLoading =
        nextStepLoading || currentStepLoading || submitAnswerLoading || createTestSessionLoading

    const title = lecture ? lecture.name : test?.chapter?.name || loco.profile.diplom['start-final']

    return (
        <>
            <Head title={`${loco.seo.test.title} ${title}`} />

            <RouteLeavingGuard
                when={dirty}
                title={loco.test.notification}
                btnText={loco.common.interrupt}
                description={loco.test.notificationDescription}
                navigate={(path: string) => history.push(path)}
                shouldBlockNavigation={(newLocation: Location<any>) => {
                    if (dirty) {
                        if (location.pathname !== newLocation.pathname) {
                            return true
                        }
                    }
                    return false
                }}
            />

            <InfoDialog
                isOpen={state.isOpen}
                toggleOpen={() => setState({ isOpen: !state.isOpen })}
                buttonProps={{
                    text: loco.dialogs.testLastStep.button,
                    onClick: () => setState({ isOpen: !state.isOpen })
                }}
                data={[
                    {
                        title: disallowGoToSummaryTest
                            ? loco.dialogs.testLastStep.lecture.title
                            : loco.dialogs.testLastStep.chapter.title,
                        description: disallowGoToSummaryTest
                            ? loco.dialogs.testLastStep.lecture.subtitle
                            : loco.dialogs.testLastStep.chapter.subtitle,
                        icon: (
                            <TipCharacter
                                style={{
                                    width: '100%',
                                    margin: '0 auto',
                                    display: 'block',
                                    height: 'auto'
                                }}
                            />
                        )
                    }
                ]}
            />

            <Container>
                <Section>
                    <BackButton onClick={goBack}>
                        {!Boolean(nextLecture) || test?.isChapterSummary
                            ? Boolean(test?.isFinalTest)
                                ? loco.common.back
                                : loco.common.backToChapter
                            : loco.common.backToLecture}
                    </BackButton>

                    {(test?.stepsTotal || 0) <= 8 ? (
                        <Stepper
                            alternativeLabel
                            activeStep={state.testStep.step - 1}
                            connector={
                                <StepConnector
                                    classes={{
                                        line: 'colorlibConnector-line',
                                        active: 'colorlibConnector-active',
                                        completed: 'colorlibConnector-completed',
                                        alternativeLabel: 'colorlibConnector-alternativeLabel'
                                    }}
                                />
                            }
                        >
                            {Array(test?.stepsTotal || 0)
                                .fill(0)
                                .map((item, i: number) => (
                                    <Step key={i}>{renderStepLabel(i + 1)}</Step>
                                ))}
                        </Stepper>
                    ) : (
                        <MobileStepper>
                            <Typography variant="body1" style={{ color: colors.primary.orange }}>
                                {state.testStep.step}
                            </Typography>{' '}
                            / <Typography variant="body1">{test?.stepsTotal || 0}</Typography>
                        </MobileStepper>
                    )}

                    <div>
                        <Formik onSubmit={submit} initialValues={{ answer: undefined }}>
                            {(props: FormikProps<{ answer: any }>) => (
                                <TestForm
                                    {...props}
                                    session={session}
                                    testStep={testStep}
                                    isLoading={isLoading}
                                    isLastStep={isLastStep}
                                    showLastStep={showLast}
                                    finalTestId={finalTestId}
                                    createNewTestSession={createNewTestSession}
                                    isNextTestChapterSum={!Boolean(nextLecture)}
                                />
                            )}
                        </Formik>
                    </div>
                </Section>
            </Container>
        </>
    )
}

const getRefetchQuerires = (
    isLastStep: boolean,
    isFinalTest: boolean,
    isChapterSummary: boolean
) => {
    if (!isLastStep) return undefined

    if (isFinalTest || isChapterSummary) {
        return [
            {
                query: DailyQuestionDocument
            },
            {
                query: MeDocument
            },
            {
                query: FinalTestDocument
            },
            {
                // Refetch all chapters here to update CONTINUE/START button at
                // home page
                query: ChaptersDocument
            }
        ]
    }

    return [
        {
            query: DailyQuestionDocument
        },
        {
            query: MeDocument
        },
        {
            query: ChaptersDocument
        }
    ]
}

export default Test
