import last from 'lodash/last'
import isNil from 'lodash/isNil'
import assign from 'lodash/assign'
import { Location } from 'history'
import { useSetState } from 'react-use'
import { useSnackbar } from 'notistack'
import { mixed, object, string } from 'yup'
import Button from '@material-ui/core/Button'
import React, { useContext, useEffect } from 'react'
import { Field, Formik, FormikHelpers } from 'formik'
import { useBeforeUnload, useToggle } from 'react-use'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'

import * as loco from '@loco'
import { translateGQLError } from '@utils'
import { notificationMsgs, Routes } from '@variables'

import { FormValues } from '..'
import Head from '../../../../../components/Head'
import { ButtonWrapper, UploadWrapper } from '../styled'
import Loader from '../../../../../components/shared/Loader'
import Tooltip from '../../../../../components/shared/Tooltip'
import InputField from '../../../../../components/Fields/Input'
import UploadField from '../../../../../components/Fields/Upload'
import { AuthContext } from '../../../../../context/Auth'
import BackButton from '../../../../../components/shared/BackButton'
import RouteLeavingGuard from '../../../../../components/RouteLeavingGuard'
import {
    Box,
    Form,
    Container,
    ToggleItem,
    ToggleWrapper,
    SmallContainer
} from '../../../../../components/shared/styled'
import {
    Role,
    CreationStatus,
    useAdminChapterQuery,
    AdminChaptersDocument,
    useUpdateChapterMutation,
    useCreateChapterUpdateDraftMutation,
    useUpdateChapterUpdateDraftMutation
} from '@graphql'

const EditChapter = () => {
    const history = useHistory()
    const location = useLocation()
    const { enqueueSnackbar } = useSnackbar()
    const params = useParams<{ readonly id: string }>()

    const { user } = useContext(AuthContext)

    const [state, setState] = useSetState<State>(initialState)

    const [updateChapter, { loading: updateLoading }] = useUpdateChapterMutation({
        refetchQueries: [{ query: AdminChaptersDocument }],
        onError: (err) => enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
    })
    const [createDraft, { loading: createDraftLoading }] = useCreateChapterUpdateDraftMutation({
        onError: (err) => enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
    })
    const [updateDraft, { loading: updateDraftLoading }] = useUpdateChapterUpdateDraftMutation({
        refetchQueries: [{ query: AdminChaptersDocument }],
        onError: (err) => enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
    })

    const isLoading = updateLoading || createDraftLoading || updateDraftLoading

    const { data, error, loading } = useAdminChapterQuery({
        variables: { where: { id: params.id } },
        skip: !params.id
    })

    const [dirty, toggleDirty] = useToggle(false)
    // On close or reload
    useBeforeUnload(dirty, loco.admin.popup.leave.title)

    const toggleContentType = () => {
        if (state.type === 'NORMAL') setState({ type: 'DRAFT' })
        else setState({ type: 'NORMAL' })
    }

    useEffect(() => {
        if (!data?.chapter) return

        const lastDraft = last(data?.chapter.drafts.filter((d) => !d.isMerged && !d.isRejected))

        if (state.type === 'DRAFT' && lastDraft?.id) {
            setState({
                data: {
                    name: lastDraft.name,
                    png: {
                        src: lastDraft.icon.png,
                        filename: 'png'
                    },
                    svg: {
                        src: lastDraft.icon.svg,
                        filename: 'svg'
                    },
                    description: lastDraft.description
                }
            })
        } else {
            setState({
                data: {
                    name: chapter.name,
                    png: {
                        src: chapter.icon.png,
                        filename: 'png'
                    },
                    svg: {
                        src: chapter.icon.svg,
                        filename: 'svg'
                    },
                    description: chapter.description
                }
            })
        }
    }, [data, state.type])

    if (loading) return <Loader />
    if (!data || error) {
        error && enqueueSnackbar(translateGQLError(error.message), { variant: 'error' })
        return null
    }

    const { chapter } = data

    const lastDraft = last(chapter.drafts.filter((d) => !d.isMerged && !d.isRejected))

    const isSameAuthor =
        (!lastDraft && chapter.status === CreationStatus.APPROVED) ||
        (chapter.author.id === user?.id && state.type === 'NORMAL') ||
        (lastDraft?.author.id === user?.id && state.type === 'DRAFT') ||
        user?.role === Role.SUPER_ADMIN

    const isUpdateDisabled =
        (user?.role === Role.ADMIN && state.type === 'NORMAL' && Boolean(lastDraft?.id)) ||
        chapter.status === CreationStatus.BLOCKED ||
        (user?.role === Role.ADMIN &&
            (chapter.status === CreationStatus.PENDING ||
                chapter.status === CreationStatus.REJECTED) &&
            chapter.author.id !== user?.id)

    const submit = async (values: FormValues, { resetForm }: FormikHelpers<any>) => {
        if (chapter.status === CreationStatus.BLOCKED) {
            loco.admin
            return enqueueSnackbar(loco.validation.common.blockedContent, { variant: 'info' })
        }

        if (!isSameAuthor) return

        // ====== helpers =====>
        const onSuccess = (data?: any | null): void => {
            if (isNil(data)) return

            toggleDirty()
            resetForm()
            enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
            history.push({
                pathname: Routes.DASHBOARD,
                search: Routes.CHAPTERS_SEARCH_PARAM
            })
        }
        // ====== helpers =====|

        const { name, description } = values
        const newData = {
            name: name,
            description: description
        }

        if (
            (!Boolean(values?.png?.gcsId) && Boolean(values?.svg?.gcsId)) ||
            (!Boolean(values?.svg?.gcsId) && Boolean(values?.png?.gcsId))
        ) {
            return enqueueSnackbar(loco.tooltips.chapterIcons, { variant: 'error' })
        }

        if (values.png.gcsId && values.svg.gcsId) {
            assign(newData, {
                icon: {
                    png: {
                        gcsId: values.png.gcsId
                    },
                    svg: {
                        gcsId: values.svg.gcsId
                    }
                }
            })
        }

        if (
            (user?.role === Role.SUPER_ADMIN && state.type === 'NORMAL') ||
            ((chapter.status === CreationStatus.PENDING ||
                chapter.status === CreationStatus.REJECTED) &&
                chapter.author.id === user?.id)
        ) {
            const { data } = await updateChapter({
                variables: {
                    data: { ...newData },
                    where: { id: chapter.id }
                }
            })

            return onSuccess(data)
        }

        // Create/update chapter draft
        if (
            chapter.status === CreationStatus.APPROVED &&
            (state.type === 'DRAFT' || !lastDraft?.id)
        ) {
            const newDrafts = chapter.drafts.filter((d) => !d.isMerged && !d.isRejected)

            if (newDrafts.length > 0) {
                const lastDraft = last(newDrafts)
                if (!lastDraft?.id) return

                const { data } = await updateDraft({
                    variables: {
                        data: { ...newData },
                        where: { id: lastDraft.id }
                    }
                })

                onSuccess(data)
            } else {
                const { data, errors } = await createDraft({
                    variables: { where: { id: chapter.id } }
                })

                if (data && !errors) {
                    const { data: res } = await updateDraft({
                        variables: {
                            data: { ...newData },
                            where: { id: data.createChapterUpdateDraft.id }
                        }
                    })
                    onSuccess(res)
                }
            }
        }
    }

    const onChange = () => {
        if (!dirty) toggleDirty()
    }

    return (
        <>
            <Head title={loco.seo.chapter.edit.title} />

            <Container>
                <SmallContainer>
                    <BackButton
                        position="relative"
                        style={{
                            margin: '40px 0 20px 0',
                            fontWeight: 400,
                            fontSize: 18
                        }}
                        onClick={() => {
                            history.push(Routes.DASHBOARD)
                        }}
                    >
                        {loco.admin.edit.chapter.title}
                    </BackButton>

                    <RouteLeavingGuard
                        when={dirty}
                        title={loco.admin.popup.leave.title}
                        btnText={loco.admin.popup.leave.confirm}
                        description={loco.admin.popup.leave.subtitle}
                        navigate={(path: string) => history.push(path)}
                        shouldBlockNavigation={(newLocation: Location<any>) => {
                            if (dirty) {
                                if (location.pathname !== newLocation.pathname) {
                                    return true
                                }
                            }
                            return false
                        }}
                    />

                    <Box>
                        {lastDraft && (
                            <ToggleWrapper>
                                <ToggleItem
                                    onClick={toggleContentType}
                                    isActive={state.type === 'NORMAL'}
                                >
                                    {loco.common.content.actual}
                                </ToggleItem>{' '}
                                /{' '}
                                <ToggleItem
                                    onClick={toggleContentType}
                                    isActive={state.type === 'DRAFT'}
                                >
                                    {loco.common.content.draft}
                                </ToggleItem>
                            </ToggleWrapper>
                        )}

                        <Formik
                            onSubmit={submit}
                            enableReinitialize
                            isInitialValid={false}
                            initialValues={state.data}
                            validationSchema={validationSchema}
                        >
                            {({ values, isValid, isSubmitting }) => {
                                const { name, description } = values

                                return (
                                    <Form spellCheck={false} onChange={onChange}>
                                        <Field
                                            fullWidth
                                            key="name"
                                            name="name"
                                            value={name}
                                            component={InputField}
                                            className="customInput"
                                            label={loco.admin.create.chapter.name.label}
                                            disabled={
                                                isLoading ||
                                                isSubmitting ||
                                                !isSameAuthor ||
                                                isUpdateDisabled
                                            }
                                            placeholder={loco.admin.create.chapter.name.placeholder}
                                        />
                                        <Field
                                            fullWidth
                                            multiline
                                            key="description"
                                            name="description"
                                            value={description}
                                            component={InputField}
                                            className="customInput"
                                            disabled={
                                                isLoading ||
                                                isSubmitting ||
                                                !isSameAuthor ||
                                                isUpdateDisabled
                                            }
                                            label={loco.admin.create.chapter.description.label}
                                            placeholder={
                                                loco.admin.create.chapter.description.placeholder
                                            }
                                        />

                                        <UploadWrapper>
                                            <UploadField
                                                name="png"
                                                disabled={
                                                    isLoading ||
                                                    isSubmitting ||
                                                    !isSameAuthor ||
                                                    isUpdateDisabled
                                                }
                                                previewType="img"
                                                label={loco.admin.create.chapter.icon.png}
                                                style={{ width: 170 }}
                                                buttonText="Nahrát"
                                                accept="image/png"
                                            />

                                            <UploadField
                                                name="svg"
                                                disabled={
                                                    isLoading ||
                                                    isSubmitting ||
                                                    !isSameAuthor ||
                                                    isUpdateDisabled
                                                }
                                                previewType="img"
                                                buttonText={loco.admin.create.chapter.upload}
                                                label={loco.admin.create.chapter.icon.svg}
                                                style={{ width: 170 }}
                                                accept="image/svg+xml"
                                            />
                                        </UploadWrapper>

                                        <ButtonWrapper>
                                            <Tooltip
                                                withHelpIcon
                                                title={
                                                    isSameAuthor
                                                        ? loco.tooltips.chapterIcons
                                                        : loco.tooltips.sameAuthorDraft
                                                }
                                            />

                                            <div style={{ marginLeft: 'auto' }}>
                                                <Link
                                                    to={Routes.DASHBOARD}
                                                    className={
                                                        loading ||
                                                        isSubmitting ||
                                                        !isSameAuthor ||
                                                        isUpdateDisabled
                                                            ? 'disabledLink'
                                                            : ''
                                                    }
                                                >
                                                    <Button
                                                        size="large"
                                                        type="button"
                                                        color="secondary"
                                                        variant="outlined"
                                                        disabled={
                                                            isLoading ||
                                                            isSubmitting ||
                                                            !isSameAuthor ||
                                                            isUpdateDisabled
                                                        }
                                                    >
                                                        {loco.common.cancel}
                                                    </Button>
                                                </Link>
                                                <Button
                                                    size="large"
                                                    type="submit"
                                                    color="primary"
                                                    variant="contained"
                                                    style={{ marginLeft: 30 }}
                                                    disabled={
                                                        !isValid ||
                                                        isLoading ||
                                                        isSubmitting ||
                                                        !isSameAuthor ||
                                                        isUpdateDisabled
                                                    }
                                                >
                                                    {user?.role === Role.SUPER_ADMIN
                                                        ? loco.common.save
                                                        : loco.common.sendToReview}
                                                </Button>
                                            </div>
                                        </ButtonWrapper>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Box>
                </SmallContainer>
            </Container>
        </>
    )
}

type State = {
    data: {
        name: string
        png: {
            src?: string | null
            filename: string
        }
        svg: {
            src?: string | null
            filename: string
        }
        description: string
    }
    type: 'NORMAL' | 'DRAFT'
}

const initialState: State = {
    data: {
        name: '',
        png: {
            src: null,
            filename: ''
        },
        svg: {
            src: null,
            filename: ''
        },
        description: ''
    },
    type: 'NORMAL'
}

const validationSchema = object({
    name: string().required(loco.validation.common.required),
    description: string().required(loco.validation.common.required),
    png: mixed(),
    svg: mixed()
})

export default EditChapter
