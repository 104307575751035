import * as loco from '@loco'
import { truncateText } from '@utils'
import { LinearProgress } from '@material-ui/core'
import { useField, useFormikContext } from 'formik'
import React, { CSSProperties, useEffect, useRef } from 'react'

import { useUpload, Options } from './hooks'
import { ErrorMsg, Label, PreviewLink } from '../../shared/styled'
import { Button, CloseRounded, IconPreview, Wrapper } from './styled'

type Props = {
    name: string
    label: string
    accept: string
    className?: string
    uploadPrefix?: Options['prefix']
    buttonText: string
    disabled?: boolean
    previewType?: 'img' | 'none'
    style?: CSSProperties
}

type FieldValue = {
    filename?: string
    gcsId?: string
    file?: File
    src?: string
}

const Upload = ({
    style,
    name,
    label,
    accept,
    uploadPrefix = 'media/',
    disabled,
    className,
    previewType,
    buttonText = loco.admin.create.chapter.upload
}: Props) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [field, { error, value }] = useField<FieldValue>(name)
    const { setFieldValue } = useFormikContext()

    const [uploadFile, { progress, cancel, reset }] = useUpload({
        prefix: uploadPrefix,
        onComplete: async ({ file, data }) => {
            setFieldValue(field.name, {
                filename: file.name,
                size: file.size,
                gcsId: data.id,
                src: data.src
            })
        }
    })

    useEffect(() => {
        if (error) {
            console.log('error', error)
            cancel()
        }
    }, [error])

    const onClick = () => {
        inputRef?.current?.click()
    }

    const changeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.currentTarget.files
        if (!files) return

        const file = files[0]
        if (!file) return

        setFieldValue(field.name, {
            filename: file.name,
            size: file.size,
            src: undefined
        })

        uploadFile(file)
    }

    const removeFile = (
        event?: React.MouseEvent<HTMLElement | SVGSVGElement, MouseEvent>
    ): void => {
        event?.preventDefault()
        if (Boolean(disabled)) return

        reset()

        setFieldValue(field.name, {
            filename: undefined,
            size: undefined,
            src: undefined
        })
    }

    /**
     * Clear target.value on each click to fix
     * onChange event when the user select the same file
     */
    const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        const element = event.target as HTMLInputElement
        element.value = ''
    }

    const buttonProps = {
        fullWidth: true,
        size: 'large',
        type: 'button',
        color: 'primary',
        variant: 'outlined',
        onClick,
        disabled: Boolean(disabled) || !!progress
    } as any

    const previewLinkStyle = field.value
        ? {}
        : {
              width: 0,
              height: 12,
              display: 'block'
          }

    return (
        <Wrapper className={className || ''} style={style || {}} isLoading={!!progress}>
            {label && <Label>{label}</Label>}

            <input
                type="file"
                id={field.name}
                accept={accept}
                onChange={changeFile}
                onClick={onInputClick}
                style={{ display: 'none' }}
                disabled={Boolean(disabled) || !!progress}
                ref={inputRef}
            />

            {field?.value?.filename && previewType === 'img' && (
                <div className={className || ''} style={style || {}}>
                    <IconPreview>
                        <CloseRounded onClick={removeFile} />
                        {progress
                            ? progress + '%'
                            : field.value.src && (
                                  <img src={field.value.src} alt="Preview" loading="lazy" />
                              )}
                    </IconPreview>
                    <ProgressBar progress={progress} />
                    {error && <ErrorMsg>{error}</ErrorMsg>}
                </div>
            )}

            {field?.value?.filename && previewType !== 'img' && (
                <>
                    <Button
                        {...buttonProps}
                        className={Boolean(value?.src) ? 'uploadButtonToRemoveFile' : ''}
                        onClick={(event) => {
                            if (value?.src) {
                                removeFile(event)
                            } else {
                                buttonProps.onClick()
                            }
                        }}
                    >
                        {progress ? progress + '%' : truncateText(field.value.filename, 20)}
                    </Button>
                    <ProgressBar progress={progress} />
                    {!error && !progress && (
                        <PreviewLink
                            target="_blank"
                            href={field.value.src}
                            style={previewLinkStyle}
                        >
                            {field.value.src ? loco.common.openInNewTab : ''}
                        </PreviewLink>
                    )}
                    {/* <div style={{ width: 0, height: 20, display: 'block' }} /> */}
                    {error && <ErrorMsg>{error}</ErrorMsg>}
                </>
            )}

            {!field?.value?.filename && (
                <>
                    <Button {...buttonProps}>{progress ? progress + '%' : buttonText}</Button>
                    <ProgressBar progress={progress} />
                    <div style={{ width: 0, height: 20, display: 'block' }} />
                </>
            )}
        </Wrapper>
    )
}

const ProgressBar = ({ progress }: { progress?: number }) => {
    if (!progress) {
        return null
    }
    return (
        <LinearProgress
            style={{ marginTop: -4, borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }}
            variant="determinate"
            value={progress}
        />
    )
}

export default Upload
