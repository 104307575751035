import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
            {...props}
        >
            <circle cx="16" cy="16" r="16" fill="#00AFEC" opacity="0.1"></circle>
            <path
                fillRule="evenodd"
                stroke="#00AFEC"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.077 11.5a1.5 1.5 0 011.5-1.5h10.846a1.5 1.5 0 011.5 1.5v9.923a1.5 1.5 0 01-1.5 1.5H10.577a1.5 1.5 0 01-1.5-1.5V11.5z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#00AFEC"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.847 9.077v1.846M20.154 9.077v1.846M16.462 19.692h2.77M16.462 15.077h2.77M14.708 18.031l-1.939 2.585-1.384-1.385M14.708 13.231l-1.939 2.585-1.384-1.385"
            ></path>
        </svg>
    )
}

export default Icon
