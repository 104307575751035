import styled from 'styled-components'

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    padding-top: 30px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export const UploadWrapper = styled.div`
    display: flex;
    width: 410px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
`

export const IconPreview = styled.div`
    width: 150px;
    height: 100px;
    display: flex;
    position: relative;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    border: 1px solid #e0e0e0;

    img {
        width: 50px;
        height: auto;
        max-width: 50px;
        max-height: 50px;
    }
`
