import React from 'react'
import { Typography } from '@material-ui/core'

import { MenuItem } from './styled'
import { LibraryCategory, LibraryQuery } from '@graphql'

export type Props = {
    maxItems: number
    isLoading: boolean
    loadMore: () => void
    selected: LibraryCategory | 'Vše'
    data: Array<LibraryQuery['library']['data'][0]>
}

export const TabPanel = (props: any) => {
    const { children, value, index, ...other } = props

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {children}
        </Typography>
    )
}

const ListItem = ({
    text,
    csName,
    selected
}: {
    text: string
    csName: string
    selected: boolean
}) => <MenuItem active={selected}>{csName}</MenuItem>

export const MenuList = (list: Array<{ name: string; csName: string }>, selected: string) => {
    return list.map((el) => {
        const { name, csName } = el

        return <ListItem text={name} csName={csName} key={name} selected={selected.length > 0} />
    })
}
