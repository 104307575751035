import styled from 'styled-components'

import { media, transition } from '@variables'
import { AccessibleButton } from '../../shared/styled'

const BaseArrow = styled(AccessibleButton)`
    transition: ${transition.main};
    position: absolute;
    opacity: 0.3;
    z-index: 2;
    top: 150px;

    ${media.tablet} {
        :hover {
            opacity: 0.45;
        }
    }
`

export const Prev = styled(BaseArrow)`
    left: 10%;
`
export const Next = styled(BaseArrow)`
    right: 10%;
`

export const IconWrapper = styled.div`
    width: 175px;
    margin: 0 auto;

    img {
        width: 100%;
        display: block;
    }

    svg {
        display: block;
        margin: 0 auto;
    }
`

export const Item = styled.div<{ visible: boolean }>`
    width: 100%;

    ${(props) =>
        !props.visible &&
        `
            display: none;
        `}
`

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    margin: 35px 0;
    flex-direction: row;
    justify-content: space-around;

    ${media.tablet} {
        margin-bottom: 50px;
    }
`
