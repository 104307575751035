import styled from 'styled-components'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { media, colors } from '@variables'

export const Button = styled(MuiButton)``

export const Wrapper = styled.div`
    width: 100%;
    color: white;
    display: flex;
    text-align: left;
    border-radius: 4px;
    position: relative;
    padding: 35px 20px;
    flex-direction: column;
    background-color: ${colors.primary.orange};

    ${media.desktop} {
        padding: 25px 20px;
    }

    ${Button} {
        width: 100%;
        min-width: 200px;

        :disabled {
            border: none !important;
            color: ${colors.primary.darkBlue} !important;
            background-color: ${colors.primary.gray2} !important;
        }
    }
`

export const Text = styled(Typography)``

export const TextWrapper = styled.div`
    order: 2;
    z-index: 2;
    width: 100%;
    position: relative;

    ${media.phone} {
        order: unset;
        max-width: 70%;
    }
    @media (min-width: 960px) and (max-width: 1250px) {
        max-width: 100%;
    }

    ${Text} {
        margin: 0;
        font-size: 12px;

        ${media.xl} {
            max-width: 170px;
        }
    }
`

export const IconWrapper = styled.div`
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
    order: 1;

    ${media.phone} {
        right: 10px;
        order: unset;
        margin-bottom: 0;
        position: absolute;
        transform: translateY(-30%);
        top: 30%;
    }

    @media (min-width: 960px) and (max-width: 1170px) {
        display: none;
    }
`

export const CoinsWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10px 0 15px 0;

    p {
        margin: 0;
        font-weight: 500;
        padding-left: 10px;
    }
`

export const ButtonWrapper = styled.div`
    order: 3;
    z-index: 2;
    width: 100%;
    margin-top: 25px;
    position: relative;

    ${media.phone} {
        order: unset;
    }
`
