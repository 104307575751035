import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M5.955 7.5v11.25h13.5"
            ></path>
            <path
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M11.955 18.75V10.5a.75.75 0 00-.75-.75h-1.5a.75.75 0 00-.75.75v8.25M17.955 18.75V13.5a.75.75 0 00-.75-.75h-1.5a.75.75 0 00-.75.75v5.25"
            ></path>
            <path
                fillRule="evenodd"
                stroke="#F59100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M22.455 21.75a1.5 1.5 0 01-1.5 1.5h-18a1.5 1.5 0 01-1.5-1.5V2.25a1.5 1.5 0 011.5-1.5h15A1.5 1.5 0 0119 1.176l3 2.883a1.5 1.5 0 01.453 1.073l.002 16.618z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default Icon
