import styled from 'styled-components'

import { colors, media } from '@variables'

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 30px 35px;
    font-size: 16px;
`

export const H1 = styled.h1`
    width: 100%;
    font-size: 24px;
    text-align: left;
    font-weight: 700;
    margin-bottom: 20px;

    ${media.phone} {
        text-align: center;
        margin-bottom: 30px;
        font-size: 32px;
    }
`

export const Forgot = styled.a`
    color: ${colors.primary.darkBlue};
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;

    ${media.tablet} {
        &:hover {
            text-decoration: none;
        }
    }
`

export const CreateNewLink = styled.span`
    color: #2a3039;
    cursor: pointer;
    padding-left: 5px;

    ${media.tablet} {
        &:hover {
            text-decoration: underline;
        }
    }
`

export const SocialButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    button,
    a {
        :not(:last-child) {
            margin-bottom: 20px;
        }
    }
`

export const Divider = styled.div`
    width: 100%;
    display: flex;
    color: #95989c;
    font-size: 12px;
    flex-basis: 100%;
    align-items: center;
    margin: 20px 0 0 0;
    text-transform: uppercase;

    &::before,
    &::after {
        content: '';
        height: 1px;
        flex-grow: 1;
        font-size: 0px;
        line-height: 0px;
        background: #f2f3f3;
    }

    &::before {
        margin-right: 8px;
    }
    &::after {
        margin-left: 8px;
    }
`

export const BottomButtonWrapper = styled.div`
    width: 100%;
    max-width: 340px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 25px auto 0 auto;

    button {
        &:first-of-type {
            margin-bottom: 15px;
        }
    }
`
