import React from 'react'
import { Form, FormikProps } from 'formik'
import { CircularProgress } from '@material-ui/core'

import * as loco from '@loco'
import Tip from './components/Tip'
import LastStep from './components/LastStep'
import { SessionFragment, Answer, StepFragment } from '@graphql'
import { RadioButtonGroup, RadioButton } from './components/RadioGroup'
import { ButtonWrapper, Question, AnswerButton, Wrapper } from './styled'

type Props = {
    isLoading: boolean
    isLastStep: boolean
    showLastStep: boolean
    testStep: StepFragment
    finalTestId?: string | null
    isNextTestChapterSum: boolean
    session?: SessionFragment | null
    createNewTestSession: () => void
}

const Test = (props: Props & FormikProps<{ answer: any }>) => {
    const {
        errors,
        touched,
        session,
        testStep,
        isLoading,
        isLastStep,
        showLastStep,
        finalTestId,
        createNewTestSession
    } = props

    return (
        <Form>
            {isLastStep && showLastStep ? (
                <LastStep
                    session={session}
                    isLoading={isLoading}
                    finalTestId={finalTestId}
                    createNewTestSession={createNewTestSession}
                />
            ) : (
                <>
                    <Wrapper>
                        <Question paragraph align="center">
                            {testStep.question.text}
                        </Question>

                        <RadioButtonGroup key="radioGroup" error={errors} touched={touched}>
                            {testStep.question.answers.map(
                                (answer: Omit<Answer, 'points' | 'isCorrect'>) => {
                                    const isCorrect = testStep?.answer
                                        ? testStep?.correctAnswer?.id === answer.id
                                        : false
                                    const isWrong = testStep?.answer
                                        ? testStep?.answer?.id === answer.id
                                        : false

                                    return (
                                        <RadioButton
                                            name="answer"
                                            key={answer.id}
                                            value={answer.id}
                                            isWrong={isWrong}
                                            label={answer.text}
                                            isCorrect={isCorrect}
                                            isSubmitting={isLoading}
                                            disabled={!!testStep.answer}
                                        />
                                    )
                                }
                            )}
                        </RadioButtonGroup>

                        {!testStep?.answer && (
                            <ButtonWrapper isLoading={isLoading}>
                                <AnswerButton
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    disabled={isLoading || !Boolean(props.values.answer)}
                                >
                                    {loco.test.check}{' '}
                                    {isLoading && (
                                        <CircularProgress
                                            style={{ height: 14, width: 14, marginLeft: 10 }}
                                        />
                                    )}
                                </AnswerButton>
                            </ButtonWrapper>
                        )}
                    </Wrapper>

                    {testStep?.answer && (
                        <Tip step={testStep} isLoading={isLoading} isLastStep={isLastStep} />
                    )}
                </>
            )}
        </Form>
    )
}

export default Test
