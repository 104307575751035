import { useMedia } from 'react-use'
import isString from 'lodash/isString'
import React, { useContext } from 'react'
import { Dialog, Button, Typography } from '@material-ui/core'

import * as loco from '@loco'
import Close from '../../shared/Close'
import { Subtitle, IconWrapper } from './styled'
import { DialogContent } from '../../shared/styled'
import { DIALOG_ID, DialogContext } from '../../../context/Dialog'

const GlobalInfoDialog = () => {
    const isWide = useMedia('(min-width: 1170px)')
    const { setOpen, id, infoData, setInfoDialogData } = useContext(DialogContext)

    const close = () => {
        setOpen(DIALOG_ID.NOT_DISPLAYED)
        setInfoDialogData({
            title: '',
            subtitle: '',
            icon: undefined,
            buttonProps: {
                text: '',
                onClick: () => {}
            }
        })
    }

    return (
        <Dialog
            onClose={close}
            id={DIALOG_ID.INFO}
            fullScreen={!isWide}
            className="customDialog"
            open={id === DIALOG_ID.INFO}
            aria-labelledby={DIALOG_ID.INFO}
        >
            <DialogContent>
                <Close onClick={close}>{loco.common.close}</Close>

                {Boolean(infoData?.icon) && (
                    <IconWrapper>
                        {isString(infoData.icon) ? (
                            <img width="164" height="160" src={infoData.icon} loading="lazy" />
                        ) : (
                            infoData.icon
                        )}
                    </IconWrapper>
                )}

                <Typography variant="h3" align="center">
                    {infoData.title}
                </Typography>

                <Subtitle align="center">{infoData.subtitle}</Subtitle>

                <Button
                    fullWidth
                    size="large"
                    color="primary"
                    variant="contained"
                    onClick={infoData.buttonProps.onClick}
                    style={{ minHeight: 50, marginBottom: 50 }}
                >
                    {infoData.buttonProps.text}
                </Button>
            </DialogContent>
        </Dialog>
    )
}

export default GlobalInfoDialog
