import styled from 'styled-components'
import {
    ArrowBackRounded,
    ArrowForwardRounded,
    ArrowDownwardRounded,
    ArrowUpwardRounded
} from '@material-ui/icons'

import { colors, transition, media } from '@variables'

export const Bottom = styled(ArrowDownwardRounded)`
    top: 0;
    position: relative;
    color: ${colors.primary.orange};
    transition: ${transition.main};

    ${media.tablet} {
        :hover {
            top: 5px;
        }
    }
`

export const Left = styled(ArrowBackRounded)`
    right: 0;
    position: relative;
    color: ${colors.primary.orange};
    transition: ${transition.main};

    ${media.tablet} {
        :hover {
            right: 5px;
        }
    }
`

export const Right = styled(ArrowForwardRounded)`
    left: 0px;
    position: relative;
    color: ${colors.primary.orange};
    transition: ${transition.main};

    ${media.tablet} {
        :hover {
            left: 5px;
        }
    }
`

export const Top = styled(ArrowUpwardRounded)`
    bottom: 0;
    position: relative;
    color: ${colors.primary.orange};
    transition: ${transition.main};

    ${media.tablet} {
        :hover {
            bottom: 5px;
        }
    }
`
