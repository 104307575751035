import React from 'react'
import { Fade } from 'react-reveal'

import * as loco from '@loco'
import AppStoreImg from '@static/images/appstore.svg'
import PlayMarketImg from '@static/images/playmarket.svg'
import RhombusImg from '@static/images/rhombus.svg'
import {
    Title,
    Header,
    Subtitle,
    MarketImg,
    MarketLink,
    Container,
    Satellite,
    DownloadTitle,
    MarketWrapper
} from './styled'
import { Routes } from '@variables'
import { openInNewTab } from '@utils'

const LpHeader = () => {
    return (
        <Header>
            <Container>
                <div>
                    <Fade>
                        <Title variant="h1">{loco.lp.header.title}</Title>
                    </Fade>

                    <Fade delay={100}>
                        <Subtitle
                            paragraph
                            dangerouslySetInnerHTML={{ __html: loco.lp.header.subtitle }}
                        ></Subtitle>
                    </Fade>

                    <Fade delay={200}>
                        <DownloadTitle
                            paragraph
                            dangerouslySetInnerHTML={{ __html: loco.lp.header.download }}
                        ></DownloadTitle>
                    </Fade>
                    <Fade delay={300}>
                        <MarketWrapper>
                            <MarketLink
                                target="_blank"
                                rel="noopener noreferrer"
                                href={Routes.APP_STORE}
                            >
                                <MarketImg
                                    width="174"
                                    height="58"
                                    loading="lazy"
                                    src={AppStoreImg}
                                    alt="download app from app store"
                                />
                            </MarketLink>
                            <MarketLink href={Routes.GOOGLE_PLAY}>
                                <MarketImg
                                    width="196"
                                    height="58"
                                    loading="lazy"
                                    src={PlayMarketImg}
                                    alt="download app from play market"
                                />
                            </MarketLink>
                        </MarketWrapper>
                    </Fade>
                </div>
                <Satellite src={RhombusImg} loading="lazy" width="113" height="113" />
            </Container>
        </Header>
    )
}

export default LpHeader
