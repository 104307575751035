import React from 'react'
import { useSnackbar } from 'notistack'
import { Link, useParams } from 'react-router-dom'
import { Grid, Typography } from '@material-ui/core'

import * as loco from '@loco'
import { Routes } from '@variables'
import Head from '../../../../../components/Head'
import { Container, TextWrapper } from './styled'
import { translateGQLError, markdownToHtml } from '@utils'
import Loader from '../../../../../components/shared/Loader'
import LectureBox from '../../../../../components/shared/LectureBox'
import BackButton from '../../../../../components/shared/BackButton'
import { CustomLink, BottomHeader, Section, Header } from '../styled'
import { TranscriptFragment as TFragment, useLibraryTranscriptItemQuery } from '@graphql'

interface TranscriptFragment extends TFragment {
    similarItems: Array<Omit<TFragment, 'author'>>
}

const LibraryText = () => {
    const { enqueueSnackbar } = useSnackbar()
    const params = useParams<{ readonly id: string }>()

    const { data, error, loading } = useLibraryTranscriptItemQuery({
        variables: {
            where: {
                id: params.id
            }
        }
    })

    if (loading) {
        return (
            <Container>
                <Section>
                    <Loader pure />
                </Section>
            </Container>
        )
    }

    // @ts-ignore
    const transcript: TranscriptFragment | undefined = data?.transcript

    if (!transcript || error) {
        error && enqueueSnackbar(translateGQLError(error.message), { variant: 'error' })
        return null
    }

    return (
        <>
            <Head title={`${loco.seo.library.transcript.title} ${transcript.name}`} />

            <Container>
                <Section>
                    <Link to={Routes.LIBRARY}>
                        <BackButton>{loco.common.backToLibrary}</BackButton>
                    </Link>
                    <Header>
                        <Typography
                            variant="h3"
                            align="center"
                            style={{
                                marginBottom: 10,
                                width: '100%'
                            }}
                        >
                            {transcript.name}
                        </Typography>
                    </Header>

                    <TextWrapper>
                        <p dangerouslySetInnerHTML={{ __html: markdownToHtml(transcript.text) }} />
                    </TextWrapper>

                    {transcript.similarItems.length > 0 && (
                        <footer>
                            <BottomHeader>
                                <Typography variant="h5" style={{ fontWeight: 400 }}>
                                    {loco.library.transcript.similar}
                                </Typography>
                                <CustomLink to={Routes.LIBRARY}>
                                    {loco.library.transcript.all}
                                </CustomLink>
                            </BottomHeader>
                            <Grid container spacing={2}>
                                {transcript.similarItems
                                    // @ts-ignore
                                    .map((transcript: TranscriptFragment) => (
                                        <Grid item sm={6} md={3} xs={12} key={transcript.id}>
                                            <LectureBox
                                                id={transcript.id}
                                                title={transcript.name}
                                                description={transcript.text}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                        </footer>
                    )}
                </Section>
            </Container>
        </>
    )
}

export default LibraryText
