// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookIcon from '@material-ui/icons/Facebook'
import { useContext } from 'react'
import React from 'react'

import { Button } from './styled'
import * as loco from '@loco'
import { AuthContext } from '../../../context/Auth'

const FacebookAppId = process.env.RAZZLE_FACEBOOK_APP_ID

const FacebookButton = () => {
    const { loginWithFacebook, isLoggingIn, logoutLoading, loading } = useContext(AuthContext)

    const isLoading = isLoggingIn || logoutLoading || loading

    const submit = async (data: { accessToken: string }) => {
        await loginWithFacebook(data)
    }

    return (
        <FacebookLogin
            autoLoad={false}
            callback={submit}
            fields="name,email"
            appId={FacebookAppId}
            disableMobileRedirect={true}
            redirectUri={`https://${process.env.RAZZLE_HOST}`}
            render={(renderProps: any) => (
                <Button type="button" disabled={isLoading} onClick={renderProps.onClick}>
                    <FacebookIcon style={{ color: 'white', marginRight: 10 }} />
                    <span>{isLoggingIn ? loco.dialogs.login.logginIn : loco.dialogs.login.fb}</span>
                </Button>
            )}
        />
    )
}

export default FacebookButton
