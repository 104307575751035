import { format } from 'date-fns'
import { cs } from 'date-fns/locale'
import { Icons } from 'material-table'
import React, { forwardRef } from 'react'
import {
    Add,
    Edit,
    Clear,
    Check,
    Delete,
    Search,
    LastPage,
    FirstPage,
    ViewColumn,
    ChevronLeft,
    ChevronRight,
    ArrowDownward,
    ArrowForwardIosRounded
} from '@material-ui/icons'

import { CustomNotificationsQuery, AdminChaptersQuery, NotificationSchedule } from '@graphql'
import { getProfileName } from '@utils'
import DiamondIcon from '../SVG/Coins'
import StatusRow from './StatusRow'
import * as loco from '@loco'
import Row from './Row'

const iconStyles = { color: '#666666' }

type Notification = CustomNotificationsQuery['customNotifications']['data'][0]

type ChapterOrLecture = AdminChaptersQuery['chapters'][0] & { parentId?: string }

export const tableIcons: Icons = {
    Add: forwardRef(function add(props, ref) {
        return <Add style={iconStyles} ref={ref} {...props} />
    }),
    Edit: forwardRef(function edit(props, ref) {
        return <Edit style={iconStyles} ref={ref} {...props} />
    }),
    FirstPage: forwardRef(function firstPage(props, ref) {
        return <FirstPage style={iconStyles} ref={ref} {...props} />
    }),
    LastPage: forwardRef(function lastPage(props, ref) {
        return <LastPage style={iconStyles} ref={ref} {...props} />
    }),
    PreviousPage: forwardRef(function previousPage(props, ref) {
        return <ChevronLeft style={iconStyles} ref={ref} {...props} />
    }),
    NextPage: forwardRef(function nextPage(props, ref) {
        return <ChevronRight style={iconStyles} ref={ref} {...props} />
    }),
    Delete: forwardRef(function deleteComp(props, ref) {
        return <Delete style={iconStyles} ref={ref} {...props} />
    }),
    ViewColumn: forwardRef(function viewColumn(props, ref) {
        return <ViewColumn style={iconStyles} ref={ref} {...props} />
    }),
    Search: forwardRef(function search(props, ref) {
        return <Search style={iconStyles} ref={ref} {...props} />
    }),
    Clear: forwardRef(function clear(props, ref) {
        return <Clear style={iconStyles} ref={ref} {...props} />
    }),
    ResetSearch: forwardRef(function resetSearch(props, ref) {
        return <Clear style={iconStyles} ref={ref} {...props} />
    }),
    Check: forwardRef(function check(props, ref) {
        return <Check style={iconStyles} ref={ref} {...props} />
    }),
    DetailPanel: forwardRef(function detailPanel(props, ref) {
        return (
            <ArrowForwardIosRounded
                style={{ color: '#2A3039', width: 15, height: 15 }}
                ref={ref}
                {...props}
            />
        )
    }),
    SortArrow: forwardRef(function sortArrow(props, ref) {
        return (
            <ArrowDownward style={{ ...iconStyles, height: 14, width: 14 }} ref={ref} {...props} />
        )
    })
}

export enum TabType {
    LECTURE = 'Lekce',
    TEST = 'Test'
}

export enum TestPreviewVariant {
    NOT_ALLOW = 'NOT_ALLOW',
    ALLOW = 'ALLOW',
    REPEAT = 'REPEAT'
}

export enum Orientation {
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal'
}

export const headerStyle = {
    lineHeight: '1rem',
    color: '#757575',
    fontWeight: 300,
    fontSize: 12
}

const sortByAuthor = (a: ChapterOrLecture, b: ChapterOrLecture) => {
    const aName = a.author?.username || ''
    const bName = b.author?.username || ''

    return aName.localeCompare(bName)
}

const sortByPoints = (a: ChapterOrLecture, b: ChapterOrLecture) => {
    const aPoints = a?.maxPoints || 0
    const bPoints = b?.maxPoints || 0

    return aPoints - bPoints
}

export const chapterAndLectureColumns = [
    {
        field: 'name',
        headerStyle: headerStyle,
        title: loco.admin.table.lecturesAndChapters.name,
        render: function render(row: ChapterOrLecture) {
            const notApprovedDraft = row.drafts.find((d) => !d.isMerged && !d.isRejected)

            return (
                <StatusRow
                    id={row.id}
                    maxChars={70}
                    text={row.name}
                    status={row.status}
                    parentId={row.parentId}
                    draft={notApprovedDraft}
                />
            )
        }
    },
    {
        field: 'author',
        customSort: sortByAuthor,
        headerStyle: { ...headerStyle, width: 250 },
        title: loco.admin.table.lecturesAndChapters.author,
        render: function render(row: ChapterOrLecture) {
            return <Row maxChars={30} parentId={row.parentId} text={getProfileName(row.author)} />
        }
    },
    {
        field: 'points',
        customSort: sortByPoints,
        headerStyle: { ...headerStyle, width: 150 },
        title: loco.admin.table.lecturesAndChapters.points,
        render: function render(row: ChapterOrLecture) {
            return (
                <Row
                    parentId={row.parentId}
                    text={`${row.maxPoints}`}
                    icon={<DiamondIcon style={{ marginRight: 5 }} />}
                />
            )
        }
    }
]

export const notificationColumns = [
    {
        field: 'title',
        headerStyle: headerStyle,
        title: loco.admin.table.notifications.name,
        render: function render(n: Notification) {
            return <StatusRow text={n.title} status={n.status} />
        }
    },
    {
        field: 'author',
        headerStyle: { ...headerStyle, width: 250 },
        title: loco.admin.table.lecturesAndChapters.author,
        render: function render(row: Notification) {
            return <Row maxChars={30} text={getProfileName(row.author)} />
        }
    },
    {
        field: 'date',
        title: loco.admin.table.notifications.date,
        headerStyle: { ...headerStyle, width: 260 },
        render: function render(row: Notification) {
            const date = format(new Date(row.runAt), 'd/M/Y, HH:mm', { locale: cs })
            const repeat: string = getRepeatData(row.repeat)
            return <Row text={date + ', ' + repeat} />
        }
    }
]

const getRepeatData = (data: NotificationSchedule): string => {
    if (data === NotificationSchedule.ONCE) return 'Neopakuje se'
    if (data === NotificationSchedule.DAILY) return 'Denně'
    if (data === NotificationSchedule.WEEKLY) return 'Týdně'
    if (data === NotificationSchedule.MONTHLY) return 'Měsíčně'
    return 'Ročně'
}
