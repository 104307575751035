import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                fill="#F59100"
                d="M22 2l-5.3.8 1.542 1.544-3.064 3.064-1.61-1.508-4.076 4.178-1.484-1.484-4.705 4.61 1.4 1.427 3.29-3.225 1.515 1.516 4.123-4.223 1.592 1.492 4.433-4.433L21.2 7.3 22 2zm-3 8v11h2V10h-2zm-4 2v9h2v-9h-2zm-4 2v7h2v-7h-2zm-4 2v5h2v-5H7zm-4 2v3h2v-3H3z"
            ></path>
        </svg>
    )
}

export default Icon
