import marked from 'marked'
import { History } from 'history'
import { ChangeEvent } from 'react'
import inRange from 'lodash/inRange'

import * as loco from '@loco'
import { Routes, RoutesType } from '@variables'
import fakeChapterIcon from '@static/images/fakeChapterIcon.svg'
import { Icon, User, Role, Chapter, Lecture, CreationStatus } from '@graphql'

export const isActivePath = (is: boolean) => (is ? 'activeNavLink' : '')
export const isActivePathMobile = (is: boolean) => (is ? 'activeMobileLink' : '')

const pad = (string: any) => {
    return ('0' + string).slice(-2)
}

export const openInNewTab = (url: string) => {
    if (typeof window === 'undefined') return
    const link = document.createElement('a')
    link.href = url
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    link.remove()
}

export const formatSecondsToMinutes = (seconds: number, withoutSeconds?: boolean): string => {
    if (withoutSeconds) {
        return `${Math.round(seconds / 60)}`
    }

    const date = new Date(seconds * 1000)
    const hh = date.getUTCHours()
    const mm = date.getUTCMinutes()
    const ss = pad(date.getUTCSeconds())
    if (hh) {
        return `${hh}:${pad(mm)}:${ss}`
    }
    return `${mm}:${ss}`
}

export const truncateText = (text: string, max: number): string => {
    if (!text) return ''
    if (text.length > max) return text.slice(0, max) + '...'
    return text
}

export const getPercentages = (value: number, total: number): number => {
    return Math.ceil(value / (total / 100))
}

export const getIcon = (icon: Icon): string => {
    if (icon?.svg) return icon.svg
    if (icon?.png) return icon.png
    return fakeChapterIcon
}

export const sortByCreationStatus = (
    a: Pick<Chapter, 'status'> | Pick<Lecture, 'status'>,
    b: Pick<Chapter, 'status'> | Pick<Lecture, 'status'>
) => {
    if (a.status === b.status) return 0
    if (a.status === CreationStatus.BLOCKED && b.status === CreationStatus.APPROVED) {
        return 1
    }
    return -1
}

export const getProfileName = (
    me?: Pick<User, 'username' | 'email'> | Pick<User, 'username' | 'email' | 'role'> | null
): string => {
    if (!me) return loco.roles.newUser
    if (me.username) return me.username
    if (me.email) return me.email.split('@').shift() || me.email
    return loco.roles.newUser
}

export const getUserRole = (
    defaultRole: Role,
    validRoles: Role[],
    user?: Omit<User, 'notificationSettings'>
): Role => {
    if (!user) return defaultRole
    if (validRoles.includes(user.role)) return user.role
    return defaultRole
}

export const getMinutesWord = (minutes: number): string => {
    const value: number = Math.round(minutes)
    const valueAsArrayOfString: string[] = value.toString().split('')
    const length: number = value.toString().length
    const lastEl: number = length > 1 ? Number(valueAsArrayOfString.pop()) : 0

    if (inRange(value, 5, 21)) return 'minut'

    if (value === 1 || lastEl === 1) return 'minuta'
    if (inRange(value, 2, 5) || inRange(lastEl, 2, 5)) return 'minuty'

    return 'minut'
}

export const handleBack = (history: History) => (e: ChangeEvent<{}>) => {
    if (history && history.length > 2) {
        history.goBack()
    } else {
        history.push(Routes.HOME)
    }
}

export const getRouteWithId = (route: RoutesType, id: string): string => {
    if (!route.includes(':id')) return route
    return route.replace(':id', id)
}

export const getRole = (role?: Role | null) => {
    if (role === Role.ADMIN) return loco.roles.admin
    if (role === Role.SUPER_ADMIN) return loco.roles['super-admin']
    if (role === Role.TEACHER) return loco.roles.teacher
    return loco.roles.student
}

export const markdownToHtml = (text?: string | null) => {
    if (!text) return ''
    const html = marked(text)
    return html
}

export const smoothScrollToIdWithEvent = (id: string) => (e: any) => {
    if (typeof window === 'undefined') return

    const el = document.getElementById(id)
    const offset = el?.offsetTop || 90 - 90

    window.scroll({
        left: 0,
        top: offset,
        behavior: 'smooth'
    })
}

export const smoothScrollToId = (id: string) => {
    if (typeof window === 'undefined') return

    const el = document.getElementById(id)
    const offset = el?.offsetTop || 90 - 90

    window.scroll({
        left: 0,
        top: offset,
        behavior: 'smooth'
    })
}

export const getServiceUrl = (service: 'diploma') => {
    // return `http://localhost:4000/services/${service}.pdf`
    return `https://gs.${process.env.RAZZLE_HOST}/services/${service}.pdf`
}

export const translateGQLError = (msg: string) => {
    if (msg.startsWith('Network error: Failed to fetch'))
        return msg.replace('Network error: Failed to fetch', loco.common.netowrkError)

    return msg
}

export const getBase64 = (file: FileList[0]) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
    })
}

export const secondsToHms = (d: number): string => {
    d = Number(d)
    const h = Math.floor(d / 3600)
    const m = Math.floor((d % 3600) / 60)
    const s = Math.floor((d % 3600) % 60)

    const hDisplay = h > 0 ? h + ' h ' : ''
    const mDisplay = m > 0 ? m + ' min ' : ''
    const sDisplay = s > 0 ? s + ' sec ' : ''
    return hDisplay + mDisplay + sDisplay
}
