import styled from 'styled-components'
import { LinearProgressProps } from '@material-ui/core/LinearProgress'

import { colors } from '@variables'

export const Wrapper = styled.div<Pick<LinearProgressProps, 'value'>>`
    position: relative;
    padding-bottom: 20px;

    ${(props) =>
        props.value &&
        `
        :after {
            content: '';
            position: absolute;
            top: 5px;
            width: 12px;
            height: 12px;
            z-index: 1;
            border-radius: 50%;
            left: ${props.value}%;
            border: 1px solid white;
            background: ${colors.primary.orange};
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        }
    `}
`

export const Label = styled.span<Pick<LinearProgressProps, 'value'>>`
    top: 20px;
    z-index: 2;
    font-weight: bold;
    position: absolute;
    left: ${(props) => props.value}%;
    color: ${colors.primary.orange};
`
