import React from 'react'
import Table from 'material-table'
import Typography from '@material-ui/core/Typography'

import * as loco from '@loco'
import Head from '../../../components/Head'
import { Header, Title, Main } from './styled'
import { tableLocalization, Routes } from '@variables'
import { tableIcons } from '../../../components/shared'
import Pagination from '../../../components/Tables/Pagination'

const CookiesPage = () => (
    <>
        <Head title={loco.seo.cookies.title} />

        <section>
            <Header>
                <Title variant="h1" align="center">
                    {loco.cookiesPage.title}
                </Title>
            </Header>
            <Main>
                <Typography variant="h5" align="left">
                    Cookies
                </Typography>
                <Typography paragraph align="left">
                    Aby aplikace správně fungovala, umisťujeme do vašeho zařízení tzv. cookies.
                    Cookies jsou malé textové soubory napomáhající pamatovat si vaše aktivity a
                    preference po určitou dobu, abyste je nemuseli znovu vkládat, když se na stránky
                    vracíte nebo přecházíte ze stránky na stránku.
                </Typography>
                <Typography variant="h5" align="left">
                    Druhy cookies, které používáme na tomto webu
                </Typography>
                <Typography variant="h6" align="left">
                    Technické cookies
                </Typography>
                <Typography paragraph align="left">
                    Zajišťují základní technickou funkčnost aplikace, tj. přihlašování, využívání
                    služeb apod.
                </Typography>
                <Typography variant="h6" align="left">
                    Relační cookies
                </Typography>
                <Typography paragraph align="left">
                    Relační cookies jsou automaticky smazány v momentě, kdy opustíte aplikaci.
                    Napomáhají v průběhu návštěvy stránek jejich správnému zobrazení.
                </Typography>
                <Typography variant="h6" align="left">
                    Permanentní cookies
                </Typography>
                <Typography paragraph align="left">
                    Permanentní cookies se ukládají v zařízení a mohou obsahovat anonymní
                    identifikátor vašeho prohlížeče. Samy o sobě vás neidentifikují jako
                    jednotlivce, pouze identifikují přístup ke stránkám a chování uživatelů
                    přistupujících z určitého zařízení a jsou využívány zejména pro účely statistik
                    používání stránek. Mohou být dále používány zejména pro měření. Umí poznat
                    opakovanou návštěvu webových stránek ze stejného prohlížeče na stejném zařízení
                    a sledovat aktivity při prohlížení stránek.
                </Typography>
                <Typography variant="h6" align="left">
                    Souhlas s používáním cookies
                </Typography>
                <Typography paragraph align="left">
                    Používáním těchto webových stránek souhlasíte s užitím cookies. Můžete je
                    vymazat nebo předem odmítnout jejich používání volbou v příslušném nastavení ve
                    vašem prohlížeči. Nicméně pokud tak učiníte, můžete být nuceni nastavovat
                    některé volby manuálně pokaždé, když navštívíte stránky, a některé služby a
                    funkce nemusí fungovat. Další informace o cookies a jejich využití najdete na
                    stránkách{' '}
                    <a target="_blank" rel="noopener noreferrer" href={Routes.ABOUT_COOKIES}>
                        aboutcookies.org
                    </a>
                </Typography>
                <Typography variant="h6" align="left">
                    Seznam cookies
                </Typography>
                <Table
                    data={data}
                    columns={columns}
                    icons={tableIcons}
                    localization={tableLocalization}
                    components={{
                        Pagination: Pagination
                    }}
                    options={{
                        search: false,
                        showTitle: false,
                        toolbar: false,
                        sorting: false,
                        draggable: false,
                        filtering: false,
                        grouping: false,
                        pageSize: 7,
                        paging: false
                    }}
                />
            </Main>
        </section>
    </>
)

const columns = [
    { title: 'Název', field: 'name' },
    { title: 'Popis', field: 'description' },
    { title: 'Platnost', field: 'exp' }
]

const data = [
    {
        name: 'ACC',
        description: 'access token - slouží k ověření a přístupu na API',
        exp: '15 minut'
    },
    {
        name: 'JID',
        description:
            'refresh token - slouží k zapamatování přihlašovacích údajů a aktualizaci access tokenu',
        exp: '30 dní'
    },
    {
        name: 'CONSENT',
        description: 'Informuje klienty o používání cookies',
        exp: '30 dní'
    },
    {
        name: 'FBM_2460988634119874',
        description: 'zajišťuje přihlášení přes Facebook',
        exp: 'dle podmínek Facebook'
    },
    {
        name: 'G_ENABLED_IDPS',
        description: 'zajišťuje přihlášení přes Google',
        exp: 'dle podmínek Google'
    },
    {
        name: '_ga',
        description:
            'Google Analytics - zajišťuje analýzu používání webové aplikace za účelem vylepšení služeb',
        exp: 'dle podmínek Google'
    },
    {
        name: '_dc_gtm',
        description:
            'Google Tag Manager - nástroj, který zjednodušuje nasazení Analytických nástrojů do platformy (připraven pro případný budoucí rozvoj)',
        exp: 'dle podmínek Google'
    }
]

export default CookiesPage
