import styled from 'styled-components'
import MuiContainer from '@material-ui/core/Container'

import { colors } from '@variables'

export const Container = styled(MuiContainer)``

export const Section = styled.section<{
    readonly isBlocked?: boolean
    readonly isLastActive?: boolean
}>`
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    margin-bottom: 30px;
    background: #ffffff;
    box-shadow: 0px 10px 50px rgba(42, 48, 57, 0.05);

    ${(props) =>
        props.isLastActive &&
        `
        ::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 6px;
            height: 100%;
            z-index: 2;
            background-color: ${colors.primary.orange};
        }
    `}

    ${(props) =>
        props.isBlocked &&
        `
        cursor: not-allowed;
        pointer-events: none;

        div { opacity: 0.7; }
    `}

    .MuiExpansionPanelSummary-content,
    .MuiExpansionPanelSummary-content.Mui-expanded {
        margin: 0;
        cursor: auto;
    }
    .MuiCollapse-container {
        border-radius: 0 0 5px 5px;
        border: none;
    }
`
