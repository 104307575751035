import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            fill="none"
            viewBox="0 0 24 25"
            {...props}
        >
            <g stroke="#f59100" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                <path d="M7.477 3.334A8.711 8.711 0 0017.2 5.289"></path>
                <path
                    fillRule="evenodd"
                    d="M17.25 6v5.25a5.25 5.25 0 11-10.5 0V6a5.25 5.25 0 0110.5 0z"
                    clipRule="evenodd"
                ></path>
                <path d="M17.251 6.75c0 1.522-1.215 4.683-2.25 5.25-.937.512-1.622-1.5-3-1.5S9.938 12.512 9 12c-1.035-.567-2.25-3.728-2.25-5.25M21.636 23.25a9.763 9.763 0 00-6.55-7.752M8.915 15.5a9.763 9.763 0 00-6.55 7.752M12.115 23.25V16.5M15.115 21.75h3"></path>
            </g>
        </svg>
    )
}

export default Icon
