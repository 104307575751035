import React from 'react'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

import { Left, Right, Top, Bottom } from './styled'

interface Props extends SvgIconProps {
    variant: 'top' | 'bottom' | 'left' | 'right'
}

const ArrowIcon = ({ variant, ref, ...rest }: Props) => {
    if (variant === 'left') return <Left {...rest} />
    if (variant === 'right') return <Right {...rest} />
    if (variant === 'top') return <Top {...rest} />
    return <Bottom {...rest} />
}

export default ArrowIcon
