import { Role } from '@graphql'
import { Routes } from '@variables'

import Test from './pages/Public/Test'
import ErrorPage from './pages/Public/Error'
import Lecture from './pages/Public/Lecture'
import Diploma from './pages/Protected/Private/Diploma'
import Library from './pages/Protected/Private/Library'
import Profile from './pages/Protected/Private/Profile'
import Chapter from './pages/Public/Chapter'
import Dashboard from './pages/Public/Dashboard'
import EditProfile from './pages/Protected/Private/Profile/Edit'
import LibraryVideo from './pages/Protected/Private/Library/Video'
import LibraryText from './pages/Protected/Private/Library/Text'
import DailyQuestion from './pages/Protected/Private/DailyQuestion'
import QuestionnaireExperience from './pages/Protected/Private/Questionnaire/Experience'
import QuestionnaireLifeStage from './pages/Protected/Private/Questionnaire/LifeStage'
import QuestionnaireDistrict from './pages/Protected/Private/Questionnaire/District'

import Review from './pages/Protected/Admin/Review'
import Activity from './pages/Protected/Admin/Activity'
import EditChapter from './pages/Protected/Admin/Chapter/Edit'
import ReviewChapter from './pages/Protected/Admin/Chapter/Review'
import CreateChapter from './pages/Protected/Admin/Chapter/Create'
import EditLecture from './pages/Protected/Admin/Lecture/Edit'
import CreateLecture from './pages/Protected/Admin/Lecture/Create'
import ReviewLecture from './pages/Protected/Admin/Lecture/Review'
import SuperAdmin from './pages/Protected/Admin/SuperAdmin'
import AdminDashboard from './pages/Protected/Admin/Dashboard'
import CreateNotification from './pages/Protected/Admin/Notification/Create'
import EditNotification from './pages/Protected/Admin/Notification/Edit'
import ReviewNotification from './pages/Protected/Admin/Notification/Review'
import StudentsStatistics from './pages/Protected/Admin/Statistics'
import CreateVocabulary from './pages/Protected/Admin/Vocabulary/Create'
import EditVocabulary from './pages/Protected/Admin/Vocabulary/Edit'
import Cookies from './pages/Public/Cookies'
import Terms from './pages/Public/Terms'
import LP from './pages/Public/LP'
import AccountDeletionPage from './pages/Public/AccountDeletion'

export const routes = (userRole?: Role) => {
    return [
        {
            component: Dashboard,
            path: [
                Routes.HOME,
                Routes.INVITE,
                Routes.NEW_PASSWORD,
                Routes.DEEP_APP,
                Routes.DEEP_RESET_PWD,
                Routes.VERIFY_EMAIL
            ],
            isPrivate: false
        },
        {
            component: Test,
            path: Routes.TEST,
            isPrivate: false
        },
        {
            component: Chapter,
            path: Routes.CHAPTER,
            isPrivate: false
        },
        {
            component: Lecture,
            path: Routes.LECTURE,
            isPrivate: false
        },
        {
            component: Terms,
            path: Routes.TERMS,
            isPrivate: false
        },
        {
            component: AccountDeletionPage,
            path: Routes.ACCOUNT_DELETION,
            isPrivate: false
        },
        {
            component: Cookies,
            path: Routes.COOKIES,
            isPrivate: false
        },
        {
            component: LP,
            path: Routes.LP,
            isPrivate: false
        },
        {
            component: Profile,
            path: Routes.PROFILE,
            isPrivate: true
        },
        {
            component: QuestionnaireExperience,
            path: Routes.QUESTIONNAIRE_EXPERIENCE,
            isPrivate: true
        },
        {
            component: QuestionnaireLifeStage,
            path: Routes.QUESTIONNAIRE_LIFESTAGE,
            isPrivate: true
        },
        {
            component: QuestionnaireDistrict,
            path: Routes.QUESTIONNAIRE_DISTRICT,
            isPrivate: true
        },
        {
            component: Library,
            path: Routes.LIBRARY,
            isPrivate: true
        },
        {
            component: EditProfile,
            path: Routes.PROFILE_EDIT,
            isPrivate: true
        },
        {
            component: LibraryText,
            path: Routes.LIBRARY_TEXT,
            isPrivate: true
        },
        {
            component: LibraryVideo,
            path: Routes.LIBRARY_VIDEO,
            isPrivate: true
        },
        {
            component: DailyQuestion,
            path: Routes.DAILY_QUESTION,
            isPrivate: true
        },
        {
            component: Diploma,
            path: Routes.DIPLOMA,
            isPrivate: true
        },
        {
            component: AdminDashboard,
            path: Routes.DASHBOARD,
            isPrivate: true,
            role: [Role.ADMIN]
        },
        {
            component: Review,
            path: Routes.REVIEW,
            isPrivate: true,
            role: [Role.ADMIN]
        },
        {
            component: Activity,
            path: Routes.ACTIVITY,
            isPrivate: true,
            role: [Role.SUPER_ADMIN]
        },
        {
            component: StudentsStatistics,
            path: Routes.STATISTICS,
            isPrivate: true,
            role: [Role.ADMIN]
        },
        {
            component: CreateChapter,
            path: Routes.CHAPTER_CREATE,
            isPrivate: true,
            role: [Role.ADMIN]
        },
        {
            component: EditChapter,
            path: Routes.CHAPTER_EDIT,
            isPrivate: true,
            role: [Role.ADMIN]
        },
        {
            component: ReviewChapter,
            path: Routes.CHAPTER_REVIEW,
            isPrivate: true,
            role: [Role.ADMIN]
        },
        {
            component: CreateLecture,
            path: Routes.LECTURE_CREATE,
            isPrivate: true,
            role: [Role.ADMIN]
        },
        {
            component: EditLecture,
            path: Routes.LECTURE_EDIT,
            isPrivate: true,
            role: [Role.ADMIN]
        },
        {
            component: ReviewLecture,
            path: Routes.LECTURE_REVIEW,
            isPrivate: true,
            role: [Role.ADMIN]
        },
        {
            component: CreateLecture,
            path: Routes.LECTURE_CREATE,
            isPrivate: true,
            role: [Role.ADMIN]
        },
        {
            component: CreateNotification,
            path: Routes.NOTIFICATION_CREATE,
            isPrivate: true,
            role: [Role.ADMIN]
        },
        {
            component: EditNotification,
            path: Routes.NOTIFICATION_EDIT,
            isPrivate: true,
            role: [Role.ADMIN]
        },
        {
            component: ReviewNotification,
            path: Routes.NOTIFICATION_REVIEW,
            isPrivate: true,
            role: [Role.ADMIN]
        },
        {
            component: CreateVocabulary,
            path: Routes.VOCABULARY_CREATE,
            isPrivate: true,
            role: [Role.ADMIN]
        },
        {
            component: EditVocabulary,
            path: Routes.VOCABULARY_EDIT,
            isPrivate: true,
            role: [Role.ADMIN]
        },
        {
            component: SuperAdmin,
            path: Routes.SUPER_ADMIN,
            isPrivate: true,
            role: [Role.SUPER_ADMIN]
        },
        // {
        //     component: EditSchools,
        //     path: Routes.DASHBOARD,
        //     isPrivate: true,
        //     role: [Role.AMBASSADOR]
        // },
        // {
        //     component: SchoolsStatistics,
        //     path: Routes.STATISTICS,
        //     isPrivate: true,
        //     role: [Role.AMBASSADOR]
        // },
        // {
        //     component: ClassesStatistics,
        //     path: Routes.STATISTICS_CLASSES,
        //     isPrivate: true,
        //     role: [Role.AMBASSADOR]
        // },
        // {
        //     component: ForTeacher,
        //     path: Routes.FOR_TEACHER,
        //     isPrivate: true,
        //     role: [Role.SCHOOL]
        // },
        // {
        //     component: EditTeachers,
        //     path: Routes.DASHBOARD,
        //     isPrivate: true,
        //     role: [Role.SCHOOL]
        // },
        // {
        //     component: ClassesStatistics,
        //     path: Routes.STATISTICS,
        //     isPrivate: true,
        //     role: [Role.SCHOOL]
        // },
        {
            component: ErrorPage,
            path: '*'
        }
    ].filter((route) => {
        if (
            !route.role ||
            (userRole && route.role.includes(userRole)) ||
            (userRole === Role.SUPER_ADMIN && route.role.includes(Role.ADMIN))
        ) {
            return true
        }
        return false
    })
}
