import { useSetState } from 'react-use'
import { useSnackbar } from 'notistack'
import Edit from '@material-ui/icons/Edit'
import React, { ChangeEvent, useContext, forwardRef } from 'react'
import MaterialTable, { MTableHeader, Column } from 'material-table'

import * as loco from '@loco'
import Row from '../../../shared/Row'
import Dialog from '../../../Dialogs/User'
import { translateGQLError } from '@utils'
import Pagination from '../../Pagination'
import { AuthContext } from '../../../../context/Auth'
import BlockOrUnblockDialog from '../../../Dialogs/Info'
import { tableIcons, headerStyle } from '../../../shared'
import { tableLocalization, notificationMsgs } from '@variables'
import {
    Title,
    getTitle,
    UserType,
    UsersType,
    ActionType,
    getButtonText,
    getDescription,
    getRefetchQueries,
    getBlockOrUnblockOrDeleteRowIcon
} from '../index'
import {
    Role,
    AdminUserFragment,
    useBlockUserMutation,
    OrderByDirectionInput,
    useUnblockUserMutation,
    useBlockAndDeleteUserMutation
} from '@graphql'

type Props = {
    title: Title
    page: number
    open: boolean
    loading: boolean
    pageSize: number
    users: UsersType
    totalCount: number
    refetch: () => void
    sortedColumnId: number
    setOpen: (is: boolean) => void
    onChangePage: (page: number) => void
    onSearch: (searchQuery: string) => void
    onChangeItemsPerPage: (page: number) => void
    sortedColumnDirection: OrderByDirectionInput
    handleSortOrderChange: (orderedColumnId: number, orderDirection: OrderByDirectionInput) => void
}

type State = {
    isOpen: boolean
    actionType: ActionType
    currentUser?: UserType
}

const SuperadminTable = ({
    page,
    open,
    title,
    setOpen,
    loading,
    pageSize,
    refetch,
    onSearch,
    users = [],
    totalCount,
    onChangePage,
    sortedColumnId,
    onChangeItemsPerPage,
    handleSortOrderChange,
    sortedColumnDirection
}: Props) => {
    const { user } = useContext(AuthContext)
    const { enqueueSnackbar } = useSnackbar()

    const [state, setState] = useSetState<State>({
        currentUser: undefined,
        actionType: undefined,
        isOpen: false
    })

    const { currentUser, isOpen } = state

    const [deleteUser, { loading: deleteLoading }] = useBlockAndDeleteUserMutation({
        onCompleted: () => refetch()
    })
    const [blockUser, { loading: blockLoading }] = useBlockUserMutation({
        refetchQueries: getRefetchQueries(currentUser)
    })
    const [unblockUser, { loading: unblockLoading }] = useUnblockUserMutation({
        refetchQueries: getRefetchQueries(currentUser)
    })

    const isLoading = blockLoading || unblockLoading || deleteLoading

    const onRowAction = (e: ChangeEvent<{}>, actionType: ActionType, user?: AdminUserFragment) => {
        if (!user) return
        setState({ currentUser: user, isOpen: true, actionType: actionType })
    }

    const onClick = async () => {
        if (!currentUser?.id) return

        if (state.actionType === 'block') {
            const { data, errors } = await blockUser({
                variables: { where: { id: currentUser?.id } }
            })

            if (data && !errors) {
                enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
                setState({ isOpen: false })
            } else {
                errors?.forEach((err) =>
                    enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
                )
            }
        }

        if (state.actionType === 'unblock') {
            const { data, errors } = await unblockUser({
                variables: { where: { id: currentUser?.id } }
            })

            if (data && !errors) {
                enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
                setState({ isOpen: false })
            } else {
                errors?.forEach((err) =>
                    enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
                )
            }
        }

        if (state.actionType === 'delete') {
            const { data, errors } = await deleteUser({
                variables: { where: { id: currentUser?.id } }
            })

            if (data && !errors) {
                enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
                setState({ isOpen: false })
            } else {
                errors?.forEach((err) =>
                    enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
                )
            }
        }
    }

    return (
        <>
            <Dialog
                open={open}
                title={title}
                refetch={refetch}
                currentUser={currentUser}
                close={() => {
                    setOpen(false)
                    setState({ currentUser: undefined })
                }}
            />

            <BlockOrUnblockDialog
                isOpen={isOpen}
                toggleOpen={() => {
                    setState({ isOpen: !isOpen, currentUser: undefined })
                }}
                buttonProps={{
                    onClick: onClick,
                    isSubmitting: isLoading,
                    text: getButtonText(state.actionType)
                }}
                data={[
                    {
                        title: getTitle(currentUser, state.actionType),
                        description: getDescription(currentUser, state.actionType)
                    }
                ]}
            />

            <MaterialTable
                page={page}
                title={title}
                columns={columns}
                icons={tableIcons}
                totalCount={totalCount}
                onSearchChange={onSearch}
                onChangePage={onChangePage}
                isLoading={loading || isLoading}
                localization={tableLocalization}
                onChangeRowsPerPage={onChangeItemsPerPage}
                options={{
                    actionsColumnIndex: -1,
                    debounceInterval: 500,
                    pageSize: pageSize,
                    thirdSortClick: false,
                    draggable: false
                }}
                components={{
                    Header: (props) =>
                        (
                            <MTableHeader
                                {...props}
                                orderBy={sortedColumnId}
                                onOrderChange={handleSortOrderChange}
                                orderDirection={sortedColumnDirection}
                            />
                        ) as any,
                    Pagination: Pagination
                }}
                data={users.filter((user) => {
                    if (title === Title.ADMIN) return user.role === Role.ADMIN
                    return false
                })}
                actions={
                    user?.role === Role.SUPER_ADMIN
                        ? [
                              (editRow) => ({
                                  tooltip: state.currentUser ? undefined : loco.common.edit,
                                  icon: forwardRef(function icon(props, ref: any) {
                                      return (
                                          <Edit style={{ color: '#666666' }} ref={ref} {...props} />
                                      )
                                  }) as any,
                                  onClick: (e, rowData) => {
                                      setState({
                                          currentUser: !Array.isArray(rowData) ? rowData : undefined
                                      })
                                      setOpen(true)
                                  }
                              }),
                              (blockOrUnblockRow) => {
                                  const icon = getBlockOrUnblockOrDeleteRowIcon(
                                      blockOrUnblockRow.isBlocked
                                  )

                                  return {
                                      icon: icon,
                                      position: 'row',
                                      tooltip: state.currentUser
                                          ? undefined
                                          : blockOrUnblockRow.isBlocked
                                          ? loco.common.unblock
                                          : loco.common.block,
                                      onClick: (e, rowData) =>
                                          onRowAction(
                                              e,
                                              blockOrUnblockRow.isBlocked ? 'unblock' : 'block',
                                              Array.isArray(rowData) ? undefined : rowData
                                          )
                                  }
                              },
                              (deleteUserRow) => {
                                  const icon = getBlockOrUnblockOrDeleteRowIcon()

                                  return {
                                      icon: icon,
                                      position: 'row',
                                      tooltip: state.currentUser
                                          ? undefined
                                          : loco.common.blockAndDelete,
                                      onClick: (e, rowData) =>
                                          onRowAction(
                                              e,
                                              'delete',
                                              Array.isArray(rowData) ? undefined : rowData
                                          )
                                  }
                              }
                          ]
                        : []
                }
            />
        </>
    )
}

const getUserStatus = (isEmailVerified: boolean) => {
    if (isEmailVerified) {
        return loco.user_email_verif_status.accepted
    }
    return loco.user_email_verif_status.waiting
}

const columns: Column<AdminUserFragment>[] = [
    {
        field: 'name',
        headerStyle: { ...headerStyle },
        title: loco.admin.statistics.ambassadors.name,
        render: function render(row: AdminUserFragment) {
            return <Row text={row?.username || ''} />
        }
    },
    {
        field: 'email',
        headerStyle: { ...headerStyle },
        title: loco.dialogs.user.email.label,
        render: function render(row: AdminUserFragment) {
            return <Row text={row?.email || ''} />
        }
    },
    {
        field: 'status',
        headerStyle: { ...headerStyle },
        title: loco.dialogs.user.status.label,
        render: function render(row: AdminUserFragment) {
            return <Row text={getUserStatus(Boolean(row?.isEmailVerified))} />
        }
    }
]

export default SuperadminTable
