import { useMedia } from 'react-use'
import React, { useContext } from 'react'
import Arrow from '@material-ui/icons/ArrowForwardIosRounded'
import { Grid, Typography, CircularProgress } from '@material-ui/core'

import { TabPanel } from '..'
import * as loco from '@loco'
import { colors } from '@variables'
import AudioBox from '../../shared/AudioBox'
import VideoBox from '../../shared/VideoBox'
import { More, TitleWrapper } from './styled'
import LectureBox from '../../shared/LectureBox'
import ExpansionPanel from '../../ExpansionPanels/Library'
import { LibraryContext } from '../../../context/Library'
import {
    WordFragment,
    VideoFragment,
    AudioFragment,
    LibraryQuery,
    LibraryCategory,
    TranscriptFragment
} from '@graphql'

type Props = {
    isLoading: boolean
    data?: LibraryQuery
    selected: LibraryCategory | 'Vše'
}

const AllLibrary = ({ selected, data, isLoading }: Props) => {
    const words = data?.library.data.filter((i) => i.__typename === 'Word') || []
    const video = data?.library.data.filter((i) => i.__typename === 'Video') || []
    const audio = data?.library.data.filter((i) => i.__typename === 'Audio') || []
    const transcripts = data?.library.data.filter((i) => i.__typename === 'Transcript') || []

    const isWide = useMedia('(min-width: 600px)')

    const { onSelect } = useContext(LibraryContext)

    return (
        <TabPanel value={selected} index="Vše">
            {isLoading && (
                <CircularProgress
                    style={{
                        display: 'block',
                        margin: '100px auto 0 auto'
                    }}
                />
            )}

            {!isLoading && (
                <>
                    <TitleWrapper>
                        <Typography variant="body1">{loco.library.video.title}</Typography>
                        <More onClick={() => onSelect(LibraryCategory.VIDEO)}>
                            <span>{loco.library['see-all']}</span> <Arrow />
                        </More>
                    </TitleWrapper>
                    <Grid container spacing={2} style={{ marginBottom: 30 }}>
                        {video.length === 0 && (
                            <Typography variant="body2" align="center" style={{ width: '100%' }}>
                                {loco.library.search['no-results']}
                            </Typography>
                        )}

                        {video.length > 0 &&
                            // @ts-ignore
                            video.slice(0, isWide ? 4 : 2).map((item: VideoFragment) => (
                                <Grid item xs={12} sm={6} md={3} key={item.id}>
                                    <VideoBox
                                        id={item.id}
                                        name={item.name}
                                        preview={item.preview}
                                        duration={item.length}
                                        tag={item.lecture.chapter.name}
                                        playBtnColor={colors.primary.orange}
                                    />
                                </Grid>
                            ))}
                    </Grid>

                    <TitleWrapper>
                        <Typography variant="body1">{loco.library.audio}</Typography>
                        <More onClick={() => onSelect(LibraryCategory.AUDIO)}>
                            <span>{loco.library['see-all']}</span> <Arrow />
                        </More>
                    </TitleWrapper>
                    <Grid container spacing={2} style={{ marginBottom: 30 }}>
                        {audio.length === 0 && (
                            <Typography variant="body2" align="center" style={{ width: '100%' }}>
                                {loco.library.search['no-results']}
                            </Typography>
                        )}

                        {audio.length > 0 &&
                            // @ts-ignore
                            audio.slice(0, isWide ? 4 : 2).map((item: AudioFragment) =>
                                item?.src ? (
                                    <Grid item xs={12} sm={6} md={3} key={item.id}>
                                        <AudioBox
                                            id={item.id}
                                            name={item.name}
                                            src={item?.src || '#'}
                                            duration={item?.length}
                                            playBtnColor={colors.primary.orange}
                                        />
                                    </Grid>
                                ) : null
                            )}
                    </Grid>

                    <TitleWrapper>
                        <Typography variant="body1">{loco.library.transcript.title}</Typography>
                        <More onClick={() => onSelect(LibraryCategory.TRANSCRIPT)}>
                            <span>{loco.library['see-all']}</span> <Arrow />
                        </More>
                    </TitleWrapper>
                    <Grid container spacing={2} style={{ marginBottom: 30 }}>
                        {transcripts.length === 0 && (
                            <Typography variant="body2" align="center" style={{ width: '100%' }}>
                                {loco.library.search['no-results']}
                            </Typography>
                        )}

                        {transcripts.length > 0 &&
                            // @ts-ignore
                            transcripts.slice(0, isWide ? 4 : 2).map((item: TranscriptFragment) => (
                                <Grid item xs={12} sm={6} md={3} key={item.id}>
                                    <LectureBox
                                        id={item.id}
                                        title={item.name}
                                        description={item.text}
                                    />
                                </Grid>
                            ))}
                    </Grid>

                    <TitleWrapper style={{ marginBottom: 20 }}>
                        <Typography variant="body1">{loco.library.dictionary}</Typography>
                        <More onClick={() => onSelect(LibraryCategory.VOCABULARY)}>
                            <span>{loco.library['see-all']}</span> <Arrow />
                        </More>
                    </TitleWrapper>
                    <Grid container spacing={2}>
                        {words.length === 0 && (
                            <Typography variant="body2" align="center" style={{ width: '100%' }}>
                                {loco.library.search['no-results']}
                            </Typography>
                        )}

                        {words.length > 0 &&
                            words
                                .slice(0, 2)
                                // @ts-ignore
                                .map((item: WordFragment) => (
                                    <ExpansionPanel
                                        key={item.id}
                                        title={item.word}
                                        description={item.description}
                                    />
                                ))}
                    </Grid>
                </>
            )}
        </TabPanel>
    )
}

export default AllLibrary
