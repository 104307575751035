import { isUndefined } from 'lodash'
import React, { useContext } from 'react'
import Button from '@material-ui/core/Button'
import { useHistory, useLocation } from 'react-router-dom'

import * as loco from '@loco'
import { Role } from '@graphql'
import { getRouteWithId } from '@utils'
import { colors, Routes } from '@variables'
import { Wrapper, Left, Tabs, Tab } from './styled'
import { TabType } from '../../../../../../components/shared'
import Arrow from '../../../../../../components/shared/Arrow'
import Tooltip from '../../../../../../components/shared/Tooltip'
import { AuthContext } from '../../../../../../context/Auth'
import { PanelVariant } from '../../../../../../components/ExpansionPanels/Test'

type Props = {
    tab: TabType
    isValid?: boolean
    isSameAuthor?: boolean
    isSubmitting: boolean
    variant: PanelVariant
    handleOpen?: () => void
    isUpdateDisabled?: boolean
    lectureId?: string
    handleTab: (newTab: TabType) => void
}

const Header = ({
    tab,
    variant,
    isValid,
    lectureId,
    handleTab,
    handleOpen,
    isSubmitting,
    isSameAuthor,
    isUpdateDisabled
}: Props) => {
    const history = useHistory()
    const location = useLocation()

    const { user } = useContext(AuthContext)

    return (
        <Wrapper>
            <Left>
                <Arrow
                    variant="left"
                    style={{
                        color: colors.primary.orange,
                        fontSize: '1.8rem',
                        marginRight: 30,
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        if (variant === PanelVariant.REVIEW) history.push(Routes.REVIEW)
                        else history.push(Routes.DASHBOARD)
                    }}
                />
                <Tabs>
                    <Tab
                        isActive
                        type="button"
                        active={tab === TabType.LECTURE}
                        onClick={() => handleTab(TabType.LECTURE)}
                    >
                        {loco.admin.create.lecture.title}
                    </Tab>
                    <Tab
                        type="button"
                        active={tab === TabType.TEST}
                        onClick={() => handleTab(TabType.TEST)}
                        disabled={variant === PanelVariant.CREATE}
                        isActive={variant === PanelVariant.EDIT || variant === PanelVariant.REVIEW}
                    >
                        {loco.admin.create.lecture.test.title}
                    </Tab>
                </Tabs>
            </Left>

            <div style={{ display: 'flex', alignItems: 'center' }}>
                {variant === PanelVariant.EDIT && tab === TabType.TEST && (
                    <Tooltip withHelpIcon title={loco.tooltips.testMustHaveAtLeastXQuestions} />
                )}
                {variant === PanelVariant.EDIT && tab === TabType.LECTURE && !isSameAuthor && (
                    <Tooltip withHelpIcon title={loco.tooltips.sameAuthorDraft} />
                )}
                {variant === PanelVariant.REVIEW &&
                    user?.role !== Role.SUPER_ADMIN &&
                    isSameAuthor && <Tooltip withHelpIcon title={loco.tooltips.sameAuthor} />}

                {(variant === PanelVariant.REVIEW ||
                    location.pathname.includes(Routes.LECTURE_CREATE)) && (
                    <Button
                        size="large"
                        type="button"
                        color="primary"
                        variant="outlined"
                        style={{ marginLeft: 30, color: colors.primary.red }}
                        onClick={() => {
                            if (variant === PanelVariant.REVIEW && handleOpen) {
                                return handleOpen()
                            }
                            history.push(Routes.DASHBOARD)
                        }}
                        disabled={
                            isSubmitting ||
                            (user?.role !== Role.SUPER_ADMIN &&
                                isSameAuthor &&
                                variant === PanelVariant.REVIEW)
                        }
                    >
                        {variant === PanelVariant.REVIEW ? loco.common.reject : loco.common.cancel}
                    </Button>
                )}

                {variant === PanelVariant.REVIEW && lectureId && (
                    <Button
                        size="large"
                        type="button"
                        color="primary"
                        variant="contained"
                        style={{ marginLeft: 30 }}
                        disabled={!isUndefined(isUpdateDisabled) && isUpdateDisabled}
                        onClick={() => {
                            history.push(getRouteWithId(Routes.LECTURE_EDIT, lectureId))
                        }}
                    >
                        {loco.common.edit}
                    </Button>
                )}

                {tab === TabType.TEST && variant === PanelVariant.EDIT ? (
                    <Button
                        size="large"
                        type="button"
                        color="primary"
                        variant="contained"
                        style={{ marginLeft: 30 }}
                        onClick={() =>
                            history.push({
                                pathname: Routes.DASHBOARD,
                                search: Routes.CHAPTERS_SEARCH_PARAM
                            })
                        }
                    >
                        {loco.common.toAdminDashboard}
                    </Button>
                ) : (
                    <Button
                        size="large"
                        type="submit"
                        color="primary"
                        variant="contained"
                        style={{ marginLeft: 30 }}
                        disabled={
                            (!Boolean(isValid) && tab === TabType.LECTURE) ||
                            (isSameAuthor &&
                                user?.role !== Role.SUPER_ADMIN &&
                                variant === PanelVariant.REVIEW) ||
                            isSubmitting
                        }
                    >
                        {getButtonText(variant, user?.role)}
                    </Button>
                )}
            </div>
        </Wrapper>
    )
}

const getButtonText = (variant: PanelVariant, role?: Role) => {
    if (variant === PanelVariant.REVIEW) {
        return loco.common.approve
    }
    if (role === Role.SUPER_ADMIN) {
        if (variant === PanelVariant.EDIT) return loco.common.save
        return loco.common.create
    }

    return loco.common.sendToReview
}

export default Header
