import React from 'react'
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress'

import { Wrapper, Label } from './styled'

const Progress = (props: LinearProgressProps) => {
    const progress = Math.round(props?.value || 0)

    return (
        <Wrapper value={progress}>
            {progress > 0 && <Label value={progress}>{progress}%</Label>}
            <LinearProgress {...props} />
        </Wrapper>
    )
}

export default Progress
