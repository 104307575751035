import get from 'lodash/get'
import { useMedia } from 'react-use'
import { useSnackbar } from 'notistack'
import Grid from '@material-ui/core/Grid'
import React, { useEffect, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import * as loco from '@loco'
import { getIcon } from '@utils'
import Head from '../../../components/Head'
import { Routes, colors } from '@variables'
import Arrow from '../../../components/shared/Arrow'
import Loader from '../../../components/shared/Loader'
import { AuthContext } from '../../../context/Auth'
import BackButton from '../../../components/shared/BackButton'
import { TestPreviewVariant } from '../../../components/shared'
import ChapterTestPreview from '../../../components/TestPreview'
import { Blur, BlurWrapper } from '../../../components/shared/styled'
import Carousel from '../../../components/Chapter/components/Carousel'
import { sortByCreationStatus, translateGQLError } from '@utils'
import Pagination from '../../../components/Chapter/components/Pagination'
import IconWithProgressBar from '../../../components/shared/IconWithProgressBar'
import LecturePreview from '../../../components/Chapter/components/LecturePreview'
import { formatSecondsToMinutes, getRouteWithId, getMinutesWord } from '@utils'
import {
    Title,
    Column,
    Section,
    Wrapper,
    Duration,
    Container,
    Description,
    QueryBuilder,
    LectureColumn,
    LectureButton
} from './styled'
import {
    CreationStatus,
    useChapterQuery,
    LectureForChapterFragment,
    useChaptersWithoutLecturesQuery
} from '@graphql'

const ChapterPage = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { isAuthorized, user } = useContext(AuthContext)

    const isWide = useMedia('(min-width: 960px)')

    const history = useHistory()
    const { id } = useParams<{ id: string }>()

    const { data, error, loading, refetch } = useChapterQuery({
        variables: { where: { id } }
    })

    const {
        data: chaptersData,
        error: chaptersError,
        loading: chaptersLoading,
        refetch: chaptersRefetch
    } = useChaptersWithoutLecturesQuery()

    const isTempOrNotAuth = !isAuthorized || Boolean(user?.isTemporary)

    useEffect(() => {
        refetch()
        chaptersRefetch()
    }, [user])

    if (loading || chaptersLoading) return <Loader />

    if (!data || error || chaptersError || !chaptersData) {
        error && enqueueSnackbar(translateGQLError(error.message), { variant: 'error' })
        chaptersError &&
            enqueueSnackbar(translateGQLError(chaptersError.message), { variant: 'error' })
        return null
    }

    if (data?.chapter?.status !== CreationStatus.APPROVED) {
        history.push(Routes.HOME)
        return null
    }

    const { chapter } = data
    const { icon, name, lectures, description, totalLength, progress } = chapter

    const maxPoints = chapter?.maxPoints || 0
    const acqPoints = chapter?.acquiredPoints || 0

    const { chapters } = chaptersData

    const handleContinue = () => {
        if (chapter?.summaryTest?.id) {
            return history.push(getRouteWithId(Routes.TEST, chapter.summaryTest.id))
        }
        if (nextLecture) {
            return history.push(getRouteWithId(Routes.LECTURE, nextLecture.id))
        }

        const anyLecture = filtredLectures.find((l) => l.status === CreationStatus.APPROVED)
        return history.push(getRouteWithId(Routes.LECTURE, anyLecture?.id || '#'))
    }

    const getPreviewVariant = () => {
        if (!chapter?.summaryTest || isTempOrNotAuth) return TestPreviewVariant.NOT_ALLOW
        if (chapter?.summaryTest?.isCompleted) return TestPreviewVariant.REPEAT
        return TestPreviewVariant.ALLOW
    }

    // Doubling the first lecture beacause of need to show fake(blurred) lecture in carousel
    // When user isTempOrNotAuth lectures.length always === 1
    const Lectures: Array<LectureForChapterFragment> = isTempOrNotAuth
        ? [...lectures, ...lectures]
        : lectures

    const filtredLectures = Lectures.filter(
        (l) =>
            !l.test?.isChapterSummary &&
            (l.status === CreationStatus.APPROVED ||
                (l.status === CreationStatus.BLOCKED && (l?.acquiredPoints || 0) > 0))
    )

    const nextLecture = Lectures.filter((l) => l.status === CreationStatus.APPROVED).find(
        (lecture) => !Boolean(lecture.acquiredPoints)
    )

    const variant = getPreviewVariant()
    const testId = get(chapter, 'summaryTest.id', '')
    const previewMaxPoints = chapter?.summaryTest?.isCompleted
        ? chapter?.summaryTest?.maxPoints
        : maxPoints
    const previewAcquiredPoints = chapter?.summaryTest?.isCompleted
        ? chapter?.summaryTest?.acquiredPoints
        : acqPoints

    return (
        <>
            <Head title={`${loco.seo.chapter.title} ${name}`} />

            <Container>
                <Section>
                    <BackButton
                        onClick={() => {
                            history.push(Routes.HOME)
                        }}
                    >
                        {loco.common.back}
                    </BackButton>

                    <Grid container>
                        <Column item md={6} xs={12}>
                            <Grid container>
                                <Grid item>
                                    <IconWithProgressBar
                                        size="large"
                                        icon={getIcon(icon)}
                                        value={progress || 0}
                                        color={colors.primary.orange}
                                    />
                                </Grid>
                                <LectureColumn item>
                                    <Title variant="h1">{name}</Title>
                                    {!isWide && <Description paragraph>{description}</Description>}
                                    <Duration paragraph>
                                        <QueryBuilder />
                                        {formatSecondsToMinutes(totalLength, true)}{' '}
                                        {getMinutesWord(Math.round(totalLength / 60))}
                                    </Duration>
                                </LectureColumn>
                            </Grid>

                            {isWide && <Description paragraph>{description}</Description>}

                            <LectureButton
                                onClick={handleContinue}
                                variant="contained"
                                color="primary"
                                size="large"
                            >
                                {isTempOrNotAuth || progress === 0
                                    ? loco.chapter.detail.start
                                    : loco.chapter.detail.continue}
                            </LectureButton>
                        </Column>

                        <Grid item md={6} xs={12}>
                            <ChapterTestPreview
                                testId={testId}
                                variant={variant}
                                maxPoints={previewMaxPoints}
                                acquiredPoints={previewAcquiredPoints}
                            />
                        </Grid>
                    </Grid>

                    <Wrapper>
                        <Carousel
                            prevButton={<Arrow variant="left" />}
                            nextButton={<Arrow variant="right" />}
                            options={{ ...carouselOptions, bound: !isTempOrNotAuth }}
                        >
                            {filtredLectures.length > 0 &&
                                filtredLectures
                                    .sort(sortByCreationStatus)
                                    .map((lecture, idx: number) => {
                                        const isBlocked = lecture.status === CreationStatus.BLOCKED
                                        const video = get(lecture, 'library', [])?.find(
                                            (i) => i.__typename === 'Video'
                                        )

                                        return (
                                            <BlurWrapper key={lecture.id}>
                                                {isTempOrNotAuth && idx > 0 && <Blur />}
                                                <LecturePreview
                                                    idx={idx}
                                                    id={lecture.id}
                                                    key={lecture.id}
                                                    name={lecture.name}
                                                    isBlocked={isBlocked}
                                                    maxPoints={lecture.maxPoints}
                                                    // @ts-ignore
                                                    videoPreview={video?.preview || ''}
                                                    acquiredPoints={lecture.acquiredPoints}
                                                    style={
                                                        isTempOrNotAuth && idx > 0
                                                            ? { filter: 'blur(3.5px)' }
                                                            : undefined
                                                    }
                                                />
                                            </BlurWrapper>
                                        )
                                    })}
                        </Carousel>
                    </Wrapper>
                </Section>

                <Pagination chapter={chapter} chapters={chapters} />
            </Container>
        </>
    )
}

const carouselOptions = {
    gap: 20,
    startAt: 0,
    perView: 3,
    bound: true,
    rewind: true,
    type: 'slider',
    peek: {
        before: 0,
        after: 160
    },
    breakpoints: {
        1170: {
            perView: 2
        },
        680: {
            perView: 2,
            peek: {
                before: 0,
                after: 80
            }
        },
        540: {
            perView: 1,
            peek: {
                before: 0,
                after: 60
            }
        },
        400: {
            perView: 1,
            peek: {
                before: 0,
                after: 0
            }
        }
    }
}

export default ChapterPage
