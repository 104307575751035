import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            fill="none"
            viewBox="0 0 48 48"
            {...props}
        >
            <circle cx="24" cy="24" r="24" fill="#f59100" opacity="0.1"></circle>
            <g stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                <path d="M25.772 32.36A11.582 11.582 0 0124 35.25c-.757-.9-1.37-1.912-1.816-3M24 14.598V12.75"></path>
                <path
                    d="M27.352 16.984L24 14.6l-3.352 2.384a2.7 2.7 0 00-1.048 2.863l2.3 8.72h4.2l2.3-8.72a2.7 2.7 0 00-1.048-2.863z"
                    clipRule="evenodd"
                ></path>
                <path d="M20.881 24.709l-1 .461a1.9 1.9 0 00-1.135 1.7v2.719a.473.473 0 00.277.42.572.572 0 00.539-.023l2.338-1.419M27.119 24.709l1 .461a1.9 1.9 0 011.135 1.7v2.719a.473.473 0 01-.277.42.572.572 0 01-.539-.023L26.1 28.567"></path>
                <path d="M24 21a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" clipRule="evenodd"></path>
                <path d="M35 16.25h-1.75a.955.955 0 00-.894 1.006c-.01.394.209.759.562.934l1.77.619c.353.176.572.54.562.934a.955.955 0 01-.894 1.007H32.25M33.75 16.25v-1M33.75 21.75v-1M15.5 13.75h-1.75a.955.955 0 00-.894 1.006c-.01.394.209.759.562.934l1.77.619c.353.176.572.54.562.934a.955.955 0 01-.894 1.007H12.75M14.25 13.75v-1M14.25 19.25v-1"></path>
            </g>
        </svg>
    )
}

export default Icon
