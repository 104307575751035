import styled from 'styled-components'

import { media } from '@variables'
import { transition } from '@variables'

export const Button = styled.button<{ readonly position: 'relative' | 'absolute' }>`
    z-index: 1;
    border: none;
    outline: none;
    font-size: 14px;
    cursor: pointer;
    font-weight: 700;
    line-height: 20px;
    display: inline-flex;
    align-items: center;
    background-color: transparent;
    transition: ${transition.main};

    ${(props) =>
        props.position === 'absolute' &&
        `
        position: absolute;
        top: 35px;
        left: 35px;
    `}

    svg {
        left: 0;
        font-size: 1rem;
        padding-right: 5px;
        position: relative;
    }

    ${media.tablet} {
        :hover {
            svg {
                left: -5px;
            }
        }
    }
`
