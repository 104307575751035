import React from 'react'
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

import Icon from '../SVG/Coins'
import * as loco from '@loco'
import { Wrapper, Button } from './styled'
import { getRouteWithId } from '@utils'
import { TestPreviewVariant } from '../shared'
import { colors, Routes } from '@variables'

type Props = {
    testId: string
    maxPoints: number
    acquiredPoints: number
    variant: TestPreviewVariant
}

const TestPreview = ({ testId, maxPoints, acquiredPoints, variant }: Props) => {
    const history = useHistory()

    const goToTest = () => {
        if (variant === TestPreviewVariant.NOT_ALLOW) return
        return history.push(getRouteWithId(Routes.TEST, testId))
    }

    return (
        <Wrapper variant={variant}>
            <div style={{ width: '100%' }}>
                <Typography variant="h6" align="center" style={{ marginBottom: 10 }}>
                    {loco.testPreview.title}
                </Typography>
                <Typography paragraph gutterBottom align="center">
                    {getText(variant)}
                </Typography>
            </div>

            <div>
                <Typography paragraph align="center" style={{ fontWeight: 600, marginBottom: 10 }}>
                    {loco.chapter.test.rewards}
                </Typography>
                <Typography paragraph style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <Icon
                        style={{ marginRight: 10 }}
                        color={
                            Boolean(acquiredPoints) ? colors.primary.orange : colors.primary.gray2
                        }
                    />
                    {acquiredPoints}/{maxPoints} {loco.common.points}
                </Typography>
            </div>

            <Button
                fullWidth
                size="large"
                variant="contained"
                onClick={goToTest}
                disabled={variant === TestPreviewVariant.NOT_ALLOW}
                color={variant === TestPreviewVariant.REPEAT ? 'secondary' : 'primary'}
            >
                {variant === TestPreviewVariant.REPEAT
                    ? loco.lecture.repeat
                    : loco.chapter.test.button}
            </Button>
        </Wrapper>
    )
}

const getText = (variant: TestPreviewVariant) => {
    if (variant === TestPreviewVariant.NOT_ALLOW) {
        return loco.chapter.test.subtitle
    }
    if (variant === TestPreviewVariant.REPEAT) {
        return loco.chapter.test.subtitle2
    }

    return loco.chapter.test.subtitle3
}

export default TestPreview
