import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import { media } from '@variables'
import { DialogContent } from '../../shared/styled'

export const Description = styled(Typography)``

export const Wrapper = styled(DialogContent)`
    ${Description} {
        width: 100%;
        line-height: normal;
        padding: 20px 0 35px 0;
    }

    b {
        display: block;
        padding-top: 5px;
    }

    button {
        margin-bottom: 35px;

        ${media.tablet} {
            margin-bottom: 50px;
        }
    }
`

export const IconWrapper = styled.div`
    width: 164px;
    height: 160px;

    img {
        width: 100%;
        height: auto;
    }

    svg {
        width: 100%;
        height: 100%;
    }
`
