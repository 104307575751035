import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import { Audio } from '../AudioBox/styled'
import { colors, media, transition } from '@variables'

export const Title = styled(Typography)`
    max-height: 20px;
    overflow: hidden;
`

export const Description = styled(Typography)`
    opacity: 0.5;
    display: block;
    max-height: 32px;
    overflow: hidden;
`

export const Text = styled(Typography)`
    text-transform: uppercase;
    color: ${colors.primary.orange};
`

export const Wrapper = styled(Audio)`
    ${Title} {
        line-height: 20px;
        margin-bottom: 5px;
    }
    ${Description} {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 5px;
    }
    ${Text} {
        right: 10px;
        bottom: 10px;
        font-size: 12px;
        position: absolute;
        transition: ${transition.main};
    }

    ${media.tablet} {
        :hover {
            ${Text} {
                text-decoration: underline;
            }
        }
    }
`
