import React, { useContext, useEffect } from 'react'

import { AuthContext } from './Auth'
import { DIALOG_ID, DialogContext } from './Dialog'
import { useUnacceptedBadgesQuery, UnacceptedBadgesQuery } from '@graphql'

type Props = {
    children: any
}

type Context = {
    loading: boolean
    refetch: () => any
    unacceptedBadges: Array<UnacceptedBadgesQuery['unacceptedBadges'][0]>
}

export const BadgeContext = React.createContext<Context>({
    loading: true,
    refetch: () => {},
    unacceptedBadges: []
})

const BadgeProvider = ({ children }: Props) => {
    const { setOpen } = useContext(DialogContext)
    const { isAuthorized } = useContext(AuthContext)

    const { data, loading, refetch } = useUnacceptedBadgesQuery({
        skip: !isAuthorized,
        fetchPolicy: 'network-only'
    })

    useEffect(() => {
        if (data?.unacceptedBadges?.length || 0 > 0) {
            setOpen(DIALOG_ID.BADGE)
        }
    }, [data])

    const unacceptedBadges: Array<UnacceptedBadgesQuery['unacceptedBadges'][0]> =
        data?.unacceptedBadges || []

    return (
        <BadgeContext.Provider
            value={{
                loading,
                refetch,
                unacceptedBadges
            }}
        >
            {children}
        </BadgeContext.Provider>
    )
}

export default BadgeProvider
