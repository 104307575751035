import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { string, object, ref } from 'yup'
import { VisibilityOff, Visibility } from '@material-ui/icons'
import { Button, IconButton, InputAdornment } from '@material-ui/core'
import { Formik, Form, Field, FormikProps, FormikHelpers } from 'formik'

import { Step } from '..'
import * as loco from '@loco'
import InputField from '../../../Fields/Input'
import { notificationMsgs } from '@variables'
import { translateGQLError } from '@utils'
import { Wrapper, H3, ButtonWrapper } from '../styled'
import { MeDocument, ChaptersDocument, useResetPasswordWithEmailTokenMutation } from '@graphql'

type Props = {
    token: string
    setActiveStep: (step: Step) => void
}

const initialValues = Object.freeze({ password: '', confirmPassword: '' })

type FormValues = typeof initialValues

const SetNewPassword = ({ token, setActiveStep }: Props) => {
    const { enqueueSnackbar } = useSnackbar()

    // ======================= SHOW/HIDE PASSWORD LOGIC =======================>
    const [showPassword, setShowPassword] = useState(false)
    const toggleShowPwd = () => setShowPassword(!showPassword)

    const [resetPassword, { loading }] = useResetPasswordWithEmailTokenMutation()

    const submit = async (
        { confirmPassword }: FormValues,
        { resetForm }: FormikHelpers<FormValues>
    ) => {
        const { data, errors } = await resetPassword({
            refetchQueries: [{ query: MeDocument }, { query: ChaptersDocument }],
            variables: {
                data: {
                    token: token,
                    password: confirmPassword
                }
            }
        })

        if (data && !errors) {
            resetForm()
            setActiveStep(Step.AFTER_NEW_PASSWORD_SETTED)
            enqueueSnackbar(notificationMsgs.passwordReset, { variant: 'success' })
        }

        errors?.forEach((err) =>
            enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
        )
    }

    return (
        <Wrapper>
            <H3>{loco.dialogs['new-password'].title}</H3>

            <Formik
                onSubmit={submit}
                enableReinitialize
                isInitialValid={false}
                initialValues={initialValues}
                validationSchema={validationSchema}
                initialTouched={{ confirmPassword: true }}
            >
                {({ isValid, isSubmitting }: FormikProps<FormValues>) => (
                    <Form style={{ width: '100%' }} spellCheck={false}>
                        <Field
                            fullWidth
                            autoFocus
                            key="password"
                            name="password"
                            component={InputField}
                            className="customInput"
                            autoComplete="new-password"
                            label={loco.dialogs['new-password'].password.label}
                            placeholder={loco.dialogs['new-password'].password.placeholder}
                            // HIDE/SHOW PROPS
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            onClick={toggleShowPwd}
                                            style={{ margin: '0 0 20px 0' }}
                                            aria-label="toggle password visibility"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Field
                            fullWidth
                            key="confirmPassword"
                            name="confirmPassword"
                            component={InputField}
                            className="customInput"
                            autoComplete="new-password"
                            label={loco.dialogs['new-password']['new-password'].label}
                            placeholder={loco.dialogs['new-password']['new-password'].placeholder}
                            // HIDE/SHOW PROPS
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            onClick={toggleShowPwd}
                                            style={{ margin: '0 0 20px 0' }}
                                            aria-label="toggle password visibility"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />

                        <ButtonWrapper>
                            <Button
                                fullWidth
                                size="large"
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={!isValid || loading || isSubmitting}
                            >
                                {loco.dialogs['new-password'].button}
                            </Button>
                        </ButtonWrapper>
                    </Form>
                )}
            </Formik>
        </Wrapper>
    )
}

const validationSchema = object({
    password: string()
        .required(loco.validation.password.common)
        .matches(
            new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$'),
            loco.validation.password.match
        ),
    confirmPassword: string()
        .oneOf([ref('password'), null], loco.validation.password.confirm)
        .required(loco.validation.password.common)
})

export default SetNewPassword
