import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="260"
            height="200"
            fill="none"
            viewBox="0 0 260 200"
            {...props}
        >
            <ellipse cx="161.284" cy="65.216" fill="#FFCA30" rx="24.284" ry="24.215"></ellipse>
            <mask id="mask0" width="49" height="49" x="137" y="41" maskUnits="userSpaceOnUse">
                <ellipse cx="161.284" cy="65.216" fill="#C4C4C4" rx="24.284" ry="24.215"></ellipse>
            </mask>
            <g filter="url(#filter0_d)" mask="url(#mask0)">
                <path
                    fill="#24AE94"
                    d="M174.579 77.115c.633-.631 1.161-1.893 1.161-2.805V56.32c0-.912-.528-2.175-1.161-2.806l-5.838-5.822c-.633-.63-1.899-1.157-2.813-1.157h-8.159c-.914 0-2.181.526-2.814 1.157l-5.837 5.822c-.633.631-1.161 1.894-1.161 2.805v17.99c0 .913.528 2.175 1.161 2.806l5.837 5.822c.633.63 1.9 1.157 2.814 1.157h8.194c.914 0 2.18-.526 2.813-1.157l5.803-5.822z"
                ></path>
                <path
                    fill="#188989"
                    d="M154.181 73.748V57.161l-5.838-2.49c-.246.561-.422 1.157-.422 1.648v17.99c0 .737.352 1.684.809 2.385l5.451-2.946z"
                ></path>
                <path
                    fill="#3AC1B0"
                    d="M165.963 46.535h-8.194c-.458 0-1.02.14-1.548.35l2.145 5.191.317.947 6.33-.631.282-.316 2.145-5.19c-.457-.21-1.02-.35-1.477-.35z"
                ></path>
                <path
                    fill="#41D9C6"
                    d="M157.769 84.094h8.194c.457 0 1.02-.14 1.547-.35l-2.145-5.19-.282-.316-6.33-.632-.316.947-2.145 5.19c.457.21 1.019.351 1.477.351z"
                ></path>
                <path
                    fill="#24AE94"
                    d="M154.954 47.727l-2.919 2.911-2.919 2.91c-.316.316-.597.807-.808 1.298l5.204 2.14.88.456 4.044-4.945v-.421l-2.145-5.19c-.528.21-1.02.49-1.337.841z"
                ></path>
                <path
                    fill="#3AC1B0"
                    d="M153.512 73.679l-5.204 2.139c.211.49.492.982.808 1.297l2.919 2.911 2.919 2.91c.317.316.809.597 1.302.807l2.145-5.19v-.42l-4.009-4.91-.88.456z"
                ></path>
                <path
                    fill="#60E9CF"
                    d="M174.614 53.549l-5.838-5.822c-.316-.315-.809-.596-1.301-.806l-2.145 5.19v.42l4.044 4.946.879-.456 5.205-2.14c-.246-.56-.528-1.017-.844-1.332z"
                ></path>
                <path
                    fill="#188989"
                    d="M168.741 82.937l5.838-5.822c.317-.315.598-.806.809-1.297l-5.205-2.14-.879-.455-4.009 4.91v.42l2.145 5.19c.528-.175.985-.49 1.301-.806z"
                ></path>
                <path
                    fill="#69FFE3"
                    d="M158.437 78.553l-4.924-4.874V56.986l4.924-4.91h6.857l4.924 4.91v16.693l-4.924 4.874h-6.857z"
                ></path>
                <path
                    fill="#F2FFFD"
                    d="M170.218 57.862l-16.705 12.029v3.787l1.196 1.193 15.509-11.152v-5.857zM170.217 68.979v-2.245l-13.75 9.89 1.301 1.297 12.449-8.942z"
                ></path>
                <path
                    fill="#fff"
                    d="M152.142 57.862c-1.794.561-2.251 1.052-2.814 2.806-.563-1.754-1.055-2.245-2.813-2.806 1.793-.561 2.25-1.052 2.813-2.805.563 1.753 1.02 2.209 2.814 2.805zM154.885 53.163c-.95.316-1.231.561-1.512 1.543-.317-.947-.563-1.227-1.548-1.543.95-.315 1.231-.56 1.548-1.508.316.947.562 1.193 1.512 1.508zM173.911 74.59c-.949.316-1.231.561-1.547 1.543-.317-.947-.563-1.227-1.548-1.543.95-.316 1.231-.561 1.548-1.543.351.982.598 1.227 1.547 1.543z"
                ></path>
            </g>
            <circle cx="28" cy="65" r="28" fill="#FFCA30"></circle>
            <mask id="mask1" width="56" height="56" x="0" y="37" maskUnits="userSpaceOnUse">
                <circle cx="28" cy="65" r="28" fill="#C4C4C4"></circle>
            </mask>
            <g mask="url(#mask1)">
                <g filter="url(#filter1_d)">
                    <path
                        fill="#BF7406"
                        d="M12.676 76.68h-.073a1.072 1.072 0 01-1.09-1.09c0-.619.472-1.092 1.09-1.092h.037c.654 0 1.236-.473 1.309-1.127.073-.582-.291-1.127-.836-1.346-5.274-2.218-6.11-12.946-6.183-14.183l-.073-1.163h10.874v2.182h-8.51c.4 3.49 1.71 9.891 4.728 11.128a3.456 3.456 0 012.182 3.2c0 1.928-1.527 3.491-3.455 3.491zM43.551 76.68h.073c.618 0 1.091-.473 1.091-1.09 0-.619-.473-1.092-1.09-1.092h-.037c-.655 0-1.237-.473-1.31-1.127-.072-.582.292-1.127.837-1.346 5.273-2.218 6.11-12.946 6.182-14.183l.073-1.163H38.497v2.182h8.51c-.4 3.49-1.71 9.891-4.728 11.128a3.456 3.456 0 00-2.182 3.2c0 1.928 1.527 3.491 3.454 3.491zM31.223 88.826h-6.182l1.091-12.473h4l1.091 12.473z"
                    ></path>
                    <path
                        fill="#F3A000"
                        d="M38.496 93.154H17.731c2.291-3.382 6.073-5.419 10.183-5.419h.436c4.073 0 7.892 2 10.146 5.419z"
                    ></path>
                    <path
                        fill="url(#paint0_linear)"
                        d="M28.132 81.116A12.68 12.68 0 0115.44 68.424V54.06h25.42v14.364c-.036 7.019-5.709 12.692-12.728 12.692z"
                    ></path>
                    <path
                        fill="#E48B07"
                        d="M41.551 51.843H14.713c-.618 0-1.09.509-1.09 1.09 0 .619.508 1.092 1.09 1.092h26.838c.619 0 1.091-.51 1.091-1.091 0-.582-.472-1.091-1.09-1.091z"
                    ></path>
                    <path
                        fill="#F3FFDE"
                        d="M37.478 50.861l-.655-.364a3.603 3.603 0 01-1.382-1.382l-.363-.654c-.037-.073-.182-.073-.219 0l-.363.654a3.603 3.603 0 01-1.382 1.382l-.655.364c-.072.036-.072.182 0 .218l.655.364c.582.327 1.055.8 1.382 1.382l.363.654c.037.073.182.073.219 0l.363-.654c.328-.582.8-1.055 1.382-1.382l.655-.364a.114.114 0 000-.218zM22.674 90.633l-.655-.363a3.604 3.604 0 01-1.382-1.382l-.364-.655c-.036-.073-.181-.073-.218 0l-.363.655a3.604 3.604 0 01-1.382 1.382l-.655.363c-.073.037-.073.182 0 .219l.655.363c.582.328 1.054.8 1.382 1.382l.363.655c.037.073.182.073.218 0l.364-.655c.328-.582.8-1.054 1.382-1.382l.655-.363a.114.114 0 000-.219zM40.86 45.879l-.363-.182a1.928 1.928 0 01-.764-.764l-.182-.364c-.036-.036-.11-.036-.11 0l-.18.364a1.928 1.928 0 01-.765.764l-.363.181c-.037.037-.037.11 0 .11l.363.181c.328.182.582.437.764.764l.182.364c.036.036.109.036.109 0l.182-.364c.182-.327.436-.582.764-.764l.363-.181c.073 0 .073-.073 0-.11zM26.056 85.649l-.364-.182a1.928 1.928 0 01-.763-.764l-.182-.363c-.037-.037-.11-.037-.11 0l-.181.363a1.928 1.928 0 01-.764.764l-.364.182c-.036.036-.036.11 0 .11l.364.18c.328.183.582.437.764.765l.182.363c.036.037.109.037.109 0l.182-.363c.181-.328.436-.582.763-.764l.364-.182c.073 0 .073-.073 0-.109z"
                    ></path>
                </g>
                <path
                    stroke="#FFCA30"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.8"
                    d="M32.229 62.295l-4.004 5.338a.754.754 0 01-1.136.08l-2.403-2.4"
                ></path>
            </g>
            <ellipse cx="103.374" cy="113.297" fill="#00AFEC" rx="27.374" ry="27.297"></ellipse>
            <mask id="mask2" width="55" height="55" x="76" y="86" maskUnits="userSpaceOnUse">
                <ellipse cx="103.374" cy="113.297" fill="#C4C4C4" rx="27.374" ry="27.297"></ellipse>
            </mask>
            <g filter="url(#filter2_d)" mask="url(#mask2)">
                <path
                    fill="#0094C7"
                    d="M115.306 82.212l-5.763 14.041H97.616l5.298-14.041h12.392z"
                ></path>
                <path
                    fill="#7D8DA8"
                    d="M110.092 93.833h-12.92c-.844 0-1.54.695-1.54 1.537v8.21h16.001v-8.21c0-.863-.696-1.537-1.541-1.537zm.401 3.01H96.476v-.631c0-.674.549-1.242 1.245-1.242h11.548c.675 0 1.245.547 1.245 1.242v.631h-.021z"
                ></path>
                <path
                    fill="#2CC3F8"
                    d="M92.042 82.212l5.573 14.041h11.928l-5.426-14.041H92.042z"
                ></path>
                <path
                    fill="#fff"
                    d="M99.811 96.253l-5.679-14.041h-.907l5.678 14.041h.908zM102.597 96.253l-5.678-14.041h-.908l5.679 14.041h.907zM105.279 96.253L99.6 82.212h-.908l5.679 14.041h.908zM108.15 96.253l-5.658-14.041h-.908l5.658 14.041h.908z"
                ></path>
                <path
                    fill="#9BAAC5"
                    d="M120.486 118.089c1.245-9.476-5.449-18.164-14.951-19.405-9.502-1.242-18.215 5.433-19.46 14.909-1.245 9.476 5.45 18.163 14.952 19.405 9.502 1.241 18.214-5.434 19.459-14.909z"
                ></path>
                <path
                    fill="#BFCBE0"
                    d="M117.305 117.673c1.014-7.723-4.442-14.805-12.187-15.817-7.745-1.011-14.846 4.429-15.861 12.153-1.015 7.723 4.441 14.804 12.186 15.816 7.746 1.012 14.847-4.429 15.862-12.152z"
                ></path>
                <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="4.106"
                    d="M108.134 112.294l-5.077 6.75a.958.958 0 01-1.441.101l-3.046-3.036"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M101.563 125.641c.549.442.697 1.158.697 1.158s-.739.021-1.309-.421c-.549-.442-.697-1.158-.697-1.158s.76-.021 1.309.421z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M100.888 126.777c.676-.231 1.372.021 1.372.021s-.38.632-1.055.864c-.676.231-1.373-.021-1.373-.021s.402-.632 1.056-.864zM99.41 125.05c.486.506.528 1.242.528 1.242s-.739-.084-1.224-.589c-.486-.505-.528-1.242-.528-1.242s.739.084 1.224.589z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M98.586 126.082c.697-.147 1.352.211 1.352.211s-.465.568-1.162.716c-.696.147-1.35-.211-1.35-.211s.464-.568 1.16-.716zM97.7 124.23c.402.568.317 1.305.317 1.305s-.718-.189-1.119-.779c-.4-.568-.316-1.305-.316-1.305s.717.19 1.119.779z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M96.729 125.114c.717-.021 1.308.421 1.308.421s-.549.505-1.266.526c-.718.021-1.31-.421-1.31-.421s.57-.505 1.268-.526zM96.117 122.945c.253.653 0 1.347 0 1.347s-.654-.358-.887-1.01c-.253-.653 0-1.347 0-1.347s.655.357.887 1.01z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M94.956 123.577c.696.147 1.16.715 1.16.715s-.654.358-1.35.211c-.697-.148-1.162-.716-1.162-.716s.655-.358 1.352-.21zM94.913 121.472c.085.695-.337 1.306-.337 1.306s-.55-.506-.634-1.2c-.084-.695.338-1.306.338-1.306s.549.506.633 1.2z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M93.626 121.809c.633.294.95.968.95.968s-.718.19-1.373-.105c-.633-.295-.95-.968-.95-.968s.74-.19 1.373.105zM93.9 119.601c-.064.694-.591 1.22-.591 1.22s-.422-.61-.38-1.284c.063-.694.59-1.221.59-1.221s.444.59.38 1.285z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M92.57 119.684c.57.421.739 1.137.739 1.137s-.739.042-1.309-.379-.739-1.137-.739-1.137.74-.042 1.31.379zM93.372 117.831c-.17.674-.781 1.116-.781 1.116s-.338-.653-.17-1.326c.17-.674.782-1.116.782-1.116s.338.652.169 1.326z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M92.042 117.705c.486.505.55 1.242.55 1.242s-.74-.084-1.246-.568c-.486-.505-.55-1.242-.55-1.242s.76.063 1.246.568zM93.31 115.853c-.339.61-1.035.884-1.035.884s-.148-.716.169-1.326c.316-.611 1.034-.885 1.034-.885s.169.716-.169 1.327z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M92.064 115.41c.359.611.232 1.327.232 1.327s-.696-.253-1.055-.842c-.36-.611-.233-1.327-.233-1.327s.697.253 1.056.842zM93.668 113.852c-.464.526-1.203.631-1.203.631s.021-.736.485-1.263c.465-.526 1.204-.631 1.204-.631s-.021.737-.486 1.263z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M92.549 113.137c.21.674-.106 1.347-.106 1.347s-.633-.4-.823-1.073c-.211-.674.105-1.348.105-1.348s.613.422.824 1.074zM94.386 112.209c-.57.4-1.33.337-1.33.337s.19-.716.76-1.116c.57-.4 1.33-.337 1.33-.337s-.19.716-.76 1.116z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M93.478 111.263c.042.695-.4 1.284-.4 1.284s-.508-.526-.55-1.221c-.042-.695.401-1.284.401-1.284s.507.526.55 1.221zM95.294 110.715c-.634.315-1.373.126-1.373.126s.296-.674.93-.989c.633-.316 1.371-.127 1.371-.127s-.295.695-.928.99z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M94.533 109.642c-.063.695-.612 1.2-.612 1.2s-.422-.61-.359-1.305c.064-.695.612-1.2.612-1.2s.444.631.36 1.305zM105.405 125.558c-.549.442-.697 1.158-.697 1.158s.739.021 1.309-.421c.549-.442.697-1.158.697-1.158s-.76-.021-1.309.421z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M106.081 126.673c-.676-.232-1.373.021-1.373.021s.38.632 1.056.863c.676.232 1.372-.021 1.372-.021s-.401-.632-1.055-.863zM107.285 125.051c-.485.506-.528 1.242-.528 1.242s.739-.084 1.225-.589c.485-.505.527-1.242.527-1.242s-.738.084-1.224.589z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M108.108 126.082c-.696-.147-1.351.211-1.351.211s.465.568 1.161.716c.697.147 1.351-.211 1.351-.211s-.464-.568-1.161-.716zM108.995 124.146c-.401.568-.316 1.305-.316 1.305s.718-.189 1.119-.779c.401-.568.316-1.305.316-1.305s-.717.211-1.119.779z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M109.966 125.03c-.718-.021-1.309.421-1.309.421s.549.505 1.267.526c.718.021 1.309-.421 1.309-.421s-.549-.484-1.267-.526zM110.515 122.904c-.253.653 0 1.347 0 1.347s.654-.358.887-1.01c.253-.653 0-1.347 0-1.347s-.634.357-.887 1.01z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M111.697 123.536c-.697.147-1.161.715-1.161.715s.654.358 1.351.211c.696-.148 1.161-.716 1.161-.716s-.655-.358-1.351-.21zM111.782 121.325c-.085.695.337 1.305.337 1.305s.549-.505.634-1.2c.084-.695-.338-1.305-.338-1.305s-.549.505-.633 1.2z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M113.07 121.662c-.634.295-.95.969-.95.969s.717.189 1.372-.106c.633-.294.95-.968.95-.968s-.718-.189-1.372.105zM112.689 119.578c.063.695.591 1.221.591 1.221s.422-.61.38-1.284c-.063-.695-.591-1.221-.591-1.221s-.422.589-.38 1.284z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M114.018 119.661c-.57.421-.739 1.137-.739 1.137s.739.042 1.309-.379.739-1.137.739-1.137-.739-.042-1.309.379zM113.216 117.788c.169.674.781 1.116.781 1.116s.338-.653.169-1.326c-.169-.674-.781-1.116-.781-1.116s-.338.652-.169 1.326z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M114.547 117.662c-.486.505-.549 1.242-.549 1.242s.739-.084 1.246-.568c.506-.484.548-1.242.548-1.242s-.738.084-1.245.568zM113.28 115.872c.338.611 1.034.884 1.034.884s.148-.715-.169-1.326c-.337-.61-1.034-.884-1.034-.884s-.169.716.169 1.326z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M114.525 115.451c-.358.611-.232 1.327-.232 1.327s.697-.253 1.056-.842c.359-.611.232-1.327.232-1.327s-.697.232-1.056.842zM112.9 113.872c.465.527 1.203.632 1.203.632s-.021-.737-.485-1.263c-.465-.526-1.203-.632-1.203-.632s.021.758.485 1.263z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M114.018 113.157c-.211.673.106 1.347.106 1.347s.633-.4.823-1.074c.211-.673-.105-1.347-.105-1.347s-.613.421-.824 1.074zM112.203 112.274c.57.4 1.33.336 1.33.336s-.19-.715-.76-1.115c-.57-.4-1.33-.337-1.33-.337s.169.716.76 1.116z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M113.111 111.325c-.042.695.401 1.285.401 1.285s.507-.527.549-1.221c.042-.695-.401-1.285-.401-1.285s-.507.527-.549 1.221zM111.232 110.8c.633.315 1.372.126 1.372.126s-.295-.674-.929-.989c-.633-.316-1.372-.127-1.372-.127s.296.674.929.99z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M111.992 109.726c.063.695.612 1.2.612 1.2s.422-.61.359-1.305-.612-1.2-.612-1.2-.423.61-.359 1.305z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M110.725 108.673c-.021.021-.063.063-.105.084-.021 0-.021.021-.042.021 2.216 1.895 3.609 4.61 3.609 7.642 0 5.684-4.897 10.294-10.935 10.294-6.037 0-10.935-4.61-10.935-10.294 0-2.905 1.288-5.516 3.335-7.389-.063-.021-.126-.042-.169-.063-2.047 1.894-3.335 4.547-3.335 7.452 0 5.768 4.982 10.462 11.104 10.462 6.122 0 11.104-4.694 11.104-10.462.043-3.074-1.372-5.831-3.631-7.747z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M96.772 109.411c-.697.147-1.352-.19-1.352-.19s.465-.589 1.162-.736c.696-.148 1.35.189 1.35.189s-.464.589-1.16.737z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M96.307 108.189c-.232.653-.887 1.032-.887 1.032s-.253-.695-.02-1.348c.231-.652.886-1.031.886-1.031s.253.694.021 1.347zM109.839 109.411c.696.147 1.351-.19 1.351-.19s-.465-.589-1.161-.736c-.697-.148-1.351.189-1.351.189s.464.589 1.161.737z"
                ></path>
                <path
                    fill="#9BAAC4"
                    d="M110.304 108.189c.232.653.886 1.032.886 1.032s.254-.695.021-1.348c-.232-.652-.886-1.031-.886-1.031s-.254.694-.021 1.347z"
                ></path>
            </g>
            <ellipse cx="184.87" cy="161.088" fill="#FFA030" rx="39.022" ry="38.912"></ellipse>
            <mask id="mask3" width="79" height="79" x="145" y="122" maskUnits="userSpaceOnUse">
                <ellipse cx="184.87" cy="161.088" fill="#C4C4C4" rx="39.022" ry="38.912"></ellipse>
            </mask>
            <g mask="url(#mask3)">
                <path
                    fill="url(#paint1_linear)"
                    d="M168.131 140.859a209.917 209.917 0 010 39.792h33.543c-1.207-13.203-1.207-26.291 0-39.242l-33.543-.55z"
                ></path>
                <path
                    fill="url(#paint2_radial)"
                    d="M168.131 140.859a209.917 209.917 0 010 39.792h33.543c-1.207-13.203-1.207-26.291 0-39.242l-33.543-.55z"
                ></path>
                <path
                    fill="#FFA030"
                    d="M171.651 147.587H198.40900000000002V148.85799999999998H171.651z"
                    opacity="0.5"
                ></path>
                <path
                    fill="#FFA030"
                    d="M171.65 150.764H181.525V152.035H171.65z"
                    opacity="0.5"
                ></path>
                <path
                    fill="#FFA030"
                    d="M171.65 153.94H182.481V155.21099999999998H171.65z"
                    opacity="0.5"
                ></path>
                <path
                    fill="#FFA030"
                    d="M182.162 150.764H198.40800000000002V152.035H182.162z"
                    opacity="0.5"
                ></path>
                <path
                    fill="#FFA030"
                    d="M193.948 153.94H198.40800000000002V155.21099999999998H193.948z"
                    opacity="0.5"
                ></path>
                <path
                    fill="#FFA030"
                    d="M183.118 153.94H193.31199999999998V155.21099999999998H183.118z"
                    opacity="0.5"
                ></path>
                <path
                    fill="#FFA030"
                    d="M171.65 157.117H198.40800000000002V158.38799999999998H171.65z"
                    opacity="0.5"
                ></path>
                <path
                    fill="#FFA030"
                    d="M171.65 160.293H181.525V161.564H171.65z"
                    opacity="0.5"
                ></path>
                <path
                    fill="#FFA030"
                    d="M171.65 163.47H182.481V164.74099999999999H171.65z"
                    opacity="0.5"
                ></path>
                <path
                    fill="#FFA030"
                    d="M182.162 160.293H198.40800000000002V161.564H182.162z"
                    opacity="0.5"
                ></path>
                <path
                    fill="#FFA030"
                    d="M193.948 163.47H198.40800000000002V164.74099999999999H193.948z"
                    opacity="0.5"
                ></path>
                <path
                    fill="#FFA030"
                    d="M183.118 163.47H193.31199999999998V164.74099999999999H183.118z"
                    opacity="0.5"
                ></path>
                <path
                    fill="url(#paint3_linear)"
                    d="M207.343 138.366c-1.338-.274-1.443 1.213-1.889 1.213-.447 0-.683-.389-1.286-1.533-.63-1.121-2.021-.824-2.021-.824l-1.129.664.866 2.585-.866 2.586 1.129.664s1.391.32 2.021-.824c.63-1.121.839-1.533 1.286-1.533.446 0 .525 1.487 1.889 1.213 1.339-.275 2.546-1.533 2.599-2.106-.053-.572-1.26-1.83-2.599-2.105z"
                ></path>
                <path
                    fill="url(#paint4_linear)"
                    d="M162.463 138.32c1.338-.274 1.443 1.213 1.889 1.213.447 0 .683-.389 1.286-1.533.63-1.121 2.021-.824 2.021-.824l1.129.664-.866 2.586.866 2.585-1.129.664s-1.391.32-2.021-.824c-.629-1.121-.839-1.533-1.286-1.533-.446 0-.525 1.487-1.889 1.213-1.339-.275-2.546-1.533-2.599-2.105.053-.572 1.26-1.831 2.599-2.106z"
                ></path>
                <path
                    fill="url(#paint5_linear)"
                    d="M202.356 144.109c-11.417-.503-22.991-.48-34.697 0-1.811-.869-1.995-5.903 0-7.322 11.522.343 23.097.32 34.697 0 1.759 1.327 2.231 6.567 0 7.322z"
                ></path>
                <path
                    fill="url(#paint6_linear)"
                    d="M202.356 144.109c-11.417-.503-22.991-.48-34.697 0-1.811-.869-1.995-5.903 0-7.322 11.522.343 23.097.32 34.697 0 1.759 1.327 2.231 6.567 0 7.322z"
                ></path>
                <path
                    fill="url(#paint7_linear)"
                    d="M207.343 179.667c-1.338-.274-1.443 1.213-1.889 1.213-.447 0-.683-.389-1.286-1.533-.63-1.121-2.021-.824-2.021-.824l-1.129.664.866 2.585-.866 2.586 1.129.663s1.391.321 2.021-.823c.63-1.121.839-1.533 1.286-1.533.446 0 .525 1.487 1.889 1.212 1.339-.274 2.546-1.533 2.599-2.105-.053-.595-1.26-1.83-2.599-2.105z"
                ></path>
                <path
                    fill="url(#paint8_linear)"
                    d="M162.463 179.621c1.338-.274 1.443 1.213 1.889 1.213.447 0 .683-.389 1.286-1.533.63-1.121 2.021-.824 2.021-.824l1.129.664-.866 2.585.866 2.586-1.129.664s-1.391.32-2.021-.824c-.629-1.144-.839-1.533-1.286-1.533-.446 0-.525 1.487-1.889 1.212-1.339-.274-2.546-1.533-2.599-2.105.053-.595 1.26-1.83 2.599-2.105z"
                ></path>
                <path
                    fill="url(#paint9_linear)"
                    d="M202.357 185.388c-11.417-.504-22.991-.481-34.697 0-1.811-.87-1.995-5.904 0-7.323 11.522.344 23.097.321 34.697 0 1.759 1.328 2.231 6.59 0 7.323z"
                ></path>
                <path
                    fill="url(#paint10_linear)"
                    d="M202.357 185.388c-11.417-.504-22.991-.481-34.697 0-1.811-.87-1.995-5.904 0-7.323 11.522.344 23.097.321 34.697 0 1.759 1.328 2.231 6.59 0 7.323z"
                ></path>
                <g filter="url(#filter3_d)">
                    <path
                        fill="#FFCA30"
                        fillRule="evenodd"
                        d="M201.049 167.55a1.575 1.575 0 012.454 0l1.461 1.812c.336.418.863.636 1.397.578l2.319-.251a1.569 1.569 0 011.739 1.734l-.252 2.313a1.567 1.567 0 00.58 1.393l1.817 1.457a1.568 1.568 0 010 2.447l-1.823 1.45a1.573 1.573 0 00-.58 1.394l.252 2.313a1.574 1.574 0 01-1.739 1.734l-2.319-.251a1.575 1.575 0 00-1.397.578l-1.455 1.806a1.578 1.578 0 01-2.454 0l-1.46-1.812a1.578 1.578 0 00-1.399-.578l-2.318.252a1.57 1.57 0 01-1.74-1.735l.252-2.312a1.57 1.57 0 00-.579-1.395l-1.817-1.455a1.568 1.568 0 010-2.448l1.817-1.457c.419-.335.638-.86.579-1.393l-.252-2.312a1.567 1.567 0 01.452-1.285 1.577 1.577 0 011.288-.45l2.318.252a1.583 1.583 0 001.399-.578l1.46-1.801z"
                        clipRule="evenodd"
                    ></path>
                    <path
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2.959"
                        d="M205.9 175.636l-3.847 5.114a.724.724 0 01-1.092.077l-2.308-2.301"
                    ></path>
                </g>
            </g>
            <ellipse cx="233" cy="86.063" fill="#04AE1C" rx="27" ry="27.063"></ellipse>
            <mask id="mask4" width="54" height="55" x="206" y="59" maskUnits="userSpaceOnUse">
                <ellipse cx="233" cy="86.063" fill="#04AE1C" rx="27" ry="27.063"></ellipse>
            </mask>
            <g mask="url(#mask4)">
                <path
                    fill="#FFB10F"
                    d="M242.984 80.297s1.433 6.274 0 10.383c-1.433 4.11-4.827 7.71-4.827 7.71h-4.298c-.022 0-3.086-13.056 9.125-18.093z"
                ></path>
                <path
                    fill="#F7A006"
                    d="M233.837 98.39h4.298c-2.402-6.98 4.827-18.093 4.827-18.093-12.189 5.037-9.125 18.093-9.125 18.093z"
                ></path>
                <path
                    fill="#FFB10F"
                    d="M222.884 80.297s-1.433 6.274 0 10.383c1.432 4.11 4.827 7.71 4.827 7.71h4.298s3.063-13.056-9.125-18.093z"
                ></path>
                <path
                    fill="#F7A006"
                    d="M222.882 80.297s8.353 11.112 4.827 18.093h4.298s3.063-13.056-9.125-18.093z"
                ></path>
                <path
                    fill="#FFDC01"
                    d="M238.355 91.586s-1.234-11.09-5.444-16.348c-4.209 5.236-5.444 16.348-5.444 16.348-2.468-5.235-11.99-7.909-11.99-7.909 2.557 3.69 5.709 14.802 5.709 14.802h23.429s3.152-11.09 5.709-14.802c.022 0-9.5 2.674-11.969 7.91z"
                ></path>
                <path
                    fill="#FFB703"
                    d="M231.854 76.807s4.54 1.723 6.502 14.78c0 0-1.234-11.09-5.444-16.349a16.234 16.234 0 00-1.058 1.569z"
                ></path>
                <path
                    fill="#FFDC01"
                    d="M227.908 97.505l-9.39-10.206c-.595-.641.375-1.547.97-.906.022.022-.331-.442 8.42 11.112zM238.929 97.507l7.714-11.046c.507-.73 1.587.044 1.08.75-.022.023.441-.507-8.794 10.296zM232.933 97.197l-.661-18.27c-.022-.84 1.301-.906 1.323-.044 0 .044.022-.707-.662 18.314z"
                ></path>
                <path
                    fill="url(#paint11_linear)"
                    d="M244.637 98.478h-23.429c-.948.95-1.565 1.503-2.513 2.453h28.455c-.948-.95-1.565-1.502-2.513-2.453z"
                ></path>
                <path
                    fill="#FFDC01"
                    d="M235.273 74.982c-1.245 1.32-3.345 1.391-4.661.107-1.317-1.248-1.388-3.352-.107-4.672 1.245-1.32 3.345-1.391 4.662-.107 1.281 1.248 1.352 3.353.106 4.672z"
                ></path>
                <path
                    fill="#FFB703"
                    d="M235.949 71.56c-.782 3.138-5.159 3.388-6.263.356-.284 1.106.036 2.354.926 3.175 1.316 1.248 3.416 1.212 4.661-.107a3.309 3.309 0 00.676-3.424z"
                ></path>
                <path
                    fill="#FFF876"
                    d="M234.775 71.027c-2.135-1.248-3.274-.071-4.271.714 1.21-2.105 3.274-1.927 4.271-.714z"
                ></path>
            </g>
            <ellipse cx="104.854" cy="20.278" fill="#00AFEC" rx="20.335" ry="20.278"></ellipse>
            <mask id="mask5" width="42" height="41" x="84" y="0" maskUnits="userSpaceOnUse">
                <ellipse cx="104.854" cy="20.278" fill="#C4C4C4" rx="20.335" ry="20.278"></ellipse>
            </mask>
            <g mask="url(#mask5)">
                <path
                    fill="#57C14F"
                    d="M104.782 29.998c.454-.68 1.25-8.553 10.962-8.61L105.18 32.774c-.455-1.19-.398-2.775-.398-2.775z"
                ></path>
                <path
                    fill="#8AE383"
                    d="M115.801 21.389s1.534 9.572-8.633 12.573c-1.079.34-1.647-.34-1.931-1.19l10.564-11.383z"
                ></path>
                <path fill="#77DD6F" d="M104.838 14.707h-1.079v25.997h1.079V14.707z"></path>
                <path fill="#57C14F" d="M105.86 14.706h-1.079v25.997h1.079V14.706z"></path>
                <path
                    fill="#FFCA00"
                    d="M96.432 14.706c0 4.587 3.749 8.326 8.349 8.326V6.38c-4.6 0-8.349 3.738-8.349 8.326zM104.782 6.38v16.652c4.6 0 8.349-3.739 8.349-8.326a8.315 8.315 0 00-8.349-8.326z"
                ></path>
                <path
                    fill="#FF8D23"
                    d="M97.853 14.706c0 3.851 3.123 6.91 6.929 6.91V7.739c-3.806.057-6.93 3.172-6.93 6.967z"
                ></path>
                <path
                    fill="#FF8D23"
                    d="M104.782 7.796v13.876a6.911 6.911 0 006.93-6.91c.056-3.851-3.068-6.966-6.93-6.966z"
                ></path>
                <path
                    fill="#FFCA00"
                    d="M102.055 12.723c0-1.585.966-2.095 2.215-2.152v-.963h1.079v.963c.796.057 1.364.17 1.932.34v1.416a38.852 38.852 0 00-2.443-.113c-.624 0-.965.056-.965.68v.283c0 .453.227.68.795.68h.909c1.647 0 1.988 1.359 1.988 2.208v.453c0 1.87-1.136 2.322-2.216 2.322v.907h-1.079v-.907c-.908 0-1.249-.113-1.931-.34v-1.415c.455.056 1.534.113 2.556.113.454 0 .852-.113.852-.567v-.34c0-.396-.114-.622-.682-.622h-.851c-1.988 0-2.159-1.643-2.159-2.21v-.736z"
                ></path>
                <path fill="#fff" d="M96.092 31.981l.511 3.625h8.179v-3.625h-8.69z"></path>
                <path fill="#E8E8E8" d="M113.529 31.98h-8.747v3.625h8.236l.511-3.624z"></path>
                <path
                    fill="#57C14F"
                    d="M105.748 29.998c-.455-.68-1.25-8.553-10.962-8.61l10.564 11.385c.454-1.19.398-2.775.398-2.775z"
                ></path>
                <path
                    fill="#8AE383"
                    d="M94.728 21.389s-1.533 9.572 8.634 12.573c1.079.34 1.647-.34 1.931-1.19L94.728 21.39z"
                ></path>
                <path fill="#FF4140" d="M96.092 31.981l1.76 12.8h6.93v-12.8h-8.69z"></path>
                <path fill="#FF4140" d="M113.529 31.98h-8.747v12.8h6.986l1.761-12.8z"></path>
                <path
                    fill="#8AE383"
                    d="M107.615 6.718a5.894 5.894 0 005.907 5.89V.886c-3.294-.057-5.907 2.605-5.907 5.833zM113.522.827v11.724c3.238 0 5.907-2.605 5.907-5.89-.056-3.172-2.669-5.834-5.907-5.834z"
                ></path>
                <path
                    fill="#fff"
                    d="M110.114 5.019c0-.624.284-1.02 1.023-1.02h.738c.738 0 1.022.396 1.022 1.02v.85c0 .622-.284 1.019-1.022 1.019h-.738c-.739 0-1.023-.397-1.023-1.02v-.85zm1.193.906h.398c.227 0 .284-.114.284-.283v-.397c0-.226-.114-.283-.284-.283h-.398c-.17 0-.284.057-.284.283v.397c0 .17 0 .283.284.283zm3.408-1.926h.909l-3.295 5.38h-.965L114.715 4zm-.625 3.568c0-.623.284-1.02 1.022-1.02h.739c.738 0 1.022.397 1.022 1.02v.85c0 .623-.284 1.02-1.022 1.02h-.739c-.738 0-1.022-.397-1.022-1.02v-.85zm1.25.906h.397c.227 0 .284-.113.284-.283v-.396c0-.227-.113-.283-.284-.283h-.397c-.171 0-.284.056-.284.283v.396c0 .17 0 .283.284.283z"
                ></path>
            </g>
            <ellipse cx="63.076" cy="167.025" fill="#FF5B7C" rx="18.076" ry="18.025"></ellipse>
            <mask id="mask6" width="37" height="37" x="45" y="149" maskUnits="userSpaceOnUse">
                <ellipse cx="63.076" cy="167.025" fill="#00AFEC" rx="18.076" ry="18.025"></ellipse>
            </mask>
            <g filter="url(#filter4_d)" mask="url(#mask6)">
                <path
                    fill="#EAC3A4"
                    d="M62.18 168.048c.74.138 1.432-.368 1.617-1.198.185-.783-.277-1.566-1.016-1.704l-6.792-1.521c-.74-.138-1.432.369-1.617 1.198-.185.783.277 1.567 1.016 1.705l6.792 1.52z"
                ></path>
                <path
                    fill="#EAC3A4"
                    d="M61.025 170.858c.832.185 1.617-.368 1.802-1.151.185-.784-.37-1.613-1.201-1.751l-7.116-1.613c-.831-.184-1.617.369-1.801 1.152-.185.783.37 1.613 1.2 1.751l7.116 1.612z"
                ></path>
                <path
                    fill="#EAC3A4"
                    d="M60.84 173.9c.74.138 1.479-.369 1.663-1.198a1.477 1.477 0 00-1.062-1.751l-6.838-1.566c-.74-.138-1.479.369-1.663 1.198a1.477 1.477 0 001.062 1.751l6.838 1.566zM61.256 176.894c.6.138 1.248-.369 1.386-1.106.139-.737-.23-1.474-.832-1.566l-6.422-1.521c-.6-.138-1.247.369-1.386 1.106-.138.737.231 1.475.832 1.567l6.422 1.52zM79.922 178.645l-4.528-.414s-2.217.921-2.957.967c-1.802.092-7.069-1.382-7.069-1.382l-.323-11.749 11.458 2.857 3.28.23.14 9.491z"
                ></path>
                <path
                    fill="#2D4375"
                    fillRule="evenodd"
                    d="M69.157 153.12c1.017 0 1.848.783 1.848 1.751l.047 24.557c0 .967-.832 1.75-1.849 1.75H63.29v.001h-5.96c-1.016 0-1.848-.784-1.848-1.751l-.046-24.557c0-.967.832-1.75 1.848-1.75h5.914v-.001h5.96z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#0095EB"
                    d="M70.266 156.622c0-.369-.323-.691-.74-.691h-6.283l.046 20.917h6.33c.416 0 .74-.323.74-.691l-.093-19.535z"
                ></path>
                <path
                    fill="#0095EB"
                    d="M56.914 155.932c-.416 0-.74.322-.74.691l.047 19.535c0 .368.323.691.74.691h6.329l-.046-20.917h-6.33z"
                ></path>
                <path
                    fill="#1E2D4F"
                    d="M62.412 178.047c-.139 0-.277.046-.416.092-.37.138-.647.507-.647.921 0 .553.462.968 1.063.968h.831v-1.981h-.831zM64.814 178.322c-.185-.184-.462-.276-.74-.276h-.83v1.981h.83c.14 0 .278-.046.417-.092.37-.138.647-.507.647-.922a.89.89 0 00-.324-.691z"
                ></path>
                <path
                    fill="#1E2D4F"
                    fillRule="evenodd"
                    d="M63.243 154.351h1.71c.23 0 .415.184.415.414 0 .185-.184.369-.415.369h-3.31c-.23 0-.416-.138-.416-.368 0-.185.185-.369.416-.369l1.6-.043v-.003z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#54DF67"
                    d="M83.572 168.186l-.878-.046-3.788-.046-.185 6.173 4.805.231.046-6.312z"
                ></path>
                <path
                    fill="#43B83A"
                    d="M83.526 174.498l-4.805-.23-.139 5.114-.046 1.382 4.99.23v-6.496z"
                ></path>
                <path
                    fill="#CECECE"
                    d="M83.526 174.498l-4.805-.23-.139 5.114-.046 1.382 4.99.23v-6.496z"
                ></path>
                <ellipse cx="63.266" cy="166.456" fill="#FFCA30" rx="6.077" ry="6.136"></ellipse>
                <path
                    fill="#FF8D23"
                    d="M58.3 166.272c0 2.718 2.17 4.93 4.943 4.976l.046-9.86a4.885 4.885 0 00-4.99 4.884z"
                ></path>
                <path
                    fill="#FF8D23"
                    d="M63.243 161.389l-.046 9.859a4.982 4.982 0 004.99-4.93 4.913 4.913 0 00-4.944-4.929z"
                ></path>
                <path
                    fill="#FFCA00"
                    d="M61.303 164.89c0-1.152.693-1.474 1.57-1.52v-.691h.74v.691c.554.046.97.138 1.386.23v1.014a23.81 23.81 0 00-1.71-.092c-.462 0-.693.046-.693.46v.185c0 .322.139.46.555.46h.647c1.155 0 1.432.968 1.432 1.613v.322c0 1.337-.832 1.659-1.617 1.659v.645h-.74v-.645c-.646 0-.877-.092-1.386-.23v-1.014c.324.046 1.11.092 1.849.092.323 0 .6-.092.6-.414v-.231c0-.276-.092-.461-.462-.461h-.6c-1.433 0-1.525-1.151-1.525-1.612l-.046-.461z"
                ></path>
                <path fill="#2D4375" d="M68.787 155.056a.37.37 0 100-.738.37.37 0 000 .738z"></path>
                <path
                    fill="#EAC3A4"
                    d="M72.853 162.243l-5.313-2.672s-.786-.507-1.017.046c-.138.322-.462 2.119 1.479 3.133.554.276 2.31 1.428 2.31 1.428s.092 3.409 1.063 5.114l4.435-.277-.555-2.395c-.046 0-2.079-3.824-2.402-4.377z"
                ></path>
            </g>
            <ellipse cx="210.911" cy="17.875" fill="#FFCA30" rx="12.911" ry="12.875"></ellipse>
            <g filter="url(#filter5_d)">
                <path fill="#8AE383" d="M200.952 20.996h17.338v-8.43h-17.338v8.43z"></path>
                <path
                    fill="#57C14F"
                    d="M201.812 13.394v6.779h15.631v-6.78h-15.631zm13.459 5.927h-4.384c.696-.504 1.159-1.45 1.159-2.49 0-1.115-.516-2.082-1.296-2.586h4.521c0 .673.633 1.534 1.318 1.534v2.48c-.675 0-1.318.39-1.318 1.062zm-12.616-3.542c.675 0 1.286-.861 1.286-1.534h4.364c-.77.504-1.297 1.471-1.297 2.585 0 1.051.464 1.987 1.16 2.491h-4.216c0-.672-.612-1.061-1.286-1.061v-2.48h-.011z"
                ></path>
                <path
                    fill="#fff"
                    d="M210.434 16c-.021-.032-.064-.053-.106-.085a2.331 2.331 0 00-.168-.094 1.518 1.518 0 00-.443-.116v.852l.074.02c.147.032.284.074.4.116.116.042.222.095.306.158a.588.588 0 01.19.231.8.8 0 01.063.337.76.76 0 01-.074.367.71.71 0 01-.211.253 1.48 1.48 0 01-.316.157 1.58 1.58 0 01-.39.053v.43h-.211v-.44c-.2-.011-.39-.053-.558-.116a1.693 1.693 0 01-.485-.274l.211-.389a.632.632 0 00.126.106c.053.042.127.073.2.115.074.032.159.063.253.095.095.031.19.042.285.052v-.851c-.021-.01-.032-.01-.053-.01-.021 0-.031-.011-.052-.011-.148-.042-.274-.073-.38-.116a.994.994 0 01-.274-.157.508.508 0 01-.158-.21.642.642 0 01-.053-.295.833.833 0 01.274-.63.928.928 0 01.295-.179c.116-.042.243-.074.38-.084v-.41h.211v.41c.179.01.337.042.495.105.148.063.285.137.401.22l-.232.39zm-1.349.105c0 .115.042.2.126.262.084.053.2.106.369.148v-.81c-.169.01-.295.042-.369.116-.095.074-.126.168-.126.284zm1.18 1.366a.317.317 0 00-.148-.273 1.607 1.607 0 00-.4-.168v.809c.179 0 .306-.032.4-.095a.295.295 0 00.148-.273zM213.712 16.788a.66.66 0 00.664.662.66.66 0 00.664-.662.66.66 0 00-.664-.662.667.667 0 00-.664.662zM205.533 16.788a.66.66 0 01-.664.662.66.66 0 01-.664-.662.66.66 0 01.664-.662.66.66 0 01.664.662z"
                ></path>
                <path fill="#8AE383" d="M203.546 23.139h17.338v-8.43h-17.338v8.43z"></path>
                <path
                    fill="#57C14F"
                    d="M204.405 15.538v6.78h15.631v-6.78h-15.631zm13.46 5.928h-4.385c.696-.505 1.16-1.45 1.16-2.491 0-1.114-.517-2.081-1.297-2.586h4.522c0 .673.632 1.535 1.317 1.535v2.48c-.674 0-1.317.39-1.317 1.062zm-12.617-3.542c.675 0 1.286-.862 1.286-1.535h4.364c-.77.505-1.297 1.472-1.297 2.586 0 1.051.464 1.986 1.16 2.49h-4.227c0-.672-.611-1.06-1.286-1.06v-2.481z"
                ></path>
                <path
                    fill="#fff"
                    d="M213.026 18.144c-.021-.031-.063-.052-.105-.084-.053-.031-.105-.063-.169-.095-.063-.031-.137-.052-.21-.073a1.543 1.543 0 00-.232-.042v.851l.074.021c.147.032.284.074.4.116.116.042.221.094.306.157a.576.576 0 01.189.232c.043.094.064.2.064.336a.76.76 0 01-.074.368.719.719 0 01-.211.252c-.095.074-.2.116-.316.158a1.592 1.592 0 01-.39.052v.431h-.211v-.441a1.89 1.89 0 01-.559-.116 1.687 1.687 0 01-.484-.273l.21-.389c.032.032.074.074.127.105.053.042.126.074.2.116.074.031.158.063.253.094.095.032.19.042.285.053v-.851c-.021-.01-.032-.01-.053-.01-.021 0-.032-.011-.053-.011-.147-.043-.274-.074-.379-.116a.977.977 0 01-.274-.158.499.499 0 01-.158-.21.642.642 0 01-.053-.294.834.834 0 01.274-.63.919.919 0 01.295-.18c.116-.042.243-.073.38-.084v-.41h.21v.41c.18.01.338.043.496.106.147.063.284.136.4.22l-.232.39zm-1.349.105c0 .116.043.2.127.263.084.052.2.105.369.147v-.81c-.169.011-.295.043-.369.116-.095.074-.127.169-.127.284zm1.181 1.367a.317.317 0 00-.148-.274 1.64 1.64 0 00-.4-.168v.81c.179 0 .305-.032.4-.095a.294.294 0 00.148-.273zM216.315 18.932a.66.66 0 00.664.662.66.66 0 00.664-.662.66.66 0 00-.664-.662.66.66 0 00-.664.662zM208.125 18.932a.66.66 0 01-.664.662.66.66 0 01-.664-.662.66.66 0 01.664-.662.66.66 0 01.664.662z"
                ></path>
            </g>
            <defs>
                <filter
                    id="filter0_d"
                    width="51.461"
                    height="59.795"
                    x="135.397"
                    y="36.807"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="1.39"></feOffset>
                    <feGaussianBlur stdDeviation="5.559"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
                </filter>
                <filter
                    id="filter1_d"
                    width="52.313"
                    height="60.085"
                    x="1.957"
                    y="41.042"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="1.4"></feOffset>
                    <feGaussianBlur stdDeviation="2.45"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"></feColorMatrix>
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
                </filter>
                <filter
                    id="filter2_d"
                    width="44.29"
                    height="60.515"
                    x="81.136"
                    y="78.79"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="1.369"></feOffset>
                    <feGaussianBlur stdDeviation="2.395"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
                </filter>
                <filter
                    id="filter3_d"
                    width="35.381"
                    height="35.308"
                    x="184.586"
                    y="162.101"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="1.951"></feOffset>
                    <feGaussianBlur stdDeviation="3.407"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
                </filter>
                <filter
                    id="filter4_d"
                    width="37.226"
                    height="34.385"
                    x="49.51"
                    y="153.12"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="3.163"></feOffset>
                    <feGaussianBlur stdDeviation="1.582"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"></feColorMatrix>
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
                </filter>
                <filter
                    id="filter5_d"
                    width="24.451"
                    height="15.091"
                    x="197.078"
                    y="11.275"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dx="-1.614" dy="0.968"></feOffset>
                    <feGaussianBlur stdDeviation="1.13"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"></feColorMatrix>
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
                </filter>
                <linearGradient
                    id="paint0_linear"
                    x1="28.115"
                    x2="28.151"
                    y1="70.369"
                    y2="81.116"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFAF14"></stop>
                    <stop offset="1" stopColor="#E2990A"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="184.903"
                    x2="184.903"
                    y1="188.297"
                    y2="139.251"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E0B474"></stop>
                    <stop offset="0.235" stopColor="#F5DCB9"></stop>
                    <stop offset="0.353" stopColor="#FEEDD7"></stop>
                    <stop offset="0.386" stopColor="#FEEDD7"></stop>
                    <stop offset="0.697" stopColor="#FEEDD7"></stop>
                    <stop offset="0.763" stopColor="#FDEBD3"></stop>
                    <stop offset="0.822" stopColor="#FAE5C8"></stop>
                    <stop offset="0.88" stopColor="#F4DAB6"></stop>
                    <stop offset="0.936" stopColor="#ECCB9C"></stop>
                    <stop offset="0.99" stopColor="#E2B87B"></stop>
                    <stop offset="1" stopColor="#E0B474"></stop>
                </linearGradient>
                <radialGradient
                    id="paint2_radial"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(32.3209 0 0 28.1776 184.903 160.754)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.036" stopColor="#fff"></stop>
                    <stop offset="0.273" stopColor="#FDFDFC"></stop>
                    <stop offset="0.456" stopColor="#F8F5F2"></stop>
                    <stop offset="0.621" stopColor="#EEE9E2"></stop>
                    <stop offset="0.774" stopColor="#E1D8CB"></stop>
                    <stop offset="0.919" stopColor="#D0C2AD"></stop>
                    <stop offset="1" stopColor="#C4B399"></stop>
                </radialGradient>
                <linearGradient
                    id="paint3_linear"
                    x1="205.482"
                    x2="205.482"
                    y1="143.198"
                    y2="137.709"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01" stopColor="#592100"></stop>
                    <stop offset="0.195" stopColor="#6E330F"></stop>
                    <stop offset="0.576" stopColor="#A56035"></stop>
                    <stop offset="0.618" stopColor="#AB653A"></stop>
                    <stop offset="1" stopColor="#633105"></stop>
                </linearGradient>
                <linearGradient
                    id="paint4_linear"
                    x1="164.326"
                    x2="164.326"
                    y1="143.156"
                    y2="137.668"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01" stopColor="#592100"></stop>
                    <stop offset="0.195" stopColor="#6E330F"></stop>
                    <stop offset="0.576" stopColor="#A56035"></stop>
                    <stop offset="0.618" stopColor="#AB653A"></stop>
                    <stop offset="1" stopColor="#633105"></stop>
                </linearGradient>
                <linearGradient
                    id="paint5_linear"
                    x1="185.036"
                    x2="185.036"
                    y1="145.503"
                    y2="139.383"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E0B474"></stop>
                    <stop offset="1" stopColor="#FFEFDA"></stop>
                </linearGradient>
                <linearGradient
                    id="paint6_linear"
                    x1="184.903"
                    x2="185.176"
                    y1="136.791"
                    y2="147.439"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.036" stopColor="#fff"></stop>
                    <stop offset="0.273" stopColor="#FDFDFC"></stop>
                    <stop offset="0.456" stopColor="#F8F5F2"></stop>
                    <stop offset="0.621" stopColor="#EEE9E2"></stop>
                    <stop offset="0.774" stopColor="#E1D8CB"></stop>
                    <stop offset="0.919" stopColor="#D0C2AD"></stop>
                    <stop offset="1" stopColor="#C4B399"></stop>
                </linearGradient>
                <linearGradient
                    id="paint7_linear"
                    x1="205.482"
                    x2="205.482"
                    y1="184.492"
                    y2="179.004"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01" stopColor="#592100"></stop>
                    <stop offset="0.195" stopColor="#6E330F"></stop>
                    <stop offset="0.576" stopColor="#A56035"></stop>
                    <stop offset="0.618" stopColor="#AB653A"></stop>
                    <stop offset="1" stopColor="#633105"></stop>
                </linearGradient>
                <linearGradient
                    id="paint8_linear"
                    x1="164.326"
                    x2="164.326"
                    y1="184.451"
                    y2="178.962"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01" stopColor="#592100"></stop>
                    <stop offset="0.195" stopColor="#6E330F"></stop>
                    <stop offset="0.576" stopColor="#A56035"></stop>
                    <stop offset="0.618" stopColor="#AB653A"></stop>
                    <stop offset="1" stopColor="#633105"></stop>
                </linearGradient>
                <linearGradient
                    id="paint9_linear"
                    x1="185.037"
                    x2="185.037"
                    y1="186.798"
                    y2="180.678"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E0B474"></stop>
                    <stop offset="1" stopColor="#FFEFDA"></stop>
                </linearGradient>
                <linearGradient
                    id="paint10_linear"
                    x1="185.037"
                    x2="185.037"
                    y1="177.824"
                    y2="184.769"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.036" stopColor="#fff"></stop>
                    <stop offset="0.273" stopColor="#FDFDFC"></stop>
                    <stop offset="0.456" stopColor="#F8F5F2"></stop>
                    <stop offset="0.621" stopColor="#EEE9E2"></stop>
                    <stop offset="0.774" stopColor="#E1D8CB"></stop>
                    <stop offset="0.919" stopColor="#D0C2AD"></stop>
                    <stop offset="1" stopColor="#C4B399"></stop>
                </linearGradient>
                <linearGradient
                    id="paint11_linear"
                    x1="232.923"
                    x2="232.923"
                    y1="98.478"
                    y2="100.931"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB10F"></stop>
                    <stop offset="1" stopColor="#DF9C11"></stop>
                </linearGradient>
            </defs>
        </svg>
    )
}

export default Icon
