import 'firebase/messaging'
import { useSetState } from 'react-use'
import * as firebase from 'firebase/app'
import React, { createContext, useEffect, useState, useContext } from 'react'

import { AuthContext } from './Auth'
import { DialogContext, DIALOG_ID } from './Dialog'
import { NotificationsQuery, useNotificationsQuery, useUpdateFcmMutation } from '@graphql'

type Props = {
    children: React.ReactNode
}

type State = {
    loading: boolean
    data?: NotificationsQuery['notifications']['data'] | null
    meta?: NotificationsQuery['notifications']['meta'] | null
    dialog: {
        isOpen: boolean
        data: Array<{
            body?: string
            icon: string
            title: string
        }>
    }
}

type Payload = {
    data: {
        title: string
        body?: string
        type: string
        iconPng: string
        iconSvg: string
    }
}

type Context = {
    loading: boolean
    close: () => any
    refetch: () => any
    loadMore: () => any
    data?: NotificationsQuery['notifications']['data'] | null
    meta?: NotificationsQuery['notifications']['meta'] | null
    dialog: {
        isOpen: boolean
        data: Array<{
            body?: string
            icon: string
            title: string
        }>
    }
}

export const NotificationContext = createContext<Context>({
    meta: null,
    data: null,
    loading: true,
    close: () => {},
    refetch: () => {},
    loadMore: () => {},
    dialog: { isOpen: false, data: [] }
})

const NotificationProvider = ({ children }: Props) => {
    const { id } = useContext(DialogContext)
    const { isAuthorized, user } = useContext(AuthContext)

    const [itemsPerPage, setItemsPerPage] = useState(20)

    const [updateFcm] = useUpdateFcmMutation()

    const [state, setState] = useSetState<State>({
        data: null,
        meta: null,
        loading: true,
        dialog: {
            isOpen: false,
            data: []
        }
    })

    const close = () => setState({ dialog: { data: [], isOpen: false } })

    const { refetch } = useNotificationsQuery({
        skip: !isAuthorized,
        notifyOnNetworkStatusChange: true,
        variables: {
            itemsPerPage: itemsPerPage
        },
        onCompleted: (data) => {
            setState({
                loading: false,
                meta: data?.notifications?.meta,
                data: data?.notifications?.data
            })
        }
    })

    useEffect(() => {
        if (!isAuthorized || !user?.id) return
        // Initiate Firebase
        if (!window['firebase']) {
            const key = atob(process.env.RAZZLE_FIREBASE_KEY || '')
            if (!key) {
                throw Error('Missing RAZZLE_FIREBASE_KEY')
            }

            // https://firebase.google.com/docs/web/setup#config-object
            // @ts-ignore
            window.firebase = firebase.initializeApp(JSON.parse(key))

            // Retrieve Firebase Messaging object.
            const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null

            if (!messaging) {
                return
            }

            // @ts-ignore
            messaging.usePublicVapidKey(process.env.RAZZLE_FCM_KEY)

            messaging.requestPermission()

            // Get Instance ID token. Initially this makes a network call, once retrieved
            // subsequent calls to getToken will return from cache.
            messaging
                .getToken()
                .then((currentToken) => {
                    if (currentToken) {
                        updateFcm({
                            variables: {
                                data: {
                                    fcmToken: currentToken,
                                    deviceId: `browser-${user?.id}`
                                }
                            }
                        })
                    } else {
                        // Show permission request.
                        console.log(
                            'No Instance ID token available. Request permission to generate one.'
                        )
                    }
                })
                .catch((err) => {
                    console.log('An error occurred while retrieving token. ', err)
                })

            // Callback fired if Instance ID token is updated.
            messaging.onTokenRefresh(() => {
                messaging
                    .getToken()
                    .then(async (refreshedToken) => {
                        const {} = await updateFcm({
                            variables: {
                                data: {
                                    fcmToken: refreshedToken,
                                    deviceId: `browser-${user?.id}`
                                }
                            }
                        })
                    })
                    .catch((err) => {
                        console.log('Unable to retrieve refreshed token ', err)
                    })
            })

            messaging.onMessage((payload: Payload) => {
                if (!payload?.data) return

                if (id === DIALOG_ID.NOT_DISPLAYED) {
                    return setState({
                        dialog: {
                            isOpen: true,
                            data: [
                                ...state.dialog.data,
                                {
                                    body: payload.data?.body,
                                    title: payload.data.title,
                                    icon: payload.data?.iconSvg || payload.data?.iconPng || ''
                                }
                            ]
                        }
                    })
                }

                return setState({
                    dialog: {
                        isOpen: false,
                        data: [
                            ...state.dialog.data,
                            {
                                body: payload.data?.body,
                                title: payload.data.title,
                                icon: payload.data?.iconSvg || payload.data?.iconPng || ''
                            }
                        ]
                    }
                })
            })
        }
    }, [isAuthorized, user?.id])

    const loadMore = () => {
        const newItemsPerPage = (state.meta?.itemsPerPage || 0) + 20
        const items = state.meta?.items || 20

        setItemsPerPage(items <= newItemsPerPage ? items : newItemsPerPage)
    }

    return (
        <NotificationContext.Provider
            value={{
                ...state,
                close,
                refetch,
                loadMore
            }}
        >
            {children}
        </NotificationContext.Provider>
    )
}

export default NotificationProvider
