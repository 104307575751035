import React from 'react'

import { Props } from '.'

function Icon({ color }: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M18.752 19.579C13.639 20.305 12 22.125 12 22.125V4.712S9.545 1.985 1.268 1.875a.506.506 0 00-.366.15.522.522 0 00-.152.369v16.375c-.002.28.22.51.5.519C9.543 19.4 12 22.125 12 22.125M12 4.712s2.455-2.727 10.732-2.837a.506.506 0 01.366.15c.098.098.152.23.152.369v12.231"
            ></path>
            <path
                fillRule="evenodd"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M18.75 19.564v-4.39a.557.557 0 01.563-.549h3.937l-4.5 4.939z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default Icon
