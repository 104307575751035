import get from 'lodash/get'
import isString from 'lodash/isString'
import { useSnackbar } from 'notistack'
import { string, object, ref } from 'yup'
import { useHistory, useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Language from '@material-ui/icons/Language'
import Visibility from '@material-ui/icons/Visibility'
import BookOutlined from '@material-ui/icons/BookOutlined'
import LockOutlined from '@material-ui/icons/LockOutlined'
import EditOutlined from '@material-ui/icons/EditOutlined'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import InputAdornment from '@material-ui/core/InputAdornment'
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined'
import { Formik, Form, FormikProps, Field, FormikHelpers } from 'formik'
import React, { useState, useContext, ChangeEvent, useEffect } from 'react'

import * as loco from '@loco'
import Head from '../../../../../components/Head'
import Avatar from '../../../../../components/Avatar'
import Close from '../../../../../components/shared/Close'
import Arrow from '../../../../../components/shared/Arrow'
import Dialog from '../../../../../components/Dialogs/Info'
import InputField from '../../../../../components/Fields/Input'
import { AuthContext } from '../../../../../context/Auth'
import { colors, Routes, notificationMsgs } from '@variables'
import icon from '@static/images/infoChar.png'
import { translateGQLError, getBase64 } from '@utils'
import {
    Row,
    Col,
    Title,
    Section,
    Container,
    ErrorLabel,
    PersonalBtn,
    GoogleIcon,
    PlatformIcon,
    FacebookIcon,
    ChooseAvatar,
    TitleWrapper,
    AvatarWrapper,
    SocialWrapper,
    ExpansionPanel,
    SendEmailButton,
    DeactivateButton,
    EmailFieldWrapper,
    ExpansionPanelDetails,
    ExpansionPanelSummary
} from './styled'
import {
    useSendEmailVerificationMutation,
    useUpdateProfilePictureMutation,
    useDeleteOwnAccountMutation,
    useUpdateSelfUserMutation,
    useChangePasswordMutation,
    useValidatorsQuery,
    SingnupType,
    MeDocument,
    Role
} from '@graphql'

type FormValues = {
    email: string
    username: string

    newPassword?: string
    currentPassword?: string
    confirmNewPassword?: string
}

const enum Panel {
    SOCIAL = 'Sociální sítě',
    PERSONAL = 'Osobní údaje',
    PASSWORD = `Nastavení hesla`,
    COMMON = 'Základní informace',
    DEACTIVATE = 'Deaktivace účtu'
}

const EditProfile = () => {
    const history = useHistory()
    const { hash } = useLocation()
    const { enqueueSnackbar } = useSnackbar()

    const { user, logout } = useContext(AuthContext)

    const isEmailVerified = user?.isEmailVerified

    const [isOpen, setIsOpen] = useState(false)

    // ================= SHOW/HIDE PASSWORD LOGIC ================>
    const [showPassword, setShowPassword] = useState(false)
    const toggleShowPwd = () => setShowPassword(!showPassword)

    // ======================= PANEL LOGIC =======================>
    const [expanded, setExpanded] = useState<Panel | boolean>(Panel.COMMON)
    const onExpand = (panel: Panel) => (e: ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false)
    }

    // ======================= MUTATIONS =======================>
    const [changePassword] = useChangePasswordMutation()
    const [sendEmailVerification] = useSendEmailVerificationMutation()
    const [deleteAccount, { loading: deleteLoading }] = useDeleteOwnAccountMutation()
    const [updateProfilePicture] = useUpdateProfilePictureMutation({
        refetchQueries: [{ query: MeDocument }]
    })
    const [updateSelfUser] = useUpdateSelfUserMutation({
        refetchQueries: [{ query: MeDocument }]
    })

    // ======================= QUERIES =======================>
    const { data } = useValidatorsQuery({
        onError: (err) => console.log(err)
    })

    useEffect(() => {
        if (hash.includes(Routes.PERSONAL_HASH_PARAM)) {
            setExpanded(Panel.PERSONAL)
        }
    }, [hash])

    const goToQuestionnaire = (route: string) => history.push(route)

    const initialValues: FormValues = {
        newPassword: '',
        currentPassword: '',
        confirmNewPassword: '',

        email: user?.email || '',
        username: user?.username || ''
    }

    // ======================= HANDLERS =======================>
    const submitUser = async ({ username, email }: FormValues) => {
        if (initialValues.username === username && initialValues.email === email) return

        let postData = {}

        if (initialValues.email !== email) {
            postData = {
                ...postData,
                email: email
            }
        }

        if (initialValues.username !== username && user?.role === Role.STUDENT) {
            postData = {
                ...postData,
                username: username
            }
        }

        const { data, errors } = await updateSelfUser({ variables: { data: { ...postData } } })

        if (data && !errors) enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
        else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }
    }

    const submitPwd = async (values: FormValues, setFieldValue: Function) => {
        const nPwd = get(values, 'newPassword', '')
        const cNPwd = get(values, 'confirmNewPassword', '')
        const cPwd = get(values, 'currentPassword', '')

        if (nPwd.length === 0 || cNPwd.length === 0 || cPwd.length === 0) return

        if (nPwd !== cNPwd) return
        if (nPwd.length < 8 || cNPwd.length < 8 || cPwd.length < 8) return

        const { data, errors } = await changePassword({
            variables: {
                data: {
                    currentPassword: cPwd,
                    newPassword: nPwd
                }
            }
        })

        if (data && !errors) {
            setFieldValue('newPassword', '')
            setFieldValue('currentPassword', '')
            setFieldValue('confirmNewPassword', '')
            enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }
    }

    const submit = async (values: FormValues, { setFieldValue }: FormikHelpers<FormValues>) => {
        if (Boolean(user?.isTemporary)) return

        submitPwd(values, setFieldValue)
        submitUser(values)
    }

    const deactivate = async () => {
        const { data, errors } = await deleteAccount()

        if (data && !errors) {
            enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
            await logout()
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }
    }

    const resendEmail = async () => {
        const { data, errors } = await sendEmailVerification()
        if (data && !errors) {
            enqueueSnackbar(loco.notifications.update, { variant: 'success' })
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }
    }

    const onAvatarInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e?.target?.files) return

        const file: FileList[0] | null = e.target.files[0]
        if (!file) return

        const pic = await getBase64(file)
        if (!isString(pic)) return

        try {
            const { data, errors } = await updateProfilePicture({
                variables: {
                    data: {
                        base64: pic
                    }
                }
            })

            if (data && !errors) enqueueSnackbar(loco.notifications.update, { variant: 'success' })
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        } catch (error) {
            enqueueSnackbar(loco.common.netowrkError, { variant: 'error' })
        }
    }

    const validationSchema = object({
        username: string().matches(
            new RegExp(
                `${get(
                    data,
                    'validators.username.regex',
                    '^(?=.{4,16}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+$'
                )}`
            ),
            get(data, 'validators.username.description', loco.validation.identificator.match)
        ),
        name: string(),
        email: string()
            .email(loco.validation.email.common)
            .matches(
                new RegExp(
                    `${get(
                        data,
                        'validators.email.regex',
                        `^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6})*$`
                    )}`
                ),
                get(data, 'validators.email.description', loco.validation.email.common)
            ),

        currentPassword: string(),
        newPassword: string().matches(
            new RegExp(
                `${get(
                    data,
                    'validators.password.regex',
                    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$'
                )}`
            ),
            get(data, 'validators.password.description', loco.validation.password.match)
        ),
        confirmNewPassword: string().when('newPassword', {
            is: (val) => (val && val.length > 0 ? true : false),
            then: string().oneOf([ref('newPassword')], loco.validation.password.confirm)
        })
    })

    return (
        <>
            <Head title={loco.seo['profile-edit'].title} />

            <Dialog
                isOpen={isOpen}
                toggleOpen={() => setIsOpen(!isOpen)}
                data={[
                    {
                        icon: icon,
                        title: loco.delete.profile.confirmation.title,
                        description: loco.delete.profile.confirmation.subtitle
                    }
                ]}
                buttonProps={{
                    onClick: deactivate,
                    isSubmitting: deleteLoading,
                    text: loco.delete.profile.confirmation.confirm
                }}
            />

            <Container>
                <TitleWrapper>
                    <Typography variant="h1">{loco.profile.edit.title}</Typography>
                </TitleWrapper>

                <Section>
                    <Close onClick={() => history.push(Routes.PROFILE)} />

                    <AvatarWrapper>
                        <Avatar size="large" />
                        <ChooseAvatar>
                            <EditOutlined />
                            <input
                                hidden
                                type="file"
                                accept="image/*"
                                onChange={onAvatarInputChange}
                            />
                        </ChooseAvatar>
                    </AvatarWrapper>

                    <Formik
                        onSubmit={submit}
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                    >
                        {({ values, dirty, isSubmitting }: FormikProps<FormValues>) => (
                            <Form style={{ width: '100%' }} spellCheck={false}>
                                {/* ========== COMMON INFORMATION PANEL ========== */}
                                <ExpansionPanel
                                    onChange={onExpand(Panel.COMMON)}
                                    expanded={expanded === Panel.COMMON}
                                >
                                    <ExpansionPanelSummary
                                        aria-controls="panel-content"
                                        expandIcon={<Arrow variant="bottom" />}
                                    >
                                        <BookOutlined />
                                        <Title>{Panel.COMMON}</Title>
                                    </ExpansionPanelSummary>

                                    <ExpansionPanelDetails>
                                        <Field
                                            fullWidth
                                            key="username"
                                            name="username"
                                            component={InputField}
                                            value={values.username}
                                            className="customInput"
                                            autoComplete="username"
                                            inputProps={{
                                                autoCorrect: 'off',
                                                autoComplete: 'off',
                                                autoCapitalize: 'none'
                                            }}
                                            disabled={user?.signupType === SingnupType.NATIVE}
                                            label={loco.profile.edit.general.username.label}
                                            placeholder={
                                                loco.profile.edit.general.username.placeholder
                                            }
                                        />

                                        <EmailFieldWrapper isEmailVerified={isEmailVerified}>
                                            <Field
                                                fullWidth
                                                key="email"
                                                name="email"
                                                value={values.email}
                                                component={InputField}
                                                className="customInput"
                                                disabled={user?.signupType !== SingnupType.NATIVE}
                                                inputProps={{
                                                    autoCorrect: 'off',
                                                    autoComplete: 'off',
                                                    autoCapitalize: 'none'
                                                }}
                                                label={loco.profile.edit.general.email.label}
                                                placeholder={
                                                    loco.profile.edit.general.email.placeholder
                                                }
                                            />
                                            {!isEmailVerified && Boolean(user?.email) && (
                                                <ErrorLabel>
                                                    {loco.validation.verifyEmail}
                                                </ErrorLabel>
                                            )}
                                            {!isEmailVerified && Boolean(user?.email) && (
                                                <SendEmailButton
                                                    role="button"
                                                    onClick={resendEmail}
                                                >
                                                    {loco.common.resend}
                                                </SendEmailButton>
                                            )}
                                        </EmailFieldWrapper>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <hr />

                                {/* ========== PERSONAL INFORMATION PANEL ========== */}
                                <ExpansionPanel
                                    id={Routes.PERSONAL_HASH_PARAM}
                                    onChange={onExpand(Panel.PERSONAL)}
                                    expanded={expanded === Panel.PERSONAL}
                                >
                                    <ExpansionPanelSummary
                                        aria-controls="panel-content"
                                        expandIcon={<Arrow variant="bottom" />}
                                    >
                                        <PermIdentityOutlinedIcon />
                                        <Title>{Panel.PERSONAL}</Title>
                                    </ExpansionPanelSummary>

                                    <ExpansionPanelDetails>
                                        <Row style={{ marginBottom: 25 }}>
                                            <div>
                                                <Typography style={{ textTransform: 'capitalize' }}>
                                                    {loco.profile.profile.experience}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        color: colors.primary.gray1,
                                                        fontWeight: 200
                                                    }}
                                                >
                                                    {user?.investmentExperience
                                                        ? user?.investmentExperience.name
                                                        : loco.leaderboard_notset}
                                                </Typography>
                                            </div>
                                            <PersonalBtn
                                                size="large"
                                                color="primary"
                                                variant="outlined"
                                                onClick={() => {
                                                    goToQuestionnaire(
                                                        Routes.QUESTIONNAIRE_EXPERIENCE
                                                    )
                                                }}
                                            >
                                                {loco.common.edit}
                                            </PersonalBtn>
                                        </Row>
                                        <Row style={{ marginBottom: 25 }}>
                                            <div>
                                                <Typography style={{ textTransform: 'capitalize' }}>
                                                    {loco.profile.profile.lifeStage}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        color: colors.primary.gray1,
                                                        fontWeight: 200
                                                    }}
                                                >
                                                    {user?.lifeStage
                                                        ? user?.lifeStage.name
                                                        : loco.leaderboard_notset}
                                                </Typography>
                                            </div>
                                            <PersonalBtn
                                                size="large"
                                                color="primary"
                                                variant="outlined"
                                                onClick={() => {
                                                    goToQuestionnaire(
                                                        Routes.QUESTIONNAIRE_LIFESTAGE
                                                    )
                                                }}
                                            >
                                                {loco.common.edit}
                                            </PersonalBtn>
                                        </Row>
                                        <Row>
                                            <div>
                                                <Typography style={{ textTransform: 'capitalize' }}>
                                                    {loco.profile.profile.district}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        color: colors.primary.gray1,
                                                        fontWeight: 200
                                                    }}
                                                >
                                                    {user?.district?.name ||
                                                        loco.leaderboard_notset}
                                                </Typography>
                                            </div>
                                            <PersonalBtn
                                                size="large"
                                                color="primary"
                                                variant="outlined"
                                                onClick={() => {
                                                    goToQuestionnaire(Routes.QUESTIONNAIRE_DISTRICT)
                                                }}
                                            >
                                                {loco.common.edit}
                                            </PersonalBtn>
                                        </Row>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <hr />

                                {/* ========== PASSWORD INFORMATION PANEL ========== */}
                                {user?.signupType === SingnupType.NATIVE && (
                                    <>
                                        <ExpansionPanel
                                            onChange={onExpand(Panel.PASSWORD)}
                                            expanded={expanded === Panel.PASSWORD}
                                        >
                                            <ExpansionPanelSummary
                                                aria-controls="panel-content"
                                                expandIcon={<Arrow variant="bottom" />}
                                            >
                                                <LockOutlined />
                                                <Title>{Panel.PASSWORD}</Title>
                                            </ExpansionPanelSummary>

                                            <ExpansionPanelDetails>
                                                <Field
                                                    fullWidth
                                                    inputProps={{
                                                        autoCorrect: 'off',
                                                        autoComplete: 'off',
                                                        autoCapitalize: 'none'
                                                    }}
                                                    key="currentPassword"
                                                    name="currentPassword"
                                                    component={InputField}
                                                    className="customInput"
                                                    autoComplete="current-password"
                                                    label={loco.profile.edit.password.old.label}
                                                    placeholder={
                                                        loco.profile.edit.password.old.placeholder
                                                    }
                                                    // HIDE/SHOW PROPS
                                                    type={showPassword ? 'text' : 'password'}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    edge="end"
                                                                    onClick={toggleShowPwd}
                                                                    style={{
                                                                        margin: '0 0 20px 0'
                                                                    }}
                                                                    aria-label="toggle password visibility"
                                                                >
                                                                    {showPassword ? (
                                                                        <VisibilityOff />
                                                                    ) : (
                                                                        <Visibility />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                                <Field
                                                    fullWidth
                                                    key="newPassword"
                                                    name="newPassword"
                                                    component={InputField}
                                                    value={values.newPassword}
                                                    className="customInput"
                                                    inputProps={{
                                                        autoCorrect: 'off',
                                                        autoComplete: 'off',
                                                        autoCapitalize: 'none'
                                                    }}
                                                    label={loco.profile.edit.password.new.label}
                                                    placeholder={
                                                        loco.profile.edit.password.new.placeholder
                                                    }
                                                    // HIDE/SHOW PROPS
                                                    type={showPassword ? 'text' : 'password'}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    edge="end"
                                                                    onClick={toggleShowPwd}
                                                                    style={{
                                                                        margin: '0 0 20px 0'
                                                                    }}
                                                                    aria-label="toggle password visibility"
                                                                >
                                                                    {showPassword ? (
                                                                        <VisibilityOff />
                                                                    ) : (
                                                                        <Visibility />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                                <Field
                                                    fullWidth
                                                    component={InputField}
                                                    key="confirmNewPassword"
                                                    name="confirmNewPassword"
                                                    className="customInput"
                                                    inputProps={{
                                                        autoCorrect: 'off',
                                                        autoComplete: 'off',
                                                        autoCapitalize: 'none'
                                                    }}
                                                    value={values.confirmNewPassword}
                                                    label={
                                                        loco.profile.edit.password['new-again']
                                                            .label
                                                    }
                                                    placeholder={
                                                        loco.profile.edit.password['new-again']
                                                            .placeholder
                                                    }
                                                    // HIDE/SHOW PROPS
                                                    type={showPassword ? 'text' : 'password'}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    edge="end"
                                                                    onClick={toggleShowPwd}
                                                                    style={{
                                                                        margin: '0 0 20px 0'
                                                                    }}
                                                                    aria-label="toggle password visibility"
                                                                >
                                                                    {showPassword ? (
                                                                        <VisibilityOff />
                                                                    ) : (
                                                                        <Visibility />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                        <hr />
                                    </>
                                )}

                                {/* ========== SOCIAL PANEL ========== */}
                                {user?.role === Role.STUDENT && (
                                    <>
                                        <ExpansionPanel
                                            onChange={onExpand(Panel.SOCIAL)}
                                            expanded={expanded === Panel.SOCIAL}
                                        >
                                            <ExpansionPanelSummary
                                                aria-controls="panel-content"
                                                expandIcon={<Arrow variant="bottom" />}
                                            >
                                                <Language />
                                                <Title>{Panel.SOCIAL}</Title>
                                            </ExpansionPanelSummary>

                                            <ExpansionPanelDetails>
                                                <SocialWrapper>
                                                    {user?.signupType === SingnupType.FACEBOOK && (
                                                        <Row>
                                                            <FacebookIcon />
                                                            <Typography
                                                                variant="body2"
                                                                style={{ color: '#4968ad' }}
                                                            >
                                                                {loco.profile.edit.social.facebook}
                                                            </Typography>
                                                        </Row>
                                                    )}
                                                    {user?.signupType === SingnupType.GOOGLE && (
                                                        <Row>
                                                            <GoogleIcon />
                                                            <Typography variant="body2">
                                                                {loco.profile.edit.social.google}
                                                            </Typography>
                                                        </Row>
                                                    )}
                                                    {user?.signupType === SingnupType.NATIVE && (
                                                        <Row>
                                                            <PlatformIcon />
                                                            <Typography
                                                                variant="body2"
                                                                style={{
                                                                    color: colors.primary.orange
                                                                }}
                                                            >
                                                                {loco.profile.edit.social.bufin}
                                                            </Typography>
                                                        </Row>
                                                    )}
                                                </SocialWrapper>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                        <hr />
                                    </>
                                )}

                                {/* ========== DEACTIVATE PANEL ========== */}
                                <ExpansionPanel
                                    onChange={onExpand(Panel.DEACTIVATE)}
                                    expanded={expanded === Panel.DEACTIVATE}
                                >
                                    <ExpansionPanelSummary
                                        aria-controls="panel-content"
                                        expandIcon={<Arrow variant="bottom" />}
                                    >
                                        <DeleteOutlined />
                                        <Title>{Panel.DEACTIVATE}</Title>
                                    </ExpansionPanelSummary>

                                    <ExpansionPanelDetails>
                                        <Col>
                                            <Typography
                                                align="center"
                                                variant="body2"
                                                style={{ padding: '0 20px' }}
                                            >
                                                {loco.delete.profile.confirmation.subtitle}
                                            </Typography>

                                            <DeactivateButton
                                                size="large"
                                                type="button"
                                                color="secondary"
                                                variant="outlined"
                                                onClick={() => setIsOpen(!isOpen)}
                                                style={{
                                                    margin: '20px 0',
                                                    padding: '12px 30px'
                                                }}
                                            >
                                                {loco.profile.edit['delete-profile'].confirm}
                                            </DeactivateButton>
                                        </Col>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>

                                <Button
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    disabled={isSubmitting || !dirty}
                                    style={{ margin: '20px auto 0 auto' }}
                                >
                                    {loco.common.save}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Section>
            </Container>
        </>
    )
}

export default EditProfile
