import React from 'react'
import { Link } from 'react-router-dom'

import Tag from '../Tag'
import Tooltip from '../Tooltip'
import * as loco from '@loco'
import { Row, StatusCircle } from './styled'
import { CreationStatus } from '@graphql'
import { Routes, colors } from '@variables'
import { truncateText, getRouteWithId } from '@utils'

type Props = {
    text: string
    status: CreationStatus
    parentId?: string
    id?: string
    className?: string
    style?: object
    maxChars?: number
    draft?: {
        id: string
        isMerged: boolean
    }
}

const StatusRow = ({
    draft,
    status,
    maxChars,
    parentId,
    className = '',
    style = {},
    text = '',
    id = ''
}: Props) => {
    const isChild = Boolean(parentId)
    const statusTooltipTitle = getCzStatus(status)
    const truncatedText = maxChars ? truncateText(text, maxChars) : text
    const tooltipTitle = text && maxChars && text.length > maxChars ? text : ''
    const link = getRouteWithId(parentId ? Routes.LECTURE_REVIEW : Routes.CHAPTER_REVIEW, id)

    return (
        <Row style={style} className={className}>
            <Tooltip title={statusTooltipTitle}>
                <StatusCircle status={status} child={isChild} />
            </Tooltip>{' '}
            {isChild ? (
                <Tooltip title={tooltipTitle}>
                    <>
                        <span style={{ opacity: 0.5 }}>{truncatedText}</span>
                        {draft?.id && (
                            <Tag color={colors.primary.orange}>
                                <Link to={link}>{loco.creationStatus.pending}</Link>
                            </Tag>
                        )}
                    </>
                </Tooltip>
            ) : (
                <Tooltip title={tooltipTitle}>
                    <>
                        <b>{truncatedText} </b>
                        {draft?.id && (
                            <Tag color={colors.primary.orange}>
                                <Link to={link}>{loco.creationStatus.pending}</Link>
                            </Tag>
                        )}
                    </>
                </Tooltip>
            )}
        </Row>
    )
}

const getCzStatus = (status: CreationStatus): string => {
    if (status === CreationStatus.REJECTED) return loco.creationStatus.rejected
    if (status === CreationStatus.BLOCKED) return loco.creationStatus.blocked
    if (status === CreationStatus.PENDING) return loco.creationStatus.pending
    return loco.creationStatus.approved
}

export default StatusRow
