import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import { media } from '@variables'

export const Wrapper = styled.div`
    border-radius: 4px;
    background-color: white;

    ${media.phone} {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    ${media.desktop} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    @media (max-width: 960px) {
        padding: 30px;
        border-radius: 4px;
        background-color: white;
    }
`

export const Title = styled(Typography)``
export const Score = styled(Typography)``

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;

    ${media.phone} {
        width: 50%;
    }
    ${media.desktop} {
        width: 100%;
    }
    @media (min-width: 960px), (max-width: 479px) {
        :not(:last-of-type) {
            margin-bottom: 30px;
        }
    }

    ${Title} {
        opacity: 0.4;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
    }
    ${Score} {
        font-weight: bold;
        font-size: 16px;
    }

    div {
        padding-left: 20px;
    }
`
