import { Edit } from '@material-ui/icons'
import React, { forwardRef } from 'react'
import { useHistory } from 'react-router-dom'
import MaterialTable, { MTableHeader } from 'material-table'

import * as loco from '@loco'
import { getRouteWithId } from '@utils'
import Pagination from '../../Pagination'
import { tableLocalization, Routes } from '@variables'
import { tableIcons, notificationColumns as columns } from '../../../shared'
import { OrderByDirectionInput, CustomNotificationsQuery } from '@graphql'

type Props = {
    page: number
    title: string
    loading: boolean
    pageSize: number
    totalCount: number
    sortedColumnId: number
    data: Array<Notification>
    onChangePage: (page: number) => void
    onChangeItemsPerPage: (page: number) => void
    onSearchChange: (searchQuery: string) => void
    sortedColumnDirection: OrderByDirectionInput
    handleSortOrderChange: (orderedColumnId: number, orderDirection: OrderByDirectionInput) => void
}

type Notification = CustomNotificationsQuery['customNotifications']['data'][0]

const NotificationTable = ({
    page,
    data,
    title,
    loading,
    pageSize,
    totalCount,
    onChangePage,
    onSearchChange,
    sortedColumnId,
    onChangeItemsPerPage,
    sortedColumnDirection,
    handleSortOrderChange
}: Props) => {
    const history = useHistory()

    return (
        <MaterialTable
            data={data}
            page={page}
            title={title}
            columns={columns}
            icons={tableIcons}
            isLoading={loading}
            totalCount={totalCount}
            onChangePage={onChangePage}
            onSearchChange={onSearchChange}
            localization={tableLocalization}
            onChangeRowsPerPage={onChangeItemsPerPage}
            components={{
                Header: (props) =>
                    (
                        <MTableHeader
                            {...props}
                            orderBy={sortedColumnId}
                            onOrderChange={handleSortOrderChange}
                            orderDirection={sortedColumnDirection}
                        />
                    ) as any,
                Pagination: Pagination
            }}
            options={{
                actionsColumnIndex: -1,
                debounceInterval: 500,
                pageSize: pageSize,
                thirdSortClick: false,
                draggable: false
            }}
            actions={[
                (editRow: Notification) => ({
                    tooltip: loco.common.approve,
                    icon: forwardRef(function Icon(props, ref: any) {
                        return <Edit style={{ color: '#666666' }} ref={ref} {...props} />
                    }) as any,
                    onClick: (e) => {
                        return history.push(getRouteWithId(Routes.NOTIFICATION_REVIEW, editRow.id))
                    }
                })
            ]}
        />
    )
}

export default NotificationTable
