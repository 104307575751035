import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            fill="none"
            viewBox="0 0 48 48"
            {...props}
        >
            <circle cx="24" cy="24" r="24" fill="#F59100" opacity="0.1"></circle>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M35.25 24.75V18H28.5"
            ></path>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M35.25 18l-7.939 7.939a1.5 1.5 0 01-2.122 0l-3.128-3.128a1.5 1.5 0 00-2.122 0L12.75 30"
            ></path>
        </svg>
    )
}

export default Icon
