import { Fade } from 'react-reveal'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import { useInView } from 'react-intersection-observer'

import * as loco from '@loco'
import { Routes } from '@variables'
import IconImg1 from '@static/images/lp-func-1.png'
import IconImg2 from '@static/images/lp-func-2.png'
import IconImg3 from '@static/images/lp-func-3.png'
import IconImg4 from '@static/images/lp-func-4.png'
import { Row, Item, Title, Wrapper, ItemText, TopTitle, ItemTitle, Img } from './styled'

const Functions = () => {
    const history = useHistory()
    const { ref, inView } = useInView({
        threshold: 0.2
    })

    useEffect(() => {
        if (inView) {
            history.replace({ hash: Routes.ABOUT_HASH_PARAM })
        }
    }, [inView])

    return (
        <Wrapper id={Routes.ABOUT_HASH_PARAM}>
            <Container ref={ref}>
                <Fade>
                    <TopTitle>{loco.lp.functions.topTitle}</TopTitle>
                </Fade>
                <Fade>
                    <Title
                        variant="h2"
                        dangerouslySetInnerHTML={{ __html: loco.lp.functions.title }}
                    ></Title>
                </Fade>

                <Row>
                    <Fade delay={100}>
                        <Item>
                            <Img src={IconImg1} loading="lazy" width="70" height="70" />
                            <ItemTitle
                                variant="h3"
                                align="left"
                                gutterBottom
                                dangerouslySetInnerHTML={{
                                    __html: loco.lp.functions.item1.title
                                }}
                            ></ItemTitle>
                            <ItemText
                                align="left"
                                dangerouslySetInnerHTML={{
                                    __html: loco.lp.functions.item1.text
                                }}
                            ></ItemText>
                        </Item>
                    </Fade>
                    <Fade delay={200}>
                        <Item>
                            <Img src={IconImg2} loading="lazy" width="70" height="70" />
                            <ItemTitle
                                variant="h3"
                                align="left"
                                gutterBottom
                                dangerouslySetInnerHTML={{
                                    __html: loco.lp.functions.item2.title
                                }}
                            ></ItemTitle>
                            <ItemText
                                align="left"
                                dangerouslySetInnerHTML={{
                                    __html: loco.lp.functions.item2.text
                                }}
                            ></ItemText>
                        </Item>
                    </Fade>
                    <Fade delay={300}>
                        <Item>
                            <Img src={IconImg3} loading="lazy" width="70" height="70" />
                            <ItemTitle
                                variant="h3"
                                align="left"
                                gutterBottom
                                dangerouslySetInnerHTML={{
                                    __html: loco.lp.functions.item3.title
                                }}
                            ></ItemTitle>
                            <ItemText
                                align="left"
                                dangerouslySetInnerHTML={{
                                    __html: loco.lp.functions.item3.text
                                }}
                            ></ItemText>
                        </Item>
                    </Fade>
                    <Fade delay={400}>
                        <Item>
                            <Img src={IconImg4} loading="lazy" width="70" height="70" />
                            <ItemTitle
                                variant="h3"
                                align="left"
                                gutterBottom
                                dangerouslySetInnerHTML={{
                                    __html: loco.lp.functions.item4.title
                                }}
                            ></ItemTitle>
                            <ItemText
                                align="left"
                                dangerouslySetInnerHTML={{
                                    __html: loco.lp.functions.item4.text
                                }}
                            ></ItemText>
                        </Item>
                    </Fade>
                </Row>
            </Container>
        </Wrapper>
    )
}

export default Functions
