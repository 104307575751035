import React from 'react'

import { colors } from '@variables'

const Grid = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="18"
            fill="none"
            viewBox="0 0 25 18"
            {...props}
        >
            <rect width="7" height="7" y="11" fill={colors.primary.orange} rx="2"></rect>
            <rect width="7" height="7" fill={colors.primary.orange} rx="2"></rect>
            <rect width="7" height="7" x="9" y="11" fill={colors.primary.orange} rx="2"></rect>
            <rect width="7" height="7" x="9" fill={colors.primary.orange} rx="2"></rect>
            <rect width="7" height="7" x="18" y="11" fill={colors.primary.orange} rx="2"></rect>
            <rect width="7" height="7" x="18" fill={colors.primary.orange} rx="2"></rect>
        </svg>
    )
}

export default Grid
