import React from 'react'
import { Grid, Typography, CircularProgress } from '@material-ui/core'

import { LoadMore } from '../styled'
import { TabPanel, Props } from '..'
import * as loco from '@loco'
import { colors } from '@variables'
import AudioBox from '../../shared/AudioBox'
import { LibraryCategory, AudioFragment } from '@graphql'

const AudioLibrary = ({ selected, maxItems, loadMore, data, isLoading }: Props) => (
    <TabPanel value={selected} index={LibraryCategory.AUDIO}>
        <Grid container spacing={2}>
            {data.length === 0 && !isLoading && (
                <Typography variant="h6" align="center" style={{ paddingTop: 80, width: '100%' }}>
                    {loco.library.search['no-results']}
                </Typography>
            )}

            {isLoading && data.length === 0 && (
                <CircularProgress
                    style={{
                        display: 'block',
                        margin: '100px auto 0 auto'
                    }}
                />
            )}

            {data
                // @ts-ignore
                .map((audio: AudioFragment) =>
                    audio?.src ? (
                        <Grid item xs={12} sm={6} md={3} key={audio.id}>
                            <AudioBox
                                id={audio.id}
                                name={audio.name}
                                src={audio?.src || '#'}
                                duration={audio?.length}
                                playBtnColor={colors.primary.orange}
                            />
                        </Grid>
                    ) : null
                )}
        </Grid>
        {data.length > 0 && maxItems > data.length && (
            <LoadMore onClick={loadMore} disabled={isLoading}>
                {isLoading ? `${loco.library.more} ..` : loco.library.more}
            </LoadMore>
        )}
    </TabPanel>
)

export default AudioLibrary
