import Button from '@material-ui/core/Button'
import Scrollbar from 'react-scrollbars-custom'
import React, { CSSProperties, useContext } from 'react'

import Leader from './Leader'
import * as loco from '@loco'
import { Outer, Wrapper } from './styled'
import NoStatistics from './NoStatistics'
import Avatar from '@static/images/avatar.png'
import { AuthContext } from '../../context/Auth'
import Dialog from '../../components/Dialogs/Leaderboard'
import { LeaderboardQuery, LeaderboardFilterEnum } from '@graphql'

type Data = LeaderboardQuery['leaderboard']['data'][0]

type Props = {
    data: Data[]
    scrollbarRef: any
    toggleOpen: () => void
    isOpen: boolean
    findYourSelf: () => void
    onScroll: (state: {
        scrollTop: number
        clientHeight: number
        contentScrollHeight: number
    }) => void
    filters: LeaderboardFilterEnum[]
    setLeaderboardFilters: (f: LeaderboardFilterEnum[]) => void
    style?: CSSProperties
}

const Leaderboard = ({
    data,
    filters,
    onScroll,
    isOpen,
    toggleOpen,
    findYourSelf,
    scrollbarRef,
    setLeaderboardFilters,
    style = {}
}: Props) => {
    const { user } = useContext(AuthContext)
    const isEmpty =
        !Boolean(user?.district?.id) &&
        !Boolean(user?.investmentExperience?.value) &&
        !Boolean(user?.lifeStage?.value)

    if (isEmpty) return <NoStatistics />

    return (
        <>
            <Dialog
                data={data}
                isOpen={isOpen}
                scrollbarRef={scrollbarRef}
                filters={filters}
                onScroll={onScroll}
                toggleOpen={toggleOpen}
                findYourSelf={findYourSelf}
                setLeaderboardFilters={setLeaderboardFilters}
            />

            <Outer>
                <Scrollbar
                    noScrollX
                    onScrollStop={onScroll}
                    style={{ height: 420, ...style }}
                    thumbYProps={{ className: 'thumbY' }}
                    trackYProps={{ className: 'trackY' }}
                >
                    <Wrapper>
                        {data.map((item: Data, idx: number) => (
                            <Leader
                                idx={idx}
                                place={item.rank}
                                name={item.username}
                                score={item.points}
                                key={`${item.username}-${idx}`}
                                avatar={item?.profilePicture || Avatar}
                            />
                        ))}
                    </Wrapper>
                </Scrollbar>
                <Button size="large" color="primary" variant="outlined" onClick={toggleOpen}>
                    {loco.leaderboard_show_dialog}
                </Button>
            </Outer>
        </>
    )
}

export default Leaderboard
