import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import { colors, media } from '@variables'

export const Name = styled(Typography)``
export const Score = styled(Typography)``
export const Place = styled(Typography)``

export const Left = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`

export const Right = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`

export const Wrapper = styled.div<{ readonly active: boolean; readonly place: string }>`
    width: 100%;
    display: flex;
    border-radius: 4px;
    flex-direction: row;
    align-items: center;
    position: relative;
    justify-content: space-between;

    padding: 15px 10px;
    margin: 12px 0;

    ${media.phone} {
        margin: 0;
        padding: 15px 20px;
    }

    @media (max-width: 479px) {
        :before {
            content: '${(props) => props.place}.';
            position: absolute;
            top: -20px;
            left: 0;
            font-weight: 500;
            font-size: 14px;
        }
    }

    ${(props) => props.active && ` background: hsla(36,100%,48%,0.2); `}

    ${Place}, ${Name}, ${Score} {
        margin: 0;
    }

    ${Place} {
        display: none;
        font-size: 14px;
        font-weight: 500;

        ${media.phone} {
            display: block;
        }
    }
    ${Name} {
        font-weight: bold;
    }
    ${Score} {
        font-size: 14px;
        font-weight: bold;
        padding-right: 10px;

        ${media.phone} {
            font-size: 18px;
            padding-right: 15px;
        }

        ${(props) => props.active && ` color: ${colors.primary.orange}; `}
    }
`

export const Avatar = styled.img`
    border-radius: 4px;
    object-fit: cover;
    height: 52px;
    width: 52px;

    margin: 0 10px 0 0;

    ${media.phone} {
        margin: 0 25px 0 10px;
    }
`
