import React, { useContext } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import './App.css'
import { routes } from './routes'
import Nav from './components/Nav'
import Footer from './components/Footer'
import Loader from './components/shared/Loader'
import AudioPlayer from './components/AudioPlayer'
import CookieBanner from './components/CookieBanner'
import LoginDialog from './components/Dialogs/Login'
import BadgeDialog from './components/Dialogs/Badge'
import InviteDialog from './components/Dialogs/Invite'
import InfoDialog from './components/Dialogs/GlobalInfo'
import { AuthContext } from './context/Auth'
import RegistrationDialog from './components/Dialogs/Registration'
import NotificationsDialog from './components/Dialogs/Notifications'
import { WrapperForHeader as Wrapper } from './components/shared/styled'
import { useCanAccessAdminLazyQuery } from '@graphql'

const App = () => {
    const { user, loading } = useContext(AuthContext)

    const location = useLocation()
    const [canAccess, { called }] = useCanAccessAdminLazyQuery()

    if (location.pathname.startsWith('/admin') && !called) {
        canAccess()
    }

    if (loading) return <Loader />

    return (
        <>
            <Nav />
            <AudioPlayer />

            <InfoDialog />
            <BadgeDialog />
            <LoginDialog />
            <InviteDialog />
            <RegistrationDialog />
            <NotificationsDialog />

            <Switch>
                {routes(user?.role).map((route, index) => (
                    <Route
                        exact
                        path={route.path}
                        key={route.path.toString() + index}
                        render={() => (
                            <Wrapper route={route.path.toString()}>
                                <route.component />
                            </Wrapper>
                        )}
                    ></Route>
                ))}
            </Switch>

            <Footer />
        </>
    )
}

export default App
