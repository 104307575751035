import React from 'react'

function Icon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            fill="none"
            viewBox="0 0 48 48"
            {...props}
        >
            <circle cx="24" cy="24" r="24" fill="#F59100" opacity="0.1"></circle>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M24 13c4.418 0 8 3.379 8 7.546 0 3.22-4.779 9.351-6.95 11.97-.252.306-.64.484-1.05.484-.41 0-.798-.178-1.05-.483-2.171-2.618-6.95-8.75-6.95-11.969 0-2.002.842-3.921 2.343-5.337C19.843 13.795 21.878 13 24 13v0z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#000046"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M24 18a1.5 1.5 0 110 3 1.5 1.5 0 010-3"
            ></path>
        </svg>
    )
}

export default Icon
