import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  DateTime: any,
  /** The `Upload` scalar type represents a file upload. */
  Upload: any,
};

export enum ActivityType {
  APPROVED = 'APPROVED',
  BLOCKED = 'BLOCKED',
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  REJECTED = 'REJECTED',
  UNBLOCKED = 'UNBLOCKED',
  PUBLISHED = 'PUBLISHED',
  UPDATED = 'UPDATED'
}

export type Answer = {
   __typename?: 'Answer',
  id: Scalars['String'],
  text: Scalars['String'],
  points: Scalars['Int'],
  isCorrect: Scalars['Boolean'],
};

export type AnswerDraft = {
   __typename?: 'AnswerDraft',
  id: Scalars['String'],
  text: Scalars['String'],
};

export type AnswerDraftListRelationFilter = {
  every?: Maybe<AnswerDraftWhereInput>,
  some?: Maybe<AnswerDraftWhereInput>,
  none?: Maybe<AnswerDraftWhereInput>,
};

export type AnswerDraftWhereInput = {
  AND?: Maybe<Array<AnswerDraftWhereInput>>,
  OR?: Maybe<Array<AnswerDraftWhereInput>>,
  NOT?: Maybe<Array<AnswerDraftWhereInput>>,
  id?: Maybe<StringFilter>,
  answerId?: Maybe<StringFilter>,
  answer?: Maybe<AnswerWhereInput>,
  createdAt?: Maybe<DateTimeFilter>,
  isCorrect?: Maybe<BoolFilter>,
  points?: Maybe<IntFilter>,
  questionId?: Maybe<StringFilter>,
  text?: Maybe<StringFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  question?: Maybe<QuestionDraftWhereInput>,
};

export type AnswerDraftWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type AnswerListRelationFilter = {
  every?: Maybe<AnswerWhereInput>,
  some?: Maybe<AnswerWhereInput>,
  none?: Maybe<AnswerWhereInput>,
};

export type AnswerQuestionUserListRelationFilter = {
  every?: Maybe<AnswerQuestionUserWhereInput>,
  some?: Maybe<AnswerQuestionUserWhereInput>,
  none?: Maybe<AnswerQuestionUserWhereInput>,
};

export type AnswerQuestionUserWhereInput = {
  AND?: Maybe<Array<AnswerQuestionUserWhereInput>>,
  OR?: Maybe<Array<AnswerQuestionUserWhereInput>>,
  NOT?: Maybe<Array<AnswerQuestionUserWhereInput>>,
  id?: Maybe<StringFilter>,
  answer?: Maybe<StringFilter>,
  questionId?: Maybe<StringFilter>,
  userId?: Maybe<StringFilter>,
  question?: Maybe<SecurityQuestionWhereInput>,
  user?: Maybe<UserWhereInput>,
};

export type AnswerWhereInput = {
  AND?: Maybe<Array<AnswerWhereInput>>,
  OR?: Maybe<Array<AnswerWhereInput>>,
  NOT?: Maybe<Array<AnswerWhereInput>>,
  id?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  isCorrect?: Maybe<BoolFilter>,
  points?: Maybe<IntFilter>,
  questionId?: Maybe<StringFilter>,
  text?: Maybe<StringFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  question?: Maybe<QuestionWhereInput>,
  dailyQuestions?: Maybe<DailyQuestionListRelationFilter>,
  testSteps?: Maybe<TestStepListRelationFilter>,
  drafts?: Maybe<AnswerDraftListRelationFilter>,
};

export type AnswerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type AppleLoginInput = {
  authorizationCode: Scalars['String'],
  isMobile?: Maybe<Scalars['Boolean']>,
  deviceId?: Maybe<Scalars['String']>,
};

export type Audio = Media & {
   __typename?: 'Audio',
  id: Scalars['ID'],
  name: Scalars['String'],
  author?: Maybe<User>,
  src?: Maybe<Scalars['String']>,
  mimetype: Scalars['String'],
  extention: Scalars['String'],
  filename: Scalars['String'],
  preview?: Maybe<Scalars['String']>,
  length?: Maybe<Scalars['Float']>,
  lecture: Lecture,
  similarItems: Array<LibraryItem>,
};

export type AuthorWhereId = {
  id?: Maybe<Scalars['ID']>,
};

export type AuthUser = {
   __typename?: 'AuthUser',
  accessToken: Scalars['String'],
  refreshToken: Scalars['String'],
  username?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  role: Role,
  isTemporary: Scalars['Boolean'],
  isReactivated: Scalars['Boolean'],
  lastLogin?: Maybe<Scalars['DateTime']>,
};

export type Badge = {
   __typename?: 'Badge',
  id: Scalars['Int'],
  description: Scalars['String'],
  name: Scalars['String'],
  icon: Icon,
};

export type BadgeBoard = {
   __typename?: 'BadgeBoard',
  id: Scalars['String'],
  student: Student,
  isAccepted: Scalars['Boolean'],
  badge: BadgeBoardBadge,
};

export type BadgeBoardBadge = {
   __typename?: 'BadgeBoardBadge',
  id: Scalars['Int'],
  description: Scalars['String'],
  name: Scalars['String'],
  icon: Icon,
};

export type BadgeBoardListRelationFilter = {
  every?: Maybe<BadgeBoardWhereInput>,
  some?: Maybe<BadgeBoardWhereInput>,
  none?: Maybe<BadgeBoardWhereInput>,
};

export type BadgeBoardWhereInput = {
  AND?: Maybe<Array<BadgeBoardWhereInput>>,
  OR?: Maybe<Array<BadgeBoardWhereInput>>,
  NOT?: Maybe<Array<BadgeBoardWhereInput>>,
  id?: Maybe<StringFilter>,
  badgeId?: Maybe<IntFilter>,
  badge?: Maybe<BadgeWhereInput>,
  studentId?: Maybe<StringFilter>,
  student?: Maybe<StudentWhereInput>,
  isAccepted?: Maybe<BoolFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
};

export type BadgeBoardWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type BadgeListRelationFilter = {
  every?: Maybe<BadgeWhereInput>,
  some?: Maybe<BadgeWhereInput>,
  none?: Maybe<BadgeWhereInput>,
};

export type BadgeWhereInput = {
  AND?: Maybe<Array<BadgeWhereInput>>,
  OR?: Maybe<Array<BadgeWhereInput>>,
  NOT?: Maybe<Array<BadgeWhereInput>>,
  id?: Maybe<IntFilter>,
  description?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  iconId?: Maybe<StringFilter>,
  icon?: Maybe<IconWhereInput>,
  badgeBoard?: Maybe<BadgeBoardListRelationFilter>,
};

export type BadgeWithData = {
   __typename?: 'BadgeWithData',
  badge: Badge,
  isAccepted: Scalars['Boolean'],
  isAcquired: Scalars['Boolean'],
};

export type BatchCustomNotifications = {
   __typename?: 'BatchCustomNotifications',
  data: Array<CustomNotification>,
  meta: BatchMeta,
};

export enum BatchCustomNotificationsOrderByColumnInput {
  createdAt = 'createdAt',
  title = 'title',
  body = 'body',
  runAt = 'runAt',
  status = 'status',
  author = 'author'
}

export type BatchCustomNotificationsOrderByInput = {
  column: BatchCustomNotificationsOrderByColumnInput,
  direction?: Maybe<OrderByDirectionInput>,
};

export type BatchDiplomas = {
   __typename?: 'BatchDiplomas',
  data: Array<Diploma>,
  meta: BatchMeta,
};

export type BatchLeaderboard = {
   __typename?: 'BatchLeaderboard',
  data: Array<Leaderboard>,
  meta: BatchMeta,
};

export enum BatchLibraryOrderByColumnInput {
  word = 'word',
  author = 'author'
}

export type BatchLibraryOrderByInput = {
  column: BatchLibraryOrderByColumnInput,
  direction?: Maybe<OrderByDirectionInput>,
};

export type BatchMeta = {
   __typename?: 'BatchMeta',
  itemsPerPage: Scalars['Int'],
  page: Scalars['Int'],
  pages: Scalars['Int'],
  items: Scalars['Int'],
};

export type BatchNotifications = {
   __typename?: 'BatchNotifications',
  data: Array<NotificationRecord>,
  meta: BatchMeta,
};

export type BatchPayload = {
   __typename?: 'BatchPayload',
  count: Scalars['Int'],
};

export type BatchStudentActivity = {
   __typename?: 'BatchStudentActivity',
  data: Array<StudentActivity>,
  meta: BatchMeta,
};

export type BatchUserActivity = {
   __typename?: 'BatchUserActivity',
  data: Array<Log>,
  meta: BatchMeta,
};

export type BatchUsers = {
   __typename?: 'BatchUsers',
  data: Array<User>,
  meta: BatchMeta,
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>,
  not?: Maybe<NestedBoolFilter>,
};

export type BoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>,
  not?: Maybe<NestedBoolNullableFilter>,
};

export type ChangePasswordInput = {
  currentPassword: Scalars['String'],
  newPassword: Scalars['String'],
};

export type Chapter = {
   __typename?: 'Chapter',
  id: Scalars['String'],
  name: Scalars['String'],
  description: Scalars['String'],
  icon: Icon,
  author: User,
  index: Scalars['Int'],
  status: CreationStatus,
  drafts: Array<ChapterDraft>,
  /** Time length of a chapter in seconds */
  totalLength: Scalars['Float'],
  summaryTest?: Maybe<Test>,
  lectures: Array<Lecture>,
  maxPoints?: Maybe<Scalars['Int']>,
  acquiredPoints?: Maybe<Scalars['Int']>,
  progress?: Maybe<Scalars['Int']>,
  isLastActive: Scalars['Boolean'],
};


export type ChapterdraftsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<ChapterDraftWhereUniqueInput>,
  after?: Maybe<ChapterDraftWhereUniqueInput>
};

export type ChapterDraft = {
   __typename?: 'ChapterDraft',
  id: Scalars['String'],
  name: Scalars['String'],
  description: Scalars['String'],
  icon: Icon,
  isMerged: Scalars['Boolean'],
  chapter: Chapter,
  author: User,
  isRejected: Scalars['Boolean'],
};

export type ChapterDraftListRelationFilter = {
  every?: Maybe<ChapterDraftWhereInput>,
  some?: Maybe<ChapterDraftWhereInput>,
  none?: Maybe<ChapterDraftWhereInput>,
};

export type ChapterDraftWhereInput = {
  AND?: Maybe<Array<ChapterDraftWhereInput>>,
  OR?: Maybe<Array<ChapterDraftWhereInput>>,
  NOT?: Maybe<Array<ChapterDraftWhereInput>>,
  id?: Maybe<StringFilter>,
  draftDescription?: Maybe<StringNullableFilter>,
  chapterId?: Maybe<StringFilter>,
  chapter?: Maybe<ChapterWhereInput>,
  authorId?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  description?: Maybe<StringFilter>,
  iconId?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  author?: Maybe<UserWhereInput>,
  icon?: Maybe<IconWhereInput>,
  testDrafts?: Maybe<TestDraftListRelationFilter>,
  isMerged?: Maybe<BoolFilter>,
  isRejected?: Maybe<BoolFilter>,
};

export type ChapterDraftWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type ChapterListRelationFilter = {
  every?: Maybe<ChapterWhereInput>,
  some?: Maybe<ChapterWhereInput>,
  none?: Maybe<ChapterWhereInput>,
};

export type ChapterUniqueRequiredInput = {
  id: Scalars['String'],
};

export type ChapterWhereInput = {
  AND?: Maybe<Array<ChapterWhereInput>>,
  OR?: Maybe<Array<ChapterWhereInput>>,
  NOT?: Maybe<Array<ChapterWhereInput>>,
  id?: Maybe<StringFilter>,
  authorId?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  description?: Maybe<StringFilter>,
  iconId?: Maybe<StringFilter>,
  index?: Maybe<IntFilter>,
  name?: Maybe<StringFilter>,
  status?: Maybe<CreationStatus>,
  updatedAt?: Maybe<DateTimeFilter>,
  author?: Maybe<UserWhereInput>,
  icon?: Maybe<IconWhereInput>,
  isDeleted?: Maybe<BoolFilter>,
  lectures?: Maybe<LectureListRelationFilter>,
  tests?: Maybe<TestListRelationFilter>,
  lectureDrafts?: Maybe<LectureDraftListRelationFilter>,
  drafts?: Maybe<ChapterDraftListRelationFilter>,
};

export type ChapterWhereUniqueInput = {
  id: Scalars['ID'],
};

export type CreateChapterInput = {
  name: Scalars['String'],
  description: Scalars['String'],
  icon: IconInput,
};

export type CreateLectureFileInput = {
  filename: Scalars['String'],
  type: FileType,
  gcsId: Scalars['String'],
};

export type CreateLectureInput = {
  name: Scalars['String'],
  chapter: ChapterWhereUniqueInput,
  description: Scalars['String'],
  summary: Scalars['String'],
  transcript: Scalars['String'],
  video: GcsId,
  videoPreview: GcsId,
  audio: GcsId,
};

export type CreateResumableUploadInput = {
  type?: Maybe<Scalars['String']>,
  filename?: Maybe<Scalars['String']>,
  prefix?: Maybe<Scalars['String']>,
};

export type CreateTestSessionInput = {
  test: TestWhereUniqueInput,
};

export type CreateWordInput = {
  word: Scalars['String'],
  description: Scalars['String'],
};

export enum CreationStatus {
  APPROVED = 'APPROVED',
  BLOCKED = 'BLOCKED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export type CustomNotification = {
   __typename?: 'CustomNotification',
  id: Scalars['String'],
  title: Scalars['String'],
  runAt: Scalars['DateTime'],
  body?: Maybe<Scalars['String']>,
  repeat: NotificationSchedule,
  status: CreationStatus,
  author: User,
  createdAt: Scalars['DateTime'],
};

export type CustomNotificationFilter = {
  search?: Maybe<Scalars['String']>,
  status?: Maybe<CreationStatus>,
  author?: Maybe<AuthorWhereId>,
};

export type CustomNotificationInput = {
  title: Scalars['String'],
  body?: Maybe<Scalars['String']>,
  runAt: Scalars['DateTime'],
  repeat: NotificationSchedule,
};

export type CustomNotificationListRelationFilter = {
  every?: Maybe<CustomNotificationWhereInput>,
  some?: Maybe<CustomNotificationWhereInput>,
  none?: Maybe<CustomNotificationWhereInput>,
};

export type CustomNotificationWhereInput = {
  AND?: Maybe<Array<CustomNotificationWhereInput>>,
  OR?: Maybe<Array<CustomNotificationWhereInput>>,
  NOT?: Maybe<Array<CustomNotificationWhereInput>>,
  id?: Maybe<StringFilter>,
  title?: Maybe<StringFilter>,
  body?: Maybe<StringNullableFilter>,
  runAt?: Maybe<DateTimeFilter>,
  repeat?: Maybe<NotificationSchedule>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  authorId?: Maybe<StringFilter>,
  author?: Maybe<UserWhereInput>,
  status?: Maybe<CreationStatus>,
  isDeleted?: Maybe<BoolFilter>,
  notificationLog?: Maybe<NotificationLogListRelationFilter>,
};

export type CustomNotificationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type DailyQuestion = {
   __typename?: 'DailyQuestion',
  question?: Maybe<DailyQuestionQuestion>,
  answers?: Maybe<Array<DailyQuestionAnswer>>,
};

export type DailyQuestionAnswer = {
   __typename?: 'DailyQuestionAnswer',
  text: Scalars['String'],
  id: Scalars['ID'],
};

export type DailyQuestionListRelationFilter = {
  every?: Maybe<DailyQuestionWhereInput>,
  some?: Maybe<DailyQuestionWhereInput>,
  none?: Maybe<DailyQuestionWhereInput>,
};

export type DailyQuestionQuestion = {
   __typename?: 'DailyQuestionQuestion',
  text: Scalars['String'],
  id: Scalars['ID'],
};

export type DailyQuestionResult = {
   __typename?: 'DailyQuestionResult',
  isCorrect: Scalars['Boolean'],
  question: DailyQuestionQuestion,
  answer: DailyQuestionAnswer,
  correctAnswer: DailyQuestionAnswer,
  description?: Maybe<Scalars['String']>,
  acquiredPoints: Scalars['Int'],
};

export type DailyQuestionWhereInput = {
  AND?: Maybe<Array<DailyQuestionWhereInput>>,
  OR?: Maybe<Array<DailyQuestionWhereInput>>,
  NOT?: Maybe<Array<DailyQuestionWhereInput>>,
  id?: Maybe<StringFilter>,
  acquiredPoints?: Maybe<IntFilter>,
  answerId?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  isCorrect?: Maybe<BoolFilter>,
  questionId?: Maybe<StringFilter>,
  studentId?: Maybe<StringFilter>,
  answer?: Maybe<AnswerWhereInput>,
  question?: Maybe<QuestionWhereInput>,
  student?: Maybe<StudentWhereInput>,
};


export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>,
  in?: Maybe<Array<Scalars['DateTime']>>,
  notIn?: Maybe<Array<Scalars['DateTime']>>,
  lt?: Maybe<Scalars['DateTime']>,
  lte?: Maybe<Scalars['DateTime']>,
  gt?: Maybe<Scalars['DateTime']>,
  gte?: Maybe<Scalars['DateTime']>,
  not?: Maybe<NestedDateTimeFilter>,
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>,
  in?: Maybe<Array<Scalars['DateTime']>>,
  notIn?: Maybe<Array<Scalars['DateTime']>>,
  lt?: Maybe<Scalars['DateTime']>,
  lte?: Maybe<Scalars['DateTime']>,
  gt?: Maybe<Scalars['DateTime']>,
  gte?: Maybe<Scalars['DateTime']>,
  not?: Maybe<NestedDateTimeNullableFilter>,
};

export type Diploma = {
   __typename?: 'Diploma',
  id: Scalars['String'],
  student: Student,
  points: Scalars['Int'],
  code: Scalars['String'],
  createdAt: Scalars['DateTime'],
};

export type DiplomaListRelationFilter = {
  every?: Maybe<DiplomaWhereInput>,
  some?: Maybe<DiplomaWhereInput>,
  none?: Maybe<DiplomaWhereInput>,
};

export type DiplomasFilterInput = {
  search?: Maybe<Scalars['String']>,
  createdFrom?: Maybe<Scalars['DateTime']>,
  createdUntil?: Maybe<Scalars['DateTime']>,
};

export enum DiplomasOrderByColumnInput {
  createdAt = 'createdAt'
}

export type DiplomasOrderByInput = {
  column: DiplomasOrderByColumnInput,
  direction?: Maybe<OrderByDirectionInput>,
};

export type DiplomaWhereInput = {
  AND?: Maybe<Array<DiplomaWhereInput>>,
  OR?: Maybe<Array<DiplomaWhereInput>>,
  NOT?: Maybe<Array<DiplomaWhereInput>>,
  id?: Maybe<StringFilter>,
  studentId?: Maybe<StringFilter>,
  student?: Maybe<StudentWhereInput>,
  code?: Maybe<StringFilter>,
  points?: Maybe<IntFilter>,
  createdAt?: Maybe<DateTimeFilter>,
};

export type DiplomaWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
  code?: Maybe<Scalars['String']>,
};

export type District = {
   __typename?: 'District',
  id: Scalars['String'],
  name: Scalars['String'],
};

export type DistrictWhereInput = {
  AND?: Maybe<Array<DistrictWhereInput>>,
  OR?: Maybe<Array<DistrictWhereInput>>,
  NOT?: Maybe<Array<DistrictWhereInput>>,
  id?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  User?: Maybe<UserListRelationFilter>,
};

export type DistrictWhereUniqueInput = {
  id: Scalars['ID'],
};

export type Document = {
   __typename?: 'Document',
  id: Scalars['String'],
  mimetype: Scalars['String'],
  size: Scalars['Int'],
  extention: Scalars['String'],
  name: Scalars['String'],
  createdAt: Scalars['DateTime'],
  filename: Scalars['String'],
  author?: Maybe<User>,
  src?: Maybe<Scalars['String']>,
};

export type DocumentBatchResult = {
   __typename?: 'DocumentBatchResult',
  data: Array<Document>,
  meta: BatchMeta,
};

export type DocumentListRelationFilter = {
  every?: Maybe<DocumentWhereInput>,
  some?: Maybe<DocumentWhereInput>,
  none?: Maybe<DocumentWhereInput>,
};

export type DocumentsFilterInput = {
  search?: Maybe<Scalars['String']>,
};

export enum DocumentsOrderByColumnInput {
  createdAt = 'createdAt',
  name = 'name',
  filename = 'filename',
  size = 'size',
  extention = 'extention',
  author = 'author'
}

export type DocumentsOrderByInput = {
  column: DocumentsOrderByColumnInput,
  direction?: Maybe<OrderByDirectionInput>,
};

export type DocumentWhereInput = {
  AND?: Maybe<Array<DocumentWhereInput>>,
  OR?: Maybe<Array<DocumentWhereInput>>,
  NOT?: Maybe<Array<DocumentWhereInput>>,
  id?: Maybe<StringFilter>,
  extention?: Maybe<StringFilter>,
  filename?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  gcsId?: Maybe<StringFilter>,
  mimetype?: Maybe<StringFilter>,
  size?: Maybe<IntFilter>,
  authorId?: Maybe<StringNullableFilter>,
  author?: Maybe<UserWhereInput>,
  isDeleted?: Maybe<BoolFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
};

export type DocumentWhereUniqueInput = {
  id: Scalars['String'],
};

export enum EntityType {
  ANSWER = 'ANSWER',
  CHAPTER = 'CHAPTER',
  LECTURE = 'LECTURE',
  QUESTION = 'QUESTION',
  TEST = 'TEST',
  USER = 'USER',
  WORD = 'WORD',
  NOTIFICATION = 'NOTIFICATION',
  DOCUMENT = 'DOCUMENT',
  LECTURE_DRAFT = 'LECTURE_DRAFT',
  CHAPTER_DRAFT = 'CHAPTER_DRAFT'
}

export type FacebookLoginInput = {
  accessToken: Scalars['String'],
  deviceId?: Maybe<Scalars['String']>,
};

export type Fcm = {
   __typename?: 'Fcm',
  id: Scalars['String'],
  deviceId: Scalars['String'],
  updatedAt: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  user: User,
  fcmToken: Scalars['String'],
};

export type FcmListRelationFilter = {
  every?: Maybe<FcmWhereInput>,
  some?: Maybe<FcmWhereInput>,
  none?: Maybe<FcmWhereInput>,
};

export type FcmWhereInput = {
  AND?: Maybe<Array<FcmWhereInput>>,
  OR?: Maybe<Array<FcmWhereInput>>,
  NOT?: Maybe<Array<FcmWhereInput>>,
  id?: Maybe<StringFilter>,
  userId?: Maybe<StringFilter>,
  user?: Maybe<UserWhereInput>,
  deviceId?: Maybe<StringFilter>,
  fcmToken?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
};

export enum FileType {
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO'
}

export type FinalTestStatus = {
   __typename?: 'FinalTestStatus',
  isCompleted: Scalars['Boolean'],
  acquiredPoints: Scalars['Int'],
  requiredPoints: Scalars['Int'],
};

export type FloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>,
  in?: Maybe<Array<Scalars['Float']>>,
  notIn?: Maybe<Array<Scalars['Float']>>,
  lt?: Maybe<Scalars['Float']>,
  lte?: Maybe<Scalars['Float']>,
  gt?: Maybe<Scalars['Float']>,
  gte?: Maybe<Scalars['Float']>,
  not?: Maybe<NestedFloatNullableFilter>,
};

export type ForgottenPasswordReset = {
   __typename?: 'ForgottenPasswordReset',
  isEmailReset: Scalars['Boolean'],
  user: User,
};

export type GcsId = {
  gcsId?: Maybe<Scalars['String']>,
};

export type GoogleLoginInput = {
  idToken: Scalars['String'],
  deviceId?: Maybe<Scalars['String']>,
};

export type Icon = {
   __typename?: 'Icon',
  png?: Maybe<Scalars['String']>,
  svg?: Maybe<Scalars['String']>,
};

export type IconInput = {
  svg: GcsId,
  png: GcsId,
};

export type IconUpdateInput = {
  svg?: Maybe<GcsId>,
  png?: Maybe<GcsId>,
};

export type IconWhereInput = {
  AND?: Maybe<Array<IconWhereInput>>,
  OR?: Maybe<Array<IconWhereInput>>,
  NOT?: Maybe<Array<IconWhereInput>>,
  id?: Maybe<StringFilter>,
  png?: Maybe<StringFilter>,
  svg?: Maybe<StringFilter>,
  badge?: Maybe<BadgeListRelationFilter>,
  chapter?: Maybe<ChapterListRelationFilter>,
  notification?: Maybe<NotificationListRelationFilter>,
  chapterDraft?: Maybe<ChapterDraftListRelationFilter>,
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>,
  in?: Maybe<Array<Scalars['Int']>>,
  notIn?: Maybe<Array<Scalars['Int']>>,
  lt?: Maybe<Scalars['Int']>,
  lte?: Maybe<Scalars['Int']>,
  gt?: Maybe<Scalars['Int']>,
  gte?: Maybe<Scalars['Int']>,
  not?: Maybe<NestedIntFilter>,
};

export type Invitation = {
   __typename?: 'Invitation',
  id: Scalars['String'],
  name: Scalars['String'],
  email: Scalars['String'],
  role: Role,
  isAccepted: Scalars['Boolean'],
  invitedBy: User,
};

export type InvitationAcceptInput = {
  token: Scalars['String'],
  password: Scalars['String'],
  passwordAgain: Scalars['String'],
};

export type InvitationCreateInput = {
  name: Scalars['String'],
  email: Scalars['String'],
  role: Role,
};

export type InvitationListRelationFilter = {
  every?: Maybe<InvitationWhereInput>,
  some?: Maybe<InvitationWhereInput>,
  none?: Maybe<InvitationWhereInput>,
};

export type InvitationOrderByInput = {
  id?: Maybe<SortOrder>,
  email?: Maybe<SortOrder>,
  name?: Maybe<SortOrder>,
  role?: Maybe<SortOrder>,
  invitedById?: Maybe<SortOrder>,
  isAccepted?: Maybe<SortOrder>,
  createdAt?: Maybe<SortOrder>,
  updatedAt?: Maybe<SortOrder>,
};

export type InvitationWhereInput = {
  AND?: Maybe<Array<InvitationWhereInput>>,
  OR?: Maybe<Array<InvitationWhereInput>>,
  NOT?: Maybe<Array<InvitationWhereInput>>,
  id?: Maybe<StringFilter>,
  email?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  role?: Maybe<Role>,
  invitedById?: Maybe<StringFilter>,
  invitedBy?: Maybe<UserWhereInput>,
  isAccepted?: Maybe<BoolFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
};

export type InvitationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
};

export type IsEmailUsedReponse = {
   __typename?: 'IsEmailUsedReponse',
  isUsed: Scalars['Boolean'],
  role?: Maybe<Role>,
};

export type LastStartedessionInput = {
  test: TestWhereUniqueInput,
};

export type Leaderboard = {
   __typename?: 'Leaderboard',
  username: Scalars['String'],
  points: Scalars['Int'],
  rank: Scalars['String'],
  studentId: Scalars['String'],
  profilePicture?: Maybe<Scalars['String']>,
};

export enum LeaderboardFilterEnum {
  LIFE_STAGE = 'LIFE_STAGE',
  INVESTMENT_EXPERIENCE = 'INVESTMENT_EXPERIENCE',
  DISTRICT = 'DISTRICT'
}

export type Lecture = {
   __typename?: 'Lecture',
  id: Scalars['String'],
  name: Scalars['String'],
  summary: Scalars['String'],
  transcript: Scalars['String'],
  status: CreationStatus,
  description: Scalars['String'],
  author: User,
  index: Scalars['Int'],
  files: Array<LectureFile>,
  test?: Maybe<Test>,
  drafts: Array<LectureDraft>,
  chapter: Chapter,
  library?: Maybe<Array<LibraryItem>>,
  maxPoints: Scalars['Int'],
  acquiredPoints?: Maybe<Scalars['Int']>,
};


export type LecturefilesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<LectureFileWhereUniqueInput>,
  after?: Maybe<LectureFileWhereUniqueInput>
};


export type LecturedraftsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<LectureDraftWhereUniqueInput>,
  after?: Maybe<LectureDraftWhereUniqueInput>
};

export type LectureDraft = {
   __typename?: 'LectureDraft',
  id: Scalars['String'],
  name: Scalars['String'],
  summary: Scalars['String'],
  transcript: Scalars['String'],
  description: Scalars['String'],
  author: User,
  test?: Maybe<TestDraft>,
  lecture: Lecture,
  files: Array<LectureFile>,
  isMerged: Scalars['Boolean'],
  isRejected: Scalars['Boolean'],
  chapter: Chapter,
};


export type LectureDraftfilesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<LectureFileWhereUniqueInput>,
  after?: Maybe<LectureFileWhereUniqueInput>
};

export type LectureDraftListRelationFilter = {
  every?: Maybe<LectureDraftWhereInput>,
  some?: Maybe<LectureDraftWhereInput>,
  none?: Maybe<LectureDraftWhereInput>,
};

export type LectureDraftWhereInput = {
  AND?: Maybe<Array<LectureDraftWhereInput>>,
  OR?: Maybe<Array<LectureDraftWhereInput>>,
  NOT?: Maybe<Array<LectureDraftWhereInput>>,
  id?: Maybe<StringFilter>,
  draftDescription?: Maybe<StringNullableFilter>,
  lectureId?: Maybe<StringFilter>,
  lecture?: Maybe<LectureWhereInput>,
  authorId?: Maybe<StringFilter>,
  chapterId?: Maybe<StringFilter>,
  description?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  summary?: Maybe<StringFilter>,
  testId?: Maybe<StringNullableFilter>,
  transcript?: Maybe<StringFilter>,
  author?: Maybe<UserWhereInput>,
  chapter?: Maybe<ChapterWhereInput>,
  test?: Maybe<TestDraftWhereInput>,
  files?: Maybe<LectureFileListRelationFilter>,
  isMerged?: Maybe<BoolFilter>,
  isRejected?: Maybe<BoolFilter>,
};

export type LectureDraftWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type LectureFile = {
   __typename?: 'LectureFile',
  id: Scalars['String'],
  extention: Scalars['String'],
  filename: Scalars['String'],
  type: FileType,
  lecture?: Maybe<Lecture>,
  gcsId: Scalars['String'],
  length?: Maybe<Scalars['Float']>,
  preview?: Maybe<Scalars['String']>,
  previewSrc?: Maybe<Scalars['String']>,
  src?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
};

export type LectureFileListRelationFilter = {
  every?: Maybe<LectureFileWhereInput>,
  some?: Maybe<LectureFileWhereInput>,
  none?: Maybe<LectureFileWhereInput>,
};

export type LectureFileWhereInput = {
  AND?: Maybe<Array<LectureFileWhereInput>>,
  OR?: Maybe<Array<LectureFileWhereInput>>,
  NOT?: Maybe<Array<LectureFileWhereInput>>,
  id?: Maybe<StringFilter>,
  lectureId?: Maybe<StringNullableFilter>,
  length?: Maybe<FloatNullableFilter>,
  preview?: Maybe<StringNullableFilter>,
  filename?: Maybe<StringFilter>,
  extention?: Maybe<StringFilter>,
  size?: Maybe<IntFilter>,
  mimetype?: Maybe<StringFilter>,
  gcsId?: Maybe<StringFilter>,
  type?: Maybe<FileType>,
  authorId?: Maybe<StringNullableFilter>,
  author?: Maybe<UserWhereInput>,
  lecture?: Maybe<LectureWhereInput>,
  isDeleted?: Maybe<BoolFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  lectureDraft?: Maybe<LectureDraftWhereInput>,
  lectureDraftId?: Maybe<StringNullableFilter>,
};

export type LectureFileWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type LectureListRelationFilter = {
  every?: Maybe<LectureWhereInput>,
  some?: Maybe<LectureWhereInput>,
  none?: Maybe<LectureWhereInput>,
};

export type LectureUniqueRequiredInput = {
  id: Scalars['String'],
};

export type LectureWhereInput = {
  AND?: Maybe<Array<LectureWhereInput>>,
  OR?: Maybe<Array<LectureWhereInput>>,
  NOT?: Maybe<Array<LectureWhereInput>>,
  id?: Maybe<StringFilter>,
  authorId?: Maybe<StringFilter>,
  chapterId?: Maybe<StringFilter>,
  description?: Maybe<StringFilter>,
  index?: Maybe<IntFilter>,
  name?: Maybe<StringFilter>,
  status?: Maybe<CreationStatus>,
  summary?: Maybe<StringFilter>,
  testId?: Maybe<StringNullableFilter>,
  transcript?: Maybe<StringFilter>,
  isDeleted?: Maybe<BoolFilter>,
  author?: Maybe<UserWhereInput>,
  chapter?: Maybe<ChapterWhereInput>,
  test?: Maybe<TestWhereInput>,
  files?: Maybe<LectureFileListRelationFilter>,
  drafts?: Maybe<LectureDraftListRelationFilter>,
};

export type LectureWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type Library = {
   __typename?: 'Library',
  data: Array<LibraryItem>,
  meta: BatchMeta,
};

export enum LibraryCategory {
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  VOCABULARY = 'VOCABULARY',
  TRANSCRIPT = 'TRANSCRIPT'
}

export type LibraryFilter = {
  type?: Maybe<LibraryCategory>,
  search?: Maybe<Scalars['String']>,
};

export type LibraryItem = Audio | Video | Transcript | Word;

export type LibraryItemWhereInput = {
  id: Scalars['String'],
};

export type Log = {
   __typename?: 'Log',
  id: Scalars['String'],
  activity: ActivityType,
  user: User,
  createdAt: Scalars['DateTime'],
  entity: EntityType,
  data?: Maybe<LogEntity>,
};

export type LogEntity = Answer | Chapter | Lecture | Question | ChapterDraft | LectureDraft | Test | User | Word | Document | CustomNotification;

export type LoginInput = {
  /** Username or e-mail */
  username: Scalars['String'],
  password: Scalars['String'],
  deviceId?: Maybe<Scalars['String']>,
};

export type LogListRelationFilter = {
  every?: Maybe<LogWhereInput>,
  some?: Maybe<LogWhereInput>,
  none?: Maybe<LogWhereInput>,
};

export type LogoutInput = {
  deviceId?: Maybe<Scalars['String']>,
};

export enum LogSort {
  asc = 'asc',
  desc = 'desc'
}

export type LogWhereInput = {
  AND?: Maybe<Array<LogWhereInput>>,
  OR?: Maybe<Array<LogWhereInput>>,
  NOT?: Maybe<Array<LogWhereInput>>,
  id?: Maybe<StringFilter>,
  activity?: Maybe<ActivityType>,
  createdAt?: Maybe<DateTimeFilter>,
  entity?: Maybe<EntityType>,
  entityId?: Maybe<StringFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  userId?: Maybe<StringFilter>,
  user?: Maybe<UserWhereInput>,
};

export type Media = {
  id: Scalars['ID'],
  name: Scalars['String'],
  author?: Maybe<User>,
  src?: Maybe<Scalars['String']>,
  mimetype: Scalars['String'],
  extention: Scalars['String'],
  filename: Scalars['String'],
  preview?: Maybe<Scalars['String']>,
  length?: Maybe<Scalars['Float']>,
  lecture: Lecture,
  similarItems: Array<LibraryItem>,
};

export type Mutation = {
   __typename?: 'Mutation',
  login: AuthUser,
  loginWithApple: AuthUser,
  loginWithGoogle: AuthUser,
  loginWithFacebook: AuthUser,
  updateTemporaryUser: AuthUser,
  changePassword: Scalars['Boolean'],
  refreshAccessToken: AuthUser,
  logout: Scalars['Boolean'],
  updateFcm: Fcm,
  updateSelfUser: User,
  resetPassword: AuthUser,
  forgottenPasswordReset: ForgottenPasswordReset,
  sendEmailVerification: Scalars['Boolean'],
  verifyEmail: Scalars['Boolean'],
  resetPasswordWithEmailToken: AuthUser,
  updateUser: User,
  deleteOwnAccount: User,
  blockUser: User,
  blockAndDeleteUser: User,
  unblockUser: User,
  registerStudent: AuthUser,
  registerTemporaryStudent: TemporaryStudent,
  acceptBadges: Array<BadgeBoard>,
  createChapter: Chapter,
  updateChapter: Chapter,
  approveChapter: Chapter,
  rejectChapter: Chapter,
  deleteChapter: Chapter,
  blockChapter: Chapter,
  publishChapter: Chapter,
  createChapterUpdateDraft: ChapterDraft,
  updateChapterUpdateDraft: ChapterDraft,
  approveChapterUpdateDraft: ChapterDraft,
  rejectChapterUpdateDraft: ChapterDraft,
  deleteDocument: Document,
  renameDocument: Document,
  uploadDocument: Document,
  createResumableUpload: Scalars['String'],
  getSignedUrl: Scalars['String'],
  createLectureFile: LectureFile,
  createLecture: Lecture,
  updateLecture: Lecture,
  approveLecture: Lecture,
  rejectLecture: Lecture,
  deleteLecture: Lecture,
  blockLecture: Lecture,
  publishLecture: Lecture,
  createLectureUpdateDraft: LectureDraft,
  updateLectureUpdateDraft: LectureDraft,
  approveLectureUpdateDraft: LectureDraft,
  rejectLectureUpdateDraft: LectureDraft,
  createTest: Test,
  createTestQuestion: Question,
  createQuestionAnswers: Array<Answer>,
  deleteTestQuestion: Question,
  updateQuestionAnswers: Array<Answer>,
  updateTestQuestion: Question,
  updateQuestionAnswersDraft: Array<Answer>,
  updateTestQuestionDraft: Question,
  createWord: Word,
  deleteWord: Word,
  updateWord: Word,
  updateNotificationSettings: NotificationSettings,
  createDiploma: Diploma,
  submitDailyQuestion: DailyQuestionResult,
  /** Creates a test session for a given student, this session is then used to track the test progress. */
  createTestSession: TestSession,
  nextTestStep: TestStep,
  submitAnswer: TestStep,
  reportIssue: Scalars['Boolean'],
  inviteUser: Invitation,
  acceptInvitation: AuthUser,
  submitQuestionnaire: Questionnaire,
  updateProfilePicture: Scalars['String'],
  addTimeSpent: Scalars['Int'],
  deleteNotifications: BatchPayload,
  approveCustomNotification: CustomNotification,
  rejectCustomNotification: CustomNotification,
  createCustomNotification: CustomNotification,
  updateCustomNotification: CustomNotification,
  deleteCustomNotification: CustomNotification,
};


export type MutationloginArgs = {
  data: LoginInput
};


export type MutationloginWithAppleArgs = {
  data: AppleLoginInput
};


export type MutationloginWithGoogleArgs = {
  data: GoogleLoginInput
};


export type MutationloginWithFacebookArgs = {
  data: FacebookLoginInput
};


export type MutationupdateTemporaryUserArgs = {
  data: UpdateTemporaryUserInput
};


export type MutationchangePasswordArgs = {
  data: ChangePasswordInput
};


export type MutationrefreshAccessTokenArgs = {
  refreshToken: Scalars['String']
};


export type MutationlogoutArgs = {
  data?: Maybe<LogoutInput>
};


export type MutationupdateFcmArgs = {
  data: NotificationTokenUpdateInput
};


export type MutationupdateSelfUserArgs = {
  data: UpdateSelfUserInput
};


export type MutationresetPasswordArgs = {
  data: ResetPasswordInput
};


export type MutationforgottenPasswordResetArgs = {
  data: RequestPasswordResetEmailInput
};


export type MutationverifyEmailArgs = {
  data: VerifyEmailInput
};


export type MutationresetPasswordWithEmailTokenArgs = {
  data: resetPasswordWithEmailToken
};


export type MutationupdateUserArgs = {
  where: UserWhereId,
  data: UserUpdateInput
};


export type MutationblockUserArgs = {
  where: UserWhereId
};


export type MutationblockAndDeleteUserArgs = {
  where: UserWhereId
};


export type MutationunblockUserArgs = {
  where: UserWhereId
};


export type MutationregisterStudentArgs = {
  data: RegisterStudentInput
};


export type MutationregisterTemporaryStudentArgs = {
  deviceId?: Maybe<Scalars['String']>
};


export type MutationacceptBadgesArgs = {
  id: Array<Scalars['ID']>
};


export type MutationcreateChapterArgs = {
  data: CreateChapterInput
};


export type MutationupdateChapterArgs = {
  where: ChapterUniqueRequiredInput,
  data: UpdateChapterInput
};


export type MutationapproveChapterArgs = {
  where: ChapterUniqueRequiredInput
};


export type MutationrejectChapterArgs = {
  where: ChapterUniqueRequiredInput,
  data: RejectChapterInput
};


export type MutationdeleteChapterArgs = {
  where: ChapterUniqueRequiredInput
};


export type MutationblockChapterArgs = {
  where: ChapterUniqueRequiredInput
};


export type MutationpublishChapterArgs = {
  where: ChapterUniqueRequiredInput
};


export type MutationcreateChapterUpdateDraftArgs = {
  where: QuestionWhereUniqueInput
};


export type MutationupdateChapterUpdateDraftArgs = {
  where: ChapterDraftWhereUniqueInput,
  data: UpdateChapterInput
};


export type MutationapproveChapterUpdateDraftArgs = {
  where: ChapterDraftWhereUniqueInput
};


export type MutationrejectChapterUpdateDraftArgs = {
  where: ChapterDraftWhereUniqueInput
};


export type MutationdeleteDocumentArgs = {
  where: DocumentWhereUniqueInput
};


export type MutationrenameDocumentArgs = {
  where: DocumentWhereUniqueInput,
  data: RenameDocumentInput
};


export type MutationuploadDocumentArgs = {
  data: UploadDocumentInput
};


export type MutationcreateResumableUploadArgs = {
  data?: Maybe<CreateResumableUploadInput>
};


export type MutationgetSignedUrlArgs = {
  gcsId: Scalars['String']
};


export type MutationcreateLectureFileArgs = {
  data: CreateLectureFileInput
};


export type MutationcreateLectureArgs = {
  data: CreateLectureInput
};


export type MutationupdateLectureArgs = {
  where: LectureUniqueRequiredInput,
  data: UpdateLectureInput
};


export type MutationapproveLectureArgs = {
  where: LectureUniqueRequiredInput
};


export type MutationrejectLectureArgs = {
  where: LectureUniqueRequiredInput,
  data: RejectLectureInput
};


export type MutationdeleteLectureArgs = {
  where: LectureUniqueRequiredInput
};


export type MutationblockLectureArgs = {
  where: LectureUniqueRequiredInput
};


export type MutationpublishLectureArgs = {
  where: LectureUniqueRequiredInput
};


export type MutationcreateLectureUpdateDraftArgs = {
  where: LectureWhereUniqueInput
};


export type MutationupdateLectureUpdateDraftArgs = {
  where: LectureDraftWhereUniqueInput,
  data: UpdateLectureDraftInput
};


export type MutationapproveLectureUpdateDraftArgs = {
  where: LectureDraftWhereUniqueInput
};


export type MutationrejectLectureUpdateDraftArgs = {
  where: LectureDraftWhereUniqueInput
};


export type MutationcreateTestArgs = {
  lecture: LectureWhereUniqueInput
};


export type MutationcreateTestQuestionArgs = {
  test: TestWhereUniqueInput,
  data: TestQuestionInput
};


export type MutationcreateQuestionAnswersArgs = {
  question: QuestionWhereUniqueInput,
  data: Array<TestAnswerInput>
};


export type MutationdeleteTestQuestionArgs = {
  where: QuestionWhereUniqueInput
};


export type MutationupdateQuestionAnswersArgs = {
  data: Array<TestAnswerUpdateInput>
};


export type MutationupdateTestQuestionArgs = {
  where: QuestionWhereUniqueInput,
  data: TestQuestionUpdateInput
};


export type MutationupdateQuestionAnswersDraftArgs = {
  data: Array<TestAnswerUpdateInput>
};


export type MutationupdateTestQuestionDraftArgs = {
  where: QuestionDraftWhereUniqueInput,
  data: TestQuestionUpdateInput
};


export type MutationcreateWordArgs = {
  data: CreateWordInput
};


export type MutationdeleteWordArgs = {
  where: WordWhereUniqueInput
};


export type MutationupdateWordArgs = {
  where: WordWhereUniqueInput,
  data: UpdateWordInput
};


export type MutationupdateNotificationSettingsArgs = {
  where: NotificationSettingsWhereUniqueInput,
  data: NotificationSettingsUpdateInput
};


export type MutationcreateDiplomaArgs = {
  studentId: Scalars['ID']
};


export type MutationsubmitDailyQuestionArgs = {
  data: SubmitDailyQuestionAnswerInput
};


export type MutationcreateTestSessionArgs = {
  data: CreateTestSessionInput
};


export type MutationnextTestStepArgs = {
  data: NextTestStepInput
};


export type MutationsubmitAnswerArgs = {
  data: SubmitAnswerInput
};


export type MutationreportIssueArgs = {
  question: QuestionWhereUniqueInput,
  issue: Scalars['String']
};


export type MutationinviteUserArgs = {
  data: InvitationCreateInput
};


export type MutationacceptInvitationArgs = {
  data: InvitationAcceptInput
};


export type MutationsubmitQuestionnaireArgs = {
  data: SubmitQuestionnaireInput
};


export type MutationupdateProfilePictureArgs = {
  data: UpdateProfilePictureInput
};


export type MutationaddTimeSpentArgs = {
  seconds: Scalars['Int']
};


export type MutationdeleteNotificationsArgs = {
  id: Array<Scalars['ID']>
};


export type MutationapproveCustomNotificationArgs = {
  where: CustomNotificationWhereUniqueInput
};


export type MutationrejectCustomNotificationArgs = {
  data: RejectCustomNotificationInput,
  where: CustomNotificationWhereUniqueInput
};


export type MutationcreateCustomNotificationArgs = {
  data: CustomNotificationInput
};


export type MutationupdateCustomNotificationArgs = {
  data: CustomNotificationInput,
  where: CustomNotificationWhereUniqueInput
};


export type MutationdeleteCustomNotificationArgs = {
  where: CustomNotificationWhereUniqueInput
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>,
  not?: Maybe<NestedBoolFilter>,
};

export type NestedBoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>,
  not?: Maybe<NestedBoolNullableFilter>,
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>,
  in?: Maybe<Array<Scalars['DateTime']>>,
  notIn?: Maybe<Array<Scalars['DateTime']>>,
  lt?: Maybe<Scalars['DateTime']>,
  lte?: Maybe<Scalars['DateTime']>,
  gt?: Maybe<Scalars['DateTime']>,
  gte?: Maybe<Scalars['DateTime']>,
  not?: Maybe<NestedDateTimeFilter>,
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>,
  in?: Maybe<Array<Scalars['DateTime']>>,
  notIn?: Maybe<Array<Scalars['DateTime']>>,
  lt?: Maybe<Scalars['DateTime']>,
  lte?: Maybe<Scalars['DateTime']>,
  gt?: Maybe<Scalars['DateTime']>,
  gte?: Maybe<Scalars['DateTime']>,
  not?: Maybe<NestedDateTimeNullableFilter>,
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>,
  in?: Maybe<Array<Scalars['Float']>>,
  notIn?: Maybe<Array<Scalars['Float']>>,
  lt?: Maybe<Scalars['Float']>,
  lte?: Maybe<Scalars['Float']>,
  gt?: Maybe<Scalars['Float']>,
  gte?: Maybe<Scalars['Float']>,
  not?: Maybe<NestedFloatNullableFilter>,
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>,
  in?: Maybe<Array<Scalars['Int']>>,
  notIn?: Maybe<Array<Scalars['Int']>>,
  lt?: Maybe<Scalars['Int']>,
  lte?: Maybe<Scalars['Int']>,
  gt?: Maybe<Scalars['Int']>,
  gte?: Maybe<Scalars['Int']>,
  not?: Maybe<NestedIntFilter>,
};

export type NestedStringFilter = {
  equals?: Maybe<Scalars['String']>,
  in?: Maybe<Array<Scalars['String']>>,
  notIn?: Maybe<Array<Scalars['String']>>,
  lt?: Maybe<Scalars['String']>,
  lte?: Maybe<Scalars['String']>,
  gt?: Maybe<Scalars['String']>,
  gte?: Maybe<Scalars['String']>,
  contains?: Maybe<Scalars['String']>,
  startsWith?: Maybe<Scalars['String']>,
  endsWith?: Maybe<Scalars['String']>,
  not?: Maybe<NestedStringFilter>,
};

export type NestedStringNullableFilter = {
  equals?: Maybe<Scalars['String']>,
  in?: Maybe<Array<Scalars['String']>>,
  notIn?: Maybe<Array<Scalars['String']>>,
  lt?: Maybe<Scalars['String']>,
  lte?: Maybe<Scalars['String']>,
  gt?: Maybe<Scalars['String']>,
  gte?: Maybe<Scalars['String']>,
  contains?: Maybe<Scalars['String']>,
  startsWith?: Maybe<Scalars['String']>,
  endsWith?: Maybe<Scalars['String']>,
  not?: Maybe<NestedStringNullableFilter>,
};

export type NextTestStepInput = {
  session: TestSessionWhereUniqueInput,
};

export type Notification = {
   __typename?: 'Notification',
  id: Scalars['String'],
  type: Scalars['String'],
  title: Scalars['String'],
  name: Scalars['String'],
  body?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  icon?: Maybe<Icon>,
};

export type NotificationListRelationFilter = {
  every?: Maybe<NotificationWhereInput>,
  some?: Maybe<NotificationWhereInput>,
  none?: Maybe<NotificationWhereInput>,
};

export type NotificationLogListRelationFilter = {
  every?: Maybe<NotificationLogWhereInput>,
  some?: Maybe<NotificationLogWhereInput>,
  none?: Maybe<NotificationLogWhereInput>,
};

export type NotificationLogWhereInput = {
  AND?: Maybe<Array<NotificationLogWhereInput>>,
  OR?: Maybe<Array<NotificationLogWhereInput>>,
  NOT?: Maybe<Array<NotificationLogWhereInput>>,
  id?: Maybe<StringFilter>,
  notificationId?: Maybe<StringNullableFilter>,
  notification?: Maybe<NotificationWhereInput>,
  customNotificationId?: Maybe<StringNullableFilter>,
  customNotification?: Maybe<CustomNotificationWhereInput>,
  userId?: Maybe<StringNullableFilter>,
  user?: Maybe<UserWhereInput>,
  bodyOverride?: Maybe<StringNullableFilter>,
  isCustom?: Maybe<BoolFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
};

export type NotificationRecord = {
   __typename?: 'NotificationRecord',
  id: Scalars['ID'],
  type: Scalars['String'],
  title: Scalars['String'],
  name: Scalars['String'],
  body?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  icon?: Maybe<Icon>,
};

export enum NotificationSchedule {
  ONCE = 'ONCE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY'
}

export type NotificationSettings = {
   __typename?: 'NotificationSettings',
  id: Scalars['String'],
  isEnabled: Scalars['Boolean'],
  item: NotificationSettingsItem,
  user: User,
};

export type NotificationSettingsItem = {
   __typename?: 'NotificationSettingsItem',
  id: Scalars['String'],
  label: Scalars['String'],
  type: NotificationSettingsType,
};

export type NotificationSettingsItemWhereInput = {
  AND?: Maybe<Array<NotificationSettingsItemWhereInput>>,
  OR?: Maybe<Array<NotificationSettingsItemWhereInput>>,
  NOT?: Maybe<Array<NotificationSettingsItemWhereInput>>,
  id?: Maybe<StringFilter>,
  label?: Maybe<StringFilter>,
  type?: Maybe<NotificationSettingsType>,
  notificationSettings?: Maybe<NotificationSettingsListRelationFilter>,
  notifications?: Maybe<NotificationListRelationFilter>,
};

export type NotificationSettingsListRelationFilter = {
  every?: Maybe<NotificationSettingsWhereInput>,
  some?: Maybe<NotificationSettingsWhereInput>,
  none?: Maybe<NotificationSettingsWhereInput>,
};

export enum NotificationSettingsType {
  DAILY_ACTIVITY = 'DAILY_ACTIVITY',
  ANNOUNCEMENTS = 'ANNOUNCEMENTS',
  NEW_CONTENT = 'NEW_CONTENT',
  NO_ACTIVITY = 'NO_ACTIVITY',
  DAILY_QUESTION = 'DAILY_QUESTION',
  NEW_LEVEL = 'NEW_LEVEL',
  WEEKLY_SUMMARY = 'WEEKLY_SUMMARY'
}

export type NotificationSettingsUpdateInput = {
  isEnabled: Scalars['Boolean'],
};

export type NotificationSettingsWhereInput = {
  AND?: Maybe<Array<NotificationSettingsWhereInput>>,
  OR?: Maybe<Array<NotificationSettingsWhereInput>>,
  NOT?: Maybe<Array<NotificationSettingsWhereInput>>,
  id?: Maybe<StringFilter>,
  itemId?: Maybe<StringFilter>,
  item?: Maybe<NotificationSettingsItemWhereInput>,
  isEnabled?: Maybe<BoolFilter>,
  userId?: Maybe<StringFilter>,
  user?: Maybe<UserWhereInput>,
};

export type NotificationSettingsWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type NotificationTokenUpdateInput = {
  fcmToken: Scalars['String'],
  deviceId: Scalars['String'],
};

export type NotificationWhereInput = {
  AND?: Maybe<Array<NotificationWhereInput>>,
  OR?: Maybe<Array<NotificationWhereInput>>,
  NOT?: Maybe<Array<NotificationWhereInput>>,
  id?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  title?: Maybe<StringFilter>,
  body?: Maybe<StringNullableFilter>,
  type?: Maybe<StringFilter>,
  users?: Maybe<UserListRelationFilter>,
  notificationLog?: Maybe<NotificationLogListRelationFilter>,
  iconId?: Maybe<StringNullableFilter>,
  icon?: Maybe<IconWhereInput>,
  group?: Maybe<NotificationSettingsItemWhereInput>,
  groupId?: Maybe<StringNullableFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
};

export enum OrderByDirectionInput {
  asc = 'asc',
  desc = 'desc'
}

export type Query = {
   __typename?: 'Query',
  answer?: Maybe<Answer>,
  answers: Array<Answer>,
  testStep?: Maybe<TestStep>,
  test?: Maybe<Test>,
  testSession?: Maybe<TestSession>,
  lecture: Lecture,
  diploma?: Maybe<Diploma>,
  securityQuestions: Array<SecurityQuestion>,
  me?: Maybe<User>,
  canAccessAdmin: Scalars['Boolean'],
  isEmailUsed: IsEmailUsedReponse,
  userSecurityAnswers: Array<UserSecurityAnswer>,
  acquiredBadges: Array<BadgeBoard>,
  unacceptedBadges: Array<BadgeBoard>,
  badges: Array<BadgeWithData>,
  chapter: Chapter,
  chapters: Array<Chapter>,
  chapterDrafts: Array<ChapterDraft>,
  document: Document,
  documents: DocumentBatchResult,
  /** Returns currect step of the test within the given session */
  currentTestStep: TestStep,
  lastStartedSession?: Maybe<TestSession>,
  lectures: Array<Lecture>,
  lectureDrafts: Array<LectureDraft>,
  libraryItem: LibraryItem,
  library: Library,
  userActivity: BatchUserActivity,
  studentActivity: BatchStudentActivity,
  notificationSettings: Array<NotificationSettings>,
  leaderboard: BatchLeaderboard,
  userLeaderboardPosition: UserLeaderboardPosition,
  students: StudentsBatch,
  diplomas: BatchDiplomas,
  dailyQuestion: DailyQuestion,
  finalTest?: Maybe<Test>,
  invitations: Array<Invitation>,
  invitation?: Maybe<Invitation>,
  users: BatchUsers,
  questionnaire: Questionnaire,
  userStats: UserStats,
  districts: Array<District>,
  notifications: BatchNotifications,
  customNotifications: BatchCustomNotifications,
  customNotification?: Maybe<CustomNotification>,
  validators: Validators,
};


export type QueryanswerArgs = {
  where: AnswerWhereUniqueInput
};


export type QueryanswersArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<AnswerWhereUniqueInput>,
  after?: Maybe<AnswerWhereUniqueInput>
};


export type QuerytestStepArgs = {
  where: TestStepWhereUniqueInput
};


export type QuerytestArgs = {
  where: TestWhereUniqueInput
};


export type QuerytestSessionArgs = {
  where: TestSessionWhereUniqueInput
};


export type QuerylectureArgs = {
  where: LectureWhereUniqueInput
};


export type QuerydiplomaArgs = {
  where: DiplomaWhereUniqueInput
};


export type QuerysecurityQuestionsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<SecurityQuestionWhereUniqueInput>,
  after?: Maybe<SecurityQuestionWhereUniqueInput>
};


export type QueryisEmailUsedArgs = {
  email: Scalars['String']
};


export type QueryuserSecurityAnswersArgs = {
  username: Scalars['String']
};


export type QuerychapterArgs = {
  where: ChapterWhereUniqueInput
};


export type QuerydocumentArgs = {
  where: DocumentWhereUniqueInput
};


export type QuerydocumentsArgs = {
  filter?: Maybe<DocumentsFilterInput>,
  orderBy?: Maybe<DocumentsOrderByInput>,
  page?: Maybe<Scalars['Int']>,
  itemsPerPage?: Maybe<Scalars['Int']>
};


export type QuerycurrentTestStepArgs = {
  session: TestSessionWhereUniqueInput
};


export type QuerylastStartedSessionArgs = {
  where: LastStartedessionInput
};


export type QuerylibraryItemArgs = {
  where: LibraryItemWhereInput
};


export type QuerylibraryArgs = {
  filter?: Maybe<LibraryFilter>,
  page?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<BatchLibraryOrderByInput>,
  itemsPerPage?: Maybe<Scalars['Int']>
};


export type QueryuserActivityArgs = {
  page?: Maybe<Scalars['Int']>,
  itemsPerPage?: Maybe<Scalars['Int']>,
  filter?: Maybe<LogWhereInput>,
  sort?: Maybe<LogSort>
};


export type QuerystudentActivityArgs = {
  filter?: Maybe<StudentActivityFilter>,
  page?: Maybe<Scalars['Int']>,
  itemsPerPage?: Maybe<Scalars['Int']>
};


export type QueryleaderboardArgs = {
  page?: Maybe<Scalars['Int']>,
  itemsPerPage?: Maybe<Scalars['Int']>,
  filter?: Maybe<Array<LeaderboardFilterEnum>>
};


export type QueryuserLeaderboardPositionArgs = {
  itemsPerPage: Scalars['Int'],
  filter?: Maybe<Array<LeaderboardFilterEnum>>
};


export type QuerystudentsArgs = {
  where?: Maybe<StudentWhereInput>,
  page?: Maybe<Scalars['Int']>,
  itemsPerPage?: Maybe<Scalars['Int']>
};


export type QuerydiplomasArgs = {
  page?: Maybe<Scalars['Int']>,
  itemsPerPage?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<DiplomasOrderByInput>,
  filter?: Maybe<DiplomasFilterInput>
};


export type QueryinvitationsArgs = {
  where?: Maybe<InvitationWhereInput>,
  orderBy?: Maybe<Array<InvitationOrderByInput>>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<InvitationWhereUniqueInput>,
  after?: Maybe<InvitationWhereUniqueInput>
};


export type QueryinvitationArgs = {
  where: InvitationWhereUniqueInput
};


export type QueryusersArgs = {
  page?: Maybe<Scalars['Int']>,
  itemsPerPage?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<UsersOrderByInput>,
  filter?: Maybe<UsersFilterInput>
};


export type QuerynotificationsArgs = {
  page?: Maybe<Scalars['Int']>,
  itemsPerPage?: Maybe<Scalars['Int']>
};


export type QuerycustomNotificationsArgs = {
  page?: Maybe<Scalars['Int']>,
  itemsPerPage?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<BatchCustomNotificationsOrderByInput>,
  filter?: Maybe<CustomNotificationFilter>
};


export type QuerycustomNotificationArgs = {
  where: CustomNotificationWhereUniqueInput
};

export type Question = {
   __typename?: 'Question',
  id: Scalars['String'],
  text: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  answers: Array<Answer>,
};

export type QuestionDraft = {
   __typename?: 'QuestionDraft',
  id: Scalars['String'],
  text: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  answers: Array<AnswerDraft>,
};


export type QuestionDraftanswersArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<AnswerDraftWhereUniqueInput>,
  after?: Maybe<AnswerDraftWhereUniqueInput>
};

export type QuestionDraftListRelationFilter = {
  every?: Maybe<QuestionDraftWhereInput>,
  some?: Maybe<QuestionDraftWhereInput>,
  none?: Maybe<QuestionDraftWhereInput>,
};

export type QuestionDraftWhereInput = {
  AND?: Maybe<Array<QuestionDraftWhereInput>>,
  OR?: Maybe<Array<QuestionDraftWhereInput>>,
  NOT?: Maybe<Array<QuestionDraftWhereInput>>,
  id?: Maybe<StringFilter>,
  questionId?: Maybe<StringFilter>,
  question?: Maybe<QuestionWhereInput>,
  createdAt?: Maybe<DateTimeFilter>,
  description?: Maybe<StringNullableFilter>,
  testId?: Maybe<StringNullableFilter>,
  text?: Maybe<StringFilter>,
  type?: Maybe<QuestionType>,
  isDeleted?: Maybe<BoolFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  test?: Maybe<TestDraftWhereInput>,
  answers?: Maybe<AnswerDraftListRelationFilter>,
};

export type QuestionDraftWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type QuestionListRelationFilter = {
  every?: Maybe<QuestionWhereInput>,
  some?: Maybe<QuestionWhereInput>,
  none?: Maybe<QuestionWhereInput>,
};

export type Questionnaire = {
   __typename?: 'Questionnaire',
  lifeStage?: Maybe<UserLifeStageField>,
  investmentExperience?: Maybe<UserInvestmentExperienceField>,
  district?: Maybe<District>,
};

export enum QuestionType {
  NUMERAL = 'NUMERAL',
  ORDER = 'ORDER',
  SELECT = 'SELECT'
}

export type QuestionWhereInput = {
  AND?: Maybe<Array<QuestionWhereInput>>,
  OR?: Maybe<Array<QuestionWhereInput>>,
  NOT?: Maybe<Array<QuestionWhereInput>>,
  id?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  description?: Maybe<StringNullableFilter>,
  testId?: Maybe<StringNullableFilter>,
  text?: Maybe<StringFilter>,
  type?: Maybe<QuestionType>,
  isDeleted?: Maybe<BoolFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  test?: Maybe<TestWhereInput>,
  answers?: Maybe<AnswerListRelationFilter>,
  dailyQuestions?: Maybe<DailyQuestionListRelationFilter>,
  testSteps?: Maybe<TestStepListRelationFilter>,
  drafts?: Maybe<QuestionDraftListRelationFilter>,
};

export type QuestionWhereUniqueInput = {
  id: Scalars['ID'],
};

export type Rank = {
   __typename?: 'Rank',
  place: Scalars['Int'],
  total: Scalars['Int'],
};

export type RankStudent = {
   __typename?: 'RankStudent',
  place: Scalars['Int'],
  missingPoints?: Maybe<Scalars['Int']>,
  total: Scalars['Int'],
};

export type RegisterStudentInput = {
  username?: Maybe<Scalars['String']>,
  password: Scalars['String'],
  email: Scalars['String'],
  deviceId?: Maybe<Scalars['String']>,
  newsletter?: Maybe<Scalars['Boolean']>,
};

export type RejectChapterInput = {
  reason: Scalars['String'],
};

export type RejectCustomNotificationInput = {
  reason: Scalars['String'],
};

export type RejectLectureInput = {
  reason: Scalars['String'],
};

export type RenameDocumentInput = {
  name: Scalars['String'],
};

export type RequestPasswordResetEmailInput = {
  identificator: Scalars['String'],
};

export type ResetPasswordInput = {
  password: Scalars['String'],
};

export type resetPasswordWithEmailToken = {
  token: Scalars['String'],
  password: Scalars['String'],
};

export enum Role {
  ADMIN = 'ADMIN',
  STUDENT = 'STUDENT',
  SUPER_ADMIN = 'SUPER_ADMIN',
  TEACHER = 'TEACHER'
}

export type SecurityQuestion = {
   __typename?: 'SecurityQuestion',
  id: Scalars['String'],
  text: Scalars['String'],
};

export type SecurityQuestionWhereInput = {
  AND?: Maybe<Array<SecurityQuestionWhereInput>>,
  OR?: Maybe<Array<SecurityQuestionWhereInput>>,
  NOT?: Maybe<Array<SecurityQuestionWhereInput>>,
  id?: Maybe<StringFilter>,
  text?: Maybe<StringFilter>,
  answeredQuestionsUser?: Maybe<AnswerQuestionUserListRelationFilter>,
  users?: Maybe<UserListRelationFilter>,
};

export type SecurityQuestionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export enum SingnupType {
  NATIVE = 'NATIVE',
  GOOGLE = 'GOOGLE',
  FACEBOOK = 'FACEBOOK',
  APPLE = 'APPLE'
}

export enum SortOrder {
  asc = 'asc',
  desc = 'desc'
}

export type StringFilter = {
  equals?: Maybe<Scalars['String']>,
  in?: Maybe<Array<Scalars['String']>>,
  notIn?: Maybe<Array<Scalars['String']>>,
  lt?: Maybe<Scalars['String']>,
  lte?: Maybe<Scalars['String']>,
  gt?: Maybe<Scalars['String']>,
  gte?: Maybe<Scalars['String']>,
  contains?: Maybe<Scalars['String']>,
  startsWith?: Maybe<Scalars['String']>,
  endsWith?: Maybe<Scalars['String']>,
  not?: Maybe<NestedStringFilter>,
};

export type StringNullableFilter = {
  equals?: Maybe<Scalars['String']>,
  in?: Maybe<Array<Scalars['String']>>,
  notIn?: Maybe<Array<Scalars['String']>>,
  lt?: Maybe<Scalars['String']>,
  lte?: Maybe<Scalars['String']>,
  gt?: Maybe<Scalars['String']>,
  gte?: Maybe<Scalars['String']>,
  contains?: Maybe<Scalars['String']>,
  startsWith?: Maybe<Scalars['String']>,
  endsWith?: Maybe<Scalars['String']>,
  not?: Maybe<NestedStringNullableFilter>,
};

export type Student = {
   __typename?: 'Student',
  id: Scalars['String'],
  sessions: Array<TestSession>,
  user: User,
  badgeBoard: Array<BadgeBoard>,
  finalTestStatus: FinalTestStatus,
  progress: StudentProgress,
};


export type StudentsessionsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<TestSessionWhereUniqueInput>,
  after?: Maybe<TestSessionWhereUniqueInput>
};


export type StudentbadgeBoardArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<BadgeBoardWhereUniqueInput>,
  after?: Maybe<BadgeBoardWhereUniqueInput>
};

export type StudentActivity = {
   __typename?: 'StudentActivity',
  type: StudentActivityTypeEnum,
  role: Role,
  createdAt: Scalars['DateTime'],
  testName?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
  points?: Maybe<Scalars['Int']>,
  answers?: Maybe<StudentActivityAnswers>,
};

export type StudentActivityAnswers = {
   __typename?: 'StudentActivityAnswers',
  correct: Scalars['Int'],
  wrong: Scalars['Int'],
};

export enum StudentActivityEntityType {
  CHAPTER = 'CHAPTER',
  LECTURE = 'LECTURE',
  TEST_SESSION = 'TEST_SESSION',
  STUDENT_PROFILE = 'STUDENT_PROFILE'
}

export type StudentActivityFilter = {
  role?: Maybe<Role>,
};

export enum StudentActivityType {
  COMPLETED = 'COMPLETED',
  READ = 'READ',
  WATCHED = 'WATCHED',
  UPDATED = 'UPDATED'
}

export enum StudentActivityTypeEnum {
  TEST = 'TEST',
  REGISTRATION = 'REGISTRATION',
  DAILY_QUESTION = 'DAILY_QUESTION',
  ACCOUNT_DELETED = 'ACCOUNT_DELETED'
}

export type StudentLog = {
   __typename?: 'StudentLog',
  id: Scalars['String'],
  activity: StudentActivityType,
  createdAt: Scalars['DateTime'],
  student: Student,
  entity: StudentActivityEntityType,
  entityId: Scalars['String'],
};

export type StudentLogListRelationFilter = {
  every?: Maybe<StudentLogWhereInput>,
  some?: Maybe<StudentLogWhereInput>,
  none?: Maybe<StudentLogWhereInput>,
};

export type StudentLogWhereInput = {
  AND?: Maybe<Array<StudentLogWhereInput>>,
  OR?: Maybe<Array<StudentLogWhereInput>>,
  NOT?: Maybe<Array<StudentLogWhereInput>>,
  id?: Maybe<StringFilter>,
  activity?: Maybe<StudentActivityType>,
  createdAt?: Maybe<DateTimeFilter>,
  entity?: Maybe<StudentActivityEntityType>,
  entityId?: Maybe<StringFilter>,
  studentId?: Maybe<StringFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  student?: Maybe<StudentWhereInput>,
};

export type StudentProgress = {
   __typename?: 'StudentProgress',
  level: StudentProgressLevel,
  lectures: StudentProgressLecture,
  points: StudentProgressPoints,
  countryRank?: Maybe<Scalars['Int']>,
  regionRank?: Maybe<Scalars['Int']>,
};

export type StudentProgressLecture = {
   __typename?: 'StudentProgressLecture',
  total: Scalars['Int'],
  reached: Scalars['Int'],
};

export type StudentProgressLevel = {
   __typename?: 'StudentProgressLevel',
  progress: Scalars['Float'],
  name: Scalars['String'],
  nextLevelName?: Maybe<Scalars['String']>,
};

export type StudentProgressPoints = {
   __typename?: 'StudentProgressPoints',
  total: Scalars['Int'],
  reached: Scalars['Int'],
};

export type StudentsBatch = {
   __typename?: 'StudentsBatch',
  data: Array<Student>,
  meta: BatchMeta,
};

export type StudentStatsListRelationFilter = {
  every?: Maybe<StudentStatsWhereInput>,
  some?: Maybe<StudentStatsWhereInput>,
  none?: Maybe<StudentStatsWhereInput>,
};

export type StudentStatsWhereInput = {
  AND?: Maybe<Array<StudentStatsWhereInput>>,
  OR?: Maybe<Array<StudentStatsWhereInput>>,
  NOT?: Maybe<Array<StudentStatsWhereInput>>,
  id?: Maybe<StringFilter>,
  points?: Maybe<IntFilter>,
  userId?: Maybe<StringFilter>,
  user?: Maybe<UserWhereInput>,
  studentId?: Maybe<StringFilter>,
  student?: Maybe<StudentWhereInput>,
  batchId?: Maybe<IntFilter>,
  isLastBatch?: Maybe<BoolFilter>,
  createdAt?: Maybe<DateTimeFilter>,
};

export type StudentWhereInput = {
  AND?: Maybe<Array<StudentWhereInput>>,
  OR?: Maybe<Array<StudentWhereInput>>,
  NOT?: Maybe<Array<StudentWhereInput>>,
  id?: Maybe<StringFilter>,
  user?: Maybe<UserWhereInput>,
  dailyQuestions?: Maybe<DailyQuestionListRelationFilter>,
  studentLogs?: Maybe<StudentLogListRelationFilter>,
  sessions?: Maybe<TestSessionListRelationFilter>,
  badgeBoard?: Maybe<BadgeBoardListRelationFilter>,
  studentStats?: Maybe<StudentStatsListRelationFilter>,
  diplomas?: Maybe<DiplomaListRelationFilter>,
};

export type SubmitAnswerInput = {
  answer: AnswerWhereUniqueInput,
  step: TestStepWhereUniqueInput,
};

export type SubmitDailyQuestionAnswerInput = {
  question: QuestionWhereUniqueInput,
  answer: AnswerWhereUniqueInput,
};

export type SubmitQuestionnaireInput = {
  lifeStage?: Maybe<UserLifeStage>,
  district?: Maybe<DistrictWhereUniqueInput>,
  investmentExperience?: Maybe<UserInvestmentExperience>,
};

export type TemporaryStudent = {
   __typename?: 'TemporaryStudent',
  accessToken: Scalars['String'],
  refreshToken: Scalars['String'],
};

export type Test = {
   __typename?: 'Test',
  id: Scalars['String'],
  lecture?: Maybe<Lecture>,
  chapter?: Maybe<Chapter>,
  sessions: Array<TestSession>,
  isChapterSummary: Scalars['Boolean'],
  isFinalTest: Scalars['Boolean'],
  questions: Array<Question>,
  isFinalTestCompleted: Scalars['Boolean'],
  acquiredPoints: Scalars['Int'],
  maxPoints: Scalars['Int'],
  isCompleted: Scalars['Boolean'],
  stepsTotal: Scalars['Int'],
};


export type TestsessionsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<TestSessionWhereUniqueInput>,
  after?: Maybe<TestSessionWhereUniqueInput>
};

export type TestAnswerInput = {
  text: Scalars['String'],
  isCorrect: Scalars['Boolean'],
};

export type TestAnswerUpdateInput = {
  id: Scalars['String'],
  text: Scalars['String'],
  isCorrect: Scalars['Boolean'],
};

export type TestDraft = {
   __typename?: 'TestDraft',
  id: Scalars['String'],
  lecture?: Maybe<LectureDraft>,
  chapter?: Maybe<ChapterDraft>,
  questions: Array<QuestionDraft>,
};


export type TestDraftquestionsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<QuestionDraftWhereUniqueInput>,
  after?: Maybe<QuestionDraftWhereUniqueInput>
};

export type TestDraftListRelationFilter = {
  every?: Maybe<TestDraftWhereInput>,
  some?: Maybe<TestDraftWhereInput>,
  none?: Maybe<TestDraftWhereInput>,
};

export type TestDraftWhereInput = {
  AND?: Maybe<Array<TestDraftWhereInput>>,
  OR?: Maybe<Array<TestDraftWhereInput>>,
  NOT?: Maybe<Array<TestDraftWhereInput>>,
  id?: Maybe<StringFilter>,
  testId?: Maybe<StringFilter>,
  test?: Maybe<TestWhereInput>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  chapterId?: Maybe<StringNullableFilter>,
  chapter?: Maybe<ChapterDraftWhereInput>,
  lecture?: Maybe<LectureDraftWhereInput>,
  questions?: Maybe<QuestionDraftListRelationFilter>,
};

export type TestListRelationFilter = {
  every?: Maybe<TestWhereInput>,
  some?: Maybe<TestWhereInput>,
  none?: Maybe<TestWhereInput>,
};

export type TestQuestionInput = {
  text: Scalars['String'],
  description?: Maybe<Scalars['String']>,
};

export type TestQuestionUpdateInput = {
  text?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
};

export type TestSession = {
   __typename?: 'TestSession',
  id: Scalars['String'],
  test: Test,
  pointsTotal: Scalars['Int'],
  student: Student,
  testSteps: Array<TestStep>,
  testStatus: TestStatus,
  isBestScore: Scalars['Boolean'],
  correctAnswersCount: Scalars['Int'],
  wrongAnswersCount: Scalars['Int'],
  nextLecture?: Maybe<Lecture>,
};


export type TestSessiontestStepsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<TestStepWhereUniqueInput>,
  after?: Maybe<TestStepWhereUniqueInput>
};

export type TestSessionListRelationFilter = {
  every?: Maybe<TestSessionWhereInput>,
  some?: Maybe<TestSessionWhereInput>,
  none?: Maybe<TestSessionWhereInput>,
};

export type TestSessionWhereInput = {
  AND?: Maybe<Array<TestSessionWhereInput>>,
  OR?: Maybe<Array<TestSessionWhereInput>>,
  NOT?: Maybe<Array<TestSessionWhereInput>>,
  id?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  isBestScore?: Maybe<BoolFilter>,
  pointsTotal?: Maybe<IntFilter>,
  studentId?: Maybe<StringFilter>,
  testId?: Maybe<StringFilter>,
  testStatus?: Maybe<TestStatus>,
  updatedAt?: Maybe<DateTimeFilter>,
  student?: Maybe<StudentWhereInput>,
  test?: Maybe<TestWhereInput>,
  testSteps?: Maybe<TestStepListRelationFilter>,
};

export type TestSessionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export enum TestStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  STARTED = 'STARTED'
}

export type TestStep = {
   __typename?: 'TestStep',
  id: Scalars['String'],
  session?: Maybe<TestSession>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  points: Scalars['Int'],
  step: Scalars['Int'],
  question: Question,
  answer?: Maybe<Answer>,
  isCorrect?: Maybe<Scalars['Boolean']>,
  description?: Maybe<Scalars['String']>,
  correctAnswer?: Maybe<Answer>,
};

export type TestStepListRelationFilter = {
  every?: Maybe<TestStepWhereInput>,
  some?: Maybe<TestStepWhereInput>,
  none?: Maybe<TestStepWhereInput>,
};

export type TestStepWhereInput = {
  AND?: Maybe<Array<TestStepWhereInput>>,
  OR?: Maybe<Array<TestStepWhereInput>>,
  NOT?: Maybe<Array<TestStepWhereInput>>,
  id?: Maybe<StringFilter>,
  answerId?: Maybe<StringNullableFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  isCompleted?: Maybe<BoolFilter>,
  isCorrect?: Maybe<BoolNullableFilter>,
  points?: Maybe<IntFilter>,
  questionId?: Maybe<StringFilter>,
  sessionId?: Maybe<StringNullableFilter>,
  step?: Maybe<IntFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  answer?: Maybe<AnswerWhereInput>,
  question?: Maybe<QuestionWhereInput>,
  session?: Maybe<TestSessionWhereInput>,
};

export type TestStepWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type TestWhereInput = {
  AND?: Maybe<Array<TestWhereInput>>,
  OR?: Maybe<Array<TestWhereInput>>,
  NOT?: Maybe<Array<TestWhereInput>>,
  id?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  isChapterSummary?: Maybe<BoolFilter>,
  isFinalTest?: Maybe<BoolFilter>,
  chapterId?: Maybe<StringNullableFilter>,
  chapter?: Maybe<ChapterWhereInput>,
  lecture?: Maybe<LectureWhereInput>,
  questions?: Maybe<QuestionListRelationFilter>,
  sessions?: Maybe<TestSessionListRelationFilter>,
  drafts?: Maybe<TestDraftListRelationFilter>,
};

export type TestWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type Transcript = {
   __typename?: 'Transcript',
  id: Scalars['ID'],
  name: Scalars['String'],
  text: Scalars['String'],
  lecture: Lecture,
  similarItems: Array<LibraryItem>,
};

export type UpdateChapterInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  icon?: Maybe<IconUpdateInput>,
};

export type UpdateLectureDraftInput = {
  name: Scalars['String'],
  chapter?: Maybe<ChapterWhereUniqueInput>,
  description?: Maybe<Scalars['String']>,
  summary?: Maybe<Scalars['String']>,
  transcript?: Maybe<Scalars['String']>,
  video?: Maybe<GcsId>,
  videoPreview?: Maybe<GcsId>,
  audio?: Maybe<GcsId>,
};

export type UpdateLectureInput = {
  name: Scalars['String'],
  chapter?: Maybe<ChapterWhereUniqueInput>,
  description?: Maybe<Scalars['String']>,
  summary?: Maybe<Scalars['String']>,
  transcript?: Maybe<Scalars['String']>,
  video?: Maybe<GcsId>,
  videoPreview?: Maybe<GcsId>,
  audio?: Maybe<GcsId>,
};

export type UpdateProfilePictureInput = {
  base64: Scalars['String'],
};

export type UpdateSelfUserInput = {
  username?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  allowUsernameSharing?: Maybe<Scalars['Boolean']>,
  lifeStage?: Maybe<UserLifeStage>,
  district?: Maybe<DistrictWhereUniqueInput>,
  investmentExperience?: Maybe<UserInvestmentExperience>,
  allowDataDownload?: Maybe<Scalars['Boolean']>,
};

export type UpdateTemporaryUserInput = {
  username?: Maybe<Scalars['String']>,
  password: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
};

export type UpdateWordInput = {
  word?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
};


export type UploadDocumentInput = {
  name: Scalars['String'],
  gcsId: Scalars['String'],
};

export type User = {
   __typename?: 'User',
  id: Scalars['String'],
  username?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  timeSpent: Scalars['Int'],
  student?: Maybe<Student>,
  role: Role,
  notificationSettings: Array<NotificationSettings>,
  isTemporary: Scalars['Boolean'],
  deactivatedAt?: Maybe<Scalars['DateTime']>,
  isBlocked: Scalars['Boolean'],
  lastLogin?: Maybe<Scalars['DateTime']>,
  allowDataDownload: Scalars['Boolean'],
  isEmailVerified: Scalars['Boolean'],
  isDeleted: Scalars['Boolean'],
  createdAt: Scalars['DateTime'],
  district?: Maybe<District>,
  profilePicture?: Maybe<Scalars['String']>,
  lifeStage?: Maybe<UserLifeStageField>,
  investmentExperience?: Maybe<UserInvestmentExperienceField>,
  signupType: SingnupType,
};


export type UsernotificationSettingsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<NotificationSettingsWhereUniqueInput>,
  after?: Maybe<NotificationSettingsWhereUniqueInput>
};

export enum UserInvestmentExperience {
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
  ADVANCED = 'ADVANCED'
}

export type UserInvestmentExperienceField = {
   __typename?: 'UserInvestmentExperienceField',
  value: UserInvestmentExperience,
  name?: Maybe<Scalars['String']>,
};

export type UserLeaderboardPosition = {
   __typename?: 'UserLeaderboardPosition',
  page?: Maybe<Scalars['Int']>,
};

export enum UserLifeStage {
  STUDENT = 'STUDENT',
  WORKING = 'WORKING',
  UNEMPLOYED = 'UNEMPLOYED',
  PENSIONER = 'PENSIONER'
}

export type UserLifeStageField = {
   __typename?: 'UserLifeStageField',
  value: UserLifeStage,
  name?: Maybe<Scalars['String']>,
};

export type UserListRelationFilter = {
  every?: Maybe<UserWhereInput>,
  some?: Maybe<UserWhereInput>,
  none?: Maybe<UserWhereInput>,
};

export type UserSecurityAnswer = {
   __typename?: 'UserSecurityAnswer',
  id: Scalars['ID'],
  question: SecurityQuestion,
};

export type UsersFilterInput = {
  search?: Maybe<Scalars['String']>,
  role?: Maybe<Role>,
  createdFrom?: Maybe<Scalars['DateTime']>,
  createdUntil?: Maybe<Scalars['DateTime']>,
};

export enum UsersOrderByColumnInput {
  name = 'name',
  email = 'email',
  username = 'username',
  role = 'role'
}

export type UsersOrderByInput = {
  column: UsersOrderByColumnInput,
  direction?: Maybe<OrderByDirectionInput>,
};

export type UserStats = {
   __typename?: 'UserStats',
  userCount: Scalars['Int'],
  diplomaCount: Scalars['Int'],
};

export type UserUpdateInput = {
  name?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  role?: Maybe<Role>,
};

export type UserWhereId = {
  id: Scalars['String'],
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>,
  OR?: Maybe<Array<UserWhereInput>>,
  NOT?: Maybe<Array<UserWhereInput>>,
  id?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  deactivatedAt?: Maybe<DateTimeNullableFilter>,
  deletedAt?: Maybe<DateTimeNullableFilter>,
  deleteRequestAt?: Maybe<DateTimeNullableFilter>,
  email?: Maybe<StringNullableFilter>,
  isEmailVerified?: Maybe<BoolFilter>,
  facebookId?: Maybe<StringNullableFilter>,
  googleId?: Maybe<StringNullableFilter>,
  appleId?: Maybe<StringNullableFilter>,
  isBlocked?: Maybe<BoolFilter>,
  isDeleted?: Maybe<BoolFilter>,
  isTemporary?: Maybe<BoolFilter>,
  lastLogin?: Maybe<DateTimeNullableFilter>,
  name?: Maybe<StringNullableFilter>,
  newsletter?: Maybe<BoolFilter>,
  notificationSettings?: Maybe<NotificationSettingsListRelationFilter>,
  password?: Maybe<StringNullableFilter>,
  role?: Maybe<Role>,
  securityQuestionId?: Maybe<StringNullableFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  username?: Maybe<StringNullableFilter>,
  securityQuestion?: Maybe<SecurityQuestionWhereInput>,
  answeredQuestionsUser?: Maybe<AnswerQuestionUserListRelationFilter>,
  chapters?: Maybe<ChapterListRelationFilter>,
  lectures?: Maybe<LectureListRelationFilter>,
  logs?: Maybe<LogListRelationFilter>,
  districtId?: Maybe<StringNullableFilter>,
  district?: Maybe<DistrictWhereInput>,
  fcm?: Maybe<FcmListRelationFilter>,
  allowDataDownload?: Maybe<BoolFilter>,
  allowUsernameSharing?: Maybe<BoolFilter>,
  studentId?: Maybe<StringNullableFilter>,
  student?: Maybe<StudentWhereInput>,
  notifications?: Maybe<NotificationListRelationFilter>,
  sentInvitations?: Maybe<InvitationListRelationFilter>,
  document?: Maybe<DocumentListRelationFilter>,
  lectureFile?: Maybe<LectureFileListRelationFilter>,
  notificationLog?: Maybe<NotificationLogListRelationFilter>,
  vocabulary?: Maybe<VocabularyListRelationFilter>,
  studentStats?: Maybe<StudentStatsListRelationFilter>,
  customNotification?: Maybe<CustomNotificationListRelationFilter>,
  chapterDraft?: Maybe<ChapterDraftListRelationFilter>,
  lectureDraft?: Maybe<LectureDraftListRelationFilter>,
  investmentExperience?: Maybe<StringNullableFilter>,
  lifeStage?: Maybe<StringNullableFilter>,
  profilePictureId?: Maybe<StringNullableFilter>,
  timeSpent?: Maybe<IntFilter>,
};

export type Validator = {
   __typename?: 'Validator',
  description: Scalars['String'],
  regex: Scalars['String'],
};

export type Validators = {
   __typename?: 'Validators',
  email: Validator,
  username: Validator,
  password: Validator,
};

export type VerifyEmailInput = {
  token: Scalars['String'],
};

export type Video = Media & {
   __typename?: 'Video',
  id: Scalars['ID'],
  name: Scalars['String'],
  author?: Maybe<User>,
  src?: Maybe<Scalars['String']>,
  mimetype: Scalars['String'],
  extention: Scalars['String'],
  filename: Scalars['String'],
  preview?: Maybe<Scalars['String']>,
  length?: Maybe<Scalars['Float']>,
  lecture: Lecture,
  similarItems: Array<LibraryItem>,
};

export type VocabularyListRelationFilter = {
  every?: Maybe<VocabularyWhereInput>,
  some?: Maybe<VocabularyWhereInput>,
  none?: Maybe<VocabularyWhereInput>,
};

export type VocabularyWhereInput = {
  AND?: Maybe<Array<VocabularyWhereInput>>,
  OR?: Maybe<Array<VocabularyWhereInput>>,
  NOT?: Maybe<Array<VocabularyWhereInput>>,
  id?: Maybe<StringFilter>,
  description?: Maybe<StringFilter>,
  word?: Maybe<StringFilter>,
  authorId?: Maybe<StringNullableFilter>,
  author?: Maybe<UserWhereInput>,
  isDeleted?: Maybe<BoolFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
};

export type Word = {
   __typename?: 'Word',
  id: Scalars['ID'],
  word: Scalars['String'],
  description: Scalars['String'],
  author?: Maybe<User>,
};

export type WordWhereUniqueInput = {
  id: Scalars['String'],
};

export type AnswerFragment = (
  { __typename?: 'Answer' }
  & Pick<Answer, 'id' | 'text' | 'points' | 'isCorrect'>
);

export type QuestionFragment = (
  { __typename?: 'Question' }
  & Pick<Question, 'id' | 'text' | 'description'>
  & { answers: Array<(
    { __typename?: 'Answer' }
    & AnswerFragment
  )> }
);

export type LectureForChapterFragment = (
  { __typename?: 'Lecture' }
  & Pick<Lecture, 'id' | 'name' | 'status' | 'maxPoints' | 'acquiredPoints'>
  & { test: Maybe<(
    { __typename?: 'Test' }
    & Pick<Test, 'id' | 'isCompleted' | 'isChapterSummary'>
  )>, library: Maybe<Array<(
    { __typename?: 'Audio' }
    & AudioFragment
  ) | (
    { __typename?: 'Video' }
    & VideoFragment
  ) | { __typename?: 'Transcript' } | { __typename?: 'Word' }>> }
);

export type TestFragment = (
  { __typename?: 'Test' }
  & Pick<Test, 'id' | 'maxPoints' | 'stepsTotal' | 'isFinalTest' | 'isCompleted' | 'isChapterSummary'>
  & { chapter: Maybe<(
    { __typename?: 'Chapter' }
    & Pick<Chapter, 'id' | 'name' | 'description'>
  )>, lecture: Maybe<(
    { __typename?: 'Lecture' }
    & Pick<Lecture, 'id' | 'name' | 'description'>
    & { chapter: (
      { __typename?: 'Chapter' }
      & Pick<Chapter, 'id'>
      & { summaryTest: Maybe<(
        { __typename?: 'Test' }
        & Pick<Test, 'id'>
      )> }
    ) }
  )>, questions: Array<(
    { __typename?: 'Question' }
    & QuestionFragment
  )> }
);

export type SessionFragment = (
  { __typename?: 'TestSession' }
  & Pick<TestSession, 'id' | 'testStatus' | 'pointsTotal' | 'wrongAnswersCount' | 'correctAnswersCount'>
  & { nextLecture: Maybe<(
    { __typename?: 'Lecture' }
    & Pick<Lecture, 'id'>
    & { chapter: (
      { __typename?: 'Chapter' }
      & Pick<Chapter, 'id'>
    ) }
  )>, test: (
    { __typename?: 'Test' }
    & TestFragment
  ), testSteps: Array<(
    { __typename?: 'TestStep' }
    & Pick<TestStep, 'id' | 'step' | 'points' | 'isCorrect' | 'description'>
    & { answer: Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'text'>
    )>, correctAnswer: Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'text'>
    )>, question: (
      { __typename?: 'Question' }
      & QuestionFragment
    ) }
  )> }
);

export type StepFragment = (
  { __typename?: 'TestStep' }
  & Pick<TestStep, 'id' | 'step' | 'points' | 'isCorrect' | 'description'>
  & { answer: Maybe<(
    { __typename?: 'Answer' }
    & Pick<Answer, 'id' | 'text'>
  )>, correctAnswer: Maybe<(
    { __typename?: 'Answer' }
    & Pick<Answer, 'id' | 'text'>
  )>, session: Maybe<(
    { __typename?: 'TestSession' }
    & SessionFragment
  )>, question: (
    { __typename?: 'Question' }
    & QuestionFragment
  ) }
);

export type LectureFragment = (
  { __typename?: 'Lecture' }
  & Pick<Lecture, 'id' | 'name' | 'index' | 'status' | 'summary' | 'maxPoints' | 'transcript' | 'description' | 'acquiredPoints'>
  & { test: Maybe<(
    { __typename?: 'Test' }
    & Pick<Test, 'id'>
  )>, library: Maybe<Array<(
    { __typename?: 'Audio' }
    & AudioFragment
  ) | (
    { __typename?: 'Video' }
    & VideoFragment
  ) | (
    { __typename?: 'Transcript' }
    & TranscriptFragment
  ) | (
    { __typename?: 'Word' }
    & WordFragment
  )>>, chapter: (
    { __typename?: 'Chapter' }
    & Pick<Chapter, 'id' | 'name'>
    & { lectures: Array<(
      { __typename?: 'Lecture' }
      & Pick<Lecture, 'id' | 'name' | 'index' | 'status' | 'maxPoints' | 'acquiredPoints'>
      & { test: Maybe<(
        { __typename?: 'Test' }
        & Pick<Test, 'id' | 'isChapterSummary'>
      )>, library: Maybe<Array<(
        { __typename?: 'Audio' }
        & AudioFragment
      ) | (
        { __typename?: 'Video' }
        & VideoFragment
      ) | (
        { __typename?: 'Transcript' }
        & TranscriptFragment
      ) | (
        { __typename?: 'Word' }
        & WordFragment
      )>> }
    )> }
  ) }
);

export type VideoFragment = (
  { __typename?: 'Video' }
  & Pick<Video, 'id' | 'name' | 'filename' | 'src' | 'length' | 'preview'>
  & { lecture: (
    { __typename?: 'Lecture' }
    & Pick<Lecture, 'id' | 'name'>
    & { chapter: (
      { __typename?: 'Chapter' }
      & Pick<Chapter, 'id' | 'name'>
    ) }
  ) }
);

export type AudioFragment = (
  { __typename?: 'Audio' }
  & Pick<Audio, 'id' | 'src' | 'name' | 'length' | 'filename'>
);

export type TranscriptFragment = (
  { __typename?: 'Transcript' }
  & Pick<Transcript, 'id' | 'name' | 'text'>
  & { lecture: (
    { __typename?: 'Lecture' }
    & Pick<Lecture, 'id' | 'name'>
  ) }
);

export type WordFragment = (
  { __typename?: 'Word' }
  & Pick<Word, 'id' | 'word' | 'description'>
);

export type FileFragment = (
  { __typename?: 'LectureFile' }
  & Pick<LectureFile, 'id' | 'src' | 'type' | 'name' | 'length' | 'preview'>
  & { lecture: Maybe<(
    { __typename?: 'Lecture' }
    & Pick<Lecture, 'id' | 'name'>
    & { chapter: (
      { __typename?: 'Chapter' }
      & Pick<Chapter, 'id' | 'name'>
    ) }
  )> }
);

export type StudentFragment = (
  { __typename?: 'Student' }
  & Pick<Student, 'id'>
  & { progress: (
    { __typename?: 'StudentProgress' }
    & Pick<StudentProgress, 'countryRank' | 'regionRank'>
    & { level: (
      { __typename?: 'StudentProgressLevel' }
      & Pick<StudentProgressLevel, 'name' | 'progress' | 'nextLevelName'>
    ), lectures: (
      { __typename?: 'StudentProgressLecture' }
      & Pick<StudentProgressLecture, 'total' | 'reached'>
    ), points: (
      { __typename?: 'StudentProgressPoints' }
      & Pick<StudentProgressPoints, 'total' | 'reached'>
    ) }
  ) }
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'role' | 'email' | 'username' | 'isBlocked' | 'isDeleted' | 'lastLogin' | 'signupType' | 'isTemporary' | 'isEmailVerified'>
  & { lifeStage: Maybe<(
    { __typename?: 'UserLifeStageField' }
    & Pick<UserLifeStageField, 'value' | 'name'>
  )>, investmentExperience: Maybe<(
    { __typename?: 'UserInvestmentExperienceField' }
    & Pick<UserInvestmentExperienceField, 'value' | 'name'>
  )>, district: Maybe<(
    { __typename?: 'District' }
    & Pick<District, 'id' | 'name'>
  )> }
);

export type StudentUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email' | 'username' | 'isBlocked' | 'isTemporary' | 'signupType' | 'isEmailVerified'>
  & { student: Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, 'id'>
  )> }
);

export type UserWithStatsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email' | 'username' | 'isBlocked' | 'createdAt' | 'isDeleted' | 'isTemporary'>
  & { lifeStage: Maybe<(
    { __typename?: 'UserLifeStageField' }
    & Pick<UserLifeStageField, 'value' | 'name'>
  )>, investmentExperience: Maybe<(
    { __typename?: 'UserInvestmentExperienceField' }
    & Pick<UserInvestmentExperienceField, 'value' | 'name'>
  )>, student: Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, 'id'>
    & { badgeBoard: Array<(
      { __typename?: 'BadgeBoard' }
      & Pick<BadgeBoard, 'id'>
      & { badge: (
        { __typename?: 'BadgeBoardBadge' }
        & Pick<BadgeBoardBadge, 'id' | 'name' | 'description'>
        & { icon: (
          { __typename?: 'Icon' }
          & Pick<Icon, 'svg' | 'png'>
        ) }
      ) }
    )>, progress: (
      { __typename?: 'StudentProgress' }
      & Pick<StudentProgress, 'regionRank' | 'countryRank'>
      & { points: (
        { __typename?: 'StudentProgressPoints' }
        & Pick<StudentProgressPoints, 'reached' | 'total'>
      ) }
    ) }
  )> }
);

export type AdminUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'role' | 'email' | 'username' | 'isBlocked' | 'isEmailVerified'>
);

export type MetaFragment = (
  { __typename?: 'BatchMeta' }
  & Pick<BatchMeta, 'itemsPerPage' | 'items' | 'page' | 'pages'>
);

export type ChapterFragment = (
  { __typename?: 'Chapter' }
  & Pick<Chapter, 'id' | 'name' | 'index' | 'status' | 'progress' | 'maxPoints' | 'description' | 'totalLength' | 'isLastActive' | 'acquiredPoints'>
  & { summaryTest: Maybe<(
    { __typename?: 'Test' }
    & Pick<Test, 'id' | 'maxPoints' | 'isCompleted' | 'acquiredPoints'>
  )>, icon: (
    { __typename?: 'Icon' }
    & Pick<Icon, 'svg' | 'png'>
  ) }
);

export type BadgeFragment = (
  { __typename?: 'BadgeBoardBadge' }
  & Pick<BadgeBoardBadge, 'id' | 'name' | 'description'>
  & { icon: (
    { __typename?: 'Icon' }
    & Pick<Icon, 'svg' | 'png'>
  ) }
);

export type BadgesFragment = (
  { __typename?: 'BadgeWithData' }
  & { badge: (
    { __typename?: 'Badge' }
    & Pick<Badge, 'id' | 'name' | 'description'>
    & { icon: (
      { __typename?: 'Icon' }
      & Pick<Icon, 'svg' | 'png'>
    ) }
  ) }
);

export type StudentWithBadgesFragment = (
  { __typename?: 'Student' }
  & Pick<Student, 'id'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'username'>
  ), progress: (
    { __typename?: 'StudentProgress' }
    & { points: (
      { __typename?: 'StudentProgressPoints' }
      & Pick<StudentProgressPoints, 'reached'>
    ) }
  ), badgeBoard: Array<(
    { __typename?: 'BadgeBoard' }
    & { badge: (
      { __typename?: 'BadgeBoardBadge' }
      & Pick<BadgeBoardBadge, 'name'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<Icon, 'svg' | 'png'>
      ) }
    ) }
  )> }
);

export type UpdateSelfUserMutationVariables = {
  data: UpdateSelfUserInput
};


export type UpdateSelfUserMutation = (
  { __typename?: 'Mutation' }
  & { updateSelfUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'username'>
    & { lifeStage: Maybe<(
      { __typename?: 'UserLifeStageField' }
      & Pick<UserLifeStageField, 'value' | 'name'>
    )>, investmentExperience: Maybe<(
      { __typename?: 'UserInvestmentExperienceField' }
      & Pick<UserInvestmentExperienceField, 'value' | 'name'>
    )>, district: Maybe<(
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name'>
    )>, student: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'id'>
    )> }
  ) }
);

export type UpdateUserMutationVariables = {
  where: UserWhereId,
  data: UserUpdateInput
};


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'User' }
    & Pick<User, 'email' | 'username'>
  ) }
);

export type CreateLectureFileMutationVariables = {
  data: CreateLectureFileInput
};


export type CreateLectureFileMutation = (
  { __typename?: 'Mutation' }
  & { createLectureFile: (
    { __typename?: 'LectureFile' }
    & Pick<LectureFile, 'id' | 'filename' | 'type'>
  ) }
);

export type CreateResumableUploadMutationVariables = {
  data?: Maybe<CreateResumableUploadInput>
};


export type CreateResumableUploadMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createResumableUpload'>
);

export type GetSignedUrlMutationVariables = {
  gcsId: Scalars['String']
};


export type GetSignedUrlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'getSignedUrl'>
);

export type UpdateTemporaryUserMutationVariables = {
  data: UpdateTemporaryUserInput
};


export type UpdateTemporaryUserMutation = (
  { __typename?: 'Mutation' }
  & { updateTemporaryUser: (
    { __typename?: 'AuthUser' }
    & Pick<AuthUser, 'accessToken' | 'refreshToken' | 'username' | 'name' | 'email'>
  ) }
);

export type CreateLectureMutationVariables = {
  data: CreateLectureInput
};


export type CreateLectureMutation = (
  { __typename?: 'Mutation' }
  & { createLecture: (
    { __typename?: 'Lecture' }
    & Pick<Lecture, 'id'>
  ) }
);

export type UpdateLectureMutationVariables = {
  where: LectureUniqueRequiredInput,
  data: UpdateLectureInput
};


export type UpdateLectureMutation = (
  { __typename?: 'Mutation' }
  & { updateLecture: (
    { __typename?: 'Lecture' }
    & Pick<Lecture, 'id'>
  ) }
);

export type LoginWithFacebookMutationVariables = {
  data: FacebookLoginInput
};


export type LoginWithFacebookMutation = (
  { __typename?: 'Mutation' }
  & { loginWithFacebook: (
    { __typename?: 'AuthUser' }
    & Pick<AuthUser, 'accessToken' | 'isReactivated' | 'refreshToken' | 'lastLogin' | 'username' | 'email' | 'role'>
  ) }
);

export type LoginWithAppleMutationVariables = {
  data: AppleLoginInput
};


export type LoginWithAppleMutation = (
  { __typename?: 'Mutation' }
  & { loginWithApple: (
    { __typename?: 'AuthUser' }
    & Pick<AuthUser, 'accessToken' | 'isReactivated' | 'refreshToken' | 'lastLogin' | 'username' | 'email' | 'role'>
  ) }
);

export type LoginWithGoogleMutationVariables = {
  data: GoogleLoginInput
};


export type LoginWithGoogleMutation = (
  { __typename?: 'Mutation' }
  & { loginWithGoogle: (
    { __typename?: 'AuthUser' }
    & Pick<AuthUser, 'accessToken' | 'isReactivated' | 'refreshToken' | 'lastLogin' | 'username' | 'email' | 'role'>
  ) }
);

export type ResetPasswordMutationVariables = {
  data: ResetPasswordInput
};


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: (
    { __typename?: 'AuthUser' }
    & Pick<AuthUser, 'accessToken' | 'refreshToken' | 'username' | 'email'>
  ) }
);

export type ChangePasswordMutationVariables = {
  data: ChangePasswordInput
};


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type RegisterStudentMutationVariables = {
  data: RegisterStudentInput
};


export type RegisterStudentMutation = (
  { __typename?: 'Mutation' }
  & { registerStudent: (
    { __typename?: 'AuthUser' }
    & Pick<AuthUser, 'accessToken' | 'refreshToken'>
  ) }
);

export type RegisterTemporaryStudentMutationVariables = {};


export type RegisterTemporaryStudentMutation = (
  { __typename?: 'Mutation' }
  & { registerTemporaryStudent: (
    { __typename?: 'TemporaryStudent' }
    & Pick<TemporaryStudent, 'accessToken' | 'refreshToken'>
  ) }
);

export type CreateChapterMutationVariables = {
  data: CreateChapterInput
};


export type CreateChapterMutation = (
  { __typename?: 'Mutation' }
  & { createChapter: (
    { __typename?: 'Chapter' }
    & ChapterFragment
  ) }
);

export type UpdateChapterMutationVariables = {
  data: UpdateChapterInput,
  where: ChapterUniqueRequiredInput
};


export type UpdateChapterMutation = (
  { __typename?: 'Mutation' }
  & { updateChapter: (
    { __typename?: 'Chapter' }
    & ChapterFragment
  ) }
);

export type ApproveChapterMutationVariables = {
  where: ChapterUniqueRequiredInput
};


export type ApproveChapterMutation = (
  { __typename?: 'Mutation' }
  & { approveChapter: (
    { __typename?: 'Chapter' }
    & ChapterFragment
  ) }
);

export type RejectChapterMutationVariables = {
  data: RejectChapterInput,
  where: ChapterUniqueRequiredInput
};


export type RejectChapterMutation = (
  { __typename?: 'Mutation' }
  & { rejectChapter: (
    { __typename?: 'Chapter' }
    & ChapterFragment
  ) }
);

export type DeleteChapterMutationVariables = {
  where: ChapterUniqueRequiredInput
};


export type DeleteChapterMutation = (
  { __typename?: 'Mutation' }
  & { deleteChapter: (
    { __typename?: 'Chapter' }
    & ChapterFragment
  ) }
);

export type ApproveLectureMutationVariables = {
  where: LectureUniqueRequiredInput
};


export type ApproveLectureMutation = (
  { __typename?: 'Mutation' }
  & { approveLecture: (
    { __typename?: 'Lecture' }
    & Pick<Lecture, 'id'>
  ) }
);

export type RejectLectureMutationVariables = {
  data: RejectLectureInput,
  where: LectureUniqueRequiredInput
};


export type RejectLectureMutation = (
  { __typename?: 'Mutation' }
  & { rejectLecture: (
    { __typename?: 'Lecture' }
    & Pick<Lecture, 'id'>
  ) }
);

export type DeleteLectureMutationVariables = {
  where: LectureUniqueRequiredInput
};


export type DeleteLectureMutation = (
  { __typename?: 'Mutation' }
  & { deleteLecture: (
    { __typename?: 'Lecture' }
    & Pick<Lecture, 'id'>
  ) }
);

export type LoginMutationVariables = {
  data: LoginInput
};


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'AuthUser' }
    & Pick<AuthUser, 'isReactivated' | 'refreshToken' | 'accessToken' | 'username' | 'role'>
  ) }
);

export type LogoutMutationVariables = {};


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type CreateTestSessionMutationVariables = {
  data: CreateTestSessionInput
};


export type CreateTestSessionMutation = (
  { __typename?: 'Mutation' }
  & { createTestSession: (
    { __typename?: 'TestSession' }
    & SessionFragment
  ) }
);

export type NextTestStepMutationVariables = {
  data: NextTestStepInput
};


export type NextTestStepMutation = (
  { __typename?: 'Mutation' }
  & { nextTestStep: (
    { __typename?: 'TestStep' }
    & StepFragment
  ) }
);

export type SubmitAnswerMutationVariables = {
  data: SubmitAnswerInput
};


export type SubmitAnswerMutation = (
  { __typename?: 'Mutation' }
  & { submitAnswer: (
    { __typename?: 'TestStep' }
    & StepFragment
  ) }
);

export type ForgottenPasswordResetMutationVariables = {
  data: RequestPasswordResetEmailInput
};


export type ForgottenPasswordResetMutation = (
  { __typename?: 'Mutation' }
  & { forgottenPasswordReset: (
    { __typename?: 'ForgottenPasswordReset' }
    & Pick<ForgottenPasswordReset, 'isEmailReset'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'username'>
    ) }
  ) }
);

export type ResetPasswordWithEmailTokenMutationVariables = {
  data: resetPasswordWithEmailToken
};


export type ResetPasswordWithEmailTokenMutation = (
  { __typename?: 'Mutation' }
  & { resetPasswordWithEmailToken: (
    { __typename?: 'AuthUser' }
    & Pick<AuthUser, 'accessToken' | 'refreshToken'>
  ) }
);

export type SubmitDailyQuestionMutationVariables = {
  data: SubmitDailyQuestionAnswerInput
};


export type SubmitDailyQuestionMutation = (
  { __typename?: 'Mutation' }
  & { submitDailyQuestion: (
    { __typename?: 'DailyQuestionResult' }
    & Pick<DailyQuestionResult, 'isCorrect' | 'description' | 'acquiredPoints'>
    & { correctAnswer: (
      { __typename?: 'DailyQuestionAnswer' }
      & Pick<DailyQuestionAnswer, 'id' | 'text'>
    ), question: (
      { __typename?: 'DailyQuestionQuestion' }
      & Pick<DailyQuestionQuestion, 'id' | 'text'>
    ), answer: (
      { __typename?: 'DailyQuestionAnswer' }
      & Pick<DailyQuestionAnswer, 'id' | 'text'>
    ) }
  ) }
);

export type CreateWordMutationVariables = {
  data: CreateWordInput
};


export type CreateWordMutation = (
  { __typename?: 'Mutation' }
  & { createWord: (
    { __typename?: 'Word' }
    & Pick<Word, 'id' | 'word' | 'description'>
  ) }
);

export type DeleteWordMutationVariables = {
  where: WordWhereUniqueInput
};


export type DeleteWordMutation = (
  { __typename?: 'Mutation' }
  & { deleteWord: (
    { __typename?: 'Word' }
    & Pick<Word, 'id' | 'word'>
  ) }
);

export type UpdateWordMutationVariables = {
  where: WordWhereUniqueInput,
  data: UpdateWordInput
};


export type UpdateWordMutation = (
  { __typename?: 'Mutation' }
  & { updateWord: (
    { __typename?: 'Word' }
    & Pick<Word, 'id' | 'word' | 'description'>
  ) }
);

export type DeleteOwnAccountMutationVariables = {};


export type DeleteOwnAccountMutation = (
  { __typename?: 'Mutation' }
  & { deleteOwnAccount: (
    { __typename?: 'User' }
    & Pick<User, 'email'>
  ) }
);

export type CreateTestMutationVariables = {
  lecture: LectureWhereUniqueInput
};


export type CreateTestMutation = (
  { __typename?: 'Mutation' }
  & { createTest: (
    { __typename?: 'Test' }
    & Pick<Test, 'id'>
  ) }
);

export type CreateTestQuestionMutationVariables = {
  test: TestWhereUniqueInput,
  data: TestQuestionInput
};


export type CreateTestQuestionMutation = (
  { __typename?: 'Mutation' }
  & { createTestQuestion: (
    { __typename?: 'Question' }
    & QuestionFragment
  ) }
);

export type DeleteTestQuestionMutationVariables = {
  where: QuestionWhereUniqueInput
};


export type DeleteTestQuestionMutation = (
  { __typename?: 'Mutation' }
  & { deleteTestQuestion: (
    { __typename?: 'Question' }
    & Pick<Question, 'id'>
  ) }
);

export type UpdateTestQuestionMutationVariables = {
  where: QuestionWhereUniqueInput,
  data: TestQuestionUpdateInput
};


export type UpdateTestQuestionMutation = (
  { __typename?: 'Mutation' }
  & { updateTestQuestion: (
    { __typename?: 'Question' }
    & QuestionFragment
  ) }
);

export type CreateQuestionAnswersMutationVariables = {
  question: QuestionWhereUniqueInput,
  data: Array<TestAnswerInput>
};


export type CreateQuestionAnswersMutation = (
  { __typename?: 'Mutation' }
  & { createQuestionAnswers: Array<(
    { __typename?: 'Answer' }
    & AnswerFragment
  )> }
);

export type UpdateQuestionAnswersMutationVariables = {
  data: Array<TestAnswerUpdateInput>
};


export type UpdateQuestionAnswersMutation = (
  { __typename?: 'Mutation' }
  & { updateQuestionAnswers: Array<(
    { __typename?: 'Answer' }
    & AnswerFragment
  )> }
);

export type BlockLectureMutationVariables = {
  where: LectureUniqueRequiredInput
};


export type BlockLectureMutation = (
  { __typename?: 'Mutation' }
  & { blockLecture: (
    { __typename?: 'Lecture' }
    & Pick<Lecture, 'id'>
  ) }
);

export type BlockChapterMutationVariables = {
  where: ChapterUniqueRequiredInput
};


export type BlockChapterMutation = (
  { __typename?: 'Mutation' }
  & { blockChapter: (
    { __typename?: 'Chapter' }
    & Pick<Chapter, 'id'>
  ) }
);

export type BlockUserMutationVariables = {
  where: UserWhereId
};


export type BlockUserMutation = (
  { __typename?: 'Mutation' }
  & { blockUser: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type UnblockUserMutationVariables = {
  where: UserWhereId
};


export type UnblockUserMutation = (
  { __typename?: 'Mutation' }
  & { unblockUser: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type InviteUserMutationVariables = {
  data: InvitationCreateInput
};


export type InviteUserMutation = (
  { __typename?: 'Mutation' }
  & { inviteUser: (
    { __typename?: 'Invitation' }
    & Pick<Invitation, 'id'>
  ) }
);

export type AcceptInvitationMutationVariables = {
  data: InvitationAcceptInput
};


export type AcceptInvitationMutation = (
  { __typename?: 'Mutation' }
  & { acceptInvitation: (
    { __typename?: 'AuthUser' }
    & Pick<AuthUser, 'role' | 'email' | 'username' | 'accessToken' | 'refreshToken'>
  ) }
);

export type PublishChapterMutationVariables = {
  where: ChapterUniqueRequiredInput
};


export type PublishChapterMutation = (
  { __typename?: 'Mutation' }
  & { publishChapter: (
    { __typename?: 'Chapter' }
    & Pick<Chapter, 'id'>
  ) }
);

export type PublishLectureMutationVariables = {
  where: LectureUniqueRequiredInput
};


export type PublishLectureMutation = (
  { __typename?: 'Mutation' }
  & { publishLecture: (
    { __typename?: 'Lecture' }
    & Pick<Lecture, 'id'>
  ) }
);

export type AcceptBadgesMutationVariables = {
  id: Array<Scalars['ID']>
};


export type AcceptBadgesMutation = (
  { __typename?: 'Mutation' }
  & { acceptBadges: Array<(
    { __typename?: 'BadgeBoard' }
    & Pick<BadgeBoard, 'id'>
  )> }
);

export type DeleteNotificationsMutationVariables = {
  id: Array<Scalars['ID']>
};


export type DeleteNotificationsMutation = (
  { __typename?: 'Mutation' }
  & { deleteNotifications: (
    { __typename?: 'BatchPayload' }
    & Pick<BatchPayload, 'count'>
  ) }
);

export type CreateCustomNotificationMutationVariables = {
  data: CustomNotificationInput
};


export type CreateCustomNotificationMutation = (
  { __typename?: 'Mutation' }
  & { createCustomNotification: (
    { __typename?: 'CustomNotification' }
    & Pick<CustomNotification, 'id'>
  ) }
);

export type UpdateCustomNotificationMutationVariables = {
  data: CustomNotificationInput,
  where: CustomNotificationWhereUniqueInput
};


export type UpdateCustomNotificationMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomNotification: (
    { __typename?: 'CustomNotification' }
    & Pick<CustomNotification, 'id'>
  ) }
);

export type DeleteCustomNotificationMutationVariables = {
  where: CustomNotificationWhereUniqueInput
};


export type DeleteCustomNotificationMutation = (
  { __typename?: 'Mutation' }
  & { deleteCustomNotification: (
    { __typename?: 'CustomNotification' }
    & Pick<CustomNotification, 'id'>
  ) }
);

export type ApproveCustomNotificationMutationVariables = {
  where: CustomNotificationWhereUniqueInput
};


export type ApproveCustomNotificationMutation = (
  { __typename?: 'Mutation' }
  & { approveCustomNotification: (
    { __typename?: 'CustomNotification' }
    & Pick<CustomNotification, 'id'>
  ) }
);

export type RejectCustomNotificationMutationVariables = {
  data: RejectCustomNotificationInput,
  where: CustomNotificationWhereUniqueInput
};


export type RejectCustomNotificationMutation = (
  { __typename?: 'Mutation' }
  & { rejectCustomNotification: (
    { __typename?: 'CustomNotification' }
    & Pick<CustomNotification, 'id'>
  ) }
);

export type UpdateFcmMutationVariables = {
  data: NotificationTokenUpdateInput
};


export type UpdateFcmMutation = (
  { __typename?: 'Mutation' }
  & { updateFcm: (
    { __typename?: 'Fcm' }
    & Pick<Fcm, 'id'>
  ) }
);

export type ReportIssueMutationVariables = {
  question: QuestionWhereUniqueInput,
  issue: Scalars['String']
};


export type ReportIssueMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reportIssue'>
);

export type VerifyEmailMutationVariables = {
  data: VerifyEmailInput
};


export type VerifyEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'verifyEmail'>
);

export type SendEmailVerificationMutationVariables = {};


export type SendEmailVerificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendEmailVerification'>
);

export type BlockAndDeleteUserMutationVariables = {
  where: UserWhereId
};


export type BlockAndDeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { blockAndDeleteUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'username'>
  ) }
);

export type CreateChapterUpdateDraftMutationVariables = {
  where: QuestionWhereUniqueInput
};


export type CreateChapterUpdateDraftMutation = (
  { __typename?: 'Mutation' }
  & { createChapterUpdateDraft: (
    { __typename?: 'ChapterDraft' }
    & Pick<ChapterDraft, 'id' | 'name' | 'description'>
  ) }
);

export type UpdateChapterUpdateDraftMutationVariables = {
  where: ChapterDraftWhereUniqueInput,
  data: UpdateChapterInput
};


export type UpdateChapterUpdateDraftMutation = (
  { __typename?: 'Mutation' }
  & { updateChapterUpdateDraft: (
    { __typename?: 'ChapterDraft' }
    & Pick<ChapterDraft, 'id' | 'name' | 'description'>
    & { icon: (
      { __typename?: 'Icon' }
      & Pick<Icon, 'png' | 'svg'>
    ), author: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ) }
  ) }
);

export type CreateLectureUpdateDraftMutationVariables = {
  where: LectureWhereUniqueInput
};


export type CreateLectureUpdateDraftMutation = (
  { __typename?: 'Mutation' }
  & { createLectureUpdateDraft: (
    { __typename?: 'LectureDraft' }
    & Pick<LectureDraft, 'id'>
  ) }
);

export type UpdateLectureUpdateDraftMutationVariables = {
  where: LectureDraftWhereUniqueInput,
  data: UpdateLectureDraftInput
};


export type UpdateLectureUpdateDraftMutation = (
  { __typename?: 'Mutation' }
  & { updateLectureUpdateDraft: (
    { __typename?: 'LectureDraft' }
    & Pick<LectureDraft, 'id'>
  ) }
);

export type ApproveChapterUpdateDraftMutationVariables = {
  where: ChapterDraftWhereUniqueInput
};


export type ApproveChapterUpdateDraftMutation = (
  { __typename?: 'Mutation' }
  & { approveChapterUpdateDraft: (
    { __typename?: 'ChapterDraft' }
    & Pick<ChapterDraft, 'id' | 'isMerged' | 'isRejected'>
  ) }
);

export type ApproveLectureUpdateDraftMutationVariables = {
  where: LectureDraftWhereUniqueInput
};


export type ApproveLectureUpdateDraftMutation = (
  { __typename?: 'Mutation' }
  & { approveLectureUpdateDraft: (
    { __typename?: 'LectureDraft' }
    & Pick<LectureDraft, 'id' | 'isMerged' | 'isRejected'>
  ) }
);

export type RejectChapterUpdateDraftMutationVariables = {
  where: ChapterDraftWhereUniqueInput
};


export type RejectChapterUpdateDraftMutation = (
  { __typename?: 'Mutation' }
  & { rejectChapterUpdateDraft: (
    { __typename?: 'ChapterDraft' }
    & Pick<ChapterDraft, 'id' | 'isMerged' | 'isRejected'>
  ) }
);

export type RejectLectureUpdateDraftMutationVariables = {
  where: LectureDraftWhereUniqueInput
};


export type RejectLectureUpdateDraftMutation = (
  { __typename?: 'Mutation' }
  & { rejectLectureUpdateDraft: (
    { __typename?: 'LectureDraft' }
    & Pick<LectureDraft, 'id' | 'isMerged' | 'isRejected'>
  ) }
);

export type UpdateProfilePictureMutationVariables = {
  data: UpdateProfilePictureInput
};


export type UpdateProfilePictureMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateProfilePicture'>
);

export type AddTimeSpentMutationVariables = {
  seconds: Scalars['Int']
};


export type AddTimeSpentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addTimeSpent'>
);

export type MeQueryVariables = {};


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'profilePicture'>
    & { student: Maybe<(
      { __typename?: 'Student' }
      & { finalTestStatus: (
        { __typename?: 'FinalTestStatus' }
        & Pick<FinalTestStatus, 'isCompleted' | 'acquiredPoints' | 'requiredPoints'>
      ) }
      & StudentFragment
    )> }
    & UserFragment
  )> }
);

export type CanAccessAdminQueryVariables = {};


export type CanAccessAdminQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'canAccessAdmin'>
);

export type DiplomasQueryVariables = {
  page?: Maybe<Scalars['Int']>,
  itemsPerPage?: Maybe<Scalars['Int']>,
  filter?: Maybe<DiplomasFilterInput>,
  orderBy?: Maybe<DiplomasOrderByInput>
};


export type DiplomasQuery = (
  { __typename?: 'Query' }
  & { diplomas: (
    { __typename?: 'BatchDiplomas' }
    & { data: Array<(
      { __typename?: 'Diploma' }
      & Pick<Diploma, 'id' | 'code' | 'points' | 'createdAt'>
      & { student: (
        { __typename?: 'Student' }
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'email' | 'username' | 'timeSpent'>
        ) }
      ) }
    )>, meta: (
      { __typename?: 'BatchMeta' }
      & Pick<BatchMeta, 'page' | 'pages' | 'items' | 'itemsPerPage'>
    ) }
  ) }
);

export type UserLeaderboardPositionQueryVariables = {
  itemsPerPage: Scalars['Int'],
  filter?: Maybe<Array<LeaderboardFilterEnum>>
};


export type UserLeaderboardPositionQuery = (
  { __typename?: 'Query' }
  & { userLeaderboardPosition: (
    { __typename?: 'UserLeaderboardPosition' }
    & Pick<UserLeaderboardPosition, 'page'>
  ) }
);

export type IsEmailUsedQueryVariables = {
  email: Scalars['String']
};


export type IsEmailUsedQuery = (
  { __typename?: 'Query' }
  & { isEmailUsed: (
    { __typename?: 'IsEmailUsedReponse' }
    & Pick<IsEmailUsedReponse, 'isUsed' | 'role'>
  ) }
);

export type UsersQueryVariables = {
  search?: Maybe<Scalars['String']>
};


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'BatchUsers' }
    & { data: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'role' | 'email'>
    )> }
  ) }
);

export type UsersWithStatsQueryVariables = {
  page?: Maybe<Scalars['Int']>,
  itemsPerPage?: Maybe<Scalars['Int']>,
  filter?: Maybe<UsersFilterInput>,
  orderBy?: Maybe<UsersOrderByInput>
};


export type UsersWithStatsQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'BatchUsers' }
    & { data: Array<(
      { __typename?: 'User' }
      & UserWithStatsFragment
    )>, meta: (
      { __typename?: 'BatchMeta' }
      & MetaFragment
    ) }
  ) }
);

export type UserStatsQueryVariables = {};


export type UserStatsQuery = (
  { __typename?: 'Query' }
  & { userStats: (
    { __typename?: 'UserStats' }
    & Pick<UserStats, 'userCount' | 'diplomaCount'>
  ) }
);

export type StudentUsersQueryVariables = {
  page?: Maybe<Scalars['Int']>,
  itemsPerPage?: Maybe<Scalars['Int']>,
  search?: Maybe<Scalars['String']>,
  orderBy?: Maybe<UsersOrderByInput>
};


export type StudentUsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'BatchUsers' }
    & { data: Array<(
      { __typename?: 'User' }
      & StudentUserFragment
    )>, meta: (
      { __typename?: 'BatchMeta' }
      & MetaFragment
    ) }
  ) }
);

export type AdminUsersQueryVariables = {
  page?: Maybe<Scalars['Int']>,
  itemsPerPage?: Maybe<Scalars['Int']>,
  search?: Maybe<Scalars['String']>,
  orderBy?: Maybe<UsersOrderByInput>
};


export type AdminUsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'BatchUsers' }
    & { data: Array<(
      { __typename?: 'User' }
      & AdminUserFragment
    )>, meta: (
      { __typename?: 'BatchMeta' }
      & MetaFragment
    ) }
  ) }
);

export type AdminChapterQueryVariables = {
  where: ChapterWhereUniqueInput
};


export type AdminChapterQuery = (
  { __typename?: 'Query' }
  & { chapter: (
    { __typename?: 'Chapter' }
    & Pick<Chapter, 'id' | 'name' | 'index' | 'status' | 'progress' | 'maxPoints' | 'description' | 'totalLength' | 'acquiredPoints'>
    & { drafts: Array<(
      { __typename?: 'ChapterDraft' }
      & Pick<ChapterDraft, 'id' | 'isMerged' | 'isRejected' | 'name' | 'description'>
      & { chapter: (
        { __typename?: 'Chapter' }
        & Pick<Chapter, 'id' | 'name' | 'index' | 'status' | 'progress' | 'maxPoints' | 'description' | 'totalLength' | 'acquiredPoints'>
      ), author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'username'>
      ), icon: (
        { __typename?: 'Icon' }
        & Pick<Icon, 'svg' | 'png'>
      ) }
    )>, author: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'username'>
    ), icon: (
      { __typename?: 'Icon' }
      & Pick<Icon, 'svg' | 'png'>
    ) }
  ) }
);

export type ChapterQueryVariables = {
  where: ChapterWhereUniqueInput
};


export type ChapterQuery = (
  { __typename?: 'Query' }
  & { chapter: (
    { __typename?: 'Chapter' }
    & Pick<Chapter, 'id' | 'name' | 'index' | 'status' | 'progress' | 'maxPoints' | 'description' | 'totalLength' | 'isLastActive' | 'acquiredPoints'>
    & { drafts: Array<(
      { __typename?: 'ChapterDraft' }
      & Pick<ChapterDraft, 'id' | 'isMerged' | 'isRejected'>
    )>, summaryTest: Maybe<(
      { __typename?: 'Test' }
      & Pick<Test, 'id' | 'maxPoints' | 'isCompleted' | 'acquiredPoints'>
    )>, icon: (
      { __typename?: 'Icon' }
      & Pick<Icon, 'svg' | 'png'>
    ), lectures: Array<(
      { __typename?: 'Lecture' }
      & LectureForChapterFragment
    )> }
  ) }
);

export type ChaptersWithoutLecturesQueryVariables = {};


export type ChaptersWithoutLecturesQuery = (
  { __typename?: 'Query' }
  & { chapters: Array<(
    { __typename?: 'Chapter' }
    & Pick<Chapter, 'id' | 'name' | 'index' | 'status' | 'maxPoints' | 'acquiredPoints'>
  )> }
);

export type ChaptersQueryVariables = {};


export type ChaptersQuery = (
  { __typename?: 'Query' }
  & { chapters: Array<(
    { __typename?: 'Chapter' }
    & Pick<Chapter, 'id' | 'name' | 'index' | 'status' | 'progress' | 'maxPoints' | 'description' | 'totalLength' | 'isLastActive' | 'acquiredPoints'>
    & { icon: (
      { __typename?: 'Icon' }
      & Pick<Icon, 'svg' | 'png'>
    ), lectures: Array<(
      { __typename?: 'Lecture' }
      & Pick<Lecture, 'id' | 'name' | 'status' | 'maxPoints' | 'acquiredPoints'>
      & { library: Maybe<Array<{ __typename?: 'Audio' } | (
        { __typename?: 'Video' }
        & Pick<Video, 'id' | 'preview'>
      ) | { __typename?: 'Transcript' } | { __typename?: 'Word' }>>, test: Maybe<(
        { __typename?: 'Test' }
        & Pick<Test, 'id' | 'isChapterSummary'>
      )> }
    )> }
  )> }
);

export type ChapterDraftsQueryVariables = {};


export type ChapterDraftsQuery = (
  { __typename?: 'Query' }
  & { chapterDrafts: Array<(
    { __typename?: 'ChapterDraft' }
    & Pick<ChapterDraft, 'id'>
    & { chapter: (
      { __typename?: 'Chapter' }
      & Pick<Chapter, 'id' | 'name' | 'index' | 'status' | 'maxPoints'>
      & { drafts: Array<(
        { __typename?: 'ChapterDraft' }
        & Pick<ChapterDraft, 'id' | 'isMerged' | 'isRejected'>
      )>, author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'username'>
      ), lectures: Array<(
        { __typename?: 'Lecture' }
        & Pick<Lecture, 'id' | 'name' | 'status' | 'maxPoints'>
        & { drafts: Array<(
          { __typename?: 'LectureDraft' }
          & Pick<LectureDraft, 'id' | 'isMerged' | 'isRejected'>
        )>, author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'username'>
        ) }
      )> }
    ) }
  )> }
);

export type ChaptersWithIdQueryVariables = {};


export type ChaptersWithIdQuery = (
  { __typename?: 'Query' }
  & { chapters: Array<(
    { __typename?: 'Chapter' }
    & Pick<Chapter, 'id' | 'name'>
  )> }
);

export type AdminChaptersQueryVariables = {};


export type AdminChaptersQuery = (
  { __typename?: 'Query' }
  & { chapters: Array<(
    { __typename?: 'Chapter' }
    & Pick<Chapter, 'id' | 'name' | 'index' | 'status' | 'maxPoints'>
    & { drafts: Array<(
      { __typename?: 'ChapterDraft' }
      & Pick<ChapterDraft, 'id' | 'isMerged' | 'isRejected'>
    )>, author: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'username'>
    ), lectures: Array<(
      { __typename?: 'Lecture' }
      & Pick<Lecture, 'id' | 'name' | 'status' | 'maxPoints'>
      & { drafts: Array<(
        { __typename?: 'LectureDraft' }
        & Pick<LectureDraft, 'id' | 'isMerged' | 'isRejected'>
      )>, author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'username'>
      ) }
    )> }
  )> }
);

export type LectureQueryVariables = {
  where: LectureWhereUniqueInput
};


export type LectureQuery = (
  { __typename?: 'Query' }
  & { lecture: (
    { __typename?: 'Lecture' }
    & { library: Maybe<Array<(
      { __typename?: 'Audio' }
      & AudioFragment
    ) | (
      { __typename?: 'Video' }
      & VideoFragment
    ) | (
      { __typename?: 'Transcript' }
      & TranscriptFragment
    ) | (
      { __typename?: 'Word' }
      & WordFragment
    )>> }
    & LectureFragment
  ) }
);

export type AdminLectureQueryVariables = {
  where: LectureWhereUniqueInput
};


export type AdminLectureQuery = (
  { __typename?: 'Query' }
  & { lecture: (
    { __typename?: 'Lecture' }
    & { author: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'username'>
    ), drafts: Array<(
      { __typename?: 'LectureDraft' }
      & Pick<LectureDraft, 'id' | 'isMerged' | 'isRejected' | 'name' | 'summary' | 'description' | 'transcript'>
      & { chapter: (
        { __typename?: 'Chapter' }
        & Pick<Chapter, 'id' | 'name'>
      ), author: (
        { __typename?: 'User' }
        & Pick<User, 'id'>
      ), files: Array<(
        { __typename?: 'LectureFile' }
        & Pick<LectureFile, 'id' | 'gcsId' | 'filename' | 'type' | 'src' | 'preview' | 'previewSrc'>
      )>, test: Maybe<(
        { __typename?: 'TestDraft' }
        & Pick<TestDraft, 'id'>
        & { questions: Array<(
          { __typename?: 'QuestionDraft' }
          & Pick<QuestionDraft, 'id' | 'text' | 'description'>
          & { answers: Array<(
            { __typename?: 'AnswerDraft' }
            & Pick<AnswerDraft, 'id' | 'text'>
          )> }
        )> }
      )> }
    )>, files: Array<(
      { __typename?: 'LectureFile' }
      & Pick<LectureFile, 'id' | 'gcsId' | 'filename' | 'type' | 'src' | 'preview' | 'previewSrc'>
    )>, test: Maybe<(
      { __typename?: 'Test' }
      & Pick<Test, 'id' | 'maxPoints' | 'isFinalTest' | 'isCompleted' | 'isChapterSummary'>
      & { lecture: Maybe<(
        { __typename?: 'Lecture' }
        & Pick<Lecture, 'id'>
        & { chapter: (
          { __typename?: 'Chapter' }
          & Pick<Chapter, 'id'>
        ) }
      )>, questions: Array<(
        { __typename?: 'Question' }
        & QuestionFragment
      )> }
    )> }
    & LectureFragment
  ) }
);

export type LecturesQueryVariables = {};


export type LecturesQuery = (
  { __typename?: 'Query' }
  & { lectures: Array<(
    { __typename?: 'Lecture' }
    & Pick<Lecture, 'id' | 'name' | 'status' | 'description'>
  )> }
);

export type SecurityQuestionQueryVariables = {};


export type SecurityQuestionQuery = (
  { __typename?: 'Query' }
  & { securityQuestions: Array<(
    { __typename?: 'SecurityQuestion' }
    & Pick<SecurityQuestion, 'id' | 'text'>
  )> }
);

export type UserSecurityAnswersQueryVariables = {
  username: Scalars['String']
};


export type UserSecurityAnswersQuery = (
  { __typename?: 'Query' }
  & { userSecurityAnswers: Array<(
    { __typename?: 'UserSecurityAnswer' }
    & Pick<UserSecurityAnswer, 'id'>
    & { question: (
      { __typename?: 'SecurityQuestion' }
      & Pick<SecurityQuestion, 'id' | 'text'>
    ) }
  )> }
);

export type CurrentTestStepQueryVariables = {
  session: TestSessionWhereUniqueInput
};


export type CurrentTestStepQuery = (
  { __typename?: 'Query' }
  & { currentTestStep: (
    { __typename?: 'TestStep' }
    & StepFragment
  ) }
);

export type ValidatorsQueryVariables = {};


export type ValidatorsQuery = (
  { __typename?: 'Query' }
  & { validators: (
    { __typename?: 'Validators' }
    & { email: (
      { __typename?: 'Validator' }
      & Pick<Validator, 'description' | 'regex'>
    ), username: (
      { __typename?: 'Validator' }
      & Pick<Validator, 'description' | 'regex'>
    ), password: (
      { __typename?: 'Validator' }
      & Pick<Validator, 'description' | 'regex'>
    ) }
  ) }
);

export type DailyQuestionQueryVariables = {};


export type DailyQuestionQuery = (
  { __typename?: 'Query' }
  & { dailyQuestion: (
    { __typename?: 'DailyQuestion' }
    & { question: Maybe<(
      { __typename?: 'DailyQuestionQuestion' }
      & Pick<DailyQuestionQuestion, 'text' | 'id'>
    )>, answers: Maybe<Array<(
      { __typename?: 'DailyQuestionAnswer' }
      & Pick<DailyQuestionAnswer, 'text' | 'id'>
    )>> }
  ) }
);

export type LibraryQueryVariables = {
  filter?: Maybe<LibraryFilter>,
  itemsPerPage?: Maybe<Scalars['Int']>,
  page?: Maybe<Scalars['Int']>
};


export type LibraryQuery = (
  { __typename?: 'Query' }
  & { library: (
    { __typename?: 'Library' }
    & { data: Array<(
      { __typename?: 'Audio' }
      & Pick<Audio, 'id' | 'src' | 'name' | 'length'>
    ) | (
      { __typename?: 'Video' }
      & Pick<Video, 'id' | 'src' | 'name' | 'length' | 'preview'>
      & { lecture: (
        { __typename?: 'Lecture' }
        & Pick<Lecture, 'id'>
        & { chapter: (
          { __typename?: 'Chapter' }
          & Pick<Chapter, 'id' | 'name'>
        ) }
      ) }
    ) | (
      { __typename?: 'Transcript' }
      & Pick<Transcript, 'id' | 'name' | 'text'>
    ) | (
      { __typename?: 'Word' }
      & Pick<Word, 'id' | 'word' | 'description'>
    )>, meta: (
      { __typename?: 'BatchMeta' }
      & MetaFragment
    ) }
  ) }
);

export type AdminLibraryQueryVariables = {
  filter?: Maybe<LibraryFilter>,
  itemsPerPage?: Maybe<Scalars['Int']>,
  page?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<BatchLibraryOrderByInput>
};


export type AdminLibraryQuery = (
  { __typename?: 'Query' }
  & { library: (
    { __typename?: 'Library' }
    & { data: Array<(
      { __typename?: 'Audio' }
      & AudioFragment
    ) | (
      { __typename?: 'Video' }
      & VideoFragment
    ) | (
      { __typename?: 'Transcript' }
      & TranscriptFragment
    ) | (
      { __typename?: 'Word' }
      & { author: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'username'>
      )> }
      & WordFragment
    )>, meta: (
      { __typename?: 'BatchMeta' }
      & MetaFragment
    ) }
  ) }
);

export type LibraryVideoItemQueryVariables = {
  where: LibraryItemWhereInput
};


export type LibraryVideoItemQuery = (
  { __typename?: 'Query' }
  & { libraryItem: { __typename?: 'Audio' } | (
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'name' | 'src' | 'length' | 'preview'>
    & { lecture: (
      { __typename?: 'Lecture' }
      & Pick<Lecture, 'id' | 'name'>
      & { chapter: (
        { __typename?: 'Chapter' }
        & Pick<Chapter, 'id' | 'name'>
      ) }
    ), similarItems: Array<{ __typename?: 'Audio' } | (
      { __typename?: 'Video' }
      & Pick<Video, 'id' | 'name' | 'src' | 'length' | 'preview'>
      & { lecture: (
        { __typename?: 'Lecture' }
        & Pick<Lecture, 'id' | 'name'>
        & { chapter: (
          { __typename?: 'Chapter' }
          & Pick<Chapter, 'id' | 'name'>
        ) }
      ) }
    ) | { __typename?: 'Transcript' } | { __typename?: 'Word' }> }
  ) | { __typename?: 'Transcript' } | { __typename?: 'Word' } }
);

export type LibraryAudioItemQueryVariables = {
  where: LibraryItemWhereInput
};


export type LibraryAudioItemQuery = (
  { __typename?: 'Query' }
  & { libraryItem: (
    { __typename?: 'Audio' }
    & Pick<Audio, 'id' | 'src' | 'name' | 'length'>
    & { lecture: (
      { __typename?: 'Lecture' }
      & Pick<Lecture, 'id'>
      & { chapter: (
        { __typename?: 'Chapter' }
        & Pick<Chapter, 'id' | 'name'>
      ) }
    ), similarItems: Array<(
      { __typename?: 'Audio' }
      & Pick<Audio, 'id' | 'src' | 'name'>
    ) | { __typename?: 'Video' } | { __typename?: 'Transcript' } | { __typename?: 'Word' }> }
  ) | { __typename?: 'Video' } | { __typename?: 'Transcript' } | { __typename?: 'Word' } }
);

export type LibraryTranscriptItemQueryVariables = {
  where: LibraryItemWhereInput
};


export type LibraryTranscriptItemQuery = (
  { __typename?: 'Query' }
  & { transcript: { __typename?: 'Audio' } | { __typename?: 'Video' } | (
    { __typename?: 'Transcript' }
    & Pick<Transcript, 'id' | 'name' | 'text'>
    & { lecture: (
      { __typename?: 'Lecture' }
      & Pick<Lecture, 'id' | 'name'>
      & { chapter: (
        { __typename?: 'Chapter' }
        & Pick<Chapter, 'id' | 'name'>
      ) }
    ), similarItems: Array<{ __typename?: 'Audio' } | { __typename?: 'Video' } | (
      { __typename?: 'Transcript' }
      & Pick<Transcript, 'id' | 'name' | 'text'>
      & { lecture: (
        { __typename?: 'Lecture' }
        & Pick<Lecture, 'id' | 'name'>
        & { chapter: (
          { __typename?: 'Chapter' }
          & Pick<Chapter, 'id' | 'name'>
        ) }
      ) }
    ) | { __typename?: 'Word' }> }
  ) | { __typename?: 'Word' } }
);

export type LibraryWordItemQueryVariables = {
  where: LibraryItemWhereInput
};


export type LibraryWordItemQuery = (
  { __typename?: 'Query' }
  & { libraryItem: { __typename?: 'Audio' } | { __typename?: 'Video' } | { __typename?: 'Transcript' } | (
    { __typename?: 'Word' }
    & WordFragment
  ) }
);

export type FinalTestQueryVariables = {};


export type FinalTestQuery = (
  { __typename?: 'Query' }
  & { finalTest: Maybe<(
    { __typename?: 'Test' }
    & Pick<Test, 'id' | 'maxPoints' | 'acquiredPoints' | 'isFinalTestCompleted'>
  )> }
);

export type BadgesQueryVariables = {};


export type BadgesQuery = (
  { __typename?: 'Query' }
  & { badges: Array<(
    { __typename?: 'BadgeWithData' }
    & BadgesFragment
  )> }
);

export type UnacceptedBadgesQueryVariables = {};


export type UnacceptedBadgesQuery = (
  { __typename?: 'Query' }
  & { unacceptedBadges: Array<(
    { __typename?: 'BadgeBoard' }
    & Pick<BadgeBoard, 'id' | 'isAccepted'>
    & { badge: (
      { __typename?: 'BadgeBoardBadge' }
      & BadgeFragment
    ) }
  )> }
);

export type UserActivityQueryVariables = {
  page?: Maybe<Scalars['Int']>,
  itemsPerPage?: Maybe<Scalars['Int']>,
  filter?: Maybe<LogWhereInput>
};


export type UserActivityQuery = (
  { __typename?: 'Query' }
  & { userActivity: (
    { __typename?: 'BatchUserActivity' }
    & { data: Array<(
      { __typename?: 'Log' }
      & Pick<Log, 'id' | 'entity' | 'activity' | 'createdAt'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'role' | 'username'>
      ), data: Maybe<(
        { __typename?: 'Answer' }
        & Pick<Answer, 'text'>
      ) | (
        { __typename?: 'Chapter' }
        & Pick<Chapter, 'name'>
      ) | (
        { __typename?: 'Lecture' }
        & Pick<Lecture, 'name'>
      ) | (
        { __typename?: 'Question' }
        & Pick<Question, 'text'>
      ) | { __typename?: 'ChapterDraft' } | { __typename?: 'LectureDraft' } | (
        { __typename?: 'Test' }
        & { lecture: Maybe<(
          { __typename?: 'Lecture' }
          & Pick<Lecture, 'name'>
        )> }
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'username' | 'role' | 'id'>
        & { email: User['email'] }
      ) | (
        { __typename?: 'Word' }
        & Pick<Word, 'word'>
      ) | (
        { __typename?: 'Document' }
        & Pick<Document, 'name'>
      ) | (
        { __typename?: 'CustomNotification' }
        & Pick<CustomNotification, 'title'>
      )> }
    )>, meta: (
      { __typename?: 'BatchMeta' }
      & MetaFragment
    ) }
  ) }
);

export type StudentActivityQueryVariables = {
  filter?: Maybe<StudentActivityFilter>,
  page?: Maybe<Scalars['Int']>,
  itemsPerPage?: Maybe<Scalars['Int']>
};


export type StudentActivityQuery = (
  { __typename?: 'Query' }
  & { studentActivity: (
    { __typename?: 'BatchStudentActivity' }
    & { data: Array<(
      { __typename?: 'StudentActivity' }
      & Pick<StudentActivity, 'type' | 'points' | 'createdAt' | 'testName' | 'username'>
      & { answers: Maybe<(
        { __typename?: 'StudentActivityAnswers' }
        & Pick<StudentActivityAnswers, 'wrong' | 'correct'>
      )> }
    )>, meta: (
      { __typename?: 'BatchMeta' }
      & MetaFragment
    ) }
  ) }
);

export type NotificationsQueryVariables = {};


export type NotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications: (
    { __typename?: 'BatchNotifications' }
    & { data: Array<(
      { __typename?: 'NotificationRecord' }
      & Pick<NotificationRecord, 'id' | 'name' | 'type' | 'body' | 'title' | 'createdAt'>
      & { icon: Maybe<(
        { __typename?: 'Icon' }
        & Pick<Icon, 'png' | 'svg'>
      )> }
    )>, meta: (
      { __typename?: 'BatchMeta' }
      & MetaFragment
    ) }
  ) }
);

export type CustomNotificationsQueryVariables = {
  page?: Maybe<Scalars['Int']>,
  itemsPerPage?: Maybe<Scalars['Int']>,
  filter?: Maybe<CustomNotificationFilter>,
  orderBy?: Maybe<BatchCustomNotificationsOrderByInput>
};


export type CustomNotificationsQuery = (
  { __typename?: 'Query' }
  & { customNotifications: (
    { __typename?: 'BatchCustomNotifications' }
    & { data: Array<(
      { __typename?: 'CustomNotification' }
      & Pick<CustomNotification, 'id' | 'title' | 'runAt' | 'body' | 'repeat' | 'status'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'role' | 'email' | 'username'>
      ) }
    )>, meta: (
      { __typename?: 'BatchMeta' }
      & MetaFragment
    ) }
  ) }
);

export type CustomNotificationQueryVariables = {
  where: CustomNotificationWhereUniqueInput
};


export type CustomNotificationQuery = (
  { __typename?: 'Query' }
  & { customNotification: Maybe<(
    { __typename?: 'CustomNotification' }
    & Pick<CustomNotification, 'id' | 'title' | 'runAt' | 'body' | 'repeat' | 'status'>
    & { author: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'role'>
    ) }
  )> }
);

export type LeaderboardQueryVariables = {
  page?: Maybe<Scalars['Int']>,
  itemsPerPage?: Maybe<Scalars['Int']>,
  filter?: Maybe<Array<LeaderboardFilterEnum>>
};


export type LeaderboardQuery = (
  { __typename?: 'Query' }
  & { leaderboard: (
    { __typename?: 'BatchLeaderboard' }
    & { data: Array<(
      { __typename?: 'Leaderboard' }
      & Pick<Leaderboard, 'profilePicture' | 'username' | 'points' | 'rank'>
    )>, meta: (
      { __typename?: 'BatchMeta' }
      & MetaFragment
    ) }
  ) }
);

export type DistrictsQueryVariables = {};


export type DistrictsQuery = (
  { __typename?: 'Query' }
  & { districts: Array<(
    { __typename?: 'District' }
    & Pick<District, 'id' | 'name'>
  )> }
);

export const VideoFragmentDoc = gql`
    fragment Video on Video {
  id
  name
  filename
  src
  length
  preview
  lecture {
    id
    name
    chapter {
      id
      name
    }
  }
}
    `;
export const AudioFragmentDoc = gql`
    fragment Audio on Audio {
  id
  src
  name
  length
  filename
}
    `;
export const LectureForChapterFragmentDoc = gql`
    fragment LectureForChapter on Lecture {
  id
  name
  status
  maxPoints
  acquiredPoints
  test {
    id
    isCompleted
    isChapterSummary
  }
  library {
    ...Video
    ...Audio
  }
}
    ${VideoFragmentDoc}
${AudioFragmentDoc}`;
export const AnswerFragmentDoc = gql`
    fragment Answer on Answer {
  id
  text
  points
  isCorrect
}
    `;
export const QuestionFragmentDoc = gql`
    fragment Question on Question {
  id
  text
  description
  answers {
    ...Answer
  }
}
    ${AnswerFragmentDoc}`;
export const TestFragmentDoc = gql`
    fragment Test on Test {
  id
  maxPoints
  stepsTotal
  isFinalTest
  isCompleted
  isChapterSummary
  chapter {
    id
    name
    description
  }
  lecture {
    id
    name
    description
    chapter {
      id
      summaryTest {
        id
      }
    }
  }
  questions {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;
export const SessionFragmentDoc = gql`
    fragment Session on TestSession {
  id
  testStatus
  pointsTotal
  wrongAnswersCount
  correctAnswersCount
  nextLecture {
    id
    chapter {
      id
    }
  }
  test {
    ...Test
  }
  testSteps {
    id
    step
    points
    isCorrect
    description
    answer {
      id
      text
    }
    correctAnswer {
      id
      text
    }
    question {
      ...Question
    }
  }
}
    ${TestFragmentDoc}
${QuestionFragmentDoc}`;
export const StepFragmentDoc = gql`
    fragment Step on TestStep {
  id
  step
  points
  isCorrect
  description
  answer {
    id
    text
  }
  correctAnswer {
    id
    text
  }
  session {
    ...Session
  }
  question {
    ...Question
  }
}
    ${SessionFragmentDoc}
${QuestionFragmentDoc}`;
export const WordFragmentDoc = gql`
    fragment Word on Word {
  id
  word
  description
}
    `;
export const TranscriptFragmentDoc = gql`
    fragment Transcript on Transcript {
  id
  name
  text
  lecture {
    id
    name
  }
}
    `;
export const LectureFragmentDoc = gql`
    fragment Lecture on Lecture {
  id
  name
  index
  status
  summary
  maxPoints
  transcript
  description
  acquiredPoints
  test {
    id
  }
  library {
    ...Word
    ...Video
    ...Audio
    ...Transcript
  }
  chapter {
    id
    name
    lectures {
      id
      name
      index
      status
      maxPoints
      acquiredPoints
      test {
        id
        isChapterSummary
      }
      library {
        ...Word
        ...Video
        ...Audio
        ...Transcript
      }
    }
  }
}
    ${WordFragmentDoc}
${VideoFragmentDoc}
${AudioFragmentDoc}
${TranscriptFragmentDoc}`;
export const FileFragmentDoc = gql`
    fragment File on LectureFile {
  id
  src
  type
  name
  length
  preview
  lecture {
    id
    name
    chapter {
      id
      name
    }
  }
}
    `;
export const StudentFragmentDoc = gql`
    fragment Student on Student {
  id
  progress {
    level {
      name
      progress
      nextLevelName
    }
    lectures {
      total
      reached
    }
    points {
      total
      reached
    }
    countryRank
    regionRank
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  role
  email
  username
  isBlocked
  isDeleted
  lastLogin
  signupType
  isTemporary
  lifeStage {
    value
    name
  }
  investmentExperience {
    value
    name
  }
  isEmailVerified
  district {
    id
    name
  }
}
    `;
export const StudentUserFragmentDoc = gql`
    fragment StudentUser on User {
  id
  email
  username
  isBlocked
  isTemporary
  signupType
  isEmailVerified
  student {
    id
  }
}
    `;
export const UserWithStatsFragmentDoc = gql`
    fragment UserWithStats on User {
  id
  email
  username
  isBlocked
  createdAt
  isDeleted
  isTemporary
  lifeStage {
    value
    name
  }
  investmentExperience {
    value
    name
  }
  student {
    id
    badgeBoard {
      id
      badge {
        id
        name
        description
        icon {
          svg
          png
        }
      }
    }
    progress {
      regionRank
      countryRank
      points {
        reached
        total
      }
    }
  }
}
    `;
export const AdminUserFragmentDoc = gql`
    fragment AdminUser on User {
  id
  role
  email
  username
  isBlocked
  isEmailVerified
}
    `;
export const MetaFragmentDoc = gql`
    fragment Meta on BatchMeta {
  itemsPerPage
  items
  page
  pages
}
    `;
export const ChapterFragmentDoc = gql`
    fragment Chapter on Chapter {
  id
  name
  index
  status
  progress
  maxPoints
  description
  totalLength
  isLastActive
  acquiredPoints
  summaryTest {
    id
    maxPoints
    isCompleted
    acquiredPoints
  }
  icon {
    svg
    png
  }
}
    `;
export const BadgeFragmentDoc = gql`
    fragment Badge on BadgeBoardBadge {
  id
  name
  description
  icon {
    svg
    png
  }
}
    `;
export const BadgesFragmentDoc = gql`
    fragment Badges on BadgeWithData {
  badge {
    id
    name
    description
    icon {
      svg
      png
    }
  }
}
    `;
export const StudentWithBadgesFragmentDoc = gql`
    fragment StudentWithBadges on Student {
  id
  user {
    id
    email
    username
  }
  progress {
    points {
      reached
    }
  }
  badgeBoard {
    badge {
      name
      icon {
        svg
        png
      }
    }
  }
}
    `;
export const UpdateSelfUserDocument = gql`
    mutation UpdateSelfUser($data: UpdateSelfUserInput!) {
  updateSelfUser(data: $data) {
    id
    email
    username
    lifeStage {
      value
      name
    }
    investmentExperience {
      value
      name
    }
    district {
      id
      name
    }
    student {
      id
    }
  }
}
    `;
export type UpdateSelfUserMutationFn = ApolloReactCommon.MutationFunction<UpdateSelfUserMutation, UpdateSelfUserMutationVariables>;
export type UpdateSelfUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateSelfUserMutation, UpdateSelfUserMutationVariables>, 'mutation'>;

    export const UpdateSelfUserComponent = (props: UpdateSelfUserComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateSelfUserMutation, UpdateSelfUserMutationVariables> mutation={UpdateSelfUserDocument} {...props} />
    );
    

/**
 * __useUpdateSelfUserMutation__
 *
 * To run a mutation, you first call `useUpdateSelfUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelfUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelfUserMutation, { data, loading, error }] = useUpdateSelfUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSelfUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSelfUserMutation, UpdateSelfUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSelfUserMutation, UpdateSelfUserMutationVariables>(UpdateSelfUserDocument, baseOptions);
      }
export type UpdateSelfUserMutationHookResult = ReturnType<typeof useUpdateSelfUserMutation>;
export type UpdateSelfUserMutationResult = ApolloReactCommon.MutationResult<UpdateSelfUserMutation>;
export type UpdateSelfUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSelfUserMutation, UpdateSelfUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($where: UserWhereId!, $data: UserUpdateInput!) {
  updateUser(where: $where, data: $data) {
    email
    username
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export type UpdateUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserMutation, UpdateUserMutationVariables>, 'mutation'>;

    export const UpdateUserComponent = (props: UpdateUserComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserMutation, UpdateUserMutationVariables> mutation={UpdateUserDocument} {...props} />
    );
    

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const CreateLectureFileDocument = gql`
    mutation CreateLectureFile($data: CreateLectureFileInput!) {
  createLectureFile(data: $data) {
    id
    filename
    type
  }
}
    `;
export type CreateLectureFileMutationFn = ApolloReactCommon.MutationFunction<CreateLectureFileMutation, CreateLectureFileMutationVariables>;
export type CreateLectureFileComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateLectureFileMutation, CreateLectureFileMutationVariables>, 'mutation'>;

    export const CreateLectureFileComponent = (props: CreateLectureFileComponentProps) => (
      <ApolloReactComponents.Mutation<CreateLectureFileMutation, CreateLectureFileMutationVariables> mutation={CreateLectureFileDocument} {...props} />
    );
    

/**
 * __useCreateLectureFileMutation__
 *
 * To run a mutation, you first call `useCreateLectureFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLectureFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLectureFileMutation, { data, loading, error }] = useCreateLectureFileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateLectureFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLectureFileMutation, CreateLectureFileMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateLectureFileMutation, CreateLectureFileMutationVariables>(CreateLectureFileDocument, baseOptions);
      }
export type CreateLectureFileMutationHookResult = ReturnType<typeof useCreateLectureFileMutation>;
export type CreateLectureFileMutationResult = ApolloReactCommon.MutationResult<CreateLectureFileMutation>;
export type CreateLectureFileMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateLectureFileMutation, CreateLectureFileMutationVariables>;
export const CreateResumableUploadDocument = gql`
    mutation CreateResumableUpload($data: CreateResumableUploadInput) {
  createResumableUpload(data: $data)
}
    `;
export type CreateResumableUploadMutationFn = ApolloReactCommon.MutationFunction<CreateResumableUploadMutation, CreateResumableUploadMutationVariables>;
export type CreateResumableUploadComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateResumableUploadMutation, CreateResumableUploadMutationVariables>, 'mutation'>;

    export const CreateResumableUploadComponent = (props: CreateResumableUploadComponentProps) => (
      <ApolloReactComponents.Mutation<CreateResumableUploadMutation, CreateResumableUploadMutationVariables> mutation={CreateResumableUploadDocument} {...props} />
    );
    

/**
 * __useCreateResumableUploadMutation__
 *
 * To run a mutation, you first call `useCreateResumableUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResumableUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResumableUploadMutation, { data, loading, error }] = useCreateResumableUploadMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateResumableUploadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateResumableUploadMutation, CreateResumableUploadMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateResumableUploadMutation, CreateResumableUploadMutationVariables>(CreateResumableUploadDocument, baseOptions);
      }
export type CreateResumableUploadMutationHookResult = ReturnType<typeof useCreateResumableUploadMutation>;
export type CreateResumableUploadMutationResult = ApolloReactCommon.MutationResult<CreateResumableUploadMutation>;
export type CreateResumableUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateResumableUploadMutation, CreateResumableUploadMutationVariables>;
export const GetSignedUrlDocument = gql`
    mutation GetSignedUrl($gcsId: String!) {
  getSignedUrl(gcsId: $gcsId)
}
    `;
export type GetSignedUrlMutationFn = ApolloReactCommon.MutationFunction<GetSignedUrlMutation, GetSignedUrlMutationVariables>;
export type GetSignedUrlComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GetSignedUrlMutation, GetSignedUrlMutationVariables>, 'mutation'>;

    export const GetSignedUrlComponent = (props: GetSignedUrlComponentProps) => (
      <ApolloReactComponents.Mutation<GetSignedUrlMutation, GetSignedUrlMutationVariables> mutation={GetSignedUrlDocument} {...props} />
    );
    

/**
 * __useGetSignedUrlMutation__
 *
 * To run a mutation, you first call `useGetSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSignedUrlMutation, { data, loading, error }] = useGetSignedUrlMutation({
 *   variables: {
 *      gcsId: // value for 'gcsId'
 *   },
 * });
 */
export function useGetSignedUrlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GetSignedUrlMutation, GetSignedUrlMutationVariables>) {
        return ApolloReactHooks.useMutation<GetSignedUrlMutation, GetSignedUrlMutationVariables>(GetSignedUrlDocument, baseOptions);
      }
export type GetSignedUrlMutationHookResult = ReturnType<typeof useGetSignedUrlMutation>;
export type GetSignedUrlMutationResult = ApolloReactCommon.MutationResult<GetSignedUrlMutation>;
export type GetSignedUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<GetSignedUrlMutation, GetSignedUrlMutationVariables>;
export const UpdateTemporaryUserDocument = gql`
    mutation UpdateTemporaryUser($data: UpdateTemporaryUserInput!) {
  updateTemporaryUser(data: $data) {
    accessToken
    refreshToken
    username
    name
    email
  }
}
    `;
export type UpdateTemporaryUserMutationFn = ApolloReactCommon.MutationFunction<UpdateTemporaryUserMutation, UpdateTemporaryUserMutationVariables>;
export type UpdateTemporaryUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateTemporaryUserMutation, UpdateTemporaryUserMutationVariables>, 'mutation'>;

    export const UpdateTemporaryUserComponent = (props: UpdateTemporaryUserComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateTemporaryUserMutation, UpdateTemporaryUserMutationVariables> mutation={UpdateTemporaryUserDocument} {...props} />
    );
    

/**
 * __useUpdateTemporaryUserMutation__
 *
 * To run a mutation, you first call `useUpdateTemporaryUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemporaryUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemporaryUserMutation, { data, loading, error }] = useUpdateTemporaryUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTemporaryUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTemporaryUserMutation, UpdateTemporaryUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTemporaryUserMutation, UpdateTemporaryUserMutationVariables>(UpdateTemporaryUserDocument, baseOptions);
      }
export type UpdateTemporaryUserMutationHookResult = ReturnType<typeof useUpdateTemporaryUserMutation>;
export type UpdateTemporaryUserMutationResult = ApolloReactCommon.MutationResult<UpdateTemporaryUserMutation>;
export type UpdateTemporaryUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTemporaryUserMutation, UpdateTemporaryUserMutationVariables>;
export const CreateLectureDocument = gql`
    mutation CreateLecture($data: CreateLectureInput!) {
  createLecture(data: $data) {
    id
  }
}
    `;
export type CreateLectureMutationFn = ApolloReactCommon.MutationFunction<CreateLectureMutation, CreateLectureMutationVariables>;
export type CreateLectureComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateLectureMutation, CreateLectureMutationVariables>, 'mutation'>;

    export const CreateLectureComponent = (props: CreateLectureComponentProps) => (
      <ApolloReactComponents.Mutation<CreateLectureMutation, CreateLectureMutationVariables> mutation={CreateLectureDocument} {...props} />
    );
    

/**
 * __useCreateLectureMutation__
 *
 * To run a mutation, you first call `useCreateLectureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLectureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLectureMutation, { data, loading, error }] = useCreateLectureMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateLectureMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLectureMutation, CreateLectureMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateLectureMutation, CreateLectureMutationVariables>(CreateLectureDocument, baseOptions);
      }
export type CreateLectureMutationHookResult = ReturnType<typeof useCreateLectureMutation>;
export type CreateLectureMutationResult = ApolloReactCommon.MutationResult<CreateLectureMutation>;
export type CreateLectureMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateLectureMutation, CreateLectureMutationVariables>;
export const UpdateLectureDocument = gql`
    mutation UpdateLecture($where: LectureUniqueRequiredInput!, $data: UpdateLectureInput!) {
  updateLecture(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateLectureMutationFn = ApolloReactCommon.MutationFunction<UpdateLectureMutation, UpdateLectureMutationVariables>;
export type UpdateLectureComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateLectureMutation, UpdateLectureMutationVariables>, 'mutation'>;

    export const UpdateLectureComponent = (props: UpdateLectureComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateLectureMutation, UpdateLectureMutationVariables> mutation={UpdateLectureDocument} {...props} />
    );
    

/**
 * __useUpdateLectureMutation__
 *
 * To run a mutation, you first call `useUpdateLectureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLectureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLectureMutation, { data, loading, error }] = useUpdateLectureMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLectureMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLectureMutation, UpdateLectureMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLectureMutation, UpdateLectureMutationVariables>(UpdateLectureDocument, baseOptions);
      }
export type UpdateLectureMutationHookResult = ReturnType<typeof useUpdateLectureMutation>;
export type UpdateLectureMutationResult = ApolloReactCommon.MutationResult<UpdateLectureMutation>;
export type UpdateLectureMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLectureMutation, UpdateLectureMutationVariables>;
export const LoginWithFacebookDocument = gql`
    mutation LoginWithFacebook($data: FacebookLoginInput!) {
  loginWithFacebook(data: $data) {
    accessToken
    isReactivated
    refreshToken
    lastLogin
    username
    email
    role
  }
}
    `;
export type LoginWithFacebookMutationFn = ApolloReactCommon.MutationFunction<LoginWithFacebookMutation, LoginWithFacebookMutationVariables>;
export type LoginWithFacebookComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoginWithFacebookMutation, LoginWithFacebookMutationVariables>, 'mutation'>;

    export const LoginWithFacebookComponent = (props: LoginWithFacebookComponentProps) => (
      <ApolloReactComponents.Mutation<LoginWithFacebookMutation, LoginWithFacebookMutationVariables> mutation={LoginWithFacebookDocument} {...props} />
    );
    

/**
 * __useLoginWithFacebookMutation__
 *
 * To run a mutation, you first call `useLoginWithFacebookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithFacebookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithFacebookMutation, { data, loading, error }] = useLoginWithFacebookMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginWithFacebookMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginWithFacebookMutation, LoginWithFacebookMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginWithFacebookMutation, LoginWithFacebookMutationVariables>(LoginWithFacebookDocument, baseOptions);
      }
export type LoginWithFacebookMutationHookResult = ReturnType<typeof useLoginWithFacebookMutation>;
export type LoginWithFacebookMutationResult = ApolloReactCommon.MutationResult<LoginWithFacebookMutation>;
export type LoginWithFacebookMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginWithFacebookMutation, LoginWithFacebookMutationVariables>;
export const LoginWithAppleDocument = gql`
    mutation LoginWithApple($data: AppleLoginInput!) {
  loginWithApple(data: $data) {
    accessToken
    isReactivated
    refreshToken
    lastLogin
    username
    email
    role
  }
}
    `;
export type LoginWithAppleMutationFn = ApolloReactCommon.MutationFunction<LoginWithAppleMutation, LoginWithAppleMutationVariables>;
export type LoginWithAppleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoginWithAppleMutation, LoginWithAppleMutationVariables>, 'mutation'>;

    export const LoginWithAppleComponent = (props: LoginWithAppleComponentProps) => (
      <ApolloReactComponents.Mutation<LoginWithAppleMutation, LoginWithAppleMutationVariables> mutation={LoginWithAppleDocument} {...props} />
    );
    

/**
 * __useLoginWithAppleMutation__
 *
 * To run a mutation, you first call `useLoginWithAppleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithAppleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithAppleMutation, { data, loading, error }] = useLoginWithAppleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginWithAppleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginWithAppleMutation, LoginWithAppleMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginWithAppleMutation, LoginWithAppleMutationVariables>(LoginWithAppleDocument, baseOptions);
      }
export type LoginWithAppleMutationHookResult = ReturnType<typeof useLoginWithAppleMutation>;
export type LoginWithAppleMutationResult = ApolloReactCommon.MutationResult<LoginWithAppleMutation>;
export type LoginWithAppleMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginWithAppleMutation, LoginWithAppleMutationVariables>;
export const LoginWithGoogleDocument = gql`
    mutation LoginWithGoogle($data: GoogleLoginInput!) {
  loginWithGoogle(data: $data) {
    accessToken
    isReactivated
    refreshToken
    lastLogin
    username
    email
    role
  }
}
    `;
export type LoginWithGoogleMutationFn = ApolloReactCommon.MutationFunction<LoginWithGoogleMutation, LoginWithGoogleMutationVariables>;
export type LoginWithGoogleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoginWithGoogleMutation, LoginWithGoogleMutationVariables>, 'mutation'>;

    export const LoginWithGoogleComponent = (props: LoginWithGoogleComponentProps) => (
      <ApolloReactComponents.Mutation<LoginWithGoogleMutation, LoginWithGoogleMutationVariables> mutation={LoginWithGoogleDocument} {...props} />
    );
    

/**
 * __useLoginWithGoogleMutation__
 *
 * To run a mutation, you first call `useLoginWithGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithGoogleMutation, { data, loading, error }] = useLoginWithGoogleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginWithGoogleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginWithGoogleMutation, LoginWithGoogleMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginWithGoogleMutation, LoginWithGoogleMutationVariables>(LoginWithGoogleDocument, baseOptions);
      }
export type LoginWithGoogleMutationHookResult = ReturnType<typeof useLoginWithGoogleMutation>;
export type LoginWithGoogleMutationResult = ApolloReactCommon.MutationResult<LoginWithGoogleMutation>;
export type LoginWithGoogleMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginWithGoogleMutation, LoginWithGoogleMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($data: ResetPasswordInput!) {
  resetPassword(data: $data) {
    accessToken
    refreshToken
    username
    email
  }
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;
export type ResetPasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResetPasswordMutation, ResetPasswordMutationVariables>, 'mutation'>;

    export const ResetPasswordComponent = (props: ResetPasswordComponentProps) => (
      <ApolloReactComponents.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> mutation={ResetPasswordDocument} {...props} />
    );
    

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($data: ChangePasswordInput!) {
  changePassword(data: $data)
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;
export type ChangePasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangePasswordMutation, ChangePasswordMutationVariables>, 'mutation'>;

    export const ChangePasswordComponent = (props: ChangePasswordComponentProps) => (
      <ApolloReactComponents.Mutation<ChangePasswordMutation, ChangePasswordMutationVariables> mutation={ChangePasswordDocument} {...props} />
    );
    

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const RegisterStudentDocument = gql`
    mutation RegisterStudent($data: RegisterStudentInput!) {
  registerStudent(data: $data) {
    accessToken
    refreshToken
  }
}
    `;
export type RegisterStudentMutationFn = ApolloReactCommon.MutationFunction<RegisterStudentMutation, RegisterStudentMutationVariables>;
export type RegisterStudentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RegisterStudentMutation, RegisterStudentMutationVariables>, 'mutation'>;

    export const RegisterStudentComponent = (props: RegisterStudentComponentProps) => (
      <ApolloReactComponents.Mutation<RegisterStudentMutation, RegisterStudentMutationVariables> mutation={RegisterStudentDocument} {...props} />
    );
    

/**
 * __useRegisterStudentMutation__
 *
 * To run a mutation, you first call `useRegisterStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerStudentMutation, { data, loading, error }] = useRegisterStudentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterStudentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterStudentMutation, RegisterStudentMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterStudentMutation, RegisterStudentMutationVariables>(RegisterStudentDocument, baseOptions);
      }
export type RegisterStudentMutationHookResult = ReturnType<typeof useRegisterStudentMutation>;
export type RegisterStudentMutationResult = ApolloReactCommon.MutationResult<RegisterStudentMutation>;
export type RegisterStudentMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterStudentMutation, RegisterStudentMutationVariables>;
export const RegisterTemporaryStudentDocument = gql`
    mutation RegisterTemporaryStudent {
  registerTemporaryStudent {
    accessToken
    refreshToken
  }
}
    `;
export type RegisterTemporaryStudentMutationFn = ApolloReactCommon.MutationFunction<RegisterTemporaryStudentMutation, RegisterTemporaryStudentMutationVariables>;
export type RegisterTemporaryStudentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RegisterTemporaryStudentMutation, RegisterTemporaryStudentMutationVariables>, 'mutation'>;

    export const RegisterTemporaryStudentComponent = (props: RegisterTemporaryStudentComponentProps) => (
      <ApolloReactComponents.Mutation<RegisterTemporaryStudentMutation, RegisterTemporaryStudentMutationVariables> mutation={RegisterTemporaryStudentDocument} {...props} />
    );
    

/**
 * __useRegisterTemporaryStudentMutation__
 *
 * To run a mutation, you first call `useRegisterTemporaryStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterTemporaryStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerTemporaryStudentMutation, { data, loading, error }] = useRegisterTemporaryStudentMutation({
 *   variables: {
 *   },
 * });
 */
export function useRegisterTemporaryStudentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterTemporaryStudentMutation, RegisterTemporaryStudentMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterTemporaryStudentMutation, RegisterTemporaryStudentMutationVariables>(RegisterTemporaryStudentDocument, baseOptions);
      }
export type RegisterTemporaryStudentMutationHookResult = ReturnType<typeof useRegisterTemporaryStudentMutation>;
export type RegisterTemporaryStudentMutationResult = ApolloReactCommon.MutationResult<RegisterTemporaryStudentMutation>;
export type RegisterTemporaryStudentMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterTemporaryStudentMutation, RegisterTemporaryStudentMutationVariables>;
export const CreateChapterDocument = gql`
    mutation CreateChapter($data: CreateChapterInput!) {
  createChapter(data: $data) {
    ...Chapter
  }
}
    ${ChapterFragmentDoc}`;
export type CreateChapterMutationFn = ApolloReactCommon.MutationFunction<CreateChapterMutation, CreateChapterMutationVariables>;
export type CreateChapterComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateChapterMutation, CreateChapterMutationVariables>, 'mutation'>;

    export const CreateChapterComponent = (props: CreateChapterComponentProps) => (
      <ApolloReactComponents.Mutation<CreateChapterMutation, CreateChapterMutationVariables> mutation={CreateChapterDocument} {...props} />
    );
    

/**
 * __useCreateChapterMutation__
 *
 * To run a mutation, you first call `useCreateChapterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChapterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChapterMutation, { data, loading, error }] = useCreateChapterMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateChapterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateChapterMutation, CreateChapterMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateChapterMutation, CreateChapterMutationVariables>(CreateChapterDocument, baseOptions);
      }
export type CreateChapterMutationHookResult = ReturnType<typeof useCreateChapterMutation>;
export type CreateChapterMutationResult = ApolloReactCommon.MutationResult<CreateChapterMutation>;
export type CreateChapterMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateChapterMutation, CreateChapterMutationVariables>;
export const UpdateChapterDocument = gql`
    mutation UpdateChapter($data: UpdateChapterInput!, $where: ChapterUniqueRequiredInput!) {
  updateChapter(data: $data, where: $where) {
    ...Chapter
  }
}
    ${ChapterFragmentDoc}`;
export type UpdateChapterMutationFn = ApolloReactCommon.MutationFunction<UpdateChapterMutation, UpdateChapterMutationVariables>;
export type UpdateChapterComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateChapterMutation, UpdateChapterMutationVariables>, 'mutation'>;

    export const UpdateChapterComponent = (props: UpdateChapterComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateChapterMutation, UpdateChapterMutationVariables> mutation={UpdateChapterDocument} {...props} />
    );
    

/**
 * __useUpdateChapterMutation__
 *
 * To run a mutation, you first call `useUpdateChapterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChapterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChapterMutation, { data, loading, error }] = useUpdateChapterMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateChapterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateChapterMutation, UpdateChapterMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateChapterMutation, UpdateChapterMutationVariables>(UpdateChapterDocument, baseOptions);
      }
export type UpdateChapterMutationHookResult = ReturnType<typeof useUpdateChapterMutation>;
export type UpdateChapterMutationResult = ApolloReactCommon.MutationResult<UpdateChapterMutation>;
export type UpdateChapterMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateChapterMutation, UpdateChapterMutationVariables>;
export const ApproveChapterDocument = gql`
    mutation ApproveChapter($where: ChapterUniqueRequiredInput!) {
  approveChapter(where: $where) {
    ...Chapter
  }
}
    ${ChapterFragmentDoc}`;
export type ApproveChapterMutationFn = ApolloReactCommon.MutationFunction<ApproveChapterMutation, ApproveChapterMutationVariables>;
export type ApproveChapterComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ApproveChapterMutation, ApproveChapterMutationVariables>, 'mutation'>;

    export const ApproveChapterComponent = (props: ApproveChapterComponentProps) => (
      <ApolloReactComponents.Mutation<ApproveChapterMutation, ApproveChapterMutationVariables> mutation={ApproveChapterDocument} {...props} />
    );
    

/**
 * __useApproveChapterMutation__
 *
 * To run a mutation, you first call `useApproveChapterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveChapterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveChapterMutation, { data, loading, error }] = useApproveChapterMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useApproveChapterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveChapterMutation, ApproveChapterMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveChapterMutation, ApproveChapterMutationVariables>(ApproveChapterDocument, baseOptions);
      }
export type ApproveChapterMutationHookResult = ReturnType<typeof useApproveChapterMutation>;
export type ApproveChapterMutationResult = ApolloReactCommon.MutationResult<ApproveChapterMutation>;
export type ApproveChapterMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveChapterMutation, ApproveChapterMutationVariables>;
export const RejectChapterDocument = gql`
    mutation RejectChapter($data: RejectChapterInput!, $where: ChapterUniqueRequiredInput!) {
  rejectChapter(data: $data, where: $where) {
    ...Chapter
  }
}
    ${ChapterFragmentDoc}`;
export type RejectChapterMutationFn = ApolloReactCommon.MutationFunction<RejectChapterMutation, RejectChapterMutationVariables>;
export type RejectChapterComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RejectChapterMutation, RejectChapterMutationVariables>, 'mutation'>;

    export const RejectChapterComponent = (props: RejectChapterComponentProps) => (
      <ApolloReactComponents.Mutation<RejectChapterMutation, RejectChapterMutationVariables> mutation={RejectChapterDocument} {...props} />
    );
    

/**
 * __useRejectChapterMutation__
 *
 * To run a mutation, you first call `useRejectChapterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectChapterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectChapterMutation, { data, loading, error }] = useRejectChapterMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRejectChapterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectChapterMutation, RejectChapterMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectChapterMutation, RejectChapterMutationVariables>(RejectChapterDocument, baseOptions);
      }
export type RejectChapterMutationHookResult = ReturnType<typeof useRejectChapterMutation>;
export type RejectChapterMutationResult = ApolloReactCommon.MutationResult<RejectChapterMutation>;
export type RejectChapterMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectChapterMutation, RejectChapterMutationVariables>;
export const DeleteChapterDocument = gql`
    mutation DeleteChapter($where: ChapterUniqueRequiredInput!) {
  deleteChapter(where: $where) {
    ...Chapter
  }
}
    ${ChapterFragmentDoc}`;
export type DeleteChapterMutationFn = ApolloReactCommon.MutationFunction<DeleteChapterMutation, DeleteChapterMutationVariables>;
export type DeleteChapterComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteChapterMutation, DeleteChapterMutationVariables>, 'mutation'>;

    export const DeleteChapterComponent = (props: DeleteChapterComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteChapterMutation, DeleteChapterMutationVariables> mutation={DeleteChapterDocument} {...props} />
    );
    

/**
 * __useDeleteChapterMutation__
 *
 * To run a mutation, you first call `useDeleteChapterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChapterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChapterMutation, { data, loading, error }] = useDeleteChapterMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteChapterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteChapterMutation, DeleteChapterMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteChapterMutation, DeleteChapterMutationVariables>(DeleteChapterDocument, baseOptions);
      }
export type DeleteChapterMutationHookResult = ReturnType<typeof useDeleteChapterMutation>;
export type DeleteChapterMutationResult = ApolloReactCommon.MutationResult<DeleteChapterMutation>;
export type DeleteChapterMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteChapterMutation, DeleteChapterMutationVariables>;
export const ApproveLectureDocument = gql`
    mutation ApproveLecture($where: LectureUniqueRequiredInput!) {
  approveLecture(where: $where) {
    id
  }
}
    `;
export type ApproveLectureMutationFn = ApolloReactCommon.MutationFunction<ApproveLectureMutation, ApproveLectureMutationVariables>;
export type ApproveLectureComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ApproveLectureMutation, ApproveLectureMutationVariables>, 'mutation'>;

    export const ApproveLectureComponent = (props: ApproveLectureComponentProps) => (
      <ApolloReactComponents.Mutation<ApproveLectureMutation, ApproveLectureMutationVariables> mutation={ApproveLectureDocument} {...props} />
    );
    

/**
 * __useApproveLectureMutation__
 *
 * To run a mutation, you first call `useApproveLectureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveLectureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveLectureMutation, { data, loading, error }] = useApproveLectureMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useApproveLectureMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveLectureMutation, ApproveLectureMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveLectureMutation, ApproveLectureMutationVariables>(ApproveLectureDocument, baseOptions);
      }
export type ApproveLectureMutationHookResult = ReturnType<typeof useApproveLectureMutation>;
export type ApproveLectureMutationResult = ApolloReactCommon.MutationResult<ApproveLectureMutation>;
export type ApproveLectureMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveLectureMutation, ApproveLectureMutationVariables>;
export const RejectLectureDocument = gql`
    mutation RejectLecture($data: RejectLectureInput!, $where: LectureUniqueRequiredInput!) {
  rejectLecture(data: $data, where: $where) {
    id
  }
}
    `;
export type RejectLectureMutationFn = ApolloReactCommon.MutationFunction<RejectLectureMutation, RejectLectureMutationVariables>;
export type RejectLectureComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RejectLectureMutation, RejectLectureMutationVariables>, 'mutation'>;

    export const RejectLectureComponent = (props: RejectLectureComponentProps) => (
      <ApolloReactComponents.Mutation<RejectLectureMutation, RejectLectureMutationVariables> mutation={RejectLectureDocument} {...props} />
    );
    

/**
 * __useRejectLectureMutation__
 *
 * To run a mutation, you first call `useRejectLectureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectLectureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectLectureMutation, { data, loading, error }] = useRejectLectureMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRejectLectureMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectLectureMutation, RejectLectureMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectLectureMutation, RejectLectureMutationVariables>(RejectLectureDocument, baseOptions);
      }
export type RejectLectureMutationHookResult = ReturnType<typeof useRejectLectureMutation>;
export type RejectLectureMutationResult = ApolloReactCommon.MutationResult<RejectLectureMutation>;
export type RejectLectureMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectLectureMutation, RejectLectureMutationVariables>;
export const DeleteLectureDocument = gql`
    mutation DeleteLecture($where: LectureUniqueRequiredInput!) {
  deleteLecture(where: $where) {
    id
  }
}
    `;
export type DeleteLectureMutationFn = ApolloReactCommon.MutationFunction<DeleteLectureMutation, DeleteLectureMutationVariables>;
export type DeleteLectureComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteLectureMutation, DeleteLectureMutationVariables>, 'mutation'>;

    export const DeleteLectureComponent = (props: DeleteLectureComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteLectureMutation, DeleteLectureMutationVariables> mutation={DeleteLectureDocument} {...props} />
    );
    

/**
 * __useDeleteLectureMutation__
 *
 * To run a mutation, you first call `useDeleteLectureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLectureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLectureMutation, { data, loading, error }] = useDeleteLectureMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteLectureMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteLectureMutation, DeleteLectureMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteLectureMutation, DeleteLectureMutationVariables>(DeleteLectureDocument, baseOptions);
      }
export type DeleteLectureMutationHookResult = ReturnType<typeof useDeleteLectureMutation>;
export type DeleteLectureMutationResult = ApolloReactCommon.MutationResult<DeleteLectureMutation>;
export type DeleteLectureMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteLectureMutation, DeleteLectureMutationVariables>;
export const LoginDocument = gql`
    mutation Login($data: LoginInput!) {
  login(data: $data) {
    isReactivated
    refreshToken
    accessToken
    username
    role
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;
export type LoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoginMutation, LoginMutationVariables>, 'mutation'>;

    export const LoginComponent = (props: LoginComponentProps) => (
      <ApolloReactComponents.Mutation<LoginMutation, LoginMutationVariables> mutation={LoginDocument} {...props} />
    );
    

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;
export type LogoutComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LogoutMutation, LogoutMutationVariables>, 'mutation'>;

    export const LogoutComponent = (props: LogoutComponentProps) => (
      <ApolloReactComponents.Mutation<LogoutMutation, LogoutMutationVariables> mutation={LogoutDocument} {...props} />
    );
    

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const CreateTestSessionDocument = gql`
    mutation CreateTestSession($data: CreateTestSessionInput!) {
  createTestSession(data: $data) {
    ...Session
  }
}
    ${SessionFragmentDoc}`;
export type CreateTestSessionMutationFn = ApolloReactCommon.MutationFunction<CreateTestSessionMutation, CreateTestSessionMutationVariables>;
export type CreateTestSessionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateTestSessionMutation, CreateTestSessionMutationVariables>, 'mutation'>;

    export const CreateTestSessionComponent = (props: CreateTestSessionComponentProps) => (
      <ApolloReactComponents.Mutation<CreateTestSessionMutation, CreateTestSessionMutationVariables> mutation={CreateTestSessionDocument} {...props} />
    );
    

/**
 * __useCreateTestSessionMutation__
 *
 * To run a mutation, you first call `useCreateTestSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestSessionMutation, { data, loading, error }] = useCreateTestSessionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTestSessionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTestSessionMutation, CreateTestSessionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTestSessionMutation, CreateTestSessionMutationVariables>(CreateTestSessionDocument, baseOptions);
      }
export type CreateTestSessionMutationHookResult = ReturnType<typeof useCreateTestSessionMutation>;
export type CreateTestSessionMutationResult = ApolloReactCommon.MutationResult<CreateTestSessionMutation>;
export type CreateTestSessionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTestSessionMutation, CreateTestSessionMutationVariables>;
export const NextTestStepDocument = gql`
    mutation NextTestStep($data: NextTestStepInput!) {
  nextTestStep(data: $data) {
    ...Step
  }
}
    ${StepFragmentDoc}`;
export type NextTestStepMutationFn = ApolloReactCommon.MutationFunction<NextTestStepMutation, NextTestStepMutationVariables>;
export type NextTestStepComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<NextTestStepMutation, NextTestStepMutationVariables>, 'mutation'>;

    export const NextTestStepComponent = (props: NextTestStepComponentProps) => (
      <ApolloReactComponents.Mutation<NextTestStepMutation, NextTestStepMutationVariables> mutation={NextTestStepDocument} {...props} />
    );
    

/**
 * __useNextTestStepMutation__
 *
 * To run a mutation, you first call `useNextTestStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNextTestStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nextTestStepMutation, { data, loading, error }] = useNextTestStepMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNextTestStepMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NextTestStepMutation, NextTestStepMutationVariables>) {
        return ApolloReactHooks.useMutation<NextTestStepMutation, NextTestStepMutationVariables>(NextTestStepDocument, baseOptions);
      }
export type NextTestStepMutationHookResult = ReturnType<typeof useNextTestStepMutation>;
export type NextTestStepMutationResult = ApolloReactCommon.MutationResult<NextTestStepMutation>;
export type NextTestStepMutationOptions = ApolloReactCommon.BaseMutationOptions<NextTestStepMutation, NextTestStepMutationVariables>;
export const SubmitAnswerDocument = gql`
    mutation SubmitAnswer($data: SubmitAnswerInput!) {
  submitAnswer(data: $data) {
    ...Step
  }
}
    ${StepFragmentDoc}`;
export type SubmitAnswerMutationFn = ApolloReactCommon.MutationFunction<SubmitAnswerMutation, SubmitAnswerMutationVariables>;
export type SubmitAnswerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubmitAnswerMutation, SubmitAnswerMutationVariables>, 'mutation'>;

    export const SubmitAnswerComponent = (props: SubmitAnswerComponentProps) => (
      <ApolloReactComponents.Mutation<SubmitAnswerMutation, SubmitAnswerMutationVariables> mutation={SubmitAnswerDocument} {...props} />
    );
    

/**
 * __useSubmitAnswerMutation__
 *
 * To run a mutation, you first call `useSubmitAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAnswerMutation, { data, loading, error }] = useSubmitAnswerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubmitAnswerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitAnswerMutation, SubmitAnswerMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitAnswerMutation, SubmitAnswerMutationVariables>(SubmitAnswerDocument, baseOptions);
      }
export type SubmitAnswerMutationHookResult = ReturnType<typeof useSubmitAnswerMutation>;
export type SubmitAnswerMutationResult = ApolloReactCommon.MutationResult<SubmitAnswerMutation>;
export type SubmitAnswerMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitAnswerMutation, SubmitAnswerMutationVariables>;
export const ForgottenPasswordResetDocument = gql`
    mutation ForgottenPasswordReset($data: RequestPasswordResetEmailInput!) {
  forgottenPasswordReset(data: $data) {
    isEmailReset
    user {
      id
      email
      username
    }
  }
}
    `;
export type ForgottenPasswordResetMutationFn = ApolloReactCommon.MutationFunction<ForgottenPasswordResetMutation, ForgottenPasswordResetMutationVariables>;
export type ForgottenPasswordResetComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ForgottenPasswordResetMutation, ForgottenPasswordResetMutationVariables>, 'mutation'>;

    export const ForgottenPasswordResetComponent = (props: ForgottenPasswordResetComponentProps) => (
      <ApolloReactComponents.Mutation<ForgottenPasswordResetMutation, ForgottenPasswordResetMutationVariables> mutation={ForgottenPasswordResetDocument} {...props} />
    );
    

/**
 * __useForgottenPasswordResetMutation__
 *
 * To run a mutation, you first call `useForgottenPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgottenPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgottenPasswordResetMutation, { data, loading, error }] = useForgottenPasswordResetMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useForgottenPasswordResetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgottenPasswordResetMutation, ForgottenPasswordResetMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgottenPasswordResetMutation, ForgottenPasswordResetMutationVariables>(ForgottenPasswordResetDocument, baseOptions);
      }
export type ForgottenPasswordResetMutationHookResult = ReturnType<typeof useForgottenPasswordResetMutation>;
export type ForgottenPasswordResetMutationResult = ApolloReactCommon.MutationResult<ForgottenPasswordResetMutation>;
export type ForgottenPasswordResetMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgottenPasswordResetMutation, ForgottenPasswordResetMutationVariables>;
export const ResetPasswordWithEmailTokenDocument = gql`
    mutation ResetPasswordWithEmailToken($data: resetPasswordWithEmailToken!) {
  resetPasswordWithEmailToken(data: $data) {
    accessToken
    refreshToken
  }
}
    `;
export type ResetPasswordWithEmailTokenMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordWithEmailTokenMutation, ResetPasswordWithEmailTokenMutationVariables>;
export type ResetPasswordWithEmailTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResetPasswordWithEmailTokenMutation, ResetPasswordWithEmailTokenMutationVariables>, 'mutation'>;

    export const ResetPasswordWithEmailTokenComponent = (props: ResetPasswordWithEmailTokenComponentProps) => (
      <ApolloReactComponents.Mutation<ResetPasswordWithEmailTokenMutation, ResetPasswordWithEmailTokenMutationVariables> mutation={ResetPasswordWithEmailTokenDocument} {...props} />
    );
    

/**
 * __useResetPasswordWithEmailTokenMutation__
 *
 * To run a mutation, you first call `useResetPasswordWithEmailTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordWithEmailTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordWithEmailTokenMutation, { data, loading, error }] = useResetPasswordWithEmailTokenMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResetPasswordWithEmailTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordWithEmailTokenMutation, ResetPasswordWithEmailTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordWithEmailTokenMutation, ResetPasswordWithEmailTokenMutationVariables>(ResetPasswordWithEmailTokenDocument, baseOptions);
      }
export type ResetPasswordWithEmailTokenMutationHookResult = ReturnType<typeof useResetPasswordWithEmailTokenMutation>;
export type ResetPasswordWithEmailTokenMutationResult = ApolloReactCommon.MutationResult<ResetPasswordWithEmailTokenMutation>;
export type ResetPasswordWithEmailTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordWithEmailTokenMutation, ResetPasswordWithEmailTokenMutationVariables>;
export const SubmitDailyQuestionDocument = gql`
    mutation SubmitDailyQuestion($data: SubmitDailyQuestionAnswerInput!) {
  submitDailyQuestion(data: $data) {
    isCorrect
    description
    acquiredPoints
    correctAnswer {
      id
      text
    }
    question {
      id
      text
    }
    answer {
      id
      text
    }
  }
}
    `;
export type SubmitDailyQuestionMutationFn = ApolloReactCommon.MutationFunction<SubmitDailyQuestionMutation, SubmitDailyQuestionMutationVariables>;
export type SubmitDailyQuestionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubmitDailyQuestionMutation, SubmitDailyQuestionMutationVariables>, 'mutation'>;

    export const SubmitDailyQuestionComponent = (props: SubmitDailyQuestionComponentProps) => (
      <ApolloReactComponents.Mutation<SubmitDailyQuestionMutation, SubmitDailyQuestionMutationVariables> mutation={SubmitDailyQuestionDocument} {...props} />
    );
    

/**
 * __useSubmitDailyQuestionMutation__
 *
 * To run a mutation, you first call `useSubmitDailyQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitDailyQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitDailyQuestionMutation, { data, loading, error }] = useSubmitDailyQuestionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubmitDailyQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitDailyQuestionMutation, SubmitDailyQuestionMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitDailyQuestionMutation, SubmitDailyQuestionMutationVariables>(SubmitDailyQuestionDocument, baseOptions);
      }
export type SubmitDailyQuestionMutationHookResult = ReturnType<typeof useSubmitDailyQuestionMutation>;
export type SubmitDailyQuestionMutationResult = ApolloReactCommon.MutationResult<SubmitDailyQuestionMutation>;
export type SubmitDailyQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitDailyQuestionMutation, SubmitDailyQuestionMutationVariables>;
export const CreateWordDocument = gql`
    mutation CreateWord($data: CreateWordInput!) {
  createWord(data: $data) {
    id
    word
    description
  }
}
    `;
export type CreateWordMutationFn = ApolloReactCommon.MutationFunction<CreateWordMutation, CreateWordMutationVariables>;
export type CreateWordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateWordMutation, CreateWordMutationVariables>, 'mutation'>;

    export const CreateWordComponent = (props: CreateWordComponentProps) => (
      <ApolloReactComponents.Mutation<CreateWordMutation, CreateWordMutationVariables> mutation={CreateWordDocument} {...props} />
    );
    

/**
 * __useCreateWordMutation__
 *
 * To run a mutation, you first call `useCreateWordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWordMutation, { data, loading, error }] = useCreateWordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateWordMutation, CreateWordMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateWordMutation, CreateWordMutationVariables>(CreateWordDocument, baseOptions);
      }
export type CreateWordMutationHookResult = ReturnType<typeof useCreateWordMutation>;
export type CreateWordMutationResult = ApolloReactCommon.MutationResult<CreateWordMutation>;
export type CreateWordMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateWordMutation, CreateWordMutationVariables>;
export const DeleteWordDocument = gql`
    mutation DeleteWord($where: WordWhereUniqueInput!) {
  deleteWord(where: $where) {
    id
    word
  }
}
    `;
export type DeleteWordMutationFn = ApolloReactCommon.MutationFunction<DeleteWordMutation, DeleteWordMutationVariables>;
export type DeleteWordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteWordMutation, DeleteWordMutationVariables>, 'mutation'>;

    export const DeleteWordComponent = (props: DeleteWordComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteWordMutation, DeleteWordMutationVariables> mutation={DeleteWordDocument} {...props} />
    );
    

/**
 * __useDeleteWordMutation__
 *
 * To run a mutation, you first call `useDeleteWordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWordMutation, { data, loading, error }] = useDeleteWordMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteWordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteWordMutation, DeleteWordMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteWordMutation, DeleteWordMutationVariables>(DeleteWordDocument, baseOptions);
      }
export type DeleteWordMutationHookResult = ReturnType<typeof useDeleteWordMutation>;
export type DeleteWordMutationResult = ApolloReactCommon.MutationResult<DeleteWordMutation>;
export type DeleteWordMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteWordMutation, DeleteWordMutationVariables>;
export const UpdateWordDocument = gql`
    mutation UpdateWord($where: WordWhereUniqueInput!, $data: UpdateWordInput!) {
  updateWord(where: $where, data: $data) {
    id
    word
    description
  }
}
    `;
export type UpdateWordMutationFn = ApolloReactCommon.MutationFunction<UpdateWordMutation, UpdateWordMutationVariables>;
export type UpdateWordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateWordMutation, UpdateWordMutationVariables>, 'mutation'>;

    export const UpdateWordComponent = (props: UpdateWordComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateWordMutation, UpdateWordMutationVariables> mutation={UpdateWordDocument} {...props} />
    );
    

/**
 * __useUpdateWordMutation__
 *
 * To run a mutation, you first call `useUpdateWordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWordMutation, { data, loading, error }] = useUpdateWordMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateWordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWordMutation, UpdateWordMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWordMutation, UpdateWordMutationVariables>(UpdateWordDocument, baseOptions);
      }
export type UpdateWordMutationHookResult = ReturnType<typeof useUpdateWordMutation>;
export type UpdateWordMutationResult = ApolloReactCommon.MutationResult<UpdateWordMutation>;
export type UpdateWordMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWordMutation, UpdateWordMutationVariables>;
export const DeleteOwnAccountDocument = gql`
    mutation DeleteOwnAccount {
  deleteOwnAccount {
    email
  }
}
    `;
export type DeleteOwnAccountMutationFn = ApolloReactCommon.MutationFunction<DeleteOwnAccountMutation, DeleteOwnAccountMutationVariables>;
export type DeleteOwnAccountComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteOwnAccountMutation, DeleteOwnAccountMutationVariables>, 'mutation'>;

    export const DeleteOwnAccountComponent = (props: DeleteOwnAccountComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteOwnAccountMutation, DeleteOwnAccountMutationVariables> mutation={DeleteOwnAccountDocument} {...props} />
    );
    

/**
 * __useDeleteOwnAccountMutation__
 *
 * To run a mutation, you first call `useDeleteOwnAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOwnAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOwnAccountMutation, { data, loading, error }] = useDeleteOwnAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteOwnAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOwnAccountMutation, DeleteOwnAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOwnAccountMutation, DeleteOwnAccountMutationVariables>(DeleteOwnAccountDocument, baseOptions);
      }
export type DeleteOwnAccountMutationHookResult = ReturnType<typeof useDeleteOwnAccountMutation>;
export type DeleteOwnAccountMutationResult = ApolloReactCommon.MutationResult<DeleteOwnAccountMutation>;
export type DeleteOwnAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOwnAccountMutation, DeleteOwnAccountMutationVariables>;
export const CreateTestDocument = gql`
    mutation CreateTest($lecture: LectureWhereUniqueInput!) {
  createTest(lecture: $lecture) {
    id
  }
}
    `;
export type CreateTestMutationFn = ApolloReactCommon.MutationFunction<CreateTestMutation, CreateTestMutationVariables>;
export type CreateTestComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateTestMutation, CreateTestMutationVariables>, 'mutation'>;

    export const CreateTestComponent = (props: CreateTestComponentProps) => (
      <ApolloReactComponents.Mutation<CreateTestMutation, CreateTestMutationVariables> mutation={CreateTestDocument} {...props} />
    );
    

/**
 * __useCreateTestMutation__
 *
 * To run a mutation, you first call `useCreateTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestMutation, { data, loading, error }] = useCreateTestMutation({
 *   variables: {
 *      lecture: // value for 'lecture'
 *   },
 * });
 */
export function useCreateTestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTestMutation, CreateTestMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTestMutation, CreateTestMutationVariables>(CreateTestDocument, baseOptions);
      }
export type CreateTestMutationHookResult = ReturnType<typeof useCreateTestMutation>;
export type CreateTestMutationResult = ApolloReactCommon.MutationResult<CreateTestMutation>;
export type CreateTestMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTestMutation, CreateTestMutationVariables>;
export const CreateTestQuestionDocument = gql`
    mutation CreateTestQuestion($test: TestWhereUniqueInput!, $data: TestQuestionInput!) {
  createTestQuestion(test: $test, data: $data) {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;
export type CreateTestQuestionMutationFn = ApolloReactCommon.MutationFunction<CreateTestQuestionMutation, CreateTestQuestionMutationVariables>;
export type CreateTestQuestionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateTestQuestionMutation, CreateTestQuestionMutationVariables>, 'mutation'>;

    export const CreateTestQuestionComponent = (props: CreateTestQuestionComponentProps) => (
      <ApolloReactComponents.Mutation<CreateTestQuestionMutation, CreateTestQuestionMutationVariables> mutation={CreateTestQuestionDocument} {...props} />
    );
    

/**
 * __useCreateTestQuestionMutation__
 *
 * To run a mutation, you first call `useCreateTestQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestQuestionMutation, { data, loading, error }] = useCreateTestQuestionMutation({
 *   variables: {
 *      test: // value for 'test'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTestQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTestQuestionMutation, CreateTestQuestionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTestQuestionMutation, CreateTestQuestionMutationVariables>(CreateTestQuestionDocument, baseOptions);
      }
export type CreateTestQuestionMutationHookResult = ReturnType<typeof useCreateTestQuestionMutation>;
export type CreateTestQuestionMutationResult = ApolloReactCommon.MutationResult<CreateTestQuestionMutation>;
export type CreateTestQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTestQuestionMutation, CreateTestQuestionMutationVariables>;
export const DeleteTestQuestionDocument = gql`
    mutation DeleteTestQuestion($where: QuestionWhereUniqueInput!) {
  deleteTestQuestion(where: $where) {
    id
  }
}
    `;
export type DeleteTestQuestionMutationFn = ApolloReactCommon.MutationFunction<DeleteTestQuestionMutation, DeleteTestQuestionMutationVariables>;
export type DeleteTestQuestionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteTestQuestionMutation, DeleteTestQuestionMutationVariables>, 'mutation'>;

    export const DeleteTestQuestionComponent = (props: DeleteTestQuestionComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteTestQuestionMutation, DeleteTestQuestionMutationVariables> mutation={DeleteTestQuestionDocument} {...props} />
    );
    

/**
 * __useDeleteTestQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteTestQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTestQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTestQuestionMutation, { data, loading, error }] = useDeleteTestQuestionMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteTestQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTestQuestionMutation, DeleteTestQuestionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTestQuestionMutation, DeleteTestQuestionMutationVariables>(DeleteTestQuestionDocument, baseOptions);
      }
export type DeleteTestQuestionMutationHookResult = ReturnType<typeof useDeleteTestQuestionMutation>;
export type DeleteTestQuestionMutationResult = ApolloReactCommon.MutationResult<DeleteTestQuestionMutation>;
export type DeleteTestQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTestQuestionMutation, DeleteTestQuestionMutationVariables>;
export const UpdateTestQuestionDocument = gql`
    mutation UpdateTestQuestion($where: QuestionWhereUniqueInput!, $data: TestQuestionUpdateInput!) {
  updateTestQuestion(where: $where, data: $data) {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;
export type UpdateTestQuestionMutationFn = ApolloReactCommon.MutationFunction<UpdateTestQuestionMutation, UpdateTestQuestionMutationVariables>;
export type UpdateTestQuestionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateTestQuestionMutation, UpdateTestQuestionMutationVariables>, 'mutation'>;

    export const UpdateTestQuestionComponent = (props: UpdateTestQuestionComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateTestQuestionMutation, UpdateTestQuestionMutationVariables> mutation={UpdateTestQuestionDocument} {...props} />
    );
    

/**
 * __useUpdateTestQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateTestQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTestQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTestQuestionMutation, { data, loading, error }] = useUpdateTestQuestionMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTestQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTestQuestionMutation, UpdateTestQuestionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTestQuestionMutation, UpdateTestQuestionMutationVariables>(UpdateTestQuestionDocument, baseOptions);
      }
export type UpdateTestQuestionMutationHookResult = ReturnType<typeof useUpdateTestQuestionMutation>;
export type UpdateTestQuestionMutationResult = ApolloReactCommon.MutationResult<UpdateTestQuestionMutation>;
export type UpdateTestQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTestQuestionMutation, UpdateTestQuestionMutationVariables>;
export const CreateQuestionAnswersDocument = gql`
    mutation CreateQuestionAnswers($question: QuestionWhereUniqueInput!, $data: [TestAnswerInput!]!) {
  createQuestionAnswers(question: $question, data: $data) {
    ...Answer
  }
}
    ${AnswerFragmentDoc}`;
export type CreateQuestionAnswersMutationFn = ApolloReactCommon.MutationFunction<CreateQuestionAnswersMutation, CreateQuestionAnswersMutationVariables>;
export type CreateQuestionAnswersComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateQuestionAnswersMutation, CreateQuestionAnswersMutationVariables>, 'mutation'>;

    export const CreateQuestionAnswersComponent = (props: CreateQuestionAnswersComponentProps) => (
      <ApolloReactComponents.Mutation<CreateQuestionAnswersMutation, CreateQuestionAnswersMutationVariables> mutation={CreateQuestionAnswersDocument} {...props} />
    );
    

/**
 * __useCreateQuestionAnswersMutation__
 *
 * To run a mutation, you first call `useCreateQuestionAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionAnswersMutation, { data, loading, error }] = useCreateQuestionAnswersMutation({
 *   variables: {
 *      question: // value for 'question'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateQuestionAnswersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateQuestionAnswersMutation, CreateQuestionAnswersMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateQuestionAnswersMutation, CreateQuestionAnswersMutationVariables>(CreateQuestionAnswersDocument, baseOptions);
      }
export type CreateQuestionAnswersMutationHookResult = ReturnType<typeof useCreateQuestionAnswersMutation>;
export type CreateQuestionAnswersMutationResult = ApolloReactCommon.MutationResult<CreateQuestionAnswersMutation>;
export type CreateQuestionAnswersMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateQuestionAnswersMutation, CreateQuestionAnswersMutationVariables>;
export const UpdateQuestionAnswersDocument = gql`
    mutation UpdateQuestionAnswers($data: [TestAnswerUpdateInput!]!) {
  updateQuestionAnswers(data: $data) {
    ...Answer
  }
}
    ${AnswerFragmentDoc}`;
export type UpdateQuestionAnswersMutationFn = ApolloReactCommon.MutationFunction<UpdateQuestionAnswersMutation, UpdateQuestionAnswersMutationVariables>;
export type UpdateQuestionAnswersComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateQuestionAnswersMutation, UpdateQuestionAnswersMutationVariables>, 'mutation'>;

    export const UpdateQuestionAnswersComponent = (props: UpdateQuestionAnswersComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateQuestionAnswersMutation, UpdateQuestionAnswersMutationVariables> mutation={UpdateQuestionAnswersDocument} {...props} />
    );
    

/**
 * __useUpdateQuestionAnswersMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionAnswersMutation, { data, loading, error }] = useUpdateQuestionAnswersMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateQuestionAnswersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateQuestionAnswersMutation, UpdateQuestionAnswersMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateQuestionAnswersMutation, UpdateQuestionAnswersMutationVariables>(UpdateQuestionAnswersDocument, baseOptions);
      }
export type UpdateQuestionAnswersMutationHookResult = ReturnType<typeof useUpdateQuestionAnswersMutation>;
export type UpdateQuestionAnswersMutationResult = ApolloReactCommon.MutationResult<UpdateQuestionAnswersMutation>;
export type UpdateQuestionAnswersMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateQuestionAnswersMutation, UpdateQuestionAnswersMutationVariables>;
export const BlockLectureDocument = gql`
    mutation BlockLecture($where: LectureUniqueRequiredInput!) {
  blockLecture(where: $where) {
    id
  }
}
    `;
export type BlockLectureMutationFn = ApolloReactCommon.MutationFunction<BlockLectureMutation, BlockLectureMutationVariables>;
export type BlockLectureComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BlockLectureMutation, BlockLectureMutationVariables>, 'mutation'>;

    export const BlockLectureComponent = (props: BlockLectureComponentProps) => (
      <ApolloReactComponents.Mutation<BlockLectureMutation, BlockLectureMutationVariables> mutation={BlockLectureDocument} {...props} />
    );
    

/**
 * __useBlockLectureMutation__
 *
 * To run a mutation, you first call `useBlockLectureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockLectureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockLectureMutation, { data, loading, error }] = useBlockLectureMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBlockLectureMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BlockLectureMutation, BlockLectureMutationVariables>) {
        return ApolloReactHooks.useMutation<BlockLectureMutation, BlockLectureMutationVariables>(BlockLectureDocument, baseOptions);
      }
export type BlockLectureMutationHookResult = ReturnType<typeof useBlockLectureMutation>;
export type BlockLectureMutationResult = ApolloReactCommon.MutationResult<BlockLectureMutation>;
export type BlockLectureMutationOptions = ApolloReactCommon.BaseMutationOptions<BlockLectureMutation, BlockLectureMutationVariables>;
export const BlockChapterDocument = gql`
    mutation BlockChapter($where: ChapterUniqueRequiredInput!) {
  blockChapter(where: $where) {
    id
  }
}
    `;
export type BlockChapterMutationFn = ApolloReactCommon.MutationFunction<BlockChapterMutation, BlockChapterMutationVariables>;
export type BlockChapterComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BlockChapterMutation, BlockChapterMutationVariables>, 'mutation'>;

    export const BlockChapterComponent = (props: BlockChapterComponentProps) => (
      <ApolloReactComponents.Mutation<BlockChapterMutation, BlockChapterMutationVariables> mutation={BlockChapterDocument} {...props} />
    );
    

/**
 * __useBlockChapterMutation__
 *
 * To run a mutation, you first call `useBlockChapterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockChapterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockChapterMutation, { data, loading, error }] = useBlockChapterMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBlockChapterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BlockChapterMutation, BlockChapterMutationVariables>) {
        return ApolloReactHooks.useMutation<BlockChapterMutation, BlockChapterMutationVariables>(BlockChapterDocument, baseOptions);
      }
export type BlockChapterMutationHookResult = ReturnType<typeof useBlockChapterMutation>;
export type BlockChapterMutationResult = ApolloReactCommon.MutationResult<BlockChapterMutation>;
export type BlockChapterMutationOptions = ApolloReactCommon.BaseMutationOptions<BlockChapterMutation, BlockChapterMutationVariables>;
export const BlockUserDocument = gql`
    mutation BlockUser($where: UserWhereId!) {
  blockUser(where: $where) {
    id
  }
}
    `;
export type BlockUserMutationFn = ApolloReactCommon.MutationFunction<BlockUserMutation, BlockUserMutationVariables>;
export type BlockUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BlockUserMutation, BlockUserMutationVariables>, 'mutation'>;

    export const BlockUserComponent = (props: BlockUserComponentProps) => (
      <ApolloReactComponents.Mutation<BlockUserMutation, BlockUserMutationVariables> mutation={BlockUserDocument} {...props} />
    );
    

/**
 * __useBlockUserMutation__
 *
 * To run a mutation, you first call `useBlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUserMutation, { data, loading, error }] = useBlockUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBlockUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BlockUserMutation, BlockUserMutationVariables>) {
        return ApolloReactHooks.useMutation<BlockUserMutation, BlockUserMutationVariables>(BlockUserDocument, baseOptions);
      }
export type BlockUserMutationHookResult = ReturnType<typeof useBlockUserMutation>;
export type BlockUserMutationResult = ApolloReactCommon.MutationResult<BlockUserMutation>;
export type BlockUserMutationOptions = ApolloReactCommon.BaseMutationOptions<BlockUserMutation, BlockUserMutationVariables>;
export const UnblockUserDocument = gql`
    mutation UnblockUser($where: UserWhereId!) {
  unblockUser(where: $where) {
    id
  }
}
    `;
export type UnblockUserMutationFn = ApolloReactCommon.MutationFunction<UnblockUserMutation, UnblockUserMutationVariables>;
export type UnblockUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnblockUserMutation, UnblockUserMutationVariables>, 'mutation'>;

    export const UnblockUserComponent = (props: UnblockUserComponentProps) => (
      <ApolloReactComponents.Mutation<UnblockUserMutation, UnblockUserMutationVariables> mutation={UnblockUserDocument} {...props} />
    );
    

/**
 * __useUnblockUserMutation__
 *
 * To run a mutation, you first call `useUnblockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockUserMutation, { data, loading, error }] = useUnblockUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUnblockUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnblockUserMutation, UnblockUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UnblockUserMutation, UnblockUserMutationVariables>(UnblockUserDocument, baseOptions);
      }
export type UnblockUserMutationHookResult = ReturnType<typeof useUnblockUserMutation>;
export type UnblockUserMutationResult = ApolloReactCommon.MutationResult<UnblockUserMutation>;
export type UnblockUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UnblockUserMutation, UnblockUserMutationVariables>;
export const InviteUserDocument = gql`
    mutation InviteUser($data: InvitationCreateInput!) {
  inviteUser(data: $data) {
    id
  }
}
    `;
export type InviteUserMutationFn = ApolloReactCommon.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;
export type InviteUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InviteUserMutation, InviteUserMutationVariables>, 'mutation'>;

    export const InviteUserComponent = (props: InviteUserComponentProps) => (
      <ApolloReactComponents.Mutation<InviteUserMutation, InviteUserMutationVariables> mutation={InviteUserDocument} {...props} />
    );
    

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, baseOptions);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = ApolloReactCommon.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const AcceptInvitationDocument = gql`
    mutation AcceptInvitation($data: InvitationAcceptInput!) {
  acceptInvitation(data: $data) {
    role
    email
    username
    accessToken
    refreshToken
  }
}
    `;
export type AcceptInvitationMutationFn = ApolloReactCommon.MutationFunction<AcceptInvitationMutation, AcceptInvitationMutationVariables>;
export type AcceptInvitationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>, 'mutation'>;

    export const AcceptInvitationComponent = (props: AcceptInvitationComponentProps) => (
      <ApolloReactComponents.Mutation<AcceptInvitationMutation, AcceptInvitationMutationVariables> mutation={AcceptInvitationDocument} {...props} />
    );
    

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAcceptInvitationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptInvitationMutation, AcceptInvitationMutationVariables>(AcceptInvitationDocument, baseOptions);
      }
export type AcceptInvitationMutationHookResult = ReturnType<typeof useAcceptInvitationMutation>;
export type AcceptInvitationMutationResult = ApolloReactCommon.MutationResult<AcceptInvitationMutation>;
export type AcceptInvitationMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>;
export const PublishChapterDocument = gql`
    mutation PublishChapter($where: ChapterUniqueRequiredInput!) {
  publishChapter(where: $where) {
    id
  }
}
    `;
export type PublishChapterMutationFn = ApolloReactCommon.MutationFunction<PublishChapterMutation, PublishChapterMutationVariables>;
export type PublishChapterComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PublishChapterMutation, PublishChapterMutationVariables>, 'mutation'>;

    export const PublishChapterComponent = (props: PublishChapterComponentProps) => (
      <ApolloReactComponents.Mutation<PublishChapterMutation, PublishChapterMutationVariables> mutation={PublishChapterDocument} {...props} />
    );
    

/**
 * __usePublishChapterMutation__
 *
 * To run a mutation, you first call `usePublishChapterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishChapterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishChapterMutation, { data, loading, error }] = usePublishChapterMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePublishChapterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublishChapterMutation, PublishChapterMutationVariables>) {
        return ApolloReactHooks.useMutation<PublishChapterMutation, PublishChapterMutationVariables>(PublishChapterDocument, baseOptions);
      }
export type PublishChapterMutationHookResult = ReturnType<typeof usePublishChapterMutation>;
export type PublishChapterMutationResult = ApolloReactCommon.MutationResult<PublishChapterMutation>;
export type PublishChapterMutationOptions = ApolloReactCommon.BaseMutationOptions<PublishChapterMutation, PublishChapterMutationVariables>;
export const PublishLectureDocument = gql`
    mutation PublishLecture($where: LectureUniqueRequiredInput!) {
  publishLecture(where: $where) {
    id
  }
}
    `;
export type PublishLectureMutationFn = ApolloReactCommon.MutationFunction<PublishLectureMutation, PublishLectureMutationVariables>;
export type PublishLectureComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PublishLectureMutation, PublishLectureMutationVariables>, 'mutation'>;

    export const PublishLectureComponent = (props: PublishLectureComponentProps) => (
      <ApolloReactComponents.Mutation<PublishLectureMutation, PublishLectureMutationVariables> mutation={PublishLectureDocument} {...props} />
    );
    

/**
 * __usePublishLectureMutation__
 *
 * To run a mutation, you first call `usePublishLectureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishLectureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishLectureMutation, { data, loading, error }] = usePublishLectureMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePublishLectureMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublishLectureMutation, PublishLectureMutationVariables>) {
        return ApolloReactHooks.useMutation<PublishLectureMutation, PublishLectureMutationVariables>(PublishLectureDocument, baseOptions);
      }
export type PublishLectureMutationHookResult = ReturnType<typeof usePublishLectureMutation>;
export type PublishLectureMutationResult = ApolloReactCommon.MutationResult<PublishLectureMutation>;
export type PublishLectureMutationOptions = ApolloReactCommon.BaseMutationOptions<PublishLectureMutation, PublishLectureMutationVariables>;
export const AcceptBadgesDocument = gql`
    mutation AcceptBadges($id: [ID!]!) {
  acceptBadges(id: $id) {
    id
  }
}
    `;
export type AcceptBadgesMutationFn = ApolloReactCommon.MutationFunction<AcceptBadgesMutation, AcceptBadgesMutationVariables>;
export type AcceptBadgesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AcceptBadgesMutation, AcceptBadgesMutationVariables>, 'mutation'>;

    export const AcceptBadgesComponent = (props: AcceptBadgesComponentProps) => (
      <ApolloReactComponents.Mutation<AcceptBadgesMutation, AcceptBadgesMutationVariables> mutation={AcceptBadgesDocument} {...props} />
    );
    

/**
 * __useAcceptBadgesMutation__
 *
 * To run a mutation, you first call `useAcceptBadgesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptBadgesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptBadgesMutation, { data, loading, error }] = useAcceptBadgesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcceptBadgesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptBadgesMutation, AcceptBadgesMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptBadgesMutation, AcceptBadgesMutationVariables>(AcceptBadgesDocument, baseOptions);
      }
export type AcceptBadgesMutationHookResult = ReturnType<typeof useAcceptBadgesMutation>;
export type AcceptBadgesMutationResult = ApolloReactCommon.MutationResult<AcceptBadgesMutation>;
export type AcceptBadgesMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptBadgesMutation, AcceptBadgesMutationVariables>;
export const DeleteNotificationsDocument = gql`
    mutation DeleteNotifications($id: [ID!]!) {
  deleteNotifications(id: $id) {
    count
  }
}
    `;
export type DeleteNotificationsMutationFn = ApolloReactCommon.MutationFunction<DeleteNotificationsMutation, DeleteNotificationsMutationVariables>;
export type DeleteNotificationsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteNotificationsMutation, DeleteNotificationsMutationVariables>, 'mutation'>;

    export const DeleteNotificationsComponent = (props: DeleteNotificationsComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteNotificationsMutation, DeleteNotificationsMutationVariables> mutation={DeleteNotificationsDocument} {...props} />
    );
    

/**
 * __useDeleteNotificationsMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationsMutation, { data, loading, error }] = useDeleteNotificationsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotificationsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteNotificationsMutation, DeleteNotificationsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteNotificationsMutation, DeleteNotificationsMutationVariables>(DeleteNotificationsDocument, baseOptions);
      }
export type DeleteNotificationsMutationHookResult = ReturnType<typeof useDeleteNotificationsMutation>;
export type DeleteNotificationsMutationResult = ApolloReactCommon.MutationResult<DeleteNotificationsMutation>;
export type DeleteNotificationsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteNotificationsMutation, DeleteNotificationsMutationVariables>;
export const CreateCustomNotificationDocument = gql`
    mutation CreateCustomNotification($data: CustomNotificationInput!) {
  createCustomNotification(data: $data) {
    id
  }
}
    `;
export type CreateCustomNotificationMutationFn = ApolloReactCommon.MutationFunction<CreateCustomNotificationMutation, CreateCustomNotificationMutationVariables>;
export type CreateCustomNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCustomNotificationMutation, CreateCustomNotificationMutationVariables>, 'mutation'>;

    export const CreateCustomNotificationComponent = (props: CreateCustomNotificationComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCustomNotificationMutation, CreateCustomNotificationMutationVariables> mutation={CreateCustomNotificationDocument} {...props} />
    );
    

/**
 * __useCreateCustomNotificationMutation__
 *
 * To run a mutation, you first call `useCreateCustomNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomNotificationMutation, { data, loading, error }] = useCreateCustomNotificationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCustomNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomNotificationMutation, CreateCustomNotificationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCustomNotificationMutation, CreateCustomNotificationMutationVariables>(CreateCustomNotificationDocument, baseOptions);
      }
export type CreateCustomNotificationMutationHookResult = ReturnType<typeof useCreateCustomNotificationMutation>;
export type CreateCustomNotificationMutationResult = ApolloReactCommon.MutationResult<CreateCustomNotificationMutation>;
export type CreateCustomNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomNotificationMutation, CreateCustomNotificationMutationVariables>;
export const UpdateCustomNotificationDocument = gql`
    mutation UpdateCustomNotification($data: CustomNotificationInput!, $where: CustomNotificationWhereUniqueInput!) {
  updateCustomNotification(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateCustomNotificationMutationFn = ApolloReactCommon.MutationFunction<UpdateCustomNotificationMutation, UpdateCustomNotificationMutationVariables>;
export type UpdateCustomNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCustomNotificationMutation, UpdateCustomNotificationMutationVariables>, 'mutation'>;

    export const UpdateCustomNotificationComponent = (props: UpdateCustomNotificationComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCustomNotificationMutation, UpdateCustomNotificationMutationVariables> mutation={UpdateCustomNotificationDocument} {...props} />
    );
    

/**
 * __useUpdateCustomNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateCustomNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomNotificationMutation, { data, loading, error }] = useUpdateCustomNotificationMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateCustomNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomNotificationMutation, UpdateCustomNotificationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomNotificationMutation, UpdateCustomNotificationMutationVariables>(UpdateCustomNotificationDocument, baseOptions);
      }
export type UpdateCustomNotificationMutationHookResult = ReturnType<typeof useUpdateCustomNotificationMutation>;
export type UpdateCustomNotificationMutationResult = ApolloReactCommon.MutationResult<UpdateCustomNotificationMutation>;
export type UpdateCustomNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCustomNotificationMutation, UpdateCustomNotificationMutationVariables>;
export const DeleteCustomNotificationDocument = gql`
    mutation DeleteCustomNotification($where: CustomNotificationWhereUniqueInput!) {
  deleteCustomNotification(where: $where) {
    id
  }
}
    `;
export type DeleteCustomNotificationMutationFn = ApolloReactCommon.MutationFunction<DeleteCustomNotificationMutation, DeleteCustomNotificationMutationVariables>;
export type DeleteCustomNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteCustomNotificationMutation, DeleteCustomNotificationMutationVariables>, 'mutation'>;

    export const DeleteCustomNotificationComponent = (props: DeleteCustomNotificationComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteCustomNotificationMutation, DeleteCustomNotificationMutationVariables> mutation={DeleteCustomNotificationDocument} {...props} />
    );
    

/**
 * __useDeleteCustomNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteCustomNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomNotificationMutation, { data, loading, error }] = useDeleteCustomNotificationMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteCustomNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCustomNotificationMutation, DeleteCustomNotificationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCustomNotificationMutation, DeleteCustomNotificationMutationVariables>(DeleteCustomNotificationDocument, baseOptions);
      }
export type DeleteCustomNotificationMutationHookResult = ReturnType<typeof useDeleteCustomNotificationMutation>;
export type DeleteCustomNotificationMutationResult = ApolloReactCommon.MutationResult<DeleteCustomNotificationMutation>;
export type DeleteCustomNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCustomNotificationMutation, DeleteCustomNotificationMutationVariables>;
export const ApproveCustomNotificationDocument = gql`
    mutation ApproveCustomNotification($where: CustomNotificationWhereUniqueInput!) {
  approveCustomNotification(where: $where) {
    id
  }
}
    `;
export type ApproveCustomNotificationMutationFn = ApolloReactCommon.MutationFunction<ApproveCustomNotificationMutation, ApproveCustomNotificationMutationVariables>;
export type ApproveCustomNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ApproveCustomNotificationMutation, ApproveCustomNotificationMutationVariables>, 'mutation'>;

    export const ApproveCustomNotificationComponent = (props: ApproveCustomNotificationComponentProps) => (
      <ApolloReactComponents.Mutation<ApproveCustomNotificationMutation, ApproveCustomNotificationMutationVariables> mutation={ApproveCustomNotificationDocument} {...props} />
    );
    

/**
 * __useApproveCustomNotificationMutation__
 *
 * To run a mutation, you first call `useApproveCustomNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveCustomNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveCustomNotificationMutation, { data, loading, error }] = useApproveCustomNotificationMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useApproveCustomNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveCustomNotificationMutation, ApproveCustomNotificationMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveCustomNotificationMutation, ApproveCustomNotificationMutationVariables>(ApproveCustomNotificationDocument, baseOptions);
      }
export type ApproveCustomNotificationMutationHookResult = ReturnType<typeof useApproveCustomNotificationMutation>;
export type ApproveCustomNotificationMutationResult = ApolloReactCommon.MutationResult<ApproveCustomNotificationMutation>;
export type ApproveCustomNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveCustomNotificationMutation, ApproveCustomNotificationMutationVariables>;
export const RejectCustomNotificationDocument = gql`
    mutation RejectCustomNotification($data: RejectCustomNotificationInput!, $where: CustomNotificationWhereUniqueInput!) {
  rejectCustomNotification(data: $data, where: $where) {
    id
  }
}
    `;
export type RejectCustomNotificationMutationFn = ApolloReactCommon.MutationFunction<RejectCustomNotificationMutation, RejectCustomNotificationMutationVariables>;
export type RejectCustomNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RejectCustomNotificationMutation, RejectCustomNotificationMutationVariables>, 'mutation'>;

    export const RejectCustomNotificationComponent = (props: RejectCustomNotificationComponentProps) => (
      <ApolloReactComponents.Mutation<RejectCustomNotificationMutation, RejectCustomNotificationMutationVariables> mutation={RejectCustomNotificationDocument} {...props} />
    );
    

/**
 * __useRejectCustomNotificationMutation__
 *
 * To run a mutation, you first call `useRejectCustomNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectCustomNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectCustomNotificationMutation, { data, loading, error }] = useRejectCustomNotificationMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRejectCustomNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectCustomNotificationMutation, RejectCustomNotificationMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectCustomNotificationMutation, RejectCustomNotificationMutationVariables>(RejectCustomNotificationDocument, baseOptions);
      }
export type RejectCustomNotificationMutationHookResult = ReturnType<typeof useRejectCustomNotificationMutation>;
export type RejectCustomNotificationMutationResult = ApolloReactCommon.MutationResult<RejectCustomNotificationMutation>;
export type RejectCustomNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectCustomNotificationMutation, RejectCustomNotificationMutationVariables>;
export const UpdateFcmDocument = gql`
    mutation UpdateFcm($data: NotificationTokenUpdateInput!) {
  updateFcm(data: $data) {
    id
  }
}
    `;
export type UpdateFcmMutationFn = ApolloReactCommon.MutationFunction<UpdateFcmMutation, UpdateFcmMutationVariables>;
export type UpdateFcmComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateFcmMutation, UpdateFcmMutationVariables>, 'mutation'>;

    export const UpdateFcmComponent = (props: UpdateFcmComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateFcmMutation, UpdateFcmMutationVariables> mutation={UpdateFcmDocument} {...props} />
    );
    

/**
 * __useUpdateFcmMutation__
 *
 * To run a mutation, you first call `useUpdateFcmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFcmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFcmMutation, { data, loading, error }] = useUpdateFcmMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateFcmMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFcmMutation, UpdateFcmMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateFcmMutation, UpdateFcmMutationVariables>(UpdateFcmDocument, baseOptions);
      }
export type UpdateFcmMutationHookResult = ReturnType<typeof useUpdateFcmMutation>;
export type UpdateFcmMutationResult = ApolloReactCommon.MutationResult<UpdateFcmMutation>;
export type UpdateFcmMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFcmMutation, UpdateFcmMutationVariables>;
export const ReportIssueDocument = gql`
    mutation ReportIssue($question: QuestionWhereUniqueInput!, $issue: String!) {
  reportIssue(question: $question, issue: $issue)
}
    `;
export type ReportIssueMutationFn = ApolloReactCommon.MutationFunction<ReportIssueMutation, ReportIssueMutationVariables>;
export type ReportIssueComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReportIssueMutation, ReportIssueMutationVariables>, 'mutation'>;

    export const ReportIssueComponent = (props: ReportIssueComponentProps) => (
      <ApolloReactComponents.Mutation<ReportIssueMutation, ReportIssueMutationVariables> mutation={ReportIssueDocument} {...props} />
    );
    

/**
 * __useReportIssueMutation__
 *
 * To run a mutation, you first call `useReportIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportIssueMutation, { data, loading, error }] = useReportIssueMutation({
 *   variables: {
 *      question: // value for 'question'
 *      issue: // value for 'issue'
 *   },
 * });
 */
export function useReportIssueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReportIssueMutation, ReportIssueMutationVariables>) {
        return ApolloReactHooks.useMutation<ReportIssueMutation, ReportIssueMutationVariables>(ReportIssueDocument, baseOptions);
      }
export type ReportIssueMutationHookResult = ReturnType<typeof useReportIssueMutation>;
export type ReportIssueMutationResult = ApolloReactCommon.MutationResult<ReportIssueMutation>;
export type ReportIssueMutationOptions = ApolloReactCommon.BaseMutationOptions<ReportIssueMutation, ReportIssueMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation VerifyEmail($data: VerifyEmailInput!) {
  verifyEmail(data: $data)
}
    `;
export type VerifyEmailMutationFn = ApolloReactCommon.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;
export type VerifyEmailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<VerifyEmailMutation, VerifyEmailMutationVariables>, 'mutation'>;

    export const VerifyEmailComponent = (props: VerifyEmailComponentProps) => (
      <ApolloReactComponents.Mutation<VerifyEmailMutation, VerifyEmailMutationVariables> mutation={VerifyEmailDocument} {...props} />
    );
    

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, baseOptions);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = ApolloReactCommon.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const SendEmailVerificationDocument = gql`
    mutation SendEmailVerification {
  sendEmailVerification
}
    `;
export type SendEmailVerificationMutationFn = ApolloReactCommon.MutationFunction<SendEmailVerificationMutation, SendEmailVerificationMutationVariables>;
export type SendEmailVerificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendEmailVerificationMutation, SendEmailVerificationMutationVariables>, 'mutation'>;

    export const SendEmailVerificationComponent = (props: SendEmailVerificationComponentProps) => (
      <ApolloReactComponents.Mutation<SendEmailVerificationMutation, SendEmailVerificationMutationVariables> mutation={SendEmailVerificationDocument} {...props} />
    );
    

/**
 * __useSendEmailVerificationMutation__
 *
 * To run a mutation, you first call `useSendEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailVerificationMutation, { data, loading, error }] = useSendEmailVerificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendEmailVerificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendEmailVerificationMutation, SendEmailVerificationMutationVariables>) {
        return ApolloReactHooks.useMutation<SendEmailVerificationMutation, SendEmailVerificationMutationVariables>(SendEmailVerificationDocument, baseOptions);
      }
export type SendEmailVerificationMutationHookResult = ReturnType<typeof useSendEmailVerificationMutation>;
export type SendEmailVerificationMutationResult = ApolloReactCommon.MutationResult<SendEmailVerificationMutation>;
export type SendEmailVerificationMutationOptions = ApolloReactCommon.BaseMutationOptions<SendEmailVerificationMutation, SendEmailVerificationMutationVariables>;
export const BlockAndDeleteUserDocument = gql`
    mutation BlockAndDeleteUser($where: UserWhereId!) {
  blockAndDeleteUser(where: $where) {
    id
    email
    username
  }
}
    `;
export type BlockAndDeleteUserMutationFn = ApolloReactCommon.MutationFunction<BlockAndDeleteUserMutation, BlockAndDeleteUserMutationVariables>;
export type BlockAndDeleteUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BlockAndDeleteUserMutation, BlockAndDeleteUserMutationVariables>, 'mutation'>;

    export const BlockAndDeleteUserComponent = (props: BlockAndDeleteUserComponentProps) => (
      <ApolloReactComponents.Mutation<BlockAndDeleteUserMutation, BlockAndDeleteUserMutationVariables> mutation={BlockAndDeleteUserDocument} {...props} />
    );
    

/**
 * __useBlockAndDeleteUserMutation__
 *
 * To run a mutation, you first call `useBlockAndDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockAndDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockAndDeleteUserMutation, { data, loading, error }] = useBlockAndDeleteUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBlockAndDeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BlockAndDeleteUserMutation, BlockAndDeleteUserMutationVariables>) {
        return ApolloReactHooks.useMutation<BlockAndDeleteUserMutation, BlockAndDeleteUserMutationVariables>(BlockAndDeleteUserDocument, baseOptions);
      }
export type BlockAndDeleteUserMutationHookResult = ReturnType<typeof useBlockAndDeleteUserMutation>;
export type BlockAndDeleteUserMutationResult = ApolloReactCommon.MutationResult<BlockAndDeleteUserMutation>;
export type BlockAndDeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<BlockAndDeleteUserMutation, BlockAndDeleteUserMutationVariables>;
export const CreateChapterUpdateDraftDocument = gql`
    mutation CreateChapterUpdateDraft($where: QuestionWhereUniqueInput!) {
  createChapterUpdateDraft(where: $where) {
    id
    name
    description
  }
}
    `;
export type CreateChapterUpdateDraftMutationFn = ApolloReactCommon.MutationFunction<CreateChapterUpdateDraftMutation, CreateChapterUpdateDraftMutationVariables>;
export type CreateChapterUpdateDraftComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateChapterUpdateDraftMutation, CreateChapterUpdateDraftMutationVariables>, 'mutation'>;

    export const CreateChapterUpdateDraftComponent = (props: CreateChapterUpdateDraftComponentProps) => (
      <ApolloReactComponents.Mutation<CreateChapterUpdateDraftMutation, CreateChapterUpdateDraftMutationVariables> mutation={CreateChapterUpdateDraftDocument} {...props} />
    );
    

/**
 * __useCreateChapterUpdateDraftMutation__
 *
 * To run a mutation, you first call `useCreateChapterUpdateDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChapterUpdateDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChapterUpdateDraftMutation, { data, loading, error }] = useCreateChapterUpdateDraftMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCreateChapterUpdateDraftMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateChapterUpdateDraftMutation, CreateChapterUpdateDraftMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateChapterUpdateDraftMutation, CreateChapterUpdateDraftMutationVariables>(CreateChapterUpdateDraftDocument, baseOptions);
      }
export type CreateChapterUpdateDraftMutationHookResult = ReturnType<typeof useCreateChapterUpdateDraftMutation>;
export type CreateChapterUpdateDraftMutationResult = ApolloReactCommon.MutationResult<CreateChapterUpdateDraftMutation>;
export type CreateChapterUpdateDraftMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateChapterUpdateDraftMutation, CreateChapterUpdateDraftMutationVariables>;
export const UpdateChapterUpdateDraftDocument = gql`
    mutation UpdateChapterUpdateDraft($where: ChapterDraftWhereUniqueInput!, $data: UpdateChapterInput!) {
  updateChapterUpdateDraft(where: $where, data: $data) {
    id
    name
    description
    icon {
      png
      svg
    }
    author {
      id
    }
  }
}
    `;
export type UpdateChapterUpdateDraftMutationFn = ApolloReactCommon.MutationFunction<UpdateChapterUpdateDraftMutation, UpdateChapterUpdateDraftMutationVariables>;
export type UpdateChapterUpdateDraftComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateChapterUpdateDraftMutation, UpdateChapterUpdateDraftMutationVariables>, 'mutation'>;

    export const UpdateChapterUpdateDraftComponent = (props: UpdateChapterUpdateDraftComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateChapterUpdateDraftMutation, UpdateChapterUpdateDraftMutationVariables> mutation={UpdateChapterUpdateDraftDocument} {...props} />
    );
    

/**
 * __useUpdateChapterUpdateDraftMutation__
 *
 * To run a mutation, you first call `useUpdateChapterUpdateDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChapterUpdateDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChapterUpdateDraftMutation, { data, loading, error }] = useUpdateChapterUpdateDraftMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateChapterUpdateDraftMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateChapterUpdateDraftMutation, UpdateChapterUpdateDraftMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateChapterUpdateDraftMutation, UpdateChapterUpdateDraftMutationVariables>(UpdateChapterUpdateDraftDocument, baseOptions);
      }
export type UpdateChapterUpdateDraftMutationHookResult = ReturnType<typeof useUpdateChapterUpdateDraftMutation>;
export type UpdateChapterUpdateDraftMutationResult = ApolloReactCommon.MutationResult<UpdateChapterUpdateDraftMutation>;
export type UpdateChapterUpdateDraftMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateChapterUpdateDraftMutation, UpdateChapterUpdateDraftMutationVariables>;
export const CreateLectureUpdateDraftDocument = gql`
    mutation CreateLectureUpdateDraft($where: LectureWhereUniqueInput!) {
  createLectureUpdateDraft(where: $where) {
    id
  }
}
    `;
export type CreateLectureUpdateDraftMutationFn = ApolloReactCommon.MutationFunction<CreateLectureUpdateDraftMutation, CreateLectureUpdateDraftMutationVariables>;
export type CreateLectureUpdateDraftComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateLectureUpdateDraftMutation, CreateLectureUpdateDraftMutationVariables>, 'mutation'>;

    export const CreateLectureUpdateDraftComponent = (props: CreateLectureUpdateDraftComponentProps) => (
      <ApolloReactComponents.Mutation<CreateLectureUpdateDraftMutation, CreateLectureUpdateDraftMutationVariables> mutation={CreateLectureUpdateDraftDocument} {...props} />
    );
    

/**
 * __useCreateLectureUpdateDraftMutation__
 *
 * To run a mutation, you first call `useCreateLectureUpdateDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLectureUpdateDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLectureUpdateDraftMutation, { data, loading, error }] = useCreateLectureUpdateDraftMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCreateLectureUpdateDraftMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLectureUpdateDraftMutation, CreateLectureUpdateDraftMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateLectureUpdateDraftMutation, CreateLectureUpdateDraftMutationVariables>(CreateLectureUpdateDraftDocument, baseOptions);
      }
export type CreateLectureUpdateDraftMutationHookResult = ReturnType<typeof useCreateLectureUpdateDraftMutation>;
export type CreateLectureUpdateDraftMutationResult = ApolloReactCommon.MutationResult<CreateLectureUpdateDraftMutation>;
export type CreateLectureUpdateDraftMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateLectureUpdateDraftMutation, CreateLectureUpdateDraftMutationVariables>;
export const UpdateLectureUpdateDraftDocument = gql`
    mutation UpdateLectureUpdateDraft($where: LectureDraftWhereUniqueInput!, $data: UpdateLectureDraftInput!) {
  updateLectureUpdateDraft(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateLectureUpdateDraftMutationFn = ApolloReactCommon.MutationFunction<UpdateLectureUpdateDraftMutation, UpdateLectureUpdateDraftMutationVariables>;
export type UpdateLectureUpdateDraftComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateLectureUpdateDraftMutation, UpdateLectureUpdateDraftMutationVariables>, 'mutation'>;

    export const UpdateLectureUpdateDraftComponent = (props: UpdateLectureUpdateDraftComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateLectureUpdateDraftMutation, UpdateLectureUpdateDraftMutationVariables> mutation={UpdateLectureUpdateDraftDocument} {...props} />
    );
    

/**
 * __useUpdateLectureUpdateDraftMutation__
 *
 * To run a mutation, you first call `useUpdateLectureUpdateDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLectureUpdateDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLectureUpdateDraftMutation, { data, loading, error }] = useUpdateLectureUpdateDraftMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLectureUpdateDraftMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLectureUpdateDraftMutation, UpdateLectureUpdateDraftMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLectureUpdateDraftMutation, UpdateLectureUpdateDraftMutationVariables>(UpdateLectureUpdateDraftDocument, baseOptions);
      }
export type UpdateLectureUpdateDraftMutationHookResult = ReturnType<typeof useUpdateLectureUpdateDraftMutation>;
export type UpdateLectureUpdateDraftMutationResult = ApolloReactCommon.MutationResult<UpdateLectureUpdateDraftMutation>;
export type UpdateLectureUpdateDraftMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLectureUpdateDraftMutation, UpdateLectureUpdateDraftMutationVariables>;
export const ApproveChapterUpdateDraftDocument = gql`
    mutation ApproveChapterUpdateDraft($where: ChapterDraftWhereUniqueInput!) {
  approveChapterUpdateDraft(where: $where) {
    id
    isMerged
    isRejected
  }
}
    `;
export type ApproveChapterUpdateDraftMutationFn = ApolloReactCommon.MutationFunction<ApproveChapterUpdateDraftMutation, ApproveChapterUpdateDraftMutationVariables>;
export type ApproveChapterUpdateDraftComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ApproveChapterUpdateDraftMutation, ApproveChapterUpdateDraftMutationVariables>, 'mutation'>;

    export const ApproveChapterUpdateDraftComponent = (props: ApproveChapterUpdateDraftComponentProps) => (
      <ApolloReactComponents.Mutation<ApproveChapterUpdateDraftMutation, ApproveChapterUpdateDraftMutationVariables> mutation={ApproveChapterUpdateDraftDocument} {...props} />
    );
    

/**
 * __useApproveChapterUpdateDraftMutation__
 *
 * To run a mutation, you first call `useApproveChapterUpdateDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveChapterUpdateDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveChapterUpdateDraftMutation, { data, loading, error }] = useApproveChapterUpdateDraftMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useApproveChapterUpdateDraftMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveChapterUpdateDraftMutation, ApproveChapterUpdateDraftMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveChapterUpdateDraftMutation, ApproveChapterUpdateDraftMutationVariables>(ApproveChapterUpdateDraftDocument, baseOptions);
      }
export type ApproveChapterUpdateDraftMutationHookResult = ReturnType<typeof useApproveChapterUpdateDraftMutation>;
export type ApproveChapterUpdateDraftMutationResult = ApolloReactCommon.MutationResult<ApproveChapterUpdateDraftMutation>;
export type ApproveChapterUpdateDraftMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveChapterUpdateDraftMutation, ApproveChapterUpdateDraftMutationVariables>;
export const ApproveLectureUpdateDraftDocument = gql`
    mutation ApproveLectureUpdateDraft($where: LectureDraftWhereUniqueInput!) {
  approveLectureUpdateDraft(where: $where) {
    id
    isMerged
    isRejected
  }
}
    `;
export type ApproveLectureUpdateDraftMutationFn = ApolloReactCommon.MutationFunction<ApproveLectureUpdateDraftMutation, ApproveLectureUpdateDraftMutationVariables>;
export type ApproveLectureUpdateDraftComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ApproveLectureUpdateDraftMutation, ApproveLectureUpdateDraftMutationVariables>, 'mutation'>;

    export const ApproveLectureUpdateDraftComponent = (props: ApproveLectureUpdateDraftComponentProps) => (
      <ApolloReactComponents.Mutation<ApproveLectureUpdateDraftMutation, ApproveLectureUpdateDraftMutationVariables> mutation={ApproveLectureUpdateDraftDocument} {...props} />
    );
    

/**
 * __useApproveLectureUpdateDraftMutation__
 *
 * To run a mutation, you first call `useApproveLectureUpdateDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveLectureUpdateDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveLectureUpdateDraftMutation, { data, loading, error }] = useApproveLectureUpdateDraftMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useApproveLectureUpdateDraftMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveLectureUpdateDraftMutation, ApproveLectureUpdateDraftMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveLectureUpdateDraftMutation, ApproveLectureUpdateDraftMutationVariables>(ApproveLectureUpdateDraftDocument, baseOptions);
      }
export type ApproveLectureUpdateDraftMutationHookResult = ReturnType<typeof useApproveLectureUpdateDraftMutation>;
export type ApproveLectureUpdateDraftMutationResult = ApolloReactCommon.MutationResult<ApproveLectureUpdateDraftMutation>;
export type ApproveLectureUpdateDraftMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveLectureUpdateDraftMutation, ApproveLectureUpdateDraftMutationVariables>;
export const RejectChapterUpdateDraftDocument = gql`
    mutation RejectChapterUpdateDraft($where: ChapterDraftWhereUniqueInput!) {
  rejectChapterUpdateDraft(where: $where) {
    id
    isMerged
    isRejected
  }
}
    `;
export type RejectChapterUpdateDraftMutationFn = ApolloReactCommon.MutationFunction<RejectChapterUpdateDraftMutation, RejectChapterUpdateDraftMutationVariables>;
export type RejectChapterUpdateDraftComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RejectChapterUpdateDraftMutation, RejectChapterUpdateDraftMutationVariables>, 'mutation'>;

    export const RejectChapterUpdateDraftComponent = (props: RejectChapterUpdateDraftComponentProps) => (
      <ApolloReactComponents.Mutation<RejectChapterUpdateDraftMutation, RejectChapterUpdateDraftMutationVariables> mutation={RejectChapterUpdateDraftDocument} {...props} />
    );
    

/**
 * __useRejectChapterUpdateDraftMutation__
 *
 * To run a mutation, you first call `useRejectChapterUpdateDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectChapterUpdateDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectChapterUpdateDraftMutation, { data, loading, error }] = useRejectChapterUpdateDraftMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRejectChapterUpdateDraftMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectChapterUpdateDraftMutation, RejectChapterUpdateDraftMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectChapterUpdateDraftMutation, RejectChapterUpdateDraftMutationVariables>(RejectChapterUpdateDraftDocument, baseOptions);
      }
export type RejectChapterUpdateDraftMutationHookResult = ReturnType<typeof useRejectChapterUpdateDraftMutation>;
export type RejectChapterUpdateDraftMutationResult = ApolloReactCommon.MutationResult<RejectChapterUpdateDraftMutation>;
export type RejectChapterUpdateDraftMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectChapterUpdateDraftMutation, RejectChapterUpdateDraftMutationVariables>;
export const RejectLectureUpdateDraftDocument = gql`
    mutation RejectLectureUpdateDraft($where: LectureDraftWhereUniqueInput!) {
  rejectLectureUpdateDraft(where: $where) {
    id
    isMerged
    isRejected
  }
}
    `;
export type RejectLectureUpdateDraftMutationFn = ApolloReactCommon.MutationFunction<RejectLectureUpdateDraftMutation, RejectLectureUpdateDraftMutationVariables>;
export type RejectLectureUpdateDraftComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RejectLectureUpdateDraftMutation, RejectLectureUpdateDraftMutationVariables>, 'mutation'>;

    export const RejectLectureUpdateDraftComponent = (props: RejectLectureUpdateDraftComponentProps) => (
      <ApolloReactComponents.Mutation<RejectLectureUpdateDraftMutation, RejectLectureUpdateDraftMutationVariables> mutation={RejectLectureUpdateDraftDocument} {...props} />
    );
    

/**
 * __useRejectLectureUpdateDraftMutation__
 *
 * To run a mutation, you first call `useRejectLectureUpdateDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectLectureUpdateDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectLectureUpdateDraftMutation, { data, loading, error }] = useRejectLectureUpdateDraftMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRejectLectureUpdateDraftMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectLectureUpdateDraftMutation, RejectLectureUpdateDraftMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectLectureUpdateDraftMutation, RejectLectureUpdateDraftMutationVariables>(RejectLectureUpdateDraftDocument, baseOptions);
      }
export type RejectLectureUpdateDraftMutationHookResult = ReturnType<typeof useRejectLectureUpdateDraftMutation>;
export type RejectLectureUpdateDraftMutationResult = ApolloReactCommon.MutationResult<RejectLectureUpdateDraftMutation>;
export type RejectLectureUpdateDraftMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectLectureUpdateDraftMutation, RejectLectureUpdateDraftMutationVariables>;
export const UpdateProfilePictureDocument = gql`
    mutation UpdateProfilePicture($data: UpdateProfilePictureInput!) {
  updateProfilePicture(data: $data)
}
    `;
export type UpdateProfilePictureMutationFn = ApolloReactCommon.MutationFunction<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables>;
export type UpdateProfilePictureComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables>, 'mutation'>;

    export const UpdateProfilePictureComponent = (props: UpdateProfilePictureComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables> mutation={UpdateProfilePictureDocument} {...props} />
    );
    

/**
 * __useUpdateProfilePictureMutation__
 *
 * To run a mutation, you first call `useUpdateProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfilePictureMutation, { data, loading, error }] = useUpdateProfilePictureMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProfilePictureMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables>(UpdateProfilePictureDocument, baseOptions);
      }
export type UpdateProfilePictureMutationHookResult = ReturnType<typeof useUpdateProfilePictureMutation>;
export type UpdateProfilePictureMutationResult = ApolloReactCommon.MutationResult<UpdateProfilePictureMutation>;
export type UpdateProfilePictureMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables>;
export const AddTimeSpentDocument = gql`
    mutation AddTimeSpent($seconds: Int!) {
  addTimeSpent(seconds: $seconds)
}
    `;
export type AddTimeSpentMutationFn = ApolloReactCommon.MutationFunction<AddTimeSpentMutation, AddTimeSpentMutationVariables>;
export type AddTimeSpentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddTimeSpentMutation, AddTimeSpentMutationVariables>, 'mutation'>;

    export const AddTimeSpentComponent = (props: AddTimeSpentComponentProps) => (
      <ApolloReactComponents.Mutation<AddTimeSpentMutation, AddTimeSpentMutationVariables> mutation={AddTimeSpentDocument} {...props} />
    );
    

/**
 * __useAddTimeSpentMutation__
 *
 * To run a mutation, you first call `useAddTimeSpentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTimeSpentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTimeSpentMutation, { data, loading, error }] = useAddTimeSpentMutation({
 *   variables: {
 *      seconds: // value for 'seconds'
 *   },
 * });
 */
export function useAddTimeSpentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddTimeSpentMutation, AddTimeSpentMutationVariables>) {
        return ApolloReactHooks.useMutation<AddTimeSpentMutation, AddTimeSpentMutationVariables>(AddTimeSpentDocument, baseOptions);
      }
export type AddTimeSpentMutationHookResult = ReturnType<typeof useAddTimeSpentMutation>;
export type AddTimeSpentMutationResult = ApolloReactCommon.MutationResult<AddTimeSpentMutation>;
export type AddTimeSpentMutationOptions = ApolloReactCommon.BaseMutationOptions<AddTimeSpentMutation, AddTimeSpentMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    profilePicture
    ...User
    student {
      ...Student
      finalTestStatus {
        isCompleted
        acquiredPoints
        requiredPoints
      }
    }
  }
}
    ${UserFragmentDoc}
${StudentFragmentDoc}`;
export type MeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MeQuery, MeQueryVariables>, 'query'>;

    export const MeComponent = (props: MeComponentProps) => (
      <ApolloReactComponents.Query<MeQuery, MeQueryVariables> query={MeDocument} {...props} />
    );
    

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const CanAccessAdminDocument = gql`
    query CanAccessAdmin {
  canAccessAdmin
}
    `;
export type CanAccessAdminComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CanAccessAdminQuery, CanAccessAdminQueryVariables>, 'query'>;

    export const CanAccessAdminComponent = (props: CanAccessAdminComponentProps) => (
      <ApolloReactComponents.Query<CanAccessAdminQuery, CanAccessAdminQueryVariables> query={CanAccessAdminDocument} {...props} />
    );
    

/**
 * __useCanAccessAdminQuery__
 *
 * To run a query within a React component, call `useCanAccessAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanAccessAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanAccessAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useCanAccessAdminQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CanAccessAdminQuery, CanAccessAdminQueryVariables>) {
        return ApolloReactHooks.useQuery<CanAccessAdminQuery, CanAccessAdminQueryVariables>(CanAccessAdminDocument, baseOptions);
      }
export function useCanAccessAdminLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CanAccessAdminQuery, CanAccessAdminQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CanAccessAdminQuery, CanAccessAdminQueryVariables>(CanAccessAdminDocument, baseOptions);
        }
export type CanAccessAdminQueryHookResult = ReturnType<typeof useCanAccessAdminQuery>;
export type CanAccessAdminLazyQueryHookResult = ReturnType<typeof useCanAccessAdminLazyQuery>;
export type CanAccessAdminQueryResult = ApolloReactCommon.QueryResult<CanAccessAdminQuery, CanAccessAdminQueryVariables>;
export const DiplomasDocument = gql`
    query Diplomas($page: Int, $itemsPerPage: Int, $filter: DiplomasFilterInput, $orderBy: DiplomasOrderByInput) {
  diplomas(page: $page, filter: $filter, orderBy: $orderBy, itemsPerPage: $itemsPerPage) {
    data {
      id
      code
      points
      createdAt
      student {
        user {
          email
          username
          timeSpent
        }
      }
    }
    meta {
      page
      pages
      items
      itemsPerPage
    }
  }
}
    `;
export type DiplomasComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DiplomasQuery, DiplomasQueryVariables>, 'query'>;

    export const DiplomasComponent = (props: DiplomasComponentProps) => (
      <ApolloReactComponents.Query<DiplomasQuery, DiplomasQueryVariables> query={DiplomasDocument} {...props} />
    );
    

/**
 * __useDiplomasQuery__
 *
 * To run a query within a React component, call `useDiplomasQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiplomasQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiplomasQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useDiplomasQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DiplomasQuery, DiplomasQueryVariables>) {
        return ApolloReactHooks.useQuery<DiplomasQuery, DiplomasQueryVariables>(DiplomasDocument, baseOptions);
      }
export function useDiplomasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DiplomasQuery, DiplomasQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DiplomasQuery, DiplomasQueryVariables>(DiplomasDocument, baseOptions);
        }
export type DiplomasQueryHookResult = ReturnType<typeof useDiplomasQuery>;
export type DiplomasLazyQueryHookResult = ReturnType<typeof useDiplomasLazyQuery>;
export type DiplomasQueryResult = ApolloReactCommon.QueryResult<DiplomasQuery, DiplomasQueryVariables>;
export const UserLeaderboardPositionDocument = gql`
    query UserLeaderboardPosition($itemsPerPage: Int!, $filter: [LeaderboardFilterEnum!]) {
  userLeaderboardPosition(itemsPerPage: $itemsPerPage, filter: $filter) {
    page
  }
}
    `;
export type UserLeaderboardPositionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserLeaderboardPositionQuery, UserLeaderboardPositionQueryVariables>, 'query'> & ({ variables: UserLeaderboardPositionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserLeaderboardPositionComponent = (props: UserLeaderboardPositionComponentProps) => (
      <ApolloReactComponents.Query<UserLeaderboardPositionQuery, UserLeaderboardPositionQueryVariables> query={UserLeaderboardPositionDocument} {...props} />
    );
    

/**
 * __useUserLeaderboardPositionQuery__
 *
 * To run a query within a React component, call `useUserLeaderboardPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLeaderboardPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLeaderboardPositionQuery({
 *   variables: {
 *      itemsPerPage: // value for 'itemsPerPage'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUserLeaderboardPositionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserLeaderboardPositionQuery, UserLeaderboardPositionQueryVariables>) {
        return ApolloReactHooks.useQuery<UserLeaderboardPositionQuery, UserLeaderboardPositionQueryVariables>(UserLeaderboardPositionDocument, baseOptions);
      }
export function useUserLeaderboardPositionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserLeaderboardPositionQuery, UserLeaderboardPositionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserLeaderboardPositionQuery, UserLeaderboardPositionQueryVariables>(UserLeaderboardPositionDocument, baseOptions);
        }
export type UserLeaderboardPositionQueryHookResult = ReturnType<typeof useUserLeaderboardPositionQuery>;
export type UserLeaderboardPositionLazyQueryHookResult = ReturnType<typeof useUserLeaderboardPositionLazyQuery>;
export type UserLeaderboardPositionQueryResult = ApolloReactCommon.QueryResult<UserLeaderboardPositionQuery, UserLeaderboardPositionQueryVariables>;
export const IsEmailUsedDocument = gql`
    query IsEmailUsed($email: String!) {
  isEmailUsed(email: $email) {
    isUsed
    role
  }
}
    `;
export type IsEmailUsedComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<IsEmailUsedQuery, IsEmailUsedQueryVariables>, 'query'> & ({ variables: IsEmailUsedQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const IsEmailUsedComponent = (props: IsEmailUsedComponentProps) => (
      <ApolloReactComponents.Query<IsEmailUsedQuery, IsEmailUsedQueryVariables> query={IsEmailUsedDocument} {...props} />
    );
    

/**
 * __useIsEmailUsedQuery__
 *
 * To run a query within a React component, call `useIsEmailUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEmailUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEmailUsedQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useIsEmailUsedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsEmailUsedQuery, IsEmailUsedQueryVariables>) {
        return ApolloReactHooks.useQuery<IsEmailUsedQuery, IsEmailUsedQueryVariables>(IsEmailUsedDocument, baseOptions);
      }
export function useIsEmailUsedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsEmailUsedQuery, IsEmailUsedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsEmailUsedQuery, IsEmailUsedQueryVariables>(IsEmailUsedDocument, baseOptions);
        }
export type IsEmailUsedQueryHookResult = ReturnType<typeof useIsEmailUsedQuery>;
export type IsEmailUsedLazyQueryHookResult = ReturnType<typeof useIsEmailUsedLazyQuery>;
export type IsEmailUsedQueryResult = ApolloReactCommon.QueryResult<IsEmailUsedQuery, IsEmailUsedQueryVariables>;
export const UsersDocument = gql`
    query Users($search: String) {
  users(filter: {search: $search}) {
    data {
      id
      role
      email
    }
  }
}
    `;
export type UsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UsersQuery, UsersQueryVariables>, 'query'>;

    export const UsersComponent = (props: UsersComponentProps) => (
      <ApolloReactComponents.Query<UsersQuery, UsersQueryVariables> query={UsersDocument} {...props} />
    );
    

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;
export const UsersWithStatsDocument = gql`
    query UsersWithStats($page: Int, $itemsPerPage: Int, $filter: UsersFilterInput, $orderBy: UsersOrderByInput) {
  users(page: $page, filter: $filter, orderBy: $orderBy, itemsPerPage: $itemsPerPage) {
    data {
      ...UserWithStats
    }
    meta {
      ...Meta
    }
  }
}
    ${UserWithStatsFragmentDoc}
${MetaFragmentDoc}`;
export type UsersWithStatsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UsersWithStatsQuery, UsersWithStatsQueryVariables>, 'query'>;

    export const UsersWithStatsComponent = (props: UsersWithStatsComponentProps) => (
      <ApolloReactComponents.Query<UsersWithStatsQuery, UsersWithStatsQueryVariables> query={UsersWithStatsDocument} {...props} />
    );
    

/**
 * __useUsersWithStatsQuery__
 *
 * To run a query within a React component, call `useUsersWithStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersWithStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersWithStatsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useUsersWithStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersWithStatsQuery, UsersWithStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersWithStatsQuery, UsersWithStatsQueryVariables>(UsersWithStatsDocument, baseOptions);
      }
export function useUsersWithStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersWithStatsQuery, UsersWithStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersWithStatsQuery, UsersWithStatsQueryVariables>(UsersWithStatsDocument, baseOptions);
        }
export type UsersWithStatsQueryHookResult = ReturnType<typeof useUsersWithStatsQuery>;
export type UsersWithStatsLazyQueryHookResult = ReturnType<typeof useUsersWithStatsLazyQuery>;
export type UsersWithStatsQueryResult = ApolloReactCommon.QueryResult<UsersWithStatsQuery, UsersWithStatsQueryVariables>;
export const UserStatsDocument = gql`
    query UserStats {
  userStats {
    userCount
    diplomaCount
  }
}
    `;
export type UserStatsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserStatsQuery, UserStatsQueryVariables>, 'query'>;

    export const UserStatsComponent = (props: UserStatsComponentProps) => (
      <ApolloReactComponents.Query<UserStatsQuery, UserStatsQueryVariables> query={UserStatsDocument} {...props} />
    );
    

/**
 * __useUserStatsQuery__
 *
 * To run a query within a React component, call `useUserStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserStatsQuery, UserStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserStatsQuery, UserStatsQueryVariables>(UserStatsDocument, baseOptions);
      }
export function useUserStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserStatsQuery, UserStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserStatsQuery, UserStatsQueryVariables>(UserStatsDocument, baseOptions);
        }
export type UserStatsQueryHookResult = ReturnType<typeof useUserStatsQuery>;
export type UserStatsLazyQueryHookResult = ReturnType<typeof useUserStatsLazyQuery>;
export type UserStatsQueryResult = ApolloReactCommon.QueryResult<UserStatsQuery, UserStatsQueryVariables>;
export const StudentUsersDocument = gql`
    query StudentUsers($page: Int, $itemsPerPage: Int, $search: String, $orderBy: UsersOrderByInput) {
  users(page: $page, orderBy: $orderBy, itemsPerPage: $itemsPerPage, filter: {role: STUDENT, search: $search}) {
    data {
      ...StudentUser
    }
    meta {
      ...Meta
    }
  }
}
    ${StudentUserFragmentDoc}
${MetaFragmentDoc}`;
export type StudentUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StudentUsersQuery, StudentUsersQueryVariables>, 'query'>;

    export const StudentUsersComponent = (props: StudentUsersComponentProps) => (
      <ApolloReactComponents.Query<StudentUsersQuery, StudentUsersQueryVariables> query={StudentUsersDocument} {...props} />
    );
    

/**
 * __useStudentUsersQuery__
 *
 * To run a query within a React component, call `useStudentUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      search: // value for 'search'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useStudentUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudentUsersQuery, StudentUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<StudentUsersQuery, StudentUsersQueryVariables>(StudentUsersDocument, baseOptions);
      }
export function useStudentUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudentUsersQuery, StudentUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudentUsersQuery, StudentUsersQueryVariables>(StudentUsersDocument, baseOptions);
        }
export type StudentUsersQueryHookResult = ReturnType<typeof useStudentUsersQuery>;
export type StudentUsersLazyQueryHookResult = ReturnType<typeof useStudentUsersLazyQuery>;
export type StudentUsersQueryResult = ApolloReactCommon.QueryResult<StudentUsersQuery, StudentUsersQueryVariables>;
export const AdminUsersDocument = gql`
    query AdminUsers($page: Int, $itemsPerPage: Int, $search: String, $orderBy: UsersOrderByInput) {
  users(page: $page, orderBy: $orderBy, itemsPerPage: $itemsPerPage, filter: {role: ADMIN, search: $search}) {
    data {
      ...AdminUser
    }
    meta {
      ...Meta
    }
  }
}
    ${AdminUserFragmentDoc}
${MetaFragmentDoc}`;
export type AdminUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AdminUsersQuery, AdminUsersQueryVariables>, 'query'>;

    export const AdminUsersComponent = (props: AdminUsersComponentProps) => (
      <ApolloReactComponents.Query<AdminUsersQuery, AdminUsersQueryVariables> query={AdminUsersDocument} {...props} />
    );
    

/**
 * __useAdminUsersQuery__
 *
 * To run a query within a React component, call `useAdminUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      search: // value for 'search'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAdminUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminUsersQuery, AdminUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminUsersQuery, AdminUsersQueryVariables>(AdminUsersDocument, baseOptions);
      }
export function useAdminUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminUsersQuery, AdminUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminUsersQuery, AdminUsersQueryVariables>(AdminUsersDocument, baseOptions);
        }
export type AdminUsersQueryHookResult = ReturnType<typeof useAdminUsersQuery>;
export type AdminUsersLazyQueryHookResult = ReturnType<typeof useAdminUsersLazyQuery>;
export type AdminUsersQueryResult = ApolloReactCommon.QueryResult<AdminUsersQuery, AdminUsersQueryVariables>;
export const AdminChapterDocument = gql`
    query AdminChapter($where: ChapterWhereUniqueInput!) {
  chapter(where: $where) {
    id
    name
    index
    status
    progress
    maxPoints
    description
    totalLength
    acquiredPoints
    drafts {
      id
      isMerged
      isRejected
      name
      description
      chapter {
        id
        name
        index
        status
        progress
        maxPoints
        description
        totalLength
        acquiredPoints
      }
      author {
        id
        email
        username
      }
      icon {
        svg
        png
      }
    }
    author {
      id
      email
      username
    }
    icon {
      svg
      png
    }
  }
}
    `;
export type AdminChapterComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AdminChapterQuery, AdminChapterQueryVariables>, 'query'> & ({ variables: AdminChapterQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AdminChapterComponent = (props: AdminChapterComponentProps) => (
      <ApolloReactComponents.Query<AdminChapterQuery, AdminChapterQueryVariables> query={AdminChapterDocument} {...props} />
    );
    

/**
 * __useAdminChapterQuery__
 *
 * To run a query within a React component, call `useAdminChapterQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminChapterQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminChapterQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAdminChapterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminChapterQuery, AdminChapterQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminChapterQuery, AdminChapterQueryVariables>(AdminChapterDocument, baseOptions);
      }
export function useAdminChapterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminChapterQuery, AdminChapterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminChapterQuery, AdminChapterQueryVariables>(AdminChapterDocument, baseOptions);
        }
export type AdminChapterQueryHookResult = ReturnType<typeof useAdminChapterQuery>;
export type AdminChapterLazyQueryHookResult = ReturnType<typeof useAdminChapterLazyQuery>;
export type AdminChapterQueryResult = ApolloReactCommon.QueryResult<AdminChapterQuery, AdminChapterQueryVariables>;
export const ChapterDocument = gql`
    query Chapter($where: ChapterWhereUniqueInput!) {
  chapter(where: $where) {
    id
    name
    index
    status
    progress
    maxPoints
    description
    totalLength
    isLastActive
    acquiredPoints
    drafts {
      id
      isMerged
      isRejected
    }
    summaryTest {
      id
      maxPoints
      isCompleted
      acquiredPoints
    }
    icon {
      svg
      png
    }
    lectures {
      ...LectureForChapter
    }
  }
}
    ${LectureForChapterFragmentDoc}`;
export type ChapterComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ChapterQuery, ChapterQueryVariables>, 'query'> & ({ variables: ChapterQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ChapterComponent = (props: ChapterComponentProps) => (
      <ApolloReactComponents.Query<ChapterQuery, ChapterQueryVariables> query={ChapterDocument} {...props} />
    );
    

/**
 * __useChapterQuery__
 *
 * To run a query within a React component, call `useChapterQuery` and pass it any options that fit your needs.
 * When your component renders, `useChapterQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChapterQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useChapterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChapterQuery, ChapterQueryVariables>) {
        return ApolloReactHooks.useQuery<ChapterQuery, ChapterQueryVariables>(ChapterDocument, baseOptions);
      }
export function useChapterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChapterQuery, ChapterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChapterQuery, ChapterQueryVariables>(ChapterDocument, baseOptions);
        }
export type ChapterQueryHookResult = ReturnType<typeof useChapterQuery>;
export type ChapterLazyQueryHookResult = ReturnType<typeof useChapterLazyQuery>;
export type ChapterQueryResult = ApolloReactCommon.QueryResult<ChapterQuery, ChapterQueryVariables>;
export const ChaptersWithoutLecturesDocument = gql`
    query ChaptersWithoutLectures {
  chapters {
    id
    name
    index
    status
    maxPoints
    acquiredPoints
  }
}
    `;
export type ChaptersWithoutLecturesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ChaptersWithoutLecturesQuery, ChaptersWithoutLecturesQueryVariables>, 'query'>;

    export const ChaptersWithoutLecturesComponent = (props: ChaptersWithoutLecturesComponentProps) => (
      <ApolloReactComponents.Query<ChaptersWithoutLecturesQuery, ChaptersWithoutLecturesQueryVariables> query={ChaptersWithoutLecturesDocument} {...props} />
    );
    

/**
 * __useChaptersWithoutLecturesQuery__
 *
 * To run a query within a React component, call `useChaptersWithoutLecturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChaptersWithoutLecturesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChaptersWithoutLecturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useChaptersWithoutLecturesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChaptersWithoutLecturesQuery, ChaptersWithoutLecturesQueryVariables>) {
        return ApolloReactHooks.useQuery<ChaptersWithoutLecturesQuery, ChaptersWithoutLecturesQueryVariables>(ChaptersWithoutLecturesDocument, baseOptions);
      }
export function useChaptersWithoutLecturesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChaptersWithoutLecturesQuery, ChaptersWithoutLecturesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChaptersWithoutLecturesQuery, ChaptersWithoutLecturesQueryVariables>(ChaptersWithoutLecturesDocument, baseOptions);
        }
export type ChaptersWithoutLecturesQueryHookResult = ReturnType<typeof useChaptersWithoutLecturesQuery>;
export type ChaptersWithoutLecturesLazyQueryHookResult = ReturnType<typeof useChaptersWithoutLecturesLazyQuery>;
export type ChaptersWithoutLecturesQueryResult = ApolloReactCommon.QueryResult<ChaptersWithoutLecturesQuery, ChaptersWithoutLecturesQueryVariables>;
export const ChaptersDocument = gql`
    query Chapters {
  chapters {
    id
    name
    index
    status
    progress
    maxPoints
    description
    totalLength
    isLastActive
    acquiredPoints
    icon {
      svg
      png
    }
    lectures {
      id
      name
      status
      maxPoints
      acquiredPoints
      library {
        ... on Video {
          id
          preview
        }
      }
      test {
        id
        isChapterSummary
      }
    }
  }
}
    `;
export type ChaptersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ChaptersQuery, ChaptersQueryVariables>, 'query'>;

    export const ChaptersComponent = (props: ChaptersComponentProps) => (
      <ApolloReactComponents.Query<ChaptersQuery, ChaptersQueryVariables> query={ChaptersDocument} {...props} />
    );
    

/**
 * __useChaptersQuery__
 *
 * To run a query within a React component, call `useChaptersQuery` and pass it any options that fit your needs.
 * When your component renders, `useChaptersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChaptersQuery({
 *   variables: {
 *   },
 * });
 */
export function useChaptersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChaptersQuery, ChaptersQueryVariables>) {
        return ApolloReactHooks.useQuery<ChaptersQuery, ChaptersQueryVariables>(ChaptersDocument, baseOptions);
      }
export function useChaptersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChaptersQuery, ChaptersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChaptersQuery, ChaptersQueryVariables>(ChaptersDocument, baseOptions);
        }
export type ChaptersQueryHookResult = ReturnType<typeof useChaptersQuery>;
export type ChaptersLazyQueryHookResult = ReturnType<typeof useChaptersLazyQuery>;
export type ChaptersQueryResult = ApolloReactCommon.QueryResult<ChaptersQuery, ChaptersQueryVariables>;
export const ChapterDraftsDocument = gql`
    query ChapterDrafts {
  chapterDrafts {
    id
    chapter {
      id
      name
      index
      status
      maxPoints
      drafts {
        id
        isMerged
        isRejected
      }
      author {
        id
        email
        username
      }
      lectures {
        id
        name
        status
        maxPoints
        drafts {
          id
          isMerged
          isRejected
        }
        author {
          id
          email
          username
        }
      }
    }
  }
}
    `;
export type ChapterDraftsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ChapterDraftsQuery, ChapterDraftsQueryVariables>, 'query'>;

    export const ChapterDraftsComponent = (props: ChapterDraftsComponentProps) => (
      <ApolloReactComponents.Query<ChapterDraftsQuery, ChapterDraftsQueryVariables> query={ChapterDraftsDocument} {...props} />
    );
    

/**
 * __useChapterDraftsQuery__
 *
 * To run a query within a React component, call `useChapterDraftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChapterDraftsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChapterDraftsQuery({
 *   variables: {
 *   },
 * });
 */
export function useChapterDraftsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChapterDraftsQuery, ChapterDraftsQueryVariables>) {
        return ApolloReactHooks.useQuery<ChapterDraftsQuery, ChapterDraftsQueryVariables>(ChapterDraftsDocument, baseOptions);
      }
export function useChapterDraftsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChapterDraftsQuery, ChapterDraftsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChapterDraftsQuery, ChapterDraftsQueryVariables>(ChapterDraftsDocument, baseOptions);
        }
export type ChapterDraftsQueryHookResult = ReturnType<typeof useChapterDraftsQuery>;
export type ChapterDraftsLazyQueryHookResult = ReturnType<typeof useChapterDraftsLazyQuery>;
export type ChapterDraftsQueryResult = ApolloReactCommon.QueryResult<ChapterDraftsQuery, ChapterDraftsQueryVariables>;
export const ChaptersWithIdDocument = gql`
    query ChaptersWithId {
  chapters {
    id
    name
  }
}
    `;
export type ChaptersWithIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ChaptersWithIdQuery, ChaptersWithIdQueryVariables>, 'query'>;

    export const ChaptersWithIdComponent = (props: ChaptersWithIdComponentProps) => (
      <ApolloReactComponents.Query<ChaptersWithIdQuery, ChaptersWithIdQueryVariables> query={ChaptersWithIdDocument} {...props} />
    );
    

/**
 * __useChaptersWithIdQuery__
 *
 * To run a query within a React component, call `useChaptersWithIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useChaptersWithIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChaptersWithIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useChaptersWithIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChaptersWithIdQuery, ChaptersWithIdQueryVariables>) {
        return ApolloReactHooks.useQuery<ChaptersWithIdQuery, ChaptersWithIdQueryVariables>(ChaptersWithIdDocument, baseOptions);
      }
export function useChaptersWithIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChaptersWithIdQuery, ChaptersWithIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChaptersWithIdQuery, ChaptersWithIdQueryVariables>(ChaptersWithIdDocument, baseOptions);
        }
export type ChaptersWithIdQueryHookResult = ReturnType<typeof useChaptersWithIdQuery>;
export type ChaptersWithIdLazyQueryHookResult = ReturnType<typeof useChaptersWithIdLazyQuery>;
export type ChaptersWithIdQueryResult = ApolloReactCommon.QueryResult<ChaptersWithIdQuery, ChaptersWithIdQueryVariables>;
export const AdminChaptersDocument = gql`
    query AdminChapters {
  chapters {
    id
    name
    index
    status
    maxPoints
    drafts {
      id
      isMerged
      isRejected
    }
    author {
      id
      email
      username
    }
    lectures {
      id
      name
      status
      maxPoints
      drafts {
        id
        isMerged
        isRejected
      }
      author {
        id
        email
        username
      }
    }
  }
}
    `;
export type AdminChaptersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AdminChaptersQuery, AdminChaptersQueryVariables>, 'query'>;

    export const AdminChaptersComponent = (props: AdminChaptersComponentProps) => (
      <ApolloReactComponents.Query<AdminChaptersQuery, AdminChaptersQueryVariables> query={AdminChaptersDocument} {...props} />
    );
    

/**
 * __useAdminChaptersQuery__
 *
 * To run a query within a React component, call `useAdminChaptersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminChaptersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminChaptersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminChaptersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminChaptersQuery, AdminChaptersQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminChaptersQuery, AdminChaptersQueryVariables>(AdminChaptersDocument, baseOptions);
      }
export function useAdminChaptersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminChaptersQuery, AdminChaptersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminChaptersQuery, AdminChaptersQueryVariables>(AdminChaptersDocument, baseOptions);
        }
export type AdminChaptersQueryHookResult = ReturnType<typeof useAdminChaptersQuery>;
export type AdminChaptersLazyQueryHookResult = ReturnType<typeof useAdminChaptersLazyQuery>;
export type AdminChaptersQueryResult = ApolloReactCommon.QueryResult<AdminChaptersQuery, AdminChaptersQueryVariables>;
export const LectureDocument = gql`
    query Lecture($where: LectureWhereUniqueInput!) {
  lecture(where: $where) {
    ...Lecture
    library {
      ...Word
      ...Video
      ...Audio
      ...Transcript
    }
  }
}
    ${LectureFragmentDoc}
${WordFragmentDoc}
${VideoFragmentDoc}
${AudioFragmentDoc}
${TranscriptFragmentDoc}`;
export type LectureComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LectureQuery, LectureQueryVariables>, 'query'> & ({ variables: LectureQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const LectureComponent = (props: LectureComponentProps) => (
      <ApolloReactComponents.Query<LectureQuery, LectureQueryVariables> query={LectureDocument} {...props} />
    );
    

/**
 * __useLectureQuery__
 *
 * To run a query within a React component, call `useLectureQuery` and pass it any options that fit your needs.
 * When your component renders, `useLectureQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLectureQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLectureQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LectureQuery, LectureQueryVariables>) {
        return ApolloReactHooks.useQuery<LectureQuery, LectureQueryVariables>(LectureDocument, baseOptions);
      }
export function useLectureLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LectureQuery, LectureQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LectureQuery, LectureQueryVariables>(LectureDocument, baseOptions);
        }
export type LectureQueryHookResult = ReturnType<typeof useLectureQuery>;
export type LectureLazyQueryHookResult = ReturnType<typeof useLectureLazyQuery>;
export type LectureQueryResult = ApolloReactCommon.QueryResult<LectureQuery, LectureQueryVariables>;
export const AdminLectureDocument = gql`
    query AdminLecture($where: LectureWhereUniqueInput!) {
  lecture(where: $where) {
    ...Lecture
    author {
      id
      email
      username
    }
    drafts {
      id
      isMerged
      isRejected
      name
      summary
      description
      transcript
      chapter {
        id
        name
      }
      author {
        id
      }
      files {
        id
        gcsId
        filename
        type
        src
        preview
        previewSrc
      }
      test {
        id
        questions {
          id
          text
          description
          answers {
            id
            text
          }
        }
      }
    }
    files {
      id
      gcsId
      filename
      type
      src
      preview
      previewSrc
    }
    test {
      id
      maxPoints
      isFinalTest
      isCompleted
      isChapterSummary
      lecture {
        id
        chapter {
          id
        }
      }
      questions {
        ...Question
      }
    }
  }
}
    ${LectureFragmentDoc}
${QuestionFragmentDoc}`;
export type AdminLectureComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AdminLectureQuery, AdminLectureQueryVariables>, 'query'> & ({ variables: AdminLectureQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AdminLectureComponent = (props: AdminLectureComponentProps) => (
      <ApolloReactComponents.Query<AdminLectureQuery, AdminLectureQueryVariables> query={AdminLectureDocument} {...props} />
    );
    

/**
 * __useAdminLectureQuery__
 *
 * To run a query within a React component, call `useAdminLectureQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminLectureQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminLectureQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAdminLectureQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminLectureQuery, AdminLectureQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminLectureQuery, AdminLectureQueryVariables>(AdminLectureDocument, baseOptions);
      }
export function useAdminLectureLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminLectureQuery, AdminLectureQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminLectureQuery, AdminLectureQueryVariables>(AdminLectureDocument, baseOptions);
        }
export type AdminLectureQueryHookResult = ReturnType<typeof useAdminLectureQuery>;
export type AdminLectureLazyQueryHookResult = ReturnType<typeof useAdminLectureLazyQuery>;
export type AdminLectureQueryResult = ApolloReactCommon.QueryResult<AdminLectureQuery, AdminLectureQueryVariables>;
export const LecturesDocument = gql`
    query Lectures {
  lectures {
    id
    name
    status
    description
  }
}
    `;
export type LecturesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LecturesQuery, LecturesQueryVariables>, 'query'>;

    export const LecturesComponent = (props: LecturesComponentProps) => (
      <ApolloReactComponents.Query<LecturesQuery, LecturesQueryVariables> query={LecturesDocument} {...props} />
    );
    

/**
 * __useLecturesQuery__
 *
 * To run a query within a React component, call `useLecturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLecturesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLecturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLecturesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LecturesQuery, LecturesQueryVariables>) {
        return ApolloReactHooks.useQuery<LecturesQuery, LecturesQueryVariables>(LecturesDocument, baseOptions);
      }
export function useLecturesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LecturesQuery, LecturesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LecturesQuery, LecturesQueryVariables>(LecturesDocument, baseOptions);
        }
export type LecturesQueryHookResult = ReturnType<typeof useLecturesQuery>;
export type LecturesLazyQueryHookResult = ReturnType<typeof useLecturesLazyQuery>;
export type LecturesQueryResult = ApolloReactCommon.QueryResult<LecturesQuery, LecturesQueryVariables>;
export const SecurityQuestionDocument = gql`
    query SecurityQuestion {
  securityQuestions {
    id
    text
  }
}
    `;
export type SecurityQuestionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SecurityQuestionQuery, SecurityQuestionQueryVariables>, 'query'>;

    export const SecurityQuestionComponent = (props: SecurityQuestionComponentProps) => (
      <ApolloReactComponents.Query<SecurityQuestionQuery, SecurityQuestionQueryVariables> query={SecurityQuestionDocument} {...props} />
    );
    

/**
 * __useSecurityQuestionQuery__
 *
 * To run a query within a React component, call `useSecurityQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecurityQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecurityQuestionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSecurityQuestionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SecurityQuestionQuery, SecurityQuestionQueryVariables>) {
        return ApolloReactHooks.useQuery<SecurityQuestionQuery, SecurityQuestionQueryVariables>(SecurityQuestionDocument, baseOptions);
      }
export function useSecurityQuestionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SecurityQuestionQuery, SecurityQuestionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SecurityQuestionQuery, SecurityQuestionQueryVariables>(SecurityQuestionDocument, baseOptions);
        }
export type SecurityQuestionQueryHookResult = ReturnType<typeof useSecurityQuestionQuery>;
export type SecurityQuestionLazyQueryHookResult = ReturnType<typeof useSecurityQuestionLazyQuery>;
export type SecurityQuestionQueryResult = ApolloReactCommon.QueryResult<SecurityQuestionQuery, SecurityQuestionQueryVariables>;
export const UserSecurityAnswersDocument = gql`
    query UserSecurityAnswers($username: String!) {
  userSecurityAnswers(username: $username) {
    id
    question {
      id
      text
    }
  }
}
    `;
export type UserSecurityAnswersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserSecurityAnswersQuery, UserSecurityAnswersQueryVariables>, 'query'> & ({ variables: UserSecurityAnswersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserSecurityAnswersComponent = (props: UserSecurityAnswersComponentProps) => (
      <ApolloReactComponents.Query<UserSecurityAnswersQuery, UserSecurityAnswersQueryVariables> query={UserSecurityAnswersDocument} {...props} />
    );
    

/**
 * __useUserSecurityAnswersQuery__
 *
 * To run a query within a React component, call `useUserSecurityAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSecurityAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSecurityAnswersQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUserSecurityAnswersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserSecurityAnswersQuery, UserSecurityAnswersQueryVariables>) {
        return ApolloReactHooks.useQuery<UserSecurityAnswersQuery, UserSecurityAnswersQueryVariables>(UserSecurityAnswersDocument, baseOptions);
      }
export function useUserSecurityAnswersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserSecurityAnswersQuery, UserSecurityAnswersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserSecurityAnswersQuery, UserSecurityAnswersQueryVariables>(UserSecurityAnswersDocument, baseOptions);
        }
export type UserSecurityAnswersQueryHookResult = ReturnType<typeof useUserSecurityAnswersQuery>;
export type UserSecurityAnswersLazyQueryHookResult = ReturnType<typeof useUserSecurityAnswersLazyQuery>;
export type UserSecurityAnswersQueryResult = ApolloReactCommon.QueryResult<UserSecurityAnswersQuery, UserSecurityAnswersQueryVariables>;
export const CurrentTestStepDocument = gql`
    query CurrentTestStep($session: TestSessionWhereUniqueInput!) {
  currentTestStep(session: $session) {
    ...Step
  }
}
    ${StepFragmentDoc}`;
export type CurrentTestStepComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CurrentTestStepQuery, CurrentTestStepQueryVariables>, 'query'> & ({ variables: CurrentTestStepQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CurrentTestStepComponent = (props: CurrentTestStepComponentProps) => (
      <ApolloReactComponents.Query<CurrentTestStepQuery, CurrentTestStepQueryVariables> query={CurrentTestStepDocument} {...props} />
    );
    

/**
 * __useCurrentTestStepQuery__
 *
 * To run a query within a React component, call `useCurrentTestStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentTestStepQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentTestStepQuery({
 *   variables: {
 *      session: // value for 'session'
 *   },
 * });
 */
export function useCurrentTestStepQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentTestStepQuery, CurrentTestStepQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentTestStepQuery, CurrentTestStepQueryVariables>(CurrentTestStepDocument, baseOptions);
      }
export function useCurrentTestStepLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentTestStepQuery, CurrentTestStepQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentTestStepQuery, CurrentTestStepQueryVariables>(CurrentTestStepDocument, baseOptions);
        }
export type CurrentTestStepQueryHookResult = ReturnType<typeof useCurrentTestStepQuery>;
export type CurrentTestStepLazyQueryHookResult = ReturnType<typeof useCurrentTestStepLazyQuery>;
export type CurrentTestStepQueryResult = ApolloReactCommon.QueryResult<CurrentTestStepQuery, CurrentTestStepQueryVariables>;
export const ValidatorsDocument = gql`
    query Validators {
  validators {
    email {
      description
      regex
    }
    username {
      description
      regex
    }
    password {
      description
      regex
    }
  }
}
    `;
export type ValidatorsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ValidatorsQuery, ValidatorsQueryVariables>, 'query'>;

    export const ValidatorsComponent = (props: ValidatorsComponentProps) => (
      <ApolloReactComponents.Query<ValidatorsQuery, ValidatorsQueryVariables> query={ValidatorsDocument} {...props} />
    );
    

/**
 * __useValidatorsQuery__
 *
 * To run a query within a React component, call `useValidatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidatorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useValidatorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ValidatorsQuery, ValidatorsQueryVariables>) {
        return ApolloReactHooks.useQuery<ValidatorsQuery, ValidatorsQueryVariables>(ValidatorsDocument, baseOptions);
      }
export function useValidatorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ValidatorsQuery, ValidatorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ValidatorsQuery, ValidatorsQueryVariables>(ValidatorsDocument, baseOptions);
        }
export type ValidatorsQueryHookResult = ReturnType<typeof useValidatorsQuery>;
export type ValidatorsLazyQueryHookResult = ReturnType<typeof useValidatorsLazyQuery>;
export type ValidatorsQueryResult = ApolloReactCommon.QueryResult<ValidatorsQuery, ValidatorsQueryVariables>;
export const DailyQuestionDocument = gql`
    query DailyQuestion {
  dailyQuestion {
    question {
      text
      id
    }
    answers {
      text
      id
    }
  }
}
    `;
export type DailyQuestionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DailyQuestionQuery, DailyQuestionQueryVariables>, 'query'>;

    export const DailyQuestionComponent = (props: DailyQuestionComponentProps) => (
      <ApolloReactComponents.Query<DailyQuestionQuery, DailyQuestionQueryVariables> query={DailyQuestionDocument} {...props} />
    );
    

/**
 * __useDailyQuestionQuery__
 *
 * To run a query within a React component, call `useDailyQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyQuestionQuery({
 *   variables: {
 *   },
 * });
 */
export function useDailyQuestionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DailyQuestionQuery, DailyQuestionQueryVariables>) {
        return ApolloReactHooks.useQuery<DailyQuestionQuery, DailyQuestionQueryVariables>(DailyQuestionDocument, baseOptions);
      }
export function useDailyQuestionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DailyQuestionQuery, DailyQuestionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DailyQuestionQuery, DailyQuestionQueryVariables>(DailyQuestionDocument, baseOptions);
        }
export type DailyQuestionQueryHookResult = ReturnType<typeof useDailyQuestionQuery>;
export type DailyQuestionLazyQueryHookResult = ReturnType<typeof useDailyQuestionLazyQuery>;
export type DailyQuestionQueryResult = ApolloReactCommon.QueryResult<DailyQuestionQuery, DailyQuestionQueryVariables>;
export const LibraryDocument = gql`
    query Library($filter: LibraryFilter, $itemsPerPage: Int, $page: Int) {
  library(filter: $filter, itemsPerPage: $itemsPerPage, page: $page) {
    data {
      ... on Video {
        id
        src
        name
        length
        preview
        lecture {
          id
          chapter {
            id
            name
          }
        }
      }
      ... on Audio {
        id
        src
        name
        length
      }
      ... on Transcript {
        id
        name
        text
      }
      ... on Word {
        id
        word
        description
      }
    }
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}`;
export type LibraryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LibraryQuery, LibraryQueryVariables>, 'query'>;

    export const LibraryComponent = (props: LibraryComponentProps) => (
      <ApolloReactComponents.Query<LibraryQuery, LibraryQueryVariables> query={LibraryDocument} {...props} />
    );
    

/**
 * __useLibraryQuery__
 *
 * To run a query within a React component, call `useLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibraryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLibraryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LibraryQuery, LibraryQueryVariables>) {
        return ApolloReactHooks.useQuery<LibraryQuery, LibraryQueryVariables>(LibraryDocument, baseOptions);
      }
export function useLibraryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LibraryQuery, LibraryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LibraryQuery, LibraryQueryVariables>(LibraryDocument, baseOptions);
        }
export type LibraryQueryHookResult = ReturnType<typeof useLibraryQuery>;
export type LibraryLazyQueryHookResult = ReturnType<typeof useLibraryLazyQuery>;
export type LibraryQueryResult = ApolloReactCommon.QueryResult<LibraryQuery, LibraryQueryVariables>;
export const AdminLibraryDocument = gql`
    query AdminLibrary($filter: LibraryFilter, $itemsPerPage: Int, $page: Int, $orderBy: BatchLibraryOrderByInput) {
  library(filter: $filter, itemsPerPage: $itemsPerPage, page: $page, orderBy: $orderBy) {
    data {
      ... on Word {
        ...Word
        author {
          id
          email
          username
        }
      }
      ...Video
      ...Audio
      ...Transcript
    }
    meta {
      ...Meta
    }
  }
}
    ${WordFragmentDoc}
${VideoFragmentDoc}
${AudioFragmentDoc}
${TranscriptFragmentDoc}
${MetaFragmentDoc}`;
export type AdminLibraryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AdminLibraryQuery, AdminLibraryQueryVariables>, 'query'>;

    export const AdminLibraryComponent = (props: AdminLibraryComponentProps) => (
      <ApolloReactComponents.Query<AdminLibraryQuery, AdminLibraryQueryVariables> query={AdminLibraryDocument} {...props} />
    );
    

/**
 * __useAdminLibraryQuery__
 *
 * To run a query within a React component, call `useAdminLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminLibraryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      page: // value for 'page'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAdminLibraryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminLibraryQuery, AdminLibraryQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminLibraryQuery, AdminLibraryQueryVariables>(AdminLibraryDocument, baseOptions);
      }
export function useAdminLibraryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminLibraryQuery, AdminLibraryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminLibraryQuery, AdminLibraryQueryVariables>(AdminLibraryDocument, baseOptions);
        }
export type AdminLibraryQueryHookResult = ReturnType<typeof useAdminLibraryQuery>;
export type AdminLibraryLazyQueryHookResult = ReturnType<typeof useAdminLibraryLazyQuery>;
export type AdminLibraryQueryResult = ApolloReactCommon.QueryResult<AdminLibraryQuery, AdminLibraryQueryVariables>;
export const LibraryVideoItemDocument = gql`
    query LibraryVideoItem($where: LibraryItemWhereInput!) {
  libraryItem(where: $where) {
    ... on Video {
      id
      name
      src
      length
      preview
      lecture {
        id
        name
        chapter {
          id
          name
        }
      }
      similarItems {
        ... on Video {
          id
          name
          src
          length
          preview
          lecture {
            id
            name
            chapter {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;
export type LibraryVideoItemComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LibraryVideoItemQuery, LibraryVideoItemQueryVariables>, 'query'> & ({ variables: LibraryVideoItemQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const LibraryVideoItemComponent = (props: LibraryVideoItemComponentProps) => (
      <ApolloReactComponents.Query<LibraryVideoItemQuery, LibraryVideoItemQueryVariables> query={LibraryVideoItemDocument} {...props} />
    );
    

/**
 * __useLibraryVideoItemQuery__
 *
 * To run a query within a React component, call `useLibraryVideoItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibraryVideoItemQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibraryVideoItemQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLibraryVideoItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LibraryVideoItemQuery, LibraryVideoItemQueryVariables>) {
        return ApolloReactHooks.useQuery<LibraryVideoItemQuery, LibraryVideoItemQueryVariables>(LibraryVideoItemDocument, baseOptions);
      }
export function useLibraryVideoItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LibraryVideoItemQuery, LibraryVideoItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LibraryVideoItemQuery, LibraryVideoItemQueryVariables>(LibraryVideoItemDocument, baseOptions);
        }
export type LibraryVideoItemQueryHookResult = ReturnType<typeof useLibraryVideoItemQuery>;
export type LibraryVideoItemLazyQueryHookResult = ReturnType<typeof useLibraryVideoItemLazyQuery>;
export type LibraryVideoItemQueryResult = ApolloReactCommon.QueryResult<LibraryVideoItemQuery, LibraryVideoItemQueryVariables>;
export const LibraryAudioItemDocument = gql`
    query LibraryAudioItem($where: LibraryItemWhereInput!) {
  libraryItem(where: $where) {
    ... on Audio {
      id
      src
      name
      length
      lecture {
        id
        chapter {
          id
          name
        }
      }
      similarItems {
        ... on Audio {
          id
          src
          name
        }
      }
    }
  }
}
    `;
export type LibraryAudioItemComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LibraryAudioItemQuery, LibraryAudioItemQueryVariables>, 'query'> & ({ variables: LibraryAudioItemQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const LibraryAudioItemComponent = (props: LibraryAudioItemComponentProps) => (
      <ApolloReactComponents.Query<LibraryAudioItemQuery, LibraryAudioItemQueryVariables> query={LibraryAudioItemDocument} {...props} />
    );
    

/**
 * __useLibraryAudioItemQuery__
 *
 * To run a query within a React component, call `useLibraryAudioItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibraryAudioItemQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibraryAudioItemQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLibraryAudioItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LibraryAudioItemQuery, LibraryAudioItemQueryVariables>) {
        return ApolloReactHooks.useQuery<LibraryAudioItemQuery, LibraryAudioItemQueryVariables>(LibraryAudioItemDocument, baseOptions);
      }
export function useLibraryAudioItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LibraryAudioItemQuery, LibraryAudioItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LibraryAudioItemQuery, LibraryAudioItemQueryVariables>(LibraryAudioItemDocument, baseOptions);
        }
export type LibraryAudioItemQueryHookResult = ReturnType<typeof useLibraryAudioItemQuery>;
export type LibraryAudioItemLazyQueryHookResult = ReturnType<typeof useLibraryAudioItemLazyQuery>;
export type LibraryAudioItemQueryResult = ApolloReactCommon.QueryResult<LibraryAudioItemQuery, LibraryAudioItemQueryVariables>;
export const LibraryTranscriptItemDocument = gql`
    query LibraryTranscriptItem($where: LibraryItemWhereInput!) {
  transcript: libraryItem(where: $where) {
    ... on Transcript {
      id
      name
      text
      lecture {
        id
        name
        chapter {
          id
          name
        }
      }
      similarItems {
        ... on Transcript {
          id
          name
          text
          lecture {
            id
            name
            chapter {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;
export type LibraryTranscriptItemComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LibraryTranscriptItemQuery, LibraryTranscriptItemQueryVariables>, 'query'> & ({ variables: LibraryTranscriptItemQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const LibraryTranscriptItemComponent = (props: LibraryTranscriptItemComponentProps) => (
      <ApolloReactComponents.Query<LibraryTranscriptItemQuery, LibraryTranscriptItemQueryVariables> query={LibraryTranscriptItemDocument} {...props} />
    );
    

/**
 * __useLibraryTranscriptItemQuery__
 *
 * To run a query within a React component, call `useLibraryTranscriptItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibraryTranscriptItemQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibraryTranscriptItemQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLibraryTranscriptItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LibraryTranscriptItemQuery, LibraryTranscriptItemQueryVariables>) {
        return ApolloReactHooks.useQuery<LibraryTranscriptItemQuery, LibraryTranscriptItemQueryVariables>(LibraryTranscriptItemDocument, baseOptions);
      }
export function useLibraryTranscriptItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LibraryTranscriptItemQuery, LibraryTranscriptItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LibraryTranscriptItemQuery, LibraryTranscriptItemQueryVariables>(LibraryTranscriptItemDocument, baseOptions);
        }
export type LibraryTranscriptItemQueryHookResult = ReturnType<typeof useLibraryTranscriptItemQuery>;
export type LibraryTranscriptItemLazyQueryHookResult = ReturnType<typeof useLibraryTranscriptItemLazyQuery>;
export type LibraryTranscriptItemQueryResult = ApolloReactCommon.QueryResult<LibraryTranscriptItemQuery, LibraryTranscriptItemQueryVariables>;
export const LibraryWordItemDocument = gql`
    query LibraryWordItem($where: LibraryItemWhereInput!) {
  libraryItem(where: $where) {
    ...Word
  }
}
    ${WordFragmentDoc}`;
export type LibraryWordItemComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LibraryWordItemQuery, LibraryWordItemQueryVariables>, 'query'> & ({ variables: LibraryWordItemQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const LibraryWordItemComponent = (props: LibraryWordItemComponentProps) => (
      <ApolloReactComponents.Query<LibraryWordItemQuery, LibraryWordItemQueryVariables> query={LibraryWordItemDocument} {...props} />
    );
    

/**
 * __useLibraryWordItemQuery__
 *
 * To run a query within a React component, call `useLibraryWordItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibraryWordItemQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibraryWordItemQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLibraryWordItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LibraryWordItemQuery, LibraryWordItemQueryVariables>) {
        return ApolloReactHooks.useQuery<LibraryWordItemQuery, LibraryWordItemQueryVariables>(LibraryWordItemDocument, baseOptions);
      }
export function useLibraryWordItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LibraryWordItemQuery, LibraryWordItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LibraryWordItemQuery, LibraryWordItemQueryVariables>(LibraryWordItemDocument, baseOptions);
        }
export type LibraryWordItemQueryHookResult = ReturnType<typeof useLibraryWordItemQuery>;
export type LibraryWordItemLazyQueryHookResult = ReturnType<typeof useLibraryWordItemLazyQuery>;
export type LibraryWordItemQueryResult = ApolloReactCommon.QueryResult<LibraryWordItemQuery, LibraryWordItemQueryVariables>;
export const FinalTestDocument = gql`
    query FinalTest {
  finalTest {
    id
    maxPoints
    acquiredPoints
    isFinalTestCompleted
  }
}
    `;
export type FinalTestComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FinalTestQuery, FinalTestQueryVariables>, 'query'>;

    export const FinalTestComponent = (props: FinalTestComponentProps) => (
      <ApolloReactComponents.Query<FinalTestQuery, FinalTestQueryVariables> query={FinalTestDocument} {...props} />
    );
    

/**
 * __useFinalTestQuery__
 *
 * To run a query within a React component, call `useFinalTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinalTestQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinalTestQuery({
 *   variables: {
 *   },
 * });
 */
export function useFinalTestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FinalTestQuery, FinalTestQueryVariables>) {
        return ApolloReactHooks.useQuery<FinalTestQuery, FinalTestQueryVariables>(FinalTestDocument, baseOptions);
      }
export function useFinalTestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FinalTestQuery, FinalTestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FinalTestQuery, FinalTestQueryVariables>(FinalTestDocument, baseOptions);
        }
export type FinalTestQueryHookResult = ReturnType<typeof useFinalTestQuery>;
export type FinalTestLazyQueryHookResult = ReturnType<typeof useFinalTestLazyQuery>;
export type FinalTestQueryResult = ApolloReactCommon.QueryResult<FinalTestQuery, FinalTestQueryVariables>;
export const BadgesDocument = gql`
    query Badges {
  badges {
    ...Badges
  }
}
    ${BadgesFragmentDoc}`;
export type BadgesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BadgesQuery, BadgesQueryVariables>, 'query'>;

    export const BadgesComponent = (props: BadgesComponentProps) => (
      <ApolloReactComponents.Query<BadgesQuery, BadgesQueryVariables> query={BadgesDocument} {...props} />
    );
    

/**
 * __useBadgesQuery__
 *
 * To run a query within a React component, call `useBadgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBadgesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBadgesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBadgesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BadgesQuery, BadgesQueryVariables>) {
        return ApolloReactHooks.useQuery<BadgesQuery, BadgesQueryVariables>(BadgesDocument, baseOptions);
      }
export function useBadgesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BadgesQuery, BadgesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BadgesQuery, BadgesQueryVariables>(BadgesDocument, baseOptions);
        }
export type BadgesQueryHookResult = ReturnType<typeof useBadgesQuery>;
export type BadgesLazyQueryHookResult = ReturnType<typeof useBadgesLazyQuery>;
export type BadgesQueryResult = ApolloReactCommon.QueryResult<BadgesQuery, BadgesQueryVariables>;
export const UnacceptedBadgesDocument = gql`
    query UnacceptedBadges {
  unacceptedBadges {
    id
    isAccepted
    badge {
      ...Badge
    }
  }
}
    ${BadgeFragmentDoc}`;
export type UnacceptedBadgesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UnacceptedBadgesQuery, UnacceptedBadgesQueryVariables>, 'query'>;

    export const UnacceptedBadgesComponent = (props: UnacceptedBadgesComponentProps) => (
      <ApolloReactComponents.Query<UnacceptedBadgesQuery, UnacceptedBadgesQueryVariables> query={UnacceptedBadgesDocument} {...props} />
    );
    

/**
 * __useUnacceptedBadgesQuery__
 *
 * To run a query within a React component, call `useUnacceptedBadgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnacceptedBadgesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnacceptedBadgesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnacceptedBadgesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnacceptedBadgesQuery, UnacceptedBadgesQueryVariables>) {
        return ApolloReactHooks.useQuery<UnacceptedBadgesQuery, UnacceptedBadgesQueryVariables>(UnacceptedBadgesDocument, baseOptions);
      }
export function useUnacceptedBadgesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnacceptedBadgesQuery, UnacceptedBadgesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UnacceptedBadgesQuery, UnacceptedBadgesQueryVariables>(UnacceptedBadgesDocument, baseOptions);
        }
export type UnacceptedBadgesQueryHookResult = ReturnType<typeof useUnacceptedBadgesQuery>;
export type UnacceptedBadgesLazyQueryHookResult = ReturnType<typeof useUnacceptedBadgesLazyQuery>;
export type UnacceptedBadgesQueryResult = ApolloReactCommon.QueryResult<UnacceptedBadgesQuery, UnacceptedBadgesQueryVariables>;
export const UserActivityDocument = gql`
    query UserActivity($page: Int, $itemsPerPage: Int, $filter: LogWhereInput) {
  userActivity(page: $page, itemsPerPage: $itemsPerPage, filter: $filter) {
    data {
      id
      entity
      activity
      createdAt
      user {
        id
        email
        role
        username
      }
      data {
        ... on CustomNotification {
          title
        }
        ... on Answer {
          text
        }
        ... on Chapter {
          name
        }
        ... on Lecture {
          name
        }
        ... on Question {
          text
        }
        ... on Test {
          lecture {
            name
          }
        }
        ... on User {
          username
          email: email
          role
          id
        }
        ... on Word {
          word
        }
        ... on Document {
          name
        }
      }
    }
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}`;
export type UserActivityComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserActivityQuery, UserActivityQueryVariables>, 'query'>;

    export const UserActivityComponent = (props: UserActivityComponentProps) => (
      <ApolloReactComponents.Query<UserActivityQuery, UserActivityQueryVariables> query={UserActivityDocument} {...props} />
    );
    

/**
 * __useUserActivityQuery__
 *
 * To run a query within a React component, call `useUserActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserActivityQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUserActivityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserActivityQuery, UserActivityQueryVariables>) {
        return ApolloReactHooks.useQuery<UserActivityQuery, UserActivityQueryVariables>(UserActivityDocument, baseOptions);
      }
export function useUserActivityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserActivityQuery, UserActivityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserActivityQuery, UserActivityQueryVariables>(UserActivityDocument, baseOptions);
        }
export type UserActivityQueryHookResult = ReturnType<typeof useUserActivityQuery>;
export type UserActivityLazyQueryHookResult = ReturnType<typeof useUserActivityLazyQuery>;
export type UserActivityQueryResult = ApolloReactCommon.QueryResult<UserActivityQuery, UserActivityQueryVariables>;
export const StudentActivityDocument = gql`
    query StudentActivity($filter: StudentActivityFilter, $page: Int, $itemsPerPage: Int) {
  studentActivity(filter: $filter, page: $page, itemsPerPage: $itemsPerPage) {
    data {
      type
      points
      createdAt
      testName
      username
      answers {
        wrong
        correct
      }
    }
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}`;
export type StudentActivityComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StudentActivityQuery, StudentActivityQueryVariables>, 'query'>;

    export const StudentActivityComponent = (props: StudentActivityComponentProps) => (
      <ApolloReactComponents.Query<StudentActivityQuery, StudentActivityQueryVariables> query={StudentActivityDocument} {...props} />
    );
    

/**
 * __useStudentActivityQuery__
 *
 * To run a query within a React component, call `useStudentActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentActivityQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *   },
 * });
 */
export function useStudentActivityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudentActivityQuery, StudentActivityQueryVariables>) {
        return ApolloReactHooks.useQuery<StudentActivityQuery, StudentActivityQueryVariables>(StudentActivityDocument, baseOptions);
      }
export function useStudentActivityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudentActivityQuery, StudentActivityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudentActivityQuery, StudentActivityQueryVariables>(StudentActivityDocument, baseOptions);
        }
export type StudentActivityQueryHookResult = ReturnType<typeof useStudentActivityQuery>;
export type StudentActivityLazyQueryHookResult = ReturnType<typeof useStudentActivityLazyQuery>;
export type StudentActivityQueryResult = ApolloReactCommon.QueryResult<StudentActivityQuery, StudentActivityQueryVariables>;
export const NotificationsDocument = gql`
    query Notifications {
  notifications {
    data {
      id
      name
      type
      body
      title
      createdAt
      icon {
        png
        svg
      }
    }
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}`;
export type NotificationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NotificationsQuery, NotificationsQueryVariables>, 'query'>;

    export const NotificationsComponent = (props: NotificationsComponentProps) => (
      <ApolloReactComponents.Query<NotificationsQuery, NotificationsQueryVariables> query={NotificationsDocument} {...props} />
    );
    

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        return ApolloReactHooks.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, baseOptions);
      }
export function useNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, baseOptions);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = ApolloReactCommon.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const CustomNotificationsDocument = gql`
    query CustomNotifications($page: Int, $itemsPerPage: Int, $filter: CustomNotificationFilter, $orderBy: BatchCustomNotificationsOrderByInput) {
  customNotifications(page: $page, filter: $filter, orderBy: $orderBy, itemsPerPage: $itemsPerPage) {
    data {
      id
      title
      runAt
      body
      repeat
      status
      author {
        id
        role
        email
        username
      }
    }
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}`;
export type CustomNotificationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CustomNotificationsQuery, CustomNotificationsQueryVariables>, 'query'>;

    export const CustomNotificationsComponent = (props: CustomNotificationsComponentProps) => (
      <ApolloReactComponents.Query<CustomNotificationsQuery, CustomNotificationsQueryVariables> query={CustomNotificationsDocument} {...props} />
    );
    

/**
 * __useCustomNotificationsQuery__
 *
 * To run a query within a React component, call `useCustomNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomNotificationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCustomNotificationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomNotificationsQuery, CustomNotificationsQueryVariables>) {
        return ApolloReactHooks.useQuery<CustomNotificationsQuery, CustomNotificationsQueryVariables>(CustomNotificationsDocument, baseOptions);
      }
export function useCustomNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomNotificationsQuery, CustomNotificationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CustomNotificationsQuery, CustomNotificationsQueryVariables>(CustomNotificationsDocument, baseOptions);
        }
export type CustomNotificationsQueryHookResult = ReturnType<typeof useCustomNotificationsQuery>;
export type CustomNotificationsLazyQueryHookResult = ReturnType<typeof useCustomNotificationsLazyQuery>;
export type CustomNotificationsQueryResult = ApolloReactCommon.QueryResult<CustomNotificationsQuery, CustomNotificationsQueryVariables>;
export const CustomNotificationDocument = gql`
    query CustomNotification($where: CustomNotificationWhereUniqueInput!) {
  customNotification(where: $where) {
    id
    title
    runAt
    body
    repeat
    status
    author {
      id
      role
    }
  }
}
    `;
export type CustomNotificationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CustomNotificationQuery, CustomNotificationQueryVariables>, 'query'> & ({ variables: CustomNotificationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CustomNotificationComponent = (props: CustomNotificationComponentProps) => (
      <ApolloReactComponents.Query<CustomNotificationQuery, CustomNotificationQueryVariables> query={CustomNotificationDocument} {...props} />
    );
    

/**
 * __useCustomNotificationQuery__
 *
 * To run a query within a React component, call `useCustomNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomNotificationQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCustomNotificationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomNotificationQuery, CustomNotificationQueryVariables>) {
        return ApolloReactHooks.useQuery<CustomNotificationQuery, CustomNotificationQueryVariables>(CustomNotificationDocument, baseOptions);
      }
export function useCustomNotificationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomNotificationQuery, CustomNotificationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CustomNotificationQuery, CustomNotificationQueryVariables>(CustomNotificationDocument, baseOptions);
        }
export type CustomNotificationQueryHookResult = ReturnType<typeof useCustomNotificationQuery>;
export type CustomNotificationLazyQueryHookResult = ReturnType<typeof useCustomNotificationLazyQuery>;
export type CustomNotificationQueryResult = ApolloReactCommon.QueryResult<CustomNotificationQuery, CustomNotificationQueryVariables>;
export const LeaderboardDocument = gql`
    query Leaderboard($page: Int, $itemsPerPage: Int, $filter: [LeaderboardFilterEnum!]) {
  leaderboard(page: $page, itemsPerPage: $itemsPerPage, filter: $filter) {
    data {
      profilePicture
      username
      points
      rank
    }
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}`;
export type LeaderboardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LeaderboardQuery, LeaderboardQueryVariables>, 'query'>;

    export const LeaderboardComponent = (props: LeaderboardComponentProps) => (
      <ApolloReactComponents.Query<LeaderboardQuery, LeaderboardQueryVariables> query={LeaderboardDocument} {...props} />
    );
    

/**
 * __useLeaderboardQuery__
 *
 * To run a query within a React component, call `useLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaderboardQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLeaderboardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LeaderboardQuery, LeaderboardQueryVariables>) {
        return ApolloReactHooks.useQuery<LeaderboardQuery, LeaderboardQueryVariables>(LeaderboardDocument, baseOptions);
      }
export function useLeaderboardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LeaderboardQuery, LeaderboardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LeaderboardQuery, LeaderboardQueryVariables>(LeaderboardDocument, baseOptions);
        }
export type LeaderboardQueryHookResult = ReturnType<typeof useLeaderboardQuery>;
export type LeaderboardLazyQueryHookResult = ReturnType<typeof useLeaderboardLazyQuery>;
export type LeaderboardQueryResult = ApolloReactCommon.QueryResult<LeaderboardQuery, LeaderboardQueryVariables>;
export const DistrictsDocument = gql`
    query Districts {
  districts {
    id
    name
  }
}
    `;
export type DistrictsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DistrictsQuery, DistrictsQueryVariables>, 'query'>;

    export const DistrictsComponent = (props: DistrictsComponentProps) => (
      <ApolloReactComponents.Query<DistrictsQuery, DistrictsQueryVariables> query={DistrictsDocument} {...props} />
    );
    

/**
 * __useDistrictsQuery__
 *
 * To run a query within a React component, call `useDistrictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDistrictsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DistrictsQuery, DistrictsQueryVariables>) {
        return ApolloReactHooks.useQuery<DistrictsQuery, DistrictsQueryVariables>(DistrictsDocument, baseOptions);
      }
export function useDistrictsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DistrictsQuery, DistrictsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DistrictsQuery, DistrictsQueryVariables>(DistrictsDocument, baseOptions);
        }
export type DistrictsQueryHookResult = ReturnType<typeof useDistrictsQuery>;
export type DistrictsLazyQueryHookResult = ReturnType<typeof useDistrictsLazyQuery>;
export type DistrictsQueryResult = ApolloReactCommon.QueryResult<DistrictsQuery, DistrictsQueryVariables>;