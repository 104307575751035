import { useSnackbar } from 'notistack'
import { Edit } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import React, { forwardRef, useContext } from 'react'
import MaterialTable, { MTableHeader } from 'material-table'

import * as loco from '@loco'
import Row from '../../../shared/Row'
import Pagination from '../../Pagination'
import { AuthContext } from '../../../../context/Auth'
import { tableIcons, headerStyle } from '../../../shared'
import { getRouteWithId, getProfileName, translateGQLError } from '@utils'
import { tableLocalization, Routes, notificationMsgs } from '@variables'
import {
    Role,
    WordFragment,
    OrderByDirectionInput,
    useDeleteWordMutation,
    BatchLibraryOrderByColumnInput
} from '@graphql'

type Word = WordFragment & { author: { id: string; email: string } }

type Props = {
    page: number
    title: string
    pageSize: number
    loading: boolean
    totalCount: number
    refetch: () => void
    data: Array<Word>
    onChangePage: (page: number) => void
    onChangeItemsPerPage: (page: number) => void
    onSearchChange: (searchQuery: string) => void
    sortedColumnDirection: OrderByDirectionInput
    sortedColumnId: BatchLibraryOrderByColumnInput
    handleSortOrderChange: (orderedColumnId: number, orderDirection: OrderByDirectionInput) => void
}

const VocabularyTable = ({
    data,
    page,
    title,
    loading,
    pageSize,
    totalCount,
    onChangePage,
    onSearchChange,
    onChangeItemsPerPage,
    handleSortOrderChange,
    sortedColumnDirection,
    sortedColumnId,
    refetch
}: Props) => {
    const history = useHistory()
    const { user } = useContext(AuthContext)
    const { enqueueSnackbar } = useSnackbar()

    const [deleteWord, { loading: deleteLoading }] = useDeleteWordMutation()

    const onDelete = async (newWord: Word) => {
        if (deleteLoading || loading) return

        if (newWord?.author?.id !== user?.id && user?.role !== Role.SUPER_ADMIN) {
            enqueueSnackbar(loco.notifications.notAllowed, { variant: 'info' })
            return
        }

        const { data, errors } = await deleteWord({
            variables: { where: { id: newWord.id } }
        })

        if (data && !errors) {
            refetch()
            enqueueSnackbar(notificationMsgs.update, { variant: 'success' })
        } else {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }
    }

    return (
        <MaterialTable
            data={data}
            page={page}
            title={title}
            columns={columns}
            icons={tableIcons}
            totalCount={totalCount}
            onChangePage={onChangePage}
            onSearchChange={onSearchChange}
            localization={tableLocalization}
            isLoading={loading || deleteLoading}
            editable={{ onRowDelete: onDelete }}
            onChangeRowsPerPage={onChangeItemsPerPage}
            options={{
                actionsColumnIndex: -1,
                debounceInterval: 500,
                thirdSortClick: false,
                pageSize: pageSize,
                draggable: false
            }}
            components={{
                Header: (props) =>
                    (
                        <MTableHeader
                            {...props}
                            onOrderChange={handleSortOrderChange}
                            orderDirection={sortedColumnDirection}
                            orderBy={sortedColumnId === BatchLibraryOrderByColumnInput.word ? 0 : 1}
                        />
                    ) as any,
                Pagination: Pagination
            }}
            actions={[
                (editRow: Word) => ({
                    tooltip: loco.common.edit,
                    onClick: (e) => {
                        history.push(getRouteWithId(Routes.VOCABULARY_EDIT, editRow.id))
                    },
                    icon: forwardRef(function icon(props, ref: any) {
                        return <Edit style={{ color: '#666666' }} ref={ref} {...props} />
                    }) as any
                })
            ]}
        />
    )
}

const columns = [
    {
        field: 'word',
        headerStyle: { ...headerStyle },
        title: loco.admin.table.vocabulary.word
    },
    {
        field: 'author',
        headerStyle: { ...headerStyle, width: 250 },
        title: loco.admin.table.lecturesAndChapters.author,
        render: function render(row: Word) {
            return <Row maxChars={30} text={getProfileName(row.author)} />
        }
    }
]

export default VocabularyTable
