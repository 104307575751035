import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { DebounceInput } from 'react-debounce-input'

import { media, colors } from '@variables'
import Icon from '../../../../components/SVG/QuestionnairePage'
import { AccessibleButton } from '../../../../components/shared/styled'
import { classicNameResolver } from 'typescript'

export const SearchField = styled(DebounceInput)`
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    line-height: 24px;
    border-radius: 8px;
    padding: 0 30px 0 70px;
    background-color: #f1f3f4;
    color: ${colors.primary.darkBlue};

    &::placeholder {
        color: #c0c0c0;
    }
`

export const SearchFieldWrapper = styled.div`
    width: 100%;
    height: 48px;
    margin: 0 auto;
    max-width: 670px;
    margin-bottom: 20px;
    position: relative;
`

export const Illustration = styled(Icon)`
    position: absolute;
    display: none;
    bottom: 0;
    right: 0;

    ${media.xl} {
        display: block;
    }
`

export const Skip = styled(Link)`
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding-top: 15px;
`

export const Close = styled(CloseIcon)``
export const Title = styled(Typography)``
export const Button = styled(MuiButton)``
export const Subtitle = styled(Typography)``

export const LogoWrapper = styled.div`
    display: none;

    ${media.xl} {
        display: block;
    }
`

export const Wrapper = styled.div`
    display: flex;
    height: inherit;
    min-height: inherit;
    flex-direction: column;

    ${media.xl} {
        flex-direction: row;
    }

    ${Title} {
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        margin-bottom: 15px;
    }
    ${Subtitle} {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 30px;

        ${media.xl} {
            margin-bottom: 40px;
        }
    }

    ${Button} {
        margin-top: 0px;
        width: 100%;

        ${media.phone} {
            width: initial;
        }
        ${media.xl} {
            max-width: inherit;
            margin-top: 40px;
        }
    }

    ${Close} {
        position: absolute;
        right: 10px;
        top: 10px;

        ${media.phone} {
            right: 25px;
            top: 33px;
        }
        ${media.xl} {
            display: none;
        }
    }
`

export const MobileProgress = styled.div`
    width: 100%;
    height: 1px;
    margin: 0 auto;
    max-width: 300px;
    margin-top: 20px;
    position: relative;
    background-color: hsla(216, 15%, 19%, 0.25);

    :before {
        left: 0;
        top: -1.5px;
        height: 4px;
        content: '';
        position: absolute;
        border-radius: 2px;
        background-color: ${colors.primary.darkBlue};
    }

    ${media.xl} {
        display: none;
    }
`

export const MobileSteps = styled.p`
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin: 35px 0 0 0;
    opacity: 0.5;
    margin: 20px 0 0 0;

    ${media.phone} {
        margin: 35px 0 0 0;
    }
    ${media.xl} {
        display: none;
    }
`

export const Left = styled.div`
    background-color: white;
    flex-direction: column;
    position: relative;
    padding: 25px;
    display: flex;
    width: 100%;
    height: 100%;

    ${media.xl} {
        max-width: 440px;
        padding: 50px 70px;
        min-height: inherit;

        :after {
            position: absolute;
            top: 0;
            z-index: 1;
            content: '';
            left: -200px;
            width: 200px;
            height: 100%;
            display: block;
            background-color: white;
        }
    }
    ${media.custom(1279)} {
        position: absolute;
        left: 0;
        top: 0;
    }
`

export const Right = styled.div`
    width: 100%;
    min-height: inherit;
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center;

    ${media.xl} {
        padding: 20px;
        margin-left: auto;
        justify-content: center;
        width: calc(100% - 440px);
        background-color: transparent;
    }
`

export const Circle = styled.div`
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    position: absolute;

    :after {
        top: 3px;
        left: 3px;
        content: '';
        height: 12px;
        width: 12px;
        z-index: 4;
        position: absolute;
        border-radius: 50%;
        border: 2px solid #eaeaeb;
        background-color: white;
    }
`

export const Step = styled.div<{ completed?: boolean }>`
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    font-weight: inherit;
    position: relative;
    padding-left: 25px;
    display: none;

    ${media.xl} {
        display: block;
    }

    ${({ completed }) =>
        completed &&
        `
            ${Circle} {
                :after {
                    border: 0 !important;
                    background-color: ${colors.primary.darkBlue} !important;
                }
            }
        `}
`

type StepsProps = {
    readonly steps: number
    readonly activeStep: number
}

export const Steps = styled.div<StepsProps>`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;

    ${media.xl} {
        height: 220px;
        margin-top: 150px;

        :before {
            width: 1px;
            content: '';
            height: 92%;
            top: 10px;
            left: 9px;
            z-index: 1;
            position: absolute;
            border-radius: 50%;
            background-color: hsla(216, 15%, 19%, 0.25);
        }

        :after {
            top: 10px;
            left: 7px;
            width: 4px;
            z-index: 2;
            content: '';
            position: absolute;
            background-color: ${colors.primary.darkBlue};
            height: calc(${(props) => (props.activeStep * 100) / props.steps - 5}%);
        }

        ${Step} {
            // active
            :nth-of-type(${({ activeStep }) => activeStep + 1}) {
                a {
                    font-weight: 700;
                    color: ${colors.primary.orange};
                }

                ${Circle} {
                    :before {
                        top: 0;
                        left: 0;
                        content: '';
                        z-index: 3;
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                        position: absolute;
                        background-color: white;
                        border: 2px solid ${colors.primary.orange};
                    }
                    :after {
                        border: 0;
                        top: 4px;
                        left: 4px;
                        width: 10px;
                        height: 10px;
                        background-color: ${colors.primary.orange};
                    }
                }
            }
        }
    }

    ${MobileProgress} {
        :before {
            width: calc(${(props) => (props.activeStep * 100) / props.steps}%);

            ${(props) =>
                props.activeStep === 0 &&
                `
                width: 8px;
            `}
        }
    }
`

export const Span = styled.span`
    padding-left: 15px;
`

export const Item = styled(AccessibleButton)<{ readonly selected: boolean }>`
    width: 100%;
    display: flex;
    max-width: 570px;
    cursor: pointer;
    text-align: left;
    padding: 20px 24px;
    border-radius: 4px;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
    background-color: white;
    border: 1px solid hsla(216, 15%, 19%, 0.1);

    p {
        font-size: 14px;

        :first-of-type {
            font-weight: 500;
        }
    }

    div {
        padding-left: 20px;
    }

    svg {
        height: 24px;
        width: 24px;
        min-height: 24px;
        min-width: 24px;
    }

    ${(props) => props.selected && ` background-color: #FEF4E5; `}
`

export const Items = styled.div`
    width: 98%;
    height: 100%;
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
    padding-top: 15px;
    position: relative;
    flex-direction: row;
    justify-content: space-between;

    ${Item} {
        width: 100%;
        padding: 18px 24px;

        ${media.phone} {
            width: 49%;
        }
    }
`
