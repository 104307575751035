import styled from 'styled-components'

import { media } from '@variables'
import { colors } from '@variables'

type QuizItemProps = {
    readonly checked: boolean
    readonly correct: boolean
    readonly wrong: boolean
    readonly disabled: boolean
}

export const QuizItem = styled.div<QuizItemProps>`
    cursor: pointer !important;
    display: table;
    width: 100%;
    min-height: 60px;
    text-align: center;
    transition: all ease-out 0.25s;
    background-color: ${(props) => (props.checked ? `#F5FDF6` : 'white')};

    border: ${(props) =>
        props.checked ? `1px solid ${colors.primary.green}` : '1px solid #eaeaeb'};

    background-color: ${(props) => props.wrong && 'white'};
    border: ${(props) => props.wrong && `1px solid ${colors.primary.red}`};

    background-color: ${(props) => props.correct && '#F2FBF3'};
    border: ${(props) => props.correct && `1px solid ${colors.primary.green}`};

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    ${media.tablet} {
        ${(props) =>
            !props.disabled &&
            `
            :hover {
                background-color: #F5FDF6;
            }
        `}
    }
`

export const Label = styled.label`
    font-size: 14px;
    line-height: 22px;
    padding: 20px 10px;
    display: table-cell;
    vertical-align: middle;
    cursor: pointer !important;
    color: ${colors.primary.darkBlue};
`

export const Fieldset = styled.fieldset`
    padding: 0;
    border: none;
    display: block;
    max-width: 570px;
    margin: 30px auto 0 auto;
`

export const Radio = styled.input`
    opacity: 0;
    position: fixed;
`
