import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import { colors } from '@variables'

export const Text = styled(Typography)``

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    margin: 0 auto;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;

    ${Text} {
        margin-top: 20px;
        color: ${colors.primary.orange};
    }

    svg {
        width: 209px;
        height: auto;
        overflow: inherit;
    }
`
