import get from 'lodash/get'
import { GraphQLError } from 'graphql'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { string, object, boolean } from 'yup'
import React, { useContext, useState } from 'react'
import { useRecaptcha } from '../../../../context/Recaptcha'
import { VisibilityOff, Visibility } from '@material-ui/icons'
import { Form, Field, FormikProps, Formik, FieldProps, FormikHelpers } from 'formik'
import { Button, InputAdornment, IconButton, Checkbox, Typography } from '@material-ui/core'

import * as loco from '@loco'
import { Routes } from '@variables'
import { FieldsWrapper } from '../styled'
import InputField from '../../../Fields/Input'
import { translateGQLError } from '@utils'
import { TermsLink } from '../../../shared/styled'
import GoogleButton from '../../../SocialButtons/Google'
import FacebookButton from '../../../SocialButtons/Facebook'
import AppleButton from '../../../SocialButtons/Apple'
import { AuthContext } from '../../../../context/Auth'
import { useRegisterStudentMutation, Validators } from '@graphql'
import { DIALOG_ID, DialogContext } from '../../../../context/Dialog'
import illustration from '@static/images/girlWithTable.png'
import {
    Title,
    Check,
    Divider,
    LoginLink,
    CheckboxesWrapper,
    BottomButtonWrapper,
    SocialButtonsWrapper
} from './styled'

type Props = {
    validators?: Validators | null
}

type FormValues = typeof initialValues

const initialValues = Object.freeze({
    email: '',
    password: '',
    termsCheckbox: false,
    subscriptionCheckbox: false
})

const RegistrationForm = ({ validators }: Props) => {
    const history = useHistory()
    const recaptcha = useRecaptcha()
    const { enqueueSnackbar } = useSnackbar()

    const { login, isLoggingIn } = useContext(AuthContext)
    const { setOpen, setInfoDialogData } = useContext(DialogContext)

    const [showPassword, setShowPassword] = useState(false)

    const [register, { loading }] = useRegisterStudentMutation()

    const submit = async (
        { email, password, subscriptionCheckbox }: FormValues,
        { resetForm }: FormikHelpers<FormValues>
    ) => {
        try {
            const token = await recaptcha.execute('registration')

            const { data, errors } = await register({
                variables: {
                    data: {
                        email: email,
                        password: password,
                        newsletter: Boolean(subscriptionCheckbox)
                    }
                },
                context: {
                    headers: {
                        'X-Recaptcha-Token': token
                    }
                }
            })

            if (!errors && data) {
                const token = await recaptcha.execute('login')
                const { errors } = await login(email, password, token)

                resetForm()

                if (data) {
                    setInfoDialogData({
                        icon: illustration,
                        title: loco.dialogs.confirm.title,
                        subtitle: loco.dialogs.confirm.subtitle,
                        buttonProps: {
                            text: loco.dialogs.confirm.button,
                            onClick: () => {
                                history.push(Routes.QUESTIONNAIRE_EXPERIENCE)
                                setOpen(DIALOG_ID.NOT_DISPLAYED)
                            }
                        }
                    })
                    setOpen(DIALOG_ID.INFO)
                }
                if (errors) {
                    errors.forEach((err: GraphQLError) => {
                        enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
                    })
                }
            } else {
                errors?.forEach((err) =>
                    enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
                )
            }
        } catch (error) {
            console.log(error)
        }
    }

    const validationSchema = object({
        email: string()
            .email(loco.validation.email.common)
            .required(loco.validation.common.required)
            .matches(
                new RegExp(
                    `${get(
                        validators,
                        'email.regex',
                        `^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6})*$`
                    )}`
                ),
                get(validators, 'email.description', loco.validation.email.common)
            ),
        password: string()
            .required(loco.validation.password.common)
            .matches(
                new RegExp(
                    `${get(
                        validators,
                        'password.regex',
                        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$'
                    )}`
                ),
                get(validators, 'password.description', loco.validation.password.match)
            ),

        termsCheckbox: boolean()
            .required(loco.validation.common.required)
            .oneOf([true], loco.validation.password.terms.common),
        subscriptionCheckbox: boolean()
    })

    const isLoading = isLoggingIn || loading

    return (
        <Formik
            validateOnChange
            onSubmit={submit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            initialTouched={{ password: true, email: true }}
        >
            {({ values, isValid, isSubmitting }: FormikProps<FormValues>) => {
                const { email, password } = values

                return (
                    <Form style={{ width: '100%' }}>
                        <Title variant="h3" gutterBottom>
                            {loco.dialogs.signup.title}
                        </Title>

                        <SocialButtonsWrapper>
                            <FacebookButton />
                            <GoogleButton />
                            <AppleButton />
                        </SocialButtonsWrapper>

                        <Divider>{loco.dialogs.login.or}</Divider>

                        <FieldsWrapper>
                            <Field
                                required
                                fullWidth
                                key="email"
                                name="email"
                                type="email"
                                value={email}
                                component={InputField}
                                className="customInput"
                                disabled={isLoading || isSubmitting}
                                label={loco.dialogs.signup.email.label}
                                placeholder={loco.dialogs.signup.email.placeholder}
                                inputProps={{
                                    autoCorrect: 'off',
                                    autoComplete: 'off',
                                    autoCapitalize: 'none'
                                }}
                            />
                            <Field
                                required
                                fullWidth
                                key="password"
                                name="password"
                                value={password}
                                component={InputField}
                                className="customInput"
                                disabled={isLoading || isSubmitting}
                                type={showPassword ? 'text' : 'password'}
                                label={loco.dialogs.signup.password.label}
                                placeholder={loco.dialogs.signup.password.placeholder}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                style={{ margin: '0 0 20px 0' }}
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </FieldsWrapper>

                        <CheckboxesWrapper>
                            <Check>
                                <Field key="subscriptionCheckbox" name="subscriptionCheckbox">
                                    {({ field }: FieldProps) => <Checkbox {...field} />}
                                </Field>
                                <Typography variant="body2">
                                    {loco.dialogs.signup.subscription}
                                </Typography>
                            </Check>

                            <Check>
                                <Field required key="termsCheckbox" name="termsCheckbox">
                                    {({ field }: FieldProps) => <Checkbox {...field} />}
                                </Field>
                                <Typography variant="body2">
                                    {loco.dialogs.signup.termsAgree}
                                </Typography>
                                <TermsLink
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`https://${process.env.RAZZLE_HOST}/${Routes.TERMS}`}
                                >
                                    <Typography variant="body2">
                                        {loco.dialogs.signup.terms}
                                    </Typography>
                                </TermsLink>
                            </Check>
                        </CheckboxesWrapper>

                        <BottomButtonWrapper>
                            <Button
                                fullWidth
                                size="large"
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={!isValid || isLoading || isSubmitting}
                            >
                                {loco.dialogs.signup.confirm}
                            </Button>
                        </BottomButtonWrapper>

                        <Typography paragraph align="center" style={{ marginBottom: 30 }}>
                            {loco.dialogs.signup.existing}
                            <LoginLink onClick={() => setOpen(DIALOG_ID.LOGIN)}>
                                <b>{loco.dialogs.signup.login}</b>
                            </LoginLink>
                        </Typography>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default RegistrationForm
