import React from 'react'
import Button from '@material-ui/core/Button'

import { Step } from '..'
import * as loco from '@loco'
import { Wrapper, H3, Text, IllustrationWrapper } from '../styled'
import illustration from '@static/images/girlWithTable.png'

type Props = {
    setActiveStep: (step: Step) => void
}

const AfterForgotPassword = ({ setActiveStep }: Props) => (
    <Wrapper>
        <IllustrationWrapper>
            <img width="164" height="160" src={illustration} loading="lazy" />
        </IllustrationWrapper>

        <H3 style={{ marginTop: 15 }}>{loco.dialogs['restore-password'].title}</H3>
        <Text>{loco.dialogs['restore-password'].subtitle}</Text>

        <Button
            fullWidth
            size="large"
            type="button"
            color="primary"
            variant="outlined"
            style={{ marginTop: 20, padding: 12 }}
            onClick={() => setActiveStep(Step.LOGIN)}
        >
            {loco.dialogs['restore-password'].back}
        </Button>
    </Wrapper>
)

export default AfterForgotPassword
