import styled from 'styled-components'
import { Link as L } from 'react-router-dom'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { media } from '@variables'

export const Link = styled(L)``
export const Text = styled(Typography)``
export const Button = styled(MuiButton)``

export const Wrapper = styled.div`
    width: 100%;
    min-height: 300px;
    padding: 40px 25px;
    border-radius: 4px;
    background-color: white;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    ${media.tablet} {
        padding: 50px;
        flex-direction: row;
    }

    ${Text} {
        margin: 15px 0;
        font-size: 16px;
    }

    ${Button} {
        overflow: hidden;
        position: relative;
        font-size: 12px !important;
    }
    ${Link} {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
`

export const Left = styled.div`
    width: 100%;

    ${media.phone} {
        width: 50%;
    }
    svg {
        width: 100%;
        height: auto;
        max-width: 270px;
        margin: 0 auto;
        display: block;
    }
`

export const Right = styled.div`
    width: 100%;
    margin: 25px 0 0 0;
    text-align: center;

    display: flex;
    align-items: center;
    flex-direction: column;

    ${media.phone} {
        width: 50%;
        min-width: 275px;
    }
    ${media.tablet} {
        text-align: left;
        margin: 0 0 0 50px;
        align-items: flex-start;
    }
`
