import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import { media } from '@variables'
import CloseIcon from '../../shared/Close'
import { DialogContent, AccessibleButton } from '../../shared/styled'

export const Close = styled(CloseIcon)``
export const Title = styled(Typography)``

export const Wrapper = styled(DialogContent)`
    width: 100%;
    max-width: 100%;
    padding: 10px 0 0 0;
    align-items: flex-start;

    ${media.desktop} {
        width: 900px;
        max-width: initial;
    }
`

export const Header = styled.header`
    width: 100%;
    display: flex;
    padding: 25px 20px;
    flex-direction: column;

    ${media.tablet} {
        align-items: center;
        flex-direction: row;
        padding: 35px 35px 25px 35px;
        justify-content: space-between;
    }

    ${Title} {
        text-transform: capitalize;
    }
    ${Close} {
        ${media.tablet} {
            top: initial;
            right: initial;
            position: relative;
        }
    }
`

export const MenuItem = styled(AccessibleButton)`
    width: 100%;
    display: flex;
    padding: 10px;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    justify-content: space-between;

    :first-of-type {
        padding-top: 0px;
    }
    :last-of-type {
        padding-bottom: 0px;
    }

    ${media.tablet} {
        :hover {
            text-decoration: underline;
        }
    }
    ${media.desktop} {
        width: initial;
        font-size: 14px;
        font-weight: 500;
    }
`

export const Controls = styled.div`
    display: flex;
    flex-direction: column;

    ${media.tablet} {
        flex-direction: row;
        align-items: center;

        button {
            margin: 0 15px 0 30px;
        }
    }
`

export const Filter = styled(AccessibleButton)`
    display: flex;
    margin: 20px 0;
    flex-direction: row;
    align-items: center;

    ${media.tablet} {
        margin: 0;
    }

    b {
        padding-left: 5px;
    }
`

export const MobileFilters = styled.div`
    width: 100%;
    display: flex;
    padding: 0 15px;
    flex-direction: column;
    align-items: flex-start;
`
