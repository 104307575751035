import { useSetState } from 'react-use'
import { isToday, isYesterday } from 'date-fns'
import Scrollbar from 'react-scrollbars-custom'
import React, { useContext, useEffect } from 'react'
import { Fade, Typography, CircularProgress, ClickAwayListener } from '@material-ui/core'

import { NotificationContext } from '../../../../context/Notification'
import NotificationIcon from '../../../../components/SVG/Notification'
import { Black, Popper, Paper, Column } from './styled'
import { colors } from '@variables'
import Close from '../../../shared/Close'
import * as loco from '@loco'
import Activity from './Activity'

type Props = {
    open: boolean
    anchorEl: any
    handleClose: () => void
}

const ActivityPopper = ({ open, handleClose, anchorEl }: Props) => {
    const { data, loading, loadMore, refetch } = useContext(NotificationContext)

    const [state, setState] = useSetState({ isNearBottom: false })
    const { isNearBottom } = state

    useEffect(() => {
        if (open) refetch()
    }, [open])

    useEffect(() => {
        if (isNearBottom) {
            loadMore()
            return setState({ isNearBottom: false })
        }
    }, [isNearBottom])

    /**
     * Check if scroll near the end and set state if true
     *
     * @param state Scoll state
     * @param state.clientHeight - Outer
     * @param state.contentScrollHeight - Inner (overflowed)
     * @param state.scrollTop - Content's native scrollTop parameter
     */
    const onScroll = (state: {
        scrollTop: number
        clientHeight: number
        contentScrollHeight: number
    }): void => {
        const scrollTop = state.scrollTop
        const clientHeight = state.clientHeight
        const contentScrollHeight = state.contentScrollHeight

        const height: number = contentScrollHeight - clientHeight
        const isNearBottom = height - 100 <= Math.round(scrollTop)

        if (isNearBottom) {
            return setState({ isNearBottom: true })
        }
    }

    const today = data?.filter((n) => isToday(new Date(n.createdAt))) || []
    const yesterday = data?.filter((n) => isYesterday(new Date(n.createdAt))) || []
    const before =
        data?.filter(
            (n) => !isToday(new Date(n.createdAt)) && !isYesterday(new Date(n.createdAt))
        ) || []

    return (
        <>
            <Popper transition open={open} anchorEl={anchorEl} placement="bottom-end">
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper>
                                <Close onClick={handleClose} style={{ zIndex: 999, right: 15 }} />

                                <Scrollbar
                                    noScrollX
                                    onScrollStop={onScroll}
                                    thumbYProps={{ className: 'thumbY' }}
                                    trackYProps={{ className: 'trackY' }}
                                    style={{ height: 400, width: '100%' }}
                                    contentProps={{ style: { padding: '30px 30px 15px 30px' } }}
                                >
                                    {today.length > 0 && (
                                        <Typography variant="body1" style={{ fontWeight: 600 }}>
                                            {loco.notifications.date.today}
                                        </Typography>
                                    )}

                                    {!loading &&
                                        today.map((n) => (
                                            <Activity
                                                id={n.id}
                                                key={n.id}
                                                body={n.body}
                                                title={n.title}
                                                img={n?.icon?.svg || n?.icon?.png || ''}
                                            />
                                        ))}

                                    {yesterday.length > 0 && (
                                        <Typography variant="body1" style={{ fontWeight: 600 }}>
                                            {loco.notifications.date.yesterday}
                                        </Typography>
                                    )}
                                    {!loading &&
                                        yesterday.map((n) => (
                                            <Activity
                                                id={n.id}
                                                key={n.id}
                                                body={n.body}
                                                title={n.title}
                                                img={n?.icon?.svg || n?.icon?.png || ''}
                                            />
                                        ))}

                                    {before.length > 0 && (
                                        <Typography variant="body1" style={{ fontWeight: 600 }}>
                                            {loco.notifications.date.others}
                                        </Typography>
                                    )}
                                    {!loading &&
                                        before.map((n) => (
                                            <Activity
                                                id={n.id}
                                                key={n.id}
                                                body={n.body}
                                                title={n.title}
                                                img={n?.icon?.svg || n?.icon?.png || ''}
                                            />
                                        ))}

                                    {loading && (
                                        <CircularProgress
                                            style={{
                                                display: 'block',
                                                margin: '40px auto'
                                            }}
                                        />
                                    )}

                                    {!loading &&
                                        today.length === 0 &&
                                        yesterday.length === 0 &&
                                        before.length === 0 && (
                                            <Column>
                                                <NotificationIcon
                                                    color={colors.primary.orange}
                                                    style={{
                                                        width: 64,
                                                        height: 64
                                                    }}
                                                />
                                                <Typography
                                                    variant="body1"
                                                    style={{ marginTop: 15, fontWeight: 600 }}
                                                >
                                                    {loco.notifications.empty}
                                                </Typography>
                                            </Column>
                                        )}
                                </Scrollbar>
                            </Paper>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>

            {open && <Black />}
        </>
    )
}

export default ActivityPopper
