import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { media } from '@variables'

export const Title = styled(Typography)``

export const Wrapper = styled.div`
    z-index: 2;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    background-color: white;

    padding: 35px 20px;

    ${media.desktop} {
        padding: 0;
    }

    ${Title} {
        max-width: 300px;
        margin: 40px 0 24px 0;

        ${media.tablet} {
            margin: 60px 0 40px 0;
        }
    }
`

export const Img = styled.img`
    width: 100%;
    height: auto;
    margin: 0 auto;
    max-width: 260px;
`
