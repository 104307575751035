import React from 'react'
import { useField, useFormikContext } from 'formik'
import { Fieldset, Radio, QuizItem, Label } from './styled'

export const RadioButton = ({
    value,
    name,
    disabled,
    isCorrect,
    isWrong,
    label,
    isSubmitting,
    ...props
}: {
    name: string
    value: string
    isWrong: boolean
    isCorrect: boolean
    label: string
    disabled: boolean
    isSubmitting: boolean
}) => {
    const [field, meta] = useField(name)
    const form = useFormikContext()
    const dirty = Boolean(field.value)

    return (
        <QuizItem
            wrong={isWrong}
            correct={isCorrect}
            data-cy="test-quiz-item"
            disabled={disabled && dirty}
            checked={value === field.value}
        >
            <Radio
                id={value}
                name={name}
                type="radio"
                onChange={() => {
                    if (isSubmitting && dirty) return
                    form.setFieldValue(name, value)
                }}
                disabled={disabled && dirty}
                checked={value === field.value}
                {...props}
            />
            <Label htmlFor={value}>{label}</Label>
        </QuizItem>
    )
}

export const RadioButtonGroup = (props: any) => <Fieldset {...props}>{props.children}</Fieldset>
