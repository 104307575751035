import { useMedia } from 'react-use'
import React, { useContext } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { useHistory } from 'react-router-dom'

import Close from '../../shared/Close'
import { DialogContent } from './styled'
import RegistrationForm from './RegistrationForm'
import { useValidatorsQuery } from '@graphql'
import { DIALOG_ID, DialogContext } from '../../../context/Dialog'

const RegistrationDialog = () => {
    const history = useHistory()
    const isWide = useMedia('(min-width: 854px)')

    const { setOpen, id } = useContext(DialogContext)

    const isOpen = id === DIALOG_ID.REGISTRATION

    const { data: validatorsData } = useValidatorsQuery({
        skip: !isOpen,
        onError: (err) => console.log(err)
    })

    const closeDialog = () => {
        setOpen(DIALOG_ID.NOT_DISPLAYED)
        history.push({ search: '' })
    }

    return (
        <Dialog
            open={isOpen}
            fullScreen={!isWide}
            onClose={closeDialog}
            className="customDialog"
            id={DIALOG_ID.REGISTRATION}
            aria-labelledby={DIALOG_ID.REGISTRATION}
        >
            <DialogContent registration>
                <Close onClick={closeDialog} />
                <RegistrationForm validators={validatorsData?.validators} />
            </DialogContent>
        </Dialog>
    )
}

export default RegistrationDialog
