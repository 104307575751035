import React from 'react'
import { Helmet } from 'react-helmet'

import * as loco from '@loco'

type Props = {
    title?: string
    ogImgUrl?: string
    description?: string
}

const Head = ({
    title,
    description,
    ogImgUrl = `https://${process.env.RAZZLE_HOST}/OG.png`
}: Props) => {
    const defaultTitle = loco.seo.title
    const customTitle = title ? ` - ${title} ` : ''

    const customDescription = description ? description : loco.seo.description

    return (
        <Helmet>
            <title>
                {defaultTitle}
                {customTitle}
            </title>
            <meta name="description" content={customDescription} />

            <meta property="og:type" content="website" />
            <meta property="og:image" content={ogImgUrl} />
            <meta property="og:description" content={customDescription} />
            <meta property="og:title" content={`${defaultTitle}${customTitle}`} />
            <meta property="og:url" content={`https://${process.env.RAZZLE_HOST}`} />
        </Helmet>
    )
}

export default Head
