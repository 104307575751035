import { Edit } from '@material-ui/icons'
import MaterialTable from 'material-table'
import { useHistory } from 'react-router-dom'
import React, { forwardRef } from 'react'

import * as loco from '@loco'
import { getRouteWithId } from '@utils'
import Pagination from '../../Pagination'
import { AdminChaptersQuery } from '@graphql'
import { tableLocalization, Routes } from '@variables'
import { tableIcons, chapterAndLectureColumns as columns } from '../../../shared'

type ChapterOrLecture = AdminChaptersQuery['chapters'][0] & { parentId?: string }

type Props = {
    title: string
    pageSize: number
    isLoading: boolean
    data: Array<ChapterOrLecture>
    onChangeItemsPerPage: (page: number) => void
    setTreeExpanded: (data: ChapterOrLecture, is: boolean) => void
}

const ReviewTable = ({
    title,
    data,
    pageSize,
    isLoading,
    setTreeExpanded,
    onChangeItemsPerPage
}: Props) => {
    const history = useHistory()

    return (
        <MaterialTable
            data={data}
            title={title}
            columns={columns}
            icons={tableIcons}
            isLoading={isLoading}
            localization={tableLocalization}
            onTreeExpandChange={setTreeExpanded}
            onChangeRowsPerPage={onChangeItemsPerPage}
            parentChildData={(row, rows) => rows.find((a) => a.id === row.parentId)}
            options={{
                actionsColumnIndex: -1,
                debounceInterval: 500,
                thirdSortClick: false,
                pageSize: pageSize,
                draggable: false
            }}
            components={{
                Pagination: Pagination
            }}
            actions={[
                (reviewRow: ChapterOrLecture) => ({
                    tooltip: loco.test.check,
                    onClick: (e) => {
                        if (Boolean(reviewRow.parentId)) {
                            return history.push(getRouteWithId(Routes.LECTURE_REVIEW, reviewRow.id))
                        }
                        return history.push(getRouteWithId(Routes.CHAPTER_REVIEW, reviewRow.id))
                    },
                    icon: forwardRef(function icon(props, ref: any) {
                        return <Edit style={{ color: '#666666' }} ref={ref} {...props} />
                    }) as any
                })
            ]}
        />
    )
}

export default ReviewTable
