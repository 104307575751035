import { NotificationSchedule } from '@graphql'

export enum Repeat {
    NOT_REPEAT = 'Neopakuje se',
    DAY = 'Denně',
    WEEK = 'Týdně',
    MONTH = 'Měsíčně',
    YEAR = 'Ročně'
}

export const mapByRepeat = (r: Repeat): NotificationSchedule => {
    if (r === Repeat.MONTH) return NotificationSchedule.MONTHLY
    if (r === Repeat.YEAR) return NotificationSchedule.YEARLY
    if (r === Repeat.WEEK) return NotificationSchedule.WEEKLY
    if (r === Repeat.DAY) return NotificationSchedule.DAILY
    return NotificationSchedule.ONCE
}

export const mapByNotificationSchedule = (n?: NotificationSchedule | null): Repeat => {
    if (n === NotificationSchedule.MONTHLY) return Repeat.MONTH
    if (n === NotificationSchedule.YEARLY) return Repeat.YEAR
    if (n === NotificationSchedule.WEEKLY) return Repeat.WEEK
    if (n === NotificationSchedule.DAILY) return Repeat.DAY
    return Repeat.NOT_REPEAT
}

export enum Minutes {
    ZERO = '0',
    FIFTEEN = '15',
    THIRTY = '30',
    FORTY_FIVE = '45'
}

export enum Hours {
    H_0 = '0',
    H_1 = '1',
    H_2 = '2',
    H_3 = '3',
    H_4 = '4',
    H_5 = '5',
    H_6 = '6',
    H_7 = '7',
    H_8 = '8',
    H_9 = '9',
    H_10 = '10',
    H_11 = '11',
    H_12 = '12',
    H_13 = '13',
    H_14 = '14',
    H_15 = '15',
    H_16 = '16',
    H_17 = '17',
    H_18 = '18',
    H_19 = '19',
    H_20 = '20',
    H_21 = '21',
    H_22 = '22',
    H_23 = '23'
}
